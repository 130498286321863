import React from "react";
import styled, { css } from "styled-components";
import { CommunityWithSignatureTemplate } from "../../../../../server/src/@shared/types/organization";
import { getFirstValidSignatureTemplate } from "./utils/utils";
import { SignatureTemplateButton } from "./SignatureTemplateButton";

type Props = {
  community: CommunityWithSignatureTemplate;
  onCheckboxChange: (community: CommunityWithSignatureTemplate) => void;
  isChecked: boolean;
};

export const CommunityListItem = (props: Props) => {
  const signatureTemplate = getFirstValidSignatureTemplate(props.community);

  const onChange = () => {
    if (signatureTemplate) {
      props.onCheckboxChange(props.community);
    }
  };

  return (
    <CommunityListItemContainer disabled={!signatureTemplate}>
      <CheckboxContainer
        type="button"
        onClick={onChange}
        disabled={!signatureTemplate}
      >
        <Checkbox
          type="checkbox"
          checked={props.isChecked}
          disabled={!signatureTemplate}
        />
        <CommunityName disabled={!signatureTemplate}>
          {props.community.name}
        </CommunityName>
      </CheckboxContainer>
      <SignatureTemplateButton community={props.community} />
    </CommunityListItemContainer>
  );
};

const CommunityListItemContainer = styled.li<{ disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  border-bottom: 1px solid #1322951a;
  &:last-of-type {
    border-bottom: 0;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const CheckboxContainer = styled.button`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 16px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-align: left;

  &:disabled {
    cursor: not-allowed;
  }
`;
const Checkbox = styled.input`
  margin-right: 8px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const CommunityName = styled.span<{ disabled: boolean }>`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #343741;

  ${(props) =>
    props.disabled &&
    css`
      color: #aab4c4;
    `}
`;
