import React, { useCallback } from "react";
import styled from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import { useLoginUser } from "../../../hooks/recoil/user";
import { useSearchParams } from "react-router-dom";
import { useOrganizationById } from "../../../hooks/api/internal/organizations";
import { OrganizationAddCommunityModal } from "./OrganizationAddCommunityModal";
import { useModal } from "../../../components/Modal";

export default function OrganizationCommunityListPage() {
  const history = usePolyfitHistory();
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get("organizationId");
  const [isShown, { show, close }] = useModal();

  const { organization, addCommunity } = useOrganizationById(
    organizationId ?? ""
  );
  const { loginUser } = useLoginUser();

  const handleEditCommunity = useCallback((communityId: string) => {
    history.push({
      to: "INTERNAL_COMMUNITY_EDIT",
      query: { communityId },
    });
  }, []);

  return (
    <>
      {loginUser?.internalRole !== "INTERNAL_ADMIN" ? (
        <>
          <div>読み込み中…</div>
          <div>画面が切り替わらない場合はお問い合わせください</div>
        </>
      ) : (
        <>
          <h2>{organization?.name}に所属するコミュニティ一覧</h2>{" "}
          <a
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push({ to: "INTERNAL_ORGANIZATION_LIST" });
            }}
          >
            組織一覧に戻る
          </a>{" "}
          <a
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push({
                to: "INTERNAL_ORGANIZATION_USER_LIST",
                query: {
                  organizationId: organizationId ?? "",
                },
              });
            }}
          >
            組織のユーザー一覧に戻る
          </a>{" "}
          <div>
            <button onClick={show}>所属するコミュニティを追加</button>
          </div>
          <table>
            <tr>
              <td>コミュニティID</td>
              <td>コミュニティ名</td>
              <td>学校コード</td>
              <td>郵便番号</td>
              <td>住所</td>
              <td>作成日時</td>
              <td>更新日時</td>
              <td>操作</td>
            </tr>
            {organization?.communities.map((community, i) => {
              return (
                <tr
                  key={i}
                  style={i % 2 === 0 ? { backgroundColor: "white" } : undefined}
                >
                  <td>
                    <a
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push({
                          to: "INTERNAL_COMMUNITY_USER_LIST",
                          query: {
                            communityId: community.id,
                            serviceType: organization.serviceType,
                          },
                        });
                      }}
                    >
                      {community.id}
                    </a>
                  </td>
                  <td>
                    <a
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push({
                          to: "INTERNAL_COMMUNITY_USER_LIST",
                          query: {
                            communityId: community.id,
                            serviceType: organization.serviceType,
                          },
                        });
                      }}
                    >
                      {community.name}
                    </a>
                  </td>
                  <td>{community.schoolCode}</td>
                  <td>{community.postalCode}</td>
                  <td>
                    {community.address}
                    {community.latitude && community.longitude && (
                      <>
                        {" "}
                        <a
                          href={`https://www.google.com/maps?q=${community.latitude},${community.longitude}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          地図
                        </a>
                      </>
                    )}
                  </td>
                  <td>{community.createdAt}</td>
                  <td>{community.updatedAt}</td>
                  <td>
                    <TransitionLink
                      onClick={() => {
                        handleEditCommunity(community.id);
                      }}
                    >
                      edit
                    </TransitionLink>
                  </td>
                </tr>
              );
            })}
          </table>
          <OrganizationAddCommunityModal
            isOpen={isShown}
            organization={organization}
            onClose={close}
            addCommunity={addCommunity}
          ></OrganizationAddCommunityModal>
        </>
      )}
    </> // end
  );
}

const TransitionLink = styled.a`
  text-decoration-line: underline;
  &:hover {
    cursor: pointer;
  }
`;
