import React from "react";
import styled from "styled-components";

interface Props {
  text: string;
  border?: boolean;
}

export const SideLineHeaderText = ({ text, border }: Props) => {
  return (
    <Container>
      <SideLine />
      <CenterText border={border}>{text}</CenterText>
      <SideLine />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const SideLine = styled.hr`
  width: 100%;
  height: 1.5px;
  background-color: #e6e7e7;
  border: none;
`;

const CenterText = styled.p<{
  border?: boolean;
}>`
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
  color: #5e6967;
  font-weight: ${(props) => (props.border ? 700 : 500)};
  font-family: "Inter";
  font-size: 14px;
  line-height: 21px;
`;
