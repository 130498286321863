import React from "react";
import {
  DeleteModalPortal,
  ModalBody,
  ModalRemoveButtons,
} from "../../../components/Modal";
import { ModalHeader } from "../../../pages/pta/admin/ProfilePage";

type CareerDeleteModalPropsType = {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
};

const CareerDeleteModal = ({
  isOpen,
  onClose,
  onSubmit,
}: CareerDeleteModalPropsType) => {
  if (!isOpen) return null;

  return (
    <DeleteModalPortal onClose={onClose}>
      <ModalHeader>経歴の削除</ModalHeader>
      <ModalBody>
        経歴を削除します。
        <br />
        よろしいですか？
        <ModalRemoveButtons
          submitColor="danger"
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </ModalBody>
    </DeleteModalPortal>
  );
};

export default CareerDeleteModal;
