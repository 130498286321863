import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { getBERecruitmentById } from "src/apiClients/boardEducation/recruitment";

export const useBERecruitmentDetail = (recruitmentId: string) => {
  const query = useQuery({
    queryKey: [
      "api",
      "boardEducation",
      "recruitment",
      "getByRecruitmentId",
      recruitmentId,
    ],
    queryFn: () => getBERecruitmentById(recruitmentId),
  });

  return {
    recruitment: useMemo(() => query.data || null, [query.data]),
    ...query,
  };
};

export function useInvalidateBERecruitmentDetail() {
  const queryClient = useQueryClient();

  function invalidateRecruitmentDetail(recruitmentId?: string) {
    if (recruitmentId) {
      queryClient.invalidateQueries({
        queryKey: [
          "api",
          "boardEducation",
          "recruitment",
          "getByRecruitmentId",
          recruitmentId,
        ],
      });
    } else {
      queryClient.invalidateQueries({
        queryKey: [
          "api",
          "boardEducation",
          "recruitment",
          "getByRecruitmentId",
        ],
      });
    }
  }

  return {
    invalidateRecruitmentDetail,
  };
}
