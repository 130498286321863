import { RecruitmentApplicationListOrder } from "@shared/types/recruitmentApplication";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const recruitmentApplicationFilterState = atom<{
  name: string;
  order?: RecruitmentApplicationListOrder;
}>({
  key: "RecruitmentApplicationFilter",
  default: {
    name: "",
    order: undefined,
  },
  effects_UNSTABLE: [persistAtom],
});

export const useRecruitmentApplicationFilter = () => {
  const [recruitmentApplicationFilter, setRecruitmentApplicationFilter] =
    useRecoilState(recruitmentApplicationFilterState);

  return [
    recruitmentApplicationFilter,
    setRecruitmentApplicationFilter,
  ] as const;
};
