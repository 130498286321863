import React, { JSX } from "react";
import { ModalHeader, ModalPortal } from "../../../components/Modal";
import { Button } from "../../../components/Button";
import {
  MINIMUM_NUMBER_OF_QUESTIONS,
  isMultiChoiceQuestion,
  SURVEY_DEADLINE_TIME_INTERVAL,
} from "../../../apiClients/survey";
import { EventHandlers } from "./eventHandlers";
import styled from "styled-components";
import { Question as QuestionComponent, QuestionField } from "./Question";
import { Label } from "../../../components/form/Label";
import ReactDatePicker from "react-datepicker";
import { Question, SurveyQuestions } from "@shared/types/post/survey/survey";

// 保存前にサニタイズする
// TODO: React Hook Form導入時に廃止する
// 1. 補足説明以外は空欄をゆるさない
// 2. 回答期限が現在時刻より後
// 返り値(isValid)
//   OK -> true
//   NG -> false
function checkIsValid(surveyQuestions: SurveyQuestions): boolean {
  // まとめてエラーメッセージを生成、出力
  // constだけで書こうとすると可読性が大きく下がる
  // 別に複雑なロジックでも無いので、ナイーブに追加していく方式を取る
  let errorMessages: string = "";
  // 各質問に対する処理
  for (const [
    questionIndex,
    question,
  ] of surveyQuestions.questionList.entries()) {
    // 質問記述欄の空欄チェック
    if (question.text === "") {
      errorMessages += `質問${questionIndex + 1}つめが空欄です\n`;
    }
    // 選択式のみ
    if (isMultiChoiceQuestion(question)) {
      // 各選択肢に対する処理
      for (const [choiceIndex, choice] of question.choiceList.entries()) {
        // 選択肢の空欄チェック
        if (choice.text === "") {
          errorMessages += `質問${questionIndex + 1}つめ選択肢${
            choiceIndex + 1
          }つめが空欄です\n`;
        }
      }
    }
  }
  // 締め切りに対する処理
  if (new Date(surveyQuestions.deadLine) < new Date()) {
    errorMessages += `回答期限を既に過ぎています\n`;
  }
  if (errorMessages === "") {
    return true;
  } else {
    alert(errorMessages);
    return false;
  }
}

// 一番下のボタン群
// questionList, eventHandlers: アンケート関連
// onClose: モーダルを閉じる処理
function Footer({
  surveyQuestions,
  eventHandlers,
  onClose,
}: {
  surveyQuestions: SurveyQuestions;
  eventHandlers: EventHandlers;
  onClose: () => void;
}): JSX.Element {
  return (
    <ButtonContainer>
      <Button
        empty
        color="primary"
        size="large"
        type="button"
        onClick={() => {
          if (!confirm("編集中のアンケートが破棄されますがよろしいですか？")) {
            return;
          }
          // 再度アンケート作成を試みられた時に
          //   前の情報を残さないようにアンケート情報を初期化する
          eventHandlers.handleResetSurvey();
          onClose();
        }}
        disabled={false}
      >
        キャンセル
      </Button>
      <Button
        fill
        color="dark"
        size="large"
        type="submit"
        disabled={false}
        onClick={() => {
          // 下書き保存なのでサニタイズはしない
          // NOTE: なのでこの状態ではポストさせない必要がある
          eventHandlers.handleUpdateSurveyState("DRAFTED");
          onClose();
        }}
      >
        下書き保存
      </Button>
      <Button
        fill
        color="primary"
        size="large"
        type="submit"
        disabled={false}
        onClick={() => {
          // 内容の妥当性を確認
          if (!checkIsValid(surveyQuestions)) {
            // ダメなら弾く
            return;
          }
          eventHandlers.handleUpdateSurveyState("CREATED");
          onClose();
        }}
      >
        作成
      </Button>
    </ButtonContainer>
  );
}

// アンケート作成用モーダル
// onClose: このモーダルを閉じる際に発火
export function CreateSurvey({
  surveyQuestions,
  eventHandlers,
  onClose,
}: {
  surveyQuestions: SurveyQuestions;
  eventHandlers: EventHandlers;
  onClose: () => void;
}): JSX.Element {
  // アンケートモーダル全体のcomponent
  return (
    <ModalPortal
      onClose={() => {
        if (!confirm("編集中のアンケートが破棄されますがよろしいですか？")) {
          return;
        }
        // 再度アンケート作成を試みられた時に
        //   前の情報を残さないようにアンケート情報を初期化する
        eventHandlers.handleResetSurvey();
        onClose();
      }}
    >
      <ModalHeader>アンケート</ModalHeader>
      {/* 質問リスト */}
      <QuestionWrapper>
        {surveyQuestions.questionList.map((question: Question) => (
          <QuestionComponent
            key={question.id}
            question={question}
            eventHandlers={eventHandlers}
            // 質問が唯一の時以外は削除できるようにする
            isDeletable={
              surveyQuestions.questionList.length > MINIMUM_NUMBER_OF_QUESTIONS
            }
          />
        ))}
      </QuestionWrapper>
      {/* 追加ボタン */}
      <Spacer height={8} />
      <FlexEnd>
        <TextButton
          type="button"
          onClick={() => eventHandlers.handleAddQuestion()}
        >
          + 質問を追加
        </TextButton>
      </FlexEnd>
      <Spacer height={8} />
      {/* 期限 */}
      <QuestionField>
        <Label size="s" marginBottom={4}>
          回答期限
        </Label>
        <ReactDatePicker
          placeholderText="2000/01/01 0:00"
          dateFormat="yyyy/MM/dd HH:mm"
          showTimeSelect
          timeIntervals={SURVEY_DEADLINE_TIME_INTERVAL}
          onChange={eventHandlers.handleUpdateDeadLine}
          selected={
            surveyQuestions.deadLine
              ? new Date(surveyQuestions.deadLine)
              : undefined
          }
          minDate={new Date()}
          maxDate={new Date("2100-12-31")}
        />
      </QuestionField>
      <Spacer height={24} />
      {/* キャンセル、下書き保存、作成ボタン */}
      <Footer
        surveyQuestions={surveyQuestions}
        eventHandlers={eventHandlers}
        onClose={onClose}
      />
    </ModalPortal>
  );
}

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TextButton = styled.button`
  color: #005ec4;
  font-size: 14px;
  font-weight: normal;
  border-width: 0;
  background-color: inherit;
  cursor: pointer;
  width: fit-content;
`;

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Spacer = styled.div<{
  height: number;
}>`
  height: ${(props) => props.height}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
