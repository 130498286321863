import React from "react";

export const PrevButtonIcon = () => {
  return (
    <>
      <svg
        width="7"
        height="14"
        viewBox="0 0 7 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.64018 0.231804C7.06446 0.585368 7.12179 1.21593 6.76822 1.64021L2.30171 7.00003L6.76822 12.3598C7.12179 12.7841 7.06446 13.4147 6.64018 13.7682C6.21591 14.1218 5.58534 14.0645 5.23178 13.6402L0.231779 7.64021C-0.0772596 7.26936 -0.0772596 6.73069 0.231779 6.35984L5.23178 0.359841C5.58534 -0.0644363 6.21591 -0.12176 6.64018 0.231804Z"
          fill="#37434F"
        />
      </svg>
    </>
  );
};
