import {
  ApplicationStatus,
  RecruitmentApplication,
} from "../recruitmentApplication";
import {
  ChatRoom,
  ChatRoomType,
  FilterApprovalStatus,
  GroupChatWithOrganization,
  IndividualChatWithBoardEducation,
  IndividualChatWithOrganization,
} from "../chatRoom";
import { Community } from "../community";
import { Role } from "../role";

export const csRoleOptions = [
  { value: Role.CSAdmin, text: "管理者" },
  { value: Role.CSOperator, text: "オペレーター" },
  { value: Role.CSMember, text: "メンバー" },
] as const;

type CSRoleOptionValue = (typeof csRoleOptions)[number]["value"];

export type ChatRoomFilterType = {
  showCSOrganizationChat: boolean;
  type: ChatRoomType | "";
  recruitmentName?: string | null;
  recruitmentSchedule?: string | null;
  applicationStatus: ApplicationStatus | "";
  approvalStatus: FilterApprovalStatus;
  participantChatRoomApprovalStatus: FilterApprovalStatus;
  chatRoomName: string | "";
  communityIds: string[];
  roles: CSRoleOptionValue[];
};

export type ChatRoomEntity = {
  chatRoom: ChatRoom & { community: Pick<Community, "name"> | null };
  individualChatWithOrganization?: IndividualChatWithOrganization;
  individualChatWithBoardEducation?: IndividualChatWithBoardEducation;
  recruitmentApplication?: RecruitmentApplication;
  groupChatWithOrganization?: GroupChatWithOrganization;
  groupChatWithBoardEducation?: GroupChatWithOrganization;
  lastReadAt: Date;
  lastReadAts?: Date[];
};
