import * as client from "./client";
import { PostType } from "../hooks/api/post";
import { SurveyPostTargetUserResponse } from "@shared/types/post/getSurveyPostTargetUsers";
import { CreatePostArgs, ListPostRequest } from "@shared/types/post/api";
import { ReapplyPostType } from "@shared/types/post/api";

export async function getLatestPostByOrganizationId() {
  const post = await client.get("/post/getLatestPost", {});
  return post;
}

/**
 * 連絡一覧を取得する
 */
export async function getPosts(data: ListPostRequest) {
  const res = await client.post<{}, PostType[]>("/post/list", data);
  return res;
}

// TODO: 以下APIのURIも修正する
/**
 * 自分の連絡を取得する
 */
export async function getCurrentUserPosts() {
  const posts = await client.get<{}, PostType[]>(
    "/post/getCurrentUserPosts",
    {}
  );
  return posts;
}

/**
 * 未承認の連絡を取得する
 */
export async function getUnApprovedPost() {
  const posts = await client.get<{}, PostType[]>("/post/getUnApprovedPost", {});
  return posts;
}

/**
 * 未承認の連絡を取得する
 */
export async function getUnDeliveredPost() {
  const posts = await client.get<{}, PostType[]>(
    "/post/getUnDeliveredPost",
    {}
  );
  return posts;
}

export async function createPost({
  args,
}: {
  args: CreatePostArgs;
}): Promise<PostType> {
  // 改行対応
  const convertedText = args.text.split(/(\n)/).map((item) => {
    return item.match(/\n/) ? "\\n" : item;
  });
  args.text = convertedText.join("");

  const res = await client.post<CreatePostArgs, PostType>(
    "/post/createPost",
    args
  );
  return res;
}

export async function reapplyPost({
  args,
}: {
  args: ReapplyPostType;
}): Promise<PostType> {
  // 改行対応
  const convertedText: string[] | undefined = args.text
    .split(/(\n)/)
    .map((item) => {
      return item.match(/\n/) ? "\\n" : item;
    });
  args.text = convertedText.join("");
  const res = await client.post<PostType, PostType>(
    "/post/reapplyPost",
    // @ts-ignore
    args
  );
  return res;
}

/**
 * 投稿の削除
 */
export async function deletePost(postId: string) {
  const res = await client.post<{ postId: string }, PostType>(
    "/post/deletePost",
    { postId }
  );

  return res;
}

/**
 * 投稿の差し戻し
 */
export async function remandPost(postId: string, comment: string) {
  await client.post<{ postId: string; comment: string }, PostType>(
    "/post/remandPost",
    { postId, comment }
  );
}

/**
 * 投稿の承認
 */
// TODO: 通常承認と最終承認でエンドポイントを分ける
export async function approvePost(
  postId: string,
  isLastApprover: boolean,
  scheduledSendAt?: Date
) {
  await client.post<{
    postId: string;
    isLastApprover: boolean;
    scheduledSendAt?: Date;
  }>("/post/approve", {
    postId,
    isLastApprover,
    scheduledSendAt,
  });
}

export async function resendPost(postId: string, userIds: string[]) {
  await client.post<{
    postId: string;
    userIds: string[];
  }>("/post/resendPost", {
    postId,
    userIds,
  });
}

export async function resendSurveyPost(postId: string, userIds: string[]) {
  await client.post<{
    postId: string;
    userIds: string[];
  }>("/post/resendSurveyPost", {
    postId,
    userIds,
  });
}

/**
 * 投稿の予約投稿のキャンセル
 */
export async function cancelScheduledPost(postId: string) {
  await client.post<{ postId: string }>("/post/cancelScheduledPost", {
    postId,
  });
}

export async function getPostWithTargets(postId: string): Promise<PostType> {
  const { post } = await client.get<{ postId: string }, { post: PostType }>(
    "/post/getPostWithTargets",
    {
      postId,
    }
  );

  return post;
}

export async function getSurveyPostTargetUsers(surveyQuestionsId: string) {
  const res = await client.get<
    { surveyQuestionsId: string },
    { users: SurveyPostTargetUserResponse[] }
  >("/post/getSurveyPostTargetUsers", { surveyQuestionsId });
  return res.users;
}

export async function uploadPdf({
  pdfFile,
}: {
  pdfFile: File;
}): Promise<string> {
  const res = await client.postWithFormData<
    { pdfFile: File },
    { pdfFilePath: string }
  >("/post/uploadPdf", { pdfFile });
  return res.pdfFilePath;
}
