import { RecruitmentTemplate } from "@shared/types/recruitmentTemplate";
import * as client from "./client";

export async function getRecruitmentTemplateList(): Promise<
  RecruitmentTemplate[]
> {
  return await client.get<{}, RecruitmentTemplate[]>(
    "/recruitment-template/list",
    {}
  );
}

export async function getRecruitmentTemplateById(
  recruitmentTemplateId: string
): Promise<RecruitmentTemplate> {
  return await client.get<
    { recruitmentTemplateId: string },
    RecruitmentTemplate
  >("/recruitment-template/getByRecruitmentTemplateId", {
    recruitmentTemplateId,
  });
}

export async function deleteRecruitmentTemplateById(
  recruitmentTemplateId: string
): Promise<void> {
  await client.post<{ recruitmentTemplateId: string }>(
    "/recruitment-template/delete",
    {
      recruitmentTemplateId,
    }
  );
}
