import React from "react";

export const RemoveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_6685_218048)">
        <path
          d="M1 1L11 11"
          stroke="#69707D"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M11 1L1 11"
          stroke="#69707D"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6685_218048">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
