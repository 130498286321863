import React from "react";

export const BikeIcon = () => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.12 4.6665L10.9867 1.5465C10.7933 1.01984 10.2933 0.666504 9.73333 0.666504H8V1.99984H9.73333L10.7067 4.6665H7.5L7.26 3.99984H8V2.6665H4.66667V3.99984H5.83333L7.04667 7.33317H6.6C6.30667 5.8465 5.06 4.7465 3.5 4.67317C1.63333 4.57984 0 6.13317 0 7.99984C0 9.8665 1.46667 11.3332 3.33333 11.3332C4.97333 11.3332 6.3 10.2065 6.6 8.6665H9.4C9.69333 10.1532 10.94 11.2532 12.5 11.3265C14.3667 11.4132 16 9.8665 16 7.99317C16 6.1265 14.5333 4.65984 12.6667 4.65984H12.12V4.6665ZM5.21333 8.6665C4.94667 9.4465 4.22 9.99984 3.33333 9.99984C2.21333 9.99984 1.33333 9.11984 1.33333 7.99984C1.33333 6.87984 2.21333 5.99984 3.33333 5.99984C4.22 5.99984 4.94667 6.55317 5.21333 7.33317H3.33333V8.6665H5.21333ZM9.4 7.33317H8.46667L7.98 5.99984H10C9.70667 6.3865 9.49333 6.83317 9.4 7.33317ZM12.6667 9.99984C11.5467 9.99984 10.6667 9.11984 10.6667 7.99984C10.6667 7.37984 10.94 6.8465 11.3667 6.47984L12.0067 8.23984L13.26 7.7865L12.6133 6.0065C12.6333 6.0065 12.6533 5.99984 12.6733 5.99984C13.7933 5.99984 14.6733 6.87984 14.6733 7.99984C14.6733 9.11984 13.7867 9.99984 12.6667 9.99984Z"
        fill="white"
      />
    </svg>
  );
};
