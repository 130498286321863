import React from "react";
import { usePolyfitHistory } from "../../../hooks/router";
import { BaseInfo, useCurrentUser } from "../../../hooks/recoil/user";
import styled from "styled-components";
import { Margin } from "../../../components/Margin";
import { RegistrationFooter } from "../../../components/Footer2";
import { useCustomReactHookForm } from "../../../components/form/ReactHookForm";
import { BelongPartSettingIcon } from "../../../components/icons/BelongPartSettingIocon";
import { SignatureConfirmIcon } from "../../../components/icons/SignatureConfirmIcon";
import { UserInfoInputIcon } from "../../../components/icons/UserInfoInputIcon";
import { unreachable } from "../../../utils/unreachable";
import { Role } from "../../../utils/types/role";

export default function RegisterDescriptionPage() {
  const history = usePolyfitHistory();
  const user = useCurrentUser();
  const { Form } = useCustomReactHookForm<BaseInfo>();

  const onSubmit = async () => {
    try {
      switch (user.role) {
        case Role.PTAAdmin:
          history.push({
            to: "REGISTER_INFO",
          });
          break;
        case Role.PTAMember:
          history.push({
            to: "REGISTER_INFO",
          });
          break;
        case Role.CSAdmin:
        case Role.CSMember:
        case Role.CSGuest:
        case Role.CSOperator:
        case Role.BEAdmin:
          break;
        default:
          unreachable(user.role);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Margin marginBottom={36} />

      <Form onSubmit={onSubmit}>
        <StyledContainer>
          <DescriptionText>
            新規登録ありがとうございます！
            <br /> これから登録に必要な作業やりましょう
          </DescriptionText>

          <RegistrationFlowImagesContainer>
            <RegistrationFlowImage>
              <UserInfoInputIcon />
              <DescriptionText>ユーザー情報入力</DescriptionText>
            </RegistrationFlowImage>

            <RegistrationFlowImage>
              <BelongPartSettingIcon />
              <DescriptionText>所属役職設定</DescriptionText>
            </RegistrationFlowImage>

            <RegistrationFlowImage>
              <SignatureConfirmIcon />
              <DescriptionText>入会規約確認</DescriptionText>
            </RegistrationFlowImage>
          </RegistrationFlowImagesContainer>
        </StyledContainer>
        <RegistrationFooter isDisplayBeforeButton={true} />
      </Form>
    </div>
  );
}

const StyledContainer = styled.div`
  max-width: 496px;
  margin: 100px auto;

  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

const DescriptionText = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #343741;
`;

const RegistrationFlowImage = styled.div`
  max-width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const RegistrationFlowImagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 40px;

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 50px;
  }
`;
