import React, { useEffect, useState } from "react";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../components/Modal";
import { ModalHeader } from "../../pages/pta/admin/ProfilePage";
import { Switch } from "../../components/form/Switch";
import { useForm } from "react-hook-form";
import { useToast } from "../../components/Toast";
import styled from "styled-components";
import { useOrganization } from "../../hooks/recoil/organization";
import { logger } from "src/utils/logger";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const NameListPublishedModal = ({ isOpen, onClose }: Props) => {
  const { organization, fetchOrganization, writeOrganization } =
    useOrganization({});

  const toast = useToast();

  const { register, setValue, getValues } = useForm({
    defaultValues: {
      nameListPublished: organization.nameListPublished!,
    },
  });

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async () => {
    setDoubleClickBlocked(true);
    try {
      await writeOrganization({
        nameListPublished: getValues("nameListPublished"),
      });
      onClose();
      toast.info("保存しました");
      await fetchOrganization();
    } catch (error) {
      logger.error(error);
      toast.error("保存に失敗しました");
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  useEffect(() => {
    setValue("nameListPublished", organization.nameListPublished!);
  }, [organization]);

  // organization === undefinedの状態では何も表示しない
  if (typeof organization === "undefined") return <></>;

  return (
    <>
      {isOpen && (
        <ModalPortal
          onClose={() => {
            onClose();
            setValue("nameListPublished", organization.nameListPublished!);
          }}
        >
          <ModalHeader>公開設定</ModalHeader>
          <ModalBody>
            <Text>名簿の公開・非公開を設定できます。</Text>
            <Switch
              label="名簿を公開する"
              register={register("nameListPublished")}
              value={1}
            />
            <ModalSubmitButtons
              disabled={doubleClickBlocked}
              onSubmit={onSubmit}
              onCancel={() => {
                onClose();
                setValue("nameListPublished", organization.nameListPublished!);
              }}
            />
          </ModalBody>
        </ModalPortal>
      )}
    </>
  );
};

const Text = styled.p`
  margin-bottom: 20px;
`;
