import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as theme from "../theme";
import { Panel } from "../components/Panel";
import { Heading1, Heading2 } from "../components/Typo";
import { Button } from "../components/Button";
import { FlexLayout } from "../components/Container";
import { PasswordConfirmInput, PasswordInput } from "../components/Form";
import { usePolyfitHistory } from "../hooks/router";
import { useRecoilState } from "recoil";
import { formEmailPasswordState } from "../hooks/formEmailPassword";
import {
  checkActionCode,
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "../utils/firebase";
import { useToast } from "../components/Toast";
import { logout } from "src/utils/auth";
import * as authApi from "../apiClients/auth";

// URLから指定したクエリパラメータのバリューを抜き出す＆デコードをする関数
const getParameterByName = (name: string) => {
  const url = window.location.href;
  // FIXME: Regular Expressionを見直す
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export default function ActionPage() {
  const history = usePolyfitHistory();
  const [error, setError] = useState("");
  const { 0: formEmailPassword } = useRecoilState(formEmailPasswordState);
  const toast = useToast();

  const mode = getParameterByName("mode");
  const actionCode = getParameterByName("oobCode");
  const url = getParameterByName("continueUrl");

  useEffect(() => {
    (async () => {
      if (!mode || !actionCode) {
        throw new Error("invalid url");
      }

      try {
        await checkActionCode(actionCode);
      } catch (e) {
        history.push({ to: "LOGIN", query: {} });
        toast.error("同じURLを２回以上使えません。");
        return;
      }

      if (mode === "verifyEmail" || mode === "verifyAndChangeEmail") {
        await applyActionCode(actionCode);
        toast.success("メール認証が完了しました。");

        if (mode === "verifyAndChangeEmail") {
          await authApi.syncEmail();
          logout();
          window.location.href = "/login";
        } else {
          window.location.href = url ?? "/login";
        }
      } else if (mode === "resetPassword") {
        await verifyPasswordResetCode(actionCode).catch(() => {
          history.push({ to: "LOGIN", query: {} });
          toast.error("不正なURLです");
        });
      }
    })();
  }, []);

  const submitNewPassword = async () => {
    if (formEmailPassword.password !== formEmailPassword.passwordConfirm)
      return setError("パスワードが一致していません");

    if (actionCode == null) {
      // ここではactionCodeがnullになることはないが、returnしておく
      return;
    }

    try {
      await confirmPasswordReset(actionCode, formEmailPassword.password);
      history.push({ to: "LOGIN", query: {} });
      toast.success("パスワードを変更しました");
    } catch (err) {
      toast.error("パスワードの再設定に失敗しました");
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <Header>polyfit</Header>
      </HeaderContainer>
      <ContainerBox>
        <FlexLayout direction="column">
          {mode === "resetPassword" ? (
            <>
              <Heading1>パスワード再設定</Heading1>
              <Panel>
                <FormDescription>
                  新しいパスワードを入力してください。
                </FormDescription>
                <PasswordInput isLogin={false} />
                <Spacer />
                <PasswordConfirmInput />

                {!!error && (
                  <ErrorMessage>
                    {error}
                    <br />
                    管理者までお問合せ下さい。
                  </ErrorMessage>
                )}
                <Spacer />

                <RegisterButton type="button" onClick={submitNewPassword}>
                  変更
                </RegisterButton>
              </Panel>
            </>
          ) : mode === "verifyEmail" ? (
            <></>
          ) : mode === "recoverEmail" ? (
            <></>
          ) : mode === "verifyAndChangeEmail" ? (
            <></>
          ) : (
            <div>error: modeが選択されていない不正なURLです。</div>
          )}
        </FlexLayout>
      </ContainerBox>
    </Container>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  gap: 460px;
  position: absolute;
  height: 48px;
  left: 0px;
  right: 0px;
  top: 0px;
  background: #2277cc;
  box-shadow: 0px 0.7px 1.4px rgba(0, 0, 0, 0.07),
    0px 1.9px 4px rgba(0, 0, 0, 0.05), 0px 4.5px 10px rgba(0, 0, 0, 0.05);
`;

const Header = styled.header`
  width: 45px;
  height: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const Container = styled.div`
  background: ${theme.states.pageBackgroundColor};
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
`;

const Spacer = styled.div`
  height: 20px;
`;

const RegisterButton = styled.button`
  height: 36px;
  display: block;
  width: 100%;
  align-items: center;
  color: #ffffff;
  background-color: ${theme.colorsPallet.primary};
  border: 1px solid rgba(105, 112, 125, 0.2);
  border-radius: 6px;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.7;
  }
`;

const ContainerBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;

  ${Panel} {
    padding: 24px;
    text-align: center;
    width: 340px;
    min-height: 200px;
  }

  ${Button} {
    height: 36px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    color: ${theme.colorTokens.text};
    border: 1px solid rgba(105, 112, 125, 0.2);
    border-radius: 6px;
  }

  ${Button} svg {
    margin-right: 8px;
  }

  ${Heading2} {
    margin-bottom: 42px;
  }

  ${Heading1} {
    text-align: center;
    font-weight: 700;
    color: #1a1c21;
    font-size: 22px;
    margin-bottom: 20px;
  }
`;

const ErrorMessage = styled.div`
  margin-top: 12px;
  background-color: #fff0ef;
  color: #bd271e;
  font-size: 12px;
  text-align: start;
  padding: 6px 10px;
`;

const FormDescription = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: ${theme.colorTokens.text};
  margin-bottom: 20px;
`;
