import React from "react";

export const LogicalUpDownIcon = () => {
  return (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9407 4.66341C9.72334 4.89113 9.36253 4.89952 9.13482 4.68216L5.47958 1.19306C5.40616 1.12298 5.29062 1.12298 5.2172 1.19306L1.56196 4.68215C1.33425 4.89952 0.97344 4.89113 0.756077 4.66341C0.538713 4.4357 0.547105 4.07489 0.774819 3.85753L4.43006 0.368439C4.944 -0.122144 5.75278 -0.122144 6.26672 0.368439L9.92196 3.85753C10.1497 4.07489 10.1581 4.4357 9.9407 4.66341Z"
        fill="white"
      />
      <path
        d="M0.756077 8.01627C0.97344 7.78856 1.33425 7.78017 1.56196 7.99753L5.2172 11.4866C5.29062 11.5567 5.40616 11.5567 5.47958 11.4866L9.13482 7.99753C9.36253 7.78017 9.72334 7.78856 9.9407 8.01627C10.1581 8.24399 10.1497 8.60479 9.92196 8.82216L6.26672 12.3112C5.75278 12.8018 4.944 12.8018 4.43006 12.3112L0.774818 8.82216C0.547104 8.60479 0.538714 8.24399 0.756077 8.01627Z"
        fill="white"
      />
    </svg>
  );
};
