import React, { useCallback, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { Button } from "src/components/Button";
import { CloseIcon } from "src/components/icons/CloseIcon";
import { FileUpload } from "src/components/icons/FileUpload";
import { PdfIcon } from "src/components/icons/PdfIcon";
import { useGetDownloadUrls } from "src/hooks/query/firebaseStorage";
import styled from "styled-components";

export type SelectedFileInfo = {
  name: string;
  path: string;
  file?: File;
};

type RecruitmentFormPDFPickerProps = {
  selectedFileInfos: SelectedFileInfo[];
  setSelectedFileInfos: React.Dispatch<
    React.SetStateAction<SelectedFileInfo[]>
  >;
};
function RecruitmentFormPDFPicker({
  selectedFileInfos,
  setSelectedFileInfos,
}: RecruitmentFormPDFPickerProps) {
  const [errorMessage, setErrorMessage] = useState<string>("");

  //選択されたファイルの処理
  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    setErrorMessage("");
    //重複ファイルを除外
    setSelectedFileInfos((prevSelectedFileInfos) => {
      const newFiles = acceptedFiles.filter(
        (newFile) =>
          !prevSelectedFileInfos?.some(
            (existingFileInfo) => newFile.name === existingFileInfo.name
          )
      );

      //重複ファイルのエラーメッセージ
      const dupFiles = acceptedFiles.filter((newFile) =>
        prevSelectedFileInfos?.some(
          (existingFile) => newFile.name === existingFile.name
        )
      );
      if (dupFiles.length > 0) {
        const dupFileNames = dupFiles.map((file) => file.name).join("、");
        setErrorMessage(`${dupFileNames}は既に添付されています`);
      }
      //fileInfoに変換
      const newFileInfos: SelectedFileInfo[] = newFiles.map((file) => {
        const path = URL.createObjectURL(file);
        return {
          name: file.name,
          path: path,
          isAlreadyUploaded: false,
          file: file,
        };
      });

      const updatedFileInfos = [...prevSelectedFileInfos, ...newFileInfos];
      if (updatedFileInfos.length > 3) {
        setErrorMessage("PDFファイルは3つまでしか添付できません");
        return prevSelectedFileInfos;
      }
      return updatedFileInfos;
    });
  }, []);
  //ファイルが大きすぎた場合にエラーメッセージを表示
  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    rejectedFiles.forEach(({ file, errors }) => {
      errors.forEach(({ code }) => {
        switch (code) {
          case "file-too-large":
            setErrorMessage(
              `${file.name} のファイルサイズが大きすぎます。30MB以下のファイルをアップロードしてください。`
            );
            break;
          default:
            break;
        }
      });
    });
  };
  const removeFileInfo = (index: number) => {
    setErrorMessage("");
    setSelectedFileInfos((prevSelectedFileInfos) => {
      const newSelectedFileInfos = prevSelectedFileInfos.filter(
        (_, i) => i !== index
      );
      return newSelectedFileInfos;
    });
  };

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: {
      "application/pdf": [".pdf", ".PDF"],
    },
    maxSize: 30 * 1024 * 1024,
  });
  const acceptStyle = useMemo(
    () => ({
      backgroundColor: "#e6f5ff",
    }),
    []
  );

  const style = useMemo(
    () =>
      ({
        ...(isDragAccept ? acceptStyle : {}),
      } as React.CSSProperties),
    [isDragAccept, acceptStyle]
  );

  const { urlsWithPath } = useGetDownloadUrls(
    selectedFileInfos
      .filter((fileInfo) => !fileInfo.file)
      .map((fileInfo) => fileInfo.path)
  );
  const selectedFileInfosUrls = selectedFileInfos.map((fileInfo) => {
    const path = fileInfo.path;
    if (fileInfo.file) {
      return path;
    } else {
      //urlsWithPathのpathとpathが一致するもののurlを取得
      return urlsWithPath.find((urlWithPath) => urlWithPath.path === path)?.url;
    }
  });

  return (
    <FilePickerSection>
      <FilePicker>
        <FilePickerRoot {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <NotMobileViewOnly>
            <FilePickerInner>
              <FileUpload size={36} />
              <p>
                <FilePickerEmp>PDFファイルを選択</FilePickerEmp>
                するか、ドラッグアンドドロップで募集にPDFを添付できます
              </p>
            </FilePickerInner>
          </NotMobileViewOnly>
          <MobileViewOnly>
            <Button
              size="small"
              color="subPrimary"
              disabled={selectedFileInfos?.length >= 3}
            >
              PDFファイルを追加
            </Button>
          </MobileViewOnly>
        </FilePickerRoot>
      </FilePicker>
      <SelectedPdfFiles>
        {selectedFileInfos?.map((fileInfo, index) => {
          return (
            <div key={index}>
              <PdfPreview>
                <PdfIconWrapper>
                  <PdfIcon size={24} />
                </PdfIconWrapper>
                <PdfLabel target="blank" href={selectedFileInfosUrls[index]}>
                  {fileInfo.name}
                </PdfLabel>
                <PdfRemoveButton
                  type="button"
                  onClick={() => removeFileInfo(index)}
                >
                  <CloseIcon color="#343741" />
                </PdfRemoveButton>
              </PdfPreview>
            </div>
          );
        })}
      </SelectedPdfFiles>
      {errorMessage && <span className="error-message">{errorMessage}</span>}
    </FilePickerSection>
  );
}

const FilePicker = styled.div`
  width: 100%;
  @media (max-width: 480px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
const FilePickerRoot = styled.div`
  width: 100%;
  @media (max-width: 480px) {
    width: fit-content;
  }
`;
const FilePickerSection = styled.div`
  width: 100%;
`;

const FilePickerEmp = styled.span`
  color: #005ec4;
  font-weight: bold;
  cursor: pointer !important;
`;

const FilePickerInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  height: 100px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  color: #757575;
  font-size: 14px;
  padding: 8px 16px;
  // background-color: inherit;
`;

const PdfPreview = styled.div`
  max-width: 208px;
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid #ececec;
  background-color: #ffffff;
  margin-top: 4px;
  &:last-child {
    margin-right: 0;
  }
`;

export const NotMobileViewOnly = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;

export const MobileViewOnly = styled.div`
  @media (min-width: 480px) {
    display: none;
    /* 1024pxのときはSPを表示する */
    @media (max-width: 480px) {
      width: 100%;
      display: flex;
      justify-content: center;
      aling-items: center;
    }
  }
`;

const SelectedPdfFiles = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 8px;
`;

const PdfLabel = styled.a`
  font-size: 14px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    text-decoration: underline;
  }
`;
const PdfRemoveButton = styled(Button)`
  width: 16px;
  height: 16px;
  background-color: transparent;
  padding: 0;
  margin-left: 8px;
`;

const PdfIconWrapper = styled.div`
  flex-shrink: 0;
`;
export default RecruitmentFormPDFPicker;
