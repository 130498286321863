import React, { useState } from "react";
import { useToast } from "../../../components/Toast";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../../components/Modal";
import { User } from "firebase/auth";
import {
  ModalWrapper,
  ModalHeader,
} from "../../../pages/pta/admin/ProfilePage";
import * as Margin from "../../../components/Margin";
import { Reauthentication } from "./Reauthentication";
import { unlinkProvider } from "src/utils/firebase";

interface Props {
  firebaseUser: User;
  isOpen: boolean;
  close: () => void;
}

export const RemovePasswordModal: React.FC<Props> = ({
  firebaseUser,
  isOpen,
  close,
}) => {
  const toast = useToast();
  const [reauthenticated, setReauthenticated] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);

  async function checkUnlinkEnabled() {
    await firebaseUser.reload();
    if (firebaseUser.providerData.length <= 1)
      throw new Error("Cannot unlink the last sign-in method");
    return;
  }

  const handleRemove = async () => {
    if (isProcessing) return;

    try {
      setIsProcessing(true);
      await checkUnlinkEnabled();
      await unlinkProvider(firebaseUser, "password");
      toast.success("パスワードログインを無効化しました");
      close();
    } catch (err) {
      toast.error("パスワードログインの無効化に失敗しました");
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={() => close()}>
          <ModalWrapper>
            <ModalHeader>パスワードログインの無効化</ModalHeader>
            {!reauthenticated ? (
              <Reauthentication
                title="パスワードログインの無効化には再認証が必要です"
                firebaseUser={firebaseUser}
                onCancel={close}
                onOk={() => setReauthenticated(true)}
              />
            ) : (
              <>
                <ModalBody>
                  <Margin.Margin marginTop={20} />
                  <div>パスワードログインを無効化してもよろしいですか？</div>
                  <Margin.Margin marginTop={40} />
                  <ModalSubmitButtons
                    disabled={isProcessing}
                    submitText="無効化"
                    onSubmit={handleRemove}
                    onCancel={close}
                  />
                </ModalBody>
              </>
            )}
          </ModalWrapper>
        </ModalPortal>
      )}
    </>
  );
};
