import { useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import * as usersApi from "src/apiClients/users";
import { QueryOptions } from "src/hooks/query/QueryOptions";

export function useUserListByOrganizationId(
  organizationId: string,
  queryOptions: QueryOptions = {}
) {
  const query = useQuery({
    queryKey: ["api", "users", "getUsersByOrganizationId", organizationId],
    queryFn: () => usersApi.getUsersByOrganizationId({ organizationId }),
    ...queryOptions,
  });

  return {
    users: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
}

export function useInvalidateUserListByOrganizationId() {
  const queryClient = useQueryClient();

  function invalidateUserListByOrganizationId(organizationId: string) {
    queryClient.invalidateQueries({
      queryKey: ["api", "users", "getUsersByOrganizationId", organizationId],
    });
  }

  return {
    invalidateUserListByOrganizationId,
  };
}
