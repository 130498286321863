import React, { FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import * as theme from "../theme";
import { ImportAction } from "./icons/SvgIcons";
import { useRecoilState } from "recoil";
import { formEmailPasswordState } from "../hooks/formEmailPassword";
import { EyeOpenIcon } from "./icons/EyeOpenIcon";
import { EyeClosedIcon } from "./icons/EyeClosedIcon";
import { Margin } from "./Margin";

const FormWrap = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;

  & input,
  select,
  span[data-file-input="true"] {
    width: 100%;
    border: ${theme.form.borderColor};
    border-radius: ${theme.form.borderRadius};
    background: ${theme.form.background.normal};
    font-size: ${theme.typo.small};
    font-weight: normal;
    line-height: 21px;
    box-sizing: border-box;
    appearance: none;
    padding: 9.5px 12px;
  }

  & span[data-file-input="true"] {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    @media (max-width: 640px) {
      padding: 12px;
    }
  }

  & input[type="file"] {
    display: none;
  }

  &[data-selector="true"]::after {
    content: "";
    width: 6px;
    height: 6px;
    border: 0px;
    border-bottom: solid 2px #b4b3b3;
    border-right: solid 2px #b4b3b3;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 24px;
    right: 12px;
    margin-top: -4px;
  }

  &:focus input,
  select {
    background: ${theme.colorsPallet.emptyShade};
  }

  &[aria-disabled="true"] input,
  select {
    background: ${theme.form.background.disabled};
  }

  &[aria-readonly="true"] input,
  select {
    background: ${theme.form.background.readonly};
  }

  & span[data-label="true"] {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  & span[data-help="true"] {
    font-size: 12px;
    margin-top: 4px;
    color: ${theme.colorTokens.textSubdued};
  }
`;

type FormProps = {
  label?: string;
  helpText?: string;
  readonly?: boolean;
};
export const TextField: FC<
  FormProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({ label, helpText, readonly, ...otherProps }) => {
  return (
    <FormWrap aria-readonly={readonly}>
      {label && <span data-label>{label}</span>}
      {(otherProps.placeholder === "電話番号" && (
        <input {...otherProps} readOnly={readonly} type="text" maxLength={11} />
      )) || <input {...otherProps} readOnly={readonly} />}
      {helpText && <span data-help>{helpText}</span>}
    </FormWrap>
  );
};

export const TextField2: FC<
  FormProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({ label, helpText, readonly, ...otherProps }) => {
  return (
    <FormWrap aria-readonly={readonly}>
      {label && <span data-label>{label}</span>}
      <input {...otherProps} readOnly={readonly} />
      {helpText && <span data-help>{helpText}</span>}
    </FormWrap>
  );
};

type ImgFieldProps = FormProps & {
  onChange?: (file: File) => void;
  className?: string;
  value?: File;
  style?: React.CSSProperties;
  accept?: string;
  children: React.ReactNode;
};
export const ImgField: FC<ImgFieldProps> = ({
  label,
  helpText,
  readonly,
  onChange,
  children,
  className,
  value,
  style,
  accept,
}) => {
  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target;
      const files = Array.from(input.files || []);
      const file = files[0];
      if (file) {
        onChange?.(file);
      }
    },
    [onChange]
  );
  const fileName = useMemo(() => {
    if (!value) return null;
    return value.name;
  }, [value]);

  const imgPreviewUrl = useMemo(() => {
    if (value) return URL.createObjectURL(value);
    else return "";
  }, [value]);

  return (
    <FormWrap aria-readonly={readonly} style={style} className={className}>
      <span data-label>{label}</span>
      <span data-file-input>
        {fileName ? null : (
          <>
            {children}
            <Margin marginTop={30} />
            <ImportAction />
          </>
        )}
        {imgPreviewUrl ? (
          <StyledImgWrapper>
            <img src={imgPreviewUrl} />
          </StyledImgWrapper>
        ) : null}
      </span>
      <input
        multiple
        type="file"
        name="image"
        readOnly={readonly}
        onChange={onInputChange}
        accept={accept}
      />
      {helpText && <span data-help>{helpText}</span>}
    </FormWrap>
  );
};

type SelectProps = FormProps & {
  options: {
    value: string;
    text: string;
  }[];
  value?: string;
};
export const SelectField: FC<
  SelectProps & React.InputHTMLAttributes<HTMLSelectElement>
> = ({ label, helpText, options, value, ...otherProps }) => {
  return (
    <FormWrap data-selector="true">
      <span data-label>{label}</span>
      <select {...otherProps} style={{ backgroundColor: "white" }}>
        {options.map((opt) => {
          return (
            <option
              key={opt.value}
              value={opt.value}
              selected={value ? opt.value === value : false}
            >
              {opt.text}
            </option>
          );
        })}
      </select>
      {helpText && <span data-help>{helpText}</span>}
    </FormWrap>
  );
};

type RadioProps = FormProps & {
  options: {
    value: string;
    text: string;
  }[];
};

export const RadioField: FC<
  RadioProps & React.InputHTMLAttributes<HTMLInputElement>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
> = ({ label, helpText, options, ...otherProps }) => {
  const [value, setValue] = React.useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  return (
    <>
      {label && <span data-label>{label}</span>}
      {options.map((item, index) => (
        <label key={index} style={{ marginRight: "10px" }}>
          <input
            type="radio"
            value={item.value}
            checked={value == `${item.value}`}
            onChange={handleChange}
          />
          {item.text}
        </label>
      ))}
      {helpText && <span data-help>{helpText}</span>}
    </>
  );
};

const _SubmitInput = styled.input`
  background-color: ${theme.colorsPallet.primary};
  color: ${theme.colorsPallet.emptyShade};
  font-size: ${theme.typo.small};
  line-height: 21px;
  padding: 5px 22px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.7;
  }
`;
export const Submit: FC<React.InputHTMLAttributes<HTMLInputElement>> = (
  props
) => {
  return <_SubmitInput {...props} type="submit" />;
};

const StyledImgWrapper = styled.div`
  img {
    width: 300px;
  }
`;

const StyledInput = styled.input`
  height: 36px;
  display: block;
  width: 100%;
  align-items: center;
  padding: 12px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid rgba(105, 112, 125, 0.2);
  color: ${theme.colorTokens.text};
`;

export const StyledInputLabel = styled.label`
  color: ${theme.colorTokens.text};
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  display: block;
  width: 100%;
  margin-bottom: 5px;
`;

export const StyledPasswordInput = styled(StyledInput)`
  position: relative;
`;

export const StyledPasswordInputWrapper = styled.div`
  position: relative;
`;

export const StyledEyeIconWrapper = styled.div`
  vertical-align: middle;
  position: absolute;
  right: 15px;
  top: 10px;
`;

export const StyledInputLabelAdditional = styled.span`
  display: inline-block;
  margin-left: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #69707d;
`;

export const PasswordInput: FC<{ isLogin: boolean }> = ({ isLogin }) => {
  const [isDisplayPassword, setIsDisplayPassword] = useState(false);
  const [formEmailPassword, setFormEmailPassword] = useRecoilState(
    formEmailPasswordState
  );
  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormEmailPassword({
      password: event.target.value,
      email: formEmailPassword.email,
      passwordConfirm: formEmailPassword.passwordConfirm,
    });
  };

  return (
    <>
      <StyledInputLabel htmlFor="password">
        パスワード
        {isLogin ? (
          ""
        ) : (
          <StyledInputLabelAdditional>
            8文字以上の半角英数字
          </StyledInputLabelAdditional>
        )}
      </StyledInputLabel>
      <StyledPasswordInputWrapper>
        <StyledPasswordInput
          type={isDisplayPassword ? "text" : "password"}
          name="password"
          id="password"
          placeholder="password"
          defaultValue={formEmailPassword.password}
          value={formEmailPassword.password}
          onChange={onChangePassword}
        />
        <StyledEyeIconWrapper
          onClick={() => setIsDisplayPassword(!isDisplayPassword)}
        >
          {isDisplayPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
        </StyledEyeIconWrapper>
      </StyledPasswordInputWrapper>
    </>
  );
};

export const PasswordConfirmInput: FC = () => {
  const [isDisplayPassword, setIsDisplayPassword] = useState(false);
  const [formEmailPassword, setFormEmailPassword] = useRecoilState(
    formEmailPasswordState
  );

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormEmailPassword({
      email: formEmailPassword.email,
      password: formEmailPassword.password,
      passwordConfirm: event.target.value,
    });
  };

  return (
    <>
      <StyledInputLabel htmlFor="confirm">パスワード再入力</StyledInputLabel>
      <StyledPasswordInputWrapper>
        <StyledPasswordInput
          type={isDisplayPassword ? "text" : "password"}
          name="confirm"
          id="confirm"
          placeholder="password confirm"
          defaultValue={formEmailPassword.passwordConfirm}
          value={formEmailPassword.passwordConfirm}
          onChange={onChangePassword}
        />
        <StyledEyeIconWrapper
          onClick={() => setIsDisplayPassword(!isDisplayPassword)}
        >
          {isDisplayPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
        </StyledEyeIconWrapper>
      </StyledPasswordInputWrapper>
    </>
  );
};

export const EmailInput: FC = () => {
  const [formEmailPassword, setFormEmailPassword] = useRecoilState(
    formEmailPasswordState
  );

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormEmailPassword({
      email: event.target.value,
      password: formEmailPassword.password,
      passwordConfirm: formEmailPassword.passwordConfirm,
    });
  };

  return (
    <>
      <StyledInputLabel htmlFor="email">メールアドレス</StyledInputLabel>
      <StyledInput
        type="text"
        name="email"
        id="email"
        placeholder="polyfit@polyfit.com"
        defaultValue={formEmailPassword.email}
        value={formEmailPassword.email}
        onChange={onChangeEmail}
      />
    </>
  );
};

export const NewEmailInput: FC = () => {
  const [formEmailPassword, setFormEmailPassword] = useRecoilState(
    formEmailPasswordState
  );

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormEmailPassword({
      email: event.target.value,
      password: formEmailPassword.password,
      passwordConfirm: formEmailPassword.passwordConfirm,
    });
  };

  return (
    <>
      <StyledInputLabel htmlFor="email">
        メールアドレス（変更後）
      </StyledInputLabel>
      <StyledInput
        type="text"
        name="email"
        id="email"
        placeholder="polyfit@polyfit.com"
        defaultValue={formEmailPassword.email}
        value={formEmailPassword.email}
        onChange={onChangeEmail}
      />
    </>
  );
};
