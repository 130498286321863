import React from "react";
import { Margin } from "../../../components/Margin";
import styled from "styled-components";
import {
  ModalPortal,
  ModalHeader,
  ModalSubmitButtons,
} from "../../../components/Modal";

interface DeleteModalProps {
  onSubmit: () => void;
  onClose: () => void;
  disabled: boolean;
}

export default function DeleteRecruitmentModal({
  onSubmit,
  onClose,
  disabled,
}: DeleteModalProps) {
  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>
        <p>下書きを削除</p>
      </ModalHeader>
      <ModalBody>
        <p>下書きを削除します。</p>
        <Margin marginBottom={10} />
      </ModalBody>
      <ModalSubmitButtons
        submitText="削除"
        submitColor="danger"
        onSubmit={onSubmit}
        onCancel={onClose}
        disabled={disabled}
      />
    </ModalPortal>
  );
}

const ModalBody = styled.div`
  min-height: 50px;
  height: fit-content;
`;
