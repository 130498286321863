import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import * as postUserGroupApi from "../../apiClients/postUserGroup";
import { PostUserGroupType } from "../api/postUserGroup";

const postUserGroupsState = atom<PostUserGroupType[] | undefined>({
  key: "postUserGroups",
  default: undefined,
});

/**
 * 連絡ユーザーグループ一覧取得
 */
export function usePostUserGroupList() {
  const [postGroups, setPostGroups] = useRecoilState(postUserGroupsState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (postGroups === undefined) {
      getPostGroups();
    } else {
      setIsLoading(false);
    }
  }, [postGroups]);

  const getPostGroups = async () => {
    const postGroups = await postUserGroupApi.getPostUserGroups();
    setPostGroups(postGroups);
    setIsLoading(false);
  };

  const getPostUserGroupLabel = useCallback(
    (target: string) => {
      if (isLoading) {
        // 空文字（""）だとTargetBadgeが縮小状態になるため
        // ローディング中はゼロ幅スペースを返す。
        return "\u200B";
      }
      return (
        postGroups?.find((postGroup) => postGroup.id === target)?.name ??
        "削除済み"
      );
    },
    [postGroups, isLoading]
  );

  return {
    postGroups: postGroups ?? [],
    isLoading,
    getPostGroups,
    getPostUserGroupLabel,
  } as const;
}
