import { useMemo } from "react";
import * as usersApi from "src/apiClients/users";
import { useQuery } from "@tanstack/react-query";

type MemberUser = usersApi.User;

export function useMemberUserById(userId: string) {
  const query = useQuery<MemberUser, Error>({
    queryKey: ["api", "users", "residentUser", "detail", userId],
    queryFn: () => usersApi.getMemberUser({ userId }),
  });

  return {
    memberUser: useMemo(() => query.data, [query.data]),
    getUser: query.refetch,
    ...query,
  };
}
