import React, { useState } from "react";
import {
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "../../components/Modal";
import {
  PostUserGroupType,
  deletePostUserGroup,
} from "../../hooks/api/postUserGroup";
import { useToast } from "../../components/Toast";
import { Margin } from "../../components/Margin";
import styled from "styled-components";
import { PulldownIcon } from "../../components/icons/PulldownIcon";
import { GroupsDropdownList } from "./GroupsDropdownList";
import { logger } from "src/utils/logger";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  postGroups: PostUserGroupType[];
  getPostGroups: () => Promise<void>;
};

export const DeleteGroupModal = ({
  isOpen,
  onClose,
  postGroups,
  getPostGroups,
}: Props) => {
  if (!isOpen) return null;
  const toast = useToast();
  const [selectedGroupId, setSelectedGroupId] = useState<string>("");
  const [isOpenedList, setIsOpenedList] = useState(false);

  const selectedGroupName =
    postGroups.find((group) => group.id === selectedGroupId)?.name || "";
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async () => {
    setDoubleClickBlocked(true);
    try {
      await deletePostUserGroup(selectedGroupId);
      await getPostGroups();
      toast.success("削除しました");
      onClose();
    } catch (error) {
      toast.error("削除に失敗しました");
      logger.error(error);
    } finally {
      setDoubleClickBlocked(false);
    }
  };
  return (
    <ModalPortal
      onClose={() => {
        onClose();
      }}
    >
      <ModalHeader>連絡グループ削除</ModalHeader>
      <p>連絡グループを削除できます。</p>
      <Margin marginTop={20} />
      <GroupSelect
        onClick={() => {
          setIsOpenedList(!isOpenedList);
        }}
      >
        {selectedGroupId !== "" ? selectedGroupName : "グループを選択"}
        <span>
          <PulldownIcon size={40} />
        </span>
      </GroupSelect>
      {isOpenedList && (
        <GroupsDropdownList
          groups={postGroups}
          selectedGroupId={selectedGroupId}
          setSelectedGroupId={setSelectedGroupId}
        />
      )}

      <ModalSubmitButtons
        submitText="削除"
        submitColor="danger"
        disabled={selectedGroupId === "" || doubleClickBlocked}
        onSubmit={onSubmit}
        onCancel={() => {
          onClose();
        }}
      />
    </ModalPortal>
  );
};

const GroupSelect = styled.div`
  width: 100%;
  padding: 10px 40px 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
  position: relative;

  span {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
