import React from "react";

export const WalkingIcon = () => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66699 3.49992C6.40033 3.49992 7.00033 2.89992 7.00033 2.16659C7.00033 1.43325 6.40033 0.833252 5.66699 0.833252C4.93366 0.833252 4.33366 1.43325 4.33366 2.16659C4.33366 2.89992 4.93366 3.49992 5.66699 3.49992ZM3.20033 5.76659L1.33366 15.1666H2.73366L3.93366 9.83325L5.33366 11.1666V15.1666H6.66699V10.1666L5.26699 8.83325L5.66699 6.83325C6.53366 7.83325 7.86699 8.49992 9.33366 8.49992V7.16659C8.06699 7.16659 7.00033 6.49992 6.46699 5.56659L5.80032 4.49992C5.42699 3.90659 4.68033 3.66659 4.03366 3.93992L0.666992 5.36659V8.49992H2.00033V6.23325L3.20033 5.76659Z"
        fill="white"
      />
    </svg>
  );
};
