import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePolyfitHistory } from "../../../hooks/router";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { useLocation } from "react-router-dom";
import {
  useUserById,
  useUserListByOrganizationId,
} from "../../../hooks/api/users";
import styled from "styled-components";
import { SelectField, TextField } from "../../../components/Form";
import { Button } from "../../../components/Button";
import {
  changeEmailByInternalAdmin,
  firebaseState,
} from "../../../apiClients/auth";
import { useFileDownload } from "../../../hooks/useFileDownload";
import * as csvAPI from "../../../apiClients/csv";
import { toInputDateFormatYearMonth } from "../../../utils/time";
import {
  UserType,
  csUserTypeOptions,
  ptaUserTypeOptions,
  userTypeOptions,
} from "../../../utils/types/userType";
import {
  Role,
  csRoleOptions,
  isCsRole,
  isPtaRole,
  ptaRoleOptions,
  roleOptions,
} from "../../../utils/types/role";
import { User, inPTA } from "../../../apiClients/users";
import { useOrganizationById } from "../../../hooks/api/internal/organizations";

type InternalUserRole = "INTERNAL_ADMIN" | "Member";

export default function OrganizationUserListPage() {
  const history = usePolyfitHistory();
  const loginUser = useCurrentUser();

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const organizationId = query.get("organizationId");
  const { organization } = useOrganizationById(organizationId ?? "");
  const { users, getUsers, loading } = useUserListByOrganizationId(
    organizationId ?? ""
  );
  const [editUser, setEditUser] = useState<User | undefined>(undefined);
  const { writeUser } = useUserById(editUser?.id ?? "");
  const [editUserEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userInternalRole, setUserInternalRole] =
    useState<InternalUserRole>("Member");
  const [userPicture, setUserPicture] = useState("");
  const [userType, setUserType] = useState<UserType>("PARENT");
  const [userRole, setUserRole] = useState<Role>(Role.PTAMember);
  const [userIsApprover, setUserIsApprover] = useState<boolean>(false);
  const [userAble, setUserAble] = useState<boolean>(false);
  const [userSignUpState, setUserSignUpState] = useState<boolean>(false);
  const [firebaseEmailVerified, setFirebaseEmailVerified] =
    useState<boolean>(false);
  const [firebaseDisabled, setFirebaseDisabled] = useState<boolean>(false);

  const onUserNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserName(e.target.value);
    },
    []
  );
  const onUserEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserEmail(e.target.value);
    },
    []
  );
  const onUserTypeChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      console.log(e.target.value as UserType);
      setUserType(e.target.value as UserType);
    },
    []
  );
  const onUserRoleChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      setUserRole(e.target.value as Role);
    },
    [userType, userRole]
  );
  const onUserIsApproverChange = useCallback((e: any) => {
    setUserIsApprover(e.target.checked);
  }, []);
  const onUserAbleChange = useCallback((e: any) => {
    setUserAble(e.target.checked);
  }, []);
  const onUserSignUpStateChange = useCallback((e: any) => {
    setUserSignUpState(e.target.checked);
  }, []);
  const [isSending, setIsSending] = useState(false);

  /** INTERNAL_ADMIN以外のユーザーのアクセスを弾く */
  useEffect(() => {
    if (typeof loginUser === "undefined" || !editUser) {
      return;
    }

    getUsers();
    setUserEmail(editUser?.email ?? "");
    setUserName(editUser?.name ?? "");
    setUserInternalRole(editUser?.internalRole);
    setUserPicture(editUser?.picture ?? "");
    setUserType(editUser?.type);
    setUserRole(editUser?.role);
    setUserIsApprover(editUser?.isApprover);
    setUserAble(editUser?.able);
    setUserSignUpState(editUser?.signUpState);

    firebaseState(editUser.id).then((res) => {
      setFirebaseEmailVerified(res.emailVerified);
      setFirebaseDisabled(res.disabled);
    });
  }, [loginUser, editUser]);

  const isDisabled = useMemo(() => {
    // UserType
    const PTAUserType = userType === "PARENT" || userType === "TEACHER";
    const CSUserType = userType === "RESIDENT";

    return (
      (PTAUserType && isCsRole(userRole)) || (CSUserType && isPtaRole(userRole))
    );
  }, [userType, userRole]);

  const onEmailSubmit = async () => {
    setIsSending(true);
    const res = await changeEmailByInternalAdmin(
      editUser?.id ?? "",
      editUserEmail
    );
    if (res.status === "success") {
      alert(
        "メールアドレスを変更しました。\nメール認証のための確認メールが送信されています。"
      );
    } else {
      alert(
        "メールアドレスの変更に失敗しました。\n" +
          res?.code +
          "\n\n" +
          res?.message
      );
    }
    setIsSending(false);
  };

  const onSubmit = async () => {
    setIsSending(true);
    try {
      if (organization) {
        await writeUser(
          {
            id: editUser?.id ?? "",
            email: editUser?.email ?? "",
            name: userName,
            internalRole: userInternalRole,
            type: userType,
            role: userRole,
            isApprover: userIsApprover,
            picture: userPicture,
            able: userAble,
            signUpState: userSignUpState,
          },
          organization.id
        );
        alert("ユーザー情報の変更に成功しました");
      }
    } catch (e) {
      alert("ユーザー情報の変更に失敗しました: " + e);
    } finally {
      setIsSending(false);
    }
  };

  const { download } = useFileDownload();

  const onDownloadCSVSubmit = async () => {
    if (!organizationId) throw new Error("organizationId is undefined");
    try {
      const blob = await csvAPI.exportCsvByInternalAdmin(organizationId);
      const date = toInputDateFormatYearMonth(new Date());
      const fileName = `【外部流出厳禁】PTA名簿_${organization?.name}_${date}.csv`;
      download(blob, fileName);
    } catch (e) {
      alert("CSVのダウンロードに失敗しました");
    }
  };

  const selectCurrentUser = useCallback(() => {
    const user = users.find((u) => u.email === loginUser?.email);
    setEditUser(user);
  }, [users, loginUser]);

  if (loginUser?.internalRole !== "INTERNAL_ADMIN") {
    return <></>;
  }

  if (loading) {
    return (
      <>
        <div>読み込み中…</div>
        <div>画面が切り替わらない場合はお問い合わせください</div>
      </>
    );
  }

  return (
    <>
      {editUser != null ? (
        <>
          <TransitionLink
            onClick={() => {
              setEditUser(undefined);
              getUsers();
            }}
          >
            ユーザー一覧に戻る
          </TransitionLink>
          <h3>ユーザーID({editUser.id})の情報の編集</h3>
          <div>
            <FormArea>
              <h4>メールアドレスの変更とメール認証確認メールの送信</h4>
              <TextField
                label="email"
                value={editUserEmail}
                onChange={onUserEmailChange}
              />
              {isSending ? (
                <Button size="large" color="text" style={{ marginLeft: "8px" }}>
                  更新する
                </Button>
              ) : (
                <Button
                  size="large"
                  color="secondary"
                  style={{ marginLeft: "8px" }}
                  onClick={onEmailSubmit}
                >
                  更新する
                </Button>
              )}
            </FormArea>
            <FormArea>
              <h4>firebaseアカウント</h4>
              <TextField
                label="メール認証(emailVerified))"
                value={firebaseEmailVerified ? "認証済み" : "未認証"}
                disabled
              />
              <TextField
                label="有効/無効(disabled)"
                value={firebaseDisabled ? "無効" : "有効"}
                disabled
              />
            </FormArea>
            <h4>{organization?.serviceType}アカウント</h4>
            <TextField
              label="name"
              value={userName}
              onChange={onUserNameChange}
            />
            <TextField
              label="internalRole(変更不可)"
              value={userInternalRole}
              disabled
            />
            <TextField label="picture" value={userPicture} disabled />
            <UserPictureImg src={userPicture} alt={userPicture} />
            <br />
            <label htmlFor="">type</label>
            <SelectField
              options={
                inPTA(editUser)
                  ? [...ptaUserTypeOptions]
                  : [...csUserTypeOptions]
              }
              onChange={onUserTypeChange}
              value={
                userTypeOptions.find((r) => r.value === editUser.type)?.value
              }
            />
            <label htmlFor="">role</label>
            <SelectField
              options={
                inPTA(editUser) ? [...ptaRoleOptions] : [...csRoleOptions]
              }
              onChange={onUserRoleChange}
              value={roleOptions.find((r) => r.value === editUser.role)?.value}
            />
            isApprover
            <input
              type="checkbox"
              checked={userIsApprover}
              onChange={onUserIsApproverChange}
            />
            <br />
            able
            <input
              type="checkbox"
              checked={userAble}
              onChange={onUserAbleChange}
            />
            <br />
            signUpState
            <input
              type="checkbox"
              checked={userSignUpState}
              onChange={onUserSignUpStateChange}
            />
            <br />
            作成日時: {editUser.createdAt}
            <br />
            更新日時: {editUser.updatedAt}
          </div>
          {isSending ? (
            <Button
              size="large"
              color="text"
              style={{ marginLeft: "8px" }}
              disabled={isDisabled}
            >
              更新する
            </Button>
          ) : (
            <Button
              size="large"
              color="secondary"
              style={{ marginLeft: "8px" }}
              onClick={onSubmit}
              disabled={isDisabled}
            >
              更新する
            </Button>
          )}
        </>
      ) : (
        <>
          <h2>
            {organization?.name}
            のユーザー一覧
          </h2>{" "}
          <a
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push({
                to: "INTERNAL_ORGANIZATION_LIST",
              });
            }}
          >
            組織一覧へ戻る
          </a>{" "}
          <a
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push({
                to: "INTERNAL_ORGANIZATION_INVITATION",
                query: { organizationId: organizationId ?? "" },
              });
            }}
            rel="noreferrer"
          >
            招待リンク
          </a>{" "}
          <a
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push({
                to: "INTERNAL_ORGANIZATION_EDIT",
                query: { organizationId: organizationId ?? "" },
              });
            }}
            rel="noreferrer"
          >
            組織情報編集
          </a>{" "}
          <a
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push({
                to: "INTERNAL_ORGANIZATION_COMMUNITY_LIST",
                query: { organizationId: organizationId ?? "" },
              });
            }}
            rel="noreferrer"
          >
            所属するコミュニティ一覧
          </a>
          {organizationId === loginUser.account?.organizationId && (
            <>
              {" "}
              <a
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={selectCurrentUser}
                rel="noreferrer"
              >
                現在のユーザーを編集
              </a>
            </>
          )}
          <table>
            <tr>
              <td>ユーザーID</td>
              <td>メールアドレス</td>
              <td>名前</td>
              <td>internalRole</td>
              <td>連絡の承認権</td>
              <td>type</td>
              <td>role</td>
              <td>作成日時</td>
              <td>更新日時</td>
              <td>操作</td>
            </tr>
            {users.map((user, i) => {
              return (
                <tr
                  key={i}
                  style={i % 2 === 0 ? { background: "white" } : undefined}
                >
                  <td>{user.id}</td>
                  <td>{user.email}</td>
                  <td>{user.name}</td>
                  <td>{user.internalRole}</td>
                  <td>{`${user.isApprover}`}</td>
                  <td>{user.type}</td>
                  <td>{user.role}</td>
                  <td>{user.createdAt}</td>
                  <td>{user.updatedAt}</td>
                  <td>
                    {/* edit押下するとそのUserの編集ページへ遷移する */}
                    <TransitionLink
                      onClick={() => {
                        setEditUser(user);
                      }}
                    >
                      edit
                    </TransitionLink>
                  </td>
                </tr>
              );
            })}
          </table>
          <Button
            size="large"
            color="secondary"
            style={{ marginLeft: "8px" }}
            onClick={onDownloadCSVSubmit}
          >
            CSVダウンロード
          </Button>
          {users.length === 0 && (
            <>
              <br />
              <div style={{ color: "red" }}>
                招待リンクから管理者ユーザーを登録してください！
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

const FormArea = styled.div`
  margin-top: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 16px;

  h4 {
    margin-bottom: 8px;
  }
`;

const TransitionLink = styled.a`
  text-decoration-line: underline;
  &:hover {
    cursor: pointer;
  }
`;

const UserPictureImg = styled.img`
  width: 100px;
`;
