import styled from "styled-components";
import * as theme from "../theme";

export const Heading1 = styled.h1`
  font-size: ${theme.typo.heading1};
  line-height: ${theme.lineHeight.heading1};
`;

export const Heading2 = styled.h2`
  font-size: ${theme.typo.heading2};
  line-height: ${theme.lineHeight.heading2};
`;

export const Heading3 = styled.h3`
  font-size: ${theme.typo.heading3};
  line-height: ${theme.lineHeight.heading3};
`;

export const Heading4 = styled.h4`
  font-size: ${theme.typo.heading4};
  line-height: ${theme.lineHeight.heading4};
`;

export const Heading5 = styled.h5`
  font-size: ${theme.typo.heading5};
  line-height: ${theme.lineHeight.heading5};
`;

export const Heading6 = styled.h6`
  font-size: ${theme.typo.heading6};
  line-height: ${theme.lineHeight.heading6};
`;

export const Paragraph = styled.p`
  font-size: ${theme.typo.paragraph};
  line-height: ${theme.lineHeight.paragraph};
`;

export const Small = styled.small`
  font-size: ${theme.typo.small};
  line-height: ${theme.lineHeight.small};
`;

export const Code = styled.code`
  font-size: ${theme.typo.code};
  line-height: ${theme.typo.code};
`;

export const Subdued = styled.div`
  color: ${theme.colorTokens.textSubdued};
`;

export const SubduedSpan = styled.span`
  color: ${theme.colorTokens.textSubdued};
`;

export const CautionSpan = styled.span`
  color: ${theme.colorTokens.dangerText};
`;
