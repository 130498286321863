import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Margin } from "../../../components/Margin";
import {
  ModalPortal,
  ModalPortalProps,
  ModalSubmitButtons,
} from "../../../components/Modal";
import { useCareer } from "../../../hooks/api/career";
import { useCurrentUser } from "../../../hooks/recoil/user";
import {
  SelectWrapper,
  TextChildField,
} from "../../../pages/registration/pta/RegisterChildInfoPage";
import * as Typo from "../../../components/Typo";
import { useToast } from "../../../components/Toast";
import { ModalHeader } from "../../../pages/pta/admin/ProfilePage";
import { PulldownIcon } from "../../../components/icons/PulldownIcon";
import BelongsDropDownList from "./BelongsDropdownList";
import PartsDropdownList from "./PartsDropdownList";
import * as theme from "../../../theme";
import { CalenderUI } from "../../../components/CalenderUI";
import { CalenderIcon } from "../../../components/icons/CalenderIcon";
import {
  InputYearFieldWrapper,
  InputYearField,
  CalenderIconWrapper,
  InputPTANameWrapper,
  PTAText,
} from "./CareerInfoEditModal";
import { SelectChildDropdown } from "./SelectChildDropdown";
import { Child } from "../../../hooks/api/child";
import { SelectHighestPartDropdown } from "./SelectHighestPartDropdown";
import { getSchoolYear } from "../../../utils/getSchoolYear";
import { useOrganization } from "../../../hooks/recoil/organization";
import { APIError } from "src/utils/types/ApiError";
import { sortByOrder } from "src/utils/sortByOrder";

export type FormValues = {
  id: string | null;
  userId: string | null;
  childId: string | null;
  partId: string | null;
  organizationId: string | null;
  belongId: string | null;
  startDate: string;
  endDate: string | null;
  isGraduatedChild: boolean;
  isHighestPart: boolean;
};

// 所属・役職のセレクトボックスの値
export type SelectBelongPartType = {
  id?: string;
  name?: string;
  isNone: boolean;
};

const CareerCreateModal = ({
  organizationName,
  userChildren: children,
  isOpen,
  onClose,
  userId,
}: {
  organizationName: string;
  userChildren: Child[];
  isOpen: boolean;
  onClose: () => void;
  userId?: string;
} & ModalPortalProps) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const { organization } = useOrganization({});
  const [selectedBelong, setSelectedBelong] = useState<SelectBelongPartType>(
    {} as SelectBelongPartType
  );
  const isSelectedBelongEmpty = Object.keys(selectedBelong).length === 0;
  const [selectedPart, setSelectedPart] = useState<SelectBelongPartType>(
    {} as SelectBelongPartType
  );
  const isSelectedPartEmpty = Object.keys(selectedPart).length === 0;
  const [isShowBelongDropdown, setIsShowBelongDropdown] =
    useState<boolean>(false);
  const [isShowPartDropdown, setIsShowPartDropdown] = useState<boolean>(false);

  const [_, setSelectSchool] = useState<{
    id: string;
    name: string;
    schoolCode: string;
    address: string;
    postalCode: string;
  }>();

  const {
    1: { fetchCareerList, createCareer },
  } = useCareer(currentUser.id);

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);

  const onSubmit = async (data: FormValues) => {
    setDoubleClickBlocked(true);
    try {
      await createCareer(
        userId ?? currentUser.id,
        data.childId ?? "",
        data.isGraduatedChild ?? !data.childId,
        data.isHighestPart ?? false,
        organization.id ?? "",
        data.belongId ?? "",
        data.partId ?? "",
        data.startDate,
        data.endDate ?? ""
      );
      await fetchCareerList();
      onClose();
      toast.success("保存しました");
    } catch (err: unknown) {
      if (err instanceof APIError) {
        toast.error(err.message);
      } else {
        toast.error("保存に失敗しました");
      }
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  useEffect(() => {
    setSelectSchool({
      id: "",
      name: organizationName,
      schoolCode: "",
      address: "",
      postalCode: "",
    });
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      isGraduatedChild: false,
    },
    mode: "onBlur",
    shouldUnregister: false,
  });

  const [showYearList, setShowYearList] = useState(false);
  const [showEndYearList, setShowEndYearList] = useState(false);
  const [thisYear, setThisYear] = useState(new Date().getFullYear());

  const changeYearValue = (value: string) => {
    setValue("startDate", value);
    setShowYearList(false);
  };
  const changeEndYearValue = (value: string) => {
    setValue("endDate", value);
    setShowEndYearList(false);
  };

  useEffect(() => {
    const selectedStartDate = watch("startDate");
    const selectedEndDate = watch("endDate");

    if (
      selectedEndDate &&
      (new Date(selectedStartDate) > new Date(selectedEndDate) ||
        new Date(selectedStartDate).getFullYear() ===
          new Date(selectedEndDate).getFullYear())
    ) {
      setError("endDate", {
        type: "custom",
        message: "終了日が不正です。",
      });
    } else clearErrors("endDate");
  }, [watch("startDate"), watch("endDate")]);

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { childId, isGraduatedChild, isHighestPart } = getValues();

    const childIdIsInvalid = !childId && !isGraduatedChild;
    const isHighestPartIsInvalid =
      isHighestPart === undefined || isHighestPart === null;

    const invalid = childIdIsInvalid || isHighestPartIsInvalid;

    if (childIdIsInvalid) {
      setError("childId", {
        type: "custom",
        message: "子供名もしくは卒業済みを選択してください",
      });
    }

    if (isHighestPartIsInvalid) {
      setError("isHighestPart", {
        type: "custom",
        message: "子供の最高役職かどうかを選択してください",
      });
    }

    if (invalid) return;

    handleSubmit(onSubmit)();
  };

  const sortedBelongs = sortByOrder(organization.belongs);

  const sortedParts = sortByOrder(organization.parts);

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={onClose} modalBoxIsHeightFull={true}>
          <StyledModalForm onSubmit={submit}>
            <CareerStyledContainer>
              <ModalHeader>経歴を追加</ModalHeader>
              <SelectWrapper>
                <FormLabel>組織</FormLabel>
                <InputPTANameWrapper>
                  <TextChildField
                    placeholder="組織名"
                    value={organizationName}
                    disabled
                  />
                  <PTAText isDisabled>PTA</PTAText>
                </InputPTANameWrapper>
                <Margin marginTop={16} />
              </SelectWrapper>
              <SelectWrapper>
                <FormLabel>所属</FormLabel>
                <Margin marginBottom={8} />
                <BelongPartSelect
                  isEmpty={isSelectedBelongEmpty}
                  isOpen={isShowBelongDropdown}
                  onClick={() => {
                    if (isShowPartDropdown) setIsShowPartDropdown(false);
                    setIsShowBelongDropdown(!isShowBelongDropdown);
                  }}
                >
                  {isSelectedBelongEmpty
                    ? "選択してください"
                    : selectedBelong.name}
                  <span>
                    <PulldownIcon size={40} />
                  </span>
                </BelongPartSelect>
                <BelongsDropDownList
                  belongs={sortedBelongs}
                  isOpen={isShowBelongDropdown}
                  onClose={() => setIsShowBelongDropdown(false)}
                  selectedBelong={selectedBelong}
                  setSelectedBelong={setSelectedBelong}
                  setValue={setValue}
                />

                {errors?.belongId != null ? (
                  <span className="error-message">
                    {errors?.belongId.message}
                  </span>
                ) : (
                  <Margin marginTop={16} />
                )}
              </SelectWrapper>
              <SelectWrapper>
                <FormLabel>役職</FormLabel>
                <Margin marginBottom={8} />
                <BelongPartSelect
                  isOpen={isShowPartDropdown}
                  isEmpty={isSelectedPartEmpty}
                  onClick={() => {
                    if (isShowBelongDropdown) setIsShowBelongDropdown(false);
                    setIsShowPartDropdown(!isShowPartDropdown);
                  }}
                >
                  {isSelectedPartEmpty ? "選択してください" : selectedPart.name}
                  <span>
                    <PulldownIcon size={40} />
                  </span>
                </BelongPartSelect>
                <PartsDropdownList
                  parts={sortedParts}
                  isOpen={isShowPartDropdown}
                  onClose={() => setIsShowPartDropdown(false)}
                  selectedPart={selectedPart}
                  setSelectedPart={setSelectedPart}
                  setValue={setValue}
                />

                {errors?.partId != null ? (
                  <span className="error-message">
                    {errors?.partId.message}
                  </span>
                ) : (
                  <Margin marginTop={16} />
                )}
              </SelectWrapper>

              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  gap: "16px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <FormLabel>開始</FormLabel>
                  <input type="hidden" {...register("startDate")} />

                  <InputYearFieldWrapper>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                    </CalenderIconWrapper>
                    <InputYearField
                      placeholder="選択してください"
                      value={watch("startDate")!}
                      onClick={() => {
                        setShowYearList(!showYearList);
                        if (showEndYearList) return setShowEndYearList(false);
                      }}
                    />
                  </InputYearFieldWrapper>
                  <YearSample>
                    注：今年度は
                    {getSchoolYear(new Date())}
                    とえらぶ
                  </YearSample>
                  {showYearList && (
                    <CalenderUI
                      thisYear={thisYear}
                      setThisYear={setThisYear}
                      onChangeYear={changeYearValue}
                    />
                  )}
                  {errors?.startDate != null ? (
                    <span className="error-message">
                      {errors?.startDate?.message}
                    </span>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </div>

                <div style={{ width: "100%" }}>
                  <FormLabel>終了</FormLabel>
                  <InputYearFieldWrapper>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                    </CalenderIconWrapper>

                    <InputYearField
                      placeholder="選択してください"
                      value={watch("endDate")!}
                      onClick={() => {
                        setShowEndYearList(!showEndYearList);
                        if (showYearList) return setShowYearList(false);
                      }}
                    />
                  </InputYearFieldWrapper>
                  <YearSample>
                    注：今年度は
                    {getSchoolYear(new Date()) + 1}
                    とえらぶ
                  </YearSample>
                  {showEndYearList && (
                    <CalenderUI
                      thisYear={thisYear}
                      setThisYear={setThisYear}
                      onChangeYear={changeEndYearValue}
                    />
                  )}
                  {errors != null && errors?.endDate != null ? (
                    <span className="error-message">
                      {errors?.endDate?.message}
                    </span>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </div>
              </div>

              <SelectWrapper>
                <FormLabel>この経歴に紐つくお子様</FormLabel>
                <Margin marginBottom={8} />
                <SelectChildDropdown
                  childItems={[
                    ...children.map((child) => {
                      return {
                        id: child.id ?? null,
                        name: `${child.childLastName} ${child.childFirstName}`,
                      };
                    }),
                    { id: null, name: "卒業済み" },
                  ]}
                  formValues={watch()}
                  setFormValue={setValue}
                  defaultValue={null}
                  clearErrors={clearErrors}
                />

                {errors?.childId != null && (
                  <span className="error-message">
                    {errors?.childId.message}
                  </span>
                )}
                <Margin marginTop={16} />
              </SelectWrapper>

              <SelectWrapper>
                <FormLabel>
                  上記の役職はこのお子様に紐つく最高役職ですか？
                </FormLabel>
                <Margin marginBottom={8} />
                <SelectHighestPartDropdown
                  formValues={watch()}
                  setFormValues={setValue}
                  defaultValue={null}
                  clearErrors={clearErrors}
                />

                {errors?.isHighestPart != null && (
                  <span className="error-message">
                    {errors?.isHighestPart.message}
                  </span>
                )}
              </SelectWrapper>

              <CareerButtonUIArea>
                <div style={{ marginLeft: "auto" }}>
                  <ModalSubmitButtons
                    submitText="追加"
                    onCancel={onClose}
                    disabled={doubleClickBlocked}
                  />
                </div>
              </CareerButtonUIArea>
            </CareerStyledContainer>
          </StyledModalForm>
        </ModalPortal>
      )}
    </>
  );
};

export const StyledModalForm = styled.form`
  max-height: 800px;
  overflow: scroll;
  width: 100%;
`;

export const FormLabel = styled(Typo.Heading5)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #1a1c21;
`;

export const CareerButtonUIArea = styled.div`
  display: flex;
`;

const CareerStyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  /* padding-bottom: 88px; */
  .error-message {
    font-size: 12px;
    color: #bd271e;
  }
`;

export const PastCareerWrap = styled.div`
  margin-top: 18px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #e4e6f3;
  padding: 20px;
`;

export const CurrentBadge = styled.div`
  background-color: #79aad9;
  width: 40px;
  height: 20px;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  border-radius: 5px;
  margin: 2px 0 0 10px;
`;

export const CareerTerm = styled.div`
  font-weight: normal;
  color: #69707d;
  text-align: left;
  padding-right: 16px;
`;

export const PastCareerCell = styled.th`
  display: flex;
`;

export const PastCareerSmallCell = styled.th`
  display: flex;
  font-size: 12px;
`;

export const PastCareerParentCell = styled.th`
  display: flex;
  padding-bottom: 70px;
`;

export const PastCareerHeading = styled.div`
  padding-bottom: 14px;
`;

export const YearSample = styled.p`
  font-size: 12px;
  color: gray;
  margin-left: 12px;
`;

export const BelongPartSelect = styled.div<{
  isOpen: boolean;
  isEmpty: boolean;
}>`
  width: 100%;
  padding: 10px 40px 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
  position: relative;

  ${(props) =>
    props.isOpen && `border-bottom: 2px solid ${theme.colorsPallet.primary};`}

  ${(props) =>
    props.isEmpty &&
    `
      color: #aab4c4;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    `}

  span {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export default CareerCreateModal;
