import React from "react";
import { useEffect, useState } from "react";
import { throttle } from "src/utils/throttle";
import styled, { keyframes } from "styled-components";

export const useNewMessageNotification = ({
  messageListRef,
}: {
  messageListRef: React.RefObject<HTMLDivElement>;
}) => {
  const [newPartnerMessage, setNewPartnerMessage] = useState("");

  const scrollToBottom = () => {
    if (!messageListRef.current) return;
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  };

  // 新着メッセージ通知をクリックしたら最下部にスクロール
  const onClickNewMessageNotification = () => {
    scrollToBottom();
    setNewPartnerMessage("");
  };

  useEffect(() => {
    (async () => {
      // bottomまでスクロール時に新着メッセージ通知を非表示にする
      const onScroll = () => {
        throttle(() => {
          const chatMessagesElement = messageListRef.current;
          if (!chatMessagesElement) return;

          const isAtBottom =
            chatMessagesElement.scrollHeight - chatMessagesElement.scrollTop <=
            chatMessagesElement.clientHeight + 10; // 判定に10ピクセルの遊びを設ける
          if (isAtBottom) {
            setNewPartnerMessage("");
          }
        }, 500)();
      };
      const messageListElement = messageListRef.current;
      messageListElement?.addEventListener("scroll", onScroll);

      return () => {
        messageListElement?.removeEventListener("scroll", onScroll);
      };
    })();
  }, [messageListRef.current]);

  const NewMessageComponent = React.memo(() => (
    <>
      {newPartnerMessage.length > 0 && (
        <NewMessageNotification
          isVisible={newPartnerMessage.length > 0}
          onClick={onClickNewMessageNotification}
        >
          {newPartnerMessage}
        </NewMessageNotification>
      )}
    </>
  ));

  return {
    newPartnerMessage,
    setNewPartnerMessage,
    NewMessageNotification: NewMessageComponent,
  };
};

const slideIn = keyframes`
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
`;

const slideOut = keyframes`
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
`;

const NewMessageNotification = styled.div<{ isVisible: boolean }>`
  width: 100%;
  height: 40px;
  transform-origin: bottom;
  overflow: hidden;
  animation: ${(props) => (props.isVisible ? slideIn : slideOut)} 100ms ease-out
    forwards;
  display: -webkit-box;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  color: #fff;
  background: rgba(105, 112, 125, 0.6);
  padding: 0 24px;
  margin-top: -40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  cursor: pointer;
`;
