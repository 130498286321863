import React, { useCallback, useMemo } from "react";
import {
  GoogleMap,
  Libraries,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import { UseFormClearErrors, UseFormSetValue } from "react-hook-form";
import { RecruitmentFormValue } from "./useRecruitmentForm";
import { logger } from "src/utils/logger";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const apiKey = import.meta.env.VITE_GOOGLE_MAP_API_KEY;

interface Props {
  setValue: UseFormSetValue<RecruitmentFormValue>;
  clearErrors: UseFormClearErrors<RecruitmentFormValue>;
  lat: number;
  lng: number;
}

const libraries: Libraries = ["places"];

export default function GoogleMapWithLocation({
  setValue,
  clearErrors,
  lat,
  lng,
}: Props) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const markerPosition = useMemo(() => ({ lat, lng }), [lat, lng]);

  // @ts-expect-error todo
  const handleOnMarkerDragEnd = useCallback((event) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      { location: { lat: event.latLng.lat(), lng: event.latLng.lng() } },
      (results, status) => {
        if (status === "OK") {
          if (results?.[0]) {
            const res = extractAddress(
              results[0].address_components.reverse(),
              results[0].formatted_address
            );
            setValue("postalCode", res.postalCode);
            if (res.postalCode !== "") {
              clearErrors("postalCode");
            }
            setValue("prefecture", res.prefecture);
            setValue("city", res.city);
            if (res.city !== "") {
              clearErrors("city");
            }
            setValue("address1", res.address1);
            if (res.address1 !== "") {
              clearErrors("address1");
            }
            setValue("address2", "");
            setValue("latitude", event.latLng.lat());
            setValue("longitude", event.latLng.lng());
          } else {
            console.log("住所が見つかりませんでした");
          }
        } else {
          logger.error("Geocoder failed due to: " + status);
        }
      }
    );
  }, []);

  const extractAddress = useCallback(
    (
      addressComponents: google.maps.GeocoderAddressComponent[],
      formattedAddress: string
    ) => {
      try {
        const formattedAddressObject = {
          postalCode:
            addressComponents
              .find((c) => c.types.includes("postal_code"))
              ?.long_name.replace("-", "") ?? "",
          prefecture:
            addressComponents.find((c) =>
              c.types.includes("administrative_area_level_1")
            )?.long_name ?? "",
          city:
            addressComponents.find((c) => c.types.includes("locality"))
              ?.long_name ?? "",
          address1:
            addressComponents
              .filter(
                (c) =>
                  c.types.includes("sublocality_level_1") ||
                  c.types.includes("sublocality")
              )
              ?.map((c) => c.long_name)
              .join("") ?? "",
          address2: "",
        };

        formattedAddressObject.address1 =
          formattedAddressObject.address1 +
          formattedAddress
            .split(formattedAddressObject.address1)[1]
            .split(" ")[0];

        formattedAddressObject.address2 =
          formattedAddress
            .split(formattedAddressObject.address1)[1]
            .split(" ")
            .slice(1)
            .join(" ") ?? "";

        return formattedAddressObject;
      } catch (error) {
        logger.error(new Error(`Error in reverse geocoding:` + error));
        throw error;
      }
    },
    []
  );

  if (!isLoaded || loadError) {
    return <></>;
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={markerPosition}
      zoom={14}
    >
      <MarkerF
        position={markerPosition}
        draggable={true}
        onDragEnd={handleOnMarkerDragEnd}
      />
    </GoogleMap>
  );
}
