import React from "react";
import styled from "styled-components";
import { MailIcon } from "../../components/icons/MailIcon";
import { ThunderIcon } from "../../components/icons/SvgIcons";
import { PostTargetsModal } from "./PostTargetsModal";
import { useDeliverySummary } from "./useDeliverySummary";

interface Props {
  postId: string;
}

export const DeliverySummary = ({ postId }: Props) => {
  const {
    isLoading,
    targetUsers,
    unreadUsers,
    undeliveredUsers,
    isOpenUnreadUsersModal,
    isOpenUndeliveredUsersModal,
    setIsOpenUnreadUsersModal,
    setIsOpenUndeliveredUsersModal,
    onDownloadCSV,
    onResend,
    post,
  } = useDeliverySummary(postId);

  const onOpenUnreadUsersModal = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setIsOpenUnreadUsersModal(true);
  };

  const onOpenBounceUsersModal = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setIsOpenUndeliveredUsersModal(true);
  };

  if (isLoading) {
    return (
      <DeliverySummaryWrapper>
        <IconWrapper>
          <MailIcon />
        </IconWrapper>
        <Label>未開封人数:</Label>
        <Loading />
        <Divider />
        <IconWrapper>
          <ThunderIcon />
        </IconWrapper>
        <Label>不通人数:</Label>
        <Loading />
      </DeliverySummaryWrapper>
    );
  }

  if (!post) return null;

  return (
    <>
      <DeliverySummaryWrapper>
        <IconWrapper>
          <MailIcon />
        </IconWrapper>
        <Label>未開封人数:</Label>
        <LinkCount onClick={onOpenUnreadUsersModal}>
          {unreadUsers.length}
        </LinkCount>
        / <Count>{targetUsers.length}</Count>
        <Divider />
        <IconWrapper>
          <ThunderIcon />
        </IconWrapper>
        <Label>不通人数:</Label>
        <LinkCount onClick={onOpenBounceUsersModal}>
          {undeliveredUsers.length}
        </LinkCount>
        / <Count>{targetUsers.length}</Count>
      </DeliverySummaryWrapper>
      {isOpenUnreadUsersModal && (
        <PostTargetsModal
          headerText="未開封の方"
          descriptionText="弊社のシステムで検知したメールを開封していない対象者の方です。"
          submitText="再送"
          onCancel={() => setIsOpenUnreadUsersModal(false)}
          postTargetUsers={unreadUsers}
          onSubmit={onResend}
        />
      )}
      {isOpenUndeliveredUsersModal && (
        <PostTargetsModal
          headerText="配信不能の方"
          descriptionText="メールの受信者の都合により、メールが届かなかった方の一覧です。メールアドレスを変更いただくように個別に連絡しましょう。"
          submitText="CSV出力"
          onCancel={() => setIsOpenUndeliveredUsersModal(false)}
          postTargetUsers={undeliveredUsers}
          onSubmit={onDownloadCSV}
        />
      )}
    </>
  );
};

const DeliverySummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  margin-right: 4px;
  line-height: 17px;
`;

const Divider = styled.div`
  ::after {
    content: "|";
    padding: 0 8px;
    vertical-align: middle;
  }
`;

const Loading = styled.div`
  height: 20px;
  ::after {
    content: "- / -";
    padding: 0 3px;
    vertical-align: middle;
  }
`;

const Count = styled.span`
  padding: 0 2px;
`;

const LinkCount = styled.span`
  margin-bottom: 1px;
  padding: 0 2px;
  font-weight: 600;
  color: #0077cc;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  font-size: 17px;
  cursor: pointer;
`;
