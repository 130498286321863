import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getBESchoolOptions } from "src/apiClients/boardEducation/recruitment";
import { useApiContext } from "src/apiClients/client";

export const useBESchoolOptions = () => {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: [
      "api",
      "boardEducation",
      "recruitment",
      "schoolOptions",
      apiContext,
    ],
    queryFn: async () => await getBESchoolOptions(),
  });

  return {
    schoolOptions: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
};
