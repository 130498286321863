import React, { memo } from "react";
import { SidebarMenu } from "./SidebarMenu";
import { useLocation } from "react-router-dom";
import { MessageIcon } from "../../icons/MessageIcon";

export const CSGuestSidebar = memo(() => {
  const { pathname } = useLocation();

  return (
    <>
      <SidebarMenu
        to="/resident/chat/recruitment"
        isActive={pathname.startsWith("/resident/chat/recruitment")}
        icon={<MessageIcon />}
        text="メッセージ"
      />
    </>
  );
});
