import React from "react";
import { useDraftRecruitment } from "../../../hooks/api/recruitment";
import { usePolyfitHistory } from "../../../hooks/router";
import styled from "styled-components";
import { NotFoundRecruitment } from "../../../features/Recruitment/recruitment/NotFoundRecruitment";
import { RecruitmentCard } from "../../../features/Recruitment/recruitment/RecruitmentCard";
import { Header } from "../../../components/Header";

export default function ResidentRecruitmentDraftListPage() {
  const { recruitments } = useDraftRecruitment();
  const history = usePolyfitHistory();

  return (
    <>
      <Header title="下書き一覧" />

      {recruitments?.length === 0 ? (
        <NotFoundRecruitment
          title="下書き中はありません"
          description="下書きが作成されるとこちらの画面に表示されます"
        />
      ) : (
        <CardContainer>
          {recruitments?.map((recruitment, i) => (
            <RecruitmentCard
              key={recruitment.id}
              recruitment={recruitment}
              primaryButtonLabel="この下書きから作成"
              onClickPrimaryButton={() =>
                history.push({
                  to: "RESIDENT_RECRUITMENT_EDIT_DRAFT",
                  query: { id: recruitment.id },
                })
              }
            />
          ))}
        </CardContainer>
      )}
    </>
  );
}

const CardContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 24px;
  margin: 2em auto;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
