import React, { useCallback, useMemo, useRef, useState } from "react";
import { isResidentUser } from "../../../apiClients/users";
import {
  BaseInfo,
  updateResidentInfoByBEAdmin,
} from "../../../apiClients/auth";
import { usePolyfitHistory } from "../../../hooks/router";
import { Child, ChildClass } from "../../../apiClients/child";
import {
  PaidType,
  VolunteerType,
  paidOption,
  volunteerOption,
} from "../../../@shared/types/volunteerType";
import {
  DayOfWeekType,
  dayOfWeekOptions,
} from "../../../utils/types/dayOfWeek";
import {
  MedicalLicenseType,
  SkillType,
  TeachingLicenseType,
  medicalLicenseOptions,
  skillOptions,
  teachingLicenseOptions,
} from "../../../utils/types/license";
import { useForm } from "react-hook-form";
import { toInputDateFormatYearMonth } from "../../../utils/time";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../../components/Modal";
import { getAddressFromPostalCode } from "../../../apiClients/postcode";
import styled from "styled-components";
import { Heading3 } from "../../../components/Typo";
import { Margin } from "../../../components/Margin";
import { ButtonGroup } from "../../../components/Button";
import { ButtonSelect } from "../../../components/ButtonSelect";
import { prefectureOptions } from "../../../utils/types/prefecture";
import { genderOption } from "../../../utils/types/gender";
import { CameraIcon } from "../../../components/icons/CameraIcon";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import { useRegisterInfo } from "../../../hooks/api/baseInfo";
import { ageGroup, zTransformAgeGroup } from "../../../utils/types/ageGroup";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { FormValue as CommunityFormValue } from "src/features/Member/Community/utils/form";
import { UserType } from "../../../../../server/src/@shared/types/userType";
import { zodResolver } from "@hookform/resolvers/zod";
import { CSAdminFormValueSchema } from "src/utils/zod/CSBaseInfoSchema";
import { useInvalidateGetResidentUsers } from "src/hooks/query/residentUsers";
import { BEEditBaseInfoCommunity } from "./BEEditBaseInfoCommunity";
import { ResidentUserDetail } from "@shared/types/boardEducation/residentUser";
import { User } from "@shared/types/user";
import { logger } from "src/utils/logger";
import { useInvalidateBEResidentUsers } from "src/hooks/query/boardEducation/users/useBEResidentUsers";
import { updateCommunityRoles } from "src/apiClients/boardEducation/auth";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

type EditBaseInfoModalProps = {
  member: ResidentUserDetail;
  onClose: () => void;
  getUser: () => Promise<void>;
  accountId: string;
  communityId: string;
};

// EditBaseInfoModalFormValues の型
// TODO: childrenとteacherInfoは削除したほうがいいが修正箇所が多いため後回し
export type EditBaseInfoModalFormValues = {
  baseInfo: BaseInfo;
  children: Child[];
  teacherInfo: {
    hasHomeroom: boolean;
    grade?: number;
    class?: ChildClass;
  };
  residentInfo: {
    preferredDays: DayOfWeekType[];
    teacherLicenses: TeachingLicenseType[];
    medicalLicenses: MedicalLicenseType[];
    skills: SkillType[];
    preferredPaid?: "EITHER" | PaidType | null;
    preferredVolunteerType: VolunteerType[];
    privateEnterprise: string | null;
    university: string | null;
    externalOrganization: string | null;
    pta: string | null;
  };
  community: CommunityFormValue;
};

export const BEEditBaseInfoModal = ({
  member,
  onClose,
  getUser,
  accountId,
  communityId,
}: EditBaseInfoModalProps) => {
  const [faceImg, setFaceImg] = useState<File>();
  const { registerInfo, registerFaceImgPathForBE } = useRegisterInfo();
  const history = usePolyfitHistory();
  const toast = useToast();
  const { invalidateGetResidentUsers } = useInvalidateGetResidentUsers();

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const { invalidateBEResidentUsers } = useInvalidateBEResidentUsers();
  const onSubmit = async (formData: EditBaseInfoModalFormValues) => {
    setDoubleClickBlocked(true);
    try {
      const { baseInfo, residentInfo, community } = formData;
      await onSubmitFile();
      await registerInfo(baseInfo);

      await updateResidentInfoByBEAdmin(accountId, {
        ...residentInfo,
        preferredPaid:
          residentInfo.preferredPaid === "EITHER"
            ? ["PAID", "FREE"]
            : residentInfo.preferredPaid === null
            ? []
            : ([residentInfo.preferredPaid] as PaidType[]),
        privateEnterprise: residentInfo.privateEnterprise || null,
        university: residentInfo.university || null,
        externalOrganization: residentInfo.externalOrganization || null,
        pta: residentInfo.pta || null,
      });
      invalidateGetResidentUsers();
      const data = [];
      data.push(
        ...(community.mainElementarySchool
          ? [
              {
                communityId: community.mainElementarySchool,
                isMain: true,
                type: community.mainElementarySchoolType,
              },
            ]
          : []),
        ...(community.mainJuniorHighSchool
          ? [
              {
                communityId: community.mainJuniorHighSchool,
                isMain: true,
                type: community.mainJuniorHighSchoolType,
              },
            ]
          : []),
        ...community.elementarySchools.map((school) => {
          return {
            communityId: school,
            isMain: false,
            type: UserType.RESIDENT,
          };
        }),
        ...community.juniorHighSchools.map((school) => {
          return {
            communityId: school,
            isMain: false,
            type: UserType.RESIDENT,
          };
        })
      );
      const update = { accountId, data };
      await updateCommunityRoles(update);
      await getUser();
      invalidateBEResidentUsers();

      history.push({
        to: "BE_ADMIN_MEMBER_DETAIL",
        query: {
          accountId: accountId,
          communityId: communityId,
        },
      });
      setDoubleClickBlocked(false);
      onClose();
    } catch (err) {
      setDoubleClickBlocked(false);
      logger.error(err);
      if (err instanceof APIError) {
        toast.error(err.message);
      }
    }
  };

  const user: User = member.user;

  const zodSchema = CSAdminFormValueSchema;

  const {
    setValue,
    register,
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<EditBaseInfoModalFormValues>({
    defaultValues: {
      baseInfo: {
        ...user.baseInfo,
        ageGroup: user.baseInfo?.ageGroup ?? -1,
        birthday: user.baseInfo?.birthday
          ? (toInputDateFormatYearMonth(user.baseInfo.birthday) as any)
          : undefined,
      },
      residentInfo:
        isResidentUser(user) && !!user.residentInfo
          ? {
              preferredDays: user.residentInfo.preferredDays,
              teacherLicenses: user.residentInfo.teacherLicenses,
              medicalLicenses: user.residentInfo.medicalLicenses,
              skills: user.residentInfo.skills,
              preferredPaid:
                user.residentInfo.preferredPaid.includes("PAID") &&
                user.residentInfo.preferredPaid.includes("FREE")
                  ? "EITHER"
                  : user.residentInfo.preferredPaid.includes("PAID")
                  ? "PAID"
                  : user.residentInfo.preferredPaid.includes("FREE")
                  ? "FREE"
                  : null,
              preferredVolunteerType: user.residentInfo.preferredVolunteerType,
              privateEnterprise: user.residentInfo.privateEnterprise,
              university: user.residentInfo.university,
              externalOrganization: user.residentInfo.externalOrganization,
              pta: user.residentInfo.pta,
            }
          : {
              preferredDays: [],
              teacherLicenses: [],
              medicalLicenses: [],
              skills: [],
              preferredPaid: null,
              preferredVolunteerType: [],
              privateEnterprise: "",
              university: "",
              externalOrganization: "",
              pta: "",
            },
      community: {
        mainElementarySchool: "",
        mainJuniorHighSchool: "",
        mainElementarySchoolType: undefined,
        mainJuniorHighSchoolType: undefined,
        elementarySchools: [],
        juniorHighSchools: [],
      },
    },
    resolver: zodResolver(zodSchema),
    mode: "onChange",
  });

  const inputFileRef = useRef(null);
  const onClickFileInput = () => {
    const inputFileElement = inputFileRef.current! as HTMLInputElement;
    inputFileElement?.click();
  };

  const onInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target;
      const files = Array.from(input.files || []);
      const file = files[0];
      if (file) {
        setFaceImg(file);
      }
    },
    []
  );

  const onSubmitFile = async () => {
    if (faceImg) await registerFaceImgPathForBE(faceImg, accountId);
  };

  const imgPreviewUrl = useMemo(() => {
    if (faceImg) return URL.createObjectURL(faceImg);
    else return "";
  }, [faceImg]);

  const handleGenderChange = (value: "MALE" | "FEMALE" | "OTHER") => {
    setValue("baseInfo.gender", value);
  };

  const handlePreferredDaysChange = (value: DayOfWeekType) => {
    const list = watch("residentInfo.preferredDays");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.preferredDays", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.preferredDays", newLists);
    }
  };

  const handleTeacherChange = (value: TeachingLicenseType) => {
    const list = watch("residentInfo.teacherLicenses");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.teacherLicenses", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.teacherLicenses", newLists);
    }
  };

  const handleMedicalChange = (value: MedicalLicenseType) => {
    const list = watch("residentInfo.medicalLicenses");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.medicalLicenses", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.medicalLicenses", newLists);
    }
  };

  const handleSkillsChange = (value: SkillType) => {
    const list = watch("residentInfo.skills");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.skills", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.skills", newLists);
    }
  };

  const handlePreferredPaidChange = (value: PaidType) => {
    const prev = watch("residentInfo.preferredPaid");
    if (prev === value) {
      setValue("residentInfo.preferredPaid", null);
    } else {
      setValue("residentInfo.preferredPaid", value);
    }
  };

  const handleVolunteerChange = (value: VolunteerType) => {
    const list = watch("residentInfo.preferredVolunteerType");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.preferredVolunteerType", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.preferredVolunteerType", newLists);
    }
  };

  const getAddress = useCallback(async (value: string) => {
    try {
      const res = await getAddressFromPostalCode(value);
      setValue("baseInfo.prefecture", res.prefecture);
      setValue("baseInfo.city", res.city);
      setValue("baseInfo.address1", res.suburb);
      setValue("baseInfo.address2", "");
      clearErrors("baseInfo.postalCode");
    } catch (err) {
      setError("baseInfo.postalCode", {
        message: "ハイフンは入力せず、正しい郵便番号を入力してください",
      });
    }
  }, []);

  return (
    <ModalPortal onClose={onClose}>
      <ModalWrapper>
        <ModalHeader>情報を編集</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DivCenterWrapper>
              <UploadImagesContainer>
                <Picture
                  src={imgPreviewUrl || user?.picture || defaultUserImg}
                  alt="image"
                />
                <UploadImgButton type="button" onClick={onClickFileInput}>
                  <CameraIcon />
                </UploadImgButton>
                <input
                  ref={inputFileRef}
                  hidden
                  multiple
                  type="file"
                  name="image"
                  readOnly={true}
                  accept="image/jpeg, image/png"
                  onChange={onInputChange}
                />
              </UploadImagesContainer>
            </DivCenterWrapper>
            <div>
              <EditModalFormLabel>お名前</EditModalFormLabel>
              <CheckTabs>
                <div>
                  <TextField
                    placeholder="姓"
                    {...register("baseInfo.lastName")}
                  />
                  {errors.baseInfo?.lastName && (
                    <ErrorMessage>
                      {errors.baseInfo?.lastName.message}
                    </ErrorMessage>
                  )}
                </div>
                <div>
                  <TextField
                    placeholder="名"
                    {...register("baseInfo.firstName")}
                  />
                  {errors.baseInfo?.firstName && (
                    <ErrorMessage>
                      {errors.baseInfo?.firstName.message}
                    </ErrorMessage>
                  )}
                </div>
              </CheckTabs>
            </div>
            <div>
              <EditModalFormLabel>ふりがな</EditModalFormLabel>
              <CheckTabs>
                <div>
                  <TextField
                    placeholder="せい"
                    {...register("baseInfo.lastNameKana")}
                  />
                  {errors.baseInfo?.lastNameKana && (
                    <ErrorMessage>
                      {errors.baseInfo?.lastNameKana.message}
                    </ErrorMessage>
                  )}
                </div>
                <div>
                  <TextField
                    placeholder="めい"
                    {...register("baseInfo.firstNameKana")}
                  />
                  {errors.baseInfo?.firstNameKana && (
                    <ErrorMessage>
                      {errors.baseInfo?.firstNameKana.message}
                    </ErrorMessage>
                  )}
                </div>
              </CheckTabs>
            </div>
            <div>
              <EditModalFormLabel>電話番号</EditModalFormLabel>
              <div>
                <TextField
                  {...register("baseInfo.phoneNumber")}
                  placeholder="例)  09012345678"
                />
                {errors.baseInfo?.phoneNumber && (
                  <ErrorMessage>
                    {errors.baseInfo.phoneNumber.message}
                  </ErrorMessage>
                )}
              </div>
            </div>
            <Margin marginBottom={20} />
            <div>
              <EditModalFormLabel>性別</EditModalFormLabel>

              <ButtonGroupExtend>
                {genderOption.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={watch("baseInfo.gender") === option.value}
                    onClick={() => handleGenderChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
              {errors?.baseInfo && errors.baseInfo.gender && (
                <ErrorMessage>{errors.baseInfo.gender.message}</ErrorMessage>
              )}
            </div>
            <div>
              <EditModalFormLabel>年代</EditModalFormLabel>
              <SelectField
                {...register(`baseInfo.ageGroup` as const, {
                  required: "入力必須項目です",
                  setValueAs: (v) => {
                    try {
                      return zTransformAgeGroup.parse(v);
                    } catch (error) {
                      return undefined;
                    }
                  },
                })}
                defaultValue={-1}
              >
                <option value="-1" disabled>
                  年代を選択
                </option>
                {ageGroup.map((opt) => {
                  return (
                    <option key={opt.value} value={opt.value}>
                      {opt.text}
                    </option>
                  );
                })}
              </SelectField>
              {errors.baseInfo?.ageGroup && (
                <ErrorMessage>{errors.baseInfo?.ageGroup.message}</ErrorMessage>
              )}
            </div>
            <div>
              <EditModalFormLabel>生年月日</EditModalFormLabel>
              <TextField
                min="1900-01-01"
                max="2100-12-31"
                type="date"
                {...register(`baseInfo.birthday` as const, {
                  validate: (value) => {
                    if (!value) return true;

                    return value && new Date(value) <= new Date()
                      ? true
                      : "未来の日付は設定できません";
                  },
                })}
              ></TextField>
              {errors?.baseInfo && errors.baseInfo.birthday && (
                <ErrorMessage>{errors.baseInfo.birthday.message}</ErrorMessage>
              )}
            </div>
            <div>
              <EditModalFormLabel>郵便番号</EditModalFormLabel>
              <TextField
                {...register(`baseInfo.postalCode` as const, {
                  required: "入力必須項目です",
                })}
                placeholder="例）1030013"
                onChange={(e) => getAddress(e.target.value)}
              ></TextField>
              {errors?.baseInfo && errors.baseInfo.postalCode && (
                <ErrorMessage>
                  ハイフンは入力せず、正しい郵便番号を入力してください
                </ErrorMessage>
              )}
            </div>
            <div>
              <EditModalFormLabel>住所</EditModalFormLabel>
              <CheckTabs>
                <div>
                  <SelectField
                    {...register(`baseInfo.prefecture`)}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      県名を選択
                    </option>
                    {prefectureOptions.map((opt) => {
                      return (
                        <option key={opt.value} value={opt.value}>
                          {opt.text}
                        </option>
                      );
                    })}
                  </SelectField>
                  {errors.baseInfo?.prefecture && (
                    <ErrorMessage>
                      {errors.baseInfo?.prefecture.message}
                    </ErrorMessage>
                  )}
                </div>
                <div>
                  <TextField
                    {...register("baseInfo.city")}
                    placeholder="例）川崎市川崎区"
                  />
                  {errors.baseInfo?.city && (
                    <ErrorMessage>{errors.baseInfo?.city.message}</ErrorMessage>
                  )}
                </div>
              </CheckTabs>
              <Margin marginTop={8} />
              <TextField
                {...register(`baseInfo.address1`)}
                placeholder="例）川崎市川崎区"
              ></TextField>
              {errors?.baseInfo && errors.baseInfo.address1 && (
                <ErrorMessage>{errors.baseInfo.address1.message}</ErrorMessage>
              )}
              <Margin marginTop={8} />
              <TextField
                {...register(`baseInfo.address2`)}
                placeholder="例）〇〇マンション101号室"
              ></TextField>
              {errors?.baseInfo && errors.baseInfo.address2 && (
                <ErrorMessage>{errors.baseInfo.address2.message}</ErrorMessage>
              )}
            </div>
            <div>
              <EditModalFormLabel>希望活動曜日</EditModalFormLabel>
              <ButtonGroupExtend>
                {dayOfWeekOptions.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={watch("residentInfo.preferredDays").includes(
                      option.value
                    )}
                    onClick={() => handlePreferredDaysChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
              <Margin marginTop={16} />
            </div>
            <div>
              <EditModalFormLabel>教員免許状</EditModalFormLabel>
              <ButtonGroupExtend>
                {teachingLicenseOptions.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={watch("residentInfo.teacherLicenses").includes(
                      option.value
                    )}
                    onClick={() => handleTeacherChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
              <Margin marginTop={16} />
            </div>
            <div>
              <EditModalFormLabel>医療・看護</EditModalFormLabel>
              <ButtonGroupExtend>
                {medicalLicenseOptions.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={watch("residentInfo.medicalLicenses").includes(
                      option.value
                    )}
                    onClick={() => handleMedicalChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
              <Margin marginTop={16} />
            </div>
            <div>
              <EditModalFormLabel>スキル</EditModalFormLabel>
              <ButtonGroupExtend>
                {skillOptions.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={watch("residentInfo.skills").includes(
                      option.value
                    )}
                    onClick={() => handleSkillsChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
              <Margin marginTop={16} />
            </div>
            <div>
              <EditModalFormLabel>有償無償選択</EditModalFormLabel>
              <ButtonGroupExtend>
                {paidOption.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={
                      watch("residentInfo.preferredPaid") === option.value
                    }
                    onClick={() => handlePreferredPaidChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
              <Margin marginTop={16} />
            </div>
            <div>
              <EditModalFormLabel>ボランティアの項目選択</EditModalFormLabel>
              <ButtonGroupExtend>
                {volunteerOption.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={watch(
                      "residentInfo.preferredVolunteerType"
                    ).includes(option.value)}
                    onClick={() => handleVolunteerChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
              <Margin marginTop={16} />
            </div>
            <div>
              <EditModalFormLabel>民間企業</EditModalFormLabel>
              <TextField
                {...register(`residentInfo.privateEnterprise`)}
                placeholder="〇〇株式会社"
              ></TextField>
            </div>
            <div>
              <EditModalFormLabel>大学</EditModalFormLabel>
              <TextField
                {...register(`residentInfo.university`)}
                placeholder="〇〇大学"
              ></TextField>
            </div>
            <div>
              <EditModalFormLabel>外郭団体</EditModalFormLabel>
              <TextField
                {...register(`residentInfo.externalOrganization`)}
                placeholder="〇〇団体"
              ></TextField>
            </div>
            <div>
              <EditModalFormLabel>PTA</EditModalFormLabel>
              <TextField
                {...register(`residentInfo.pta`)}
                placeholder="〇〇PTA"
              ></TextField>
            </div>
            <BEEditBaseInfoCommunity
              member={member}
              control={control}
              errors={errors}
              currentCommunityId={communityId}
            />
            <Margin marginTop={25} />
            <ModalSubmitButtons
              disabled={Object.keys(errors).length > 0 || doubleClickBlocked}
              submitText="保存"
              onCancel={onClose}
            />
          </form>
        </ModalBody>
      </ModalWrapper>
    </ModalPortal>
  );
};

const ButtonGroupExtend = styled(ButtonGroup)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .button {
    width: auto;
  }
  svg {
    margin-left: 12px;
  }
`;

const CheckTabs = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  > * {
    width: 100%;
  }
`;

const ModalWrapper = styled.div`
  overflow-y: scroll;
  max-height: 564px;
  padding: 20px;
`;

const ModalHeader = styled(Heading3)`
  padding-bottom: 24px;
  display: flex;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;

const EditModalFormLabel = styled.label`
  color: #1a1c21;
  font-weight: 700;
  font-size: 12px;
  margin-top: 14px;
  margin-bottom: 4px;
  display: inline-block;
`;

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const SelectField = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
`;

const UploadImagesContainer = styled.div`
  position: relative;
`;

const UploadImgButton = styled.button`
  background-color: gray;
  border-radius: 50%;
  border: none;
  position: absolute;
  bottom: 0;
  left: 53%;
  height: 24px;
  width: 24px;
`;

const DivCenterWrapper = styled.div`
  text-align: center;
`;

const Picture = styled.img`
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 50%;
`;
