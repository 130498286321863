import React, { FC, useMemo } from "react";
import styled from "styled-components";
import * as styles from "./Table.css";
import * as theme from "../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons/faCheckSquare";
import { faSquare } from "@fortawesome/free-regular-svg-icons/faSquare";
import { faMinusSquare } from "@fortawesome/free-regular-svg-icons/faMinusSquare";
import classNames from "classnames";

export const Table = styled.table`
  font-size: 0.875rem;
  line-height: 1.5;
  font-feature-settings: "calt" 1, "kern" 1, "liga" 1, "tnum" 1;
  width: 100%;
  table-layout: fixed;

  border-collapse: collapse;
  border-radius: 6px;

  table-layout: auto;

  td {
    height: 56px;
  }

  th,
  td {
    padding: 8px 16px;
    white-space: nowrap;
    vertical-align: middle;
    border-bottom: 1px solid #d3dae6;
    text-align: inherit;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
    border-top: none;
  }

  thead {
    color: ${theme.colorTokens.textSubdued};
  }

  tbody {
    color: #1a1c21;
  }

  tfoot tr td {
    text-align: right;
  }
`;

export const TableHeader: FC<{ children: React.ReactNode }> = (props) => {
  return (
    <thead>
      <tr>{props.children}</tr>
    </thead>
  );
};
export const TableHeaderItem: FC<{ children: React.ReactNode }> = (props) => {
  return <th>{props.children}</th>;
};

export const TableBody: FC<{ children: React.ReactNode }> = (props) => {
  return <tbody>{props.children}</tbody>;
};

export const TableRow: FC<{
  onClick?: () => void;
  children: React.ReactNode;
}> = (props) => {
  return <tr onClick={props.onClick}>{props.children}</tr>;
};

export const TableData: FC<{ children: React.ReactNode }> = (props) => {
  return <td>{props.children}</td>;
};

export const Checked: FC<{
  checked?: boolean;
  rejected?: boolean;
  children: React.ReactNode;
}> = ({ checked, rejected, children }) => {
  const icon = useMemo(() => {
    if (rejected) {
      return faMinusSquare;
    }
    if (checked) {
      return faCheckSquare;
    } else {
      return faSquare;
    }
  }, [checked, rejected]);
  return (
    <label
      className={classNames(
        styles.CheckBox,
        checked && "__checked",
        rejected && "__rejected"
      )}
    >
      <span>
        {/* @ts-ignore */}
        <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
      </span>
      {children}
    </label>
  );
};

export const TableTh = styled.th`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #343741;
`;

export const TableItem = styled.td`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #343741;
`;
export const TableFooter: FC<{ children: React.ReactNode }> = (props) => {
  return (
    <tfoot>
      <tr>
        <td colSpan={99999}>{props.children}</td>
      </tr>
    </tfoot>
  );
};
