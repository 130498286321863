import React, { useMemo } from "react";
import {
  GoogleMap,
  Libraries,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const apiKey = import.meta.env.VITE_GOOGLE_MAP_API_KEY;

interface Props {
  lat: number;
  lng: number;
}

const libraries: Libraries = ["places"];

export default function GoogleMapArea({ lat, lng }: Props) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const markerPosition = useMemo(() => ({ lat, lng }), [lat, lng]);

  if (!isLoaded || loadError) {
    return <></>;
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={markerPosition}
      zoom={14}
    >
      <MarkerF position={markerPosition} draggable={false} />
    </GoogleMap>
  );
}
