import React, { useMemo } from "react";
import styled from "styled-components";
import RecruitmentThumbnailImage from "./RecruitmentThumbnailImage";
import { LocationSpotIcon } from "../../../components/icons/LocationSpotIcon";
import { WalkingIcon } from "../../../components/icons/WalkingIcon";
import { BikeIcon } from "../../../components/icons/BikeIcon";
import { CarIcon } from "../../../components/icons/CarIcon";
import { CalenderIcon } from "../../../components/icons/CalenderIcon";
import { toDateIntervalFormat, toHourMinuteFormat } from "../../../utils/time";
import { Button } from "../../../components/Button";
import ExternalLinkIcon from "../../../components/icons/ExternalLinkIcon";
import { Avatar } from "../../../components/Common/Avatar";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import { Recruitment, RecruitmentOrigin } from "@shared/types/recruitment";
import { useGetOrganizationByRecruitmentId } from "src/hooks/query/organization";
import { RecruitmentTemplate } from "@shared/types/recruitmentTemplate";
// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

type RecruitmentCardProps = {
  recruitment: Recruitment | RecruitmentTemplate;
  // プライマリーボタン
  primaryButtonLabel: string;
  onClickPrimaryButton: () => void;
  primaryButtonIsDisabled?: boolean;
  // キャンセルボタン
  cancelButtonIsDisabled?: boolean;
  cancelButtonLabel?: string;
  onClickCancelButton?: () => void;
};

export const RecruitmentCard = ({
  recruitment,
  primaryButtonLabel,
  onClickPrimaryButton,
  primaryButtonIsDisabled = false,
  cancelButtonIsDisabled = false,
  cancelButtonLabel,
  onClickCancelButton,
}: RecruitmentCardProps) => {
  const { organization } = useGetOrganizationByRecruitmentId(recruitment.id);
  const createdNameBy = useMemo(() => {
    return {
      title:
        recruitment.origin === RecruitmentOrigin.CS ? "校区" : "教育委員会",
      value:
        recruitment.origin === RecruitmentOrigin.CS
          ? recruitment.community?.name
          : organization?.name,
    };
  }, [organization]);
  return (
    <Card>
      <RecruitmentThumbnailImage
        src={recruitment.picture}
        title={recruitment.title}
      />
      <CardBody>
        <TagWrapper>
          <Tag color="blue">{recruitment.volunteerType}</Tag>
          <Tag color="blue">{recruitment.volunteerDetailType}</Tag>
          <Tag color="green">
            {recruitment.isPaidVolunteer ? "有償" : "無償"}
          </Tag>
          <Tag color="pink">単発</Tag>
          {recruitment.walkMinute !== undefined &&
            recruitment.walkMinute !== null && (
              <Tag color="orange">
                <WalkingIcon />
                {toHourMinuteFormat(recruitment.walkMinute)}
              </Tag>
            )}
          {recruitment.bikeMinute !== undefined &&
            recruitment.bikeMinute !== null && (
              <Tag color="orange">
                <BikeIcon />
                {toHourMinuteFormat(recruitment.bikeMinute)}
              </Tag>
            )}
          {recruitment.carMinute !== undefined &&
            recruitment.carMinute !== null && (
              <Tag color="orange">
                <CarIcon />
                {toHourMinuteFormat(recruitment.carMinute)}
              </Tag>
            )}
        </TagWrapper>

        <Title>{recruitment.title}</Title>
        {recruitment.account && (
          <UserPicCell>
            <PreUserPicCell>
              <Avatar
                src={recruitment.account.user.picture || defaultUserImg}
                alt={recruitment.account.user.name}
                size={30}
              />
            </PreUserPicCell>
            <PreUserNameCell>
              作成者
              <Name>{recruitment.account.user.name}</Name>
            </PreUserNameCell>
          </UserPicCell>
        )}
        <UserPicCell>
          <PreUserPicCell>
            <HiddenAvater>
              <Avatar
                src={defaultUserImg}
                alt={recruitment.community?.name || "校区"}
                size={30}
              />
            </HiddenAvater>
          </PreUserPicCell>
          <PreUserNameCell>
            {createdNameBy.title}
            <Name>{createdNameBy.value}</Name>
          </PreUserNameCell>
        </UserPicCell>

        <Subtitle>
          <CalenderIcon />
          時間・曜日
        </Subtitle>
        {recruitment.schedule?.map((schedule, i) => (
          <Time key={i}>
            {toDateIntervalFormat(
              new Date(schedule.start),
              new Date(schedule.end)
            )}
          </Time>
        ))}

        <Spacer height={12} />

        <Subtitle>
          <LocationSpotIcon />
          場所
        </Subtitle>
        <a
          href={`https://www.google.com/maps?q=${recruitment.latitude},${recruitment.longitude}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <PostalCode>
            〒{recruitment.postalCode?.slice(0, 3)}-
            {recruitment.postalCode?.slice(3)}
          </PostalCode>
          <Address>
            {recruitment.prefecture}
            {recruitment.city}
            {recruitment.address1}
            {recruitment.address2}
            <ExternalLinkIconWrapper>
              <ExternalLinkIcon size={12} />
            </ExternalLinkIconWrapper>
          </Address>
        </a>
      </CardBody>

      <CardFooter>
        <CardButton
          size="large"
          fill
          onClick={onClickPrimaryButton}
          isDisabled={primaryButtonIsDisabled}
        >
          {primaryButtonLabel}
        </CardButton>
        {cancelButtonLabel && onClickCancelButton && (
          <CardCancelButton
            color="danger"
            size="large"
            empty
            onClick={onClickCancelButton}
            isDisabled={cancelButtonIsDisabled}
          >
            {cancelButtonLabel}
          </CardCancelButton>
        )}
      </CardFooter>
    </Card>
  );
};

const Card = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const CardBody = styled.div`
  padding: 8px 12px;
  flex: 1;
`;

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 8px;
  margin-bottom: 8px;
`;

const Tag = styled.p<{
  color: "blue" | "green" | "pink" | "orange";
}>`
  background-color: ${(props) => {
    switch (props.color) {
      case "blue":
        return "#79aad9";
      case "green":
        return "#6DCCB1";
      case "pink":
        return "#EE789D";
      case "orange":
        return "#FF7E62";
      default:
        return "#79aad9";
    }
  }};
  padding: 4px 12px;
  border-radius: 16px;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const Subtitle = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: #000;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 0;
  & > svg {
    width: 12px;
    height: 12px;
  }
`;

const Time = styled.p`
  color: #343741;
  font-size: 12px;
`;

const Address = styled.p`
  color: #343741;
  font-size: 12px;
`;

const ExternalLinkIconWrapper = styled.span`
  position: relative;
  & > svg {
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
  }
`;

const PostalCode = styled.p`
  color: #343741;
  font-size: 12px;
`;

const CardFooter = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Spacer = styled.div<{
  height: number;
}>`
  height: ${(props) => props.height}px;
`;

const CardButton = styled(Button)<{
  isDisabled?: boolean;
}>`
  width: 100%;
  ${(props) => props.isDisabled && `background-color: #99C9Eb; color: #FFFFFF;`}
`;

const CardCancelButton = styled(Button)<{
  isDisabled?: boolean;
}>`
  width: 100%;
  ${(props) =>
    props.isDisabled &&
    `background-color: rgba(105,112,125,0.2); color: #5a606b;`}
`;

const HiddenAvater = styled.div`
  visibility: hidden;
`;

const UserPicCell = styled.th`
  color: #343741;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  color: #343741;
  font-size: 12px;
  line-height: 24px;
  font-weight: normal;
  text-align: left;
`;

const PreUserPicCell = styled.div`
  padding: 10px;
`;

const PreUserNameCell = styled.div`
  text-align: left;
`;
