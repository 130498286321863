import React from "react";
import { verifiedInvitationBoardEducationMember } from "src/apiClients/invitation";
import { usePolyfitLocationQuery } from "src/hooks/router";
import { InviteMemberActivationContent } from "src/features/InviteMember/InviteMemberActivationContent";

export default function BoardEducationInviteMemberActivationCompletePage() {
  const { token } = usePolyfitLocationQuery("BOARD_EDUCATION_ACTIVATION", {
    token: "",
  });

  return (
    <InviteMemberActivationContent
      verify={verifiedInvitationBoardEducationMember}
      token={token}
    />
  );
}
