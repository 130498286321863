import React, { useState } from "react";
import { User } from "../../../apiClients/users";
import { usePolyfitHistory } from "../../../hooks/router";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../../components/Modal";
import { Heading3 } from "../../../components/Typo";
import styled from "styled-components";
import { csRoleOptions } from "../../../utils/types/role";
import { updateRole } from "src/apiClients/boardEducation/auth";
import { useInvalidateBEResidentUsers } from "src/hooks/query/boardEducation/users/useBEResidentUsers";

type EditRoleModalProps = {
  user: User;
  roleName: string;
  onClose: () => void;
  getUser: () => Promise<void>;
  isLastCSAdminUser: boolean;
  accountId: string;
  communityId: string;
};

export const BEEditRoleModal = ({
  user,
  roleName,
  onClose,
  getUser,
  isLastCSAdminUser,
  accountId,
  communityId,
}: EditRoleModalProps) => {
  const [role, setRole] = useState(roleName);
  const history = usePolyfitHistory();
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const { invalidateBEResidentUsers } = useInvalidateBEResidentUsers();
  const onSubmit = async () => {
    setDoubleClickBlocked(true);
    try {
      await updateRole(accountId, role, communityId);
      setRole(role);
      await getUser();
      invalidateBEResidentUsers();
      history.push({
        to: "BE_ADMIN_MEMBER_DETAIL",
        query: {
          accountId: accountId,
          communityId: communityId,
        },
      });
      setDoubleClickBlocked(false);
      onClose();
    } catch (err) {
      setDoubleClickBlocked(false);
      console.error(err);
    }
  };

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>権限を変更</ModalHeader>
      <ModalBody>
        {isLastCSAdminUser ? (
          <Message>
            CS管理者が不在の場合、CS管理者を新規で増やすことができません。
            <br />
            他の方を管理者権限に移行してください。
          </Message>
        ) : (
          <>
            <Message>
              {user.name}
              さんの権限を選択できます。
            </Message>
            {csRoleOptions.map((role) => {
              return (
                <div key={role.value}>
                  <input
                    type="radio"
                    value={role.value}
                    name="role"
                    defaultChecked={role.value === roleName}
                    onChange={() => setRole(role.value)}
                  />{" "}
                  <Role>{role.text}</Role>
                  <DescriptionList>
                    {role.descriptions.map((description) => (
                      <Description key={description}>{description}</Description>
                    ))}
                  </DescriptionList>
                </div>
              );
            })}

            <ModalSubmitButtons
              disabled={roleName === role || doubleClickBlocked}
              submitText="保存"
              onSubmit={onSubmit}
              onCancel={onClose}
            />
          </>
        )}
      </ModalBody>
    </ModalPortal>
  );
};

const ModalHeader = styled(Heading3)`
  display: flex;
`;

const Message = styled.div`
  padding: 16px 0;
  max-width: 432px;
`;

const Role = styled.span`
  font-weight: bold;
`;

const DescriptionList = styled.ul`
  padding: 2px 4px 8px 24px;
`;

const Description = styled.li`
  font-size: 14px;
`;
