import { useCallback, useEffect, useState } from "react";
import {
  atom,
  useRecoilValue,
  useRecoilCallback,
  useRecoilState,
} from "recoil";
import { recoilPersist } from "recoil-persist";
import {
  signInOrSignUpWithPopup,
  signUpWithEmailAndPassword,
} from "../../utils/auth";
import { usePolyfitHistory } from "../router";
import * as authApi from "../../apiClients/auth";

const { persistAtom } = recoilPersist();

export type User = authApi.CurrentUser;
export const userState = atom<User>({
  key: "User",
  default: {
    id: "",
    email: "",
    name: "",
    internalRole: "Member",
    role: "PTAMember",
    isApprover: false,
    picture: "",
    firebaseUid: "",
    able: false,
    signUpState: false,
    type: "PARENT",
    baseInfo: {
      userId: "",
      lastName: "",
      firstName: "",
      lastNameKana: "",
      firstNameKana: "",
      phoneNumber: "",
      schoolGroup: "",
      isPreschooler: false,
      selfFreeMemo: "",
      belongId: "",
      partId: "",
    },
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export type BaseInfo = authApi.BaseInfo;
export type ResidentBaseInfo = authApi.ResidentBaseInfo;

/**
 * Recoilからcurrent userを取得・参照する
 */
export function useCurrentUser() {
  const user = useRecoilValue(userState);
  return user;
}

/**
 * バックエンドからcurrent userを取得・参照する
 */
export function useLoginUser() {
  const [loginUser, setLoginUser] = useState<User>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchLoginUser = useCallback(async () => {
    setIsLoading(true);

    // セッションが切れていた場合はログアウトさせる
    await authApi.currentUser().then((user) => setLoginUser(user));

    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchLoginUser();
  }, []);

  return { loginUser, isLoading, fetchLoginUser };
}

export function useFetchCurrentUser() {
  const fetch = useRecoilCallback(({ set }) => async () => {
    const fetchedUser = await authApi.currentUser();
    set(userState, fetchedUser);
  });
  return fetch;
}

export function useCurrentUserHook() {
  const [loading, setIsLoading] = useState(true);
  const [user, setUser] = useRecoilState(userState);

  const fetchCurrentUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const user = await authApi.currentUser();
      setUser(user);
    } catch (e) {
      console.log("fetch error");
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  return { user, loading, refetch: fetchCurrentUser };
}

export function useSignupWithInvitationToken({ token }: { token: string }) {
  const [user, setUser] = useRecoilState(userState);
  const history = usePolyfitHistory();

  const signupEmailPassword = useCallback(
    async (email: string, password: string, isGuest: boolean = false) => {
      await signUpWithEmailAndPassword(email, password);
      const { user, organizationId } = await authApi.signupWithInvitation({
        token,
        isGuest,
      });
      if (user) {
        setUser(user);
        if (organizationId)
          await authApi.setCurrentAccount(user.id, organizationId);
        history.push({ to: "LOADING", query: { token } });
      }
    },
    [token, user, setUser, history]
  );

  const signupProvider = useCallback(
    async (isGuest: boolean = false) => {
      await signInOrSignUpWithPopup();
      const { user, organizationId } = await authApi.signupWithInvitation({
        token,
        isGuest,
      });
      if (user) {
        setUser(user);
        if (organizationId)
          await authApi.setCurrentAccount(user.id, organizationId);
        history.push({ to: "LOADING", query: { token } });
      }
    },
    [token, setUser, history]
  );

  return { signupEmailPassword, signupProvider };
}
