import React from "react";
import { PageContainer, PageHeader, PageBody } from "../../../components/Page";
import * as Typo from "../../../components/Typo";
import * as Responsive from "../../../components/Responsive";
import { AirPlaneIcon } from "../../../components/icons/SvgIcons";
import styled from "styled-components";
import { Navigation } from "../../../components/Navigation3";

const LoginWithResetEmailCompletePageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 702px;
  margin: 0 auto;
  padding-top: 124px;
  .message {
    text-align: center;
  }
  @media (max-width: 1279px) {
    max-width: 343px;
    padding-top: 120px;
  }
`;

export default function LoginWithResetEmailCompleteMemberPage() {
  return (
    <>
      <Navigation />
      <LoginWithResetEmailCompletePageContainer>
        <AirPlaneIcon />
        <PageHeader>メールを送信しました</PageHeader>
        <PageBody>
          <Responsive.Row>
            <Responsive.Col>
              <Typo.Paragraph className="message">
                入力いただいたメールアドレスに確認メールを送信しました。
                <br />
                記載のURLにアクセスいただき、再認証を完了してください。
              </Typo.Paragraph>
            </Responsive.Col>
          </Responsive.Row>
        </PageBody>
      </LoginWithResetEmailCompletePageContainer>
    </>
  );
}
