type LonLatResponse = [{ geometry: { coordinates: [number, number] } }];

export async function getLonLatFromAddress(address: string) {
  const res = await fetch(
    "https://msearch.gsi.go.jp/address-search/AddressSearch?q=" + address,
    { method: "GET" }
  );
  const json: LonLatResponse = await res.json();

  return {
    latitude: json[0].geometry.coordinates[1],
    longitude: json[0].geometry.coordinates[0],
  };
}
