import * as client from "../client";
import {
  CreateChatMessageRequest,
  ChatMessageForView,
  ChatMessage,
} from "@shared/types/chatMessage";

export async function fetchChatMessagesByRoomId(chatRoomId: string) {
  const chatMessages = await client.get<
    { chatRoomId: string },
    ChatMessageForView[]
  >("/boardEducation/chatMessage/fetchChatMessagesByRoomId", { chatRoomId });
  return chatMessages;
}

export async function createChatMessage(
  chatRoomId: string,
  content: string,
  files: File[]
): Promise<void> {
  await client.postWithFormData<
    CreateChatMessageRequest & { files: File[] },
    { message: ChatMessage }
  >("/boardEducation/chatMessage/create", { chatRoomId, content, files });
}
