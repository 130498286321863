import React, {
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useToast } from "../../../components/Toast";
import { useCurrentUser } from "../../../hooks/recoil/user";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../../components/Modal";
import {
  ModalWrapper,
  ModalHeader,
  SelectField,
  DivCenterWrapper,
  UploadImagesContainer,
  Picture,
  UploadImgButton,
  EditModalFormLabel,
  CheckTabs,
  TextField,
  BlueTextAreaField,
  ErrorMessage,
  TextAreaField,
} from "../../../pages/pta/admin/ProfilePage";
import { Margin } from "../../../components/Margin";
import { Button, ButtonGroup } from "../../../components/Button";
import { CheckIcon } from "../../../components/icons/CheckIcon";
import { CameraIcon } from "../../../components/icons/CameraIcon";
import type { BaseInfo } from "../../../hooks/recoil/user";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import { prefectureOptions } from "../../../utils/types/prefecture";
import { toInputDateFormatYearMonth } from "../../../utils/time";
import { ButtonSelect } from "../../../components/ButtonSelect";
import { genderOption } from "../../../utils/types/gender";
import { getAddressFromPostalCode } from "../../../apiClients/postcode";
import { useBaseInfoById, useRegisterInfo } from "../../../hooks/api/baseInfo";
import { isPtaAdminRole, isPtaRole } from "../../../utils/types/role";
import { ageGroup, zTransformAgeGroup } from "../../../utils/types/ageGroup";
import { Organization } from "../../../apiClients/organization";
import { updateCommunityRoles } from "src/apiClients/auth";
import { useCurrentCommunityId, usePolyfitHistory } from "src/hooks/router";
import { EditBaseInfoCommunity } from "src/features/Member/EditBaseInfoCommunity";
import { APIError } from "src/utils/types/ApiError";
import { useInvalidateGetChildrenByCondition } from "src/hooks/query/childrenList";
import { FormValue as CommunityFormValue } from "src/features/Member/Community/utils/form";
import { UserType } from "src/@shared/types/userType";
import { zodResolver } from "@hookform/resolvers/zod";
import { PTAFormValueSchema } from "src/utils/zod/PTABaseInfoSchema";
import { CSFormValueSchema } from "src/utils/zod/CSBaseInfoSchema";
import { sortByOrder } from "src/utils/sortByOrder";
import { useInvalidateGetResidentUsers } from "src/hooks/query/residentUsers";
import { logger } from "src/utils/logger";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

type BaseInfoEditFormValues = {
  baseInfo: BaseInfo;
  community: CommunityFormValue;
};

type Props = {
  isOpen: boolean;
  baseInfo: BaseInfo;
  organization: Organization;
  close: () => void;
  openChangeEmailModal: () => void;
};

const BaseInfoEditModal = ({
  isOpen,
  baseInfo,
  organization,
  close,
  openChangeEmailModal,
}: Props) => {
  const user = useCurrentUser();
  const history = usePolyfitHistory();
  const [faceImg, setFaceImg] = useState<File>();
  const { registerInfo, registerFaceImgPath } = useRegisterInfo();
  const { communityId } = useCurrentCommunityId();
  const toast = useToast();
  const { invalidateGetChildrenByCondition } =
    useInvalidateGetChildrenByCondition();
  const { invalidateGetResidentUsers } = useInvalidateGetResidentUsers();

  const defaultValues = useMemo(() => {
    return {
      baseInfo: {
        userId: baseInfo.userId,
        lastName: baseInfo.lastName,
        lastNameKana: baseInfo.lastNameKana,
        firstName: baseInfo.firstName,
        firstNameKana: baseInfo.firstNameKana,
        phoneNumber: baseInfo.phoneNumber,
        schoolGroup: baseInfo.schoolGroup,
        isPreschooler: Number(baseInfo.isPreschooler),
        selfFreeMemo: baseInfo.selfFreeMemo,
        adminFreeMemo: baseInfo.adminFreeMemo,
        belongId: baseInfo.belongId,
        partId: baseInfo.partId,
        gender: baseInfo.gender,
        ageGroup: baseInfo?.ageGroup ?? -1,
        birthday: user.baseInfo?.birthday
          ? (toInputDateFormatYearMonth(user.baseInfo.birthday) as any)
          : undefined,
        postalCode: baseInfo.postalCode,
        prefecture: baseInfo.prefecture,
        city: baseInfo.city,
        address1: baseInfo.address1,
        address2: baseInfo.address2,
      },
      community: {
        mainElementarySchool: "",
        mainJuniorHighSchool: "",
        mainElementarySchoolType: undefined,
        mainJuniorHighSchoolType: undefined,
        elementarySchools: [],
        juniorHighSchools: [],
      },
    };
  }, [baseInfo]);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<BaseInfoEditFormValues>({
    defaultValues,
    resolver: zodResolver(
      isPtaRole(user.role) ? PTAFormValueSchema : CSFormValueSchema
    ),
    mode: "onChange",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const { baseInfoById, updateBelongAndPart } = useBaseInfoById(user.id);
  const selectBelong = async (id: string) => {
    setValue("baseInfo.belongId", id);
  };
  const selectPart = async (id: string) => {
    setValue("baseInfo.partId", id);
  };

  const handleGenderChange = (value: "MALE" | "FEMALE" | "OTHER") => {
    setValue("baseInfo.gender", value);
  };

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);

  const onSubmit = async (data: BaseInfoEditFormValues) => {
    setDoubleClickBlocked(true);
    try {
      const { baseInfo, community } = data;
      await onSubmitFile();
      await registerInfo(baseInfo);
      if (user.type === "PARENT" || user.type === "TEACHER") {
        await updateBelongAndPart(baseInfo.belongId, baseInfo.partId);
      }
      if (user.type === "PARENT") {
        invalidateGetChildrenByCondition();
      }
      if (user.type === "RESIDENT") {
        invalidateGetResidentUsers();
        const data = [];
        data.push(
          ...(community.mainElementarySchool
            ? [
                {
                  communityId: community.mainElementarySchool,
                  isMain: true,
                  type: community.mainElementarySchoolType,
                },
              ]
            : []),
          ...(community.mainJuniorHighSchool
            ? [
                {
                  communityId: community.mainJuniorHighSchool,
                  isMain: true,
                  type: community.mainJuniorHighSchoolType,
                },
              ]
            : []),
          ...community.elementarySchools.map((school) => {
            return {
              communityId: school,
              isMain: false,
              type: UserType.RESIDENT,
            };
          }),
          ...community.juniorHighSchools.map((school) => {
            return {
              communityId: school,
              isMain: false,
              type: UserType.RESIDENT,
            };
          })
        );
        const update = { userId: user.id, data };
        // 更新後のコミュニティに現在選択しているコミュニティが含まれていない場合はLOADING画面に遷移
        if (update.data.every((u) => u.communityId !== communityId)) {
          history.push({ to: "LOADING", query: {} });
        }
        await updateCommunityRoles(update);
      }
      setDoubleClickBlocked(false);
      close();
      toast.success("保存しました");
    } catch (err: any) {
      logger.error(err);
      if (err instanceof APIError) {
        toast.error(err.message);
        return;
      }
      setDoubleClickBlocked(false);
      toast.error("保存に失敗しました");
    }
  };

  const inputFileRef = useRef(null);
  const onClickFileInput = () => {
    const inputFileElement = inputFileRef.current! as HTMLInputElement;
    inputFileElement?.click();
  };

  const onInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target;
      const files = Array.from(input.files || []);
      const file = files[0];
      if (file) {
        setFaceImg(file);
      }
    },
    []
  );

  const onSubmitFile = async () => {
    if (faceImg) await registerFaceImgPath(faceImg);
  };

  const imgPreviewUrl = useMemo(() => {
    if (faceImg) return URL.createObjectURL(faceImg);
    else return "";
  }, [faceImg]);

  const preschoolerOption = [
    { value: 1, text: "いる" },
    { value: 0, text: "いない" },
  ];

  const getAddress = useCallback(async (value: string) => {
    try {
      const res = await getAddressFromPostalCode(value);
      setValue("baseInfo.prefecture", res.prefecture);
      setValue("baseInfo.city", res.city);
      setValue("baseInfo.address1", res.suburb);
      setValue("baseInfo.address2", "");
      clearErrors("baseInfo.postalCode");
    } catch (err) {
      setError("baseInfo.postalCode", {
        message: "ハイフンは入力せず、正しい郵便番号を入力してください",
      });
    }
  }, []);

  const validatePostalCode = useCallback(async (value: string) => {
    try {
      await getAddressFromPostalCode(value);
      return true;
    } catch (err) {
      return false;
    }
  }, []);

  const sortedBelongs = sortByOrder(organization.belongs);

  const sortedParts = sortByOrder(organization.parts);

  return (
    <>
      {isOpen && (
        <ModalPortal
          onClose={() => {
            reset(defaultValues);
            close();
          }}
          modalBoxIsHeightFull={true}
        >
          <ModalWrapper>
            <ModalHeader>情報を編集</ModalHeader>
            <ModalBody>
              <DivCenterWrapper>
                <UploadImagesContainer>
                  <Picture
                    src={imgPreviewUrl || user?.picture || defaultUserImg}
                    alt="image"
                  />
                  <UploadImgButton type="button" onClick={onClickFileInput}>
                    <CameraIcon />
                  </UploadImgButton>
                  <input
                    ref={inputFileRef}
                    hidden
                    multiple
                    type="file"
                    name="image"
                    readOnly={true}
                    accept="image/jpeg, image/png"
                    onChange={onInputChange}
                  />
                </UploadImagesContainer>
              </DivCenterWrapper>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <EditModalFormLabel>お名前</EditModalFormLabel>
                  <CheckTabs>
                    <div>
                      <TextField
                        placeholder="姓"
                        {...register("baseInfo.lastName")}
                      />
                      {errors.baseInfo?.lastName && (
                        <ErrorMessage>
                          {errors.baseInfo?.lastName.message}
                        </ErrorMessage>
                      )}
                    </div>
                    <div>
                      <TextField
                        placeholder="名"
                        {...register("baseInfo.firstName")}
                      />
                      {errors.baseInfo?.firstName && (
                        <ErrorMessage>
                          {errors.baseInfo?.firstName.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </CheckTabs>
                </div>
                <div>
                  <EditModalFormLabel>ふりがな</EditModalFormLabel>
                  <CheckTabs>
                    <div>
                      <TextField
                        placeholder="せい"
                        {...register("baseInfo.lastNameKana")}
                      />
                      {errors.baseInfo?.lastNameKana && (
                        <ErrorMessage>
                          {errors.baseInfo?.lastNameKana.message}
                        </ErrorMessage>
                      )}
                    </div>
                    <div>
                      <TextField
                        placeholder="めい"
                        {...register("baseInfo.firstNameKana")}
                      />
                      {errors.baseInfo?.firstNameKana && (
                        <ErrorMessage>
                          {errors.baseInfo?.firstNameKana.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </CheckTabs>
                </div>
                {(user.type === "PARENT" || user.type === "TEACHER") &&
                  organization &&
                  !!organization.belongs.length && (
                    <div>
                      <EditModalFormLabel>所属</EditModalFormLabel>
                      <CheckTabs>
                        <ButtonGroupExtend>
                          {sortedBelongs.map((belong) => {
                            return (
                              <Button
                                key={belong.id}
                                size="large"
                                fill
                                color={
                                  watch("baseInfo.belongId") !== belong.id
                                    ? "ghost"
                                    : "primary"
                                }
                                className="button"
                                onClick={() => selectBelong(belong.id)}
                                type="button"
                              >
                                {belong.name}
                                {baseInfoById?.belongId === belong.id && (
                                  <CheckIcon size={8} />
                                )}
                              </Button>
                            );
                          })}
                        </ButtonGroupExtend>
                      </CheckTabs>
                    </div>
                  )}
                {(user.type === "PARENT" || user.type === "TEACHER") &&
                  organization &&
                  !!organization.parts.length && (
                    <div>
                      <EditModalFormLabel>役職</EditModalFormLabel>
                      <CheckTabs>
                        <ButtonGroupExtend>
                          {sortedParts.map((part) => {
                            return (
                              <Button
                                key={part.id}
                                size="large"
                                fill
                                color={
                                  watch("baseInfo.partId") !== part.id
                                    ? "ghost"
                                    : "primary"
                                }
                                className="button"
                                onClick={() => selectPart(part.id)}
                                type="button"
                              >
                                {part.name}
                                {baseInfoById?.partId === part.id && (
                                  <CheckIcon size={8} />
                                )}
                              </Button>
                            );
                          })}
                        </ButtonGroupExtend>
                      </CheckTabs>
                    </div>
                  )}
                <div>
                  <EditModalFormLabel>メールアドレス</EditModalFormLabel>
                  <div>
                    <TextField value={user?.email} disabled />
                  </div>
                  <ButtonWrapper>
                    <Button
                      size="small"
                      color="subPrimary"
                      onClick={(e) => {
                        e.preventDefault();
                        openChangeEmailModal();
                      }}
                    >
                      変更
                    </Button>
                  </ButtonWrapper>
                </div>
                <div>
                  <EditModalFormLabel>電話番号</EditModalFormLabel>
                  <div>
                    <TextField
                      {...register("baseInfo.phoneNumber")}
                      placeholder="例)  09012345678"
                    />
                    {errors.baseInfo?.phoneNumber && (
                      <ErrorMessage>
                        {errors.baseInfo.phoneNumber.message}
                      </ErrorMessage>
                    )}
                  </div>
                </div>
                {user?.type === "PARENT" && (
                  <div>
                    <EditModalFormLabel>登校班</EditModalFormLabel>
                    <div>
                      <TextField
                        {...register("baseInfo.schoolGroup")}
                        placeholder="例)  13"
                      />
                      {errors.baseInfo?.schoolGroup && (
                        <ErrorMessage>
                          {errors.baseInfo.schoolGroup.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>
                )}
                {user?.type === "PARENT" && (
                  <div>
                    <EditModalFormLabel>
                      来年度に入学予定の児童（同学校）
                    </EditModalFormLabel>
                    <SelectField
                      {...register("baseInfo.isPreschooler", {
                        valueAsNumber: true,
                      })}
                    >
                      {preschoolerOption.map((opt) => {
                        return (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        );
                      })}
                    </SelectField>
                  </div>
                )}
                {(user.type === "PARENT" || user.type === "TEACHER") && (
                  <div>
                    <EditModalFormLabel>自由記述</EditModalFormLabel>
                    <div>
                      <TextAreaField {...register("baseInfo.selfFreeMemo")} />
                      {errors.baseInfo?.selfFreeMemo && (
                        <ErrorMessage>
                          {errors.baseInfo.selfFreeMemo.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>
                )}
                {(user.type === "PARENT" || user.type === "TEACHER") &&
                  isPtaAdminRole(user.role) && (
                    <div>
                      <EditModalFormLabel>
                        自由記述（役員管理用）
                      </EditModalFormLabel>
                      <div>
                        <BlueTextAreaField
                          {...register("baseInfo.adminFreeMemo")}
                        />
                        {errors.baseInfo?.adminFreeMemo && (
                          <ErrorMessage>
                            {errors.baseInfo.adminFreeMemo.message}
                          </ErrorMessage>
                        )}
                      </div>
                    </div>
                  )}
                {user.type === "RESIDENT" && (
                  <>
                    <div>
                      <EditModalFormLabel>性別</EditModalFormLabel>
                      <ButtonGroupExtend>
                        {genderOption.map((option, i) => (
                          <ButtonSelect
                            key={i}
                            selected={watch("baseInfo.gender") === option.value}
                            onClick={() => handleGenderChange(option.value)}
                          >
                            <span>{option.text}</span>
                          </ButtonSelect>
                        ))}
                      </ButtonGroupExtend>
                      {errors?.baseInfo && errors.baseInfo.gender && (
                        <ErrorMessage>
                          {errors.baseInfo.gender.message}
                        </ErrorMessage>
                      )}
                    </div>
                    <div>
                      <EditModalFormLabel>年代</EditModalFormLabel>
                      <SelectField
                        {...register(`baseInfo.ageGroup`, {
                          required: "入力必須項目です",
                          setValueAs: (v) => {
                            try {
                              return zTransformAgeGroup.parse(v);
                            } catch (error) {
                              return undefined;
                            }
                          },
                        })}
                        defaultValue={-1}
                      >
                        <option value="-1" disabled>
                          年代を選択
                        </option>
                        {ageGroup.map((opt) => {
                          return (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          );
                        })}
                      </SelectField>
                      {errors.baseInfo?.ageGroup && (
                        <ErrorMessage>
                          {errors.baseInfo?.ageGroup.message}
                        </ErrorMessage>
                      )}
                    </div>
                    <div>
                      <EditModalFormLabel>生年月日</EditModalFormLabel>
                      <TextField
                        min="1900-01-01"
                        max="2100-12-31"
                        type="date"
                        {...register("baseInfo.birthday")}
                      ></TextField>
                      {errors?.baseInfo && errors.baseInfo.birthday && (
                        <ErrorMessage>
                          {errors.baseInfo.birthday.message}
                        </ErrorMessage>
                      )}
                    </div>
                    <div>
                      <EditModalFormLabel>郵便番号</EditModalFormLabel>
                      <TextField
                        {...register(`baseInfo.postalCode` as const, {
                          required: "入力必須項目です",
                          validate: (value) =>
                            value ? validatePostalCode(value) : false,
                        })}
                        placeholder="例）1030013"
                        onChange={(e) => getAddress(e.target.value)}
                      ></TextField>
                      {errors?.baseInfo && errors.baseInfo.postalCode && (
                        <ErrorMessage>
                          ハイフンは入力せず、正しい郵便番号を入力してください
                        </ErrorMessage>
                      )}
                    </div>
                    <div>
                      <EditModalFormLabel>住所</EditModalFormLabel>
                      <CheckTabs>
                        <div>
                          <SelectField
                            {...register(`baseInfo.prefecture`)}
                            defaultValue=""
                          >
                            <option value="" disabled>
                              県名を選択
                            </option>
                            {prefectureOptions.map((opt) => {
                              return (
                                <option key={opt.value} value={opt.value}>
                                  {opt.text}
                                </option>
                              );
                            })}
                          </SelectField>
                          {errors.baseInfo?.prefecture && (
                            <ErrorMessage>
                              {errors.baseInfo?.prefecture.message}
                            </ErrorMessage>
                          )}
                        </div>
                        <div>
                          <TextField
                            {...register("baseInfo.city", {
                              required: "入力必須項目です",
                            })}
                            placeholder="例）川崎市川崎区"
                          />
                          {errors.baseInfo?.city && (
                            <ErrorMessage>
                              {errors.baseInfo?.city.message}
                            </ErrorMessage>
                          )}
                        </div>
                      </CheckTabs>
                      <Margin marginTop={8} />
                      <TextField
                        {...register(`baseInfo.address1` as const, {
                          required: "入力必須項目です",
                        })}
                        placeholder="例）川崎市川崎区"
                      ></TextField>
                      {errors?.baseInfo && errors.baseInfo.address1 && (
                        <ErrorMessage>
                          {errors.baseInfo.address1.message}
                        </ErrorMessage>
                      )}
                      <Margin marginTop={8} />
                      <TextField
                        {...register("baseInfo.address2")}
                        placeholder="例）〇〇マンション101号室"
                      ></TextField>
                      {errors?.baseInfo && errors.baseInfo.address2 && (
                        <ErrorMessage>
                          {errors.baseInfo.address2.message}
                        </ErrorMessage>
                      )}
                    </div>
                    <EditBaseInfoCommunity
                      user={user}
                      control={control as any}
                      errors={errors}
                    />
                  </>
                )}
                <Margin marginTop={25} />
                <ModalSubmitButtons
                  submitText="保存"
                  disabled={
                    Object.keys(errors).length > 0 || doubleClickBlocked
                  }
                  onCancel={() => {
                    reset(defaultValues);
                    close();
                  }}
                />
              </form>
            </ModalBody>
          </ModalWrapper>
        </ModalPortal>
      )}
    </>
  );
};

const ButtonWrapper = styled.div`
  margin-top: 8px;
`;

const ButtonGroupExtend = styled(ButtonGroup)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .button {
    width: auto;
    /* @media (max-width: 1279px) {
      width: auto;
    } */
  }
  svg {
    margin-left: 12px;
  }
`;

export default BaseInfoEditModal;
