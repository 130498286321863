import React from "react";
import {
  Answer,
  isMultiChoiceAnswer,
  isOpenEndedAnswer,
} from "../../../apiClients/surveyAnswers";
import { EventHandlers } from "./hooks";
import { MultiChoiceAnswerField } from "./MultiChoiceAnswerField";
import { OpenEndedAnswerField } from "./OpenEndedAnswerField";
import styled from "styled-components";

type Props = {
  answer: Answer;
  eventHandlers: EventHandlers;
};

export const AnswerField = ({ answer, eventHandlers }: Props): JSX.Element => {
  if (isMultiChoiceAnswer(answer)) {
    return (
      <MultiChoiceAnswerField answer={answer} eventHandlers={eventHandlers} />
    );
  }
  if (isOpenEndedAnswer(answer)) {
    return (
      <OpenEndedAnswerField answer={answer} eventHandlers={eventHandlers} />
    );
  }

  return <></>;
};

export const AnswerContainer = styled.div`
  /* box-style */
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(245, 247, 250, 1);
`;
