import * as client from "./client";
import {
  BoardEducation,
  UpdateBoardEducationRequest,
} from "@shared/types/boardEducation";

export async function getBoardEducationByOrganizationId(
  organizationId: string
): Promise<BoardEducation> {
  return client.get<{ organizationId: string }, BoardEducation>(
    "/internal/boardEducation/getByOrganizationId",
    { organizationId }
  );
}

export async function updateBoardEducation(
  updateData: UpdateBoardEducationRequest
): Promise<BoardEducation> {
  return client.post<UpdateBoardEducationRequest, BoardEducation>(
    "/internal/boardEducation/updateBoardEducation",
    updateData
  );
}
