import React from "react";

type Props = {
  size?: number;
};
export const PulldownIcon = ({ size }: Props) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2075 16.9821C14.4935 16.6825 14.9682 16.6715 15.2679 16.9575L20.0774 21.5484C20.174 21.6406 20.326 21.6406 20.4226 21.5484L25.2321 16.9575C25.5318 16.6715 26.0065 16.6825 26.2925 16.9821C26.5785 17.2818 26.5675 17.7565 26.2679 18.0425L21.4583 22.6334C20.7821 23.2789 19.7179 23.2789 19.0417 22.6334L14.2321 18.0425C13.9325 17.7565 13.9215 17.2818 14.2075 16.9821Z"
        fill="#343741"
      />
    </svg>
  );
};
