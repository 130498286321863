import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { QueryOptions } from "src/hooks/query/QueryOptions";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import * as postApi from "src/apiClients/post";

export const PAGE_SIZE = 20;

export function usePosts(queryOptions: QueryOptions = {}) {
  const { apiContext } = useApiContext();

  const query = useInfiniteQuery({
    queryKey: ["api", "post", "list", apiContext],
    queryFn: async ({ pageParam }) => {
      return await postApi.getPosts({
        limit: PAGE_SIZE,
        skip: pageParam,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      const hasNext = lastPage.length === PAGE_SIZE;
      return hasNext ? PAGE_SIZE * pages.length : undefined;
    },
    ...queryOptions,
  });

  const posts = useMemo(() => {
    return query.data?.pages?.flatMap((page) => page || []) || [];
  }, [query.data]);

  return {
    posts,
    ...query,
  };
}

export function useInvalidatePosts() {
  const queryClient = useQueryClient();

  function invalidatePosts() {
    queryClient.invalidateQueries({
      queryKey: ["api", "post", "list"],
    });
  }

  return {
    invalidatePosts,
  };
}
