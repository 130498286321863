import React from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "../../../components/Modal";

interface Props {
  name: string;
  onSubmit: () => void;
  onClose: () => void;
  disabled: boolean;
}

export default function ApproveApplicationModal({
  name,
  onSubmit,
  onClose,
  disabled,
}: Props) {
  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>{name} を採用しますか？</ModalHeader>
        <ModalBody>
          <div>採用を確定すると、お相手に採用通知が届きます。</div>
          <ModalSubmitButtons
            submitText="採用する"
            onSubmit={onSubmit}
            onCancel={onClose}
            disabled={disabled}
          />
        </ModalBody>
      </ModalPortal>
    </>
  );
}
