import React, { useEffect } from "react";
import { PageContainer, PageHeader, PageBody } from "../../../components/Page";
import { usePolyfitUrl, usePolyfitHistory } from "../../../hooks/router";
import * as Typo from "../../../components/Typo";
import * as Responsive from "../../../components/Responsive";
import { ThumbsUp } from "../../../components/icons/SvgIcons";
import styled from "styled-components";

const IconCenter = styled.div`
  width: 100px;
  margin: 0 auto;
`;

export default function SignUpCompleteAdminPage() {
  const backUrl = usePolyfitUrl({ to: "PTA_INDEX" });
  const history = usePolyfitHistory();

  useEffect(() => {
    const sleep = async (msec: number) => {
      await new Promise((resolve) => setTimeout(resolve, msec));
    };
    sleep(5000).then(() => {
      history.push({
        to: "PTA_INDEX",
      });
    });
  }, []);

  return (
    <PageContainer>
      <PageHeader backTo={backUrl}>登録完了</PageHeader>
      <PageBody>
        <Responsive.Row>
          <Responsive.Col>
            <Typo.Heading3 style={{ textAlign: "center" }}>
              入力おめでとう
              <br />
              これから新しい活動が始まりますね
            </Typo.Heading3>
            <IconCenter>
              <ThumbsUp />
            </IconCenter>
          </Responsive.Col>
        </Responsive.Row>
      </PageBody>
    </PageContainer>
  );
}
