import { useMemo } from "react";
import {
  getBEApplicationListByAccountId,
  getBEApplicationListByStatus,
} from "src/apiClients/boardEducation/recruitmentApplication";
import { QueryOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppliedRecruitment } from "@shared/types/appliedRecruitment";
import {
  ApplicationStatus,
  RecruitmentApplicationListOrder,
} from "@shared/types/recruitmentApplication";
import { BERecruitmentApplication } from "@shared/types/boardEducation/recruitmentApplication";
import { useApiContext } from "src/apiClients/client";

export const useBERecruitmentApplicantListByAccountId = (
  accountId: string,
  queryOptions: QueryOptions = {}
) => {
  const query = useQuery({
    queryKey: [
      "api",
      "boardEducation",
      "recruitmentApplication",
      "getListByAccountId",
      accountId,
    ],
    queryFn: async () => {
      if (accountId !== "")
        return await getBEApplicationListByAccountId(accountId);
      return [];
    },
    ...queryOptions,
  });

  return {
    recruitmentList:
      (useMemo(() => query.data, [query.data]) as AppliedRecruitment[]) || [],
    isLoading: query.isLoading,
    refetch: query.refetch,
  };
};

export const useBEApplicantList = (
  status: ApplicationStatus | "ALL",
  name: string,
  order?: RecruitmentApplicationListOrder
) => {
  const { apiContext } = useApiContext();

  const query = useQuery<BERecruitmentApplication[] | null>({
    queryKey: [
      "api",
      "boardEducation",
      "recruitmentApplication",
      "getListByStatus",
      apiContext,
      status,
      name,
      order,
    ],
    queryFn: async () => {
      return await getBEApplicationListByStatus(status, name, order);
    },
  });

  return {
    applicantList: useMemo(() => query.data, [query.data]) || null,
    ...query,
  };
};

export const useInvalidateBEApplicantList = () => {
  const queryClient = useQueryClient();

  const invalidateBEApplicantList = () => {
    queryClient.invalidateQueries({
      queryKey: [
        "api",
        "boardEducation",
        "recruitmentApplication",
        "getListByStatus",
      ],
    });
  };

  return {
    invalidateBEApplicantList,
  };
};
