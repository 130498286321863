import React, { Dispatch, useEffect, useState, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { useSearchSchool } from "../../hooks/api/school";
import styled from "styled-components";

type SchoolStateType = {
  id: string;
  name: string;
  schoolCode: string;
  address: string;
  postalCode: string;
};

type Props = {
  selectSchool: SchoolStateType | undefined;
  setSelectSchool: Dispatch<SetStateAction<SchoolStateType | undefined>>;
  candidateHeight: number;
};

export const SearchSchool = ({
  selectSchool,
  setSelectSchool,
  candidateHeight,
}: Props) => {
  const { watch, setValue } = useForm();
  const { searchSchool, schools } = useSearchSchool();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    watch((value, { name, type }) => {
      if (name === "searchText") {
        // searchテキストの値が変わった場合にAPIを叩く
        if (value.searchText.lnegth === 0) return;
        searchSchool(value.searchText);
      }
    });
  }, [watch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setValue("searchText", e.target.value);
  };

  return (
    <div>
      <SearchWrapper>
        <SearchTextFieldLabel>
          <SearchTextField
            placeholder="例)  和田小学校"
            type="text"
            disabled={!!selectSchool}
            value={selectSchool?.name ?? searchText}
            onChange={handleChange}
          />
          <SearchRemoveButton
            type="button"
            onClick={() => {
              setSelectSchool(undefined);
              setSearchText("");
            }}
          />
        </SearchTextFieldLabel>
      </SearchWrapper>

      {searchText.length > 0 && (
        <SearchCandidate height={searchText.length === 0 ? 0 : candidateHeight}>
          {schools.map((school, index) => (
            <li
              key={index}
              onClick={() => {
                setSelectSchool({
                  id: school.id,
                  name: school.name,
                  schoolCode: school.code,
                  address: school.address,
                  postalCode: school.postalCode,
                });
                setSearchText("");
              }}
            >
              {school.name}
            </li>
          ))}
        </SearchCandidate>
      )}
    </div>
  );
};

export const SearchTextFieldLabel = styled.label`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%23343741' d='m11.271 11.978 3.872 3.873a.502.502 0 0 0 .708 0 .502.502 0 0 0 0-.708l-3.565-3.564c2.38-2.747 2.267-6.923-.342-9.532-2.73-2.73-7.17-2.73-9.898 0-2.728 2.729-2.728 7.17 0 9.9a6.955 6.955 0 0 0 4.949 2.05.5.5 0 0 0 0-1 5.96 5.96 0 0 1-4.242-1.757 6.01 6.01 0 0 1 0-8.486 6.004 6.004 0 0 1 8.484 0 6.01 6.01 0 0 1 0 8.486.5.5 0 0 0 .034.738Z'/%3e%3c/svg%3e")
      center / contain no-repeat;
  }
`;

export const SearchTextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  padding-left: 40px;
  ::placeholder {
    color: #aab4c4;
  }
`;

export const SearchCandidate = styled.ul<{ height: number }>`
  height: ${(props) => `${props.height}px`};
  overflow: scroll;
  width: 100%;

  li {
    width: 100%;
    padding: 12px 16px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 109, 228, 0.1);
    }
  }
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

export const SearchRemoveButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%343741" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>')
    no-repeat center center;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-right: 5px;
  border: none;
`;
