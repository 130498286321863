import React, { useState } from "react";
import { Label } from "../../../components/form/Label";
import { MobileViewOnly, PcViewOnly } from "../../../components/Responsive";
import styled from "styled-components";
import {
  ChevronIcon,
  ReverseChevronIcon,
} from "../../../components/icons/SvgIcons";
import { InputRecruitmentFilter } from "../../../components/InputRecruitmentFilter";

type Props = {
  recruitmentFilter: string;
  setRecruitmentFilter: (filter: string) => void;
};

export const RecruitmentApplicationsFilter = ({
  recruitmentFilter,
  setRecruitmentFilter,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  return (
    <>
      <PcViewOnly>
        <Label>フィルター設定</Label>
        <InputRecruitmentFilter
          inputFilter={recruitmentFilter}
          handleInputFilter={setRecruitmentFilter}
        />
      </PcViewOnly>
      <MobileViewOnly>
        <ToggleButton onClick={() => setIsOpen(!isOpen)}>
          <Label>フィルター設定</Label>
          {isOpen ? (
            <ReverseChevronIcon size="21" />
          ) : (
            <ChevronIcon size="21" />
          )}
        </ToggleButton>
        {isOpen && (
          <InputRecruitmentFilter
            inputFilter={recruitmentFilter}
            handleInputFilter={setRecruitmentFilter}
          />
        )}
      </MobileViewOnly>
    </>
  );
};

const ToggleButton = styled.button`
  outline: none;
  border: none;
  background: none;
  display: flex;
`;
