import { UpdateParticipantChatRoomRequest } from "@shared/types/participantChatRoom";
import * as client from "./client";

export type ParticipantChatRoomUpdateArgsType = {
  chatRoomId: string;
};

export async function updateParticipantChatRoom(
  arg: ParticipantChatRoomUpdateArgsType
): Promise<void> {
  await client.post<{ arg: ParticipantChatRoomUpdateArgsType }>(
    "/participant-chat-room/update",
    { arg }
  );
}

export async function approveParticipantChatRoom(chatRoomId: string) {
  await client.post<UpdateParticipantChatRoomRequest, {}>(
    "/participant-chat-room/approve",
    {
      chatRoomId,
    }
  );
}

export async function rejectParticipantChatRoom(chatRoomId: string) {
  await client.post<UpdateParticipantChatRoomRequest, {}>(
    "/participant-chat-room/reject",
    {
      chatRoomId,
    }
  );
}

export async function deleteParticipantChatRoom(chatRoomId: string) {
  await client.post<UpdateParticipantChatRoomRequest, {}>(
    "/participant-chat-room/delete",
    {
      chatRoomId,
    }
  );
}
