import React, { FC, useCallback } from "react";
import styled from "styled-components";
import { PdfIcon } from "../../components/icons/PdfIcon";
import { Margin } from "../../components/Margin";
import { getDownloadUrl } from "../../apiClients/storage";

type props = {
  filePath: string;
  fileName: string;
  children: React.ReactNode;
};

export const PdfBadge: FC<props> = ({ children, filePath, fileName }) => {
  const handleOnClick = useCallback(async () => {
    const url = await getDownloadUrl(filePath, fileName);
    window.location.href = url;
  }, [filePath]);

  return (
    <PdfFileLink onClick={handleOnClick}>
      <PdfIcon size={25} />
      <Margin marginRight={5} />
      {children}
    </PdfFileLink>
  );
};

const PdfFileLink = styled.a`
  border: 1px solid #d3dae6;
  border-radius: 25px;
  width: auto;
  padding: 3.5px 15px;
  background-color: #f5f7fa;
  color: #394559;
  align-items: center;
  display: inline-flex;
  font-size: 14px;
`;
