import React, { useState } from "react";
import { PulldownIcon } from "../../../components/icons/PulldownIcon";
import {
  BelongPartSelect as SelectInput,
  FormValues,
} from "./CareerInfoCreateModal";
import { UseFormClearErrors, UseFormSetValue } from "react-hook-form";
import {
  DropdownMenu,
  DropdownMenuLabel,
  DropdownMenuLi,
  DropdownMenuUl,
  DropdownMenuWrapper,
} from "./SelectChildDropdown";

type ItemType = { isHighestPart: boolean; label: string };
type Props = {
  formValues: FormValues;
  setFormValues: UseFormSetValue<FormValues>;
  defaultValue: ItemType | null;
  clearErrors: UseFormClearErrors<FormValues>;
};

export const SelectHighestPartDropdown = ({
  setFormValues,
  defaultValue,
  clearErrors,
}: Props) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedIsHighestPart, setSelectedIsHighestPart] =
    useState<ItemType | null>(defaultValue);

  const setValue = (value: ItemType) => {
    setSelectedIsHighestPart(value);
    setFormValues("isHighestPart", value?.isHighestPart);
    clearErrors("isHighestPart");
  };

  return (
    <>
      <SelectInput
        isOpen={isOpenDropdown}
        isEmpty={false}
        onClick={() => setIsOpenDropdown(!isOpenDropdown)}
      >
        <div style={{ height: "20px" }}>
          {selectedIsHighestPart?.label ?? ""}
        </div>
        <span>
          <PulldownIcon size={40} />
        </span>
      </SelectInput>
      <div style={isOpenDropdown ? { height: "100px" } : {}}>
        <SelectHighestPartDropdownMenu
          isOpen={isOpenDropdown}
          value={selectedIsHighestPart}
          setValue={setValue}
          close={() => setIsOpenDropdown(false)}
        />
      </div>
    </>
  );
};

const SelectHighestPartDropdownMenu = ({
  isOpen,
  close,
  value,
  setValue,
}: {
  isOpen: boolean;
  value: ItemType | null;
  setValue: (arg: ItemType) => void;
  close: () => void;
}) => {
  if (!isOpen) return null;

  return (
    <>
      <DropdownMenuWrapper>
        <DropdownMenu height={76}>
          <DropdownMenuUl>
            <DropdownMenuLi isLast={false}>
              <input
                type="radio"
                name="highest-part"
                id="highest-part"
                checked={!!value && value.isHighestPart}
                onChange={() => {
                  setValue({
                    isHighestPart: true,
                    label: "はい",
                  });
                  close();
                }}
              />
              <DropdownMenuLabel htmlFor="highest-part">はい</DropdownMenuLabel>
            </DropdownMenuLi>

            <DropdownMenuLi isLast={true}>
              <input
                type="radio"
                name="not-highest-part"
                id="not-highest-part"
                checked={!!value && !value.isHighestPart}
                onChange={() => {
                  setValue({
                    isHighestPart: false,
                    label: "いいえ",
                  });
                  close();
                }}
              />
              <DropdownMenuLabel htmlFor="not-highest-part">
                いいえ
              </DropdownMenuLabel>
            </DropdownMenuLi>
          </DropdownMenuUl>
        </DropdownMenu>
      </DropdownMenuWrapper>
    </>
  );
};
