import React, { useState } from "react";
import { ModalBackGround } from "src/components/Common/Menu";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "src/components/Modal";
import { useForm } from "react-hook-form";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { z } from "zod";
import { Label } from "src/components/form/Label";
import styled from "styled-components";
import { Organization } from "src/apiClients/organization";
import { Margin } from "../../../../components/Margin";
import { inviteBEMember } from "src/apiClients/invitation";
import { zodResolver } from "@hookform/resolvers/zod";
import { useInvalidateInvitationBEUserListByOrganizationId } from "src/hooks/query/internal/invitations/invitationBEUserList";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  organization: Organization;
};

const FormValueSchema = z.object({
  lastName: z.string().min(1, "入力必須項目です"),
  firstName: z.string().min(1, "入力必須項目です"),
  lastNameKana: z
    .string()
    .min(1, "入力必須項目です")
    .refine((value) => /^[ぁ-んーー]+$/u.test(value), {
      message: "ひらがなで入力してください",
    }),
  firstNameKana: z
    .string()
    .min(1, "入力必須項目です")
    .refine((value) => /^[ぁ-んーー]+$/u.test(value), {
      message: "ひらがなで入力してください",
    }),
  email: z
    .string()
    .min(1, "入力必須項目です")
    .email("メールアドレスのフォーマットで入力してください"),
  phoneNumber: z
    .string()
    .regex(/^[0-9]+$/, {
      message: "ハイフンは入力せず、半角数字のみで入力してください",
    })
    .regex(new RegExp("^0{1}[0-9]{9,10}$"), "電話番号の形式で入力してください"),
});

export type FormData = z.infer<typeof FormValueSchema>;

export const CreateBoardEducationModal = ({
  isOpen,
  onClose,
  organization,
}: Props) => {
  if (!isOpen) return null;

  const toast = useToast();
  const { invalidateInvitationBEUserListByOrganizationId } =
    useInvalidateInvitationBEUserListByOrganizationId();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<FormData>({
    defaultValues: {
      email: "",
      lastName: "",
      firstName: "",
      lastNameKana: "",
      firstNameKana: "",
      phoneNumber: "",
    },
    mode: "onChange",
    resolver: zodResolver(FormValueSchema),
  });

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async (data: FormData) => {
    try {
      setDoubleClickBlocked(true);
      await inviteBEMember({ ...data, organizationId: organization.id });
      invalidateInvitationBEUserListByOrganizationId(organization.id);

      toast.success("招待しました");

      onClose();
    } catch (err) {
      if (err instanceof APIError) {
        toast.error(err.message);
      }
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>{`「${organization.name}」のユーザー招待`}</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Label required>メールアドレス</Label>
            <TextField
              type="email"
              placeholder="email@example.com"
              {...register("email")}
            />
            {isSubmitted && errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}

            <Margin marginTop={8} />
            <Label required>お名前</Label>
            <HorizontalInputWrapper>
              <SelectWrapper>
                <TextField placeholder="例）山田" {...register("lastName")} />
                {isSubmitted && errors.lastName && (
                  <ErrorMessage>{errors.lastName.message}</ErrorMessage>
                )}
              </SelectWrapper>
              <SelectWrapper>
                <TextField placeholder="例）太郎" {...register("firstName")} />
                {isSubmitted && errors.firstName && (
                  <ErrorMessage>{errors.firstName.message}</ErrorMessage>
                )}
              </SelectWrapper>
            </HorizontalInputWrapper>
            <Margin marginTop={8} />
            <HorizontalInputWrapper>
              <SelectWrapper>
                <TextField
                  placeholder="例）やまだ"
                  {...register("lastNameKana")}
                />
                {isSubmitted && errors.lastNameKana && (
                  <ErrorMessage>{errors.lastNameKana.message}</ErrorMessage>
                )}
              </SelectWrapper>
              <SelectWrapper>
                <TextField
                  placeholder="例）たろう"
                  {...register("firstNameKana")}
                />
                {isSubmitted && errors.firstNameKana && (
                  <ErrorMessage>{errors.firstNameKana.message}</ErrorMessage>
                )}
              </SelectWrapper>
            </HorizontalInputWrapper>

            <Margin marginTop={16} />
            <Label>電話番号（日中に連絡が取れる番号）</Label>
            <TextField
              placeholder="例)  09012345678"
              {...register("phoneNumber")}
            />
            {isSubmitted && errors.phoneNumber && (
              <ErrorMessage>{errors.phoneNumber.message}</ErrorMessage>
            )}

            <ModalSubmitButtons
              onCancel={onClose}
              type="submit"
              disabled={doubleClickBlocked}
              submitText="招待"
            />
          </form>
        </ModalBody>
      </ModalPortal>
      <ModalBackGround isOpen={isOpen} />
    </>
  );
};

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;

const HorizontalInputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 16px;
`;

const SelectWrapper = styled.div`
  min-width: 240px;
  @media (max-width: 1279px) {
    min-width: 163px;
  }
`;
