import React, { useEffect, useState } from "react";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../components/Modal";
import { ModalHeader } from "../../pages/pta/admin/ProfilePage";
import { Switch } from "../../components/form/Switch";
import { useForm } from "react-hook-form";
import { useToast } from "../../components/Toast";
import styled from "styled-components";
import { useOrganization } from "../../hooks/recoil/organization";
import { logger } from "src/utils/logger";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type Form = {
  requireAdminToUpdateCareer: boolean;
};

export const UpdateCareerModal = ({ isOpen, onClose }: Props) => {
  const { organization, fetchOrganization, writeOrganization } =
    useOrganization({});

  const toast = useToast();

  const { register, setValue, getValues } = useForm<Form>();
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async () => {
    setDoubleClickBlocked(true);
    try {
      await writeOrganization({
        requireAdminToUpdateCareer: getValues("requireAdminToUpdateCareer"),
      });
      onClose();
      toast.success("保存しました");
      await fetchOrganization();
    } catch (error) {
      logger.error(error);
      toast.error("保存に失敗しました");
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  useEffect(() => {
    setValue(
      "requireAdminToUpdateCareer",
      organization?.requireAdminToUpdateCareer ?? false
    );
  }, [organization]);

  return (
    <>
      {isOpen && (
        <ModalPortal
          onClose={() => {
            onClose();
          }}
        >
          <ModalHeader>経歴入力設定</ModalHeader>
          <ModalBody>
            <Text>経歴の入力を管理者のみに限定できます。</Text>
            <Switch
              label="経歴の入力を管理者のみに限定する"
              register={register("requireAdminToUpdateCareer")}
              value={1}
            />
            <ModalSubmitButtons
              disabled={doubleClickBlocked}
              onSubmit={onSubmit}
              onCancel={onClose}
            />
          </ModalBody>
        </ModalPortal>
      )}
    </>
  );
};

const Text = styled.p`
  margin-bottom: 20px;
`;
