import { useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import * as chatRoomApi from "src/apiClients/boardEducation/chatRoom";
import { ChatRoomFilterType } from "@shared/types/boardEducation/chatRoom";
import { ChatRoomEntity } from "@shared/types/chatRoom";

export const useGetChatRooms = (chatRoomFilter: ChatRoomFilterType) => {
  const query = useQuery({
    queryKey: ["api", "chatRooms", chatRoomFilter],
    queryFn: () => chatRoomApi.fetchChatRooms(chatRoomFilter),
  });

  return {
    chatRooms: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
};

export const useInvalidateChatRooms = () => {
  const queryClient = useQueryClient();

  const invalidateChatRooms = () => {
    queryClient.invalidateQueries({
      queryKey: ["api", "chatRooms"],
    });
  };

  return {
    invalidateChatRooms,
  };
};

export const useSetChatRoomsQueryData = (
  chatRoomFilter: ChatRoomFilterType
) => {
  const queryClient = useQueryClient();

  const updateChatRooms = (
    updater: (
      prev: ChatRoomEntity[] | undefined
    ) => ChatRoomEntity[] | undefined
  ) => {
    queryClient.setQueryData(["api", "chatRooms", chatRoomFilter], updater);
  };

  return { updateChatRooms };
};
