import React from "react";
import styled from "styled-components";
import { ChatInputForm } from "src/components/form/ChatInputForm";

type GroupContentChatMessageInputAreaProps = {
  setInput: React.Dispatch<React.SetStateAction<string>>;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  canSend: boolean;
  onSend: () => void;
};

export const GroupContentChatMessageInputArea = (
  props: GroupContentChatMessageInputAreaProps
) => {
  return (
    <>
      <ChatMessageInputContainer>
        <ChatMessageInputArea>
          <ChatInputForm
            placeholder="メッセージを入力"
            setValue={props.setInput}
            setFiles={props.setFiles}
            canSend={props.canSend}
            onSend={props.onSend}
          />
        </ChatMessageInputArea>
      </ChatMessageInputContainer>
    </>
  );
};

const ChatMessageInputContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChatMessageInputArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
