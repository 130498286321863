import styled from "styled-components";
import React, { useState, useEffect, useCallback } from "react";
import { Career } from "../../../apiClients/career";
import { Button } from "../../../components/Button";
import { useModal } from "../../../components/Modal";
import { toDateFormatYearMonth } from "../../../utils/time";
import { useCareer } from "../../../hooks/api/career";
import { useCurrentUser } from "../../../hooks/recoil/user";
import * as Typo from "../../../components/Typo";
import CareerEditModal from "./CareerInfoEditModal";
import CareerCreateModal from "./CareerInfoCreateModal";
import { PCBaseInfoWrapper } from "../../../pages/pta/admin/ProfilePage";
import { Child } from "../../../hooks/api/child";
import { isPtaAdminRole } from "../../../utils/types/role";
import { useOrganization } from "../../../hooks/recoil/organization";

type PencilButtonPropsType = {
  career: Career;
  openCareerId: string;
  setOpenCareerId: (arg: string) => void;
  isModal: boolean;
  setIsModal: (arg: boolean) => void;
  show: () => void;
};

export const PencilButton = ({
  career,
  openCareerId,
  setOpenCareerId,
  show,
  isModal,
  setIsModal,
}: PencilButtonPropsType) => {
  const onClickEditButton = useCallback(() => {
    setIsModal(!isModal);
    show();
    if (openCareerId !== career.id && !isModal) {
      setOpenCareerId(career.id);
    } else {
      setOpenCareerId("");
    }
  }, []);

  return (
    <EditButton onClick={onClickEditButton}>
      <PencilSvg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.1481 3.14807L11 2L2 11V14H5L14 5L12.8561 3.85607L4.854 11.854C4.756 11.951 4.628 12 4.5 12C4.372 12 4.244 11.951 4.146 11.854C3.951 11.658 3.951 11.342 4.146 11.146L12.1481 3.14807ZM11 1C11.256 1 11.512 1.098 11.707 1.293L14.707 4.293C15.098 4.683 15.098 5.317 14.707 5.707L5.707 14.707C5.52 14.895 5.265 15 5 15H2C1.448 15 1 14.552 1 14V11C1 10.735 1.105 10.48 1.293 10.293L10.293 1.293C10.488 1.098 10.744 1 11 1ZM5 14H2V11L5 14Z"
          fill="#5A606B"
        />
      </PencilSvg>
    </EditButton>
  );
};

const CareerInfoContent = ({
  userChildren,
  userId,
}: {
  userChildren: Child[];
  userId: string;
}) => {
  const [, { show, close }] = useModal();
  const currentUser = useCurrentUser();
  const { organization } = useOrganization({});
  const [isModal, setIsModal] = useState(false);
  const [{ careerList, isLoadingCareer }, { fetchCareerList }] =
    useCareer(userId);
  const currentYear = new Date().getFullYear();
  const isLoading = isLoadingCareer;

  useEffect(() => {
    fetchCareerList();
  }, []);

  const [openCareerId, setOpenCareerId] = useState("");

  const onClose = useCallback(() => {
    setIsModal(false);
    setOpenCareerId("");
    close();
    fetchCareerList();
  }, [setIsModal]);

  const onCreateOpen = useCallback(() => {
    setIsModal(true);
    show();
  }, [setIsModal]);

  const isCurrentUser = currentUser.id === userId;
  /** 経歴の編集を管理者のみに限定しているか */
  const requireAdminToUpdateCareer =
    organization?.requireAdminToUpdateCareer ?? false;

  /**
   * 経歴の編集権限があるか
   * ① PTA管理者
   * または
   * ② 自分の経歴 かつ organization.requireAdminToUpdateCareer が false
   */
  const isEditable =
    isPtaAdminRole(currentUser.role) ||
    (isCurrentUser && !requireAdminToUpdateCareer);

  return (
    <PCBaseInfoWrapper>
      <CareerHeader>
        <CareerTitle>経歴</CareerTitle>
        {!isLoading && isEditable && (
          <CareerAddButtonSP>
            <Button
              fill
              size="large"
              color="text"
              onClick={() => {
                setIsModal(!isModal);
                show();
              }}
            >
              経歴を追加
            </Button>
          </CareerAddButtonSP>
        )}
      </CareerHeader>
      <CareerPCWrap>
        <div style={{ position: "relative" }}>
          {isLoading ? (
            <>読み込み中…</>
          ) : (
            <CareerListWrapper>
              {careerList.length > 0 &&
                careerList.map((career: Career, i: number) => {
                  const startDate = new Date(career.startDate);
                  const endDate = career.endDate
                    ? new Date(career.endDate)
                    : null;

                  // NOTE: 最新の経歴かどうか
                  const isLatestCareer = i === 0;

                  // NOTE: 終了日を文字列で比較(Dateだとだるいので)
                  const endDateToString = endDate
                    ? `${endDate.getFullYear()}/${
                        endDate.getMonth() + 1
                      }/${endDate.getDate()}`
                    : null;

                  // NOTE: 現在ラベルがつく条件
                  // 1. 最新の経歴でかつ、終了年がない → 現在もその経歴が続いているとpolyfit側で解釈するため
                  // 2. 最新の経歴でかつ、終了年が今年度の最終日（2023年度は2024年3月31日）
                  const currentCareerCondition =
                    (isLatestCareer && !endDate) ||
                    (isLatestCareer &&
                      endDateToString === `${currentYear + 1}/3/31`);

                  return (
                    <div key={i}>
                      <div>
                        <CareerParentCell key={career.id}>
                          <CareerTermWrapper>
                            <CareerTerm>
                              <div>
                                {startDate && toDateFormatYearMonth(startDate)}-
                              </div>
                              <div>
                                {currentCareerCondition
                                  ? "現在"
                                  : endDate
                                  ? toDateFormatYearMonth(endDate)
                                  : ""}
                              </div>
                              {i + 1 !== careerList.length && <Rect />}
                            </CareerTerm>

                            <SPCurrentBudgeWrapper>
                              {currentCareerCondition && (
                                <SPBadge color="primary">現在</SPBadge>
                              )}
                              {career.childId ? (
                                <SPBadge color="success">在校生</SPBadge>
                              ) : (
                                <SPBadge color="danger">卒業済み</SPBadge>
                              )}
                            </SPCurrentBudgeWrapper>

                            <SPPencilButtonWrapper>
                              {isEditable && (
                                <PencilButton
                                  career={career}
                                  openCareerId={openCareerId}
                                  setOpenCareerId={setOpenCareerId}
                                  isModal={isModal}
                                  setIsModal={setIsModal}
                                  show={show}
                                />
                              )}
                            </SPPencilButtonWrapper>
                          </CareerTermWrapper>
                          <div>
                            <CareerPtaName>
                              {career.organization.name}
                              PTA
                            </CareerPtaName>
                            <PastCareerSmallCell>
                              {career.belong || career.part
                                ? `${
                                    (career.belong && career.belong.name) || ""
                                    // eslint-disable-next-line no-irregular-whitespace
                                  }　${(career.part && career.part.name) || ""}`
                                : "所属役職なし"}
                            </PastCareerSmallCell>
                          </div>

                          <OptionalWrapper>
                            {currentCareerCondition && (
                              <Badge color="primary">現在</Badge>
                            )}
                            {career.childId ? (
                              <Badge color="success">在校生</Badge>
                            ) : (
                              <Badge color="danger">卒業済み</Badge>
                            )}

                            {isEditable && (
                              <PencilButton
                                career={career}
                                openCareerId={openCareerId}
                                setOpenCareerId={setOpenCareerId}
                                isModal={isModal}
                                setIsModal={setIsModal}
                                show={show}
                              />
                            )}
                          </OptionalWrapper>
                        </CareerParentCell>
                        {isModal && openCareerId === career.id && (
                          <CareerEditModal
                            userId={userId}
                            inputCareer={career}
                            userChildren={userChildren}
                            isOpen={openCareerId === career.id}
                            onClose={onClose}
                            refetch={fetchCareerList}
                          ></CareerEditModal>
                        )}
                      </div>
                      {i + 1 !== careerList.length && <SPRect />}
                    </div>
                  );
                })}
            </CareerListWrapper>
          )}
        </div>
        <CareerAddButton>
          {!isLoading && isEditable && (
            <Button fill color="text" onClick={onCreateOpen}>
              経歴を追加
            </Button>
          )}
          {isModal && openCareerId === "" && (
            <CareerCreateModal
              organizationName={organization?.name ?? ""}
              userChildren={userChildren}
              isOpen={openCareerId === ""}
              onClose={onClose}
              userId={userId}
            ></CareerCreateModal>
          )}
        </CareerAddButton>
      </CareerPCWrap>
    </PCBaseInfoWrapper>
  );
};

export const PastCareerWrap = styled.div`
  margin-top: 18px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #e4e6f3;
  padding: 20px;
`;

export const CareerTermWrapper = styled.div`
  @media (max-width: 500px) {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
`;
const SPCurrentBudgeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media (min-width: 501px) {
    display: none;
  }
`;

const SPPencilButtonWrapper = styled.div`
  margin-left: auto;
  @media (min-width: 501px) {
    display: none;
  }
`;

const SPBadge = styled.div<{
  color: "primary" | "success" | "danger";
}>`
  background: ${(props) => {
    if (props.color === "primary") return "#79AAD9";
    if (props.color === "success") return "#6DCCB1";
    if (props.color === "danger") return "#ff7e62";
  }};
  padding: 4px 8px;
  font-weight: normal;
  height: fit-content;
  font-size: 12px;
  border-radius: 5px;
  color: #ffffff;
`;

const CareerListWrapper = styled.div`
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

const OptionalWrapper = styled.div`
  display: flex;
  margin-left: auto;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Badge = styled.div<{
  color: "primary" | "success" | "danger";
}>`
  background: ${(props) => {
    if (props.color === "primary") return "#79AAD9";
    if (props.color === "success") return "#6DCCB1";
    if (props.color === "danger") return "#ff7e62";
  }};
  padding: 4px 8px;
  font-weight: normal;
  height: fit-content;
  font-size: 12px;
  border-radius: 5px;
  margin: 2px 0 0 10px;
  color: #ffffff;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const CareerTerm = styled.div`
  font-weight: normal;
  color: #69707d;
  text-align: left;
  padding-right: 16px;

  @media (max-width: 1025px) {
    padding-right: 8px;
  }

  @media (max-width: 500px) {
    padding-right: 0;
  }

  div {
    @media (max-width: 1025px) {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #69707d;
      white-space: nowrap;
    }
  }
`;

export const PastCareerCell = styled.th`
  display: flex;
`;

export const PastCareerSmallCell = styled.th`
  display: flex;
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  color: #343741;
  margin-top: 3px;
`;

const CareerAddButton = styled.div`
  margin-left: auto;
  ${Button} {
    white-space: nowrap;
    padding: 12px 24px;

    @media (max-width: 1025px) {
      padding: 8px 16px;
      margin-left: 12px;
    }
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const CareerTitle = styled(Typo.Heading2)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #343741;
  margin-bottom: 20px;
`;

export const CareerHeader = styled.div`
  display: flex;
`;

export const CareerPtaName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #343741;
  text-align: left;

  @media (max-width: 1025px) {
    font-size: 14px;
  }
`;

export const CareerPCWrap = styled.div`
  width: 100%;

  @media (min-width: 500px) {
    display: flex;
  }
`;
const CareerAddButtonSP = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: none;

  @media (max-width: 500px) {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    ${Button} {
      font-size: 12px;
    }
  }
`;

const PencilSvg = styled.svg`
  transition: opacity 0.2s cubic-bezier(0.3, 0.3, 0.3, 1) 0s;
  color: rgba(0, 0, 0, 0.4);
  vertical-align: middle;
  margin-left: 16px;
  width: 24px;
  height: 24px;

  @media (max-width: 500px) {
    background: rgba(105, 112, 125, 0.2);
    border-radius: 4px;
    padding: 4px;
    width: 28px;
    height: 28px;
    margin-left: 0;
  }
`;

const EditButton = styled.span`
  @media (max-width: 500px) {
    opacity: 1;
  }
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

export const CareerParentCell = styled.th`
  display: flex;

  @media (max-width: 500px) {
    display: block;
  }
`;

const StyledRectWrapper = styled.div`
  padding: 5px 0;
  @media (max-width: 500px) {
    display: none;
  }
`;
const StyledRectWrapperSP = styled.div`
  padding: 5px 0;
  @media (min-width: 501px) {
    display: none;
  }
`;

const SPRect = () => {
  return (
    <StyledRectWrapperSP>
      <svg
        width="2"
        height="45"
        viewBox="0 0 2 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="2" height="45" rx="1" fill="#D3DAE6" />
      </svg>
    </StyledRectWrapperSP>
  );
};

export const Rect = () => {
  return (
    <StyledRectWrapper>
      <svg
        width="2"
        height="45"
        viewBox="0 0 2 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="2" height="45" rx="1" fill="#D3DAE6" />
      </svg>
    </StyledRectWrapper>
  );
};

export default CareerInfoContent;
