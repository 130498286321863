import React, { memo } from "react";

type Props = {
  size: number;
};

export const FileUpload = memo(({ size = 16 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 448 512"
      //   fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#AAB4C4"
        d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-102.1-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31L216 408z"
      />
    </svg>
  );
});
