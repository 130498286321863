export const UserType = {
  PARENT: "PARENT",
  TEACHER: "TEACHER",
  RESIDENT: "RESIDENT",
} as const;

export const ptaUserTypeOptions = [
  { value: "PARENT", text: "保護者" },
  { value: "TEACHER", text: "先生" },
] as const;

export const csUserTypeOptions = [
  { value: "RESIDENT", text: "地域住民" },
] as const;

export const userTypeOptions = [...ptaUserTypeOptions, ...csUserTypeOptions];

export type UserType = (typeof UserType)[keyof typeof UserType];
