import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Heading2 } from "../../../components/Typo";
import { PCBaseInfoWrapper } from "../../../pages/pta/admin/ProfilePage";
import { QrcodeView } from "./QrcodeView";
import { useCurrentUserHook } from "../../../hooks/recoil/user";
import * as invitationApi from "../../../apiClients/invitation";

export const ResidentChatQRCode = () => {
  const { user } = useCurrentUserHook();
  const [residentToken, setResidentToken] = useState<string>();

  useEffect(() => {
    invitationApi
      .getOrGenerateInvitationResidentToken({
        flow: "ORGANIZATION_CHAT",
      })
      .then((token) => {
        setResidentToken(token);
      });
  }, []);

  if (!residentToken || !user.account?.organizationId) return null;

  const link = `${location.origin}/organization_chat_registration_handler?token=${residentToken}&openExternalBrowser=1`;

  const isInternalAdmin = user.internalRole === "INTERNAL_ADMIN";

  return (
    <>
      <PCBaseInfoWrapper>
        <Title>管理者さまへの連絡用QRコード</Title>
        <QrcodeView link={link} />
        {isInternalAdmin ? <div>AdminOnly: {link}</div> : null}
      </PCBaseInfoWrapper>
    </>
  );
};

const Title = styled(Heading2)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  color: #343741;
  margin-bottom: 12px;
`;
