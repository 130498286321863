import React, { useCallback, useEffect, useRef, useState } from "react";
import { PageContainer, PageHeader, PageBody } from "src/components/Page";
import * as Responsive from "src/components/Responsive";
import { AirPlaneIcon } from "src/components/icons/SvgIcons";
import styled from "styled-components";
import { Paragraph } from "src/components/Typo";
import { Button } from "src/components/Button";
import { usePolyfitHistory } from "src/hooks/router";
import { logger } from "src/utils/logger";

type Props = {
  token: string;
  verify: (token: string) => Promise<{ isNewUser: boolean }>;
};

export const InviteMemberActivationContent = ({ token, verify }: Props) => {
  const history = usePolyfitHistory();
  const verifyCallRef = useRef(false);
  const [headerMessage, setHeaderMessage] = useState("認証中です...");
  const [bodyMessage, setBodyMessage] = useState("");
  const [isNewUser, setIsNewUser] = useState<boolean>();

  const runVerify = useCallback(async () => {
    try {
      const result = await verify(token);
      setIsNewUser(result.isNewUser);
      setHeaderMessage("メールアドレスの認証が完了しました！");
      setBodyMessage(
        result.isNewUser
          ? "アカウント発行メールが届くまで少々お待ちください。届いたメールに仮パスワードが記載されているので、そちらでログインしてください。"
          : "既にpolyfitのアカウントが存在します。polyfitアカウントでログインしてください。"
      );
    } catch (err) {
      if (err instanceof Error) {
        setHeaderMessage("メールアドレスの認証に失敗しました");
        setBodyMessage(err.message);
      }
      logger.error(err);
    }
  }, [token, verify]);

  useEffect(() => {
    if (verifyCallRef.current) return;
    verifyCallRef.current = true;

    runVerify();
  }, [token, verify]);

  return (
    <Container>
      <AirPlaneIcon />
      <PageHeader>{headerMessage}</PageHeader>
      <PageBody>
        <Responsive.Row>
          <Responsive.Col>
            <BodyMessage>{bodyMessage}</BodyMessage>
          </Responsive.Col>
        </Responsive.Row>
        {isNewUser === false && (
          <Responsive.Row>
            <Responsive.Col className="login-button">
              <Button
                size="large"
                fill
                onClick={() => {
                  history.push({ to: "LOGIN", query: {} });
                }}
              >
                ログイン画面へ
              </Button>
            </Responsive.Col>
          </Responsive.Row>
        )}
      </PageBody>
    </Container>
  );
};

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 702px;
  margin: 0 auto;
  padding-top: 124px;
  .login-button {
    display: inherit;
    justify-content: center;
    margin-top: 12px;
  }

  @media (max-width: 1279px) {
    max-width: 343px;
    padding-top: 120px;
  }
`;

const BodyMessage = styled(Paragraph)`
  text-align: center;
`;
