import React, { useState, useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";

import { Document, Page, pdfjs } from "react-pdf";
import { getToken } from "../apiClients/client";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type WrapProps = { width?: string; height?: string; pageWidth?: string };
const Wrap = styled.div<WrapProps>`
  margin: 0 auto;

  > .Document {
    overflow: scroll;
    width: 100%;
    aspect-ratio: 210 / 297; // PDFの縦横比
    border-radius: 4px;

    display: flex;
    flex-direction: column;

    background-color: #00003230;

    > .Page {
      width: 100% !important;

      &:first-child {
        margin-top: 0;
      }

      > canvas {
        width: 100% !important;
        max-width: 500px !important;
        height: auto !important;
      }

      > .react-pdf__Page__textContent {
        display: none;
      }

      > .annotationLayer {
        display: none;
      }
    }
  }
`;

type PdfViewerProps = {
  file: string;
} & WrapProps;

export const PdfViewer = ({ file, ...wrapProps }: PdfViewerProps) => {
  const [pageCount, setPageCount] = useState<number>();
  const [idToken, setIdToken] = useState<string>();
  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy | null>(null);
  const onError = useCallback(() => {}, []);
  const onLoad = useCallback((pdf: { numPages: number }) => {
    setPageCount(pdf.numPages);
  }, []);

  const fileMemo = useMemo(() => {
    return {
      url: file,
    };
  }, [file]);

  const options = useMemo(() => {
    return {
      httpHeaders: {
        authorization: `Bearer ${idToken}`,
      },
      cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
      cMapPacked: true,
    };
  }, [idToken]);

  useEffect(() => {
    const loadPdf = async () => {
      const token = await getToken();
      setIdToken(token);

      if (token) {
        const loadingTask = pdfjs.getDocument({
          url: file,
          cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
          httpHeaders: {
            authorization: `Bearer ${token}`,
          },
        });

        try {
          const pdf = await loadingTask.promise;
          setPdfDocument(pdf);
          setPageCount(pdf.numPages);
        } catch (error) {
          console.log(error);
        }
      }
    };

    loadPdf();
  }, [file]);

  return (
    <Wrap
      style={{ minHeight: "240px", display: "flex", alignItems: "center" }}
      {...wrapProps}
    >
      {pdfDocument && (
        // @ts-expect-error todo
        <Document
          className="Document"
          file={fileMemo}
          onLoadSuccess={onLoad}
          onLoadError={onError}
          options={options}
        >
          {Array.from(new Array(pageCount), (_, index) => (
            <Page
              className="Page"
              key={index}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          ))}
        </Document>
      )}
    </Wrap>
  );
};
