import React from "react";

type Props = {
  size?: number;
};
export const PaperclipIcon = ({ size = 24 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7609 3.02815L4.56707 12.8556C3.08694 14.2825 2.86855 16.6301 4.26927 18.1297C5.69578 19.6613 8.14007 19.7881 9.72876 18.4129C9.78028 18.3683 9.83048 18.3224 9.87947 18.2752L18.3614 10.0982C18.6633 9.8071 18.6633 9.33518 18.3614 9.04407C18.0595 8.753 17.57 8.753 17.268 9.04407L8.87962 17.1309C8.02167 17.958 6.62264 18.1165 5.68349 17.376C4.68909 16.5971 4.53791 15.1885 5.3459 14.2298C5.39615 14.1702 5.44954 14.1131 5.50588 14.0588L15.8544 4.08225C17.3641 2.62686 19.8117 2.62686 21.3214 4.08225C22.8311 5.53764 22.8311 7.89733 21.3214 9.35276L10.9497 19.3517C8.95092 21.2786 5.70477 21.5905 3.52532 19.8552C1.21443 18.0268 0.878473 14.7384 2.77513 12.5105C2.88744 12.3786 3.00632 12.2521 3.13148 12.1314L11.801 3.77351C12.1029 3.48245 12.1029 3.01053 11.801 2.71941C11.4991 2.4283 11.0096 2.42834 10.7076 2.71941L2.03807 11.0773C-0.679325 13.697 -0.679372 17.9443 2.03802 20.564L2.03807 20.5641V20.5641C5.31829 23.7264 9.8407 22.5289 12.0275 20.4207L22.4148 10.4068C24.5284 8.3692 24.5284 5.06563 22.4148 3.02811C20.3012 0.990624 16.8745 0.990624 14.7609 3.02815Z"
        fill="#5A606B"
      />
    </svg>
  );
};
