import React, {
  createContext,
  useContext,
  FC,
  useState,
  useEffect,
} from "react";
import { User } from "firebase/auth";
import { auth } from "../../utils/firebase";
import { TokenClaims } from "@shared/types/firebaseUser";

export type FirebaseUserContextValue = {
  firebaseUser: User | null;
  idTokenClaims: TokenClaims | null;
  isLoading: boolean;
  error: Error | null;
};

const FirebaseUserContext = createContext<FirebaseUserContextValue>({
  firebaseUser: null,
  idTokenClaims: null,
  isLoading: true,
  error: null,
});

export const FirebaseUserProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);
  const [idTokenClaims, setIdTokenClaims] = useState<TokenClaims | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult();
          setIdTokenClaims(idTokenResult.claims as TokenClaims);
        } catch (error) {
          // 現状エラーが発生した場合はログイン画面にリダイレクトされる
          setError(error as Error);
          setIsLoading(false);
          return;
        }
      } else {
        setIdTokenClaims(null);
      }
      setFirebaseUser(user);
      setIsLoading(false);
    });
  }, []);

  return (
    <FirebaseUserContext.Provider
      value={{ firebaseUser, idTokenClaims, isLoading, error }}
    >
      {children}
    </FirebaseUserContext.Provider>
  );
};

export function useFirebaseUserContext() {
  return useContext(FirebaseUserContext);
}
