import React from "react";
import styled from "styled-components";
import { FileIcon } from "../../../components/icons/FileIcon";
import * as Typo from "../../../components/Typo";
import { BREAKPOINTS } from "../../../components/Responsive";

type Props = {
  title: string;
};

const DeniedContent = (props: Props) => {
  return (
    <>
      <SectionTitle>応募一覧</SectionTitle>
      <NotFoundWrapper>
        <NotFoundContents>
          <FileIcon size={240} />
          <NotFoundTextWrapper>
            <NotFountTitle>{props.title}</NotFountTitle>
          </NotFoundTextWrapper>
        </NotFoundContents>
      </NotFoundWrapper>
    </>
  );
};

const SectionTitle = styled(Typo.Heading2)`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #343741;
  margin-bottom: 20px;
`;

const NotFoundWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  max-height: 584px;
  border-radius: 10px;
  border: 1px solid #e4e6f3;
`;

const NotFoundContents = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: 142px 0;

  @media (max-width: 700px) {
    padding: 38px 0;
  }
`;

const NotFoundTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 52px;
`;

const NotFountTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #343741;

  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 16px;
  }
`;

export default DeniedContent;
