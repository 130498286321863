import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { AppliedRecruitment } from "@shared/types/appliedRecruitment";
import { toDateIntervalFormat } from "../../../../utils/time";
import RecruitmentTag from "../../../../components/RecruitmentTag";
import { Button } from "../../../../components/Button";
import { FileIcon } from "../../../../components/icons/FileIcon";
// import ExternalLinkIcon from "../../../../components/icons/ExternalLinkIcon";
// import { createPolyfitUrl } from "../../../../hooks/router";

type AppliedRecruitmentCardProps = {
  recruitment: AppliedRecruitment;
  onOpen: () => void;
};

export const AppliedRecruitmentCard = ({
  recruitment,
  onOpen,
}: AppliedRecruitmentCardProps) => {
  // const newTabUrl = useMemo(() => {
  //   return createPolyfitUrl({
  //     to: "RESIDENT_RECRUITMENT_DETAIL",
  //     query: { id: recruitment.recruitment.id },
  //   });
  // }, [recruitment]);

  const handleClickMoreComment = useCallback(() => {
    onOpen();
  }, [recruitment]);

  const latestComment = useMemo(() => {
    if (!recruitment.comment) return null;
    return recruitment.comment.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    })[0];
  }, [recruitment]);

  return (
    <Card>
      <CardBody>
        <CardHeader>
          <TagWrapper>
            <RecruitmentTag
              status={recruitment.rejectedAt ? "REJECTED" : recruitment.status}
            />
          </TagWrapper>
          {/* TODO: 応募一覧の詳細ページが作成されるまではコメントアウト */}
          {/* {recruitment.recruitment.status !== "DELETED" && (
            <LinkWrapper>
              <a href={newTabUrl} target="_blank" rel="noreferrer">
                <ExternalLinkIcon />
              </a>
            </LinkWrapper>
          )} */}
        </CardHeader>
        <Title>
          {recruitment.recruitment.status === "DELETED"
            ? `[削除済み] ${recruitment.recruitment.title}`
            : recruitment.recruitment.title}
        </Title>
        <DateWrapper>
          <Time>
            <span>実施日：</span>
            {recruitment.recruitment.schedule.map((schedule, i) => (
              <span key={i}>
                {toDateIntervalFormat(
                  new Date(schedule.start),
                  new Date(schedule.end)
                )}
              </span>
            ))}
          </Time>
        </DateWrapper>
        <CommentWrapper>
          {!latestComment ? (
            <>
              <CommentNoContentsWrapper>
                <FileIcon size={89} />
                <Button
                  color="primary"
                  fill
                  size="large"
                  disabled={recruitment.recruitment.status === "DELETED"}
                  onClick={handleClickMoreComment}
                >
                  コメントを投稿
                </Button>
              </CommentNoContentsWrapper>
            </>
          ) : (
            <CommentContentsWrapper>
              <CommentUserWrapper>
                <ImgStyled
                  src={latestComment.user.picture}
                  alt={latestComment.user.picture}
                />
                <CommentUserName>{latestComment.user.name}</CommentUserName>
                {latestComment.isAlert && <AlertTag>アラート</AlertTag>}
              </CommentUserWrapper>

              <CommentContent>{latestComment.comment}</CommentContent>

              <CommentMoreButton onClick={handleClickMoreComment}>
                もっと見る
              </CommentMoreButton>
            </CommentContentsWrapper>
          )}
        </CommentWrapper>
      </CardBody>
    </Card>
  );
};

const Card = styled.div`
  width: 100%;
  min-width: 0;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const CardBody = styled.div`
  padding: 8px 12px;
  flex: 1;
`;

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 8px;
  margin-bottom: 8px;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const Time = styled.p`
  font-size: 12px;
  color: #69707d;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DateWrapper = styled.div`
  display: flex;
`;

const CommentWrapper = styled.div`
  width: 100%;
  background-color: #f5f7fa;
  margin: 8px 0;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

const CommentNoContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

// const LinkWrapper = styled.div`
//   display: flex;
//   width: 72px;
//   height: 27px;
//   padding: 1px 0px;
//   flex-direction: column;
//   justify-content: center;
//   align-items: end;
//   gap: 4px;
//   text-align: center;
//   line-height: 18px; /* 150% */
//   margin-left: auto;
//   cursor: pointer;
// `;
const CardHeader = styled.div`
  display: flex;
`;

const CommentUserWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const CommentUserName = styled.p`
  font-size: 16px;
  font-weight: 700;
`;

const AlertTag = styled.p`
  background-color: #bd271e;
  padding: 4px 12px;
  color: white;
  font-size: 12px;
  font-weight: 400;
  border-radius: 40px;
  margin-left: auto;
`;

const CommentContent = styled.p`
  text-align: justify;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ImgStyled = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 40px;
`;

const CommentContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;

const CommentMoreButton = styled.a`
  color: #0077cc;
  cursor: pointer;
  font-size: 12px;
  margin-left: auto;
  margin-right: auto;
`;
