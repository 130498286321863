import * as communityApi from "../../apiClients/community";
import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "./QueryOptions";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import { GetCommunityListQuery } from "@shared/types/community";

export function useGetCommunities(
  { searchText, take }: GetCommunityListQuery,
  queryOptions: QueryOptions = {}
) {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "community", "list", apiContext, searchText, take],
    queryFn: async () => {
      return await communityApi.getCommunities(searchText, take);
    },
    ...queryOptions,
  });

  return {
    communities: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
}
