import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "../../QueryOptions";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import { getCommunitiesMemberRoleCounts } from "src/apiClients/boardEducation/community";

export function useGetCommunitiesMemberRoleCounts(
  queryOptions: QueryOptions = {}
) {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: [
      "api",
      "boardEducation",
      "community",
      "getCommunityMemberRoleCounts",
      apiContext,
    ],
    queryFn: async () => {
      return await getCommunitiesMemberRoleCounts();
    },
    ...queryOptions,
  });

  return {
    communitiesMemberRoleCounts: useMemo(() => query.data, [query.data]),
    ...query,
  };
}
