import { useEffect } from "react";
import * as chatRoomApi from "src/apiClients/boardEducation/chatRoom";
import { useChatRoomFilter } from "src/hooks/recoil/boardEducation/chatRoomFilter";
import { useWebSocket } from "src/hooks/recoil/socket";
import { ChatRoomEntity } from "@shared/types/chatRoom";
import { EVENT_NAMES } from "@shared/types/webSocket";
import {
  useGetChatRooms,
  useSetChatRoomsQueryData,
} from "src/hooks/query/boardEducation/chatRooms/chatRooms";
import { ChatRoomFilterType } from "@shared/types/boardEducation/chatRoom";

export const useChatRooms = () => {
  const socket = useWebSocket();
  const { chatRoomFilter } = useChatRoomFilter();
  const { chatRooms, isLoading, refetch } = useGetChatRooms(
    chatRoomFilter as ChatRoomFilterType
  );
  const { updateChatRooms } = useSetChatRoomsQueryData(
    chatRoomFilter as ChatRoomFilterType
  );

  useEffect(() => {
    if (!socket.instance) return;

    const handleUpdatedChatRoom = async (data: { chatRoomId: string }) => {
      const updatedChatRoom = await chatRoomApi.fetchChatRoomById(
        data.chatRoomId
      );
      updateChatRooms((prev: ChatRoomEntity[] | undefined) => {
        if (!prev) return prev;
        const updatedIndex = prev.findIndex(
          (room) => room.chatRoom.id === updatedChatRoom.chatRoom.id
        );
        if (updatedIndex === -1) return prev;
        const newData = [...prev];
        newData[updatedIndex] = updatedChatRoom;
        return [
          newData[updatedIndex],
          ...newData.slice(0, updatedIndex),
          ...newData.slice(updatedIndex + 1),
        ];
      });
    };

    const handleAddedNewChatRoom = () => {
      refetch();
    };

    const handleDeletedChatRoom = (data: { chatRoomId: string }) => {
      updateChatRooms((prev: ChatRoomEntity[] | undefined) => {
        if (!prev) return prev;
        return prev.filter((room) => room.chatRoom.id !== data.chatRoomId);
      });
    };

    socket.instance.on(EVENT_NAMES.UPDATED_CHAT_ROOM, handleUpdatedChatRoom);
    socket.instance.on(EVENT_NAMES.ADDED_NEW_CHAT_ROOM, handleAddedNewChatRoom);
    socket.instance.on(EVENT_NAMES.DELETED_CHAT_ROOM, handleDeletedChatRoom);

    return () => {
      socket.instance?.off(
        EVENT_NAMES.UPDATED_CHAT_ROOM,
        handleUpdatedChatRoom
      );
      socket.instance?.off(
        EVENT_NAMES.ADDED_NEW_CHAT_ROOM,
        handleAddedNewChatRoom
      );
      socket.instance?.off(
        EVENT_NAMES.DELETED_CHAT_ROOM,
        handleDeletedChatRoom
      );
    };
  }, [socket.isInitialized, chatRoomFilter, refetch]);

  return { chatRooms, isLoading, refetch };
};
