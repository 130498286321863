import React from "react";
import { Label } from "../../../components/form/Label";
import styled, { css } from "styled-components";
import { ArrowDropDownIcon } from "../../../components/icons/ArrowDropDown";
import { BREAKPOINTS } from "src/components/Responsive";
import { Button, ButtonGroup } from "../../../components/Button";
import { ButtonSelect } from "../../../components/ButtonSelect";
import { ApplicationStatus } from "@shared/types/recruitmentApplication";
import { useRecruitmentList } from "src/hooks/query/recruitments/recruitmentList";
import { Recruitment } from "@shared/types/recruitment";
import { useFormContext } from "react-hook-form";

export const ResidentRecruitmentApplicationFilter = () => {
  const { recruitments } = useRecruitmentList(
    {
      volunteerType: undefined,
      isPaidVolunteer: undefined,
      date: undefined,
      isOpen: false,
    },
    {
      refetchOnMount: "always",
    }
  );

  const displayRecruitments =
    recruitments?.map((recruitment) => {
      return {
        value: recruitment.id,
        text: recruitment.title,
      };
    }) || [];

  const { register, setValue, watch } =
    useFormContext<ResidentRecruitmentApplicationFilter>();

  const currentApplicationStatus = watch("applicationStatus");

  return (
    <>
      <Filter>
        <FilterSummary>
          フィルター設定
          <ArrowDropDownIcon size={24} />
        </FilterSummary>
        <FilterContainer>
          <InputMajorContainer>
            <InputContainer>
              <Label size="s">募集タイトル</Label>
              <InputSelect {...register("recruitmentId")}>
                <option value="">種類を選択</option>
                {displayRecruitments.map((opt) => {
                  return (
                    <option key={opt.value} value={opt.value}>
                      {opt.text}
                    </option>
                  );
                })}
              </InputSelect>
            </InputContainer>
          </InputMajorContainer>
          <InputMajorContainer>
            <InputContainer>
              <Label size="s">氏名</Label>
              <SearchTextField placeholder="氏名を入力" {...register("name")} />
            </InputContainer>
          </InputMajorContainer>
          <InputMajorContainer>
            <InputContainer>
              <Label size="s">採用ステータス</Label>
              <ButtonGroupExtend>
                {ApplicationStatusList.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={
                      currentApplicationStatus === option.value || false
                    }
                    onClick={() => {
                      if (currentApplicationStatus === option.value) {
                        setValue("applicationStatus", undefined);
                      } else {
                        setValue("applicationStatus", option.value);
                      }
                    }}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
            </InputContainer>
          </InputMajorContainer>
          <FilterButtonWrapper>
            <Button
              fill
              style={{ width: "100%" }}
              disabled={false}
              type="submit"
            >
              この条件で検索
            </Button>
          </FilterButtonWrapper>
        </FilterContainer>
      </Filter>
    </>
  );
};

const ApplicationStatusList: Array<{ value: ApplicationStatus; text: string }> =
  [
    {
      value: ApplicationStatus.APPLIED,
      text: "選考中",
    },
    {
      value: ApplicationStatus.APPROVED,
      text: "採用",
    },
    {
      value: ApplicationStatus.REJECTED,
      text: "不採用",
    },
    {
      value: ApplicationStatus.DECLINED,
      text: "辞退",
    },
  ];

export type ResidentRecruitmentApplicationFilter = {
  recruitmentId: Recruitment["id"] | undefined;
  name: string | undefined;
  applicationStatus: ApplicationStatus | undefined;
};

const Filter = styled.details`
  & > summary > svg {
    transition: transform 0.2s;
  }
  &[open] > summary > svg {
    transform: rotate(180deg);
  }
`;

const FilterSummary = styled.summary`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 6px;
  list-style: none;
  ::-webkit-details-marker {
    display: none;
  }
`;

const FilterContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 16px;
`;

const InputMajorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  margin-bottom: 16px;
  @media (max-width: ${BREAKPOINTS.SP}) {
    flex-direction: column;
  }
`;

const InputContainer = styled.div`
  flex: 1;
  min-width: 200px;
`;

const InputSelect = styled.select`
  ${(props) =>
    props.value === "" &&
    css`
      color: #aab4c4;
    `}
  ${(props) =>
    props.value !== "" &&
    css`
      color: #343741;
    `}
  appearance: none;
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  box-sizing: border-box;
`;

const ButtonGroupExtend = styled(ButtonGroup)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .button {
    width: auto;
    /* @media (max-width: 1279px) {
        width: auto;
      } */
  }
  svg {
    margin-left: 12px;
  }
`;

const SearchTextField = styled.input`
  ${(props) =>
    props.value === "" &&
    css`
      color: #aab4c4;
    `}
  ${(props) =>
    props.value !== "" &&
    css`
      color: #343741;
    `}
  appearance: none;
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  box-sizing: border-box;
`;

const FilterButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
