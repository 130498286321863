import React from "react";

export const GroupAddIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3885 11.2921C12.732 10.8998 12.9695 10.4716 13.101 10.0076C13.2323 9.54346 13.298 9.03571 13.298 8.48437C13.298 7.93321 13.2323 7.42879 13.101 6.97112C12.9695 6.51329 12.732 6.08829 12.3885 5.69612C13.2705 5.56662 14.0273 5.76787 14.6588 6.29987C15.2901 6.83187 15.6058 7.56329 15.6058 8.49413C15.6058 9.42496 15.2901 10.1531 14.6588 10.6786C14.0273 11.2043 13.2705 11.4088 12.3885 11.2921ZM17.3463 18.6154V17.2269C17.3463 16.6099 17.2193 16.0399 16.9655 15.5169C16.7115 14.994 16.2499 14.566 15.5808 14.2326C17.2078 14.4711 18.3773 14.8378 19.0895 15.3326C19.8017 15.8275 20.1578 16.4589 20.1578 17.2269V18.6154H17.3463ZM19.3078 12.3904V10.1594H17.077V9.39037H19.3078V7.15937H20.077V9.39037H22.3078V10.1594H20.077V12.3904H19.3078ZM8.63451 11.3786C7.80318 11.3786 7.11618 11.1041 6.57351 10.5551C6.03084 10.0061 5.75951 9.31587 5.75951 8.48437C5.75951 7.65304 6.03084 6.96604 6.57351 6.42337C7.11618 5.88071 7.80318 5.60938 8.63451 5.60938C9.46601 5.60938 10.1563 5.88071 10.7053 6.42337C11.2543 6.96604 11.5288 7.65304 11.5288 8.48437C11.5288 9.31587 11.2543 10.0061 10.7053 10.5551C10.1563 11.1041 9.46601 11.3786 8.63451 11.3786ZM1.69226 18.6154V17.1691C1.69226 16.7525 1.81443 16.3643 2.05876 16.0046C2.30293 15.6451 2.63851 15.3679 3.06551 15.1729C4.08601 14.7294 5.04143 14.3974 5.93176 14.1769C6.82209 13.9564 7.72301 13.8461 8.63451 13.8461C9.54618 13.8461 10.443 13.9564 11.325 14.1769C12.207 14.3974 13.1647 14.7294 14.198 15.1729C14.6122 15.3679 14.9455 15.6451 15.198 16.0046C15.4507 16.3643 15.577 16.7525 15.577 17.1691V18.6154H1.69226Z"
        fill="white"
      />
    </svg>
  );
};
