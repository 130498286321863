import { createRoute } from "..";
import { BEMemberList } from "../../pages/boardEducation/member/BEMemberList";
import { AppBEDefaultLayout } from "../../layouts/AppBEDefaultLayout";
import BEMemberPage from "src/pages/boardEducation/member/BEMemberPage";
import { ChatRoomPage } from "src/pages/boardEducation/chatMessage/ChatPage";
import BERecruitmentListPage from "src/pages/boardEducation/recruitment/BERecruitmentListPage";
import BERecruitmentCreatePage from "src/pages/boardEducation/recruitment/BERecruitmentCreatePage";
import BERecruitmentDetailPage from "src/pages/boardEducation/recruitment/BERecruitmentDetailPage";
import BERecruitmentApplicationListPage from "src/pages/boardEducation/application/BERecruitmentApplicationListPage";
import BERecruitmentEditOpenedPage from "src/pages/boardEducation/recruitment/BERecruitmentEditOpenedPage";

/**
 * BE_ADMINのみが通れるルート
 */
export const BE_ADMIN_ROUTE_DEFS = {
  // 名簿
  BE_ADMIN_MEMBER_LIST: createRoute({
    path: "/board-education/member/list",
    title: "一覧",
    component: BEMemberList,
    layout: AppBEDefaultLayout,
    route: "BE_ADMIN",
  }),
  BE_ADMIN_MEMBER_DETAIL: createRoute<{
    accountId: string;
    communityId: string;
  }>({
    path: "/board-education/member",
    title: "詳細",
    component: BEMemberPage,
    layout: AppBEDefaultLayout,
    route: "BE_ADMIN",
  }),
  // 募集
  BE_ADMIN_RECRUITMENT_LIST: createRoute({
    path: "/board-education/recruitment/list",
    title: "募集一覧",
    component: BERecruitmentListPage,
    layout: AppBEDefaultLayout,
    route: "BE_ADMIN",
  }),
  BE_ADMIN_RECRUITMENT_CREATE: createRoute({
    path: "/board-education/recruitment/create",
    title: "募集新規作成",
    component: BERecruitmentCreatePage,
    layout: AppBEDefaultLayout,
    route: "BE_ADMIN",
  }),
  BE_ADMIN_RECRUITMENT_DETAIL: createRoute<{ id: string }>({
    path: "/board-education/recruitment/detail",
    title: "募集詳細",
    component: BERecruitmentDetailPage,
    layout: AppBEDefaultLayout,
    route: "BE_ADMIN",
  }),
  BE_ADMIN_RECRUITMENT_EDIT_OPENED: createRoute<{ id: string }>({
    path: "/board-education/recruitment/opened/edit",
    title: "公開募集編集",
    component: BERecruitmentEditOpenedPage,
    layout: AppBEDefaultLayout,
    route: "BE_ADMIN",
  }),
  BE_ADMIN_RECRUITMENT_APPLICATION_LIST: createRoute({
    path: "/board-education/recruitment/application_list",
    title: "応募一覧",
    component: BERecruitmentApplicationListPage,
    layout: AppBEDefaultLayout,
    route: "BE_ADMIN",
  }),
  // チャット
  BE_CHATROOM_LIST: createRoute<{
    tab?: string;
    chatRoomId?: string;
  }>({
    path: "/board-education/chatroom",
    title: "メッセージ",
    component: ChatRoomPage,
    layout: AppBEDefaultLayout,
    route: "BE_ADMIN",
  }),
};

export const beAdminRouters = Object.values(BE_ADMIN_ROUTE_DEFS);
