import { User } from "src/apiClients/users";
import * as client from "./client";
import { CreateChatMessageRequest } from "@shared/types/chatMessage";

export type ChatMessage = PlainChatMessage | ChatMessageWithHeader;

export type ChatMessageBase = {
  id: string;
  chatRoomId: string;
  participantId: string;
  content: string;
  createdAt: string;
  updatedAt: string;
  enabledAt: string;
};

export type PlainChatMessage = ChatMessageBase & {
  type: "PLAIN";
};

export type ChatMessageWithHeader = ChatMessageBase & {
  type: "WITH_HEADER";
  headerText: string;
  headerBackgroundColor: string;
};

export type ChatMessageFile = {
  id: string;
  path: string;
  name: string;
  type: "IMAGE" | "PDF";
};

export type ChatMessageForView = ChatMessage & {
  user: Pick<User, "picture" | "name">;
  files: ChatMessageFile[];
};

export async function fetchChatMessagesByRoomId(chatRoomId: string) {
  const chatMessages = await client.get<
    { chatRoomId: string },
    ChatMessageForView[]
  >("/chat-message/fetchChatMessagesByRoomId", { chatRoomId });
  return chatMessages;
}

export async function createChatMessage(
  chatRoomId: string,
  content: string,
  files: File[]
): Promise<void> {
  await client.postWithFormData<
    CreateChatMessageRequest & { files: File[] },
    { message: ChatMessage }
  >("/chat-message/create", { chatRoomId, content, files });
}
