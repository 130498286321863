import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalPortal } from "src/components/Modal";
import { Spinner } from "src/components/icons/Spinner";
import { ChatRoom } from "@shared/types/chatRoom";
import { useBEResidentUsers } from "src/hooks/query/boardEducation/users/useBEResidentUsers";
import { MemberSelectionStep } from "./MemberSelectionStep";
import { GroupInfoEditStep } from "./GroupInfoEditStep";

// publicディレクト配下の絶対パスを指定

export type chatRoomEditorFormValues = {
  name: string;
  accountIds: string[];
  file: File | undefined;
};

type Props = {
  close: () => void;
  refetch: () => void;
  chatRoom: ChatRoom;
};

export const EditModal = ({ close, refetch, chatRoom }: Props) => {
  const { isLoading: isResidentLoading, residents } = useBEResidentUsers();
  // グループのユーザーIDを初期値に設定する
  const participantUserIds = chatRoom.ParticipantChatRooms.filter(
    (participant) =>
      participant.participantType === "USER" &&
      participant.approvalStatus !== "REJECTED"
  ).map((participant) => participant.participantId);
  const userIdToAccountIdMap = new Map<string, string | undefined>();
  residents.forEach((user) => {
    userIdToAccountIdMap.set(user.account.user.id, user.accountId);
  });
  const participantAccountIds = participantUserIds
    .map((userId) => userIdToAccountIdMap.get(userId))
    .filter((accountId): accountId is string => accountId !== undefined);
  useEffect(() => {
    if (residents.length > 0 && chatRoom) {
      setSelectedMemberIds(participantAccountIds);
    }
  }, [residents, chatRoom]);

  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const [isSelectionCompleted, setIsSelectionCompleted] = useState(false);

  // TODO: デフォルト値として設定しているため、条件が変わった場合は変更する

  const completeSelection = (selectedMemberIds: string[]) => {
    setIsSelectionCompleted(true);
    setSelectedMemberIds(selectedMemberIds);
  };

  if (isResidentLoading) {
    return (
      <LoadingCard>
        <Spinner />
      </LoadingCard>
    );
  } else {
    return (
      <ModalPortal onClose={() => close()}>
        {!isSelectionCompleted && (
          <MemberSelectionStep
            residents={residents}
            initialSelectedAccountIds={participantAccountIds}
            completeSelection={completeSelection}
            onCancel={close}
          />
        )}

        {isSelectionCompleted && (
          <GroupInfoEditStep
            chatRoom={chatRoom}
            selectedMemberIds={selectedMemberIds}
            onCancel={() => setIsSelectionCompleted(false)}
            refetch={refetch}
            close={close}
            residents={residents}
          />
        )}
      </ModalPortal>
    );
  }
};

const LoadingCard = styled.div`
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
