import { Role } from "@shared/types/role";
import React, { useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { CurrentUser } from "src/apiClients/auth";
import { Button } from "src/components/Button3";
import { Avatar } from "src/components/Common/Avatar";
import { Margin } from "src/components/Margin";
import { PcViewOnly, MobileViewOnly } from "src/components/Responsive";
import { TabNavigation } from "src/components/TabNavigation";
import { RadioButton } from "src/components/form/RadioButton";
import { ArrowDropDownIcon } from "src/components/icons/ArrowDropDown";
import { ArrowRightIcon } from "src/components/icons/ArrowRight";
import { Spinner } from "src/components/icons/Spinner";
import { NoResults } from "src/features/ListPage/NoResults";
import { useResidentFilter } from "src/hooks/recoil/residentFilter";
import { useCurrentUser } from "src/hooks/recoil/user";
import { usePolyfitHistory, useCurrentCommunityId } from "src/hooks/router";
import { toDisplayDateFormatYearMonth } from "src/utils/time";
import { getAgeGroupText } from "src/utils/types/ageGroup";
import { dayOfWeekOptions, getDayOfWeekText } from "src/utils/types/dayOfWeek";
import {
  teachingLicenseOptions,
  medicalLicenseOptions,
  skillOptions,
} from "src/utils/types/license";
import { isCsAdminRole, roleOptions } from "src/utils/types/role";
import { volunteerOption } from "@shared/types/volunteerType";
import styled from "styled-components";
import * as usersApi from "../../../apiClients/users";
import { User } from "../../../apiClients/users";
import { CheckBox } from "src/components/form/CheckBox";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { CommunityUserTypeOptions } from "src/features/Member/Community/CommunityUserTypeSelect";
import {
  getCommunityUserTypeText,
  getLicensesAndSkills,
  getPreferredVolunteerTypes,
} from "src/features/Member/Community/utils/utils";
import { ResidentFilterType } from "@shared/types/user";
import { useGetResidentUsers } from "src/hooks/query/residentUsers";
import { withinWalkMinuteLabel } from "src/features/ListPage/utils";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

/**
 * PTA名簿用ページ(地域住民)
 */
export function PTAListResidentTab({
  setTab,
}: {
  setTab: (tab: "parent" | "teacher" | "resident") => void;
}) {
  const currentUser: CurrentUser = useCurrentUser();

  const [residentFilter, setResidentFilter] = useResidentFilter();
  const [searchCondition, setSearchCondition] = useState({
    filter: residentFilter,
  });

  const handleFilterFormSubmit = useCallback((data: ResidentFilterType) => {
    setResidentFilter(data);
    setSearchCondition({ filter: data });
  }, []);

  const { isLoading: isResidentLoading, residents } = useGetResidentUsers(
    searchCondition.filter,
    { enabled: !!currentUser }
  );

  return (
    <NameListPage>
      <NameListHeadingRow>
        <NameListHeading>名簿</NameListHeading>
      </NameListHeadingRow>

      {residentFilter && (
        <ResidentFilterForm
          defaultValues={residentFilter}
          onSubmit={handleFilterFormSubmit}
          loading={isResidentLoading}
        />
      )}

      <TabNavigation
        elements={[
          ...(usersApi.inPTA(currentUser)
            ? [
                {
                  isActive: false,
                  text: "保護者",
                  onclick: () => {
                    setTab("parent");
                    // reset();
                  },
                },
                {
                  isActive: false,
                  text: "教員",
                  onclick: () => {
                    setTab("teacher");
                    // reset();
                  },
                },
              ]
            : []),
          ...(isCsAdminRole(currentUser.role)
            ? [
                {
                  isActive: true,
                  text: "地域住民",
                  onclick: () => {
                    setTab("resident");
                    // reset();
                  },
                },
              ]
            : []),
        ]}
      />

      <Margin marginBottom={10} />

      <PcViewOnly>
        <NameListTable>
          <NameListColumnNameRow head={true}>
            <NameListColumn>名前</NameListColumn>
            <NameListColumnWrapper>ユーザータイプ</NameListColumnWrapper>
            <NameListColumnWrapper>年代</NameListColumnWrapper>
            <NameListColumnWrapper>住所</NameListColumnWrapper>
            <NameListColumnWrapper>学校からの徒歩</NameListColumnWrapper>
            <NameListColumnWrapper>有償・無償</NameListColumnWrapper>
            <NameListColumnWrapper>希望活動日</NameListColumnWrapper>
            <NameListColumnWrapper>スキル・資格</NameListColumnWrapper>
            <NameListColumnWrapper>希望活動内容</NameListColumnWrapper>
          </NameListColumnNameRow>
          {/* TODO: テーブル化 */}
          {residents?.length
            ? residents?.map((resident: User, i: number) => {
                return <ResidentListColumn key={i} resident={resident} />;
              })
            : null}
        </NameListTable>
        {isResidentLoading && residents.length === 0 && (
          <LoadingCard>
            <Spinner />
          </LoadingCard>
        )}
        {!isResidentLoading && residents && !residents?.length && <NoResults />}
      </PcViewOnly>
      <MobileViewOnly>
        {residents?.length
          ? residents?.map((resident: User, i: number) => {
              return <ResidentListCardColumn key={i} resident={resident} />;
            })
          : null}
        {isResidentLoading && residents.length === 0 && (
          <LoadingCard>
            <Spinner />
          </LoadingCard>
        )}
        {!isResidentLoading && residents && !residents?.length && <NoResults />}
      </MobileViewOnly>
    </NameListPage>
  );
}

const ResidentFilterForm = ({
  defaultValues,
  onSubmit,
  loading,
}: {
  defaultValues: ResidentFilterType;
  onSubmit: (data: ResidentFilterType) => void;
  loading: boolean;
}) => {
  const [tmpResidentFilter, setTmpResidentFilter] =
    useState<ResidentFilterType>(defaultValues);

  const handleOnSubmit = useCallback(
    // @ts-expect-error todo
    (event) => {
      onSubmit(tmpResidentFilter);
      event.preventDefault();
    },
    [tmpResidentFilter]
  );

  return (
    <ResidentFilter>
      <ResidentFilterSummary>
        フィルター条件
        <ArrowDropDownIcon size={24} />
      </ResidentFilterSummary>

      <form onSubmit={handleOnSubmit}>
        <FilterContainer>
          <Section>
            <SectionTitle>ユーザータイプ</SectionTitle>
            <SectionInputArea>
              {CommunityUserTypeOptions.map((userType, index) => {
                return (
                  <CheckBox
                    key={index}
                    label={userType.text}
                    defaultChecked={tmpResidentFilter.communityUserType.includes(
                      userType.value
                    )}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const tmpUserType = [
                          ...tmpResidentFilter.communityUserType,
                          userType.value,
                        ];

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          communityUserType: tmpUserType,
                        });
                      } else {
                        const tmpUserType =
                          tmpResidentFilter.communityUserType.filter(
                            (l) => l !== userType.value
                          );

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          communityUserType: tmpUserType,
                        });
                      }
                    }}
                  />
                );
              })}
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>有償・無償</SectionTitle>
            <SectionInputArea>
              <CheckBox
                label="有償"
                defaultChecked={tmpResidentFilter.preferredPaid.includes(
                  "PAID"
                )}
                onChange={(e) => {
                  // FIXME: 関数切り出ししたい
                  if (e.target.checked) {
                    const preferredPaid: ("FREE" | "PAID")[] = [
                      ...tmpResidentFilter.preferredPaid,
                      "PAID",
                    ];
                    setTmpResidentFilter({
                      ...tmpResidentFilter,
                      preferredPaid,
                    });
                  } else {
                    const preferredPaid =
                      tmpResidentFilter.preferredPaid.filter(
                        (l) => l !== "PAID"
                      );
                    setTmpResidentFilter({
                      ...tmpResidentFilter,
                      preferredPaid,
                    });
                  }
                }}
              />
              <CheckBox
                label="無償"
                defaultChecked={
                  tmpResidentFilter.preferredPaid.includes("FREE") ?? false
                }
                onChange={(e) => {
                  // FIXME: 関数切り出ししたい
                  if (e.target.checked) {
                    const preferredPaid: ("FREE" | "PAID")[] = [
                      ...tmpResidentFilter.preferredPaid,
                      "FREE",
                    ];
                    setTmpResidentFilter({
                      ...tmpResidentFilter,
                      preferredPaid,
                    });
                  } else {
                    const preferredPaid =
                      tmpResidentFilter.preferredPaid.filter(
                        (l) => l !== "FREE"
                      );
                    setTmpResidentFilter({
                      ...tmpResidentFilter,
                      preferredPaid,
                    });
                  }
                }}
              />
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>希望活動曜日</SectionTitle>
            <SectionInputArea>
              {dayOfWeekOptions.map((dayOfWeekOption, index) => {
                return (
                  <CheckBox
                    key={index}
                    label={dayOfWeekOption.text}
                    defaultChecked={tmpResidentFilter.preferredDays?.includes(
                      dayOfWeekOption.value
                    )}
                    onChange={(e) => {
                      // FIXME: 関数切り出ししたい
                      if (e.target.checked) {
                        const preferredDays = [
                          ...tmpResidentFilter.preferredDays,
                          dayOfWeekOption.value,
                        ];

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          preferredDays,
                        });
                      } else {
                        const preferredDays =
                          tmpResidentFilter.preferredDays.filter(
                            (l) => l !== dayOfWeekOption.value
                          );

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          preferredDays,
                        });
                      }
                    }}
                  />
                );
              })}
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>スキル・資格</SectionTitle>
            <SectionInputArea>
              {[
                ...teachingLicenseOptions,
                ...medicalLicenseOptions,
                ...skillOptions,
              ].map((license, index) => {
                return (
                  <CheckBox
                    key={index}
                    label={license.text}
                    defaultChecked={tmpResidentFilter.licenses.includes(
                      license.value
                    )}
                    onChange={(e) => {
                      // FIXME: 関数切り出ししたい
                      if (e.target.checked) {
                        const licenses = [
                          ...tmpResidentFilter.licenses,
                          license.value,
                        ];

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          licenses,
                        });
                      } else {
                        const licenses = tmpResidentFilter.licenses.filter(
                          (l) => l !== license.value
                        );

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          licenses,
                        });
                      }
                    }}
                  />
                );
              })}
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>活動内容</SectionTitle>
            <SectionInputArea>
              {volunteerOption.map((volunteerOption, index) => {
                return (
                  <CheckBox
                    key={index}
                    label={volunteerOption.text}
                    defaultChecked={tmpResidentFilter.preferredVolunteerTypes.includes(
                      volunteerOption.value
                    )}
                    onChange={(e) => {
                      // FIXME: 関数切り出ししたい
                      if (e.target.checked) {
                        const volunteerType = [
                          ...tmpResidentFilter.preferredVolunteerTypes,
                          volunteerOption.value,
                        ];

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          preferredVolunteerTypes: volunteerType,
                        });
                      } else {
                        const volunteerType =
                          tmpResidentFilter.preferredVolunteerTypes.filter(
                            (l) => l !== volunteerOption.value
                          );

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          preferredVolunteerTypes: volunteerType,
                        });
                      }
                    }}
                  />
                );
              })}
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>学校からの徒歩</SectionTitle>
            <SectionInputArea>
              {[1, 5, 7, 10, 15, 20, 30].map((withinWalkMinute, index) => {
                return (
                  <RadioButton
                    key={index}
                    label={`${withinWalkMinute}分以内`}
                    checked={
                      tmpResidentFilter.withinWalkMinute === withinWalkMinute
                    }
                    onChange={() => {
                      setTmpResidentFilter({
                        ...tmpResidentFilter,
                        withinWalkMinute: withinWalkMinute,
                      });
                    }}
                  />
                );
              })}
              <RadioButton
                label="指定しない"
                checked={tmpResidentFilter.withinWalkMinute === undefined}
                onChange={() => {
                  setTmpResidentFilter({
                    ...tmpResidentFilter,
                    withinWalkMinute: undefined,
                  });
                }}
              />
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>住所</SectionTitle>
            <SectionInputArea>
              <TextField
                type="text"
                placeholder="住所を入力"
                onChange={(e) => {
                  setTmpResidentFilter({
                    ...tmpResidentFilter,
                    city: e.target.value,
                  });
                }}
                defaultValue={tmpResidentFilter.city}
              />
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>名前</SectionTitle>
            <SectionInputArea>
              <TextField
                type="text"
                placeholder="氏名を入力"
                onChange={(e) => {
                  setTmpResidentFilter({
                    ...tmpResidentFilter,
                    name: e.target.value,
                  });
                }}
                defaultValue={tmpResidentFilter?.name}
              />
            </SectionInputArea>
          </Section>

          <FilterButtonWrapper>
            <Button fill style={{ width: "100%" }} disabled={loading}>
              この条件で検索
            </Button>
          </FilterButtonWrapper>
        </FilterContainer>
      </form>
    </ResidentFilter>
  );
};

const ResidentListColumn = ({ resident }: { resident: User }) => {
  const history = usePolyfitHistory();
  const { communityId } = useCurrentCommunityId();

  const communityUserType = useMemo(
    () =>
      resident?.account?.communityRoles.find(
        (role) => role.communityId === communityId
      )?.type,
    [resident]
  );

  return (
    <NameListColumnNameRow
      onClick={() =>
        history.push({
          to: "PTA_LIST_MEMBER",
          query: { userId: resident.id },
        })
      }
    >
      <UserPicCell>
        <PreUserPicCell>
          <Avatar src={resident.picture || defaultUserImg} alt="" size={40} />
        </PreUserPicCell>
        <PreUserNameCell>
          <Name>{resident.name}</Name>
          <NameListOrganizationData>
            <ResidentRoleTagWrapper role={resident.role}>
              {roleOptions.find((role) => role.value === resident.role)?.text}
            </ResidentRoleTagWrapper>
          </NameListOrganizationData>
        </PreUserNameCell>
        <NameButtonWrap>
          <Link
            to={{
              pathname: `/pta/list/member`,
              search: `userId=${resident.id}&communityId=${communityId}`,
            }}
          >
            &gt;
          </Link>
        </NameButtonWrap>
      </UserPicCell>
      <Panel>
        {/* ユーザータイプ */}
        {getCommunityUserTypeText(communityUserType)}
      </Panel>
      <Panel>
        {/* 年代 */}
        {getAgeGroupText(resident?.baseInfo?.ageGroup)}
      </Panel>
      <Panel>
        {/* 住所 */}
        {resident?.baseInfo?.city}
        {resident?.baseInfo?.address1}
      </Panel>
      <Panel>{withinWalkMinuteLabel(resident?.walkMinute)}</Panel>
      <Panel>
        {/* 有償・無償 */}
        {usersApi.isResidentUser(resident) &&
          (resident?.residentInfo?.preferredPaid.includes("PAID") &&
          resident?.residentInfo?.preferredPaid.includes("FREE")
            ? "どちらでも"
            : resident?.residentInfo?.preferredPaid.includes("PAID")
            ? "有償"
            : resident?.residentInfo?.preferredPaid.includes("FREE")
            ? "無償"
            : "該当なし")}
      </Panel>
      <Panel>
        {/* 希望活動日 */}
        {usersApi.isResidentUser(resident) &&
        resident?.residentInfo?.preferredDays?.length > 0
          ? (resident?.residentInfo?.preferredDays ?? [])
              ?.sort((a, b) => {
                const indexA = dayOfWeekOptions.findIndex(
                  (dayObj) => dayObj.value === a
                );
                const indexB = dayOfWeekOptions.findIndex(
                  (dayObj) => dayObj.value === b
                );
                return indexA - indexB;
              })
              .map((day, index) => <p key={index}>{getDayOfWeekText(day)}</p>)
          : "該当なし"}
      </Panel>
      <Panel>
        {/* スキル・資格 */}
        {(usersApi.isResidentUser(resident) &&
          getLicensesAndSkills(resident?.residentInfo)?.map(
            (license, index) => <p key={index}>{license}</p>
          )) ??
          "該当なし"}
      </Panel>
      <Panel>
        {/* 希望活動内容 */}
        {(usersApi.isResidentUser(resident) &&
          getPreferredVolunteerTypes(resident?.residentInfo)?.map(
            (license, index) => <p key={index}>{license}</p>
          )) ??
          "該当なし"}
      </Panel>

      <Panel>
        <ArrowRightIcon size={24} />
      </Panel>
    </NameListColumnNameRow>
  );
};

const ResidentListCardColumn = ({ resident }: { resident: User }) => {
  const history = usePolyfitHistory();
  const { communityId } = useCurrentCommunityId();

  const communityUserType = resident?.account?.communityRoles.find(
    (role) => role.communityId === communityId
  )?.type;

  return (
    <ResidentMobileCard
      onClick={() =>
        history.push({
          to: "PTA_LIST_MEMBER",
          query: { userId: resident.id },
        })
      }
    >
      <ResidentMobileCardHeader>
        <Avatar src={resident.picture || defaultUserImg} alt="" size={40} />
        <div>
          <Name>{resident.name}</Name>
          <NameListOrganizationData>
            {/* CSAdmin */} {/* CSMember */}
            <ResidentRoleTagWrapper role={resident.role}>
              {roleOptions.find((role) => role.value === resident.role)?.text}
            </ResidentRoleTagWrapper>
          </NameListOrganizationData>
        </div>
      </ResidentMobileCardHeader>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>ユーザータイプ</ResidentMobileCardLabel>
        {getCommunityUserTypeText(communityUserType)}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>生年月日</ResidentMobileCardLabel>
        {resident?.baseInfo?.birthday &&
          toDisplayDateFormatYearMonth(resident?.baseInfo?.birthday)}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>住所</ResidentMobileCardLabel>
        {resident?.baseInfo?.city}
        {resident?.baseInfo?.address1}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>学校からの徒歩</ResidentMobileCardLabel>
        {withinWalkMinuteLabel(resident?.walkMinute)}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>有償・無償</ResidentMobileCardLabel>
        {usersApi.isResidentUser(resident) &&
          (resident?.residentInfo?.preferredPaid.includes("PAID") &&
          resident?.residentInfo?.preferredPaid.includes("FREE")
            ? "どちらでも"
            : resident?.residentInfo?.preferredPaid.includes("PAID")
            ? "有償"
            : resident?.residentInfo?.preferredPaid.includes("FREE")
            ? "無償"
            : "該当なし")}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>希望活動日</ResidentMobileCardLabel>
        {usersApi.isResidentUser(resident) &&
        resident?.residentInfo?.preferredDays?.length > 0
          ? (resident?.residentInfo?.preferredDays ?? [])
              ?.sort((a, b) => {
                const indexA = dayOfWeekOptions.findIndex(
                  (dayObj) => dayObj.value === a
                );
                const indexB = dayOfWeekOptions.findIndex(
                  (dayObj) => dayObj.value === b
                );
                return indexA - indexB;
              })
              .map((day, index) => <p key={index}>{getDayOfWeekText(day)}</p>)
          : "該当なし"}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>スキル・資格</ResidentMobileCardLabel>
        {(usersApi.isResidentUser(resident) &&
          getLicensesAndSkills(resident?.residentInfo)?.map(
            (license, index) => <p key={index}>{license}</p>
          )) ??
          "該当なし"}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>希望活動内容</ResidentMobileCardLabel>
        {(usersApi.isResidentUser(resident) &&
          getPreferredVolunteerTypes(resident?.residentInfo)?.map(
            (license, index) => <p key={index}>{license}</p>
          )) ??
          "該当なし"}
      </ResidentMobileCardSection>
    </ResidentMobileCard>
  );
};

const NameListPage = styled.div`
  margin: 0 auto;
`;

const NameListHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  color: #1a1c21;
`;

const NameListTable = styled.table`
  background-color: #fff;
  border: none;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-weight: normal;
`;

const Name = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: left;
`;

const Panel = styled.th`
  color: #343741;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  padding-left: 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const NameListHeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 16px;
`;

const NameListColumnNameRow = styled.tr<{ head?: boolean }>`
  background-color: #fff;
  ${(props) =>
    !props.head &&
    `border-top: 1px solid #e3e6eb; cursor: pointer; &:hover { filter: brightness(95%);}`};
`;

const NameListColumn = styled.th`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
`;

const NameListColumnWrapper = styled.th`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const NameListOrganizationData = styled.div`
  display: flex;
  gap: 4px;
  color: #69707d;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  text-align: left;
`;

const LoadingCard = styled.div`
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserPicCell = styled.th`
  color: #343741;
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 100%;
`;

const PreUserPicCell = styled.div`
  padding: 14px;
`;

const PreUserNameCell = styled.div`
  padding: 14px 0;
`;

const NameButtonWrap = styled.div`
  margin: auto;
  margin-right: 0;
  padding-right: 10px;
  display: none;
  @media (max-width: 1279px) {
    display: block;
  }
`;

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const FilterContainer = styled.div`
  gap: 8px;
  padding: 16px;
  background-color: #fff;
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 20px;
  width: 100%;
`;

const SectionTitle = styled.p`
  font-weight: bold;
  font-size: 12px;
  width: 113px;
`;

const SectionInputArea = styled.p`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: auto;
`;

const FilterButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ResidentMobileCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid rgba(228, 230, 243, 1);
  gap: 16px;
  cursor: pointer;
  &:hover {
    filter: brightness(95%);
  }
`;

const ResidentMobileCardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ResidentRoleTagWrapper = styled.div<{ role: Role }>`
  display: inline-block;
  padding: 2px 8px;
  background-color: ${(props) => {
    switch (props.role) {
      case Role.CSAdmin:
        return "#79aad9";
      case Role.CSOperator:
        return "#EE789D";
      case Role.CSMember:
        return "#6DCCB1";
      default:
        break;
    }
  }};
  border-radius: 40px;
  color: #ffffff;
  /* font-size: 12px; */
`;

const ResidentMobileCardSection = styled.div``;

const ResidentMobileCardLabel = styled.div`
  color: #69707d;
  font-size: 12px;
`;

const ResidentFilter = styled.details`
  & > summary > svg {
    transition: transform 0.2s;
  }
  &[open] > summary > svg {
    transform: rotate(180deg);
  }
`;

const ResidentFilterSummary = styled.summary`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 6px;
  list-style: none;
  ::-webkit-details-marker {
    display: none;
  }
`;
