import React, { Dispatch, SetStateAction } from "react";
import { ModalBackGround } from "../../../components/ListPageFilterSetting";
import { SelectBelongPartType } from "./CareerInfoEditModal";
import { UseFormSetValue } from "react-hook-form";
import { FormValues } from "./CareerInfoCreateModal";
import {
  DropdownMenu,
  DropdownMenuLabel,
  DropdownMenuLi,
  DropdownMenuUl,
  DropdownMenuWrapper,
} from "./SelectChildDropdown";
import { Belong } from "../../../apiClients/belong";

type BelongsDropDownListPropsType = {
  belongs: Belong[];
  isOpen: boolean;
  onClose: () => void;
  selectedBelong: SelectBelongPartType;
  setSelectedBelong: Dispatch<SetStateAction<SelectBelongPartType>>;
  setValue: UseFormSetValue<FormValues>;
};

const BelongsDropDownList = ({
  belongs,
  isOpen,
  onClose,
  selectedBelong,
  setSelectedBelong,
  setValue,
}: BelongsDropDownListPropsType) => {
  if (!isOpen) return null;

  return (
    <>
      <DropdownMenuWrapper>
        <DropdownMenu height={belongs.length * 38}>
          <DropdownMenuUl>
            {belongs.map((belong, index) => (
              <DropdownMenuLi key={index} isLast={belongs.length - 1 === index}>
                <input
                  type="radio"
                  name={belong.id}
                  id={belong.id}
                  checked={belong.id === selectedBelong.id}
                  onChange={() => {
                    setSelectedBelong({
                      id: belong.id,
                      name: belong.name,
                      isNone: false,
                    });
                    setValue("belongId", belong.id);
                    onClose();
                  }}
                  style={{ cursor: "pointer" }}
                />
                <DropdownMenuLabel htmlFor={belong.id}>
                  {belong.name}
                </DropdownMenuLabel>
              </DropdownMenuLi>
            ))}
          </DropdownMenuUl>
        </DropdownMenu>
      </DropdownMenuWrapper>
      <ModalBackGround onClick={() => onClose()} />
    </>
  );
};

export default BelongsDropDownList;
