import { getLonLatFromAddress } from "../apiClients/geocoding";
import { getAddressFromPostalCode } from "../apiClients/postcode";

declare global {
  interface Window {
    GSI: { MUNI_ARRAY: { [key: number]: string } };
  }
}

export const useGeoCording = () => {
  const getLocationAddressFromPostcode = async (postcode: string) => {
    const address = await getAddressFromPostalCode(postcode);
    const location = await getLonLatFromAddress(
      `${address.prefecture}${address.city}${address.suburb}`
    );

    return {
      prefecture: address.prefecture,
      city: address.city,
      address1: address.suburb,
      latitude: location.latitude,
      longitude: location.longitude,
    };
  };

  return {
    getLocationAddressFromPostcode,
  };
};
