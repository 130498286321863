import React from "react";
import { usePolyfitHistory } from "../../../hooks/router";
import styled from "styled-components";
import { useRecruitmentTemplateList } from "../../../hooks/api/recruitmentTemplate";
import { NotFoundRecruitment } from "../../../features/Recruitment/recruitment/NotFoundRecruitment";
import { RecruitmentCard } from "../../../features/Recruitment/recruitment/RecruitmentCard";
import { Header } from "../../../components/Header";

export default function ResidentRecruitmentTemplateListPage() {
  const { recruitmentTemplates } = useRecruitmentTemplateList();
  const history = usePolyfitHistory();

  return (
    <>
      <Header title="ひな形一覧" />

      {/* TODO: カード部分を共通化 */}
      {recruitmentTemplates?.length === 0 ? (
        <NotFoundRecruitment
          title="ひな形がありません"
          description="ひな形が作成されるとこちらの画面に表示されます"
        />
      ) : (
        <CardContainer>
          {recruitmentTemplates?.map((recruitment, i) => (
            <RecruitmentCard
              key={recruitment.id}
              recruitment={recruitment}
              primaryButtonLabel="このひな形から作成"
              onClickPrimaryButton={() =>
                history.push({
                  to: "RESIDENT_RECRUITMENT_CREATE_FROM_TEMPLATE",
                  query: { templateId: recruitment.id },
                })
              }
            />
          ))}
        </CardContainer>
      )}
    </>
  );
}

const CardContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 24px;
  margin: 2em auto;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
