import React, { ReactElement } from "react";
import styled from "styled-components";
import { MenuButton } from "./MenuButton";
import { MenuModal } from "./MenuModal";
import { zIndexes } from "../../../zIndex";

type Props = {
  variant?: "contained" | "text";
  isDisplay: boolean;
  isOpenMenu: boolean;
  toggleMenu: (arg: boolean) => void;
  menuList: {
    label: string;
    onClick: () => void;
    color?: string;
    icon?: ReactElement;
  }[];
};

export const Menu = ({
  variant = "contained",
  isDisplay,
  menuList,
  toggleMenu,
  isOpenMenu = false,
}: Props) => {
  if (!isDisplay) return null;

  return (
    <>
      <div style={{ position: "relative" }}>
        <MenuButton
          variant={variant}
          isOpenMenu={isOpenMenu}
          toggle={toggleMenu}
        />
        <MenuModal isOpen={isOpenMenu} items={menuList} />
      </div>

      <ModalBackGround isOpen={isOpenMenu} onClick={() => toggleMenu(false)} />
    </>
  );
};

export const ModalBackGround = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${zIndexes.notModalMenuBackground};
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;
