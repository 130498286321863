import { format, isThisYear, isToday, isYesterday } from "date-fns";
import { ja } from "date-fns/locale";

export function formatRecruitmentDates(
  recruitments: { start: Date; end: Date }[]
) {
  const formattedDates = recruitments.map((recruitment) => {
    const startDate = new Date(recruitment.start);
    const endDate = new Date(recruitment.end);

    const formattedStart = formatFullDate(startDate);
    const formattedEnd = formatFullDate(endDate);

    return `${formattedStart} ~ ${formattedEnd}`;
  });

  return formattedDates.join(" ,  ");
}

function formatFullDate(date: Date) {
  const yearFormat = isThisYear(date) ? "" : "yyyy年";
  const formattedDate = `${yearFormat}MM月dd日(E) HH時mm分`;

  return format(date, formattedDate, { locale: ja });
}

export function formatDateTime(datetime: string) {
  const formattedDateTime = `yyyy年MM月dd日(E) HH:mm`;

  return format(new Date(datetime), formattedDateTime, { locale: ja });
}

export function formatDateTimeToDate(datetime: string) {
  const date = new Date(datetime);
  if (isToday(date)) {
    return "今日";
  }

  if (isYesterday(date)) {
    return "昨日";
  }

  const yearFormat = isThisYear(date) ? "" : "yyyy年";
  const formattedDate = `${yearFormat}MM月dd日(E)`;

  return format(date, formattedDate, { locale: ja });
}

export function formatDateTimeToTime(datetime: string) {
  const formattedDateTime = `HH:mm`;

  return format(new Date(datetime), formattedDateTime, { locale: ja });
}
