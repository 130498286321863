import React from "react";
import { usePolyfitHistory } from "../../../hooks/router";
import { BaseInfo } from "../../../hooks/recoil/user";
import styled from "styled-components";
import { RegistrationFooter } from "../../../components/Footer2";
import { useCustomReactHookForm } from "../../../components/form/ReactHookForm";
import { UserInfoInputIcon } from "../../../components/icons/UserInfoInputIcon";
import { NotificationIcon } from "../../../components/icons/NotificationIcon";
import { CommunityRegisterIcon } from "../../../components/icons/CommunityRegisterIcon";

export default function ResidentRegisterDescriptionPage() {
  const history = usePolyfitHistory();
  const { Form } = useCustomReactHookForm<BaseInfo>();

  const onSubmit = async () => {
    history.push({
      to: "RESIDENT_REGISTER_INFO",
      query: {},
    });
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <StyledContainer>
          <DescriptionText>
            新規登録ありがとうございます！
            <br /> これから登録に必要な作業を一緒にやりましょう（1分）
          </DescriptionText>

          <RegistrationFlowImagesContainer>
            <RegistrationFlowImage>
              <UserInfoInputIcon />
              <DescriptionText>ユーザー情報入力</DescriptionText>
            </RegistrationFlowImage>

            <RegistrationFlowImage>
              <CommunityRegisterIcon />
              <DescriptionText>校区を入力</DescriptionText>
            </RegistrationFlowImage>

            <RegistrationFlowImage>
              <NotificationIcon />
              <DescriptionText>通知が届く</DescriptionText>
            </RegistrationFlowImage>
          </RegistrationFlowImagesContainer>
        </StyledContainer>
        <RegistrationFooter isDisplayBeforeButton={false} />
      </Form>
    </div>
  );
}

const StyledContainer = styled.div`
  max-width: 496px;
  margin: 100px auto;
  margin-top: 100px;

  @media (max-width: 1279px) {
    max-width: 100vw;
    margin-top: 20vh;
  }
`;

const DescriptionText = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #343741;
  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

const RegistrationFlowImage = styled.div`
  max-width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  @media (max-width: 800px) {
    & svg {
      width: 30vw;
      height: 30vw;
    }
  }
`;

const RegistrationFlowImagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 40px;

  @media (max-width: 800px) {
    margin-top: 10vh;
    gap: 2vw;
  }
`;
