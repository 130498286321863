import React from "react";
import styled from "styled-components";
import { CreateLinkButton } from "./CreateLinkButton";

type Props = {
  isAdmin: boolean;
  openModal: (arg: boolean) => void;
};

export const LinkListHeader = ({ isAdmin, openModal }: Props) => {
  return (
    <>
      <StyledWrapper>
        <div>
          <StyledPageTitle>リンク一覧</StyledPageTitle>
          <StyledPageDescriptionPc>
            {isAdmin
              ? "自由にリンクを作成することができます。"
              : "リンクを作成したい場合は管理者に依頼してください。"}
          </StyledPageDescriptionPc>
        </div>
        <StyleButtonWrapper>
          <CreateLinkButton
            isDisplay={isAdmin}
            onClick={() => openModal(true)}
          />
        </StyleButtonWrapper>
      </StyledWrapper>

      <StyledPageDescriptionSp>
        {isAdmin
          ? "自由にリンクを作成することができます。"
          : "リンクを作成したい場合は管理者に依頼してください。"}
      </StyledPageDescriptionSp>
    </>
  );
};

const StyledPageTitle = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 42px;
  color: #1a1c21;
`;

const StyledPageDescriptionPc = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  margin-left: 5px;
  color: #1a1c21;

  @media (max-width: 500px) {
    display: none;
  }
`;

const StyledPageDescriptionSp = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  margin-left: 5px;
  color: #1a1c21;
  display: block;

  @media (min-width: 501px) {
    display: none;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    gap: 30px;
    align-items: flex-start;
  }
`;

const StyleButtonWrapper = styled.div`
  @media (max-width: 500px) {
    margin-top: 5px;
  }
`;
