import { useState, useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import * as communityApi from "../../apiClients/community";
import { Community } from "@shared/types/community";

export type UpdateCommunity = {
  name: string;
  communityId: string;
  schoolCode?: string;
  address?: string;
  postalCode?: string;
  prefecture?: string;
  city?: string;
  address1?: string;
  address2?: string;
  latitude?: number;
  longitude?: number;
  postSchoolYearsFilter?: string[];
};

const communityState = atom<Community | undefined>({
  key: "communityState",
  default: undefined,
});

export function useCommunityById(communityId: string) {
  const [community, setCommunity] = useRecoilState(communityState);
  const [isLoadingCommunity, setIsLoading] = useState(false);
  const [isWritingCommunity, setIsWriting] = useState(false);

  const fetchCommunity = useCallback(async () => {
    setIsLoading(true);
    const communityData = await communityApi.getCommunityById(communityId);
    setCommunity(communityData);
    setIsLoading(false);
    return communityData;
  }, [communityId]);

  useEffect(() => {
    fetchCommunity();
  }, [communityId, fetchCommunity]);

  const writeCommunity = useCallback(
    async ({
      communityName,
      communityCode,
      communityAddress,
      communityPostalCode,
      communityNameListPublished,
      communityRequireAdminToUpdateCareer,
      communityId,
      prefecture,
      city,
      address1,
      address2,
      latitude,
      longitude,
    }: {
      communityName: string;
      communityCode: string;
      communityAddress: string;
      communityPostalCode: string;
      communityNameListPublished: boolean | undefined;
      communityRequireAdminToUpdateCareer: boolean | undefined;
      communityId: string;
      prefecture?: string;
      city?: string;
      address1?: string;
      address2?: string;
      latitude?: number;
      longitude?: number;
    }) => {
      setIsWriting(true);
      await communityApi.updateCommunity({
        name: communityName,
        schoolCode: communityCode,
        address: communityAddress,
        postalCode: communityPostalCode,
        nameListPublished: communityNameListPublished,
        requireAdminToUpdateCareer: communityRequireAdminToUpdateCareer,
        id: communityId,
        prefecture,
        city,
        address1,
        address2,
        latitude: latitude,
        longitude: longitude,
      });
      fetchCommunity();
      setIsWriting(false);
    },
    []
  );

  const updateCommunity: (community: UpdateCommunity) => Promise<void> =
    useCallback(
      async ({
        name,
        schoolCode,
        address,
        postalCode,
        prefecture,
        city,
        address1,
        address2,
        latitude,
        longitude,
        postSchoolYearsFilter,
        communityId,
      }: UpdateCommunity) => {
        setIsWriting(true);
        await communityApi.updateCommunity({
          id: communityId,
          name: name,
          schoolCode: schoolCode ?? "",
          address: address ?? "",
          postalCode: postalCode ?? "",
          prefecture: prefecture ?? "",
          city: city ?? "",
          address1: address1 ?? "",
          address2: address2 ?? "",
          latitude: latitude,
          longitude: longitude,
          postSchoolYearsFilter: postSchoolYearsFilter ?? [],
        });
        fetchCommunity();
        setIsWriting(false);
      },
      []
    );

  return [
    {
      community,
      isLoadingCommunity,
      isWritingCommunity,
    },
    {
      fetchCommunity,
      writeCommunity,
      updateCommunity,
    },
  ] as const;
}
