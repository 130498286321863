import { ResidentUserDetail } from "@shared/types/boardEducation/residentUser";
import * as client from "../client";

/**
 * 地域住民詳細
 */
export async function getBEResidentUserDetail({
  accountId,
  communityId,
}: {
  accountId: string;
  communityId: string;
}): Promise<ResidentUserDetail> {
  const res = await client.get<
    { accountId: string; communityId: string },
    { account: ResidentUserDetail }
  >("/boardEducation/users/residentUser/detail", {
    accountId: accountId,
    communityId: communityId,
  });
  return res.account;
}
