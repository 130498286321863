import React, { useState } from "react";
import styled from "styled-components";
import * as theme from "../theme";
import { Panel } from "../components/Panel";
import { Heading1, Heading2 } from "../components/Typo";
import { Button } from "../components/Button";
import { FlexLayout } from "../components/Container";
import { usePolyfitHistory } from "../hooks/router";
import { EmailInput } from "../components/Form";
import { useRecoilState } from "recoil";
import { formEmailPasswordState } from "../hooks/formEmailPassword";
import { sendPasswordResetEmail } from "../utils/firebase";

const Container = styled.div`
  background: ${theme.states.pageBackgroundColor};
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
`;

const Spacer = styled.div`
  height: 20px;
`;

const RegisterButton = styled.button`
  height: 36px;
  display: block;
  width: 100%;
  align-items: center;
  color: #ffffff;
  background-color: ${theme.colorsPallet.primary};
  border: 1px solid rgba(105, 112, 125, 0.2);
  border-radius: 6px;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.8;
  }
`;

const ContainerBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;

  ${Panel} {
    padding: 24px;
    text-align: center;
    width: 340px;
    min-height: 200px;
  }

  ${Button} {
    height: 36px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    color: ${theme.colorTokens.text};
    border: 1px solid rgba(105, 112, 125, 0.2);
    border-radius: 6px;
  }

  ${Button} svg {
    margin-right: 8px;
  }

  ${Heading2} {
    margin-bottom: 42px;
  }

  ${Heading1} {
    text-align: center;
    font-weight: 700;
    color: #1a1c21;
    font-size: 22px;
    margin-bottom: 20px;
  }
`;

const ErrorMessage = styled.div`
  margin-top: 12px;
  background-color: #fff0ef;
  color: #bd271e;
  font-size: 12px;
  text-align: start;
  padding: 6px 10px;
`;

const FormDescription = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: ${theme.colorTokens.text};
  margin-bottom: 20px;
`;

export default function InputEmailPage() {
  const [error, setError] = useState("");
  const { 0: formEmailPassword } = useRecoilState(formEmailPasswordState);
  const history = usePolyfitHistory();

  const actionCodeSettings = {
    url: `${location.origin}/login`,
    handleCodeInApp: false,
  };

  const onClickSendResetPasswordButton = async () => {
    try {
      await sendPasswordResetEmail(formEmailPassword.email, actionCodeSettings);
      history.push({ to: "SEND_RESET_PASSWORD_MAIL_COMPLETE" });
    } catch (err) {
      setError("ユーザーが見つかりませんでした");
      console.log("エラーが発生", err);
    }
  };

  return (
    <Container>
      <ContainerBox>
        <FlexLayout direction="column">
          <Heading1>パスワード再設定</Heading1>
          <Panel>
            <FormDescription>
              パスワード再設定のためのリンクを送信します。
            </FormDescription>
            <EmailInput />
            {!!error && (
              <ErrorMessage>
                {error}
                <br />
                管理者までお問合せ下さい。
              </ErrorMessage>
            )}
            <Spacer />
            <RegisterButton
              type="button"
              onClick={onClickSendResetPasswordButton}
              disabled={!formEmailPassword.email}
            >
              送信
            </RegisterButton>
          </Panel>
        </FlexLayout>
      </ContainerBox>
    </Container>
  );
}
