// 「アンケートに回答」ボタンを表示するかどうかを判定する

import { PostType } from "../../../hooks/api/post";
import { isSurveyDefined } from "../../../apiClients/surveyQuestions";
import { SurveyQuestions } from "@shared/types/post/survey/survey";

export function shouldShowAnswerSurvey(
  post: PostType,
  surveyQuestions: SurveyQuestions | undefined
): boolean {
  // アンケートが定義されていないときは表示しない
  if (!isSurveyDefined(surveyQuestions)) {
    return false;
  }
  // 承認済みでなければ表示しない
  if (post.status !== "APPROVED") {
    return false;
  }
  // 承認済みであっても、投稿予約されていて、投稿時間前であれば表示しない
  if (post.scheduledSendAt) {
    if (new Date(post.scheduledSendAt) > new Date()) {
      return false;
    }
  }
  return true;
}
