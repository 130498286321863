import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { getStaticImageUrl } from "../../../../utils/getStaticImageUrl";

type Props = {
  src: string | null;
  title: string;
};

const RecruitmentThumbnailImage = ({ src, title }: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.clientWidth);
    }
  }, [ref]);

  const fontSize = getFontSizeFromWidth(width);

  const defaultPreviewImgUrl = getStaticImageUrl("/defaultPreviewImg.png");
  return (
    <ImageWrapper ref={ref}>
      <BackgroundImage src={src ?? defaultPreviewImgUrl} />
      {src === null && <Text fontSize={fontSize}>{title}</Text>}
    </ImageWrapper>
  );
};

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 6px;
`;

const BackgroundImage = styled.div<{
  src: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 6px;
`;

const Text = styled.p<{
  fontSize: number;
}>`
  /* position */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 7.5%;
  /* font-style */
  color: #343741;
  font-size: ${(props) => props.fontSize}px;
  font-weight: bold;
  /* 1行のときだけ中央揃え */
  margin-inline: auto;
  max-inline-size: max-content;
  /* 3行以上になると「...」で省略される */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

/**
 * 親要素の幅からフォントサイズを計算する
 */
const getFontSizeFromWidth = (width: number) => {
  return Math.floor(width / 23);
};

export default memo(RecruitmentThumbnailImage);
