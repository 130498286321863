import React from "react";
import styled from "styled-components";
import { NextButtonIcon } from "./icons/NextButtonIcon";

type Props = {
  type?: "submit" | "button" | "reset";
  onClick: () => void;
};

// FIXME: do not use "export const" with styled-components
export const NextButton = ({ type = "button", onClick }: Props) => {
  return (
    <NextYearsButton type={type} onClick={onClick}>
      <NextButtonIcon />
    </NextYearsButton>
  );
};

// FIXME: do not use "export const" with styled-components
export const NextYearsButton = styled.button`
  background: #fff;
  border: none;
  cursor: pointer;
`;
