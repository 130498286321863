import { useCallback, useEffect, useState } from "react";
import {
  getApplyRecruitmentList,
  getApproveRecruitmentList,
  getDraftRecruitmentList,
} from "../../apiClients/recruitment";
import { Recruitment } from "@shared/types/recruitment";

export const useDraftRecruitment = () => {
  const [recruitments, setRecruitments] = useState<Recruitment[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchRecruitment = useCallback(async () => {
    setLoading(true);
    const recruitments = await getDraftRecruitmentList();
    setRecruitments(recruitments);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchRecruitment();
  }, []);

  return { recruitments, isLoading, refetch: fetchRecruitment };
};

export const useApplyRecruitment = (status?: string) => {
  const [recruitments, setRecruitments] = useState<Recruitment[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchRecruitment = useCallback(async () => {
    setLoading(true);
    const filter = status === "" ? undefined : status;
    const recruitments = await getApplyRecruitmentList(filter);
    setRecruitments(recruitments);
    setLoading(false);
  }, [status]);

  useEffect(() => {
    fetchRecruitment();
  }, []);

  return { recruitments, isLoading, refetch: fetchRecruitment };
};

export const useApproveRecruitment = () => {
  const [recruitments, setRecruitments] = useState<Recruitment[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchRecruitment = useCallback(async () => {
    setLoading(true);
    const recruitments = await getApproveRecruitmentList();
    setRecruitments(recruitments);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchRecruitment();
  }, []);

  return { recruitments, isLoading, refetch: fetchRecruitment };
};
