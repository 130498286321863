import { useState, useCallback, useEffect } from "react";
import * as authApi from "../../apiClients/auth";
import { ChildClass } from "../../apiClients/child";

export const useTeacherInfo = () => {
  const [currentTeacherInfo, setCurrentTeacherInfo] = useState<
    { grade: number; class: ChildClass } | null | undefined
  >(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const getTeacherInfo = useCallback(async () => {
    setIsLoading(true);
    const info = (await authApi.getTeacherInfo()).data;
    setCurrentTeacherInfo(info);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getTeacherInfo();
  }, []);

  const updateTeacherInfo = useCallback(
    async (data: { grade: number; class: ChildClass }) => {
      await authApi.updateTeacherInfo(data);
    },
    []
  );

  return {
    isLoading,
    currentTeacherInfo,
    updateTeacherInfo,
    refetchTeacherInfo: getTeacherInfo,
  };
};
