import React, { FC } from "react";

export const FlexLayout: FC<{
  direction?: "column" | "row";
  children?: React.ReactNode;
}> = ({ direction, children }) => {
  const style: React.CSSProperties = {
    display: "flex",
    flexDirection: direction === "column" ? "column" : "row",
  };
  return <div style={style}>{children}</div>;
};
