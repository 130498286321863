import { useMemo } from "react";
import * as usersApi from "src/apiClients/boardEducation/users";
import { useQuery } from "@tanstack/react-query";
import { ResidentUserDetail } from "@shared/types/boardEducation/residentUser";

export function useBEResidentUserById(accountId: string, communityId: string) {
  const query = useQuery<ResidentUserDetail, Error>({
    // TODO: Fix this queryKey
    queryKey: [
      "api",
      "boardEducation",
      "residentUser",
      "detail",
      accountId,
      communityId,
    ],
    queryFn: () => usersApi.getBEResidentUserDetail({ accountId, communityId }),
  });

  return {
    memberUser: useMemo(() => query.data, [query.data]),
    getUser: query.refetch,
    ...query,
  };
}
