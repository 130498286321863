import { createRoute } from "..";
import AppRegistrationLayout from "../../layouts/AppRegistrationLayout";
import RegisterOrganizationPage from "../../pages/registration/resident/RegisterOrganizationPage";
import ResidentContactInfoPage from "../../pages/registration/resident/ResidentContactInfoPage";
import ResidentRegisterInfoPage from "../../pages/registration/resident/ResidentRegisterInfoPage";
import ResidentRegisterPreviewPage from "../../pages/registration/resident/ResidentRegisterPreviewPage";
import ResidentRegisterDescriptionPage from "../../pages/registration/resident/ResidentRegistrationDescription";
import RegidentRegisterCommunityPage from "../../pages/registration/resident/RegidentRegisterCommunityPage";

/**
 * 登録関連のルート(地域住民)
 */
export const REGISTER_RESIDENT_ROUTE_DEFS = {
  RESIDENT_REGISTER_ORGANIZATION: createRoute({
    path: "/resident_register_organization",
    title: "",
    component: RegisterOrganizationPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_CS",
  }),
  RESIDENT_REGISTER_DESCRIPTION: createRoute({
    path: "/resident_register_description",
    title: "",
    component: ResidentRegisterDescriptionPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_CS",
  }),
  RESIDENT_REGISTER_INFO: createRoute<{ token?: string }>({
    path: "/resident_register_info",
    title: "",
    component: ResidentRegisterInfoPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_CS",
  }),
  RESIDENT_CONTACT_INFO: createRoute<{ token?: string }>({
    path: "/resident_contact_info",
    title: "",
    component: ResidentContactInfoPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_CS",
  }),
  RESIDENT_REGISTER_COMMUNITY: createRoute<{ token?: string }>({
    path: "/resident_register_community",
    title: "",
    component: RegidentRegisterCommunityPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_CS",
  }),
  RESIDENT_REGISTER_PREVIEW: createRoute<{ token?: string }>({
    path: "/resident_register_preview",
    title: "",
    component: ResidentRegisterPreviewPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_CS",
  }),
};

export const registerResidentRouters = Object.values(
  REGISTER_RESIDENT_ROUTE_DEFS
);
