import * as client from "./client";

export async function entity(filePath: string) {
  const res = await client.getBlob<{ filePath: string }>("/storage/entity", {
    filePath,
  });
  return res;
}

export async function getDownloadUrl(filePath: string, fileName: string) {
  const res = await client.get<
    { filePath: string; fileName: string },
    { url: string }
  >("/storage/download", { filePath, fileName });
  return res.url;
}

export async function upload({ file }: { file: File }): Promise<string> {
  const res = await client.postWithFormData<
    { file: File },
    { filePath: string }
  >("/storage/upload", { file });
  return res.filePath;
}

// uploadとの差分
// - uploadはuploadしたファイルの相対パスが返ってくる
// ex: /storage/2021/01/01/xxxxx.pdf
// - uploadImageはuploadしたファイルのURLがフルパスで返ってくる
// ex: https://xxxxx.cloudfront.net/storage/2021/01/01/xxxxx.pdf
export async function uploadImage({
  file,
  imageUploadDirectory,
}: {
  file: File;
  imageUploadDirectory?: string;
}): Promise<string> {
  const res = await client.postWithFormData<
    { file: File; imageUploadDirectory?: string },
    { filePath: string }
  >("/storage/uploadImage", { file, imageUploadDirectory });
  return res.filePath;
}

export async function deleteImage({
  filePath,
}: {
  filePath: string;
}): Promise<string> {
  const res = await client.post<{ filePath: string }>("/storage/deleteImage", {
    filePath,
  });
  return res.result;
}

export function entityUrl(filePath: string) {
  return `${client.PATH_PREFIX}/storage/entity?filePath=${encodeURIComponent(
    filePath
  )}`;
}
