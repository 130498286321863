import React, { memo } from "react";
import { SidebarMenu } from "./SidebarMenu";
import { useLocation } from "react-router-dom";
import { MemberListIcon } from "../../icons/MemberListIcon";
import { MessageIcon } from "../../icons/MessageIcon";
import { BagEditIcon } from "../../icons/BagEditIcon";

export const BEAdminSidebar = memo(() => {
  const { pathname } = useLocation();

  return (
    <>
      <SidebarMenu
        to="/board-education/member/list"
        isActive={pathname.startsWith("/board-education/member/list")}
        icon={<MemberListIcon />}
        text="名簿"
      />
      <SidebarMenu
        to="/board-education/recruitment/list"
        isActive={pathname.startsWith("/board-education/recruitment")}
        icon={<BagEditIcon />}
        text="募集"
        menus={[
          {
            text: "募集一覧",
            to: "/board-education/recruitment/list",
          },
          {
            text: "応募一覧",
            to: "/board-education/recruitment/application_list",
          },
        ]}
      />

      <SidebarMenu
        to="/board-education/chatroom"
        isActive={pathname.startsWith("/board-education/chatroom")}
        icon={<MessageIcon />}
        text="チャット"
      />
    </>
  );
});
