import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const searchTextState = atom<string>({
  key: "SearchTextField",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const useSearchText = () => {
  const [searchText, setSearchText] = useRecoilState(searchTextState);

  return [searchText, setSearchText] as const;
};
