import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import { useMessageList } from "../useMessageList";
import { ChatMessage } from "src/features/common/ChatMessage/components/ChatMessage";
import { DateLabel } from "src/features/common/ChatMessage/components/DateLabel";
import {
  ChatRoom,
  IndividualChatWithOrganization,
} from "@shared/types/chatRoom";
import { SystemMessage } from "src/features/common/ChatMessage/components//SystemMessage";
import { ChatRoomOrigin } from "@shared/types/chatRoom";
import { BREAKPOINTS } from "src/components/Responsive";
import { ChatRoomBackButton } from "src/features/common/ChatMessage/components/ChatRoomBackButton";
import { useFloatingDateLabel } from "src/features/common/ChatMessage/hooks/useFloatingDateLabel";

type Props = {
  isOpen: boolean;
  chatRoomParticipantId: string;
  chatRoom: ChatRoom;
  individualChatWithOrganization: IndividualChatWithOrganization;
  communityName: string;
  lastReadAt: Date;
};

export const ChatRoomIndividualContent = (props: Props) => {
  const chatRoomInfoRef = useRef<HTMLDivElement>(null);
  const messageListRef = useRef<HTMLDivElement>(null); // チャットメッセージエリアの参照
  const { displayItemList, lastReadAt } = useMessageList({
    initLastReadAt: props.lastReadAt,
    messageListRef,
    chatRoomParticipantId: props.chatRoomParticipantId,
  });
  const currentDate = useFloatingDateLabel({
    messageListRef,
    displayItemList,
  }); // メッセージ一覧画面の上部にfixedで表示する日付

  // ChatRoomInfoの高さ分paddingをつける
  const chatRoomInfoHeight = useMemo(() => {
    return chatRoomInfoRef.current?.clientHeight ?? 0;
  }, [chatRoomInfoRef.current]);

  if (!displayItemList) return null;
  if (props.chatRoom.approvalStatus === "REJECTED") return null;

  return (
    <ChatRoomContainer isOpen={props.isOpen}>
      <ChatRoomInfo ref={chatRoomInfoRef}>
        <Header>
          <ChatRoomBackButton targetRoute="BE_CHATROOM_LIST" />
          <HeaderTitle>
            {props.individualChatWithOrganization.account.name}
          </HeaderTitle>
          {props.chatRoom.origin === ChatRoomOrigin.QRCODE && (
            <StatusBadge color="#F9AB01">QRコードから連絡</StatusBadge>
          )}
        </Header>
        <HeaderSubLabel>{props.communityName}</HeaderSubLabel>
      </ChatRoomInfo>
      <ChatBox paddingTop={chatRoomInfoHeight}>
        <ChatMessages ref={messageListRef}>
          {currentDate && <FloatDateLabel>{currentDate}</FloatDateLabel>}
          {displayItemList.map((item, index) => {
            switch (item.itemType) {
              case "dateLabel":
                return (
                  <DateLabel key={index} data={item} className="date-label" />
                );
              case "systemMessage":
                return <SystemMessage key={index} message={item} />;
              case "userMessage":
                return (
                  <ChatMessage
                    key={index}
                    message={item}
                    participantId={props.chatRoomParticipantId}
                    lastReadAt={lastReadAt}
                  />
                );
            }
          })}
        </ChatMessages>
      </ChatBox>
    </ChatRoomContainer>
  );
};

const ChatRoomContainer = styled.div<{
  isOpen: boolean;
}>`
  position: relative;
  flex: 1;
  min-width: 0;
  height: 100%;
  max-height: 100%;
  background: #fff;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }
`;

const ChatRoomInfo = styled.div`
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChatBox = styled.div<{ paddingTop: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${(props) =>
    props.paddingTop ? props.paddingTop + "px 8px 0 8px" : "60px 8px 0 8px"};
  height: 100%;
`;

const ChatMessages = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1;
  gap: 40px;
  height: 100%;
  padding-bottom: 20px;
`;

const Header = styled.div`
  display: flex;
  gap: 8px;
  & > button {
    display: none;
    @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
      display: block;
    }
  }
`;

const HeaderTitle = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.05em;
  text-align: left;
`;

const StatusBadge = styled.span<{ color: string }>`
  background: ${(props) => props.color};
  color: white;
  font-size: 12px;
  padding: 4px 4px 0px 4px;
  border-radius: 20px;
  margin-left: auto;
`;

const HeaderSubLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #69707d;
`;

const FloatDateLabel = styled.div`
  background: white;
  height: 24px;
  font-size: 12px;
  padding: 0 16px;
  border-radius: 24px;
  border: 1px solid rgba(221, 221, 221, 1);
  text-align: center;
  width: fit-content;
  font-weight: 600;
  position: sticky;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  line-height: 22px;
`;
