import * as client from "./client";

export type Partner = {
  id: string;
  email: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  isShare: boolean;
};

export const getPartner = async (): Promise<Partner | null> => {
  const res = await client.get("/partner/getPartner", {});
  return res.data;
};

export const getPartnerForAdmin = async (
  userId: string
): Promise<Partner | null> => {
  const res = await client.get("/partner/getPartnerForAdmin", { userId });
  return res.data;
};

export type UpdatePartnerProps = {
  email: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  isShare: boolean;
};

export const upsertPartner = async (
  data: UpdatePartnerProps
): Promise<void> => {
  await client.post("/partner/upsertPartner", { data });
};
