import React, { ReactNode } from "react";
import { FileIcon } from "../icons/FileIcon";
import styled from "styled-components";

type Props = {
  title?: string;
  message?: string;
  icon?: ReactNode;
};

export const EmptyResultCard = ({
  title,
  message,
  icon = <FileIcon />,
}: Props) => {
  return (
    <Card>
      {icon}
      <Title>{title}</Title>
      <Message>{message}</Message>
    </Card>
  );
};

const Title = styled.p`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 4px;
  color: #343741;
`;

const Message = styled.p`
  font-size: 16px;
  color: #343741;
`;

const Card = styled.div`
  border-radius: 6px;
  border: 1px solid #e4e6f3;
  background: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
