import React from "react";

export const MailIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5.38889V18.6111H20V5.38889H4ZM3 3.5C2.44772 3.5 2 3.92284 2 4.44444V19.5556C2 20.0772 2.44772 20.5 3 20.5H21C21.5523 20.5 22 20.0772 22 19.5556V4.44444C22 3.92284 21.5523 3.5 21 3.5H3Z"
        fill="#37434F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.293 14.7072L2.29297 5.70718L3.70718 4.29297L12.0001 12.5859L20.293 4.29297L21.7072 5.70718L12.7072 14.7072C12.5196 14.8947 12.2653 15.0001 12.0001 15.0001C11.7349 15.0001 11.4805 14.8947 11.293 14.7072Z"
        fill="#37434F"
      />
    </svg>
  );
};
