import React from "react";

export const SurveyIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 14.5C5.28333 14.5 5.52083 14.4042 5.7125 14.2125C5.90417 14.0208 6 13.7833 6 13.5C6 13.2167 5.90417 12.9792 5.7125 12.7875C5.52083 12.5958 5.28333 12.5 5 12.5C4.71667 12.5 4.47917 12.5958 4.2875 12.7875C4.09583 12.9792 4 13.2167 4 13.5C4 13.7833 4.09583 14.0208 4.2875 14.2125C4.47917 14.4042 4.71667 14.5 5 14.5ZM5 10.5C5.28333 10.5 5.52083 10.4042 5.7125 10.2125C5.90417 10.0208 6 9.78333 6 9.5C6 9.21667 5.90417 8.97917 5.7125 8.7875C5.52083 8.59583 5.28333 8.5 5 8.5C4.71667 8.5 4.47917 8.59583 4.2875 8.7875C4.09583 8.97917 4 9.21667 4 9.5C4 9.78333 4.09583 10.0208 4.2875 10.2125C4.47917 10.4042 4.71667 10.5 5 10.5ZM5 6.5C5.28333 6.5 5.52083 6.40417 5.7125 6.2125C5.90417 6.02083 6 5.78333 6 5.5C6 5.21667 5.90417 4.97917 5.7125 4.7875C5.52083 4.59583 5.28333 4.5 5 4.5C4.71667 4.5 4.47917 4.59583 4.2875 4.7875C4.09583 4.97917 4 5.21667 4 5.5C4 5.78333 4.09583 6.02083 4.2875 6.2125C4.47917 6.40417 4.71667 6.5 5 6.5ZM8 14.5H14V12.5H8V14.5ZM8 10.5H14V8.5H8V10.5ZM8 6.5H14V4.5H8V6.5ZM2 18.5C1.45 18.5 0.979167 18.3042 0.5875 17.9125C0.195833 17.5208 0 17.05 0 16.5V2.5C0 1.95 0.195833 1.47917 0.5875 1.0875C0.979167 0.695833 1.45 0.5 2 0.5H16C16.55 0.5 17.0208 0.695833 17.4125 1.0875C17.8042 1.47917 18 1.95 18 2.5V16.5C18 17.05 17.8042 17.5208 17.4125 17.9125C17.0208 18.3042 16.55 18.5 16 18.5H2ZM2 16.5H16V2.5H2V16.5Z"
        fill="white"
      />
    </svg>
  );
};
