export type SchoolByCodeRequest = {
  code: string;
};

export type School = {
  id: string;
  name: string;
  type: string;
  postalCode: string;
  code: string;
  installationSection: string;
  address: string;
};

export type SchoolByCodeResponse = School | null;

export const SchoolTypeOptions = {
  A1: "幼稚園",
  A2: "幼保連携型認定こども園",
  B1: "小学校",
  C1: "中学校",
  C2: "義務教育学校",
  D1: "高等学校",
  D2: "中等教育学校",
  E1: "特別支援学校",
  F1: "大学",
  F2: "短期大学",
  G1: "高等専門学校",
  H1: "専修学校",
  H2: "各種学校",
};

export const isElementarySchoolByType = (type: string) => {
  return type === "B1";
};

export const isJuniorHighSchoolByType = (type: string) => {
  return type === "C1";
};
