import React from "react";
import styled from "styled-components";

type RadioButtonProps = {
  label: string;
  checked: boolean;
  onChange: () => void;
};

export const RadioButton = ({ label, ...props }: RadioButtonProps) => {
  return (
    <StyledLabel>
      <HiddenInput type="radio" {...props} />
      <LabelText>{label}</LabelText>
    </StyledLabel>
  );
};

const HiddenInput = styled.input`
  display: none;
`;

const LabelText = styled.span`
  color: #343741;
  font-size: 14px;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;

  &::before,
  &:has(:checked)::after {
    border-radius: 50%;
    content: "";
  }

  &::before {
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border: 1px solid #c9cbcd;
    box-sizing: border-box;
  }

  &:has(:checked)::before {
    background-color: #0077cc;
    border: none;
  }

  &:has(:checked)::after {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    background-color: #ffffff;
  }
`;
