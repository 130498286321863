import React, { useState } from "react";
import styled from "styled-components";
import * as theme from "../theme";
import { useCurrentUser } from "../hooks/recoil/user";
import { SpMenuButtonIcon } from "./icons/SpMenuButtonIcon";
import { SidebarContent } from "./Common/Sidebar/SideBar";
import { BREAKPOINTS, MobileViewOnly } from "./Responsive";
import { getStaticImageUrl } from "../utils/getStaticImageUrl";
import { Avatar } from "./Common/Avatar";
import { zIndexes } from "../zIndex";
import { logout } from "../utils/auth";
import { useSidebarMenuModal } from "../hooks/recoil/sidebar";
import { useGetCurrentOrganization } from "src/hooks/query/boardEducation/organizations/organization";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

const NaxBox = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: ${zIndexes.fixedElement};
  display: flex;
  align-items: center;
  height: 48px;
  justify-content: space-between;
  padding: 4px 16px;
  background: ${theme.colorsPallet.primary};
`;
const NavHeader = styled.div`
  font-size: ${theme.typo.paragraph};
  color: ${theme.colorsPallet.emptyShade};
  display: flex;
  align-items: center;
  gap: 12px;
`;

const NavTitle = styled.div`
  font-weight: 700;
`;

const NavLabel = styled.div`
  font-weight: normal;
  white-space: nowrap;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const ModalBackGround = styled.div`
  z-index: ${zIndexes.modalBackground};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

const HeaderButtonWrapper = styled.div`
  display: flex;
  svg {
    display: none;
    @media (max-width: 1024px) {
      display: initial;
    }
  }
  justify-content: space-between;
`;

function Navigation() {
  const user = useCurrentUser();
  const { organization } = useGetCurrentOrganization();

  const [
    isShowSPSidebarMenuModal,
    { close: closeSPSidebarMenuModal, toggle: toggleSPSidebarMenuModal },
  ] = useSidebarMenuModal();
  const [isMenuShow, setIsMenuShow] = useState<boolean>(false);

  return (
    <>
      <NaxBox>
        <HeaderButtonWrapper>
          <NavHeader>
            <SpMenuButtonIcon onClick={toggleSPSidebarMenuModal} />
            <NavTitle>polyfit</NavTitle>
            <NavLabel>{organization?.name}</NavLabel>
          </NavHeader>
          {isShowSPSidebarMenuModal && (
            <SPMenuModal onClose={closeSPSidebarMenuModal} />
          )}
        </HeaderButtonWrapper>

        <UserImgButton onClick={() => setIsMenuShow(!isMenuShow)}>
          <Avatar
            src={user?.picture || defaultUserImg}
            alt={user?.name}
            size={30}
          />
        </UserImgButton>
      </NaxBox>
      {isMenuShow && (
        <SettingEditTab>
          <SettingEditInfo
            onClick={() => {
              setIsMenuShow(!isMenuShow);
              // history.push({ to: "PTA_PROFILE", query: { userId: user.id } });
            }}
          >
            プロフィール
          </SettingEditInfo>
          <SettingEditInfo
            onClick={() => {
              setIsMenuShow(!isMenuShow);
              logout();
            }}
          >
            ログアウト
          </SettingEditInfo>
        </SettingEditTab>
      )}
      {isMenuShow && <ModalBackGround onClick={() => setIsMenuShow(false)} />}
    </>
  );
}

export { Navigation };

const ModalWrapper = styled.div`
  position: absolute;
  width: 160px;
  top: 40px;
  top: 0;
  left: 0;
  height: 100vh;
  padding-top: 24px;
  background: white;
  display: none;
  @media (max-width: ${BREAKPOINTS.PC}) {
    display: block;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: ${zIndexes.modal};
  opacity: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserImgButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

const SettingEditInfo = styled.div`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e4e6f3;
  }
`;

const SettingEditTab = styled.div`
  position: fixed;
  right: 15px;
  top: 35px;
  z-index: ${zIndexes.modal};
  background-color: #ffffff;
  border-radius: 5px;
  width: 200px;
  border: 1px solid #e4e6f3;
  padding: 5px 0;
`;

export type SPMenuModalProps = {
  onClose: () => void;
};
const SPMenuModal = ({ onClose }: SPMenuModalProps) => {
  return (
    <MobileViewOnly>
      <ModalOverlay onClick={onClose}>
        <ModalWrapper onClick={(e) => e.stopPropagation()}>
          <SidebarContent />
        </ModalWrapper>
      </ModalOverlay>
    </MobileViewOnly>
  );
};
