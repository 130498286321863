import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

type PromotionDisplayState = {
  showPromoteResidentInfoModalAt?: string; // DateをISO文字列として保存
};

// プロモーション関連の表示状態を管理するrecoil
// 新規ログイン後の操作ガイドのモーダルや新機能紹介などを想定
const promotionDisplayState = atom<PromotionDisplayState>({
  key: "PromotionDisplayState",
  default: {
    showPromoteResidentInfoModalAt: undefined,
  },
  effects_UNSTABLE: [persistAtom],
});

export const usePromotionDisplay = () => {
  const [promotionDisplay, setPromotionDisplay] = useRecoilState(
    promotionDisplayState
  );

  const updatePromotionDisplay = (
    key: keyof PromotionDisplayState,
    value: string | undefined
  ) => {
    setPromotionDisplay((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return [promotionDisplay, updatePromotionDisplay] as const;
};
