import React from "react";

type Props = {
  size: number;
};
export const CheckIcon = ({ size }: Props) => {
  return (
    <svg
      width={`${size * 1.5}px`}
      height={`${size}px`}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50025 8.00025C4.37225 8.00025 4.24425 7.95125 4.14625 7.85425L0.14625 3.85425C-0.04875 3.65825 -0.04875 3.34225 0.14625 3.14625C0.34225 2.95125 0.65825 2.95125 0.85425 3.14625L4.50025 6.79325L11.1462 0.14625C11.3422 -0.04875 11.6582 -0.04875 11.8542 0.14625C12.0492 0.34225 12.0492 0.65825 11.8542 0.85425L4.85425 7.85425C4.75625 7.95125 4.62825 8.00025 4.50025 8.00025Z"
        fill="white"
      />
    </svg>
  );
};
