import React, { useCallback, useState } from "react";
import { FilePlusIcon } from "../../../components/icons/FilePlusIcon";
import { Button } from "../../../components/Button3";
import { Description, Title } from "./PartnerInfoContent";
import { PartnerInfoEditModal } from "./PartnerInfoEditModal";
import styled from "styled-components";

type Props = {
  fetchPartner: () => void;
};
export const NoPartner = ({ fetchPartner }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <>
      <NoPartnerWrapper>
        <StyledFlexContainer>
          <StyledCardUI>
            <StyledContainerPc>
              <Title>他の保護者情報</Title>

              <Flex style={{ justifyContent: "flex-end" }}>
                <Button size="large" fill color="text" onClick={onOpen}>
                  他の保護者を追加
                </Button>
              </Flex>
              <Flex style={{ justifyContent: "space-around" }}>
                <FilePlusIcon />
              </Flex>
              <Flex style={{ justifyContent: "space-around" }}>
                <TextGray>
                  他の保護者を追加すると表示されます。
                  <br />
                  追加すると他の保護者に向けてメールを送信できるようになります。
                </TextGray>
              </Flex>
            </StyledContainerPc>

            <StyledContainerSp>
              <Title>他の保護者情報</Title>

              <Flex style={{ justifyContent: "center" }}>
                <Button size="large" fill color="text" onClick={onOpen}>
                  他の保護者を追加
                </Button>
              </Flex>

              <FileIconWrapper>
                <FilePlusIcon />
              </FileIconWrapper>

              <Flex style={{ justifyContent: "center" }}>
                <TextGray>
                  他の保護者を追加すると表示されます。
                  <br />
                  追加すると他の保護者に向けてメールを送信できるようになります。
                </TextGray>
              </Flex>
            </StyledContainerSp>
          </StyledCardUI>
        </StyledFlexContainer>
        <Description>
          （注意）家族に関する情報はpolyfitでのメール配信に使われますが、ユーザーには共有されない内容です。
        </Description>
      </NoPartnerWrapper>

      {isOpen && (
        <PartnerInfoEditModal
          onClose={onClose}
          partner={null}
          fetch={fetchPartner}
        />
      )}
    </>
  );
};

const FileIconWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
`;

const NoPartnerWrapper = styled.div`
  width: 85%;

  @media (max-width: 1025px) {
    width: 100%;
  }
`;

const StyledCardUI = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 0.8px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 17px rgba(0, 0, 0, 0.03);
  padding: 24px;
`;

const StyledContainerPc = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledContainerSp = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const StyledFlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 480px) {
    display: block;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const TextGray = styled.p`
  color: #69707d;
  font-size: 14px;
  text-align: center;
`;
