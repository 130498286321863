import { createRoute } from "..";
import AppRegistrationLayout from "../../layouts/AppRegistrationLayout";
import EmptyLayout from "../../layouts/EmptyLayout";
import OrganizationSettingPage from "../../pages/registration/pta/OrganizationSettingPage";
import PositionSettingPage from "../../pages/registration/pta/PositionSettingPage";
import RegisterChildInfoPage from "../../pages/registration/pta/RegisterChildInfoPage";
import RegisterOrganizationPage from "../../pages/registration/pta/RegisterOrganizationPage";
import RegisterDescriptionPage from "../../pages/registration/pta/RegisterDescriptionPage";
import RegisterInfoPage from "../../pages/registration/pta/RegisterInfoPage";
import RegisterTeacherInfoPage from "../../pages/registration/pta/RegisterTeacherInfoPage";
import RegistrationUserTypePage from "../../pages/registration/pta/RegistrationUserTypePage";
import SignUpCompleteAdminPage from "../../pages/registration/pta/SignUpCompleteAdminPage";
import SignUpPreviewMemberPage from "../../pages/registration/pta/SignUpPreviewMemberPage";
import SignatureConfirmPage from "../../pages/registration/pta/SignatureConfirmPage";
import SignatureSettingPage from "../../pages/registration/pta/SignatureSettingPage";

/**
 * 登録関連のルート(PTA)
 */
export const REGISTER_ROUTE_DEFS = {
  REGISTER_ORGANIZATION: createRoute({
    path: "/register_organization",
    title: "",
    component: RegisterOrganizationPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_PTA",
  }),
  REGISTER_DESCRIPTION: createRoute({
    path: "/register_description",
    title: "",
    component: RegisterDescriptionPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_PTA",
  }),
  REGISTER_USER_TYPE: createRoute({
    path: "/register_user_type",
    title: "",
    component: RegistrationUserTypePage,
    layout: EmptyLayout,
    route: "REGISTER_PTA",
  }),
  REGISTER_INFO: createRoute({
    path: "/register_info",
    title: "",
    component: RegisterInfoPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_PTA",
  }),
  REGISTER_CHILD_INFO: createRoute({
    path: "/register_child_info",
    title: "",
    component: RegisterChildInfoPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_PTA",
  }),
  REGISTER_TEACHER_INFO: createRoute({
    path: "/register_teacher_info",
    title: "",
    component: RegisterTeacherInfoPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_PTA",
  }),
  COMMUNITY_SETTING: createRoute({
    path: "/community_setting",
    title: "",
    component: OrganizationSettingPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_PTA",
  }),
  POSITION_SETTING: createRoute({
    path: "/position_setting",
    title: "",
    component: PositionSettingPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_PTA",
  }),
  SIGNATURE_SETTING: createRoute({
    path: "/signature_setting",
    title: "",
    component: SignatureSettingPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_PTA",
  }),
  SIGNATURE_CONFIRM: createRoute({
    path: "/signature_confirm",
    title: "",
    component: SignatureConfirmPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_PTA",
  }),
  SIGNUP_PREVIEW_MEMBER: createRoute({
    path: "/signup_preview_member",
    title: "",
    component: SignUpPreviewMemberPage,
    layout: AppRegistrationLayout,
    route: "REGISTER_PTA",
  }),
  SIGNUP_COMPLETE_ADMIN: createRoute({
    path: "/signup_complete_admin",
    title: "",
    component: SignUpCompleteAdminPage,
    layout: EmptyLayout,
    route: "REGISTER_PTA",
  }),
};

export const registerRouters = Object.values(REGISTER_ROUTE_DEFS);
