import * as client from "../client";

export type ParticipantChatRoomUpdateArgsType = {
  chatRoomId: string;
};

export async function updateParticipantChatRoom(
  arg: ParticipantChatRoomUpdateArgsType
): Promise<void> {
  await client.post<{ arg: ParticipantChatRoomUpdateArgsType }>(
    "/boardEducation/participantChatRoom/update",
    { arg }
  );
}
