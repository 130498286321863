import React from "react";

type Props = {
  color?: string;
};

export const PencilIcon = ({ color = "#0077CC" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.1481 3.14807L11 2L2 11V14H5L14 5L12.8561 3.85607L4.854 11.854C4.756 11.951 4.628 12 4.5 12C4.372 12 4.244 11.951 4.146 11.854C3.951 11.658 3.951 11.342 4.146 11.146L12.1481 3.14807ZM11 1C11.256 1 11.512 1.098 11.707 1.293L14.707 4.293C15.098 4.683 15.098 5.317 14.707 5.707L5.707 14.707C5.52 14.895 5.265 15 5 15H2C1.448 15 1 14.552 1 14V11C1 10.735 1.105 10.48 1.293 10.293L10.293 1.293C10.488 1.098 10.744 1 11 1ZM5 14H2V11L5 14Z"
        fill={color}
      />
    </svg>
  );
};
