import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import { getRecruitmentList } from "src/apiClients/recruitment";
import { QueryOptions } from "src/hooks/query/QueryOptions";

type Condition = {
  volunteerType: string | undefined;
  isPaidVolunteer: "PAID" | "FREE" | undefined;
  date: string | undefined;
  isOpen: boolean;
};

export const useRecruitmentList = (
  condition: Condition,
  queryOptions: QueryOptions = {}
) => {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "recruitment", "list", apiContext, condition],
    queryFn: async () => await getRecruitmentList(condition),
    ...queryOptions,
  });

  return {
    recruitments: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
};
