import { useCallback } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { logger } from "src/utils/logger";

const apiKey = import.meta.env.VITE_GOOGLE_MAP_API_KEY;

const useGoogleGeocoding = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  const getLocationAddressByAddress = async (address: string) => {
    if (!isLoaded) return;

    const geocoder = new window.google.maps.Geocoder();
    try {
      const results = await geocoder.geocode({ address });

      const splitted = extractAddress(
        results.results[0].address_components.reverse(),
        results.results[0].formatted_address
      );
      const { location } = results.results[0].geometry;
      return {
        ...splitted,
        latitude: location.lat(),
        longitude: location.lng(),
      };
    } catch (error) {
      logger.error(new Error(`Error in geocoding:` + error));
    }
  };

  const extractAddress = useCallback(
    (
      addressComponents: google.maps.GeocoderAddressComponent[],
      formattedAddress: string
    ) => {
      try {
        const formattedAddressObject = {
          postalCode:
            addressComponents
              .find((c) => c.types.includes("postal_code"))
              ?.long_name.replace("-", "") ?? "",
          prefecture:
            addressComponents.find((c) =>
              c.types.includes("administrative_area_level_1")
            )?.long_name ?? "",
          city:
            addressComponents.find((c) => c.types.includes("locality"))
              ?.long_name ?? "",
          address1:
            addressComponents
              .filter(
                (c) =>
                  c.types.includes("sublocality_level_1") ||
                  c.types.includes("sublocality")
              )
              ?.map((c) => c.long_name)
              .join("") ?? "",
          address2: "",
        };

        formattedAddressObject.address1 =
          formattedAddressObject.address1 +
            formattedAddress
              .split(formattedAddressObject.address1)?.[1]
              .split(" ")?.[0] ?? "";

        formattedAddressObject.address2 =
          formattedAddress
            .split(formattedAddressObject.address1)?.[1]
            .split(" ")
            .slice(1)
            .join(" ") ?? "";

        return formattedAddressObject;
      } catch (error) {
        logger.error(new Error(`Error in reverse geocoding:` + error));
        throw error;
      }
    },
    []
  );

  return { getLocationAddressByAddress };
};

export default useGoogleGeocoding;
