import React from "react";
import styled from "styled-components";
import { PrevButtonIcon } from "./icons/PrevButtonIcon";

type Props = {
  type?: "submit" | "button" | "reset";
  onClick: () => void;
};

export const PrevButton = ({ type = "button", onClick }: Props) => {
  return (
    <PrevYearsButton type={type} onClick={onClick}>
      <PrevButtonIcon />
    </PrevYearsButton>
  );
};

const PrevYearsButton = styled.button`
  background: #fff;
  border: none;
  cursor: pointer;
`;
