import React, { useCallback, useMemo, useRef, useState } from "react";
import { User, isResidentUser, isTeacherUser } from "../../apiClients/users";
import { useCurrentUser } from "../../hooks/recoil/user";
import {
  BaseInfo,
  CurrentUser,
  updateBelongAndPart,
  updateCommunityRoles,
  updateResidentInfoByCSAdmin,
  updateTeacherInfoByAdmin,
} from "../../apiClients/auth";
import { useCurrentCommunityId, usePolyfitHistory } from "../../hooks/router";
import {
  Child,
  ChildClass,
  upsertAndDeleteChildren,
} from "../../apiClients/child";
import {
  PaidType,
  VolunteerType,
  paidOption,
  volunteerOption,
} from "../../@shared/types/volunteerType";
import { DayOfWeekType, dayOfWeekOptions } from "../../utils/types/dayOfWeek";
import {
  MedicalLicenseType,
  SkillType,
  TeachingLicenseType,
  medicalLicenseOptions,
  skillOptions,
  teachingLicenseOptions,
} from "../../utils/types/license";
import { useFieldArray, useForm } from "react-hook-form";
import { toInputDateFormatYearMonth } from "../../utils/time";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../components/Modal";
import { getAddressFromPostalCode } from "../../apiClients/postcode";
import styled from "styled-components";
import { Heading3 } from "../../components/Typo";
import { Margin } from "../../components/Margin";
import { Button, ButtonGroup } from "../../components/Button";
import { ButtonSelect } from "../../components/ButtonSelect";
import { prefectureOptions } from "../../utils/types/prefecture";
import { genderOption } from "../../utils/types/gender";
import { CameraIcon } from "../../components/icons/CameraIcon";
import { getStaticImageUrl } from "../../utils/getStaticImageUrl";
import { CheckIcon } from "../../components/icons/CheckIcon";
import {
  AddChildForm,
  TrashButton,
  TrashIcon,
} from "../../pages/registration/pta/RegisterChildInfoPage";
import { classOption } from "../../utils/getChildClass";
import { useRegisterInfo } from "../../hooks/api/baseInfo";
import { Role, isPtaAdminRole, isPtaRole } from "../../utils/types/role";
import { ageGroup, zTransformAgeGroup } from "../../utils/types/ageGroup";
import { Organization } from "../../apiClients/organization";
import { EditBaseInfoCommunity } from "./EditBaseInfoCommunity";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { useInvalidateGetChildrenByCondition } from "src/hooks/query/childrenList";
import { FormValue as CommunityFormValue } from "src/features/Member/Community/utils/form";
import { UserType } from "../../../../server/src/@shared/types/userType";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  parentPTAFormValueSchema,
  parentPTAAdminFormValueSchema,
  teacherPTAFormValueSchema,
  teacherPTAAdminFormValueSchema,
} from "src/utils/zod/PTABaseInfoSchema";
import { CSAdminFormValueSchema } from "src/utils/zod/CSBaseInfoSchema";
import { sortByOrder } from "src/utils/sortByOrder";
import { useInvalidateGetResidentUsers } from "src/hooks/query/residentUsers";
import { logger } from "src/utils/logger";

const gradeOption = [
  { value: "1", text: "1" },
  { value: "2", text: "2" },
  { value: "3", text: "3" },
  { value: "4", text: "4" },
  { value: "5", text: "5" },
  { value: "6", text: "6" },
];

const isTeacherOption = [
  { value: "true", text: "あり" },
  { value: "false", text: "なし" },
];

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

type EditBaseInfoModalProps = {
  user: User;
  organization: Organization;
  onClose: () => void;
  getUser: () => Promise<void>;
  getChildren: () => Promise<void>;
  children: Child[];
  userId: string;
  cu: CurrentUser;
};

// EditBaseInfoModalFormValues の型
export type EditBaseInfoModalFormValues = {
  baseInfo: BaseInfo;
  children: Child[];
  teacherInfo: {
    hasHomeroom: boolean;
    grade?: number;
    class?: ChildClass;
  };
  residentInfo: {
    preferredDays: DayOfWeekType[];
    teacherLicenses: TeachingLicenseType[];
    medicalLicenses: MedicalLicenseType[];
    skills: SkillType[];
    preferredPaid?: "EITHER" | PaidType | null;
    preferredVolunteerType: VolunteerType[];
    privateEnterprise: string | null;
    university: string | null;
    externalOrganization: string | null;
    pta: string | null;
  };
  community: CommunityFormValue;
};

export const EditBaseInfoModal = ({
  user,
  organization,
  onClose,
  getUser,
  getChildren,
  children,
  userId,
  cu,
}: EditBaseInfoModalProps) => {
  const [faceImg, setFaceImg] = useState<File>();
  const currentUser: CurrentUser = useCurrentUser();
  const { communityId: currentCommunityId } = useCurrentCommunityId();
  const { registerInfo, registerFaceImgPath } = useRegisterInfo();
  const history = usePolyfitHistory();
  const toast = useToast();
  const { invalidateGetChildrenByCondition } =
    useInvalidateGetChildrenByCondition();
  const { invalidateGetResidentUsers } = useInvalidateGetResidentUsers();

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async (data: EditBaseInfoModalFormValues) => {
    setDoubleClickBlocked(true);
    try {
      const {
        baseInfo,
        children: newChildren,
        teacherInfo,
        residentInfo,
        community,
      } = data;
      await onSubmitFile();
      await registerInfo(baseInfo);
      if (user.type === "PARENT") {
        const childrenWithUserId = newChildren.map((child, i) => {
          return {
            childFirstName: child.childFirstName,
            childFirstNameKana: child.childFirstNameKana,
            childLastName: child.childLastName,
            childLastNameKana: child.childLastNameKana,
            class: child.class,
            grade: child.grade,
            userId: user.id,
            id: children[i]?.id || child.id,
          };
        });
        await upsertAndDeleteChildren(user.id, childrenWithUserId);
      }
      if (user.type === "TEACHER") {
        await updateTeacherInfoByAdmin(
          userId,
          teacherInfo?.hasHomeroom && teacherInfo?.grade && teacherInfo?.class
            ? { grade: teacherInfo.grade, class: teacherInfo.class }
            : null
        );
      }
      if (user.type === "RESIDENT") {
        await updateResidentInfoByCSAdmin(userId, {
          ...residentInfo,
          preferredPaid:
            residentInfo.preferredPaid === "EITHER"
              ? ["PAID", "FREE"]
              : residentInfo.preferredPaid === null
              ? []
              : ([residentInfo.preferredPaid] as PaidType[]),
          privateEnterprise: residentInfo.privateEnterprise || null,
          university: residentInfo.university || null,
          externalOrganization: residentInfo.externalOrganization || null,
          pta: residentInfo.pta || null,
        });
        invalidateGetResidentUsers();
        const data = [];
        data.push(
          ...(community.mainElementarySchool
            ? [
                {
                  communityId: community.mainElementarySchool,
                  isMain: true,
                  type: community.mainElementarySchoolType,
                },
              ]
            : []),
          ...(community.mainJuniorHighSchool
            ? [
                {
                  communityId: community.mainJuniorHighSchool,
                  isMain: true,
                  type: community.mainJuniorHighSchoolType,
                },
              ]
            : []),
          ...community.elementarySchools.map((school) => {
            return {
              communityId: school,
              isMain: false,
              type: UserType.RESIDENT,
            };
          }),
          ...community.juniorHighSchools.map((school) => {
            return {
              communityId: school,
              isMain: false,
              type: UserType.RESIDENT,
            };
          })
        );
        const update = { userId, data };
        // 更新後のコミュニティに現在選択しているコミュニティが含まれていない場合はLOADING画面に遷移
        if (update.data.every((u) => u.communityId !== currentCommunityId)) {
          history.push({ to: "LOADING", query: {} });
        }
        await updateCommunityRoles(update);
      }
      if (user.type === "PARENT" || user.type === "TEACHER") {
        await updateBelongAndPart(user.id, baseInfo.belongId, baseInfo.partId);
      }
      await getUser();
      if (user.type === "PARENT") {
        await getChildren();
        invalidateGetChildrenByCondition();
      }
      history.push({
        to: "PTA_LIST_MEMBER",
        query: {
          userId: user.id,
        },
      });
      setDoubleClickBlocked(false);
      onClose();
    } catch (err) {
      setDoubleClickBlocked(false);
      logger.error(err);
      if (err instanceof APIError) {
        toast.error(err.message);
      }
    }
  };

  const selectBelong = async (id: string) => {
    setValue("baseInfo.belongId", id);
  };
  const selectPart = async (id: string) => {
    setValue("baseInfo.partId", id);
  };

  const zodSchema = (() => {
    if (isPtaRole(user.role)) {
      if (user.type === "PARENT") {
        if (isPtaAdminRole(cu.role)) {
          return parentPTAAdminFormValueSchema;
        }
        return parentPTAFormValueSchema;
      } else {
        if (isPtaAdminRole(cu.role)) {
          return teacherPTAAdminFormValueSchema;
        }
        return teacherPTAFormValueSchema;
      }
    } else {
      return CSAdminFormValueSchema;
    }
  })();

  const {
    setValue,
    register,
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<EditBaseInfoModalFormValues>({
    defaultValues: {
      baseInfo: {
        ...user.baseInfo,
        ageGroup: user.baseInfo?.ageGroup ?? -1,
        birthday: user.baseInfo?.birthday
          ? (toInputDateFormatYearMonth(user.baseInfo.birthday) as any)
          : undefined,
      },
      children,
      teacherInfo:
        isTeacherUser(user) && !!user.teacherInfo
          ? {
              hasHomeroom: user.teacherInfo.hasHomeroom,
              grade: user.teacherInfo.grade
                ? user.teacherInfo.grade
                : undefined,
              class: user.teacherInfo.class
                ? user.teacherInfo.class
                : undefined,
            }
          : {
              hasHomeroom: false,
              grade: undefined,
              class: undefined,
            },
      residentInfo:
        isResidentUser(user) && !!user.residentInfo
          ? {
              preferredDays: user.residentInfo.preferredDays,
              teacherLicenses: user.residentInfo.teacherLicenses,
              medicalLicenses: user.residentInfo.medicalLicenses,
              skills: user.residentInfo.skills,
              preferredPaid:
                user.residentInfo.preferredPaid.includes("PAID") &&
                user.residentInfo.preferredPaid.includes("FREE")
                  ? "EITHER"
                  : user.residentInfo.preferredPaid.includes("PAID")
                  ? "PAID"
                  : user.residentInfo.preferredPaid.includes("FREE")
                  ? "FREE"
                  : null,
              preferredVolunteerType: user.residentInfo.preferredVolunteerType,
              privateEnterprise: user.residentInfo.privateEnterprise,
              university: user.residentInfo.university,
              externalOrganization: user.residentInfo.externalOrganization,
              pta: user.residentInfo.pta,
            }
          : {
              preferredDays: [],
              teacherLicenses: [],
              medicalLicenses: [],
              skills: [],
              preferredPaid: null,
              preferredVolunteerType: [],
              privateEnterprise: "",
              university: "",
              externalOrganization: "",
              pta: "",
            },
      community: {
        mainElementarySchool: "",
        mainJuniorHighSchool: "",
        mainElementarySchoolType: undefined,
        mainJuniorHighSchoolType: undefined,
        elementarySchools: [],
        juniorHighSchools: [],
      },
    },
    resolver: zodResolver(zodSchema),
    mode: "onChange",
  });

  const hasHomeroomInput: boolean = watch("teacherInfo.hasHomeroom");

  const { fields, append, remove } = useFieldArray({
    name: "children",
    control,
  });

  const inputFileRef = useRef(null);
  const onClickFileInput = () => {
    const inputFileElement = inputFileRef.current! as HTMLInputElement;
    inputFileElement?.click();
  };

  const onInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target;
      const files = Array.from(input.files || []);
      const file = files[0];
      if (file) {
        setFaceImg(file);
      }
    },
    []
  );

  const onSubmitFile = async () => {
    if (faceImg) await registerFaceImgPath(faceImg);
  };

  const imgPreviewUrl = useMemo(() => {
    if (faceImg) return URL.createObjectURL(faceImg);
    else return "";
  }, [faceImg]);

  const handleGenderChange = (value: "MALE" | "FEMALE" | "OTHER") => {
    setValue("baseInfo.gender", value);
  };

  const handlePreferredDaysChange = (value: DayOfWeekType) => {
    const list = watch("residentInfo.preferredDays");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.preferredDays", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.preferredDays", newLists);
    }
  };

  const handleTeacherChange = (value: TeachingLicenseType) => {
    const list = watch("residentInfo.teacherLicenses");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.teacherLicenses", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.teacherLicenses", newLists);
    }
  };

  const handleMedicalChange = (value: MedicalLicenseType) => {
    const list = watch("residentInfo.medicalLicenses");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.medicalLicenses", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.medicalLicenses", newLists);
    }
  };

  const handleSkillsChange = (value: SkillType) => {
    const list = watch("residentInfo.skills");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.skills", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.skills", newLists);
    }
  };

  const handlePreferredPaidChange = (value: PaidType) => {
    const prev = watch("residentInfo.preferredPaid");
    if (prev === value) {
      setValue("residentInfo.preferredPaid", null);
    } else {
      setValue("residentInfo.preferredPaid", value);
    }
  };

  const handleVolunteerChange = (value: VolunteerType) => {
    const list = watch("residentInfo.preferredVolunteerType");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.preferredVolunteerType", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.preferredVolunteerType", newLists);
    }
  };

  const getAddress = useCallback(async (value: string) => {
    try {
      const res = await getAddressFromPostalCode(value);
      setValue("baseInfo.prefecture", res.prefecture);
      setValue("baseInfo.city", res.city);
      setValue("baseInfo.address1", res.suburb);
      setValue("baseInfo.address2", "");
      clearErrors("baseInfo.postalCode");
    } catch (err) {
      setError("baseInfo.postalCode", {
        message: "ハイフンは入力せず、正しい郵便番号を入力してください",
      });
    }
  }, []);

  const sortedBelongs = sortByOrder(organization.belongs);
  const sortedParts = sortByOrder(organization.parts);

  return (
    <ModalPortal onClose={onClose}>
      <ModalWrapper>
        <ModalHeader>情報を編集</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DivCenterWrapper>
              <UploadImagesContainer>
                <Picture
                  src={imgPreviewUrl || user?.picture || defaultUserImg}
                  alt="image"
                />
                {user.id === currentUser.id && (
                  <>
                    <UploadImgButton type="button" onClick={onClickFileInput}>
                      <CameraIcon />
                    </UploadImgButton>
                    <input
                      ref={inputFileRef}
                      hidden
                      multiple
                      type="file"
                      name="image"
                      readOnly={true}
                      accept="image/jpeg, image/png"
                      onChange={onInputChange}
                    />
                  </>
                )}
              </UploadImagesContainer>
            </DivCenterWrapper>
            <div>
              <EditModalFormLabel>お名前</EditModalFormLabel>
              <CheckTabs>
                <div>
                  <TextField
                    placeholder="姓"
                    {...register("baseInfo.lastName")}
                  />
                  {errors.baseInfo?.lastName && (
                    <ErrorMessage>
                      {errors.baseInfo?.lastName.message}
                    </ErrorMessage>
                  )}
                </div>
                <div>
                  <TextField
                    placeholder="名"
                    {...register("baseInfo.firstName")}
                  />
                  {errors.baseInfo?.firstName && (
                    <ErrorMessage>
                      {errors.baseInfo?.firstName.message}
                    </ErrorMessage>
                  )}
                </div>
              </CheckTabs>
            </div>
            <div>
              <EditModalFormLabel>ふりがな</EditModalFormLabel>
              <CheckTabs>
                <div>
                  <TextField
                    placeholder="せい"
                    {...register("baseInfo.lastNameKana")}
                  />
                  {errors.baseInfo?.lastNameKana && (
                    <ErrorMessage>
                      {errors.baseInfo?.lastNameKana.message}
                    </ErrorMessage>
                  )}
                </div>
                <div>
                  <TextField
                    placeholder="めい"
                    {...register("baseInfo.firstNameKana")}
                  />
                  {errors.baseInfo?.firstNameKana && (
                    <ErrorMessage>
                      {errors.baseInfo?.firstNameKana.message}
                    </ErrorMessage>
                  )}
                </div>
              </CheckTabs>
            </div>
            {organization &&
              organization.belongs.length !== 0 &&
              (user.type === "PARENT" || user.type === "TEACHER") && (
                <div>
                  <EditModalFormLabel>所属</EditModalFormLabel>
                  <CheckTabs>
                    <ButtonGroupExtend>
                      {sortedBelongs.map((belong) => {
                        return (
                          <Button
                            key={belong.id}
                            size="large"
                            fill
                            color={
                              watch("baseInfo.belongId") !== belong.id
                                ? "ghost"
                                : "primary"
                            }
                            className="button"
                            onClick={() => selectBelong(belong.id)}
                            type="button"
                          >
                            {belong.name}
                            {user.baseInfo?.belongId === belong.id && (
                              <CheckIcon size={8} />
                            )}
                          </Button>
                        );
                      })}
                    </ButtonGroupExtend>
                  </CheckTabs>
                </div>
              )}
            {organization &&
              organization.parts.length !== 0 &&
              (user.type === "PARENT" || user.type === "TEACHER") && (
                <div>
                  <EditModalFormLabel>役職</EditModalFormLabel>
                  <CheckTabs>
                    <ButtonGroupExtend>
                      {sortedParts.map((part) => {
                        return (
                          <Button
                            key={part.id}
                            size="large"
                            fill
                            color={
                              watch("baseInfo.partId") !== part.id
                                ? "ghost"
                                : "primary"
                            }
                            className="button"
                            onClick={() => selectPart(part.id)}
                            type="button"
                          >
                            {part.name}
                            {user.baseInfo?.partId === part.id && (
                              <CheckIcon size={8} />
                            )}
                          </Button>
                        );
                      })}
                    </ButtonGroupExtend>
                  </CheckTabs>
                </div>
              )}
            {cu.role !== Role.PTAMember && (
              <div>
                <EditModalFormLabel>電話番号</EditModalFormLabel>
                <div>
                  <TextField
                    {...register("baseInfo.phoneNumber")}
                    placeholder="例)  09012345678"
                  />
                  {errors.baseInfo?.phoneNumber && (
                    <ErrorMessage>
                      {errors.baseInfo.phoneNumber.message}
                    </ErrorMessage>
                  )}
                </div>
              </div>
            )}
            {user.type === "PARENT" && (
              <div>
                <EditModalFormLabel>登校班</EditModalFormLabel>
                <div>
                  <TextField
                    {...register("baseInfo.schoolGroup")}
                    placeholder="例)  13"
                  />
                  {errors.baseInfo?.schoolGroup && (
                    <ErrorMessage>
                      {errors.baseInfo.schoolGroup.message}
                    </ErrorMessage>
                  )}
                </div>
              </div>
            )}
            {(user.type === "PARENT" || user.type === "TEACHER") && (
              <div>
                <EditModalFormLabel>自由記述</EditModalFormLabel>
                <div>
                  <TextAreaField {...register("baseInfo.selfFreeMemo")} />
                  {errors.baseInfo?.selfFreeMemo && (
                    <ErrorMessage>
                      {errors.baseInfo.selfFreeMemo.message}
                    </ErrorMessage>
                  )}
                </div>
              </div>
            )}
            {isPtaAdminRole(cu.role) &&
              (user.type === "PARENT" || user.type === "TEACHER") && (
                <div>
                  <EditModalFormLabel>
                    自由記述（役員管理用）
                  </EditModalFormLabel>
                  <div>
                    <BlueTextAreaField
                      {...register("baseInfo.adminFreeMemo")}
                    />
                    {errors.baseInfo?.adminFreeMemo && (
                      <ErrorMessage>
                        {errors.baseInfo.adminFreeMemo.message}
                      </ErrorMessage>
                    )}
                  </div>
                </div>
              )}
            <Margin marginBottom={20} />
            {user.type === "PARENT" && (
              <>
                {fields.map((field, index) => (
                  <>
                    <EditModalFormLabel>
                      お子さまのお名前({index + 1}人目)
                    </EditModalFormLabel>
                    <CheckTabs>
                      <div>
                        <TextField
                          placeholder="例) 山田"
                          {...register(`children.${index}.childLastName`)}
                        />
                        {errors?.children &&
                          errors.children[index]?.childLastName && (
                            <ErrorMessage>
                              {errors.children[index]?.childLastName?.message}
                            </ErrorMessage>
                          )}
                      </div>
                      <div>
                        <TextField
                          placeholder="例) 太郎"
                          {...register(`children.${index}.childFirstName`)}
                        />
                        {errors?.children &&
                          errors.children[index]?.childFirstName && (
                            <ErrorMessage>
                              {errors.children[index]?.childFirstName?.message}
                            </ErrorMessage>
                          )}
                      </div>
                    </CheckTabs>
                    <EditModalFormLabel>ふりがな</EditModalFormLabel>
                    <CheckTabs>
                      <div>
                        <TextField
                          placeholder="例) やまだ"
                          {...register(`children.${index}.childLastNameKana`)}
                        />
                        {errors?.children &&
                          errors.children[index]?.childLastNameKana && (
                            <ErrorMessage>
                              {
                                errors.children[index]?.childLastNameKana
                                  ?.message
                              }
                            </ErrorMessage>
                          )}
                      </div>
                      <div>
                        <TextField
                          placeholder="例) たろう"
                          {...register(`children.${index}.childFirstNameKana`)}
                        />
                        {errors?.children &&
                          errors.children[index]?.childFirstNameKana && (
                            <ErrorMessage>
                              {
                                errors.children[index]?.childFirstNameKana
                                  ?.message
                              }
                            </ErrorMessage>
                          )}
                      </div>
                    </CheckTabs>
                    <CheckTabs>
                      <div>
                        <EditModalFormLabel>学年</EditModalFormLabel>
                        <SelectField
                          {...register(`children.${index}.grade`)}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            選択してください
                          </option>
                          {gradeOption.map((opt) => {
                            return (
                              <option key={opt.value} value={opt.value}>
                                {opt.text}
                              </option>
                            );
                          })}
                        </SelectField>
                        {errors?.children && errors.children[index]?.grade && (
                          <ErrorMessage>
                            {errors.children[index]?.grade?.message}
                          </ErrorMessage>
                        )}
                      </div>
                      <div>
                        <EditModalFormLabel>クラス</EditModalFormLabel>
                        <SelectField
                          {...register(`children.${index}.class`)}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            選択してください
                          </option>
                          {classOption.map((opt) => {
                            return (
                              <option key={opt.value} value={opt.value}>
                                {opt.text}
                              </option>
                            );
                          })}
                        </SelectField>
                        {errors?.children && errors.children[index]?.class && (
                          <ErrorMessage>
                            {errors.children[index]?.class?.message}
                          </ErrorMessage>
                        )}
                      </div>
                    </CheckTabs>
                    {fields.length > 1 && (
                      <TrashButton
                        style={{ marginTop: "12px", marginRight: "5px" }}
                        type="button"
                        onClick={() => remove(index)}
                      >
                        <TrashIcon />
                      </TrashButton>
                    )}
                  </>
                ))}
                <Margin marginTop={20} />
                <AddChildForm
                  type="button"
                  onClick={() => {
                    append({
                      childLastName: "",
                      childFirstName: "",
                      childLastNameKana: "",
                      childFirstNameKana: "",
                      grade: undefined,
                      class: undefined,
                    });
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "5px" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 7H11.5C11.7761 7 12 7.22386 12 7.5C12 7.77614 11.7761 8 11.5 8H8V11.5C8 11.7761 7.77614 12 7.5 12C7.22386 12 7 11.7761 7 11.5V8H3.5C3.22386 8 3 7.77614 3 7.5C3 7.22386 3.22386 7 3.5 7H7V3.5C7 3.22386 7.22386 3 7.5 3C7.77614 3 8 3.22386 8 3.5V7ZM7.5 0C11.6356 0 15 3.36441 15 7.5C15 11.6356 11.6356 15 7.5 15C3.36441 15 0 11.6356 0 7.5C0 3.36441 3.36441 0 7.5 0ZM7.5 0.882353C3.84529 0.882353 0.882353 3.84529 0.882353 7.5C0.882353 11.1547 3.84529 14.1176 7.5 14.1176C11.1547 14.1176 14.1176 11.1547 14.1176 7.5C14.1176 3.84529 11.1547 0.882353 7.5 0.882353Z"
                      fill="#5A606B"
                    />
                  </svg>
                  お子さまを追加
                </AddChildForm>{" "}
              </>
            )}
            {user.type === "TEACHER" && (
              <>
                <div>
                  <EditModalFormLabel>担任</EditModalFormLabel>
                  <SelectField
                    {...register("teacherInfo.hasHomeroom")}
                    defaultChecked={hasHomeroomInput}
                  >
                    {isTeacherOption.map((opt) => {
                      return (
                        <option key={opt.value} value={opt.value}>
                          {opt.text}
                        </option>
                      );
                    })}
                  </SelectField>
                </div>
                {String(hasHomeroomInput) === "true" && (
                  <CheckTabs>
                    <div>
                      <EditModalFormLabel>学年</EditModalFormLabel>
                      <SelectField
                        {...register(`teacherInfo.grade` as const, {
                          required: "入力必須項目です",
                          setValueAs: (v) =>
                            v === "" ? undefined : parseInt(v, 10),
                        })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          選択してください
                        </option>
                        {gradeOption.map((opt) => {
                          return (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          );
                        })}
                      </SelectField>
                      {errors?.teacherInfo && (
                        <ErrorMessage>
                          {errors.teacherInfo.grade?.message}
                        </ErrorMessage>
                      )}
                    </div>
                    <div>
                      <EditModalFormLabel>クラス</EditModalFormLabel>
                      <SelectField
                        {...register(`teacherInfo.class` as const, {
                          required: "入力必須項目です",
                          setValueAs: (v) =>
                            v === "" ? undefined : parseInt(v, 10),
                        })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          選択してください
                        </option>
                        {classOption.map((opt) => {
                          return (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          );
                        })}
                      </SelectField>
                      {errors?.teacherInfo && (
                        <ErrorMessage>
                          {errors.teacherInfo.class?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </CheckTabs>
                )}
              </>
            )}
            {user.type === "RESIDENT" && (
              <>
                <div>
                  <EditModalFormLabel>性別</EditModalFormLabel>

                  <ButtonGroupExtend>
                    {genderOption.map((option, i) => (
                      <ButtonSelect
                        key={i}
                        selected={watch("baseInfo.gender") === option.value}
                        onClick={() => handleGenderChange(option.value)}
                      >
                        <span>{option.text}</span>
                      </ButtonSelect>
                    ))}
                  </ButtonGroupExtend>
                  {errors?.baseInfo && errors.baseInfo.gender && (
                    <ErrorMessage>
                      {errors.baseInfo.gender.message}
                    </ErrorMessage>
                  )}
                </div>
                <div>
                  <EditModalFormLabel>年代</EditModalFormLabel>
                  <SelectField
                    {...register(`baseInfo.ageGroup` as const, {
                      required: "入力必須項目です",
                      setValueAs: (v) => {
                        try {
                          return zTransformAgeGroup.parse(v);
                        } catch (error) {
                          return undefined;
                        }
                      },
                    })}
                    defaultValue={-1}
                  >
                    <option value="-1" disabled>
                      年代を選択
                    </option>
                    {ageGroup.map((opt) => {
                      return (
                        <option key={opt.value} value={opt.value}>
                          {opt.text}
                        </option>
                      );
                    })}
                  </SelectField>
                  {errors.baseInfo?.ageGroup && (
                    <ErrorMessage>
                      {errors.baseInfo?.ageGroup.message}
                    </ErrorMessage>
                  )}
                </div>
                <div>
                  <EditModalFormLabel>生年月日</EditModalFormLabel>
                  <TextField
                    min="1900-01-01"
                    max="2100-12-31"
                    type="date"
                    {...register(`baseInfo.birthday` as const, {
                      validate: (value) => {
                        if (!value) return true;

                        return value && new Date(value) <= new Date()
                          ? true
                          : "未来の日付は設定できません";
                      },
                    })}
                    // style={{ appearance: "none" }}
                  ></TextField>
                  {errors?.baseInfo && errors.baseInfo.birthday && (
                    <ErrorMessage>
                      {errors.baseInfo.birthday.message}
                    </ErrorMessage>
                  )}
                </div>
                <div>
                  <EditModalFormLabel>郵便番号</EditModalFormLabel>
                  <TextField
                    {...register(`baseInfo.postalCode` as const, {
                      required: "入力必須項目です",
                      // validate: (value) =>
                      //   value ? validatePostalCode(value) : false,
                    })}
                    placeholder="例）1030013"
                    onChange={(e) => getAddress(e.target.value)}
                  ></TextField>
                  {errors?.baseInfo && errors.baseInfo.postalCode && (
                    <ErrorMessage>
                      ハイフンは入力せず、正しい郵便番号を入力してください
                    </ErrorMessage>
                  )}
                </div>
                <div>
                  <EditModalFormLabel>住所</EditModalFormLabel>
                  <CheckTabs>
                    <div>
                      <SelectField
                        {...register(`baseInfo.prefecture`)}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          県名を選択
                        </option>
                        {prefectureOptions.map((opt) => {
                          return (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          );
                        })}
                      </SelectField>
                      {errors.baseInfo?.prefecture && (
                        <ErrorMessage>
                          {errors.baseInfo?.prefecture.message}
                        </ErrorMessage>
                      )}
                    </div>
                    <div>
                      <TextField
                        {...register("baseInfo.city")}
                        placeholder="例）川崎市川崎区"
                      />
                      {errors.baseInfo?.city && (
                        <ErrorMessage>
                          {errors.baseInfo?.city.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </CheckTabs>
                  <Margin marginTop={8} />
                  <TextField
                    {...register(`baseInfo.address1`)}
                    placeholder="例）川崎市川崎区"
                  ></TextField>
                  {errors?.baseInfo && errors.baseInfo.address1 && (
                    <ErrorMessage>
                      {errors.baseInfo.address1.message}
                    </ErrorMessage>
                  )}
                  <Margin marginTop={8} />
                  <TextField
                    {...register(`baseInfo.address2`)}
                    placeholder="例）〇〇マンション101号室"
                  ></TextField>
                  {errors?.baseInfo && errors.baseInfo.address2 && (
                    <ErrorMessage>
                      {errors.baseInfo.address2.message}
                    </ErrorMessage>
                  )}
                </div>
                <div>
                  <EditModalFormLabel>希望活動曜日</EditModalFormLabel>
                  <ButtonGroupExtend>
                    {dayOfWeekOptions.map((option, i) => (
                      <ButtonSelect
                        key={i}
                        selected={watch("residentInfo.preferredDays").includes(
                          option.value
                        )}
                        onClick={() => handlePreferredDaysChange(option.value)}
                      >
                        <span>{option.text}</span>
                      </ButtonSelect>
                    ))}
                  </ButtonGroupExtend>
                  {errors?.residentInfo?.preferredDays != null ? (
                    <span className="error-message">
                      {/* @ts-ignore */}
                      {errors?.residentInfo.preferredDays?.map(
                        // @ts-ignore
                        (e) => e.message
                      )}
                    </span>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </div>
                <div>
                  <EditModalFormLabel>教員免許状</EditModalFormLabel>
                  <ButtonGroupExtend>
                    {teachingLicenseOptions.map((option, i) => (
                      <ButtonSelect
                        key={i}
                        selected={watch(
                          "residentInfo.teacherLicenses"
                        ).includes(option.value)}
                        onClick={() => handleTeacherChange(option.value)}
                      >
                        <span>{option.text}</span>
                      </ButtonSelect>
                    ))}
                  </ButtonGroupExtend>
                  {errors?.residentInfo?.teacherLicenses != null ? (
                    <span className="error-message">
                      {/* @ts-ignore */}
                      {errors?.residentInfo.teacherLicenses?.map(
                        // @ts-ignore
                        (e) => e.message
                      )}
                    </span>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </div>
                <div>
                  <EditModalFormLabel>医療・看護</EditModalFormLabel>
                  <ButtonGroupExtend>
                    {medicalLicenseOptions.map((option, i) => (
                      <ButtonSelect
                        key={i}
                        selected={watch(
                          "residentInfo.medicalLicenses"
                        ).includes(option.value)}
                        onClick={() => handleMedicalChange(option.value)}
                      >
                        <span>{option.text}</span>
                      </ButtonSelect>
                    ))}
                  </ButtonGroupExtend>
                  {errors?.residentInfo?.medicalLicenses != null ? (
                    <span className="error-message">
                      {/* @ts-ignore */}
                      {errors?.residentInfo.medicalLicenses?.map(
                        // @ts-ignore
                        (e) => e.message
                      )}
                    </span>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </div>
                <div>
                  <EditModalFormLabel>スキル</EditModalFormLabel>
                  <ButtonGroupExtend>
                    {skillOptions.map((option, i) => (
                      <ButtonSelect
                        key={i}
                        selected={watch("residentInfo.skills").includes(
                          option.value
                        )}
                        onClick={() => handleSkillsChange(option.value)}
                      >
                        <span>{option.text}</span>
                      </ButtonSelect>
                    ))}
                  </ButtonGroupExtend>
                  {errors?.residentInfo?.skills != null ? (
                    <span className="error-message">
                      {/* @ts-ignore */}
                      {errors?.residentInfo.skills?.map((e) => e.message)}
                    </span>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </div>
                <div>
                  <EditModalFormLabel>有償無償選択</EditModalFormLabel>
                  <ButtonGroupExtend>
                    {paidOption.map((option, i) => (
                      <ButtonSelect
                        key={i}
                        selected={
                          watch("residentInfo.preferredPaid") === option.value
                        }
                        onClick={() => handlePreferredPaidChange(option.value)}
                      >
                        <span>{option.text}</span>
                      </ButtonSelect>
                    ))}
                  </ButtonGroupExtend>
                  {errors?.residentInfo?.preferredPaid != null ? (
                    <span className="error-message">
                      {errors?.residentInfo.preferredPaid?.message}
                    </span>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </div>
                <div>
                  <EditModalFormLabel>
                    ボランティアの項目選択
                  </EditModalFormLabel>
                  <ButtonGroupExtend>
                    {volunteerOption.map((option, i) => (
                      <ButtonSelect
                        key={i}
                        selected={watch(
                          "residentInfo.preferredVolunteerType"
                        ).includes(option.value)}
                        onClick={() => handleVolunteerChange(option.value)}
                      >
                        <span>{option.text}</span>
                      </ButtonSelect>
                    ))}
                  </ButtonGroupExtend>
                  {/* [imo] ↓medicalLicensesでいいのか？ */}
                  {errors?.residentInfo?.medicalLicenses != null ? (
                    <span className="error-message">
                      {/* @ts-ignore */}
                      {errors?.residentInfo.medicalLicenses?.map(
                        // @ts-ignore
                        (e) => e.message
                      )}
                    </span>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </div>
                <div>
                  <EditModalFormLabel>民間企業</EditModalFormLabel>
                  <TextField
                    {...register(`residentInfo.privateEnterprise`)}
                    placeholder="〇〇株式会社"
                  ></TextField>
                  {errors?.residentInfo &&
                    errors.residentInfo?.privateEnterprise && (
                      <ErrorMessage>
                        ハイフンは入力せず、正しい郵便番号を入力してください
                      </ErrorMessage>
                    )}
                </div>
                <div>
                  <EditModalFormLabel>大学</EditModalFormLabel>
                  <TextField
                    {...register(`residentInfo.university`)}
                    placeholder="〇〇大学"
                  ></TextField>
                  {errors?.residentInfo && errors.residentInfo.university && (
                    <ErrorMessage>
                      ハイフンは入力せず、正しい郵便番号を入力してください
                    </ErrorMessage>
                  )}
                </div>
                <div>
                  <EditModalFormLabel>外郭団体</EditModalFormLabel>
                  <TextField
                    {...register(`residentInfo.externalOrganization`)}
                    placeholder="〇〇団体"
                  ></TextField>
                  {errors?.residentInfo &&
                    errors.residentInfo?.externalOrganization && (
                      <ErrorMessage>
                        ハイフンは入力せず、正しい郵便番号を入力してください
                      </ErrorMessage>
                    )}
                </div>
                <div>
                  <EditModalFormLabel>PTA</EditModalFormLabel>
                  <TextField
                    {...register(`residentInfo.pta`)}
                    placeholder="〇〇PTA"
                  ></TextField>
                  {errors?.residentInfo && errors.residentInfo.pta && (
                    <ErrorMessage>
                      ハイフンは入力せず、正しい郵便番号を入力してください
                    </ErrorMessage>
                  )}
                </div>
                <EditBaseInfoCommunity
                  user={user}
                  control={control}
                  errors={errors}
                />
              </>
            )}
            <Margin marginTop={25} />
            <ModalSubmitButtons
              disabled={Object.keys(errors).length > 0 || doubleClickBlocked}
              submitText="保存"
              onCancel={onClose}
            />
          </form>
        </ModalBody>
      </ModalWrapper>
    </ModalPortal>
  );
};

const ButtonGroupExtend = styled(ButtonGroup)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .button {
    width: auto;
    /* @media (max-width: 1279px) {
        width: auto;
      } */
  }
  svg {
    margin-left: 12px;
  }
`;

const CheckTabs = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  > * {
    width: 100%;
  }
`;

const ModalWrapper = styled.div`
  overflow-y: scroll;
  max-height: 564px;
  padding: 20px;
`;

const ModalHeader = styled(Heading3)`
  padding-bottom: 24px;
  display: flex;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;

const EditModalFormLabel = styled.label`
  color: #1a1c21;
  font-weight: 700;
  font-size: 12px;
  margin-top: 14px;
  margin-bottom: 4px;
  display: inline-block;
`;

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const TextAreaField = styled.textarea`
  width: 100%;
  height: 96px;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const BlueTextAreaField = styled.textarea`
  width: 100%;
  height: 96px;
  padding: 10px 12px;
  background: #e5f4ff;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const SelectField = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
`;

const UploadImagesContainer = styled.div`
  position: relative;
`;

const UploadImgButton = styled.button`
  background-color: gray;
  border-radius: 50%;
  border: none;
  position: absolute;
  bottom: 0;
  left: 53%;
  height: 24px;
  width: 24px;
`;

const DivCenterWrapper = styled.div`
  text-align: center;
`;

const Picture = styled.img`
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 50%;
`;
