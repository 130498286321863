import React from "react";
import { Margin } from "../../../../components/Margin";
import styled from "styled-components";
import {
  ModalPortal,
  ModalHeader,
  ModalSubmitButtons,
} from "../../../../components/Modal";

interface Props {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
  targetUserName: string;
}

export const DeleteChatRoomModal = (props: Props) => {
  if (!props.isOpen) return null;

  return (
    <ModalPortal onClose={props.onClose}>
      <ModalHeader>
        <p>リクエストを削除しますか</p>
      </ModalHeader>
      <ModalBody>
        <p>{`${props.targetUserName}さんからのメッセージリクエストを削除します。
          削除すると元に戻りませんがよろしいですか？。`}</p>
        <Margin marginBottom={10} />
      </ModalBody>
      <ModalSubmitButtons
        submitText="削除"
        submitColor="danger"
        onSubmit={props.onSubmit}
        onCancel={props.onClose}
        cancelColor="subPrimary"
      />
    </ModalPortal>
  );
};

const ModalBody = styled.div`
  min-height: 50px;
  height: fit-content;
`;
