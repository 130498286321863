import React from "react";

type Props = {
  size?: number;
};
export const DangerIcon = ({ size }: Props) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.59 22.0586L19.3504 17.1814H20.6504L20.4006 22.0586H19.59ZM19.9847 23.96C19.8104 23.96 19.6612 23.8979 19.5369 23.7736C19.4126 23.6493 19.3504 23.5014 19.3504 23.33C19.3504 23.1557 19.4126 23.0071 19.5369 22.8843C19.6612 22.7614 19.8104 22.7 19.9847 22.7C20.1533 22.7 20.2997 22.7607 20.424 22.8821C20.5483 23.0036 20.6104 23.1529 20.6104 23.33C20.6104 23.5043 20.5476 23.6529 20.4219 23.7757C20.2962 23.8986 20.1504 23.96 19.9847 23.96ZM14.0004 26C13.2414 26 12.7591 25.1875 13.1225 24.5211L19.1225 13.5211C19.5016 12.8263 20.4993 12.8263 20.8783 13.5211L26.8783 24.5211C27.2418 25.1875 26.7595 26 26.0004 26H14.0004ZM14.0004 25H26.0004L20.0004 14L14.0004 25Z"
        fill="#BD271E"
      />
    </svg>
  );
};
