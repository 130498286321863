import * as client from "./client";
import { ServiceType } from "../utils/types/serviceType";
import { Belong } from "./belong";
import { Part } from "./part";
import { Community } from "@shared/types/community";
import {
  CreateBEOrganizationArgs,
  CreateBeOrganizationResponse,
  InternalOrganization,
  OrganizationWithSignatureTemplates,
  UpdateOrganizationArg,
} from "@shared/types/organization";

export type Organization = {
  id: string;
  name: string;
  serviceType: ServiceType;
  nameListPublished: boolean;
  requireAdminToUpdateCareer: boolean;
  postSchoolYearsFilter: string[];
  belongs: Belong[];
  parts: Part[];
  communities: Community[];
  managingBoardEducation?: any;
  createdAt: string;
  updatedAt: string;
};

export type CreateOrganizationArg = {
  communityIds: string[];
  name: string;
  serviceType: ServiceType;
};
export async function createOrganization(
  args: CreateOrganizationArg
): Promise<Organization> {
  const res = await client.post<CreateOrganizationArg, Organization>(
    "/organization/create",
    args
  );
  return res;
}

export async function addCommunity(
  args: UpdateOrganizationArg
): Promise<Organization> {
  const res = await client.post<UpdateOrganizationArg, Organization>(
    "/organization/addCommunity",
    args
  );
  return res;
}

export async function updateOrganization(
  args: UpdateOrganizationArg
): Promise<Organization> {
  const res = await client.post<UpdateOrganizationArg, Organization>(
    "/organization/update",
    args
  );
  return res;
}

export async function currentOrganization(): Promise<OrganizationWithSignatureTemplates> {
  const res = await client.get<{}, OrganizationWithSignatureTemplates>(
    "/organization/currentOrganization",
    {}
  );
  return res;
}

export async function getOrganizations(): Promise<InternalOrganization[]> {
  const res = await client.get<{}, InternalOrganization[]>(
    "/organization/list",
    {}
  );
  return res;
}

export async function getOrganizationById(
  organizationId: string
): Promise<Organization> {
  const res = await client.get<{}, Organization>("/organization/get", {
    organizationId,
  });
  return res;
}

export async function getOrganizationByRecruitmentId(
  recruitmentId: string
): Promise<Organization> {
  const res = await client.get<{}, Organization>(
    "/organization/getByRecruitmentId",
    {
      recruitmentId,
    }
  );
  return res;
}

export async function getOrganizationByCommunityId(
  communityId: string
): Promise<Organization[]> {
  const res = await client.get<{}, Organization[]>(
    "/organization/listByCommunityId",
    {
      communityId,
    }
  );
  return res;
}

export async function createBEOrganization(
  args: CreateBEOrganizationArgs
): Promise<CreateBeOrganizationResponse> {
  const res = await client.post<
    CreateBEOrganizationArgs,
    CreateBeOrganizationResponse
  >("/organization/create/boardEducation", args);
  return res;
}
