import React from "react";
import styled from "styled-components";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useUserListByOrganizationId } from "src/hooks/query/internal/users/userList";
import { useOrganizationById } from "src/hooks/api/internal/organizations";
import { Margin } from "src/components/Margin";
import { toDateFormat } from "src/utils/time";
import { useModal } from "src/components/Modal";
import { CreateBoardEducationModal } from "./CreateBoardEducationUserModal";
import { useInvitationBEUserListByOrganizationId } from "src/hooks/query/internal/invitations/invitationBEUserList";

export function BoardEducationUserListPage() {
  const history = usePolyfitHistory();
  const loginUser = useCurrentUser();
  const [isOpen, { show, close }] = useModal();
  const { organizationId } = usePolyfitLocationQuery(
    "INTERNAL_ORGANIZATION_BOARD_EDUCATION_USERS",
    {
      organizationId: "",
    }
  );
  const { organization } = useOrganizationById(organizationId);
  const { users, isLoading } = useUserListByOrganizationId(organizationId);
  const { invitationBEUsers, isLoading: isLoadingBEInvitationUsers } =
    useInvitationBEUserListByOrganizationId(organizationId ?? "");

  if (loginUser?.internalRole !== "INTERNAL_ADMIN") {
    return <>アクセス権限がありません</>;
  }

  if (isLoading || isLoadingBEInvitationUsers) {
    return (
      <>
        <div>読み込み中…</div>
        <div>画面が切り替わらない場合はお問い合わせください</div>
      </>
    );
  }

  return (
    <>
      <h2>{organization?.name}</h2>
      <MenuContainer>
        <Link
          onClick={() => {
            history.push({
              to: "INTERNAL_ORGANIZATION_LIST",
            });
          }}
        >
          組織一覧へ戻る
        </Link>
        <Link
          onClick={() => {
            history.push({
              to: "INTERNAL_ORGANIZATION_EDIT",
              query: { organizationId: organizationId },
            });
          }}
          rel="noreferrer"
        >
          組織情報編集
        </Link>
        <Link
          onClick={() => {
            history.push({
              to: "INTERNAL_BOARD_EDUCATION_EDIT",
              query: { organizationId: organizationId },
            });
          }}
          rel="noreferrer"
        >
          住所情報編集
        </Link>
      </MenuContainer>
      <Margin marginTop={20} />
      <h3>ユーザー一覧</h3>
      <table>
        <tr>
          <td>ユーザーID</td>
          <td>メールアドレス</td>
          <td>名前</td>
          <td>role</td>
          <td>作成日時</td>
          <td>更新日時</td>
          <td>操作</td>
        </tr>
        {users.map((user, i) => {
          return (
            <tr
              key={i}
              style={i % 2 === 0 ? { background: "white" } : undefined}
            >
              <td>{user.id}</td>
              <td>{user.email}</td>
              <td>{user.name}</td>
              <td>{user.role}</td>
              <td>
                {user.createdAt && toDateFormat(new Date(user.createdAt))}
              </td>
              <td>
                {user.updatedAt && toDateFormat(new Date(user.updatedAt))}
              </td>
              <td>edit（TODO）</td>
            </tr>
          );
        })}
      </table>

      <Margin marginTop={20} />
      <button
        onClick={() => {
          show();
        }}
      >
        教育委員会ユーザーを招待
      </button>

      <Margin marginTop={28} />
      <h3>招待中一覧</h3>
      <table>
        <tr>
          <td>メールアドレス</td>
          <td>名前</td>
          <td>作成日時</td>
          <td>有効期限</td>
          <td>操作</td>
        </tr>
        {invitationBEUsers.map((user, i) => {
          return (
            <tr
              key={i}
              style={i % 2 === 0 ? { background: "white" } : undefined}
            >
              <td>{user.email}</td>
              <td>{user.baseInfo.lastName + user.baseInfo.firstName}</td>
              <td>{toDateFormat(new Date(user.createdAt))}</td>
              <td>{toDateFormat(new Date(user.expiredAt))}</td>
              <td>削除（TODO）</td>
            </tr>
          );
        })}
      </table>

      {organization && (
        <CreateBoardEducationModal
          isOpen={isOpen}
          onClose={close}
          organization={organization}
        />
      )}
    </>
  );
}

const MenuContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
`;

const Link = styled.a`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;
