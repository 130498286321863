import styled from "styled-components";

export const Panel = styled.div`
  cursor: "pointer";
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 0px 0.8px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 17px rgba(0, 0, 0, 0.03);
`;

export const PanelHeader = styled.div``;

export const PanelContent = styled.div``;
export const PanelMedia = styled.div``;
