import React from "react";

export const NextButtonIcon = () => {
  return (
    <>
      <svg
        width="7"
        height="14"
        viewBox="0 0 7 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.359816 0.231804C-0.0644612 0.585368 -0.121785 1.21593 0.231779 1.64021L4.69829 7.00003L0.231779 12.3598C-0.121785 12.7841 -0.0644612 13.4147 0.359816 13.7682C0.784092 14.1218 1.41466 14.0645 1.76822 13.6402L6.76822 7.64021C7.07726 7.26936 7.07726 6.73069 6.76822 6.35984L1.76822 0.359841C1.41466 -0.0644363 0.784092 -0.12176 0.359816 0.231804Z"
          fill="#37434F"
        />
      </svg>
    </>
  );
};
