import React from "react";
import styled from "styled-components";
import { ModalPortal, ModalHeader } from "../../../../components/Modal";
import { Margin } from "src/components/Margin";
import { useGetApprovedAccountList } from "src/hooks/query/boardEducation/recruitment/accountList";
import { AccountListTable } from "src/components/Common/AccountListTable";

interface Props {
  onClose: () => void;
  recruitmentId: string;
}

export const ApprovedCountModal = ({ onClose, recruitmentId }: Props) => {
  const { approvedAccountList } = useGetApprovedAccountList(recruitmentId);
  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>
        <p>参加確定者一覧</p>
      </ModalHeader>
      <ModalBody>
        <AccountListTable accountList={approvedAccountList} />
        <Margin marginBottom={10} />
      </ModalBody>
    </ModalPortal>
  );
};

const ModalBody = styled.div`
  min-height: 50px;
  height: fit-content;
`;
