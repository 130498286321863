import React, { memo } from "react";
import { Button } from "../../../components/Button";
import {
  isMultiChoiceQuestion,
  isOpenEndedQuestion,
} from "../../../apiClients/survey";
import { EventHandlers } from "./eventHandlers";
import { CheckIcon } from "../../../components/icons/CheckIcon";
import styled from "styled-components";
import { Question, QuestionType } from "@shared/types/post/survey/survey";

// ボタンを押された際に記述式質問に切り替える
function changeToOpenEndedQuestion(
  question: Question,
  eventHandlers: EventHandlers
): void {
  // 今が選択式の時のみ対象
  if (isMultiChoiceQuestion(question)) {
    // 記述式データに切り替わるので選択肢が失われる
    // 選択肢リストに何かしら記述がある場合は警告する
    const withUpdate: boolean = question.choiceList.some(
      (choice) => choice.text !== ""
    );
    if (withUpdate && !confirm("選択肢が破棄されますがよろしいですか？")) {
      return;
    }
    const newQuestionType: QuestionType = "OPEN_ENDED";
    eventHandlers.handleEditQuestionType(question, newQuestionType);
  }
}

// ボタンを押された際に選択式質問に切り替える
function changeToMultiChoiceQuestion(
  question: Question,
  eventHandlers: EventHandlers
): void {
  // 今が記述式の時のみ対象
  if (isOpenEndedQuestion(question)) {
    const newQuestionType: QuestionType = "MULTI_CHOICE";
    eventHandlers.handleEditQuestionType(question, newQuestionType);
  }
}

export const ToggleQuestionTypeField = memo(
  ({
    question,
    eventHandlers,
  }: {
    question: Question;
    eventHandlers: EventHandlers;
  }) => {
    return (
      <Container>
        {/* 選択式への変更 */}
        <StyledButton
          fill
          color={isMultiChoiceQuestion(question) ? "primary" : "ghost"}
          onClick={() => changeToMultiChoiceQuestion(question, eventHandlers)}
        >
          {isMultiChoiceQuestion(question) && <CheckIcon size={8} />}
          選択式
        </StyledButton>
        {/* 記述式への変更 */}
        <StyledButton
          fill
          color={isOpenEndedQuestion(question) ? "primary" : "ghost"}
          onClick={() => changeToOpenEndedQuestion(question, eventHandlers)}
        >
          {isOpenEndedQuestion(question) && <CheckIcon size={8} />}
          記述式
        </StyledButton>
      </Container>
    );
  }
);

const Container = styled.div`
  display: flex;
  gap: 4px;
`;

const StyledButton = styled(Button)`
  gap: 8px;
`;
