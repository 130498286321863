import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { NextButton } from "./NextButton";
import { PrevButton } from "./PrevButton";

type Props = {
  thisYear: number;
  setThisYear: Dispatch<SetStateAction<number>>;
  onChangeYear: (arg: string) => void;
};

export const CalenderUI = ({ thisYear, setThisYear, onChangeYear }: Props) => {
  return (
    <>
      <YearPickerContent>
        <YearPickerHeader>
          <YearRange>
            {thisYear - 7} - {thisYear}
          </YearRange>

          <YearListChangeButtons>
            <PrevButton onClick={() => setThisYear(thisYear - 8)} />
            <NextButton onClick={() => setThisYear(thisYear + 8)} />
          </YearListChangeButtons>
        </YearPickerHeader>
        <YearNumberList>
          {[7, 6, 5, 4, 3, 2, 1, 0].map((i: number) => {
            return (
              <Button
                key={i}
                type="button"
                onClick={() => onChangeYear(String(thisYear - i))}
              >
                {thisYear - i}
              </Button>
            );
          })}
        </YearNumberList>
      </YearPickerContent>
    </>
  );
};

const Button = styled.button`
  width: 50px;
  height: 40px;
  border: none;
  background: #fff;
  cursor: pointer;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #000000;
`;

const YearRange = styled.div`
  max-width: 109px;
  width: 100%;
  height: 27px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  color: #37434f;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: -6px;
  white-space: nowrap;
`;

const YearPickerHeader = styled.div`
  height: 16px;
  display: flex;
  justify-content: space-between;
`;

const YearNumberList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 16px;
  width: 100%;
`;

const YearPickerContent = styled.div`
  width: 100%;
  padding: 10px 12px 6px;
  background: #ffffff;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const YearListChangeButtons = styled.div`
  display: flex;
  gap: 34px;
`;
