import React, { useLayoutEffect } from "react";
import { Heading3 } from "../../../components/Typo";
import { Panel } from "../../../components/Panel";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { useDisabledUsersByOrganizationId } from "../../../hooks/api/users";
import { Link } from "react-router-dom";
import { usePolyfitUrl } from "../../../hooks/router";
import { Margin } from "../../../components/Margin";
import { Avatar } from "../../../components/Common/Avatar";
import { isPtaAdminRole } from "../../../utils/types/role";
import styled from "styled-components";

export const DisabledOrganizationPanel = () => {
  const user = useCurrentUser();
  const membersUrl = usePolyfitUrl({
    to: "PTA_DISABLED_MEMBERS",
  });
  const [{ users }, fetch] = useDisabledUsersByOrganizationId();
  useLayoutEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      {isPtaAdminRole(user.role) && users.length != 0 && (
        <>
          <Heading3>承認待ちメンバー</Heading3>
          <Margin marginTop={8} />
          <Panel>
            <Link to={membersUrl}>
              <Ul>
                {users.map((user) => (
                  <Li key={user.id}>
                    <FlexRow>
                      <Avatar src={user.picture!} size={48} alt={user.name} />
                      <FlexColumn>
                        <Name>{user.name}</Name>
                        <Email>{user.email}</Email>
                      </FlexColumn>
                    </FlexRow>
                  </Li>
                ))}
                <Li>
                  <UserListFooter>もっと見る</UserListFooter>
                </Li>
              </Ul>
            </Link>
          </Panel>
          <Margin marginBottom={30} />
        </>
      )}
    </>
  );
};

const Ul = styled.ul`
  list-style-type: none;
  li {
    border-bottom: 1px solid #e3e6eb;
  }
  li:last-child {
    border-bottom: none;
  }
`;

const Li = styled.li`
  list-style-type: none;
  padding: 8px 16px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.span`
  font-size: 16px;
  color: #343741;
`;

const Email = styled.span`
  font-size: 12px;
  color: #69707d;
`;

const UserListFooter = styled.div`
  text-align: end;
  color: #343741;
  font-size: 14px;
`;
