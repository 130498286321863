import { useState, useCallback, useEffect } from "react";
import * as authApi from "../../apiClients/auth";
import { ResidentInfo } from "../../apiClients/auth";

export const useResidentInfo = () => {
  const [currentResidentInfo, setCurrentResidentInfo] = useState<
    ResidentInfo | undefined | null
  >(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const getResidentInfo = useCallback(async () => {
    setIsLoading(true);
    const info = (await authApi.getResidentInfo()).data;
    setCurrentResidentInfo(info);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getResidentInfo();
  }, []);

  const updateResidentInfo = useCallback(
    async (data: Partial<ResidentInfo>) => {
      await authApi.updateResidentInfo(data);
    },
    []
  );

  return {
    isLoading,
    currentResidentInfo,
    updateResidentInfo,
    refetchResidentInfo: getResidentInfo,
  };
};
