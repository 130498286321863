import React, { useCallback, useState } from "react";
import { Margin } from "../../../components/Margin";
import styled from "styled-components";
import {
  ModalPortal,
  ModalHeader,
  ModalSubmitButtons,
} from "../../../components/Modal";
import { ModalLabel } from "../../Post/CreatePostModal";

interface ApplyModalProps {
  recruitmentTitle: string;
  onSubmit: (comment?: string) => void;
  onClose: () => void;
}

export default function ApplyRecruitmentModal({
  recruitmentTitle,
  onSubmit,
  onClose,
}: ApplyModalProps) {
  const [text, setText] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const onChangeText = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value.length <= 3000) {
        setText(e.target.value);
        setShowErrorMessage(false);
      } else {
        setShowErrorMessage(true);
      }
    },
    []
  );

  const handleSubmit = useCallback(() => {
    if (showErrorMessage) return;
    const comment = text.trim() === "" ? undefined : text;
    onSubmit(comment);
  }, [showErrorMessage, text, onSubmit]);

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>
        <p>応募する</p>
      </ModalHeader>
      <ModalBody>
        <p>
          「<strong>{recruitmentTitle}</strong>」の募集に応募しますか？
        </p>
        <Margin marginTop={15} />
        <ModalLabel>
          応募メッセージ <Optional>*任意</Optional>
        </ModalLabel>
        <Margin marginTop={5} />
        <TextArea
          name="comment"
          cols={30}
          rows={5}
          value={text}
          onChange={onChangeText}
          placeholder="是非参加したいです！よろしくお願い致します。"
        />
        {showErrorMessage && (
          <ErrorMessage>コメントは3000文字以内で入力してください</ErrorMessage>
        )}
      </ModalBody>
      <ModalSubmitButtons
        submitText="応募"
        onSubmit={handleSubmit}
        onCancel={onClose}
      />
    </ModalPortal>
  );
}

const ModalBody = styled.div`
  min-height: 50px;
  height: fit-content;
`;

const Optional = styled.span`
  color: #69707d;
  font-size: 10px;
`;

const TextArea = styled.textarea`
  width: 100%;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 8px;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;
