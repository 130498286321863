import styled from "styled-components";

export const ButtonSelect = styled.button.attrs(() => ({
  type: "button",
}))<{
  selected: boolean;
}>`
  background-color: ${(props) => (props.selected ? "#e6f5ff" : "#fbfcfd")};
  line-height: 21px;
  padding: 7px 22px;
  border-radius: 4px;
  color: #000;

  border: 1px solid
    ${(props) => (props.selected && !props.disabled ? "#0077CC" : "#1322951A")};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

export const ButtonGroupExtend = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  @media (max-width: 1279px) {
    gap: 8px;
  }
  .button {
    width: auto;
    /* @media (max-width: 1279px) {
        width: auto;
      } */
  }
`;
