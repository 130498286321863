import React from "react";

export const NoteIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8333 2H10.35C10 0.985 9.08333 0.25 8 0.25C6.91667 0.25 6 0.985 5.65 2H2.16667C1.25 2 0.5 2.7875 0.5 3.75V16C0.5 16.9625 1.25 17.75 2.16667 17.75H13.8333C14.75 17.75 15.5 16.9625 15.5 16V3.75C15.5 2.7875 14.75 2 13.8333 2ZM8 1.78125C8.34167 1.78125 8.625 2.07875 8.625 2.4375C8.625 2.79625 8.34167 3.09375 8 3.09375C7.65833 3.09375 7.375 2.79625 7.375 2.4375C7.375 2.07875 7.65833 1.78125 8 1.78125ZM13.8333 16H2.16667V3.75H13.8333V16Z"
        fill="#005EC4"
      />
      <path
        d="M10.5667 9.02625L8.8 7.17125L3.83333 12.3775V14.25H5.58333L10.5667 9.02625Z"
        fill="#005EC4"
      />
      <path
        d="M12.0417 7.48625C12.2083 7.31125 12.2083 7.04 12.0417 6.865L10.8667 5.63125C10.7 5.45625 10.4417 5.45625 10.275 5.63125L9.39167 6.55875L11.1583 8.41375L12.0417 7.48625Z"
        fill="#005EC4"
      />
    </svg>
  );
};
