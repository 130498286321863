export const paidOption = [
  { value: "EITHER", text: "どちらでも" },
  { value: "PAID", text: "有償" },
  { value: "FREE", text: "無償" },
] as const;

export type PaidType = (typeof paidOption)[number]["value"];

const volunteerRelation = [
  {
    volunteerType: "学習活動への支援",
    volunteerDetailType: "実技支援",
    title: "実技支援スタッフ募集",
    description:
      "主な仕事内容は、生徒の実技学習（ミシン、書道など）をサポート。不明な点があれば、スタッフに気軽に質問してくださいね！",
  },
  {
    volunteerType: "学習活動への支援",
    volunteerDetailType: "補習支援",
    title: "補習支援スタッフ募集",
    description:
      "放課後や休業中の補習で生徒をサポートするのが主なお仕事。一緒に学生の成長をサポートしましょう♪",
  },
  {
    volunteerType: "学習活動への支援",
    volunteerDetailType: "放課後子供学習の支援",
    title: "放課後子供学習サポーター募集",
    description:
      "放課後子ども教室では、参加する子どもたちの受付業務、安全確保のための見守り、遊具の準備と片付け、さらには万が一のケガに対する応急処置などを行います。一緒に子供と遊びましょう！",
  },
  {
    volunteerType: "学習活動への支援",
    volunteerDetailType: "体験活動支援",
    title: "体験活動サポーター募集",
    description:
      "学校の体験活動での支援が主な業務。分からないことがあれば、気軽に質問してください！",
  },
  {
    volunteerType: "安心・安全確保への支援",
    volunteerDetailType: "通学路の見守り",
    title: "通学路見守りスタッフ募集",
    description:
      "登下校時の生徒の安全を守る重要な役割。安全な通学路を確保しましょう！",
  },
  {
    volunteerType: "安心・安全確保への支援",
    volunteerDetailType: "朝の挨拶運動、巡回",
    title: "朝の挨拶運動スタッフ募集",
    description:
      "朝の挨拶運動と校内外の巡回を通じて、学校の日常を支えます。元気な挨拶で一日をスタートしましょう！",
  },
  {
    volunteerType: "安心・安全確保への支援",
    volunteerDetailType: "防災訓練への協力",
    title: "防災訓練協力スタッフ募集",
    description:
      "災害発生時の対応訓練への参加と支援がメイン。安全な学校環境を一緒に作りましょう！",
  },
  {
    volunteerType: "学校の環境設備への支援",
    volunteerDetailType: "花壇の整備、除草",
    title: "花壇整備スタッフ募集",
    description:
      "学校の花壇の手入れと維持が主な業務。美しい校庭で学生たちを迎えましょう！",
  },
  {
    volunteerType: "学校の環境設備への支援",
    volunteerDetailType: "施設設備の修繕",
    title: "施設設備修繕スタッフ募集",
    description:
      "学校の施設や設備の保守・修理を行います。安全で快適な学習環境のために一緒に頑張りましょう！",
  },
  {
    volunteerType: "学校の環境設備への支援",
    volunteerDetailType: "図書整理",
    title: "図書整理スタッフ募集",
    description:
      "図書館の本の整理と管理がメイン。知識の宝庫を整え、生徒の探究心をサポートしましょう！",
  },
  {
    volunteerType: "部活動・クラブ活動への支援",
    volunteerDetailType: "実技指導",
    title: "実技指導スタッフ募集",
    description:
      "特定のスポーツや芸術などの実技指導を行います。生徒の才能を引き出し、成長をサポート！",
  },
  {
    volunteerType: "部活動・クラブ活動への支援",
    volunteerDetailType: "大会引率支援",
    title: "大会引率スタッフ募集",
    description:
      "学校代表としての大会への引率と支援が主な業務。一緒にチームを応援しましょう！",
  },
  {
    volunteerType: "学校の事務作業への支援",
    volunteerDetailType: "事務作業支援",
    title: "事務作業支援スタッフ募集",
    description:
      "学校の日々の事務作業をサポート。スムーズな学校運営のために、あなたの手を貸してください！",
  },
  {
    volunteerType: "学校の事務作業への支援",
    volunteerDetailType: "文書作成・管理",
    title: "文書作成・管理スタッフ募集",
    description:
      "文書の作成と管理が主な仕事。効率的な文書処理で、学校運営をサポートしましょう！",
  },
  {
    volunteerType: "学校の事務作業への支援",
    volunteerDetailType: "印刷・封入",
    title: "印刷・封入スタッフ募集",
    description:
      "書類の印刷と封入作業がメイン。正確で迅速な作業で、学校のコミュニケーションをサポート！",
  },
  {
    volunteerType: "地域コーディネーターへの支援",
    volunteerDetailType: "行事準備",
    title: "行事準備スタッフ募集",
    description:
      "学校や地域の行事の準備を担います。楽しいイベント作りに一緒に取り組みましょう！",
  },
  {
    volunteerType: "地域コーディネーターへの支援",
    volunteerDetailType: "地域の行事",
    title: "地域行事サポートスタッフ募集",
    description:
      "地域コミュニティの行事への参加と支援がメイン。地域との連携を深め、学校の顔として活躍しましょう！",
  },
  {
    volunteerType: "地域コーディネーターへの支援",
    volunteerDetailType: "社会奉仕活動",
    title: "社会奉仕活動スタッフ募集",
    description:
      "地域社会への奉仕活動を通じて、社会貢献に取り組みます。一緒に地域に貢献しましょう！",
  },
  {
    volunteerType: "学校行事への支援",
    volunteerDetailType: "受付業務",
    title: "受付業務サポートスタッフ募集",
    description:
      "来校者の方への温かい出迎えと案内が主な仕事です。あなたの明るい笑顔で、学校の顔としてお客様をお迎えしましょう!",
  },
  {
    volunteerType: "学校行事への支援",
    volunteerDetailType: "校外学習支援",
    title: "校外学習サポートスタッフ募集",
    description:
      "校外学習の際に生徒の皆さんの安全を守るため、同行して見守りを行います。新しい体験を通して、子供たちの成長を一緒に喜びましょう!",
  },
  {
    volunteerType: "学校行事への支援",
    volunteerDetailType: "行事の準備",
    title: "行事準備サポートスタッフ募集",
    description:
      "学校やPTAが主催するイベントの準備を一緒に手伝っていただきます。楽しい思い出作りに貢献して、笑顔あふれる学校を作りましょう!",
  },
  {
    volunteerType: "地域イベントへの参加",
    volunteerDetailType: "学校主催イベント",
    title: "学校主催イベント",
    description:
      "学校主催のイベントです。イベントに積極的に参加を表明し、イベントを盛り上げましよう。",
  },
  {
    volunteerType: "地域イベントへの参加",
    volunteerDetailType: "学校外主催イベント",
    title: "学校外主催イベント",
    description:
      "学校外主催のイベントです。いつもとは異なるメンバーと協働を続けましょう。",
  },
];

export const getTitleAndDescription = (
  volunteerType: VolunteerType,
  volunteerDetailType: string
) => {
  const relation = volunteerRelation.find(
    (o) =>
      o.volunteerType === volunteerType &&
      o.volunteerDetailType === volunteerDetailType
  );
  if (!relation) return { title: "", description: "" };
  return { title: relation.title, description: relation.description };
};

export const volunteerOption = [
  {
    value: "学習活動への支援",
    text: "学習活動への支援",
    example: [
      "ミシン、書道などの実技支援",
      "放課後、長期休業中の補習支援",
      "体験活動(学校ファームを含む)への支援 等",
    ],
  },
  {
    value: "安心・安全確保への支援",
    text: "安心・安全確保への支援",
    example: [
      "登下校時における通学路の見守り",
      "朝の挨拶運動、校内外の巡回",
      "防災訓練への協力 等",
    ],
  },
  {
    value: "学校の環境設備への支援",
    text: "学校の環境設備への支援",
    example: [
      "花壇の整備、除草",
      "学校の施設設備の修繕",
      "学校図書館の図書整理 等",
    ],
  },
  {
    value: "部活動・クラブ活動への支援",
    text: "部活動・クラブ活動への支援",
    example: [
      "外部コーチとしての実技指導",
      "大会への引率支援 等",
      "学校の事務作業への支援",
    ],
  },
  {
    value: "学校の事務作業への支援",
    text: "学校の事務作業への支援",
    example: ["文書作成・管理", "印刷・封入", "行事準備 等"],
  },
  {
    value: "地域コーディネーターへの支援",
    text: "地域コーディネーターへの支援",
    example: ["地域の行事", "社会奉仕活動"],
  },
  {
    value: "学校行事への支援",
    text: "学校行事への支援",
    example: ["受付業務", "校外学習支援", "行事の準備"],
  },
  {
    value: "地域イベントへの参加",
    text: "地域イベントへの参加",
    example: ["学校主催イベント", "学校外主催イベント"],
  },
] as const;

export const getVolunteerDetailOption = (volunteerType?: VolunteerType) => {
  if (!volunteerType) return [];

  const all = [
    {
      volunteerType: "学習活動への支援",
      text: "実技支援",
      value: "実技支援",
    },
    {
      volunteerType: "学習活動への支援",
      text: "補習支援",
      value: "補習支援",
    },
    {
      volunteerType: "学習活動への支援",
      text: "放課後子供学習の支援",
      value: "放課後子供学習の支援",
    },
    {
      volunteerType: "学習活動への支援",
      text: "体験活動支援",
      value: "体験活動支援",
    },
    {
      volunteerType: "安心・安全確保への支援",
      text: "通学路の見守り",
      value: "通学路の見守り",
    },
    {
      volunteerType: "安心・安全確保への支援",
      text: "朝の挨拶運動、巡回",
      value: "朝の挨拶運動、巡回",
    },
    {
      volunteerType: "安心・安全確保への支援",
      text: "防災訓練への協力",
      value: "防災訓練への協力",
    },
    {
      volunteerType: "学校の環境設備への支援",
      text: "花壇の整備、除草",
      value: "花壇の整備、除草",
    },
    {
      volunteerType: "学校の環境設備への支援",
      text: "施設設備の修繕",
      value: "施設設備の修繕",
    },
    {
      volunteerType: "学校の環境設備への支援",
      text: "図書整理",
      value: "図書整理",
    },
    {
      volunteerType: "部活動・クラブ活動への支援",
      text: "実技指導",
      value: "実技指導",
    },
    {
      volunteerType: "部活動・クラブ活動への支援",
      text: "大会引率支援",
      value: "大会引率支援",
    },
    {
      volunteerType: "学校の事務作業への支援",
      text: "事務作業支援",
      value: "事務作業支援",
    },
    {
      volunteerType: "学校の事務作業への支援",
      text: "文書作成・管理",
      value: "文書作成・管理",
    },
    {
      volunteerType: "学校の事務作業への支援",
      text: "印刷・封入",
      value: "印刷・封入",
    },
    {
      volunteerType: "地域コーディネーターへの支援",
      text: "行事準備",
      value: "行事準備",
    },
    {
      volunteerType: "地域コーディネーターへの支援",
      text: "地域の行事",
      value: "地域の行事",
    },
    {
      volunteerType: "地域コーディネーターへの支援",
      text: "社会奉仕活動",
      value: "社会奉仕活動",
    },
    {
      volunteerType: "学校行事への支援",
      text: "受付業務",
      value: "受付業務",
    },
    {
      volunteerType: "学校行事への支援",
      text: "校外学習支援",
      value: "校外学習支援",
    },
    {
      volunteerType: "学校行事への支援",
      text: "行事の準備",
      value: "行事の準備",
    },
    {
      volunteerType: "地域イベントへの参加",
      text: "学校主催イベント",
      value: "学校主催イベント",
    },
    {
      volunteerType: "地域イベントへの参加",
      text: "学校外主催イベント",
      value: "学校外主催イベント",
    },
  ];

  return all.filter((o) => o.volunteerType === volunteerType);
};

export type VolunteerType = (typeof volunteerOption)[number]["value"];
export type VolunteerDetailType = ReturnType<
  typeof getVolunteerDetailOption
>[number]["value"];

type VolunteerEnumType = [VolunteerType, ...VolunteerType[]];
export const volunteerEnum = volunteerOption.map(
  (o) => o.value
) as VolunteerEnumType;
