import React from "react";
import styled from "styled-components";
import * as Typo from "../../../components/Typo";
import SignUpPreviewMemberChildInfo from "../../../features/registration/SignUpPreviewMemberChildInfo";
import SignUpPreviewMemberPositionSetting from "../../../features/registration/SignUpPreviewMemberPositionSetting";
import { usePolyfitHistory } from "../../../hooks/router";
import { RegistrationFooter } from "../../../components/Footer2";
import { useCurrentUser } from "../../../hooks/recoil/user";
import SignUpPreviewBaseInfo from "../../../features/registration/SignUpPreviewBaseInfo";
import SignUpPreviewTeacherInfo from "../../../features/registration/SignUpPreviewTeacherInfo";
import { useGetChildren } from "../../../hooks/api/child";
import { completeMemberRegistration } from "../../../apiClients/auth";
import { useBaseInfo } from "../../../hooks/api/baseInfo";
import { useTeacherInfo } from "../../../hooks/api/teacherInfo";

const SignUpPreviewMemberPage = () => {
  const history = usePolyfitHistory();
  const user = useCurrentUser();

  const onSubmit = async () => {
    await completeMemberRegistration();
    history.push({ to: "SIGNUP_COMPLETE_MEMBER" });
  };

  return (
    <>
      {user?.type === "PARENT" && (
        <SignUpPreviewParentMemberPage onSubmit={onSubmit} />
      )}
      {user?.type === "TEACHER" && (
        <SignUpPreviewTeacherMemberPage onSubmit={onSubmit} />
      )}
    </>
  );
};

const SignUpPreviewParentMemberPage = ({
  onSubmit,
}: {
  onSubmit: () => Promise<void>;
}) => {
  const user = useCurrentUser();
  const { currentBaseInfo, isLoading: isBaseInfoLoading } = useBaseInfo();
  const { children, isLoading: isChildrenLoading } = useGetChildren(user.id);

  if (isBaseInfoLoading || isChildrenLoading) return <></>;

  return (
    <>
      <TitleWrapper>
        <Typo.Heading3>こちらの内容で登録しますか？</Typo.Heading3>
      </TitleWrapper>

      <StyledContainer>
        <PreviewCard>
          {/* Display base info */}
          <SignUpPreviewBaseInfo currentBaseInfo={currentBaseInfo} />
          {/* Display children */}
          <SignUpPreviewMemberChildInfo currentChildren={children} />
          {/* Display organization info */}
          <SignUpPreviewMemberPositionSetting
            currentBaseInfo={currentBaseInfo}
          />
        </PreviewCard>
      </StyledContainer>

      <RegistrationFooter onClickNext={onSubmit} />
    </>
  );
};

const SignUpPreviewTeacherMemberPage = ({
  onSubmit,
}: {
  onSubmit: () => Promise<void>;
}) => {
  const { currentBaseInfo, isLoading: isBaseInfoLoading } = useBaseInfo();
  const { currentTeacherInfo, isLoading: isTeacherLoading } = useTeacherInfo();

  if (isBaseInfoLoading || isTeacherLoading) return <></>;

  return (
    <>
      <TitleWrapper>
        <Typo.Heading3>こちらの内容で登録しますか？</Typo.Heading3>
      </TitleWrapper>

      <StyledContainer>
        <PreviewCard>
          {/* Display base info */}
          <SignUpPreviewBaseInfo currentBaseInfo={currentBaseInfo} />
          {/* Display teacher info */}
          <SignUpPreviewTeacherInfo currentTeacherInfo={currentTeacherInfo} />
          {/* Display organization info */}
          <SignUpPreviewMemberPositionSetting
            currentBaseInfo={currentBaseInfo}
          />
        </PreviewCard>
      </StyledContainer>

      <RegistrationFooter onClickNext={onSubmit} />
    </>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const StyledContainer = styled.div`
  max-width: 720px;
  margin: 0 auto;
  padding-bottom: 88px;
  @media (max-width: 1024px) {
    max-width: auto;
  }
`;

const PreviewCard = styled.div`
  background: white;
  padding: 24px;
  margin-top: 16px;
`;

export default SignUpPreviewMemberPage;
