import React, { forwardRef, useMemo } from "react";
import styled from "styled-components";
import { APIError } from "../utils/types/ApiError";

export interface ErrorViewProps extends React.HTMLAttributes<HTMLDivElement> {
  error: Error;
}

export const ErrorView = forwardRef<HTMLDivElement, ErrorViewProps>(
  (props, ref) => {
    const { error, ...others } = props;

    const statusCode = useMemo(() => {
      if (error instanceof APIError) {
        return error.status;
      }
      return 500;
    }, [error]);

    return (
      <ErrorViewWrapper ref={ref} {...others}>
        {400 <= statusCode && statusCode < 500 ? (
          <ErrorMessage>
            <strong>{statusCode} Error</strong>
            <span>{error.message}</span>
          </ErrorMessage>
        ) : (
          <ErrorMessage>
            <span>
              予期せぬエラーが発生しました。時間をおいて再度お試しください。
            </span>
          </ErrorMessage>
        )}
      </ErrorViewWrapper>
    );
  }
);

const ErrorViewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 200px;
  max-width: 400px;
  margin: 0 auto;
`;

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  color: #bd271e;
`;
