export const errorCodeToMessage = (errorCode: string) => {
  switch (errorCode) {
    case "auth/invalid-email":
      return "メールアドレスが正しくありません";
    case "auth/user-disabled":
      return "ユーザーがコミュニティの承認者に承認されていません";
    case "auth/user-not-found":
      return "ユーザーが見つかりませんでした";
    case "auth/wrong-password":
      return "パスワードが正しくありません";
    case "auth/email-already-in-use":
    case "auth/email-already-exists":
      return "メールアドレスは既に登録されています";
    case "auth/weak-password":
      return "パスワードは8文字以上の安全なものを設定してください";
    default:
      return "何らかのエラーが発生しました";
  }
};
