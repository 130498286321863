import React from "react";

type Props = {
  size: number;
};
export const ArrowRightIcon = ({ size }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35982 5.2318C8.93554 5.58537 8.87821 6.21593 9.23178 6.64021L13.6983 12L9.23178 17.3598C8.87821 17.7841 8.93554 18.4147 9.35982 18.7682C9.78409 19.1218 10.4147 19.0645 10.7682 18.6402L15.7682 12.6402C16.0773 12.2694 16.0773 11.7307 15.7682 11.3598L10.7682 5.35984C10.4147 4.93556 9.78409 4.87824 9.35982 5.2318Z"
        fill="#37434F"
      />
    </svg>
  );
};
