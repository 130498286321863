import { User } from "src/apiClients/users";
import * as client from "./client";
import { Organization } from "./organization";

export type Part = {
  id: string;
  organizationId: string;
  name: string;
  order: number | null;
  createdAt: Date;
  organization?: Organization;
  users: User[];
};

export async function createPart(
  organizationId: string,
  name: string,
  order: number | null
) {
  const res = await client.post("/part/create", {
    organizationId,
    name,
    order,
  });
  return res;
}

export async function updatePart(
  partId: string,
  name: string,
  order: number | null
) {
  const res = await client.post("/part/update", {
    partId,
    name,
    order,
  });
  return res;
}

export async function deletePart(partId: string) {
  const res = await client.post("/part/delete", { partId });
  return res;
}
