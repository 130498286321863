import { z } from "zod";

/**
 * ホーム/プロフィール
 */
export const PTAFormValueSchema = z.object({
  baseInfo: z.object({
    lastName: z.string().min(1, { message: "入力必須項目です" }),
    lastNameKana: z
      .string()
      .min(1, { message: "入力必須項目です" })
      .refine((value) => /^[ぁ-んー]+$/.test(value), {
        message: "ひらがなのみで入力してください",
      }),
    firstName: z.string().min(1, { message: "入力必須項目です" }),
    firstNameKana: z
      .string()
      .min(1, { message: "入力必須項目です" })
      .refine((value) => /^[ぁ-んー]+$/.test(value), {
        message: "ひらがなのみで入力してください",
      }),
    belongId: z.string().optional().nullable(),
    partId: z.string().optional().nullable(),
    phoneNumber: z
      .string()
      .min(1, { message: "入力必須項目です" })
      .refine((value) => /(0{1}\d{9,10})$/.test(value), {
        message: "ハイフンは入力せず、半角文字のみで入力してください",
      }),
    schoolGroup: z.string().optional().nullable(),
    isPreschooler: z.number().optional().nullable(), // memberPTAFormValueSchemaにはない
    selfFreeMemo: z.string().optional().nullable(),
    adminFreeMemo: z.string().optional().nullable(),
  }),
});

/**
 * 名簿/プロフィール
 * 管理人&&保護者用
 */
export const parentPTAAdminFormValueSchema = z.object({
  baseInfo: z.object({
    userId: z.string(),
    lastName: z.string().min(1, { message: "入力必須項目です" }),
    lastNameKana: z
      .string()
      .min(1, { message: "入力必須項目です" })
      .refine((value) => /^[ぁ-んー]+$/.test(value), {
        message: "ひらがなのみで入力してください",
      }),
    firstName: z.string().min(1, { message: "入力必須項目です" }),
    firstNameKana: z
      .string()
      .min(1, { message: "入力必須項目です" })
      .refine((value) => /^[ぁ-んー]+$/.test(value), {
        message: "ひらがなのみで入力してください",
      }),
    belongId: z.string().optional().nullable(),
    partId: z.string().optional().nullable(),
    phoneNumber: z
      .string()
      .min(1, { message: "入力必須項目です" })
      .refine((value) => /(0{1}\d{9,10})$/.test(value), {
        message: "ハイフンは入力せず、半角文字のみで入力してください",
      }),
    schoolGroup: z.string().optional().nullable(),
    selfFreeMemo: z.string().optional().nullable(),
    adminFreeMemo: z.string().optional().nullable(),
  }),
  children: z.array(
    z.object({
      id: z.string().optional(),
      childLastName: z.string().min(1, { message: "入力必須項目です" }),
      childLastNameKana: z
        .string()
        .min(1, { message: "入力必須項目です" })
        .refine((value) => /^[ぁ-んー]+$/.test(value), {
          message: "ひらがなのみで入力してください",
        }),
      childFirstName: z.string().min(1, { message: "入力必須項目です" }),
      childFirstNameKana: z
        .string()
        .min(1, { message: "入力必須項目です" })
        .refine((value) => /^[ぁ-んー]+$/.test(value), {
          message: "ひらがなのみで入力してください",
        }),
      grade: z
        .union([z.string(), z.number()])
        .refine((value) => String(value).length > 0, {
          message: "入力必須項目です",
        })
        .transform((val) => Number(val)),
      class: z
        .union([z.string(), z.number()])
        .refine((value) => String(value).length > 0, {
          message: "入力必須項目です",
        })
        .transform((val) => Number(val)),
    })
  ),
});

/**
 * 名簿/プロフィール
 * !管理人&&保護者用
 */
export const parentPTAFormValueSchema = z.object({
  baseInfo: z.object({
    userId: z.string(),
    lastName: z.string().min(1, { message: "入力必須項目です" }),
    lastNameKana: z
      .string()
      .min(1, { message: "入力必須項目です" })
      .refine((value) => /^[ぁ-んー]+$/.test(value), {
        message: "ひらがなのみで入力してください",
      }),
    firstName: z.string().min(1, { message: "入力必須項目です" }),
    firstNameKana: z
      .string()
      .min(1, { message: "入力必須項目です" })
      .refine((value) => /^[ぁ-んー]+$/.test(value), {
        message: "ひらがなのみで入力してください",
      }),
    belongId: z.string().optional().nullable(),
    partId: z.string().optional().nullable(),
    schoolGroup: z.string().optional().nullable(),
    selfFreeMemo: z.string().optional().nullable(),
    adminFreeMemo: z.string().optional().nullable(),
  }),
  children: z.array(
    z.object({
      id: z.string(),
      childLastName: z.string().min(1, { message: "入力必須項目です" }),
      childLastNameKana: z
        .string()
        .min(1, { message: "入力必須項目です" })
        .refine((value) => /^[ぁ-んー]+$/.test(value), {
          message: "ひらがなのみで入力してください",
        }),
      childFirstName: z.string().min(1, { message: "入力必須項目です" }),
      childFirstNameKana: z
        .string()
        .min(1, { message: "入力必須項目です" })
        .refine((value) => /^[ぁ-んー]+$/.test(value), {
          message: "ひらがなのみで入力してください",
        }),
      grade: z
        .union([z.string(), z.number()])
        .refine((value) => String(value).length > 0, {
          message: "入力必須項目です",
        })
        .transform((val) => Number(val)),
      class: z
        .union([z.string(), z.number()])
        .refine((value) => String(value).length > 0, {
          message: "入力必須項目です",
        })
        .transform((val) => Number(val)),
    })
  ),
});

/**
 * 名簿/プロフィール
 * 管理人&&教員用
 */
export const teacherPTAAdminFormValueSchema = z.object({
  baseInfo: z.object({
    userId: z.string(),
    lastName: z.string().min(1, { message: "入力必須項目です" }),
    lastNameKana: z
      .string()
      .min(1, { message: "入力必須項目です" })
      .refine((value) => /^[ぁ-んー]+$/.test(value), {
        message: "ひらがなのみで入力してください",
      }),
    firstName: z.string().min(1, { message: "入力必須項目です" }),
    firstNameKana: z
      .string()
      .min(1, { message: "入力必須項目です" })
      .refine((value) => /^[ぁ-んー]+$/.test(value), {
        message: "ひらがなのみで入力してください",
      }),
    belongId: z.string().optional().nullable(),
    partId: z.string().optional().nullable(),
    phoneNumber: z
      .string()
      .min(1, { message: "入力必須項目です" })
      .refine((value) => /(0{1}\d{9,10})$/.test(value), {
        message: "ハイフンは入力せず、半角文字のみで入力してください",
      }),
    schoolGroup: z.string().optional().nullable(),
    selfFreeMemo: z.string().optional().nullable(),
    adminFreeMemo: z.string().optional().nullable(),
  }),
  teacherInfo: z
    .object({
      hasHomeroom: z.union([z.string(), z.boolean()]).transform((value) => {
        if (value === "true" || value === true) {
          return true;
        } else {
          return false;
        }
      }),
      grade: z.number().optional(),
      class: z.number().optional(),
    })
    .refine(
      (data) => {
        if (data.hasHomeroom) {
          return !!data.grade;
        }
        return true;
      },
      {
        message: "学年・クラスを選択してください",
        path: ["teacherInfo", "grade"],
      }
    )
    .refine(
      (data) => {
        if (data.hasHomeroom) {
          return !!data.class;
        }
        return true;
      },
      {
        message: "学年・クラスを選択してください",
        path: ["teacherInfo", "class"],
      }
    ),
});

/**
 * 名簿/プロフィール
 * !管理人&&教員用
 */
export const teacherPTAFormValueSchema = z.object({
  baseInfo: z.object({
    userId: z.string(),
    lastName: z.string().min(1, { message: "入力必須項目です" }),
    lastNameKana: z
      .string()
      .min(1, { message: "入力必須項目です" })
      .refine((value) => /^[ぁ-んー]+$/.test(value), {
        message: "ひらがなのみで入力してください",
      }),
    firstName: z.string().min(1, { message: "入力必須項目です" }),
    firstNameKana: z
      .string()
      .min(1, { message: "入力必須項目です" })
      .refine((value) => /^[ぁ-んー]+$/.test(value), {
        message: "ひらがなのみで入力してください",
      }),
    belongId: z.string().optional().nullable(),
    partId: z.string().optional().nullable(),
    schoolGroup: z.string().optional().nullable(),
    selfFreeMemo: z.string().optional().nullable(),
    adminFreeMemo: z.string().optional().nullable(),
  }),
  teacherInfo: z
    .object({
      hasHomeroom: z.union([z.string(), z.boolean()]).transform((value) => {
        if (value === "true" || value === true) {
          return true;
        } else {
          return false;
        }
      }),
      grade: z.number().optional(),
      class: z.number().optional(),
    })
    .refine(
      (data) => {
        if (data.hasHomeroom) {
          return !!data.grade;
        }
        return true;
      },
      {
        message: "学年・クラスを選択してください",
        path: ["teacherInfo", "grade"],
      }
    )
    .refine(
      (data) => {
        if (data.hasHomeroom) {
          return !!data.class;
        }
        return true;
      },
      {
        message: "学年・クラスを選択してください",
        path: ["teacherInfo", "class"],
      }
    ),
});
