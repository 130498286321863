import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { editRecruitmentApplicationComment } from "../../../apiClients/recruitmentApplicationComment";
import { useToast } from "../../../components/Toast";
import styled from "styled-components";
import { CheckBox } from "../../../components/form/CheckBox";
import ReactTextareaAutosize from "react-textarea-autosize";

interface EditRecruitmentAppliedCommentModalFormProps {
  comment: string;
  isAlert: boolean;
  refetch: () => void;
  recruitmentApplicationCommentId: string;
  setIsEditingComment: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditRecruitmentAppliedCommentModalForm = ({
  comment,
  isAlert,
  refetch,
  recruitmentApplicationCommentId,
  setIsEditingComment,
}: EditRecruitmentAppliedCommentModalFormProps) => {
  type FormValues = {
    recruitmentApplicationCommentId: string;
    comment: string;
    isAlert: boolean;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const toast = useToast();

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onCommentClick = async (data: FormValues) => {
    setDoubleClickBlocked(true);
    const arg: FormValues = {
      recruitmentApplicationCommentId: recruitmentApplicationCommentId,
      comment: data.comment,
      isAlert: data.isAlert,
    };
    try {
      await editRecruitmentApplicationComment(arg);
      toast.success("コメントを編集しました");
      refetch();
      setIsEditingComment(false);
    } catch (e) {
      setIsEditingComment(false);
      toast.warn("コメントの編集に失敗しました");
    }
    setDoubleClickBlocked(false);
  };
  return (
    <FormContainer>
      <CommentTextArea
        defaultValue={comment}
        {...register("comment", {
          required: "入力必須項目です",
        })}
      />
      {errors.comment && <ErrorMessage>{errors.comment.message}</ErrorMessage>}
      <BottomContainer>
        <CheckBox
          label="アラートタグをつける"
          register={register("isAlert")}
          defaultChecked={isAlert}
        />
        <UpdateLinkButton
          onClick={handleSubmit(onCommentClick)}
          disabled={doubleClickBlocked}
        >
          編集完了
        </UpdateLinkButton>
      </BottomContainer>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  background-color: #f5f7fa;
  padding: 8px;
  border-radius: 4px;
`;

const CommentTextArea = styled(ReactTextareaAutosize)`
  /* reset css */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
  /* style */
  resize: vertical;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  color: #bd271e;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

const UpdateLinkButton = styled.button`
  color: #0077cc;
  cursor: pointer;
  font-size: 12px;
  background-color: transparent;
  border: 0;
`;

export default EditRecruitmentAppliedCommentModalForm;
