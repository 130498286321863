import * as client from "../client";
import { CommunityMemberRoleCountsResponse } from "@shared/types/boardEducation/community";

export async function getCommunitiesMemberRoleCounts() {
  const res = await client.get<{}, CommunityMemberRoleCountsResponse>(
    "/boardEducation/community/getCommunityMemberRoleCounts",
    {}
  );
  return res;
}
