import React, { useEffect } from "react";
import { PageContainer, PageHeader, PageBody } from "../../../components/Page";
import * as Typo from "../../../components/Typo";
import * as Responsive from "../../../components/Responsive";
import { AirPlaneIcon } from "../../../components/icons/SvgIcons";
import styled from "styled-components";
import { useCurrentUserHook } from "../../../hooks/recoil/user";
import { Spinner } from "../../../components/icons/Spinner";
import { usePolyfitHistory } from "../../../hooks/router";
import { Navigation } from "../../../components/Navigation3";

const SignUpCompleteMemberPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 702px;
  margin: 0 auto;
  padding-top: 124px;
  .message {
    text-align: center;
  }
  @media (max-width: 1279px) {
    max-width: 343px;
    padding-top: 120px;
  }
`;

export default function SignUpCompleteMemberPage() {
  const { user, loading } = useCurrentUserHook();
  const history = usePolyfitHistory();

  const isSignupComplete = user?.signUpState && user?.able;

  useEffect(() => {
    // すでに承認済みの場合はリダイレクト
    if (isSignupComplete) {
      history.replace({ to: "LOADING", query: {} });
    }
  }, [isSignupComplete]);

  return (
    <>
      <Navigation />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <SignUpCompleteMemberPageContainer>
          <AirPlaneIcon />
          <PageHeader>申請が完了しました！</PageHeader>
          <PageBody>
            <Responsive.Row>
              <Responsive.Col>
                <Typo.Paragraph className="message">
                  管理者が承認するまで少々お待ちください
                  <br />
                  承認されると、入会手続きが完了し、polyfitをご利用いただけます！
                </Typo.Paragraph>
              </Responsive.Col>
            </Responsive.Row>
          </PageBody>
        </SignUpCompleteMemberPageContainer>
      )}
    </>
  );
}
