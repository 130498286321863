import { useEffect, useState } from "react";
import { getPartner, Partner } from "../../apiClients/partner";

export const usePartner = () => {
  const [partner, setPartner] = useState<Partner | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPartner = async () => {
    setIsLoading(true);
    const partner = await getPartner();
    setPartner(partner);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPartner();
  }, []);

  return {
    partner,
    isLoading,
    fetchPartner,
  };
};
