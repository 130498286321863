import { CommunityWithSignatureTemplate } from "src/@shared/types/organization";
import { SignatureTemplate } from "src/@shared/types/signatureTemplate";
import { communityUserTypeOptions } from "src/@shared/types/userType";
import { AccountCommunityRole } from "src/apiClients/auth";
import { isCsAdminRole } from "src/utils/types/role";
import { Community } from "src/@shared/types/community";
import { ResidentInfo } from "src/@shared/types/residentInfo";

export const hasValidSignatureTemplate = (
  community: CommunityWithSignatureTemplate
): boolean => {
  return community.signatureTemplates.length > 0;
};

export const getFirstValidSignatureTemplate = (
  community: CommunityWithSignatureTemplate
): SignatureTemplate | null => {
  return community.signatureTemplates.length > 0
    ? community.signatureTemplates[0]
    : null;
};

export const getCommunityUserTypeText = (type: string | undefined) => {
  const option = communityUserTypeOptions.find(
    (option) => option.value === type
  );
  return option ? option.text : "";
};

export const isAdminRoleCommunity = (
  communityId: string,
  communityRoles: AccountCommunityRole[]
) => {
  return communityRoles.some(
    (communityRole) =>
      communityRole.communityId === communityId &&
      isCsAdminRole(communityRole.role.name)
  );
};

export const sortCommunities = (a: Community, b: Community): number => {
  // B1（小学校）とC1（中学校）をソート
  if (a.school?.type !== b.school?.type) {
    return a.school?.type === "B1" ? -1 : 1;
  }

  return 0;
};

// 組織権限（account.roleId）と校区権限（accountCommunityRole.roleId）が不一致になると`communityId`のクエリパラメータが付与されない不具合があった。
// 組織権限と校区権限の整理が済むまでは、
//   1. 組織権限と一致する校区権限
//   2. （1）の該当がない時はcommunityRoles[0]
// 以上の優先順位でfirstCommunityIdを取得する
// https://github.com/Polyfit/polyfit-app/issues/3501https://github.com/Polyfit/polyfit-app/issues/3501
export const getFirstCommunityId = (
  communityRoles: AccountCommunityRole[],
  roleId: string | undefined
): string | undefined => {
  // 1. 組織権限と一致する校区権限
  const firstCommunity = communityRoles.find(
    (communityRole) => communityRole.roleId === roleId
  );
  if (firstCommunity) {
    return firstCommunity.communityId;
  }

  // 2. （1）の該当がない時はcommunityRoles[0]
  return communityRoles.length > 0 ? communityRoles[0].communityId : undefined;
};

export const getLicensesAndSkills = (
  residentInfo: ResidentInfo
): string[] | null => {
  const items = [
    ...(residentInfo?.teacherLicenses || []),
    ...(residentInfo?.medicalLicenses || []),
    ...(residentInfo?.skills || []),
  ];

  if (items.length === 0) {
    return null;
  }

  return items.map((item, index) => item);
};

export const getPreferredVolunteerTypes = (
  residentInfo: ResidentInfo
): string[] | null => {
  const types = residentInfo?.preferredVolunteerType || [];

  if (types.length === 0) {
    return null;
  }

  return types.map((type) => type);
};
