export const dayOfWeekOptions = [
  { value: "MONDAY", text: "月曜日" },
  { value: "TUESDAY", text: "火曜日" },
  { value: "WEDNESDAY", text: "水曜日" },
  { value: "THURSDAY", text: "木曜日" },
  { value: "FRIDAY", text: "金曜日" },
  { value: "SATURDAY", text: "土曜日" },
  { value: "SUNDAY", text: "日曜日" },
] as const;

export type DayOfWeekType = (typeof dayOfWeekOptions)[number]["value"];

export function getDayOfWeekText(dayOfWeek: DayOfWeekType): string {
  return (
    dayOfWeekOptions.find((option) => option.value === dayOfWeek)?.text ?? ""
  );
}
