import React from "react";
import styled from "styled-components";
import { DateLabelItem } from "src/features/common/ChatMessage/types";

type Props = {
  data: DateLabelItem;
  className: string;
};

export const DateLabel = ({ data, className }: Props) => {
  return (
    <div
      className={className}
      data-prev-date={data.prevDate}
      data-current-date={data.value}
    >
      <Divider>
        <Label>{data.value}</Label>
      </Divider>
    </div>
  );
};

const Label = styled.div`
  background: white;
  height: 24px;
  font-size: 12px;
  padding: 0 16px;
  border-radius: 24px;
  border: 1px solid rgba(221, 221, 221, 1);
  text-align: center;
  width: fit-content;
  margin-bottom: -12px;
  font-weight: 600;
  line-height: 22px;
`;

const Divider = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(221, 221, 221, 1);
`;
