import styled from "styled-components";

export const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
`;

export const SubTitle = styled.h2`
  margin-top: 5px;
  color: #343741;
  font-size: 16px;
  font-weight: normal;
`;
