import React, { FC } from "react";
import { Navigation } from "../components/NavigationBE";
import styled from "styled-components";
import { SideBar } from "../components/Common/Sidebar/SideBar";
import { BREAKPOINTS } from "../components/Responsive";

const SideBarBlock = styled.div`
  min-width: 160px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const PageBody = styled.div`
  flex: 1;
  padding: 24px;
  min-width: 0;
  height: 100%;
  @media (max-width: ${BREAKPOINTS.SP}) {
    padding: 24px 16px;
  }
`;

const Container = styled.div`
  height: 100%;
  padding-top: 48px;
`;

type AppLayoutProps = {
  children?: React.ReactNode;
};

export const AppBEDefaultLayout: FC<AppLayoutProps> = (props) => {
  return (
    <>
      <Navigation />
      <Container>
        <SideBar />
        <div style={{ display: "flex", height: "100%" }}>
          <SideBarBlock />
          <PageBody>{props.children}</PageBody>
        </div>
      </Container>
    </>
  );
};
