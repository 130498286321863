import { useCallback, useEffect, useMemo, useState } from "react";
import { getAccounts } from "../../apiClients/auth";
import { GetAccountsResponse } from "@shared/types/auth";
import { School } from "@shared/types/school";

type Organization = {
  id: string;
  name: string;
  communities: {
    id: string;
    name: string;
    school: School;
  }[];
};
export function useAccounts() {
  const [loading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState<GetAccountsResponse[]>([]);

  const fetchAccounts = useCallback(async () => {
    setIsLoading(true);
    const accounts = await getAccounts();
    setAccounts(accounts);
    setIsLoading(false);
  }, []);

  const ptaOrganizations: Organization[] = useMemo(() => {
    return accounts
      .filter((account) => account.serviceType === "PTA")
      .map((account) => account.organization);
  }, [accounts]);

  const csOrganizations: Organization[] = useMemo(() => {
    return accounts
      .filter((account) => account.serviceType === "CS")
      .map((account) => account.organization);
  }, [accounts]);

  useEffect(() => {
    fetchAccounts();
  }, []);

  return {
    accounts,
    loading,
    ptaOrganizations,
    csOrganizations,
  };
}
