import { useEffect, useState } from "react";
import * as chatRoomApi from "../../../apiClients/chatRoom";
import { useChatRoomFilter } from "../../../hooks/recoil/chatRoomFilter";
import { useWebSocket } from "../../../hooks/recoil/socket";
import { ChatRoomEntity } from "@shared/types/chatRoom";
import { EVENT_NAMES } from "@shared/types/webSocket";

export const useChatRooms = ({
  chatRoomParticipantId,
}: {
  chatRoomParticipantId: string;
}) => {
  const socket = useWebSocket();
  const { chatRoomFilter } = useChatRoomFilter();
  const [chatRooms, setChatRooms] = useState<ChatRoomEntity[]>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchChatRooms();
  }, [chatRoomFilter]);

  useEffect(() => {
    if (!socket.instance) return;

    const handleUpdatedChatRoom = async (data: { chatRoomId: string }) => {
      const {
        chatRoom,
        individualChatWithOrganization,
        recruitmentApplication,
        groupChatWithOrganization,
      } = await chatRoomApi.fetchChatRoomById(data.chatRoomId);
      setChatRooms((prevChatRooms) => {
        // 更新があったチャットルームを取得
        const targetChatRoom = prevChatRooms?.find(
          (_chatRoom) => _chatRoom.chatRoom.id === chatRoom.id
        );

        // 自分が参加していないチャットルームの場合は何もしない
        // 基本この分岐に入るとはない想定
        if (!targetChatRoom) return prevChatRooms;

        // 更新があったチャットルームを新しいデータで上書き
        targetChatRoom.chatRoom = chatRoom;
        targetChatRoom.individualChatWithOrganization =
          individualChatWithOrganization;
        targetChatRoom.recruitmentApplication = recruitmentApplication;
        targetChatRoom.groupChatWithOrganization = groupChatWithOrganization;

        const otherChatRooms =
          prevChatRooms?.filter(
            (_chatRoom) => _chatRoom.chatRoom.id !== chatRoom.id
          ) ?? [];

        return [targetChatRoom, ...otherChatRooms];
      });
    };

    const handleAddedNewChatRoom = (data: { chatRoomId: string }) => {
      fetchChatRooms(false);
    };

    const handleDeletedChatRoom = (data: { chatRoomId: string }) => {
      setChatRooms((prevChatRooms) =>
        prevChatRooms?.filter(
          (_chatRoom) => _chatRoom.chatRoom.id !== data.chatRoomId
        )
      );
    };

    socket.instance.on(EVENT_NAMES.UPDATED_CHAT_ROOM, handleUpdatedChatRoom);
    socket.instance.on(EVENT_NAMES.ADDED_NEW_CHAT_ROOM, handleAddedNewChatRoom);
    socket.instance.on(EVENT_NAMES.DELETED_CHAT_ROOM, handleDeletedChatRoom);

    return () => {
      socket.instance?.off(
        EVENT_NAMES.UPDATED_CHAT_ROOM,
        handleUpdatedChatRoom
      );
      socket.instance?.off(
        EVENT_NAMES.ADDED_NEW_CHAT_ROOM,
        handleAddedNewChatRoom
      );
      socket.instance?.off(
        EVENT_NAMES.DELETED_CHAT_ROOM,
        handleDeletedChatRoom
      );
    };
  }, [socket.isInitialized]);

  const fetchChatRooms = async (showLoading = true) => {
    setIsLoading(showLoading);
    const chatRooms = await chatRoomApi.fetchChatRooms(chatRoomFilter);
    setChatRooms(chatRooms);
    setIsLoading(false);
  };

  return { chatRooms, isLoading, refetch: fetchChatRooms };
};
