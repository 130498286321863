import React, { JSX } from "react";
import { Label } from "../../../components/form/Label";
import styled from "styled-components";
import { QuestionContainer, QuestionTextField } from "./Components";
import { Choice, MultiChoiceQuestion } from "@shared/types/post/survey/survey";

export function MultiChoiceQuestionField({
  question,
}: {
  question: MultiChoiceQuestion;
}): JSX.Element {
  return (
    <QuestionContainer>
      <Label size="s" marginBottom={0}>
        質問
      </Label>
      <QuestionTextField>{question.text}</QuestionTextField>
      <Label size="s" marginBottom={0}>
        補足説明
      </Label>
      <QuestionTextField>{question.description}</QuestionTextField>
      <Label size="s" marginBottom={0}>
        選択肢一覧
      </Label>
      {question.choiceList.map((choice: Choice, index: number) => (
        <ChoiceContainer key={index}>
          <CircleIcon />
          <ChoiceText key={index}>{choice.text}</ChoiceText>
        </ChoiceContainer>
      ))}
      <Label size="s" marginBottom={0}>
        選択肢の取り扱い
      </Label>
      <OptionContainer
        title="複数の子供がいる場合、子供ごとに票を数える"
        checked={question.weightsNumberOfChildren}
      />
      <OptionContainer
        title="複数の選択肢を選べるようにする"
        checked={question.allowMultipleAnswers}
      />
    </QuestionContainer>
  );
}

const ChoiceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  svg: {
    width: 16px;
    height: 16px;
  }
`;

const ChoiceText = styled.div`
  flex: 1;
  background-color: transparent;
  font-size: 14px;
  color: #343741;
  line-height: 24px;
  border-width: 0px;
  border-bottom: rgba(19, 34, 149, 0.1) 1px solid;
`;

const CircleIcon = React.memo(() => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7.5" fill="white" stroke="#C9CBCD" />
  </svg>
));

function OptionContainer({
  title,
  checked,
}: {
  title: string;
  checked: boolean;
}): JSX.Element {
  const style: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
  };
  return (
    <div style={style}>
      <CheckBox checked={checked} />
      <div style={{ flex: "1 1" }}>{title}</div>
    </div>
  );
}

function CheckBox({ checked }: { checked: boolean }): JSX.Element {
  const style: React.CSSProperties = {
    flex: "0 0",
    display: "inline-block",
    alignItems: "center",
    justifyContent: "center",
    width: "16px",
    height: "16px",
    background: "#fff",
    border: "1px solid #c9cbcd",
    boxSizing: "content-box",
    borderRadius: "4px",
  };
  return (
    <div style={style}>
      {checked && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#0077cc"
          stroke="#0077cc"
          strokeWidth="3px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polyline points="18 3 7 14 2 9" fill="none" />
        </svg>
      )}
      {!checked && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#ffffff"
          xmlns="http://www.w3.org/2000/svg"
        ></svg>
      )}
    </div>
  );
}
