import { ResidentFilterType } from "@shared/types/user";
import React, { useCallback, useState } from "react";
import { ArrowDropDownIcon } from "src/components/icons/ArrowDropDown";
import { CommunityUserTypeOptions } from "src/features/Member/Community/CommunityUserTypeSelect";
import { CheckBox } from "src/components/form/CheckBox";
import { dayOfWeekOptions } from "src/utils/types/dayOfWeek";
import {
  medicalLicenseOptions,
  skillOptions,
  teachingLicenseOptions,
} from "src/utils/types/license";
import { volunteerOption } from "@shared/types/volunteerType";
import { RadioButton } from "src/components/form/RadioButton";
import { Button } from "src/components/Button3";
import styled from "styled-components";
import { BEResidentFilter } from "@shared/types/boardEducation/residentUsers";
import { Community } from "@shared/types/community";
import { useExtractSchools } from "../../Member/Community/utils/hooks";

export const BEResidentFilterForm = ({
  defaultValues,
  onSubmit,
  communities,
  loading,
}: {
  defaultValues: ResidentFilterType;
  onSubmit: (data: ResidentFilterType) => void;
  communities: Community[];
  loading: boolean;
}) => {
  const [tmpResidentFilter, setTmpResidentFilter] =
    useState<BEResidentFilter>(defaultValues);

  const handleOnSubmit = useCallback(
    // @ts-expect-error todo
    (event) => {
      onSubmit(tmpResidentFilter);
      event.preventDefault();
    },
    [tmpResidentFilter]
  );

  const { elementarySchools, juniorHighSchools } =
    useExtractSchools(communities);

  return (
    <ResidentFilter>
      <ResidentFilterSummary>
        フィルター条件
        <ArrowDropDownIcon size={24} />
      </ResidentFilterSummary>

      <form onSubmit={handleOnSubmit}>
        <FilterContainer>
          <Section>
            <SectionTitle>ユーザータイプ</SectionTitle>
            <SectionInputArea>
              {CommunityUserTypeOptions.map((userType, index) => {
                return (
                  <CheckBox
                    key={index}
                    label={userType.text}
                    defaultChecked={tmpResidentFilter.communityUserType.includes(
                      userType.value
                    )}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const tmpUserType = [
                          ...tmpResidentFilter.communityUserType,
                          userType.value,
                        ];

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          communityUserType: tmpUserType,
                        });
                      } else {
                        const tmpUserType =
                          tmpResidentFilter.communityUserType.filter(
                            (l) => l !== userType.value
                          );

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          communityUserType: tmpUserType,
                        });
                      }
                    }}
                  />
                );
              })}
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>校区</SectionTitle>
            <SectionInputArea>
              {[
                { value: true, text: "メイン校区" },
                { value: false, text: "サブ校区" },
                { value: undefined, text: "指定なし" },
              ].map((option, index) => (
                <RadioButton
                  key={index}
                  label={option.text}
                  checked={tmpResidentFilter.isMain === option.value}
                  onChange={() => {
                    setTmpResidentFilter({
                      ...tmpResidentFilter,
                      isMain: option.value,
                    });
                  }}
                />
              ))}
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>小学校区</SectionTitle>
            <SectionInputArea>
              {elementarySchools.map((school) => (
                <CheckBox
                  key={school.id}
                  label={school.name}
                  defaultChecked={tmpResidentFilter.communityIds?.includes(
                    school.id
                  )}
                  onChange={(e) => {
                    const newCommunityIds = e.target.checked
                      ? [...(tmpResidentFilter.communityIds || []), school.id]
                      : tmpResidentFilter.communityIds?.filter(
                          (id) => id !== school.id
                        ) || [];
                    setTmpResidentFilter({
                      ...tmpResidentFilter,
                      communityIds: newCommunityIds,
                    });
                  }}
                />
              ))}
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>中学校区</SectionTitle>
            <SectionInputArea>
              {juniorHighSchools.map((school) => (
                <CheckBox
                  key={school.id}
                  label={school.name}
                  defaultChecked={tmpResidentFilter.communityIds?.includes(
                    school.id
                  )}
                  onChange={(e) => {
                    const newCommunityIds = e.target.checked
                      ? [...(tmpResidentFilter.communityIds || []), school.id]
                      : tmpResidentFilter.communityIds?.filter(
                          (id) => id !== school.id
                        ) || [];
                    setTmpResidentFilter({
                      ...tmpResidentFilter,
                      communityIds: newCommunityIds,
                    });
                  }}
                />
              ))}
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>有償・無償</SectionTitle>
            <SectionInputArea>
              <CheckBox
                label="有償"
                defaultChecked={tmpResidentFilter.preferredPaid.includes(
                  "PAID"
                )}
                onChange={(e) => {
                  // FIXME: 関数切り出ししたい
                  if (e.target.checked) {
                    const preferredPaid: ("FREE" | "PAID")[] = [
                      ...tmpResidentFilter.preferredPaid,
                      "PAID",
                    ];
                    setTmpResidentFilter({
                      ...tmpResidentFilter,
                      preferredPaid,
                    });
                  } else {
                    const preferredPaid =
                      tmpResidentFilter.preferredPaid.filter(
                        (l) => l !== "PAID"
                      );
                    setTmpResidentFilter({
                      ...tmpResidentFilter,
                      preferredPaid,
                    });
                  }
                }}
              />
              <CheckBox
                label="無償"
                defaultChecked={
                  tmpResidentFilter.preferredPaid.includes("FREE") ?? false
                }
                onChange={(e) => {
                  // FIXME: 関数切り出ししたい
                  if (e.target.checked) {
                    const preferredPaid: ("FREE" | "PAID")[] = [
                      ...tmpResidentFilter.preferredPaid,
                      "FREE",
                    ];
                    setTmpResidentFilter({
                      ...tmpResidentFilter,
                      preferredPaid,
                    });
                  } else {
                    const preferredPaid =
                      tmpResidentFilter.preferredPaid.filter(
                        (l) => l !== "FREE"
                      );
                    setTmpResidentFilter({
                      ...tmpResidentFilter,
                      preferredPaid,
                    });
                  }
                }}
              />
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>希望活動曜日</SectionTitle>
            <SectionInputArea>
              {dayOfWeekOptions.map((dayOfWeekOption, index) => {
                return (
                  <CheckBox
                    key={index}
                    label={dayOfWeekOption.text}
                    defaultChecked={tmpResidentFilter.preferredDays?.includes(
                      dayOfWeekOption.value
                    )}
                    onChange={(e) => {
                      // FIXME: 関数切り出ししたい
                      if (e.target.checked) {
                        const preferredDays = [
                          ...tmpResidentFilter.preferredDays,
                          dayOfWeekOption.value,
                        ];

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          preferredDays,
                        });
                      } else {
                        const preferredDays =
                          tmpResidentFilter.preferredDays.filter(
                            (l) => l !== dayOfWeekOption.value
                          );

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          preferredDays,
                        });
                      }
                    }}
                  />
                );
              })}
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>スキル・資格</SectionTitle>
            <SectionInputArea>
              {[
                ...teachingLicenseOptions,
                ...medicalLicenseOptions,
                ...skillOptions,
              ].map((license, index) => {
                return (
                  <CheckBox
                    key={index}
                    label={license.text}
                    defaultChecked={tmpResidentFilter.licenses.includes(
                      license.value
                    )}
                    onChange={(e) => {
                      // FIXME: 関数切り出ししたい
                      if (e.target.checked) {
                        const licenses = [
                          ...tmpResidentFilter.licenses,
                          license.value,
                        ];

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          licenses,
                        });
                      } else {
                        const licenses = tmpResidentFilter.licenses.filter(
                          (l) => l !== license.value
                        );

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          licenses,
                        });
                      }
                    }}
                  />
                );
              })}
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>活動内容</SectionTitle>
            <SectionInputArea>
              {volunteerOption.map((volunteerOption, index) => {
                return (
                  <CheckBox
                    key={index}
                    label={volunteerOption.text}
                    defaultChecked={tmpResidentFilter.preferredVolunteerTypes.includes(
                      volunteerOption.value
                    )}
                    onChange={(e) => {
                      // FIXME: 関数切り出ししたい
                      if (e.target.checked) {
                        const volunteerType = [
                          ...tmpResidentFilter.preferredVolunteerTypes,
                          volunteerOption.value,
                        ];

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          preferredVolunteerTypes: volunteerType,
                        });
                      } else {
                        const volunteerType =
                          tmpResidentFilter.preferredVolunteerTypes.filter(
                            (l) => l !== volunteerOption.value
                          );

                        setTmpResidentFilter({
                          ...tmpResidentFilter,
                          preferredVolunteerTypes: volunteerType,
                        });
                      }
                    }}
                  />
                );
              })}
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>学校からの徒歩</SectionTitle>
            <SectionInputArea>
              {[1, 5, 7, 10, 15, 20, 30].map((withinWalkMinute, index) => {
                return (
                  <RadioButton
                    key={index}
                    label={`${withinWalkMinute}分以内`}
                    checked={
                      tmpResidentFilter.withinWalkMinute === withinWalkMinute
                    }
                    onChange={() => {
                      setTmpResidentFilter({
                        ...tmpResidentFilter,
                        withinWalkMinute: withinWalkMinute,
                      });
                    }}
                  />
                );
              })}
              <RadioButton
                label="指定しない"
                checked={tmpResidentFilter.withinWalkMinute === undefined}
                onChange={() => {
                  setTmpResidentFilter({
                    ...tmpResidentFilter,
                    withinWalkMinute: undefined,
                  });
                }}
              />
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>住所</SectionTitle>
            <SectionInputArea>
              <TextField
                type="text"
                placeholder="住所を入力"
                onChange={(e) => {
                  setTmpResidentFilter({
                    ...tmpResidentFilter,
                    city: e.target.value,
                  });
                }}
                defaultValue={tmpResidentFilter.city}
              />
            </SectionInputArea>
          </Section>

          <Section>
            <SectionTitle>名前</SectionTitle>
            <SectionInputArea>
              <TextField
                type="text"
                placeholder="氏名を入力"
                onChange={(e) => {
                  setTmpResidentFilter({
                    ...tmpResidentFilter,
                    name: e.target.value,
                  });
                }}
                defaultValue={tmpResidentFilter?.name}
              />
            </SectionInputArea>
          </Section>

          <FilterButtonWrapper>
            <Button fill style={{ width: "100%" }} disabled={loading}>
              この条件で検索
            </Button>
          </FilterButtonWrapper>
        </FilterContainer>
      </form>
    </ResidentFilter>
  );
};

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const FilterContainer = styled.div`
  gap: 8px;
  padding: 16px;
  background-color: #fff;
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 20px;
  width: 100%;
`;

const SectionTitle = styled.p`
  font-weight: bold;
  font-size: 12px;
  width: 113px;
`;

const SectionInputArea = styled.p`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: auto;
`;

const FilterButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ResidentFilter = styled.details`
  & > summary > svg {
    transition: transform 0.2s;
  }
  &[open] > summary > svg {
    transform: rotate(180deg);
  }
`;

const ResidentFilterSummary = styled.summary`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 6px;
  list-style: none;
  ::-webkit-details-marker {
    display: none;
  }
`;
