import React, { useEffect } from "react";
import styled from "styled-components";
import { usePolyfitHistory } from "../../hooks/router";
import {
  useCurrentUser,
  useLoginUser,
  userState,
} from "../../hooks/recoil/user";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

export const PostNavigation = () => {
  const history = usePolyfitHistory();
  const currentUser = useCurrentUser();
  const location = useLocation();
  const { loginUser } = useLoginUser();
  const [, setUser] = useRecoilState(userState);
  useEffect(() => {
    if (!loginUser) return;
    if (currentUser.isApprover != loginUser.isApprover) {
      // Recoilの値とDBの値が違う場合、Recoilの値を更新する
      setUser({ ...currentUser, isApprover: loginUser.isApprover });
    }
  }, [loginUser]);

  return (
    <PostTabWrapper>
      <PostTab
        isActive={location.pathname == "/pta/post"}
        onClick={() => history.push({ to: "POST_PAGE" })}
      >
        配信済み
      </PostTab>
      <PostTab
        isActive={location.pathname == "/pta/post/me"}
        onClick={() => history.push({ to: "POST_ME_PAGE" })}
      >
        自分
      </PostTab>
      {currentUser.isApprover && (
        <>
          <PostTab
            isActive={location.pathname == "/pta/post/unapproved"}
            onClick={() => history.push({ to: "POST_UNAPPROVED_PAGE" })}
          >
            承認待ち
          </PostTab>
          <PostTab
            isActive={location.pathname == "/pta/post/undelivered"}
            onClick={() => history.push({ to: "POST_UNDELIVERED_PAGE" })}
          >
            投稿予約
          </PostTab>
        </>
      )}
    </PostTabWrapper>
  );
};

const PostTabWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  border-bottom: 1px solid #d3dae6;
`;

const PostTab = styled.div<{ isActive: boolean }>`
  ${(props) =>
    props.isActive && `color: #0077CC; border-bottom: 1px solid #0077CC`};
  padding: 10px;
  cursor: pointer;
`;

/**
 * 改行やる
 * @param {string} text
 * @param {boolean} breakText
 * @return {string}
 */
