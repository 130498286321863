import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { PostHeader } from "../../../features/Post/PostHeader";
import { NonPostElement } from "../PTAPersonalPage";
import { PostNavigation } from "../../../features/Post/Post";
import { PostCard } from "../../../features/Post/PostCard";
import { usePosts, PAGE_SIZE } from "../../../hooks/query/post/posts";
import { Spinner } from "../../../components/icons/Spinner";
import { useOrganization } from "../../../hooks/recoil/organization";
import { usePostUserGroupList } from "../../../hooks/recoil/postUserGroup";
import { ErrorView } from "src/components/ErrorView";
import { Button } from "../../../components/Button3";
import { TargetGradesType } from "@shared/types/post/api";

/**
 * 連絡ページ
 */
export default function PostPage() {
  const { organization, writeOrganization } = useOrganization({});
  const { getPostUserGroupLabel } = usePostUserGroupList();
  const {
    posts,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = usePosts();
  const [openPostId, setOpenPostId] = useState("");

  const refetchPosts = useCallback(async () => {
    refetch();
  }, [refetch]);

  const getPostBelongLabel = useCallback(
    (target: TargetGradesType | string) => {
      return (
        organization?.belongs?.find((belong) => belong.id === target)?.name ??
        "削除済み"
      );
    },
    [organization]
  );

  return (
    <>
      <PostHeader
        organization={organization}
        updateOrganization={writeOrganization}
        refetch={refetchPosts}
      />
      <PostNavigation />
      <Posts>
        {isLoading ? (
          <Card>
            <Spinner />
          </Card>
        ) : error ? (
          <ErrorView error={error} />
        ) : posts.length === 0 ? (
          <NonPostElement
            title="連絡がありません"
            message="新規作成から作成ができます"
          />
        ) : (
          <>
            {posts.map((post, i) => (
              <div
                key={post.id}
                onClick={() =>
                  setOpenPostId(openPostId !== post.id ? post.id : "")
                }
              >
                <PostCard
                  post={post}
                  isOpen={openPostId == post.id}
                  type="default"
                  getPostBelongLabel={getPostBelongLabel}
                  getPostUserGroupLabel={getPostUserGroupLabel}
                  refetch={refetchPosts}
                  borderBottom={i !== posts.length - 1}
                />
              </div>
            ))}
            {hasNextPage && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 16,
                  borderTop: "1px solid #d3dae6",
                }}
              >
                <Button
                  fill
                  size="large"
                  color="dark"
                  onClick={() => fetchNextPage()}
                  type="button"
                  disabled={isFetchingNextPage}
                >
                  {isFetchingNextPage
                    ? "読み込み中..."
                    : `次の${PAGE_SIZE}件を表示`}
                </Button>
              </div>
            )}
          </>
        )}
      </Posts>
    </>
  );
}

export const Posts = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 0px 0.8px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 17px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
`;

const Card = styled.div`
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
