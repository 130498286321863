import React, { memo } from "react";
import { RegisterStepper } from "../RegisterStepper";

type StepAdminProps = {
  stepNum: number;
};

export const StepAdmin = memo(({ stepNum }: StepAdminProps) => {
  return (
    <RegisterStepper
      count={5}
      current={stepNum}
      title="polyfitをより快適に使っていただくため、入力お願いします！"
    />
  );
});
