import { useCallback, useEffect, useState } from "react";
import { getRecruitmentTemplateList } from "../../apiClients/recruitmentTemplate";
import { RecruitmentTemplate } from "@shared/types/recruitmentTemplate";

export const useRecruitmentTemplateList = () => {
  const [recruitmentTemplates, setRecruitmentTemplates] = useState<
    RecruitmentTemplate[] | null
  >(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchRecruitment = useCallback(async () => {
    setLoading(true);
    const recruitments = await getRecruitmentTemplateList();
    setRecruitmentTemplates(recruitments);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchRecruitment();
  }, []);

  return { recruitmentTemplates, isLoading, refetch: fetchRecruitment };
};
