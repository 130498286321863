import React, { JSX, memo } from "react";
import {
  MINIMUM_NUMBER_OF_CHOICES,
  MAXIMUM_NUMBER_OF_CHOICES,
} from "../../../apiClients/survey";
import { EventHandlers } from "./eventHandlers";
import { CloseButton } from "./closeButton";
import { QuestionContainer, QuestionField } from "./Question";
import { Label } from "../../../components/form/Label";
import { ToggleQuestionTypeField } from "./ToggleQuestionTypeField";
import styled from "styled-components";
import { ChoiceOption } from "./ChoiceOption";
import { CheckBox } from "../../../components/form/CheckBox";
import { Choice, MultiChoiceQuestion } from "@shared/types/post/survey/survey";

type Props = {
  question: MultiChoiceQuestion;
  eventHandlers: EventHandlers;
  isDeletable: boolean;
};

export const MultiChoiceQuestionForm = memo(
  ({ question, eventHandlers, isDeletable }: Props): JSX.Element => {
    const { choiceList, weightsNumberOfChildren, allowMultipleAnswers } =
      question;
    // 選択肢が削除を許されているか
    const isDeletableChoice: boolean =
      choiceList.length > MINIMUM_NUMBER_OF_CHOICES;
    return (
      <QuestionContainer>
        {/* N.B. QuestionContainerがposition:relativeであること前提での右上バツ印 */}
        {isDeletable && (
          <CloseButton
            onClick={() => eventHandlers.handleDeleteQuestion(question)}
          />
        )}
        <QuestionField>
          <Label size="s" marginBottom={4}>
            質問の種類
          </Label>
          <ToggleQuestionTypeField
            question={question}
            eventHandlers={eventHandlers}
          />
        </QuestionField>
        <QuestionField>
          <Label size="s" marginBottom={4}>
            質問
          </Label>
          <TextField
            placeholder="例）次期PTA会長に推薦したい方を選択してください"
            value={question.text}
            onChange={(e) => {
              question.text = e.target.value;
              eventHandlers.handleEditQuestionText(question);
            }}
          />
        </QuestionField>
        <QuestionField>
          <Label size="s" marginBottom={4}>
            補足説明
          </Label>
          <TextField
            placeholder="例）他に推薦したい方がいればお知らせください"
            value={question.description}
            onChange={(e) => {
              question.description = e.target.value;
              eventHandlers.handleEditDescription(question);
            }}
          />
        </QuestionField>
        <QuestionField>
          <Label size="s" marginBottom={4}>
            選択肢(10個まで作成可能)
          </Label>
          <ChoiceOptionContainer>
            {choiceList.map((choice: Choice) => (
              <ChoiceOption
                key={choice.id}
                question={question}
                choice={choice}
                eventHandlers={eventHandlers}
                isDeletable={isDeletableChoice}
              />
            ))}
          </ChoiceOptionContainer>
        </QuestionField>
        {question.choiceList.length < MAXIMUM_NUMBER_OF_CHOICES && (
          <FlexEnd>
            <TextButton
              type="button"
              onClick={() => eventHandlers.handleAddChoice(question)}
            >
              + 選択肢を追加
            </TextButton>
          </FlexEnd>
        )}
        <QuestionField>
          <CheckBox
            defaultChecked={weightsNumberOfChildren}
            onChange={() => {
              // 変更された、フラグ反転する
              eventHandlers.handleEditWeightsNumberOfChildren(
                question,
                !weightsNumberOfChildren
              );
            }}
            label="複数の子供がいる場合、子供ごとに票を数える"
          />
        </QuestionField>
        <QuestionField>
          <CheckBox
            defaultChecked={allowMultipleAnswers}
            onChange={() => {
              // 変更された、フラグ反転する
              eventHandlers.handleEditAllowMultipleAnswers(
                question,
                !allowMultipleAnswers
              );
            }}
            label="複数の選択肢を選べるようにする"
          />
        </QuestionField>
      </QuestionContainer>
    );
  }
);

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  ::placeholder {
    color: #aab4c4;
    font-size: 14px;
  }
  &:disabled {
    opacity: 0.6;
  }
  -webkit-appearance: none;
`;

const ChoiceOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TextButton = styled.button`
  color: #005ec4;
  font-size: 14px;
  font-weight: normal;
  border-width: 0;
  background-color: inherit;
  cursor: pointer;
  width: fit-content;
`;

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;
