import React from "react";

type Props = {
  size: number;
};
export const SearchIcon = ({ size }: Props) => {
  const searchIconSvg = `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='${size}' height='${size}' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%23343741' d='m11.271 11.978 3.872 3.873a.502.502 0 0 0 .708 0 .502.502 0 0 0 0-.708l-3.565-3.564c2.38-2.747 2.267-6.923-.342-9.532-2.73-2.73-7.17-2.73-9.898 0-2.728 2.729-2.728 7.17 0 9.9a6.955 6.955 0 0 0 4.949 2.05.5.5 0 0 0 0-1 5.96 5.96 0 0 1-4.242-1.757 6.01 6.01 0 0 1 0-8.486 6.004 6.004 0 0 1 8.484 0 6.01 6.01 0 0 1 0 8.486.5.5 0 0 0 .034.738Z'/%3e%3c/svg%3e`;

  return <img src={searchIconSvg} alt="Search" />;
};
