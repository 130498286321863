import React, { useMemo } from "react";
import styled from "styled-components";
import { CameraIcon } from "../../../../components/icons/CameraIcon";
import {
  Control,
  FieldError,
  UseFormRegisterReturn,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { RecruitmentFormValue } from "./useRecruitmentForm";
import { TrashIcon } from "../../../../components/icons/TrashIcon2";

type RecruitmentImageFieldProps = {
  control: Control<RecruitmentFormValue>;
  error?: FieldError;
  register: UseFormRegisterReturn;
  setValue: UseFormSetValue<RecruitmentFormValue>;
};

export default function RecruitmentImageField({
  control,
  register,
  error,
  setValue,
}: RecruitmentImageFieldProps) {
  const uploadImage = useWatch({
    control,
    name: "uploadImage",
  });

  const getUrlFromUploadImage = (
    uploadImage: string | FileList | null | undefined
  ) => {
    if (typeof uploadImage === "string") {
      return uploadImage;
    }
    if (uploadImage && uploadImage.length) {
      return URL.createObjectURL(uploadImage[0]);
    }
  };

  const url = useMemo(() => getUrlFromUploadImage(uploadImage), [uploadImage]);

  const handleDeleteImage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setValue("uploadImage", null);
  };

  return (
    <>
      <ImageFieldArea>
        <FileInput type="file" accept="image/*" {...register} />
        <BackgroundImage src={url} />
        {uploadImage && uploadImage.length > 0 && (
          <DeleteButton
            onClick={(e) => {
              handleDeleteImage(e);
            }}
          >
            <TrashIcon />
          </DeleteButton>
        )}
        <ImageFieldLabel selectedImage={Boolean(url)}>
          <CameraIcon />
          <span>{url ? "画像を変更する" : "画像を追加しましょう"}</span>
        </ImageFieldLabel>
      </ImageFieldArea>
      <p className="error-message">{error?.message}</p>
    </>
  );
}

const ImageFieldArea = styled.label`
  display: block;
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 6px;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  cursor: pointer;
`;

const FileInput = styled.input`
  display: none;
`;

const BackgroundImage = styled.div<{
  src?: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 6px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 6px;
    z-index: ${(props) => (props.src ? 10 : -10)};
  }
`;

const ImageFieldLabel = styled.div<{
  selectedImage?: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  color: ${(props) =>
    props.selectedImage ? "rgba(255, 255, 255, 0.6)" : "#69707D"};
  z-index: 20;
`;

const DeleteButton = styled.button`
  z-index: 100;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 40px;
  height: 40px;
  border: 1px solid #e4e6f3;
  background: white;
  border-radius: 6px;
  cursor: pointer;
`;
