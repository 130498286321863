import { EmptyObject } from "react-hook-form";
import * as client from "../client";
import {
  BEAccount,
  UpdateCommunityRolesRequestForBE,
} from "@shared/types/boardEducation/auth";

/**
 * 現在の教育委員会アカウントを取得する
 */
export const getAccount = async (): Promise<BEAccount> => {
  const res = await client.get<{}, BEAccount>(
    "/boardEducation/auth/getAccount",
    {}
  );
  return res;
};

/**
 * 現在のRoleを更新する
 */
export const updateRole = async (
  accountId: string,
  role: string,
  currentCommunityId: string
) => {
  const res = await client.post<{
    accountId: string;
    role: string;
    currentCommunityId: string;
  }>("/boardEducation/auth/updateRole", {
    accountId,
    role,
    currentCommunityId,
  });
  return res;
};

/**
 * 名簿用の退会処理
 */
export async function deleteAccountById(
  accountId: string,
  currentCommunityId: string
): Promise<EmptyObject> {
  const data = { accountId, currentCommunityId };
  const res = await client.post("/boardEducation/auth/deleteAccount", { data });
  return res;
}

/**
 * 紐づくcommunitiesを更新する
 */
export const updateCommunityRoles = async (
  data: UpdateCommunityRolesRequestForBE
) => {
  const res = await client.post<UpdateCommunityRolesRequestForBE>(
    "/boardEducation/auth/updateCommunityRoles",
    data
  );
  return res;
};
