import React from "react";
import styled, { css } from "styled-components";
import { BREAKPOINTS } from "./Responsive";
import { Label } from "./form/Label";
import { applicationStatusOptions } from "../apiClients/recruitmentApplication";

type Props = {
  inputFilter: string;
  handleInputFilter: (filter: string) => void;
};

export const InputRecruitmentFilter = ({
  inputFilter,
  handleInputFilter,
}: Props) => {
  return (
    <FilterContainer>
      <InputMajorContainer>
        <InputContainer>
          <Label size="s">応募ステータス</Label>
          <InputSelect
            defaultValue=""
            onChange={(e) => handleInputFilter(e.target.value)}
            value={inputFilter}
          >
            <option value="">項目を選択</option>
            {applicationStatusOptions.map((opt) => {
              return (
                <option key={opt.value} value={opt.value}>
                  {opt.text}
                </option>
              );
            })}
          </InputSelect>
        </InputContainer>
      </InputMajorContainer>
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 24px;
  width: 100%;
  padding: 16px;
`;

const InputMajorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  margin-bottom: 16px;
  @media (max-width: ${BREAKPOINTS.SP}) {
    flex-direction: column;
  }
  max-width: 200px;
`;

const InputContainer = styled.div`
  flex: 1;
  min-width: 200px;
`;

const InputSelect = styled.select`
  ${(props) =>
    props.value === "" &&
    css`
      color: #aab4c4;
    `}
  ${(props) =>
    props.value !== "" &&
    css`
      color: #343741;
    `}
  appearance: none;
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
`;
