import React, { useState } from "react";
import { UseFormClearErrors, UseFormSetValue } from "react-hook-form";
import styled from "styled-components";
import { PulldownIcon } from "../../../components/icons/PulldownIcon";
import {
  FilterModal,
  FilterModalWrap,
} from "../../../components/ListPageFilterSetting";
import {
  BelongPartSelect as SelectInput,
  FormValues,
} from "./CareerInfoCreateModal";
import { zIndexes } from "../../../zIndex";

type ItemType = { id: string | null; name: string };

type Props = {
  childItems: ItemType[];
  formValues: FormValues;
  setFormValue: UseFormSetValue<FormValues>;
  defaultValue: ItemType | null;
  clearErrors: UseFormClearErrors<FormValues>;
};

export const SelectChildDropdown = ({
  childItems,
  setFormValue,
  defaultValue,
  clearErrors,
}: Props) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedChild, setSelectedChild] = useState<ItemType | null>(
    defaultValue
  );

  return (
    <>
      <SelectInput
        isOpen={isOpenDropdown}
        isEmpty={false}
        onClick={() => setIsOpenDropdown(!isOpenDropdown)}
      >
        <div style={{ height: "20px" }}>{selectedChild?.name ?? ""}</div>
        <span>
          <PulldownIcon size={40} />
        </span>
      </SelectInput>
      <div style={isOpenDropdown ? { height: childItems.length * 42 } : {}}>
        <SelectChildDropdownMenu
          childItems={childItems}
          setValue={setSelectedChild}
          value={selectedChild}
          isOpen={isOpenDropdown}
          close={() => setIsOpenDropdown(false)}
          setFormValue={setFormValue}
          clearErrors={clearErrors}
        />
      </div>
    </>
  );
};

type SelectChildDropdownMenuProps = Pick<Props, "childItems"> & {
  value: ItemType | null;
  isOpen?: boolean;
  setValue: (arg: ItemType) => void;
  close: () => void;
  setFormValue: UseFormSetValue<FormValues>;
  clearErrors: UseFormClearErrors<FormValues>;
};

const SelectChildDropdownMenu = ({
  childItems,
  isOpen,
  value,
  setValue,
  close,
  setFormValue,
  clearErrors,
}: SelectChildDropdownMenuProps) => {
  if (!isOpen) return null;

  const onChange = (childId: string | null, childName: string) => {
    setValue({
      id: childId,
      name: childName,
    });
    if (childId) {
      setFormValue("childId", childId);
      setFormValue("isGraduatedChild", false);
    } else {
      setFormValue("childId", null);
      setFormValue("isGraduatedChild", true);
    }
    clearErrors("childId");

    close();
  };

  return (
    <>
      <DropdownMenuWrapper>
        <DropdownMenu height={childItems.length * 38}>
          <DropdownMenuUl>
            {childItems.map((child, index) => (
              <DropdownMenuLi
                key={index}
                isLast={childItems.length - 1 == index}
              >
                <input
                  type="radio"
                  name={child.id ?? "child-id-null"}
                  id={child.id ?? "child-id-null"}
                  checked={!!value && child.id === value.id}
                  onChange={() => onChange(child.id, child.name)}
                />
                <DropdownMenuLabel htmlFor={child.id ?? "child-id-null"}>
                  {child.name}
                </DropdownMenuLabel>
              </DropdownMenuLi>
            ))}
          </DropdownMenuUl>
        </DropdownMenu>
      </DropdownMenuWrapper>
    </>
  );
};

export const DropdownMenuWrapper = styled(FilterModalWrap)`
  width: 100%;
  position: relative;
  z-index: ${zIndexes.modalMenu};
`;

export const DropdownMenu = styled(FilterModal)<{ height?: number }>`
  width: 100%;
  overflow: scroll;
  height: ${(props) => (props.height ? `${props.height}px` : "130px")};
  background: #ffffff;
  padding: 0;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

export const DropdownMenuUl = styled.ul`
  list-style: none;
  width: 100%;
`;

export const DropdownMenuLi = styled.li<{ isLast: boolean }>`
  list-style: none;
  width: 100%;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: left;
  border-bottom: ${(props) => (!props.isLast ? "1px solid #e7ebf1" : "")};

  &:hover {
    background-color: #006de41a;
  }
`;

export const DropdownMenuLabel = styled.label`
  font-size: 14px;
  width: 100%;
  display: inline-block;
`;

export const DropdownMenuOptionText = styled.span`
  margin-left: 5px;
`;

export const TextInput = styled.input`
  border: 1px solid #1322951a;
  height: 40px;
  width: 95%;
  padding: 8px 12px;
  border-radius: 6px;
  background: #fbfcfd;
  margin-left: 20px;
  margin-top: 5px;
`;
