import React, { useState } from "react";
import { useToast } from "../../../components/Toast";
import { ModalPortal, ModalBody } from "../../../components/Modal";
import { User } from "firebase/auth";
import {
  ModalWrapper,
  ModalHeader,
} from "../../../pages/pta/admin/ProfilePage";
import * as Margin from "../../../components/Margin";
import { Reauthentication } from "./Reauthentication";
import { linkWithGoogle } from "src/utils/firebase";
import { Button } from "src/components/Button";
import { Google } from "src/components/icons/SvgIcons";
import { FirebaseError } from "firebase/app";

interface Props {
  firebaseUser: User;
  isOpen: boolean;
  close: () => void;
}

export const LinkGoogleModal: React.FC<Props> = ({
  firebaseUser,
  isOpen,
  close,
}) => {
  const toast = useToast();
  const [reauthenticated, setReauthenticated] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);

  const handleClickLinkWithGoogle = async () => {
    if (isProcessing) return;

    try {
      setIsProcessing(true);
      await linkWithGoogle(firebaseUser);
      toast.success("Googleアカウントの連携に成功しました");
      close();
    } catch (err) {
      if (
        err instanceof FirebaseError &&
        err.code === "auth/credential-already-in-use"
      ) {
        toast.error("Googleアカウントが既に別のユーザーに紐づいています");
      } else {
        toast.error("Googleアカウントの連携に失敗しました");
      }
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={() => close()}>
          <ModalWrapper>
            <ModalHeader>Googleアカウントの連携</ModalHeader>
            {!reauthenticated ? (
              <Reauthentication
                title="Googleアカウントの連携には再認証が必要です"
                firebaseUser={firebaseUser}
                onCancel={close}
                onOk={() => setReauthenticated(true)}
              />
            ) : (
              <>
                <ModalBody>
                  <Margin.Margin marginTop={20} />
                  <div>
                    以下のボタンをクリックしてGoogleアカウントとの連携を行なってください
                  </div>
                  <Margin.Margin marginTop={40} />
                  <Button
                    onClick={handleClickLinkWithGoogle}
                    color={"subPrimary"}
                    fill={true}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Google />
                    Googleアカウントと連携
                  </Button>
                </ModalBody>
              </>
            )}
          </ModalWrapper>
        </ModalPortal>
      )}
    </>
  );
};
