import {
  auth,
  signInWithGoogle,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "../utils/firebase";

export type AuthUser = {
  email: string;
  name: string;
  uid: string;
};

export async function signInOrSignUpWithPopup(): Promise<AuthUser> {
  const userCredential = await signInWithGoogle();
  const user = userCredential.user;
  if (!user) throw new Error(`could'nt sign in`);

  return {
    email: user.email || "",
    name: user.displayName || "",
    uid: user.uid || "",
  };
}

export const signUpWithEmailAndPassword = async (
  email: string,
  password: string
): Promise<void> => {
  try {
    await createUserWithEmailAndPassword(email, password);
  } catch (e) {
    if (
      e instanceof Object &&
      "code" in e &&
      e.code === "auth/email-already-in-use"
    ) {
      // Firebaseに登録済みの場合は代わりにログインを行う
      await signInWithEmailAndPassword(email, password);
      return;
    }
    throw e;
  }
};

export const logout = async () => {
  await auth.signOut();
  localStorage.clear();
};
