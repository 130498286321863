import React from "react";

type Props = {
  size?: number;
};

export const FileIcon = ({ size = 240 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Illust">
        <path
          id="Rectangle 263"
          d="M182 200.501H58C57.1716 200.501 56.5 199.829 56.5 199.001V43.001C56.5 42.1725 57.1716 41.501 58 41.501H146.328C146.729 41.501 147.114 41.6617 147.395 41.9473L149.167 40.1986L147.395 41.9473L183.068 78.0888L184.847 76.3327L183.068 78.0888C183.345 78.3696 183.5 78.7481 183.5 79.1425V199.001C183.5 199.829 182.828 200.501 182 200.501Z"
          fill="#DEEFFF"
          stroke="#37434F"
          strokeWidth="5"
        />
        <rect
          id="Rectangle 264"
          x="84"
          y="81.001"
          width="49"
          height="8"
          rx="4"
          fill="#37434F"
        />
        <rect
          id="Rectangle 265"
          x="84"
          y="105.001"
          width="72"
          height="8"
          rx="4"
          fill="#37434F"
        />
        <rect
          id="Rectangle 266"
          x="84"
          y="129.001"
          width="72"
          height="8"
          rx="4"
          fill="#37434F"
        />
        <rect
          id="Rectangle 267"
          x="84"
          y="153.001"
          width="72"
          height="8"
          rx="4"
          fill="#37434F"
        />
        <path
          id="Star 3"
          d="M40 194.133L42.5697 198.971C42.8588 199.515 43.3826 199.896 43.9896 200.003L49.3851 200.952L45.5778 204.891C45.1494 205.334 44.9493 205.95 45.0354 206.56L45.8003 211.984L40.8776 209.581C40.3238 209.31 39.6762 209.31 39.1224 209.581L34.1997 211.984L34.9646 206.56C35.0507 205.95 34.8506 205.334 34.4222 204.891L30.6149 200.952L36.0104 200.003C36.6174 199.896 37.1412 199.515 37.4303 198.971L40 194.133Z"
          fill="white"
          stroke="#37434F"
          strokeWidth="2"
        />
        <path
          id="Star 4"
          d="M199 42.1329L202.49 48.7042C202.779 49.2485 203.303 49.6291 203.91 49.7358L211.238 51.0245L206.067 56.3745C205.639 56.8176 205.439 57.4335 205.525 58.0437L206.564 65.4115L199.878 62.1466C199.324 61.8762 198.676 61.8762 198.122 62.1466L191.436 65.4115L192.475 58.0437C192.561 57.4335 192.361 56.8176 191.933 56.3745L186.762 51.0245L194.09 49.7358C194.697 49.6291 195.221 49.2485 195.51 48.7042L199 42.1329Z"
          fill="white"
          stroke="#37434F"
          strokeWidth="2"
        />
        <path
          id="Vector 26"
          d="M178 76.501L148.5 46.501V74.501C148.5 75.6055 149.395 76.501 150.5 76.501H178Z"
          fill="white"
        />
        <path
          id="Rectangle 273"
          d="M154 81.501V86.501L181 82.001V81.501H154Z"
          fill="#8AC6FF"
        />
        <path
          id="Rectangle 271"
          d="M59 44.001H60.5L67.5 198.001H59V44.001Z"
          fill="white"
        />
        <path
          id="Vector 27"
          d="M146 44.001V74.001C146 76.7624 148.239 79.001 151 79.001H181"
          stroke="#37434F"
          strokeWidth="5"
        />
      </g>
    </svg>
  );
};
