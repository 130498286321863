import Quill from "quill";

const Inline = Quill.import("blots/inline");
const BackgroundStyle = Quill.import("attributors/style/background");
const ColorStyle = Quill.import("attributors/style/color");
const SizeStyle = Quill.import("attributors/style/size");

class ItalicBlot extends Inline {
  static create() {
    const node = super.create();
    node.style.fontStyle = "italic";
    return node;
  }

  static formats(node: HTMLElement) {
    return node.style.fontStyle === "italic";
  }
}
ItalicBlot.blotName = "italic";
ItalicBlot.tagName = "i";

// サイズスタイルのホワイトリスト設定
SizeStyle.whitelist = ["12px", "16px", "18px", "32px"];

export { ItalicBlot, BackgroundStyle, ColorStyle, SizeStyle };
