import { ResidentInfo } from "../apiClients/auth";

// ResidentInfoが空かどうかを判定する
export const isResidentInfoEmpty = ({
  preferredDays,
  teacherLicenses,
  medicalLicenses,
  skills,
  preferredPaid,
  preferredVolunteerType,
  privateEnterprise,
  university,
  externalOrganization,
  pta,
}: ResidentInfo): boolean => {
  return (
    preferredDays.length === 0 &&
    teacherLicenses.length === 0 &&
    medicalLicenses.length === 0 &&
    skills.length === 0 &&
    preferredPaid.length === 0 &&
    preferredVolunteerType.length === 0 &&
    privateEnterprise == null &&
    university == null &&
    externalOrganization == null &&
    pta == null
  );
};
