import React from "react";

export const InviteIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_2505_79259" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7059 14.2683C18.2014 12.9852 19.1457 11.1007 19.1457 9C19.1457 5.13401 15.9472 2 12.0016 2C8.05606 2 4.85753 5.13401 4.85753 9C4.85753 11.1007 5.80195 12.9853 7.29746 14.2684C5.17038 15.5136 3.60024 17.5776 3.04037 20.0163C2.79321 21.0928 3.71007 22 4.81464 22H12.0018H19.1889C20.2935 22 21.2104 21.0928 20.9632 20.0163C20.4033 17.5776 18.8331 15.5135 16.7059 14.2683Z"
        />
      </mask>
      <path
        d="M16.7059 14.2683L15.7292 13.1299L14.1324 14.4999L15.9482 15.5628L16.7059 14.2683ZM7.29746 14.2684L8.05528 15.5629L9.871 14.4999L8.27418 13.1299L7.29746 14.2684ZM3.04037 20.0163L4.50234 20.3519H4.50234L3.04037 20.0163ZM20.9632 20.0163L19.5013 20.3519L20.9632 20.0163ZM17.6457 9C17.6457 10.6389 16.9112 12.1157 15.7292 13.1299L17.6827 15.4067C19.4915 13.8547 20.6457 11.5624 20.6457 9H17.6457ZM12.0016 3.5C15.1478 3.5 17.6457 5.9911 17.6457 9H20.6457C20.6457 4.27691 16.7467 0.5 12.0016 0.5V3.5ZM6.35753 9C6.35753 5.9911 8.85552 3.5 12.0016 3.5V0.5C7.25659 0.5 3.35753 4.27691 3.35753 9H6.35753ZM8.27418 13.1299C7.09209 12.1158 6.35753 10.639 6.35753 9H3.35753C3.35753 11.5625 4.51181 13.8548 6.32075 15.4068L8.27418 13.1299ZM4.50234 20.3519C4.96607 18.332 6.27086 16.6075 8.05528 15.5629L6.53965 12.9739C4.06991 14.4197 2.23441 16.8232 1.5784 19.6806L4.50234 20.3519ZM4.81464 20.5C4.66722 20.5 4.56335 20.438 4.51852 20.3898C4.49889 20.3687 4.49738 20.3582 4.49882 20.3632C4.50139 20.3721 4.49757 20.3727 4.50234 20.3519L1.5784 19.6806C1.06195 21.9302 3.00089 23.5 4.81464 23.5V20.5ZM12.0018 20.5H4.81464V23.5H12.0018V20.5ZM19.1889 20.5H12.0018V23.5H19.1889V20.5ZM19.5013 20.3519C19.506 20.3727 19.5022 20.3721 19.5048 20.3632C19.5062 20.3582 19.5047 20.3687 19.4851 20.3898C19.4402 20.438 19.3364 20.5 19.1889 20.5V23.5C21.0027 23.5 22.9416 21.9302 22.4252 19.6806L19.5013 20.3519ZM15.9482 15.5628C17.7326 16.6074 19.0375 18.332 19.5013 20.3519L22.4252 19.6806C21.7692 16.8232 19.9336 14.4196 17.4637 12.9738L15.9482 15.5628Z"
        fill="#37434F"
        mask="url(#path-1-inside-1_2505_79259)"
      />
      <path
        d="M9 7.8987C7.82843 8.12663 7.17157 7.67077 6 7.8987L7.5 4.98048L12.0004 3L16.5 4.98048L18 7.8987C18 7.8987 16.1716 8.12663 15 7.8987C13.8284 7.67077 12 6.14777 12 6.14777C12 6.14777 10.1716 7.67077 9 7.8987Z"
        fill="#37434F"
        stroke="#37434F"
        strokeWidth="1.2"
      />
      <circle cx="9.9" cy="9.9" r="0.9" fill="#37434F" />
      <circle cx="14.0997" cy="9.9" r="0.9" fill="#37434F" />
      <circle cx="17.5" cy="18.5" r="4.5" fill="#37434F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 20.5C17 20.7761 17.2239 21 17.5 21C17.7761 21 18 20.7761 18 20.5V19H19.5C19.7761 19 20 18.7761 20 18.5C20 18.2239 19.7761 18 19.5 18H18V16.5C18 16.2239 17.7761 16 17.5 16C17.2239 16 17 16.2239 17 16.5V18H15.5C15.2239 18 15 18.2239 15 18.5C15 18.7761 15.2239 19 15.5 19H17V20.5Z"
        fill="white"
      />
    </svg>
  );
};
