import React, { FC } from "react";
import styled from "styled-components";
import * as theme from "../theme";
import * as Responsive from "./Responsive";
import * as Typo from "./Typo";
import { BackIcon } from "./icons/SvgIcons";
import { Link } from "react-router-dom";
import * as styles from "./Page.css";

export const PageContainer = styled.div`
  /* padding-top: 10px; */
`;

export type PageHeaderProps = {
  backTo?: string;
  children: React.ReactNode;
};
export const PageHeader: FC<PageHeaderProps> = ({ backTo, children }) => {
  return (
    <Responsive.Row>
      <Responsive.Col>
        <Typo.Heading1 style={{ fontSize: "24px" }}>
          <Flex>
            {backTo && (
              <Link to={backTo} className={styles.PageHeaderBack}>
                <BackIcon size="34px" />
              </Link>
            )}
            {children}
          </Flex>
        </Typo.Heading1>
      </Responsive.Col>
    </Responsive.Row>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * @deprecated
 */
export const PageHeaderTitle = styled.div`
  font-size: ${theme.typo.heading1};
  font-weight: 700;
`;

// FIXME: do not use "export const" with styled-components
export const PageBody = styled.div`
  margin-top: 24px;
`;
