import React from "react";
import styled from "styled-components";
import { Margin } from "../../components/Margin";
import { Child } from "../../hooks/api/child";
import { getClassName } from "../../utils/getChildClass";

interface Props {
  currentChildren: Child[];
}

const SignUpPreviewMemberChildInfo = ({ currentChildren }: Props) => {
  return (
    <>
      {currentChildren.map((child: Child, i) => (
        <>
          <ProfileLabel key={i}>子どもの名前({i + 1}人目)</ProfileLabel>
          <ProfileContent>
            {child.childLastName}
            {child.childFirstName}({child.childLastNameKana}
            {child.childFirstNameKana})
          </ProfileContent>
          <Margin marginBottom={32} />
          <ProfileLabel key={i}>学年とクラス({i + 1}人目)</ProfileLabel>
          <ProfileContent>
            {child.grade}年 {getClassName(child.class, true)}
          </ProfileContent>
          <Margin marginBottom={32} />
        </>
      ))}
    </>
  );
};

const ProfileLabel = styled.p`
  color: #343741;
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: bold;
`;

const ProfileContent = styled.p`
  color: #343741;
  font-size: 18px;
`;
export default SignUpPreviewMemberChildInfo;
