import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  PageContainer,
  PageHeader,
  PageHeaderTitle,
  PageBody,
} from "../../../components/Page";
import { usePolyfitHistory } from "../../../hooks/router";
import { useLoginUser } from "../../../hooks/recoil/user";
import { useOrganizations } from "../../../hooks/api/internal/organizations";
import { ServiceType } from "../../../utils/types/serviceType";

export default function InvitationListPage() {
  const history = usePolyfitHistory();
  const { organizations, createOrganization } = useOrganizations();
  const [serviceType, setServiceType] = React.useState<ServiceType>("PTA");
  const filteredOrganizations = useMemo(() => {
    return organizations
      .filter((organization) => organization.serviceType === serviceType)
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
  }, [organizations, serviceType]);
  const { loginUser, fetchLoginUser } = useLoginUser();
  const [isInternalAdminUser, setIsInternalAdminUser] =
    useState<boolean>(false);

  useEffect(() => {
    fetchLoginUser();
  }, []);

  /** INTERNAL_ADMIN以外のユーザーのアクセスを弾く */
  useEffect(() => {
    if (typeof loginUser === "undefined") return;

    setIsInternalAdminUser(true);

    return () => {
      setIsInternalAdminUser(false);
    };
  }, [loginUser]);

  const createInvitationLink = useCallback(async () => {
    createOrganization({
      communityIds: [],
      name: "",
      serviceType,
    });
  }, [serviceType]);

  return (
    <PageContainer>
      <PageHeader>
        <PageHeaderTitle>INTERNAL ADMIN / Organization list</PageHeaderTitle>
      </PageHeader>
      <a
        style={{
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => {
          history.push({
            to: "INTERNAL_ORGANIZATION_LIST",
          });
        }}
      >
        組織一覧はこちら
      </a>
      <div>
        <button
          onClick={() => {
            setServiceType("PTA");
          }}
        >
          PTA組織
        </button>
        <button
          onClick={() => {
            setServiceType("CS");
          }}
        >
          CS組織
        </button>
      </div>
      <h3>{serviceType}組織一覧</h3>
      <PageBody>
        <div>
          <button
            onClick={createInvitationLink}
            disabled={!isInternalAdminUser}
          >
            create {serviceType} invitation link
          </button>
        </div>
        <br />
        <ul>
          {filteredOrganizations?.map((organization, index) => {
            return (
              <li key={index}>
                <a
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push({
                      to: "INTERNAL_ORGANIZATION_INVITATION",
                      query: { organizationId: organization.id },
                    });
                  }}
                  rel="noreferrer"
                >
                  generate invitation link of {organization.name || "unknown"} -{" "}
                  {organization.id}
                </a>
              </li>
            );
          })}
        </ul>
      </PageBody>
    </PageContainer>
  );
}
