import React, { useState } from "react";
import styled from "styled-components";
import { CloseIcon } from "./icons/CloseIcon";
import { Button } from "./Button";
import { Margin } from "./Margin";
import { TextField } from "src/components/form/TextField";
import { SearchIcon } from "./icons/SearchIcon";

export type Member = {
  accountId: string;
  name: string;
  firstNameKana: string;
  lastNameKana: string;
};

type Props = {
  members: Member[];
  selectedMemberIds: string[];
  setSelectedMemberIds: (memberIds: string[]) => void;
};

export const SearchMembersField = ({
  members,
  selectedMemberIds,
  setSelectedMemberIds,
}: Props) => {
  const [searchText, setSearchText] = useState<string>("");
  const [predictions, setPredictions] = useState<Member[]>([]);

  const onChange = (_searchText: string) => {
    setSearchText(_searchText);

    if (_searchText === "") {
      setPredictions([]);
      return;
    }

    // 検索処理
    const filteredTargets = members.filter((member) => {
      const name = member.name;
      const nameKana = member.lastNameKana + member.firstNameKana;
      return nameKana.includes(_searchText) || name.includes(_searchText);
    });
    setPredictions(filteredTargets);
  };

  const handlePredictions = (target: Member) => {
    if (selectedMemberIds.includes(target.accountId)) {
      setSelectedMemberIds(
        selectedMemberIds.filter((id) => id !== target.accountId)
      );
    } else {
      setSelectedMemberIds([...selectedMemberIds, target.accountId]);
    }
  };

  return (
    <Container>
      <TextField
        value={searchText}
        onChange={onChange}
        placeholder={"メンバーを検索"}
        prepend={<SearchIcon size={20} />}
      />

      {/* 宛先候補のドロップダウン */}
      {predictions.length > 0 && (
        <PredictionsWrapper>
          <PredictionsList>
            {predictions.map((target, index) => {
              return (
                <PredictionsListItem key={index}>
                  <ItemButton
                    type="button"
                    onClick={() => handlePredictions(target)}
                  >
                    <input
                      type="checkbox"
                      value={target.accountId}
                      checked={selectedMemberIds.includes(
                        target.accountId || ""
                      )}
                      onChange={() => handlePredictions(target)}
                    />
                    {target.name}
                  </ItemButton>
                </PredictionsListItem>
              );
            })}
          </PredictionsList>
        </PredictionsWrapper>
      )}
      <Margin marginTop={20} />

      {/* 選択したメンバー */}
      {selectedMemberIds.length > 0 && (
        <SelectedValuesList>
          <Divider />
          <SubTitle>選択中のメンバー</SubTitle>
          <Margin marginBottom={10} />
          <BadgeWrapper>
            {selectedMemberIds.map((memberId, index) => {
              const member = members.find(
                (member) => member.accountId === memberId
              );
              if (!member) return null;
              return (
                <Badge key={index}>
                  {member.name}
                  <RemoveButton
                    onClick={() =>
                      setSelectedMemberIds(
                        selectedMemberIds.filter((id) => id !== memberId)
                      )
                    }
                  >
                    <CloseIcon />
                  </RemoveButton>
                </Badge>
              );
            })}
          </BadgeWrapper>
        </SelectedValuesList>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  overflow-y: visible;
`;

// 予測値のリスト
const PredictionsWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 150px;
  z-index: 99;
`;

const PredictionsList = styled.ul`
  width: 100%;
  background: white;
  list-style: none;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const PredictionsListItem = styled.li`
  padding: 16px;
  padding-bottom: 0;
  width: 100%;

  &:last-of-type {
    padding-bottom: 16px;
  }
`;

const ItemButton = styled.button`
  background: white;
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  text-align: left;
  color: #343741;

  &:hover {
    opacity: 0.6;
  }
`;

// 選択した値のリスト
const SelectedValuesList = styled.ul`
  list-style: none;
`;

const SubTitle = styled.p`
  font-size: 12px;
`;

const Divider = styled.div`
  width: 100%;
  border: 0.75px solid #f0f2f5;
  margin: 10px 0px;
`;

const BadgeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const Badge = styled.div`
  width: fit-content;
  border: 1px solid #0071c2;
  border-radius: 36px;
  background: #e6f1fa;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  gap: 4px;
  font-size: 12px;
  margin-right: 8px;
`;

const RemoveButton = styled(Button)`
  width: 16px;
  height: 16px;
  color: #aab4c4;
  background-color: transparent;
  padding: 0;
`;
