import React from "react";
import styled from "styled-components";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { PTAAdminSidebar } from "./PTAAdminSidebar";
import { PTAMemberSidebar } from "./PTAMemberSidebar";
import { InternalAdminSidebar } from "./InternalAdminSidebar";
import { CSAdminSidebar } from "./CSAdminSidebar";
import { CSMemberSidebar } from "./CSMemberSidebar";
import { CSOperatorSidebar } from "./CSOperatorSidebar";
import { BEAdminSidebar } from "./BEAdminSidebar";
import { CSGuestSidebar } from "./CSGuestSidebar";
import { zIndexes } from "../../../zIndex";
import {
  isBeAdminRole,
  isCsAdminRole,
  isCsGuestRole,
  isCsMemberRole,
  isCsOperatorRole,
  isPtaAdminRole,
  isPtaMemberRole,
} from "../../../utils/types/role";

export const SideBar = () => {
  return (
    <SideBarWrapper>
      <SidebarContent />
    </SideBarWrapper>
  );
};

const SideBarWrapper = styled.div`
  z-index: ${zIndexes.sidebar};
  width: 160px;
  height: 100%;
  padding: 24px 0;
  background: #ffffff;
  position: fixed;
  top: 48px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const SidebarContent = () => {
  const currentUser = useCurrentUser();

  const isPTAAdmin = isPtaAdminRole(currentUser.role);
  const isPTAMember = isPtaMemberRole(currentUser.role);
  const isCSAdmin = isCsAdminRole(currentUser.role);
  const isCSMember = isCsMemberRole(currentUser.role);
  const isCSOperator = isCsOperatorRole(currentUser.role);
  const isCSGuest = isCsGuestRole(currentUser.role);
  const isBEAdmin = isBeAdminRole(currentUser.role);
  const isInternalAdmin = currentUser.internalRole === "INTERNAL_ADMIN";

  return (
    <>
      {isPTAAdmin && <PTAAdminSidebar />}
      {isPTAMember && <PTAMemberSidebar />}
      {isCSAdmin && <CSAdminSidebar />}
      {isCSMember && <CSMemberSidebar />}
      {isCSOperator && <CSOperatorSidebar />}
      {isCSGuest && <CSGuestSidebar />}
      {isBEAdmin && <BEAdminSidebar />}
      {isInternalAdmin && <InternalAdminSidebar />}
    </>
  );
};
