import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Heading2 } from "../../../components/Typo";
import {
  PCBaseInfoWrapper,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledTr,
  EditLinkContainer,
  EditBaseInfoIcon,
} from "../../../pages/pta/admin/ProfilePage";
import { PencilIcon } from "../../../components/icons/PencilIcon";
import ResidentInfoBelongOrganizationEditModal from "./ResidentInfoBelongOrganizationEditModal";
import { useResidentInfo } from "../../../hooks/api/residentInfo";

const ResidentInfoBelongOrganizationContent = () => {
  const { isLoading, currentResidentInfo, refetchResidentInfo } =
    useResidentInfo();
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  return (
    <>
      <PCBaseInfoWrapper>
        <StyledFlexContainer>
          {isLoading ? (
            <>読み込み中…</>
          ) : (
            <div>
              <StyledContainerPc>
                <Title>所属団体</Title>

                <StyledTable>
                  <StyledTr>
                    <StyledTh>民間企業</StyledTh>
                    <StyledTd>
                      {currentResidentInfo?.privateEnterprise ?? "該当なし"}
                    </StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <StyledTh>大学</StyledTh>
                    <StyledTd>
                      {currentResidentInfo?.university ?? "該当なし"}
                    </StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <StyledTh>外郭団体</StyledTh>
                    <StyledTd>
                      {currentResidentInfo?.externalOrganization ?? "該当なし"}
                    </StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <StyledTh>PTA</StyledTh>
                    <StyledTd>
                      {currentResidentInfo?.pta ?? "該当なし"}
                    </StyledTd>
                  </StyledTr>
                </StyledTable>
              </StyledContainerPc>

              <StyledContainerSp>
                <Title>所属団体</Title>
                <StyledTableSp>
                  <StyledTr>
                    <StyledTHSp>民間企業</StyledTHSp>
                    <StyledTdSp>
                      {currentResidentInfo?.privateEnterprise ?? ""}
                    </StyledTdSp>
                  </StyledTr>
                  <StyledTr>
                    <StyledTHSp>大学</StyledTHSp>
                    <StyledTdSp>
                      {currentResidentInfo?.university ?? ""}
                    </StyledTdSp>
                  </StyledTr>
                  <StyledTr>
                    <StyledTHSp>外郭団体</StyledTHSp>
                    <StyledTdSp>
                      {currentResidentInfo?.externalOrganization ?? ""}
                    </StyledTdSp>
                  </StyledTr>
                  <StyledTr>
                    <StyledTHSp>PTA</StyledTHSp>
                    <StyledTdSp>{currentResidentInfo?.pta ?? ""}</StyledTdSp>
                  </StyledTr>
                </StyledTableSp>
              </StyledContainerSp>
            </div>
          )}

          {!isLoading && (
            <EditLinkContainer onClick={onOpen}>
              <PencilIcon />
              <EditBaseInfoIcon>編集</EditBaseInfoIcon>
            </EditLinkContainer>
          )}

          {isOpen && currentResidentInfo && (
            <ResidentInfoBelongOrganizationEditModal
              onClose={() => setIsOpen(false)}
              residentInfo={{
                privateEnterprise: currentResidentInfo.privateEnterprise,
                university: currentResidentInfo.university,
                externalOrganization: currentResidentInfo.externalOrganization,
                pta: currentResidentInfo.pta,
              }}
              fetch={refetchResidentInfo}
            />
          )}
        </StyledFlexContainer>
      </PCBaseInfoWrapper>
    </>
  );
};

export default ResidentInfoBelongOrganizationContent;

const StyledContainerPc = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;
const StyledContainerSp = styled.div`
  @media (min-width: 500px) {
    display: none;
  }
`;

const StyledTableSp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`;

export const PartnerInfoContainer = styled.div`
  width: 100%;

  @media (max-width: 1025px) {
    width: 100%;
  }
`;

const StyledFlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 480px) {
    display: block;
  }
`;

const StyledTHSp = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const StyledTdSp = styled.p`
  font-size: 12px;
  word-break: break-all;
`;

export const Title = styled(Heading2)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #343741;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-size: 14px;
  color: #0077cc;
  padding: 5px;
`;
