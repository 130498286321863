import * as client from "./client";
import { ChildrenFilterType } from "../hooks/api/child";
import { Child } from "./child";

export async function exportCsv(
  type: "PARENT" | "TEACHER",
  q?: string,
  filter?: ChildrenFilterType
): Promise<Blob> {
  const filterQuery = filter ? JSON.stringify(filter) : undefined;
  const res = await client.getBlob<
    { type: "PARENT" | "TEACHER"; q?: string; filter?: string },
    Child[]
  >("/csv/exportCsv", {
    type,
    ...(q && { q }),
    ...(filterQuery && { filter: filterQuery }),
  });
  return res;
}

export async function exportCsvByInternalAdmin(
  organizationId: string
): Promise<Blob> {
  const res = await client.getBlob("/csv/exportCsvByInternalAdmin", {
    organizationId,
  });
  return res;
}

export async function bulkUpdateByCsvFile({
  csvData,
  organizationId,
}: {
  csvData: string[][];
  organizationId: string;
}) {
  const res = await client.post<
    { csv: string[][]; organizationId: string },
    { status: "success" | "failed"; message?: string }
  >("/csv/bulkUpdateByCsv", {
    csv: csvData,
    organizationId: organizationId,
  });

  return res;
}
