import { useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import * as organizationApi from "src/apiClients/organization";
import { QueryOptions } from "src/hooks/query/QueryOptions";

export function useGetOrganizations(queryOptions: QueryOptions = {}) {
  const query = useQuery({
    queryKey: ["api", "organization", "getOrganizations"],
    queryFn: organizationApi.getOrganizations,
    ...queryOptions,
  });

  return {
    organizations: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
}

export function useInvalidateGetOrganizations() {
  const queryClient = useQueryClient();

  function invalidateGetOrganizations() {
    queryClient.invalidateQueries({
      queryKey: ["api", "organization", "getOrganizations"],
    });
  }

  return {
    invalidateGetOrganizations,
  };
}
