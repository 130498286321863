import React, { Dispatch, useState } from "react";
import styled from "styled-components";
import { Margin } from "../Margin";
import { CloseIcon } from "../icons/CloseIcon";
import { Button } from "../Button";
import { ServiceType } from "../../utils/types/serviceType";
import { Spinner } from "../icons/Spinner";
import { Community } from "@shared/types/community";
import { useGetCommunities } from "src/hooks/query/communityList";
import { useGetCommunityById } from "src/hooks/query/communityById";

type Props = {
  serviceType: ServiceType;
  selectedCommunityIds: string[];
  setSelectedCommunityIds: Dispatch<React.SetStateAction<string[]>>;
};

export const SearchCommunity = ({
  serviceType,
  selectedCommunityIds,
  setSelectedCommunityIds,
}: Props) => {
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const { communities, isLoading, refetch } = useGetCommunities({
    searchText,
    take: 200,
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsShowDropdown(true);
    const searchText = e.target.value;
    setSearchText(searchText);
    refetch();
  };

  const handlePredictions = (target: Community) => {
    if (serviceType === "PTA") {
      setSelectedCommunityIds([target.id]);
    } else {
      if (selectedCommunityIds.includes(target.id)) {
        setSelectedCommunityIds(
          selectedCommunityIds.filter((id) => id !== target.id)
        );
      } else {
        setSelectedCommunityIds([...selectedCommunityIds, target.id]);
      }
    }
  };

  return (
    <Container>
      <TextFieldLabel htmlFor="search-text-field" />
      <TextField
        id="search-text-field"
        onChange={onChange}
        type="text"
        placeholder={"コミュニティを検索"}
        onClick={() => setIsShowDropdown(!isShowDropdown)}
      />

      {/* 宛先候補のドロップダウン */}
      {isShowDropdown &&
        (isLoading ? (
          <PredictionsWrapper>
            <PredictionsList>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                }}
              >
                <Spinner />
              </div>
            </PredictionsList>
          </PredictionsWrapper>
        ) : (
          <PredictionsWrapper>
            <PredictionsList>
              {communities.map((target, index) => {
                return (
                  <PredictionsListItem key={index}>
                    <ItemButton
                      type="button"
                      onClick={() => handlePredictions(target)}
                    >
                      <input
                        type={serviceType === "PTA" ? "radio" : "checkbox"}
                        value={target.name}
                        checked={selectedCommunityIds.includes(target.id || "")}
                        onChange={() => handlePredictions(target)}
                      />
                      {target.name}
                    </ItemButton>
                  </PredictionsListItem>
                );
              })}
            </PredictionsList>
          </PredictionsWrapper>
        ))}
      <Margin marginTop={20} />

      {/* 選択したメンバー */}
      {selectedCommunityIds.length > 0 && (
        <SelectedValuesList>
          <Divider />
          <SubTitle>選択中のコミュニティ</SubTitle>
          <Margin marginBottom={10} />
          <BadgeWrapper>
            {selectedCommunityIds.map((communityId, index) => {
              return (
                <BadgeComponent
                  key={index}
                  communityId={communityId}
                  onClick={() =>
                    setSelectedCommunityIds(
                      selectedCommunityIds.filter((id) => id !== communityId)
                    )
                  }
                ></BadgeComponent>
              );
            })}
          </BadgeWrapper>
        </SelectedValuesList>
      )}
    </Container>
  );
};

function BadgeComponent({
  communityId,
  onClick,
}: {
  communityId: string;
  onClick: () => void;
}) {
  const { community, isLoading } = useGetCommunityById(communityId);

  if (isLoading || !community) return null;

  return (
    <Badge>
      {community.name}
      <RemoveButton type="button" onClick={onClick}>
        <CloseIcon />
      </RemoveButton>
    </Badge>
  );
}

const Container = styled.div`
  position: relative;
  overflow-y: visible;
`;

const TextFieldLabel = styled.label`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%23343741' d='m11.271 11.978 3.872 3.873a.502.502 0 0 0 .708 0 .502.502 0 0 0 0-.708l-3.565-3.564c2.38-2.747 2.267-6.923-.342-9.532-2.73-2.73-7.17-2.73-9.898 0-2.728 2.729-2.728 7.17 0 9.9a6.955 6.955 0 0 0 4.949 2.05.5.5 0 0 0 0-1 5.96 5.96 0 0 1-4.242-1.757 6.01 6.01 0 0 1 0-8.486 6.004 6.004 0 0 1 8.484 0 6.01 6.01 0 0 1 0 8.486.5.5 0 0 0 .034.738Z'/%3e%3c/svg%3e")
      center / contain no-repeat;
  }
`;

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  padding-left: 40px;
  ::placeholder {
    color: #aab4c4;
  }
`;

// 予測値のリスト
const PredictionsWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 150px;
  z-index: 99;
`;

const PredictionsList = styled.ul`
  width: 100%;
  background: white;
  list-style: none;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const PredictionsListItem = styled.li`
  padding: 16px;
  padding-bottom: 0;
  width: 100%;

  &:last-of-type {
    padding-bottom: 16px;
  }
`;

const ItemButton = styled.button`
  background: white;
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  text-align: left;
  color: #343741;

  &:hover {
    opacity: 0.6;
  }
`;

// 選択した値のリスト
const SelectedValuesList = styled.ul`
  list-style: none;
`;

const SubTitle = styled.p`
  font-size: 12px;
`;

const Divider = styled.div`
  width: 100%;
  border: 0.75px solid #f0f2f5;
  margin: 10px 0px;
`;

const BadgeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const Badge = styled.div`
  width: fit-content;
  border: 1px solid #0071c2;
  border-radius: 36px;
  background: #e6f1fa;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  gap: 4px;
  font-size: 12px;
  margin-right: 8px;
`;

const RemoveButton = styled(Button)`
  width: 16px;
  height: 16px;
  color: #aab4c4;
  background-color: transparent;
  padding: 0;
`;
