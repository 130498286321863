import React from "react";
import DOMPurify from "dompurify";
import styles from "./HTMLViewer.module.css";

type HtmlViewerProps = {
  htmlContent: string;
};

const HtmlViewer = ({ htmlContent }: HtmlViewerProps) => {
  // HTMLコンテンツをサニタイズ
  const sanitizedHtml = DOMPurify.sanitize(htmlContent, {
    FORBID_TAGS: ["script"],
  });

  return (
    <div
      className={styles.htmlViewer}
      // サニタイズされたHTMLを安全に表示
      dangerouslySetInnerHTML={{ __html: sanitizedHtml.replaceAll("\\n", "") }}
    />
  );
};

export default HtmlViewer;
