import React, { useCallback } from "react";
import styled from "styled-components";
import type { SystemMessageItem } from "src/features/common/ChatMessage/types";

type Props = {
  message: SystemMessageItem;
};

export const SystemMessage = (props: Props) => {
  const message = props.message.value;

  const renderChatMessage = useCallback(() => {
    return (
      <>
        <ChatMessageContainer key={message.id}>
          <MessageContent>{message.content}</MessageContent>
        </ChatMessageContainer>
      </>
    );
  }, [message]);

  return <>{renderChatMessage()}</>;
};

const ChatMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const MessageContent = styled.div`
  max-width: calc(100% - 20px);
  width: 100vw;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 10px 16px;
  min-width: fit-content;
  text-align: center;
  color: #313131;
  background: #f5f7fa;
  border-radius: 12px 12px 12px 12px;
  margin-left: 8px;
`;
