import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Margin } from "../../../components/Margin";
import { Button } from "../../../components/Button";
import {
  RecruitmentFormValue,
  covertFormValueToRecruitment,
} from "./useRecruitmentForm";
import { RecruitmentDetailCard } from "./RecruitmentDetailCard";
import { SelectedFileInfo } from "../../common/Recruitment/RecruitmentFormPDFPicker";
import { useGetDownloadUrls } from "src/hooks/query/firebaseStorage";
import { Recruitment } from "@shared/types/recruitment";
import { RecruitmentTemplate } from "@shared/types/recruitmentTemplate";

interface PreviewProps {
  watchAllValue: RecruitmentFormValue;
  onSubmit: () => void;
  onCancel: () => void;
  isLoading: boolean;
  isRecruitmentCreateNew?: boolean;
  recruitmentPreview?: Recruitment | RecruitmentTemplate | null;
  defaultPdfFiles: File[] | undefined;
  defaultAlreadyUploadedPdfFiles: { name: string; path: string }[];
}

export default function RecruitmentPreview({
  watchAllValue,
  onSubmit,
  onCancel,
  isLoading,
  isRecruitmentCreateNew = false,
  recruitmentPreview,
  defaultPdfFiles,
  defaultAlreadyUploadedPdfFiles,
}: PreviewProps) {
  const covertRecruitment = covertFormValueToRecruitment(watchAllValue);
  //「新規募集作成」画面以外からプレビュー画面表示でcommunityとaccountデータも追加する
  covertRecruitment.community = recruitmentPreview?.community;
  covertRecruitment.account = recruitmentPreview?.account;

  const [selectedFileInfos, setSelectedFileInfos] = useState<
    SelectedFileInfo[]
  >([]);
  const { urls } = useGetDownloadUrls(
    defaultAlreadyUploadedPdfFiles.map((info) => info.path) //map内で取得するとエラーになるので一度に取得する
  );
  useEffect(() => {
    const defaultPdfInfos: SelectedFileInfo[] =
      defaultPdfFiles?.map((file) => ({
        name: file.name,
        path: URL.createObjectURL(file),
        file: file,
      })) ?? [];
    defaultAlreadyUploadedPdfFiles?.forEach((file, index) => {
      defaultPdfInfos?.push({
        name: file.name,
        path: urls[index],
      });
    });
    if (defaultPdfInfos) {
      setSelectedFileInfos(defaultPdfInfos);
    }
  }, [defaultAlreadyUploadedPdfFiles, defaultPdfFiles]);
  return (
    <PreviewContainer>
      <RecruitmentDetailCard
        recruitment={covertRecruitment}
        isRecruitmentCreateNew={isRecruitmentCreateNew}
        recruitmentPreview={recruitmentPreview}
        selectedFileInfos={selectedFileInfos}
      />
      <Margin marginBottom={24} />
      <Button
        size="large"
        fill
        style={{ width: "100%" }}
        onClick={onSubmit}
        disabled={isLoading}
      >
        この内容で募集を投稿
      </Button>
      <Margin marginBottom={12} />
      <Button
        color="dark"
        size="large"
        fill
        style={{ width: "100%" }}
        onClick={onCancel}
      >
        編集画面へ戻る
      </Button>
    </PreviewContainer>
  );
}

// TODO: 詳細側とCSS/コンポーネントを共通化
const PreviewContainer = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding-bottom: 30px;
`;
