// 「アンケート結果を見る」ボタンを表示するかどうかを判定する
// NOTE: あくまでポストの都合による判別だけ行い、
//       権限などはサーバー側が判定する責務を負う

import { User } from "../../../apiClients/users";
import { PostType } from "../../../hooks/api/post";
import { isSurveyDefined } from "../../../apiClients/surveyQuestions";
import { isPtaAdminRole } from "../../../utils/types/role";
import { SurveyQuestions } from "@shared/types/post/survey/survey";

export function shouldShowAggregateSurvey(
  user: User,
  post: PostType,
  surveyQuestions: SurveyQuestions | undefined
): boolean {
  // アンケートが定義されていないときは表示しない
  if (!isSurveyDefined(surveyQuestions)) {
    return false;
  }
  // undefinedの可能性がないことをTSCが見抜いてくれないので再度弾く
  if (surveyQuestions === undefined) {
    return false;
  }
  // 承認済みでなければ表示しない
  if (post.status !== "APPROVED") {
    return false;
  }
  // 承認済みであっても、投稿予約されていて、投稿時間前であれば表示しない
  if (post.scheduledSendAt) {
    if (new Date(post.scheduledSendAt) > new Date()) {
      return false;
    }
  }
  // 管理者であれば表示する
  if (isPtaAdminRole(user.role)) {
    return true;
  }
  // 投稿者本人にも表示する
  if (user.id === surveyQuestions.userId) {
    return true;
  }
  return false;
}
