import { CurrentUser } from "./auth";
import { Belong } from "./belong";
import * as client from "./client";
import { Organization } from "./organization";
import { Part } from "./part";

export type Career = {
  id: string;
  userId: string;
  childId: string | null;
  isGraduatedChild: boolean;
  isGradutatedChild?: boolean; // todo: isGraduatedChildに統一してこのプロパティは消す
  isHighestPart: boolean;
  organizationId: string;
  belongId: string;
  partId: string;
  startDate: string;
  endDate: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  user: CurrentUser;
  belong: Belong;
  part: Part;
  organization: Organization;
};

export async function getCareer({
  careerId,
}: {
  careerId: string;
}): Promise<Career> {
  const res = await client.get<{ careerId: string }, { career: Career }>(
    "/career/getCareer",
    {
      careerId,
    }
  );
  return res.career;
}

export async function getCareerListByUser({
  userId,
}: {
  userId: string;
}): Promise<Career[]> {
  const res = await client.get<{ userId: string }, Career[]>(
    "/career/getCareerListByUser",
    {
      userId,
    }
  );
  return res;
}

export async function createCareer(
  args: Omit<
    Career,
    | "id"
    | "createdAt"
    | "updatedAt"
    | "user"
    | "belong"
    | "part"
    | "organization"
  >
): Promise<Career> {
  const res = await client.post<
    Omit<
      Career,
      | "id"
      | "createdAt"
      | "updatedAt"
      | "user"
      | "belong"
      | "part"
      | "organization"
    >,
    { career: Career }
  >("/career/createCareer", args);
  return res.career;
}

export async function updateCareer(
  args: Omit<
    Career,
    "createdAt" | "updatedAt" | "user" | "belong" | "part" | "organization"
  >
): Promise<Career> {
  const res = await client.post<
    Omit<
      Career,
      "createdAt" | "updatedAt" | "user" | "belong" | "part" | "organization"
    >,
    { career: Career }
  >("/career/updateCareer", args);
  return res.career;
}

export async function deleteCareer(careerId: string) {
  const res = await client.post("/career/deleteCareer", { careerId: careerId });
  return res;
}
