import React, { FC } from "react";
import { Navigation } from "../components/Navigation3";
import * as Responsive from "../components/Responsive";

const PageBody = Responsive.Container;

type AppLayoutProps = {
  children?: React.ReactNode;
};

const AppRegistrationLayout: FC<AppLayoutProps> = (props) => {
  return (
    <div>
      <Navigation />
      <PageBody>{props.children}</PageBody>
    </div>
  );
};

export default AppRegistrationLayout;
