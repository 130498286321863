import React from "react";

export const ShapeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6432 1.87429C11.896 2.12707 11.896 2.53542 11.6432 2.78821L10.4571 3.97437L8.02641 1.54372L9.21257 0.35756C9.46536 0.104772 9.87371 0.104772 10.1265 0.35756L11.6432 1.87429ZM0.164062 11.8367V9.40607L7.33287 2.23726L9.76352 4.66792L2.59471 11.8367H0.164062Z"
        fill="#343741"
      />
    </svg>
  );
};
