import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import * as organizationApi from "../../apiClients/organization";
import {
  isElementarySchoolByType,
  isJuniorHighSchoolByType,
} from "@shared/types/school";
import {
  CommunityWithSignatureTemplate,
  OrganizationWithSignatureTemplates,
} from "@shared/types/organization";

const { persistAtom } = recoilPersist();

export type UpdateOrganization = {
  communityIds?: string[];
  name?: string;
  nameListPublished?: boolean;
  requireAdminToUpdateCareer?: boolean;
  postSchoolYearsFilter?: string[];
};

const organizationState = atom({
  key: "organization",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const useOrganization = ({ skip = false }: { skip?: boolean }) => {
  const [organization, setOrganization] =
    useRecoilState<OrganizationWithSignatureTemplates>(organizationState);
  const [isLoading, setIsLoading] = useState(false);

  const fetchOrganization = async (): Promise<void> => {
    setIsLoading(true);
    const organization = await organizationApi.currentOrganization();
    setOrganization(organization);
    setIsLoading(false);
  };

  const writeOrganization = useCallback(
    async (args: UpdateOrganization) => {
      if (!organization) throw new Error("組織が設定されていません");
      await organizationApi.updateOrganization({
        id: organization.id,
        ...args,
      });
      fetchOrganization();
    },
    [organization]
  );

  useEffect(() => {
    if (!skip) {
      fetchOrganization();
    }
  }, []);

  return { organization, isLoading, fetchOrganization, writeOrganization };
};

/**
 * Organizationに紐づくコミュニティを取得する
 */
export const useOrganizationCommunity = () => {
  const { organization, isLoading: isLoadingOrganization } = useOrganization(
    {}
  );
  const [elementarySchoolCommunities, setElementarySchoolCommunities] =
    useState<CommunityWithSignatureTemplate[]>([]);
  const [juniorHighSchoolCommunities, setJuniorHighSchoolCommunities] =
    useState<CommunityWithSignatureTemplate[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCommunities = () => {
    if (
      isLoadingOrganization ||
      !organization ||
      !organization.communities ||
      !organization.communities.length
    )
      return;
    setIsLoading(true);

    organization.communities.map((community) => {
      if (isElementarySchoolByType(community.school.type)) {
        setElementarySchoolCommunities((prevState) => {
          if (prevState.find((p) => p.id === community.id)) return prevState;
          return [...prevState, community];
        });
      } else if (isJuniorHighSchoolByType(community.school.type)) {
        setJuniorHighSchoolCommunities((prevState) => {
          if (prevState.find((p) => p.id === community.id)) return prevState;
          return [...prevState, community];
        });
      }
    });

    setIsLoading(false);
  };

  useEffect(() => {
    fetchCommunities();
  }, [isLoadingOrganization, organization]);

  return {
    isLoading,
    communities: organization.communities,
    elementarySchoolCommunities,
    juniorHighSchoolCommunities,
    fetchCommunities,
  };
};
