import * as client from "./client";

export type Link = {
  id?: string | null;
  title: string;
  url: string;
  description: string | null;
};

export async function createLink(arg: Link): Promise<void> {
  await client.post<{ arg: Link }, { result: string }>("/link/createLink", {
    arg,
  });
}

export async function updateLink(arg: Link): Promise<void> {
  await client.post<{ arg: Link }, { result: string }>("/link/updateLink", {
    arg,
  });
}

export async function deleteLink(linkId: string): Promise<void> {
  await client.post<{ linkId: string }, { result: string }>(
    "/link/deleteLink",
    { linkId }
  );
}

export async function getOrganizationLinks(): Promise<Link[]> {
  const links = await client.get<{}, Link[]>("/link/getOrganizationLinks", {});

  return links;
}
