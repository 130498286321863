import { TargetGradesType } from "@shared/types/post/api";
import React, { FC } from "react";
import styled from "styled-components";

type props = {
  grade?: TargetGradesType;
  type?: "belong" | "userPostGroup";
  children?: React.ReactNode;
};
export const TargetBadge: FC<props> = ({ grade, type, children }) => {
  return (
    <StyledTargetTagBadge grade={grade} type={type}>
      <GradeBadgeText>{children}</GradeBadgeText>
    </StyledTargetTagBadge>
  );
};

const StyledTargetTagBadge = styled.div<{
  grade?: TargetGradesType;
  type?: "belong" | "userPostGroup";
}>`
  padding: 5px 24px;
  display: grid;
  place-items: center;
  border-radius: 3px;
  background: ${(props) => {
    // gradeが指定されている場合はgradeの色を優先
    if (props.grade) {
      if (props.grade === 1) return "#FEC514";
      if (props.grade === 2) return "#00BFB3";
      if (props.grade === 3) return "#0077CC";
      if (props.grade === 4) return "#BD271E";
      if (props.grade === 5) return "#98A2B3";
      if (props.grade === 6) return "#343741";
    }
    // gradeが指定されていない場合はtypeの色を使用
    if (props.type) {
      if (props.type === "belong") return "#1a84d1"; // 所属、委員会の場合の色
      if (props.type === "userPostGroup") return "#583f99"; // 連絡グループの場合の色
    }
    // デフォルト色は既存コードを考慮して所属、委員会の場合の色と同じ
    return "#1a84d1";
  }};
`;

const GradeBadgeText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  white-space: nowrap;
`;
