export const teachingLicenseOptions = [
  { value: "幼稚園教諭", text: "幼稚園教諭" },
  { value: "小学校教諭", text: "小学校教諭" },
  { value: "中学校教諭", text: "中学校教諭" },
  { value: "高等学校教諭", text: "高等学校教諭" },
  { value: "特別支援学校教諭", text: "特別支援学校教諭" },
  { value: "栄養教諭", text: "栄養教諭" },
  { value: "社会教育士", text: "社会教育士" },
] as const;

export type TeachingLicenseType =
  (typeof teachingLicenseOptions)[number]["value"];

export const medicalLicenseOptions = [
  { value: "看護師", text: "看護師" },
  { value: "保健師医師", text: "保健師医師" },
  { value: "救急救命士", text: "救急救命士" },
  { value: "介護福祉士", text: "介護福祉士" },
  { value: "社会福祉士", text: "社会福祉士" },
  { value: "精神保健福祉士", text: "精神保健福祉士" },
] as const;

export type MedicalLicenseType =
  (typeof medicalLicenseOptions)[number]["value"];

export const skillOptions = [
  { value: "デザイン", text: "デザイン" },
  { value: "広報/宣伝", text: "広報/宣伝" },
  { value: "記事制作", text: "記事制作" },
  { value: "音楽制作", text: "音楽制作" },
  { value: "ビデオ編集", text: "ビデオ編集" },
  { value: "言語翻訳", text: "言語翻訳" },
  { value: "イベント企画", text: "イベント企画" },
  { value: "プログラミング", text: "プログラミング" },
] as const;

export type SkillType = (typeof skillOptions)[number]["value"];
