import React from "react";
import styled from "styled-components";
import { useCurrentUser } from "../../hooks/recoil/user";
import { Margin } from "../../components/Margin";

interface Props {
  currentBaseInfo: {
    lastName: string;
    firstName: string;
    lastNameKana: string;
    firstNameKana: string;
    phoneNumber: string;
  };
}

const SignUpPreviewBaseInfo = ({ currentBaseInfo }: Props) => {
  const user = useCurrentUser();

  return (
    <>
      <ProfileLabel>
        {user.type === "TEACHER"
          ? "教員さまの"
          : user.type === "PARENT"
          ? "保護者の"
          : ""}
        の名前
      </ProfileLabel>
      <ProfileContent>
        {currentBaseInfo.lastName} {currentBaseInfo.firstName}（
        {currentBaseInfo.lastNameKana} {currentBaseInfo.firstNameKana}）
      </ProfileContent>
      <Margin marginBottom={32} />
      <ProfileLabel>電話番号</ProfileLabel>
      <ProfileContent>{currentBaseInfo.phoneNumber}</ProfileContent>
      <Margin marginBottom={32} />
    </>
  );
};

const ProfileLabel = styled.p`
  color: #343741;
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: bold;
`;

const ProfileContent = styled.p`
  color: #343741;
  font-size: 18px;
`;
export default SignUpPreviewBaseInfo;
