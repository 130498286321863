import React, { useCallback, useState } from "react";
import { useApproveRecruitment } from "../../../hooks/api/recruitment";
import { Title } from "../../../components/Title";
import styled from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import { declineApplicationByRecruitmentId } from "../../../apiClients/recruitmentApplication";
import SelfDeclineApplicationModal from "../../../features/Recruitment/application/SelfDeclineApplicationModal";
import { NotFoundRecruitment } from "../../../features/Recruitment/recruitment/NotFoundRecruitment";
import { RecruitmentCard } from "../../../features/Recruitment/recruitment/RecruitmentCard";
import SelfDeclineApplicationForbiddenModal from "../../../features/Recruitment/application/SelfDeclineApplicationForbiddenModal";
import { Recruitment } from "@shared/types/recruitment";

export default function ResidentRecruitmentApproveListPage() {
  const { recruitments, refetch } = useApproveRecruitment();
  const history = usePolyfitHistory();

  const [editRecruitmentId, setRecruitmentId] = useState<string>();
  const [isEditingRecruitmentStarted, setIsEditingRecruitmentStarted] =
    useState(false);

  const handleOpenModal = useCallback((id: string, isStarted: boolean) => {
    setRecruitmentId(id);
    setIsEditingRecruitmentStarted(isStarted);
  }, []);

  const handleDeclineSubmit = useCallback(
    async (comment: string) => {
      await declineApplicationByRecruitmentId(editRecruitmentId ?? "", comment);
      await refetch();
      setRecruitmentId(undefined);
      setIsEditingRecruitmentStarted(false);
    },
    [editRecruitmentId]
  );

  const handleCloseModal = useCallback(() => {
    setRecruitmentId(undefined);
    setIsEditingRecruitmentStarted(false);
  }, []);

  const isRecruitmentStarted = useCallback((recruitment: Recruitment) => {
    const firstDay = new Date(
      recruitment.schedule.reduce((a, b) =>
        new Date(a.date) < new Date(b.date) ? a : b
      ).date
    );
    firstDay.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return firstDay <= today;
  }, []);

  return (
    <>
      <Title>参加する募集一覧</Title>

      {/* TODO: カード部分を共通化 */}
      {recruitments?.length === 0 ? (
        <NotFoundRecruitment
          title="参加する募集一覧はありません"
          description="参加する募集が作成されるとこちらの画面に表示されます"
        />
      ) : (
        <CardContainer>
          {recruitments?.map((recruitment, i) => (
            <RecruitmentCard
              key={recruitment.id}
              recruitment={recruitment}
              primaryButtonLabel="詳細を確認する"
              onClickPrimaryButton={() =>
                history.push({
                  to: "RESIDENT_RECRUITMENT_DETAIL",
                  query: { id: recruitment.id },
                })
              }
              cancelButtonLabel="辞退する"
              cancelButtonIsDisabled={isRecruitmentStarted(recruitment)}
              onClickCancelButton={() => {
                handleOpenModal(
                  recruitment.id ?? "",
                  isRecruitmentStarted(recruitment)
                );
              }}
            />
          ))}
        </CardContainer>
      )}

      {editRecruitmentId &&
        (isEditingRecruitmentStarted ? (
          <SelfDeclineApplicationForbiddenModal onClose={handleCloseModal} />
        ) : (
          <SelfDeclineApplicationModal
            onSubmit={handleDeclineSubmit}
            onClose={handleCloseModal}
          />
        ))}
    </>
  );
}

const CardContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 24px;
  margin: 2em auto;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
