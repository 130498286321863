import React from "react";
import { useCountRecruitmentApplicant } from "../../../hooks/api/recruitmentApplication";
import * as Responsive from "../../../components/Responsive";
import { Margin } from "../../../components/Margin";
import { DangerIcon } from "../../../components/icons/DangerIcon";
import styled from "styled-components";

export default function CountRecruitmentApplicationBar() {
  const { countRecruitmentApplicant, isLoading } =
    useCountRecruitmentApplicant();

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        countRecruitmentApplicant &&
        countRecruitmentApplicant.count > 0 && (
          <>
            <Responsive.Row>
              <Responsive.Col>
                <CountRecruitmentAppliedContent>
                  <CountRecruitmentAppliedPopUp>
                    <CautionBlock>
                      <CountRecruitmentAppliedConfirm>
                        <DangerIcon size={35} />
                        実施予定日が近いか、過ぎている活動の応募者の採用可否ステータスを、速やかに変更してください。
                      </CountRecruitmentAppliedConfirm>
                    </CautionBlock>
                  </CountRecruitmentAppliedPopUp>
                </CountRecruitmentAppliedContent>
                <Margin marginBottom={12} />
              </Responsive.Col>
            </Responsive.Row>
          </>
        )
      )}
    </>
  );
}

const CountRecruitmentAppliedContent = styled.div`
  width: 100%;
  background-color: #fff9e8;
  padding: 16px;
`;

const CountRecruitmentAppliedPopUp = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 460px) {
    flex-direction: column;
  }
`;

const CountRecruitmentAppliedConfirm = styled.span`
  color: #8a6a0a;
  display: flex;
  align-items: center;
  font-size: 16px;

  @media (max-width: 500px) {
    font-size: 13px;
  }

  @media (max-width: 460px) {
    justify-content: center;
  }
`;
const CautionBlock = styled.div`
  @media (max-width: 460px) {
    width: 100%;
    text-align: center;
  }
`;
