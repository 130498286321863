import React from "react";
import { PostUserGroupType } from "../../hooks/api/postUserGroup";
import styled from "styled-components";
import {
  FilterModal,
  FilterModalWrap,
} from "../../components/ListPageFilterSetting";
import { zIndexes } from "../../zIndex";
import { Margin } from "../../components/Margin";

type Props = {
  groups: PostUserGroupType[];
  selectedGroupId: string;
  setSelectedGroupId: (value: string) => void;
};

export const GroupsDropdownList = ({
  groups,
  selectedGroupId,
  setSelectedGroupId,
}: Props) => {
  /**
   * ドロップダウンリストの高さ
   */
  const dropDownListHeight = 38;
  return (
    <>
      <DropdownMenuWrapper>
        <DropdownMenu height={groups.length * dropDownListHeight}>
          <DropdownMenuUl>
            {groups.map((group) => (
              <DropdownMenuLi
                key={group.id}
                onClick={() => setSelectedGroupId(group.id)}
                isLast={groups[groups.length - 1].id === group.id}
              >
                <input
                  type="radio"
                  name={group.id}
                  id={group.id}
                  checked={selectedGroupId === group.id}
                  onChange={() => setSelectedGroupId(group.id)}
                />
                <DropdownMenuLabel>{group.name}</DropdownMenuLabel>
              </DropdownMenuLi>
            ))}
          </DropdownMenuUl>
        </DropdownMenu>
      </DropdownMenuWrapper>
      <Margin
        marginBottom={
          groups.length * dropDownListHeight > 180
            ? 200
            : groups.length * dropDownListHeight + 20
        }
      />
    </>
  );
};

const DropdownMenuWrapper = styled(FilterModalWrap)`
  width: 100%;
  position: relative;
  z-index: ${zIndexes.modalMenu};
`;

const DropdownMenu = styled(FilterModal)<{ height?: number }>`
  width: 100%;
  max-height: 180px;
  overflow: scroll;
  height: ${(props) => (props.height ? `${props.height}px` : "130px")};
  background: #ffffff;
  padding: 0;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const DropdownMenuUl = styled.ul`
  list-style: none;
  width: 100%;
`;

const DropdownMenuLi = styled.li<{ isLast: boolean }>`
  list-style: none;
  width: 100%;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: left;
  border-bottom: ${(props) => (!props.isLast ? "1px solid #e7ebf1" : "")};

  &:hover {
    background-color: #006de41a;
  }
`;

const DropdownMenuLabel = styled.label`
  font-size: 14px;
  width: 100%;
  display: inline-block;
`;
