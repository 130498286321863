import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useCurrentUser } from "../../../../../hooks/recoil/user";
import { ModalPortal } from "../../../../../components/Modal";
import { CurrentUser } from "src/apiClients/auth";
import { Spinner } from "src/components/icons/Spinner";
import { ChatRoom } from "@shared/types/chatRoom";
import { useGetResidentUsers } from "src/hooks/query/residentUsers";
import { MemberSelectionStep } from "./MemberSelectionStep";
import { GroupInfoEditStep } from "./GroupInfoEditStep";

// publicディレクト配下の絶対パスを指定

export type chatRoomEditorFormValues = {
  name: string;
  accountIds: string[];
  file: File | undefined;
};

type Props = {
  close: () => void;
  refetch: () => void;
  chatRoom: ChatRoom;
};

export const EditModal = ({ close, refetch, chatRoom }: Props) => {
  const currentUser: CurrentUser = useCurrentUser();
  const { isLoading: isResidentLoading, residents } = useGetResidentUsers(
    { roles: ["CSMember"] },
    {
      enabled: !!currentUser,
    }
  );
  // 自身を除いたユーザー
  const residentsWithoutCurrentUser = useMemo(
    () => residents?.filter((resident) => resident.id !== currentUser.id),
    [residents]
  );
  // グループのユーザーIDを初期値に設定する
  const participantUserIds = chatRoom.ParticipantChatRooms.filter(
    (participant) =>
      participant.participantType === "USER" &&
      participant.approvalStatus !== "REJECTED"
  ).map((participant) => participant.participantId);
  const userIdToAccountIdMap = new Map<string, string | undefined>();
  residents.forEach((user) => {
    userIdToAccountIdMap.set(user.id, user.account?.id);
  });
  const participantAccountIds = participantUserIds
    .map((userId) => userIdToAccountIdMap.get(userId))
    .filter((accountId): accountId is string => accountId !== undefined);
  useEffect(() => {
    if (residents.length > 0 && chatRoom) {
      setSelectedMemberIds(participantAccountIds);
    }
  }, [residents, chatRoom]);

  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const [isSelectionCompleted, setIsSelectionCompleted] = useState(false);

  // TODO: デフォルト値として設定しているため、条件が変わった場合は変更する

  const completeSelection = (selectedMemberIds: string[]) => {
    setIsSelectionCompleted(true);
    setSelectedMemberIds(selectedMemberIds);
  };

  if (isResidentLoading) {
    return (
      <LoadingCard>
        <Spinner />
      </LoadingCard>
    );
  } else {
    return (
      <ModalPortal onClose={() => close()}>
        {!isSelectionCompleted && (
          <MemberSelectionStep
            residentsWithoutCurrentUser={residentsWithoutCurrentUser}
            initialSelectedAccountIds={participantAccountIds}
            completeSelection={completeSelection}
            onCancel={close}
          />
        )}

        {isSelectionCompleted && (
          <GroupInfoEditStep
            chatRoom={chatRoom}
            selectedMemberIds={selectedMemberIds}
            onCancel={() => setIsSelectionCompleted(false)}
            refetch={refetch}
            close={close}
            residentsWithoutCurrentUser={residentsWithoutCurrentUser}
          />
        )}
      </ModalPortal>
    );
  }
};

const LoadingCard = styled.div`
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
