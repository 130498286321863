import React, { useState } from "react";
import { useToast } from "../../../components/Toast";
import { useForm } from "react-hook-form";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../../components/Modal";
import { User } from "firebase/auth";
import { EyeOpenIcon } from "../../../components/icons/EyeOpenIcon";
import { EyeClosedIcon } from "../../../components/icons/EyeClosedIcon";
import {
  StyledInputLabel,
  StyledPasswordInputWrapper,
  StyledPasswordInput,
  StyledEyeIconWrapper,
  StyledInputLabelAdditional,
} from "../../../components/Form";
import {
  ModalWrapper,
  ModalHeader,
  ErrorMessage,
} from "../../../pages/pta/admin/ProfilePage";
import * as Margin from "../../../components/Margin";
import { Reauthentication } from "./Reauthentication";
import { linkWithPassword } from "src/utils/firebase";

type PasswordResetFormValue = {
  newPassword: string;
  newPasswordConfirm: string;
};

interface Props {
  firebaseUser: User;
  isOpen: boolean;
  close: () => void;
}

export const SetPasswordModal: React.FC<Props> = ({
  firebaseUser,
  isOpen,
  close,
}) => {
  const toast = useToast();
  const [reauthenticated, setReauthenticated] = useState(false);

  const [isDisplayNewPassword, setIsDisplayNewPassword] =
    useState<boolean>(false);
  const [isDisplayPasswordConfirm, setIsDisplayPasswordConfirm] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm<PasswordResetFormValue>({
    defaultValues: {
      newPassword: "",
      newPasswordConfirm: "",
    },
  });

  const [isProcessing, setIsProcessing] = useState(false);

  const handleSave = async () => {
    if (isProcessing) return;

    const newPassword = getValues("newPassword");
    const newPasswordConfirm = getValues("newPasswordConfirm");
    if (newPassword !== newPasswordConfirm) {
      return setError("newPasswordConfirm", {
        type: "custom",
        message: "新しいパスワードが一致しません。",
      });
    }

    try {
      setIsProcessing(true);
      await linkWithPassword(firebaseUser, newPassword);
      toast.success("パスワードを設定しました");
      close();
    } catch (err) {
      toast.error("パスワードの設定に失敗しました");
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={() => close()}>
          <ModalWrapper>
            <ModalHeader>パスワードログインの有効化</ModalHeader>
            {!reauthenticated ? (
              <Reauthentication
                title="パスワードログインの有効化には再認証が必要です"
                firebaseUser={firebaseUser}
                onCancel={close}
                onOk={() => setReauthenticated(true)}
              />
            ) : (
              <>
                <ModalBody>
                  <Margin.Margin marginTop={25} />
                  <StyledInputLabel htmlFor="newPassword">
                    新しいパスワード
                    <StyledInputLabelAdditional>
                      8文字以上の半角英数字
                    </StyledInputLabelAdditional>
                  </StyledInputLabel>
                  <StyledPasswordInputWrapper>
                    <StyledPasswordInput
                      type={isDisplayNewPassword ? "text" : "password"}
                      {...register("newPassword", {
                        required: "入力必須項目です",
                      })}
                    />
                    <StyledEyeIconWrapper
                      onClick={() =>
                        setIsDisplayNewPassword(!isDisplayNewPassword)
                      }
                    >
                      {isDisplayNewPassword ? (
                        <EyeOpenIcon />
                      ) : (
                        <EyeClosedIcon />
                      )}
                    </StyledEyeIconWrapper>
                  </StyledPasswordInputWrapper>
                  {errors.newPassword && (
                    <ErrorMessage>{errors.newPassword.message}</ErrorMessage>
                  )}

                  <Margin.Margin marginTop={10} />
                  <StyledInputLabel htmlFor="password">
                    新しいパスワードの再入力
                  </StyledInputLabel>
                  <StyledPasswordInputWrapper>
                    <StyledPasswordInput
                      type={isDisplayPasswordConfirm ? "text" : "password"}
                      {...register("newPasswordConfirm", {
                        required: "入力必須項目です",
                      })}
                    />
                    <StyledEyeIconWrapper
                      onClick={() =>
                        setIsDisplayPasswordConfirm(!isDisplayPasswordConfirm)
                      }
                    >
                      {isDisplayPasswordConfirm ? (
                        <EyeOpenIcon />
                      ) : (
                        <EyeClosedIcon />
                      )}
                    </StyledEyeIconWrapper>
                  </StyledPasswordInputWrapper>
                  {errors.newPasswordConfirm && (
                    <ErrorMessage>
                      {errors.newPasswordConfirm.message}
                    </ErrorMessage>
                  )}

                  <Margin.Margin marginTop={20} />
                  <ModalSubmitButtons
                    disabled={isProcessing}
                    submitText="保存"
                    onSubmit={handleSubmit(handleSave)}
                    onCancel={close}
                  />
                </ModalBody>
              </>
            )}
          </ModalWrapper>
        </ModalPortal>
      )}
    </>
  );
};
