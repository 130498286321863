import React from "react";
import styled from "styled-components";

type Props = {
  src: string;
  alt: string;
  size: number;
};
export const Avatar = ({ src, alt, size }: Props) => {
  return (
    <div style={{ height: size, width: size }}>
      <Img src={src} alt={alt} size={size} referrerPolicy="no-referrer"></Img>
    </div>
  );
};

const Img = styled.img<{ size: number }>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: ${(props) => props.size}px;
  object-fit: cover;
`;
