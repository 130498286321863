import React, { useState } from "react";
import { ModalBackGround } from "src/components/Common/Menu";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "src/components/Modal";
import { useForm } from "react-hook-form";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { Organization } from "src/@shared/types/organization";
import { z } from "zod";
import { Label } from "src/components/form/Label";
import styled from "styled-components";
import { useInvalidateGetOrganizations } from "src/hooks/query/internal/organizations/useGetOrganizations";
import { createBEOrganization } from "src/apiClients/organization";
import { usePolyfitHistory } from "src/hooks/router";
import { zodResolver } from "@hookform/resolvers/zod";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  organization: Organization;
};

const FormValueSchema = z.object({
  name: z.string().min(1, "入力必須項目です"),
  csOrganizationId: z.string().min(1, "入力必須項目です"),
});

export type FormData = z.infer<typeof FormValueSchema>;

export const CreateBoardEducationModal = ({
  isOpen,
  onClose,
  organization,
}: Props) => {
  if (!isOpen) return null;

  const history = usePolyfitHistory();
  const toast = useToast();
  const { invalidateGetOrganizations } = useInvalidateGetOrganizations();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      csOrganizationId: organization.id,
    },
    mode: "onChange",
    resolver: zodResolver(FormValueSchema),
  });

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async (data: FormData) => {
    try {
      setDoubleClickBlocked(true);
      const result = await createBEOrganization(data);
      invalidateGetOrganizations();

      toast.success("作成しました");

      history.push({
        to: "INTERNAL_ORGANIZATION_BOARD_EDUCATION_USERS",
        query: {
          organizationId: result.organization.id,
        },
      });

      onClose();
    } catch (err) {
      if (err instanceof APIError) {
        toast.error(err.message);
      }
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>{`${organization.name}の教育委員会を作成`}</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Label required>教育委員会組織名</Label>
            <TextField
              placeholder="例)  polyfit教育委員会"
              {...register("name")}
            />
            {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}

            <ModalSubmitButtons
              onCancel={onClose}
              type="submit"
              disabled={!isValid || doubleClickBlocked}
              submitText="作成"
            />
          </form>
        </ModalBody>
      </ModalPortal>
      <ModalBackGround isOpen={isOpen} />
    </>
  );
};

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;
