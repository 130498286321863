import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "../../QueryOptions";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import {
  getCurrentOrganization,
  getManagedOrganization,
} from "src/apiClients/boardEducation/organization";

export const useGetManagedOrganization = (queryOptions: QueryOptions = {}) => {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "organization", "getManagedOrganization", apiContext],
    queryFn: async () => {
      return await getManagedOrganization();
    },
    ...queryOptions,
  });

  return {
    organization: useMemo(() => query.data, [query.data]),
    ...query,
  };
};

export const useGetCurrentOrganization = (queryOptions: QueryOptions = {}) => {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "organization", "getCurrentOrganization", apiContext],
    queryFn: async () => {
      return await getCurrentOrganization();
    },
    ...queryOptions,
  });

  return {
    organization: useMemo(() => query.data, [query.data]),
    ...query,
  };
};
