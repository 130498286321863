import React from "react";

type ArrowDropDownIconProps = {
  size?: number;
};

export const ArrowDropDownIcon = ({ size = 24 }: ArrowDropDownIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
    >
      <path d="M7 10L12 15L17 10L7 10Z" fill="#313131" fillOpacity="0.54" />
    </svg>
  );
};
