import { useRef, useState, useEffect } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { deleteImage, uploadImage } from "../../apiClients/storage";
import {
  ItalicBlot,
  BackgroundStyle,
  ColorStyle,
  SizeStyle,
} from "./QuillBlots";
import { ImageFormats } from "@xeger/quill-image-formats";
import { ImageActions } from "@xeger/quill-image-actions";
import { logger } from "src/utils/logger";

// カスタムブロットとスタイルの登録
Quill.register(
  {
    "attributors/style/background": BackgroundStyle,
    "attributors/style/color": ColorStyle,
    "attributors/style/size": SizeStyle,
    "formats/italic": ItalicBlot,
  },
  true
);

// alignスタイルだけ一括登録するとなぜかinlineCSS化されなくなったので個別に登録
const AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);
Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);

type Props = {
  onChange: (value: string) => void;
  initialValue?: string;
  placeholder?: string;
  imageUploadDirectory?: string;
};

const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["link", "image"],
];

const useImageHandler = (
  quillInstanceRef: React.MutableRefObject<Quill | null>,
  onImageUploaded: (imageUrl: string) => void,
  imageUploadDirectory?: string
) => {
  const [isUploading, setIsUploading] = useState(false); // ローダーの状態
  const handler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/png, image/jpeg, image/gif");
    input.click();

    input.onchange = async () => {
      const files = input.files;
      if (!files || files.length <= 0) return;
      const file = files[0];

      const quillInstance = quillInstanceRef.current;
      if (!quillInstance) return;

      const range = quillInstance.getSelection();
      if (!range) return;

      try {
        setIsUploading(true);

        const imageUrl = await uploadImage({ file, imageUploadDirectory });
        onImageUploaded(imageUrl);

        quillInstance.setSelection(range.index, 1);
        const imageHtml = `<img src="${imageUrl}" >`;
        quillInstance.clipboard.dangerouslyPasteHTML(range.index, imageHtml);
        quillInstance.setSelection(range.index + 1, 1);
      } catch (error) {
        logger.error(new Error(`Image upload failed:` + error));
      } finally {
        setIsUploading(false);
      }
    };
  };
  return { handler, isUploading };
};

const addResetCSS = (quillElement: HTMLDivElement | null) => {
  if (!quillElement) return;

  // ulとolのスタイルリセット
  const lists = quillElement.querySelectorAll("ul, ol");
  lists.forEach((list) => {
    const htmlElement = list as HTMLElement;
    htmlElement.style.paddingInlineStart = "0";
    htmlElement.style.margin = "0";
    htmlElement.style.padding = "0";
  });

  // h1〜h6のスタイルリセット
  const headers = quillElement.querySelectorAll("h1, h2, h3, h4, h5, h6");
  headers.forEach((header) => {
    const htmlElement = header as HTMLElement;
    htmlElement.style.margin = "0";
    htmlElement.style.lineHeight = "1em";
  });
};

const extractImagesFromQuill = (quillInstanceRef: Quill): string[] => {
  const currentImages: string[] = [];
  const ops = quillInstanceRef.getContents().ops;
  ops.forEach((op) => {
    if (op.insert && typeof op.insert === "object" && op.insert.image) {
      currentImages.push(op.insert.image as string);
    }
  });

  return currentImages;
};

export const useQuillEditor = (props: Props) => {
  const quillElementRef = useRef<HTMLDivElement | null>(null);
  const quillInstanceRef = useRef<Quill | null>(null);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);

  const onImageUploaded = (imageUrl: string) => {
    setUploadedImages((prevImages) => [...prevImages, imageUrl]);
  };

  const { handler, isUploading } = useImageHandler(
    quillInstanceRef,
    onImageUploaded,
    props.imageUploadDirectory
  );

  // editor外部からcontentを更新する関数
  const updateContent = (value: string) => {
    if (!quillInstanceRef.current) return;
    quillInstanceRef.current.root.innerHTML = value;
    props.onChange(value);
  };

  const deleteImages = () => {
    if (!quillInstanceRef.current) return;

    const currentImages = extractImagesFromQuill(quillInstanceRef.current);

    const deletedImages = uploadedImages.filter(
      (element) => !currentImages.includes(element)
    );

    if (deletedImages.length === 0) return;

    const deleteCall = deletedImages.map((image) => {
      const parsedUrl = new URL(image as string);
      const path = `${parsedUrl.pathname}`.replace(
        "/" + import.meta.env.VITE_STORAGE_BUCKET + "/",
        ""
      );
      return deleteImage({ filePath: path });
    });

    Promise.all(deleteCall)
      .then(() => {
        // ここで削除処理の後続の処理が必要なら記述
      })
      .catch((error) => {
        logger.error(new Error("Error deleting images:") + error);
      });
  };

  useEffect(() => {
    if (!quillElementRef.current || quillInstanceRef.current) return;

    const quill = new Quill(quillElementRef.current, {
      formats: [
        "align",
        "background",
        "blockquote",
        "bold",
        "code-block",
        "color",
        "float",
        "font",
        "header",
        "height",
        "image",
        "italic",
        "link",
        "script",
        "strike",
        "size",
        "underline",
        "width",
        "list",
      ],
      placeholder: props.placeholder || "入力してください",
      modules: {
        imageActions: {},
        imageFormats: {},
        toolbar: toolbarOptions,
      },
      theme: "snow",
    });

    quillInstanceRef.current = quill;

    quill.getModule("toolbar").addHandler("image", handler);

    quill.on("text-change", () => {
      props.onChange(quill.root.innerHTML);
      addResetCSS(quillElementRef.current);
    });

    // clipboard.dangerouslyPasteHTMLでgetContentsを更新
    // disableにしないと、paste時にfocusが当たって、スクロールが生じてしまう
    quill.disable();
    quill.clipboard.dangerouslyPasteHTML(props.initialValue || "");
    quill.enable();

    // 初期に含まれる画像をuploadedImagesに追加
    setUploadedImages(extractImagesFromQuill(quillInstanceRef.current));

    // clipboard.dangerouslyPasteHTMLだと、inlineCSSが消えてしまうので、innerHTMLを改めて直接更新
    quill.root.innerHTML = props.initialValue || "";
  }, []);

  return { quillElementRef, updateContent, deleteImages, isUploading, handler };
};
