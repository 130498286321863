import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import styled from "styled-components";
import {
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "../../components/Modal";
import { Margin } from "../../components/Margin";
import { CheckBox, CheckBoxRHF } from "../../components/form/CheckBox";
import { Label } from "../../components/form/Label";
import { getSchoolYear } from "../../utils/getSchoolYear";
import { Organization } from "../../apiClients/organization";
import { UpdateOrganization } from "../../hooks/recoil/organization";

type Props = {
  organization: Organization;
  updateOrganization: (organization: UpdateOrganization) => Promise<void>;
  refetch: () => Promise<void>;
  onClose: () => void;
};

type postList = {
  postYears: string[];
};

export const PostYearsModal = ({
  organization,
  updateOrganization,
  refetch,
  onClose,
}: Props) => {
  /**
   * コミュニティ作成年度
   */
  const organizationCreatedYear: number = getSchoolYear(
    new Date(organization.createdAt)
  );
  const currentYear: number = getSchoolYear(new Date());

  /**
   * 投稿リストの全年度
   * - コミュニティ作成年度から現在の年度までの年度を配列で保持
   */
  const allPostYears: string[] = Array.from(
    { length: currentYear - organizationCreatedYear + 1 },
    (_, i) => (organizationCreatedYear + i).toString()
  );
  const allPostList: postList = {
    postYears: allPostYears,
  };

  /**
   * 選択中の投稿リストの表示年度
   */
  const filterPostList: postList = {
    postYears: organization.postSchoolYearsFilter,
  };

  const { handleSubmit, setValue, control } = useForm<postList>({
    defaultValues: filterPostList,
  });

  /**
   * チェックボックスの変更を監視
   */
  const postYears = useWatch({
    control,
    name: "postYears",
  });

  /**
   * 選択中の投稿リストの表示年度の初期値
   * - フィルターが設定されている場合は、その年度を初期値とする
   * - フィルターが設定されていない場合は、全年度を初期値とする
   */
  useEffect(() => {
    if (organization.postSchoolYearsFilter.length === 0) {
      setValue("postYears", allPostYears);
    }
  }, []);

  /**
   * 全年度チェックボックスをクリックした時の処理
   */
  const checkAllPostYears = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      setValue("postYears", allPostYears);
    } else {
      setValue("postYears", []);
    }
  };

  /**
   * 設定ボタンをクリックした時の処理
   */
  const onSubmit = async () => {
    const newOrganization: UpdateOrganization = {
      ...organization,
      postSchoolYearsFilter: postYears,
    };
    await updateOrganization(newOrganization);
    await refetch();
    onClose();
  };

  return (
    <ModalPortal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <p>表示年度の設定</p>
        </ModalHeader>
        <ModalBody>
          <p>メンバーの連絡の表示年度を設定できます。</p>
          <Margin marginBottom={10} />
          <Divider />
          <SubTitle>表示したい年度を選択(複数可)</SubTitle>
          <Margin marginBottom={10} />
          <Label>
            <CheckBox
              checked={postYears.length === allPostYears.length}
              onChange={checkAllPostYears}
              label="全年度"
            />
          </Label>
          <DivReverse>
            {allPostList.postYears?.map((year) => (
              <CheckBoxRHF
                key={year}
                label={`${year}年度（4月から翌年3月）`}
                control={control}
                value={year}
                name="postYears"
              />
            ))}
          </DivReverse>
        </ModalBody>
        <ModalSubmitButtons
          disabled={postYears.length === 0}
          submitText="設定"
          onCancel={onClose}
        />
      </form>
    </ModalPortal>
  );
};

const ModalBody = styled.div`
  min-height: 200px;
  height: fit-content;
`;

const Divider = styled.div`
  width: 100%;
  border: 0.75px solid #f0f2f5;
  margin: 10px 0px;
`;

const SubTitle = styled.p`
  font-weight: bold;
  font-size: 12px;
`;

const DivReverse = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;
