import React, { ChangeEvent, useEffect, useState } from "react";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import styled from "styled-components";
import * as Responsive from "../../../components/Responsive";
import * as Typo from "../../../components/Typo";
import { StepResident } from "../../../components/icons/StepResident";
import { Margin } from "../../../components/Margin";
import { RegistrationFooter } from "../../../components/Footer2";
import {
  Controller,
  ControllerRenderProps,
  useController,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Label } from "../../../components/form/Label";
import { updateCommunityRoles } from "src/apiClients/auth";
import { UpdateCommunityRolesRequest } from "@shared/types/auth";
import { useOrganizationCommunity } from "src/hooks/recoil/organization";
import { Spinner } from "src/components/icons/Spinner";
import { Community } from "@shared/types/community";
import { useToast } from "src/components/Toast";
import { useCurrentUser } from "src/hooks/recoil/user";
import { CommunityListItem } from "src/features/Member/Community/CommunityListItem";
import { SignatureTemplateButton } from "src/features/Member/Community/SignatureTemplateButton";
import {
  useFilteredSchoolCommunitiesExceptMain,
  useFindAdditionalCommunities,
  useFindMainCommunity,
  useSelectedCommunity,
} from "src/features/Member/Community/utils/hooks";
import { hasValidSignatureTemplate } from "src/features/Member/Community/utils/utils";
import {
  TabType,
  FormValueSchema,
  FormValue,
} from "src/features/Member/Community/utils/form";
import { CommunityUserTypeSelect } from "src/features/Member/Community/CommunityUserTypeSelect";
import { UserType } from "src/@shared/types/userType";

/**
 * CS登録時にで校区を選択するページ
 */
export default function RegidentRegisterCommunityPage() {
  const history = usePolyfitHistory();
  const user = useCurrentUser();
  const { token } = usePolyfitLocationQuery("RESIDENT_REGISTER_COMMUNITY", {
    token: undefined,
  });
  const toast = useToast();
  const [tab, setTab] = useState<TabType>("elementarySchool");
  const {
    elementarySchoolCommunities,
    juniorHighSchoolCommunities,
    isLoading,
  } = useOrganizationCommunity();
  const [selectedCommunities, setSelectedCommunities] = useState<Community[]>(
    []
  );
  const communityRoles = user.account?.communityRoles ?? [];

  const mainElementarySchool = useFindMainCommunity(
    elementarySchoolCommunities,
    communityRoles
  );
  const mainJuniorHighSchool = useFindMainCommunity(
    juniorHighSchoolCommunities,
    communityRoles
  );
  const elementarySchools = useFindAdditionalCommunities(
    elementarySchoolCommunities,
    communityRoles
  );
  const juniorHighSchools = useFindAdditionalCommunities(
    juniorHighSchoolCommunities,
    communityRoles
  );

  const {
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValue>({
    resolver: zodResolver(FormValueSchema),
    defaultValues: {
      mainElementarySchool: "",
      mainJuniorHighSchool: "",
      mainElementarySchoolType: undefined,
      mainJuniorHighSchoolType: undefined,
      elementarySchools: [],
      juniorHighSchools: [],
    },
  });
  // 最寄りの小学校区
  const { field: mainElementarySchoolField } = useController({
    control,
    name: "mainElementarySchool",
  });
  // 最寄りの小学校区での役割
  const { field: mainElementarySchoolTypeField } = useController({
    control,
    name: "mainElementarySchoolType",
  });
  // 最寄りの中学校区
  const { field: mainJuniorHighSchoolField } = useController({
    control,
    name: "mainJuniorHighSchool",
  });
  // 最寄りの中学校区での役割
  const { field: mainJuniorHighSchoolTypeField } = useController({
    control,
    name: "mainJuniorHighSchoolType",
  });
  // 追加の小学校区
  const { field: elementarySchoolsField } = useController({
    name: "elementarySchools",
    control,
  });
  // 追加の小学校区
  const { field: juniorHighSchoolsField } = useController({
    name: "juniorHighSchools",
    control,
  });
  // 最寄りの小学校区以外の小学校区
  const filteredElementarySchoolCommunities =
    useFilteredSchoolCommunitiesExceptMain(
      elementarySchoolCommunities,
      mainElementarySchoolField.value
    );
  // 最寄りの中学校区以外の中学校区
  const filteredJuniorHighSchoolCommunities =
    useFilteredSchoolCommunitiesExceptMain(
      juniorHighSchoolCommunities,
      mainJuniorHighSchoolField.value
    );

  useEffect(() => {
    mainElementarySchoolField.onChange(
      mainElementarySchool?.community.id ?? ""
    );
    mainJuniorHighSchoolField.onChange(
      mainJuniorHighSchool?.community.id ?? ""
    );

    mainElementarySchoolTypeField.onChange(
      mainElementarySchool?.communityRole.type
    );
    mainJuniorHighSchoolTypeField.onChange(
      mainJuniorHighSchool?.communityRole.type
    );

    elementarySchoolsField.onChange(elementarySchools.map((s) => s.id));
    juniorHighSchoolsField.onChange(juniorHighSchools.map((s) => s.id));
    setSelectedCommunities([...elementarySchools, ...juniorHighSchools]);
  }, [
    elementarySchoolCommunities,
    juniorHighSchoolCommunities,
    communityRoles,
  ]);

  // 最寄りの小学校区が更新された場合は、追加で選択された小学校区からは削除する
  useEffect(() => {
    const values = elementarySchoolsField.value.filter(
      (s) => s !== mainElementarySchoolField.value
    );
    elementarySchoolsField.onChange(values);
    setSelectedCommunities((prev) => {
      return prev.filter((p) => p.id !== mainElementarySchoolField.value);
    });
  }, [mainElementarySchoolField.value]);
  // 最寄りの中学校区が更新された場合は、追加で選択された中学校区からは削除する
  useEffect(() => {
    const values = juniorHighSchoolsField.value.filter(
      (s) => s !== mainJuniorHighSchoolField.value
    );
    juniorHighSchoolsField.onChange(values);
    setSelectedCommunities((prev) => {
      return prev.filter((p) => p.id !== mainJuniorHighSchoolField.value);
    });
  }, [mainJuniorHighSchoolField.value]);

  const handleMainElementarySchoolSelect = (
    field: ControllerRenderProps<FormValue, "mainElementarySchool">,
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const communityId = event.target.value;

    field.onChange(communityId);
    mainElementarySchoolTypeField.onChange("");
  };

  const handleMainJuniorHighSchoolSelect = (
    field: ControllerRenderProps<FormValue, "mainJuniorHighSchool">,
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const communityId = event.target.value;

    field.onChange(communityId);
    mainJuniorHighSchoolTypeField.onChange("");
  };

  const handleElementarySchoolsCheckBox = (community: Community) => {
    const elementarySchools = getValues("elementarySchools");
    const values = elementarySchools.includes(community.id)
      ? elementarySchools.filter((s) => s !== community.id)
      : [...elementarySchools, community.id];
    setValue("elementarySchools", values);
    setSelectedCommunities((prev) => {
      if (prev.findIndex((p) => p.id === community.id) === -1) {
        return [...prev, community];
      }
      return prev.filter((p) => p.id !== community.id);
    });
  };

  const handleJuniorHighSchoolsCheckBox = (community: Community) => {
    const juniorHighSchools = getValues("juniorHighSchools");
    const values = juniorHighSchools.includes(community.id)
      ? juniorHighSchools.filter((s) => s !== community.id)
      : [...juniorHighSchools, community.id];
    setValue("juniorHighSchools", values);
    setSelectedCommunities((prev) => {
      if (prev.findIndex((p) => p.id === community.id) === -1) {
        return [...prev, community];
      }
      return prev.filter((p) => p.id !== community.id);
    });
  };

  const onSubmit = async (data: FormValue) => {
    if (!data.mainElementarySchool && !data.mainJuniorHighSchool) {
      toast.error("一つ以上の校区を選択してください");
      return;
    }
    try {
      const update: UpdateCommunityRolesRequest = { userId: user.id, data: [] };
      update.data.push(
        ...(data.mainElementarySchool && data.mainElementarySchoolType
          ? [
              {
                roleName: user.role,
                communityId: data.mainElementarySchool,
                isMain: true,
                type: data.mainElementarySchoolType,
              },
            ]
          : []),
        ...(data.mainJuniorHighSchool && data.mainJuniorHighSchoolType
          ? [
              {
                roleName: user.role,
                communityId: data.mainJuniorHighSchool,
                isMain: true,
                type: data.mainJuniorHighSchoolType,
              },
            ]
          : []),
        ...data.elementarySchools.map((school) => {
          return {
            roleName: user.role,
            communityId: school,
            isMain: false,
            type: UserType.RESIDENT,
          };
        }),
        ...data.juniorHighSchools.map((school) => {
          return {
            roleName: user.role,
            communityId: school,
            isMain: false,
            type: UserType.RESIDENT,
          };
        })
      );
      await updateCommunityRoles(update);
      history.push({
        to: "RESIDENT_CONTACT_INFO",
        query: {
          ...(token && { token }),
        },
      });
    } catch (err) {
      toast.error("校区の設定に失敗しました");
    }
  };

  const selectedElementarySchool = useSelectedCommunity(
    elementarySchoolCommunities,
    mainElementarySchoolField.value
  );

  const selectedJuniorHighSchool = useSelectedCommunity(
    juniorHighSchoolCommunities,
    mainJuniorHighSchoolField.value
  );

  return (
    <div>
      <Margin marginBottom={24} />
      <StepResident stepNum={2} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledContainer>
          <Margin marginBottom={16} />
          <SubTitleWrapper>
            <Typo.Heading3>校区選択</Typo.Heading3>
          </SubTitleWrapper>
          <Margin marginBottom={16} />
          <Responsive.Row>
            <InputWrapper>
              <Responsive.Col>
                <Label>最寄りの小学校区</Label>
                <SelectWrapper>
                  <Controller
                    name="mainElementarySchool"
                    control={control}
                    render={({ field, formState }) => {
                      return (
                        <>
                          <SelectBox
                            onChange={(event) =>
                              handleMainElementarySchoolSelect(field, event)
                            }
                            value={field.value}
                          >
                            <option
                              value=""
                              disabled
                              style={{ color: "#aab4c4" }}
                            >
                              〇〇小学校
                            </option>
                            {elementarySchoolCommunities
                              .filter(hasValidSignatureTemplate)
                              .map((community) => {
                                return (
                                  <option
                                    key={community.id}
                                    value={community.id}
                                  >
                                    {community.name}
                                  </option>
                                );
                              })}
                          </SelectBox>
                          {formState.errors.mainElementarySchool ? (
                            <span className="error-message">
                              {formState.errors.mainElementarySchool.message}
                            </span>
                          ) : (
                            <Margin marginTop={8} />
                          )}
                        </>
                      );
                    }}
                  />
                  {selectedElementarySchool && (
                    <>
                      <SignatureTemplateButton
                        community={selectedElementarySchool}
                      />
                      <CommunityUserTypeSelect
                        name="mainElementarySchoolType"
                        control={control}
                        fieldError={errors?.mainElementarySchoolType}
                      />
                    </>
                  )}
                </SelectWrapper>
              </Responsive.Col>
              <Responsive.Col>
                <Label>最寄りの中学校区</Label>
                <SelectWrapper>
                  <Controller
                    name="mainJuniorHighSchool"
                    control={control}
                    render={({ field, formState }) => {
                      return (
                        <>
                          <SelectBox
                            onChange={(event) =>
                              handleMainJuniorHighSchoolSelect(field, event)
                            }
                            value={field.value}
                          >
                            <option
                              value=""
                              disabled
                              style={{ color: "#aab4c4" }}
                            >
                              〇〇中学校
                            </option>
                            {juniorHighSchoolCommunities
                              .filter(hasValidSignatureTemplate)
                              .map((community) => {
                                return (
                                  <option
                                    key={community.id}
                                    value={community.id}
                                  >
                                    {community.name}
                                  </option>
                                );
                              })}
                          </SelectBox>
                          {formState.errors.mainJuniorHighSchool ? (
                            <span className="error-message">
                              {formState.errors.mainJuniorHighSchool.message}
                            </span>
                          ) : (
                            <Margin marginTop={8} />
                          )}
                        </>
                      );
                    }}
                  />
                  {selectedJuniorHighSchool && (
                    <>
                      <SignatureTemplateButton
                        community={selectedJuniorHighSchool}
                      />
                      <CommunityUserTypeSelect
                        name="mainJuniorHighSchoolType"
                        control={control}
                        fieldError={errors?.mainJuniorHighSchoolType}
                      />
                    </>
                  )}
                </SelectWrapper>
              </Responsive.Col>
            </InputWrapper>
            <Responsive.Col>
              <Margin marginTop={16} />
              <Label>校区を追加</Label>
              <TabWrapper>
                <Tab
                  isActive={tab === "elementarySchool"}
                  onClick={() => setTab("elementarySchool")}
                >
                  小学校
                </Tab>
                <Tab
                  isActive={tab === "juniorHighSchool"}
                  onClick={() => setTab("juniorHighSchool")}
                >
                  中学校
                </Tab>
              </TabWrapper>
              <CommunityListWrapper>
                <CommunityList>
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : tab === "elementarySchool" ? (
                    filteredElementarySchoolCommunities.map(
                      (community, index) => {
                        return (
                          <CommunityListItem
                            key={index}
                            community={community}
                            onCheckboxChange={handleElementarySchoolsCheckBox}
                            isChecked={watch("elementarySchools").includes(
                              community.id || ""
                            )}
                          />
                        );
                      }
                    )
                  ) : (
                    tab === "juniorHighSchool" &&
                    filteredJuniorHighSchoolCommunities.map(
                      (community, index) => {
                        return (
                          <CommunityListItem
                            key={index}
                            community={community}
                            onCheckboxChange={handleJuniorHighSchoolsCheckBox}
                            isChecked={watch("juniorHighSchools").includes(
                              community.id || ""
                            )}
                          />
                        );
                      }
                    )
                  )}
                </CommunityList>
              </CommunityListWrapper>
              {selectedCommunities.length > 0 && (
                <SelectedValuesList>
                  <Divider />
                  <SubTitle>選択された追加の校区</SubTitle>
                  <Margin marginBottom={10} />
                  <BadgeWrapper>
                    {selectedCommunities.map((community, index) => {
                      return <Badge key={index}>{community.name}</Badge>;
                    })}
                  </BadgeWrapper>
                </SelectedValuesList>
              )}
            </Responsive.Col>
          </Responsive.Row>
        </StyledContainer>
        <RegistrationFooter />
      </form>
    </div>
  );
}

const StyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  padding-bottom: 88px;
  .error-message {
    font-size: 12px;
    color: #bd271e;
  }
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

const SelectWrapper = styled.div`
  min-width: 200px;
  @media (max-width: 1279px) {
    min-width: 163px;
  }
`;

const SelectBox = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
`;

const TabWrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #1322951a;
`;

const Tab = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: start;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 20px;
  ${(props) =>
    props.isActive && `color: #0077CC; border-bottom: 2px solid #0077CC`};
  cursor: pointer;
`;

const CommunityListWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 8px;
`;

const CommunityList = styled.ul`
  overflow: auto;
  width: 100%;
  max-height: 300px;
  background: white;
  list-style: none;
  border-radius: 6px;
  border: 1px solid #1322951a;
`;

// 選択した値のリスト
const SelectedValuesList = styled.ul`
  list-style: none;
`;

const SubTitle = styled.p`
  font-size: 12px;
`;

const Divider = styled.div`
  width: 100%;
  border: 0.75px solid #f0f2f5;
  margin: 10px 0px;
`;

const BadgeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const Badge = styled.div`
  width: fit-content;
  border: 1px solid #0071c2;
  border-radius: 36px;
  background: #e6f1fa;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  gap: 4px;
  font-size: 12px;
  margin-right: 8px;
`;
