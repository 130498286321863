import React from "react";
import styled from "styled-components";
import { Margin } from "../../components/Margin";

interface Props {
  currentBaseInfo: {
    belong?: {
      name: string;
    };
    part?: {
      name: string;
    };
  };
}

const SignUpPreviewMemberPositionSetting = ({ currentBaseInfo }: Props) => {
  return (
    <>
      {currentBaseInfo && (
        <>
          <ProfileLabel>所属</ProfileLabel>
          <ProfileContent>
            {currentBaseInfo.belong?.name ?? "所属なし"}
          </ProfileContent>
          <Margin marginBottom={32} />
          <ProfileLabel>役職</ProfileLabel>
          <ProfileContent>
            {currentBaseInfo.part?.name ?? "所属なし"}
          </ProfileContent>
          <Margin marginBottom={32} />
        </>
      )}
    </>
  );
};

const ProfileLabel = styled.p`
  color: #343741;
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: bold;
`;

const ProfileContent = styled.p`
  color: #343741;
  font-size: 18px;
`;
export default SignUpPreviewMemberPositionSetting;
