import React from "react";
import { ChatMessageFile } from "src/apiClients/chatMessage";
import { ImageView } from "src/components/Image";
import { PdfIcon } from "src/components/icons/PdfIcon";
import { useGetDownloadUrl } from "src/hooks/query/firebaseStorage";
import styled from "styled-components";

const Image = ({ file }: { file: ChatMessageFile }) => {
  const { url } = useGetDownloadUrl(file.path, "");

  return (
    <ImagePreview>
      <a target="blank" href={url}>
        <ImageView
          width="100px"
          height="100px"
          src={url}
          alt={file.name}
        ></ImageView>
      </a>
    </ImagePreview>
  );
};

const Pdf = ({ file }: { file: ChatMessageFile }) => {
  const { url } = useGetDownloadUrl(file.path, "");

  return (
    <PdfPreview>
      <PdfIcon size={24} />
      <PdfLabel target="blank" href={url}>
        {file.name}
      </PdfLabel>
    </PdfPreview>
  );
};

interface Props {
  files: ChatMessageFile[];
}

export const ChatMessageFileContainer = ({ files }: Props) => {
  const imageFiles = files.filter((file) => file.type === "IMAGE");
  const pdfFiles = files.filter((file) => file.type === "PDF");

  return (
    <FileContainer>
      {imageFiles.length > 0 && (
        <ImageContainer>
          {imageFiles.map((file) => {
            return <Image key={file.name} file={file} />;
          })}
        </ImageContainer>
      )}
      {pdfFiles.length > 0 && (
        <PdfContainer>
          {pdfFiles.map((file) => {
            return <Pdf key={file.name} file={file} />;
          })}
        </PdfContainer>
      )}
    </FileContainer>
  );
};

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 2;
`;
const ImageContainer = styled.div`
  width: 100%;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const ImagePreview = styled.div`
  position: relative;
`;

const PdfContainer = styled.div`
  width: 100%;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const PdfPreview = styled.div`
  max-width: 204px;
  display: flex;
  align-items: center;
  padding: 0 4px;
  border-radius: 8px;
  border: 1px solid #ececec;
  background-color: #ffffff;
  &:last-child {
    margin-right: 0;
  }
`;

const PdfLabel = styled.a`
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;
