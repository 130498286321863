export const genderOption = [
  { value: "MALE", text: "男性" },
  { value: "FEMALE", text: "女性" },
  { value: "OTHER", text: "その他" },
] as const;

export type GenderType = (typeof genderOption)[number]["value"];

export function getGenderText(gender: GenderType): string {
  return genderOption.find((option) => option.value === gender)?.text ?? "";
}
