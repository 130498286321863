import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

export type ProfileTabType =
  | "baseInfo"
  | "childInfo"
  | "partnerInfo"
  | "teacherInfo"
  | "residentInfo"
  | "resume"
  | "loginSetting"
  | "residentInfoBelongOrganization"
  | "residentChatQRCode";

const { persistAtom } = recoilPersist();
export const profileTabState = atom<ProfileTabType>({
  key: "ProfileTab",
  default: "baseInfo",
  effects_UNSTABLE: [persistAtom],
});
