/**
 * 与えられた年月日から年度を取得する
 * @param date 年度を取得したい日付
 * @returns 年度
 */
export const getSchoolYear = (date: Date): number => {
  const schoolStartMonth = 4; // 年度の開始月

  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  if (month < schoolStartMonth) {
    return year - 1;
  } else {
    return year;
  }
};
