import React from "react";

export const FileCharacterIcon = () => {
  return (
    <svg
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M182 200.5H58C57.1716 200.5 56.5 199.828 56.5 199V43C56.5 42.1716 57.1716 41.5 58 41.5H146.328C146.729 41.5 147.114 41.6607 147.395 41.9463L149.167 40.1976L147.395 41.9463L183.068 78.0879L184.847 76.3317L183.068 78.0879C183.345 78.3686 183.5 78.7471 183.5 79.1416V199C183.5 199.828 182.828 200.5 182 200.5Z"
        fill="#DEEFFF"
        stroke="#37434F"
        strokeWidth="5"
      />
      <path
        d="M199 42.1319L202.49 48.7032C202.779 49.2475 203.303 49.6281 203.91 49.7349L211.238 51.0235L206.067 56.3735C205.639 56.8166 205.439 57.4325 205.525 58.0428L206.564 65.4105L199.878 62.1457C199.324 61.8752 198.676 61.8752 198.122 62.1457L191.436 65.4105L192.475 58.0428C192.561 57.4325 192.361 56.8166 191.933 56.3735L186.762 51.0235L194.09 49.7349C194.697 49.6281 195.221 49.2475 195.51 48.7032L199 42.1319Z"
        fill="white"
        stroke="#37434F"
        strokeWidth="2"
      />
      <path
        d="M178 76.5L148.5 46.5V74.5C148.5 75.6046 149.395 76.5 150.5 76.5H178Z"
        fill="white"
      />
      <path d="M154 81.5V86.5L181 82V81.5H154Z" fill="#8AC6FF" />
      <path d="M59 44H60.5L67.5 198H59V44Z" fill="white" />
      <path
        d="M146 44V74C146 76.7614 148.239 79 151 79H181"
        stroke="#37434F"
        strokeWidth="5"
      />
      <path
        d="M130.004 139.039C130.004 139.039 124.251 142.092 120.231 142.019C116.038 141.944 110.004 139.001 110.004 139.001"
        stroke="#37434F"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <circle cx="140" cy="103" r="6" fill="#37434F" />
      <circle cx="99" cy="103" r="6" fill="#37434F" />
      <path
        d="M180 24.1319L182.57 28.9701C182.859 29.5144 183.383 29.895 183.99 30.0018L189.385 30.9506L185.578 34.8896C185.149 35.3327 184.949 35.9486 185.035 36.5588L185.8 41.9835L180.878 39.5797C180.324 39.3092 179.676 39.3092 179.122 39.5797L174.2 41.9835L174.965 36.5588C175.051 35.9486 174.851 35.3327 174.422 34.8896L170.615 30.9506L176.01 30.0018C176.617 29.895 177.141 29.5144 177.43 28.9701L180 24.1319Z"
        fill="white"
        stroke="#37434F"
        strokeWidth="2"
      />
    </svg>
  );
};
