import React from "react";
import { User } from "../../apiClients/users";
import { usePolyfitHistory } from "../../hooks/router";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../components/Modal";
import styled from "styled-components";
import { Heading3 } from "../../components/Typo";
import { Account, deleteAccountById } from "../../apiClients/auth";
import { useInvalidateGetChildrenByCondition } from "src/hooks/query/childrenList";
import { APIError } from "../../utils/types/ApiError";
import { useToast } from "../../components/Toast";
import { useInvalidateGetResidentUsers } from "src/hooks/query/residentUsers";
import { logger } from "src/utils/logger";

type LeaveModalProps = {
  user: User;
  account: Account;
  onClose: () => void;
  isLastAdminUser: boolean;
};

export const LeaveModal = ({
  user,
  account,
  onClose,
  isLastAdminUser,
}: LeaveModalProps) => {
  const history = usePolyfitHistory();
  const { invalidateGetChildrenByCondition } =
    useInvalidateGetChildrenByCondition();
  const { invalidateGetResidentUsers } = useInvalidateGetResidentUsers();
  const toast = useToast();

  const onSubmit = async () => {
    try {
      // 権限を変更処理
      await deleteAccountById(account.id);
      invalidateGetChildrenByCondition();
      invalidateGetResidentUsers();
      onClose();
      history.push({ to: "PTA_LIST" });
    } catch (err) {
      logger.error(err);
      if (err instanceof APIError) {
        toast.error(err.message);
        onClose();
        return;
      }
    }
  };
  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>退会</ModalHeader>
      <ModalBody>
        {isLastAdminUser ? (
          <>
            <Message>
              管理者が不在の場合、管理者を新規で増やすことができません。
              <br />
              以後、新規招待や退会処理ができなくなってしまいます。
              <br />
              他の方を管理者権限に移行してください。
            </Message>
          </>
        ) : (
          <>
            <Message>
              {user.name}さんを退会させます。
              <br />
              退会させると、メンバーがpolyfitにログインできなくなります。
            </Message>
            <ModalSubmitButtons
              // disabled={memberUser.role === role}
              submitText="退会"
              onSubmit={onSubmit}
              onCancel={onClose}
            />
          </>
        )}
      </ModalBody>
    </ModalPortal>
  );
};

const ModalHeader = styled(Heading3)`
  padding-bottom: 24px;
  display: flex;
`;

const Message = styled.div`
  padding: 16px 0;
  max-width: 432px;
`;
