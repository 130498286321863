import React, { useEffect, useState } from "react";
import { usePolyfitHistory } from "../../../hooks/router";
import { useCurrentUser } from "../../../hooks/recoil/user";
import styled from "styled-components";
import * as Responsive from "../../../components/Responsive";
import * as Typo from "../../../components/Typo";
import { StepAdmin } from "../../../components/icons/StepAdmin";
import { StepMember } from "../../../components/icons/StepMember";
import { Margin } from "../../../components/Margin";
import { RegistrationFooter } from "../../../components/Footer2";
import { useForm } from "react-hook-form";
import { unreachable } from "../../../utils/unreachable";
import { classOption } from "../../../utils/getChildClass";
import { useTeacherInfo } from "../../../hooks/api/teacherInfo";
import { Role, isPtaAdminRole } from "../../../utils/types/role";

export default function RegisterTeacherInfoPage() {
  const history = usePolyfitHistory();
  const user = useCurrentUser();
  const { isLoading, currentTeacherInfo, updateTeacherInfo } = useTeacherInfo();

  const [hasHomeroom, setHasHomeroom] = React.useState<boolean | undefined>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (data: any) => {
    if (hasHomeroom === undefined) {
      setErrorMessage("担当の有無を選択してください");
      return;
    }
    setErrorMessage("");

    try {
      await updateTeacherInfo(hasHomeroom ? data : null);
      switch (user.role) {
        case Role.PTAAdmin:
          history.push({
            to: "COMMUNITY_SETTING",
          });
          break;
        case Role.PTAMember:
          history.push({
            to: "POSITION_SETTING",
          });
          break;
        case Role.CSAdmin:
        case Role.CSMember:
        case Role.CSGuest:
        case Role.CSOperator:
        case Role.BEAdmin:
          break;
        default:
          unreachable(user.role);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const gradeOption = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
    { value: "6", text: "6" },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
  });

  useEffect(() => {
    // setValue関数は一度しか実行できないため、取得完了後に一度だけ実行する
    if (isLoading) {
      return;
    }

    if (currentTeacherInfo !== undefined) {
      setHasHomeroom(currentTeacherInfo !== null);
    }

    if (currentTeacherInfo) {
      setValue("grade", currentTeacherInfo.grade);
      setValue("class", currentTeacherInfo.class);
    }
  }, [isLoading, currentTeacherInfo]);

  return (
    <div>
      <Margin marginBottom={24} />
      {isPtaAdminRole(user.role) ? (
        <StepAdmin stepNum={2} />
      ) : (
        <StepMember stepNum={2} />
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledContainer>
          <Margin marginBottom={16} />
          <Typo.Heading3>担当情報の入力</Typo.Heading3>
          {hasHomeroom === undefined && errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
          <Margin marginBottom={8} />
          <Responsive.Row>
            <Responsive.Col>
              <RadioInputWrapper>
                <RadioInput
                  type="radio"
                  name="hasHomeroom"
                  id="hasHomeroom"
                  checked={hasHomeroom === true}
                  onChange={() => {
                    setHasHomeroom(true);
                  }}
                />
                <label htmlFor="hasHomeroom">
                  <Typo.Heading4>担任あり</Typo.Heading4>
                </label>
              </RadioInputWrapper>
              <Margin marginBottom={10} />

              {hasHomeroom && (
                <HorizontalInputWrapper>
                  <SelectWrapper>
                    <Typo.Heading5>学年</Typo.Heading5>
                    <Margin marginBottom={8} />
                    <SelectChildInfo
                      {...register(`grade` as const, {
                        required: "入力必須項目です",
                        setValueAs: (v) =>
                          v === "" ? undefined : parseInt(v, 10),
                      })}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        選択してください
                      </option>
                      {gradeOption.map((opt) => {
                        return (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        );
                      })}
                    </SelectChildInfo>
                    {errors?.grade?.message != null ? (
                      <span className="error-message">
                        {/* @ts-expect-error todo */}
                        {errors.grade.message}
                      </span>
                    ) : (
                      <Margin marginTop={16} />
                    )}
                  </SelectWrapper>
                  <SelectWrapper>
                    <Typo.Heading5>クラス</Typo.Heading5>
                    <Margin marginBottom={8} />
                    <SelectChildInfo
                      {...register(`class` as const, {
                        required: "入力必須項目です",
                        setValueAs: (v) =>
                          v === "" ? undefined : parseInt(v, 10),
                      })}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        選択してください
                      </option>
                      {classOption.map((opt) => {
                        return (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        );
                      })}
                    </SelectChildInfo>
                    {errors?.class != null && errors?.class != null ? (
                      <span className="error-message">
                        {/* @ts-expect-error todo */}
                        {errors?.class?.message}
                      </span>
                    ) : (
                      <Margin marginTop={16} />
                    )}
                  </SelectWrapper>
                  <Margin marginBottom={10} />
                </HorizontalInputWrapper>
              )}
            </Responsive.Col>

            <Responsive.Col>
              <RadioInputWrapper>
                <RadioInput
                  type="radio"
                  name="noHomeroom"
                  id="noHomeroom"
                  checked={hasHomeroom === false}
                  onChange={() => {
                    setHasHomeroom(false);
                  }}
                />
                <label htmlFor="noHomeroom">
                  <Typo.Heading4>
                    担任なし（校長、副校長、その他）
                  </Typo.Heading4>
                </label>
              </RadioInputWrapper>
            </Responsive.Col>
          </Responsive.Row>
        </StyledContainer>
        <RegistrationFooter />
      </form>
    </div>
  );
}

const StyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  padding-bottom: 88px;
  .error-message {
    font-size: 12px;
    color: #bd271e;
  }
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

const RadioInputWrapper = styled.div`
  display: flex;
  grid-template-columns: auto auto;
`;

const RadioInput = styled.input`
  margin-right: 8px;
`;

const HorizontalInputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 16px;
`;

const SelectWrapper = styled.div`
  min-width: 240px;
  @media (max-width: 1279px) {
    min-width: 163px;
  }
`;

const SelectChildInfo = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background: "#fbfcfd";
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: "pointer";
`;
