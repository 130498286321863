import { useEffect, useState } from "react";
import { getPartnerForAdmin, Partner } from "../../apiClients/partner";

export const usePartnerForAdmin = (userId: string, isAdmin: boolean) => {
  const [partner, setPartner] = useState<Partner | null>(null);

  const fetchPartner = async () => {
    if (!isAdmin) return;
    const partner = await getPartnerForAdmin(userId);
    setPartner(partner);
  };

  useEffect(() => {
    fetchPartner();
  }, [userId]);

  return { partner };
};
