import React from "react";

export const ChevronDownIcon = () => {
  return (
    <>
      <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1.5L6 6.5L11 1.5" stroke="#5A606B" />
      </svg>
    </>
  );
};
