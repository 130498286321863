import { z } from "zod";

/**
 * CSの基本情報のスキーマ
 */
export const CSFormValueSchema = z
  .object({
    baseInfo: z.object({
      lastName: z.string().min(1, { message: "入力必須項目です" }),
      lastNameKana: z
        .string()
        .min(1, { message: "入力必須項目です" })
        .refine((value) => /^[ぁ-んー]+$/.test(value), {
          message: "ひらがなのみで入力してください",
        }),
      firstName: z.string().min(1, { message: "入力必須項目です" }),
      firstNameKana: z
        .string()
        .min(1, { message: "入力必須項目です" })
        .refine((value) => /^[ぁ-んー]+$/.test(value), {
          message: "ひらがなのみで入力してください",
        }),
      phoneNumber: z.string().min(1, { message: "入力必須項目です" }),
      gender: z.enum(["MALE", "FEMALE", "OTHER"]),
      ageGroup: z.number().optional(),
      birthday: z
        .string()
        .optional()
        .refine((value) => !value || new Date(value) <= new Date(), {
          message: "未来の日付は設定できません",
        }),
      postalCode: z.string().optional().nullable(),
      prefecture: z.string().optional().nullable(),
      city: z.string().min(1, { message: "入力必須項目です" }),
      address1: z.string().min(1, { message: "入力必須項目です" }),
      address2: z.string().optional().nullable(),
    }),
    community: z.object({
      mainElementarySchool: z.string(),
      mainJuniorHighSchool: z.string(),
      mainElementarySchoolType: z
        .enum(["PARENT", "TEACHER", "RESIDENT"], {
          errorMap: () => ({
            message: "最寄りの小学校区の役割を選択してください",
          }),
        })
        .optional(),
      mainJuniorHighSchoolType: z
        .enum(["PARENT", "TEACHER", "RESIDENT"], {
          errorMap: () => ({
            message: "最寄りの中学校区の役割を選択してください",
          }),
        })
        .optional(),
      elementarySchools: z.string().array(),
      juniorHighSchools: z.string().array(),
    }),
  })
  .refine(
    (data) => {
      if (data.community.mainElementarySchool) {
        return !!data.community.mainElementarySchoolType;
      }
      return true;
    },
    {
      message: "最寄りの小学校区の役割を選択してください",
      path: ["community", "mainElementarySchoolType"],
    }
  )
  .refine(
    (data) => {
      if (data.community.mainJuniorHighSchool) {
        return !!data.community.mainJuniorHighSchoolType;
      }
      return true;
    },
    {
      message: "最寄りの中学校区の役割を選択してください",
      path: ["community", "mainJuniorHighSchoolType"],
    }
  );

/**
 * 名簿/プロフィール
 * 管理人のみアクセス可
 */
export const CSAdminFormValueSchema = z
  .object({
    baseInfo: z.object({
      userId: z.string(),
      lastName: z.string().min(1, { message: "入力必須項目です" }),
      lastNameKana: z
        .string()
        .min(1, { message: "入力必須項目です" })
        .refine((value) => /^[ぁ-んー]+$/.test(value), {
          message: "ひらがなのみで入力してください",
        }),
      firstName: z.string().min(1, { message: "入力必須項目です" }),
      firstNameKana: z
        .string()
        .min(1, { message: "入力必須項目です" })
        .refine((value) => /^[ぁ-んー]+$/.test(value), {
          message: "ひらがなのみで入力してください",
        }),
      phoneNumber: z.string().min(1, { message: "入力必須項目です" }),
      gender: z.enum(["MALE", "FEMALE", "OTHER"]),
      ageGroup: z.number().optional(),
      birthday: z
        .string()
        .optional()
        .refine((value) => !value || new Date(value) <= new Date(), {
          message: "未来の日付は設定できません",
        }),
      postalCode: z.string().optional().nullable(),
      prefecture: z.string().optional().nullable(),
      city: z.string().min(1, { message: "入力必須項目です" }),
      address1: z.string().min(1, { message: "入力必須項目です" }),
      address2: z.string().optional().nullable(),
    }),
    community: z.object({
      mainElementarySchool: z.string(),
      mainJuniorHighSchool: z.string(),
      mainElementarySchoolType: z
        .enum(["PARENT", "TEACHER", "RESIDENT"], {
          errorMap: () => ({
            message: "最寄りの小学校区の役割を選択してください",
          }),
        })
        .optional(),
      mainJuniorHighSchoolType: z
        .enum(["PARENT", "TEACHER", "RESIDENT"], {
          errorMap: () => ({
            message: "最寄りの中学校区の役割を選択してください",
          }),
        })
        .optional(),
      elementarySchools: z.string().array(),
      juniorHighSchools: z.string().array(),
    }),
    residentInfo: z.object({
      preferredDays: z.string().array(),
      teacherLicenses: z.string().array(),
      medicalLicenses: z.string().array(),
      skills: z.string().array(),
      preferredPaid: z.string().nullable(),
      preferredVolunteerType: z.string().array(),
      privateEnterprise: z.string().optional().nullable(),
      university: z.string().optional().nullable(),
      externalOrganization: z.string().optional().nullable(),
      pta: z.string().optional().nullable(),
    }),
  })
  .refine(
    (data) => {
      if (data.community.mainElementarySchool) {
        return !!data.community.mainElementarySchoolType;
      }
      return true;
    },
    {
      message: "最寄りの小学校区の役割を選択してください",
      path: ["community", "mainElementarySchoolType"],
    }
  )
  .refine(
    (data) => {
      if (data.community.mainJuniorHighSchool) {
        return !!data.community.mainJuniorHighSchoolType;
      }
      return true;
    },
    {
      message: "最寄りの中学校区の役割を選択してください",
      path: ["community", "mainJuniorHighSchoolType"],
    }
  );
