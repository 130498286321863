import React from "react";
import { Margin } from "../../../../components/Margin";
import styled from "styled-components";
import {
  ModalPortal,
  ModalHeader,
  ModalSubmitButtons,
} from "../../../../components/Modal";

interface DeleteModalProps {
  title: string;
  onSubmit: () => void;
  onClose: () => void;
}

export default function CloseRecruitmentModal({
  title,
  onSubmit,
  onClose,
}: DeleteModalProps) {
  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>
        <p>掲載を終了</p>
      </ModalHeader>
      <ModalBody>
        <p>「{title}」の掲載を終了します。掲載終了すると再開できません。</p>
        <Margin marginBottom={10} />
      </ModalBody>
      <ModalSubmitButtons
        submitText="掲載終了"
        submitColor="danger"
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    </ModalPortal>
  );
}

const ModalBody = styled.div`
  min-height: 50px;
  height: fit-content;
`;
