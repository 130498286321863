import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import {
  getAppliedAccountList,
  getApprovedAccountList,
} from "src/apiClients/boardEducation/recruitment";

export const useGetAppliedAccountList = (recruitmentId: string) => {
  const query = useQuery({
    queryKey: [
      "api",
      "boardEducation",
      "recruitment",
      "getAppliedAccountListByRecruitmentId",
      recruitmentId,
    ],
    queryFn: () => getAppliedAccountList(recruitmentId),
  });

  return {
    appliedAccountList: useMemo(() => query.data || null, [query.data]),
    ...query,
  };
};

export const useGetApprovedAccountList = (recruitmentId: string) => {
  const query = useQuery({
    queryKey: [
      "api",
      "boardEducation",
      "recruitment",
      "getApprovedAccountListByRecruitmentId",
      recruitmentId,
    ],
    queryFn: () => getApprovedAccountList(recruitmentId),
  });

  return {
    approvedAccountList: useMemo(() => query.data || null, [query.data]),
    ...query,
  };
};
