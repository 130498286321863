import React, { useEffect } from "react";
import { useCurrentUserHook } from "../../../hooks/recoil/user";
import {
  useCurrentCommunityId,
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import { useAccounts } from "../../../hooks/api/account";
import {
  setCurrentAccount,
  updateCommunityRolesByToken,
} from "../../../apiClients/auth";
import { useOrganization } from "../../../hooks/recoil/organization";
import { useInvitation } from "../../../hooks/recoil/invitation";
import { auth } from "../../../utils/firebase";
import { Spinner } from "../../../components/icons/Spinner";
import styled from "styled-components";
import { isCsGuestRole, isCsMemberRole } from "../../../utils/types/role";
import { getOrCreateChatRoomByMember } from "../../../apiClients/chatRoom";
import { useChatRoomFilter } from "../../../hooks/recoil/chatRoomFilter";

export const ResidentOrganizationChatRegistrationHandler = () => {
  const { user, loading: isLoadingUser } = useCurrentUserHook();
  const { accounts, loading: isLoadingAccounts } = useAccounts();
  const { communityId, setCommunityId } = useCurrentCommunityId();
  const { resetFilter } = useChatRoomFilter();
  const history = usePolyfitHistory();
  const { fetchOrganization } = useOrganization({ skip: true });
  const { token } = usePolyfitLocationQuery(
    "RESIDENT_ORGANIZATION_CHAT_REGISTRATION_HANDLER",
    {
      token: "",
    }
  );
  const {
    isLoading: isLoadingToken,
    isValid,
    invitation,
  } = useInvitation(token);

  useEffect(() => {
    if (
      !auth.currentUser ||
      isLoadingUser ||
      isLoadingAccounts ||
      isLoadingToken
    ) {
      console.log("waiting for loading...");

      // TODO 一定時間経過で連絡用QRコードのエラーページに飛ばす
      return;
    }

    const handleAccountCheckAndRedirect = async () => {
      if (
        !invitation?.organizationId ||
        !invitation?.flow ||
        invitation?.flow !== "ORGANIZATION_CHAT" ||
        invitation?.targetCommunityId === undefined ||
        !isValid
      ) {
        history.push({
          to: "LOADING",
          query: {},
        });
        return;
      }

      const account = accounts.find(
        (account) => account.organizationId === invitation.organizationId
      );

      // ユーザーがorganizationIdに属するアカウントを所持している場合
      if (account) {
        // ユーザーの現在のアカウントがorganizationIdに属するアカウントでない場合は現在のアカウントを連絡用QRコードのorganizationIdに変更する
        if (user.account?.organizationId !== invitation.organizationId) {
          await setCurrentAccount(user.id, invitation.organizationId);
          await fetchOrganization();
          location.reload();
          return;
        }

        try {
          const userHasCommunityRole = user.account?.communityRoles?.some(
            (c) => c.communityId === invitation.targetCommunityId
          );
          if (!userHasCommunityRole) {
            await updateCommunityRolesByToken(token, account.role.name);
          }
        } catch (err) {
          return;
          // TODO 連絡用QRコードのエラーページに飛ばす
        }

        if (communityId !== invitation.targetCommunityId) {
          setCommunityId(invitation.targetCommunityId);
          location.reload();
          return;
        }

        // CSMemberは取得結果のchatRoomIdをRESIDENT_CHATのchatRoomIdクエリーとして渡す
        let chatRoomId: string | null = null;

        if (isCsMemberRole(user.role) || isCsGuestRole(user.role)) {
          const chatRoom = await getOrCreateChatRoomByMember();
          chatRoomId = chatRoom.id;

          // フィルターの設定次第でチャット一覧には表示されないが、チャット詳細は表示されるため、フィルターをリセットする
          resetFilter();
        }

        history.push({
          to: "RESIDENT_CHAT",
          query: {
            ...(chatRoomId && { chatRoomId }),
          },
        });
        return;
      } else {
        // ログイン済みユーザーでorganizationIdに属するアカウントを所持していない場合
        history.push({
          to: "RESIDENT_CHAT_REGISTRATION_DESCRIPTION",
          query: { token },
        });
      }
    };

    handleAccountCheckAndRedirect();
  }, [
    accounts,
    isLoadingUser,
    isLoadingAccounts,
    isLoadingToken,
    history,
    invitation,
  ]);

  return (
    <Card>
      <Spinner />
    </Card>
  );
};

const Card = styled.div`
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
