import React, { JSX, memo } from "react";
import { EventHandlers } from "./eventHandlers";
import { ToggleQuestionTypeField } from "./ToggleQuestionTypeField";
import { QuestionContainer, QuestionField } from "./Question";
import { Label } from "../../../components/form/Label";
import styled from "styled-components";
import { CloseButton } from "./closeButton";
import { OpenEndedQuestion } from "@shared/types/post/survey/survey";

// 記述式用component
type Props = {
  question: OpenEndedQuestion;
  eventHandlers: EventHandlers;
  isDeletable: boolean;
};

export const OpenEndedQuestionForm = memo(
  ({ question, eventHandlers, isDeletable }: Props): JSX.Element => {
    return (
      <QuestionContainer>
        {isDeletable && (
          <CloseButton
            onClick={() => eventHandlers.handleDeleteQuestion(question)}
          />
        )}
        <QuestionField>
          <Label size="s" marginBottom={4}>
            質問の種類
          </Label>
          <ToggleQuestionTypeField
            question={question}
            eventHandlers={eventHandlers}
          />
        </QuestionField>
        <QuestionField>
          <Label size="s" marginBottom={4}>
            質問
          </Label>
          <TextField
            placeholder="例）PTAに質問があれば教えてください"
            value={question.text}
            onChange={(e) => {
              question.text = e.target.value;
              eventHandlers.handleEditQuestionText(question);
            }}
          />
        </QuestionField>
        <QuestionField>
          <Label size="s" marginBottom={4}>
            補足説明
          </Label>
          <TextField
            placeholder="例）出来るだけ全ての質問へ回答しますが、場合によっては回答致しかねます"
            value={question.description}
            onChange={(e) => {
              question.description = e.target.value;
              eventHandlers.handleEditDescription(question);
            }}
          />
        </QuestionField>
      </QuestionContainer>
    );
  }
);

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  ::placeholder {
    color: #aab4c4;
    font-size: 14px;
  }
  &:disabled {
    opacity: 0.6;
  }
  -webkit-appearance: none;
`;
