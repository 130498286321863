import React from "react";

type Props = {
  size: number;
};
export const PdfIcon = ({ size }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 17H5V4H12V6C12 7.10457 12.8954 8 14 8H16V17ZM18 8V18.15C18 18.6194 17.6269 19 17.1667 19H3.83333C3.3731 19 3 18.6194 3 18.15V2.85C3 2.38056 3.3731 2 3.83333 2H12C12.4602 2 18 7.53056 18 8ZM7 10C7 9.44772 7.44772 9 8 9H13C13.5523 9 14 9.44772 14 10C14 10.5523 13.5523 11 13 11H8C7.44772 11 7 10.5523 7 10ZM7 13C7 12.4477 7.44772 12 8 12H13C13.5523 12 14 12.4477 14 13C14 13.5523 13.5523 14 13 14H8C7.44772 14 7 13.5523 7 13ZM20 8C20.5523 8 21 8.44772 21 9V21C21 21.5523 20.5523 22 20 22H8C7.44772 22 7 21.5523 7 21C7 20.4477 7.44772 20 8 20H19V9C19 8.44772 19.4477 8 20 8Z"
        fill="#394559"
      />
    </svg>
  );
};
