import {
  BERecruitment,
  BERecruitmentCreateArgsType,
  BERecruitmentUpdateArgsType,
  RecruitmentAccountList,
  RecruitmentFilter,
} from "@shared/types/boardEducation/recruitment";
import * as client from "../client";

export async function getBERecruitmentList(
  filter: RecruitmentFilter
): Promise<BERecruitment[]> {
  return await client.get<{}, BERecruitment[]>(
    "/boardEducation/recruitment/list",
    {
      volunteerType: filter.volunteerType,
      isPaidVolunteer: filter.isPaidVolunteer,
      date: filter.date,
      isOpen: filter.isOpen,
      selectedSchool: filter.selectedSchool,
    }
  );
}

export async function getBERecruitmentById(
  recruitmentId: string
): Promise<BERecruitment> {
  return await client.get<{ recruitmentId: string }, BERecruitment>(
    "/boardEducation/recruitment/getByRecruitmentId",
    { recruitmentId }
  );
}

export async function createBERecruitment(
  arg: BERecruitmentCreateArgsType,
  files: File[]
): Promise<void> {
  const recruitmentCreateArg = JSON.stringify(arg);

  await client.postWithFormData<
    { recruitmentCreateArg: string; files: File[] },
    { result: string }
  >("/boardEducation/recruitment/create", { recruitmentCreateArg, files });
}

export async function updateBERecruitment(
  arg: BERecruitmentUpdateArgsType,
  files: File[]
) {
  const recruitmentUpdateArg = JSON.stringify(arg);
  await client.postWithFormData<
    { recruitmentUpdateArg: string; files: File[] },
    { result: string }
  >("/boardEducation/recruitment/update", { recruitmentUpdateArg, files });
}

export async function closeBERecruitment(recruitmentId: string): Promise<void> {
  await client.post<{ recruitmentId: string }, { result: string }>(
    "/boardEducation/recruitment/close",
    { recruitmentId }
  );
}

export async function deleteBERecruitment(
  recruitmentId: string
): Promise<void> {
  await client.post<{ recruitmentId: string }, { result: string }>(
    "/boardEducation/recruitment/delete",
    { recruitmentId }
  );
}

// 公開済みの募集は論理削除する
export async function deleteBEOpenRecruitment(
  recruitmentId: string
): Promise<void> {
  await client.post<{ recruitmentId: string }, { result: string }>(
    "/boardEducation/recruitment/deleteOpen",
    { recruitmentId }
  );
}

/**
 * 応募者情報一覧取得
 */
export async function getAppliedAccountList(
  recruitmentId: string
): Promise<RecruitmentAccountList[]> {
  return await client.get<{ recruitmentId: string }, RecruitmentAccountList[]>(
    "/boardEducation/recruitment/getAppliedAccountListByRecruitmentId",
    { recruitmentId }
  );
}

/**
 * 応募確定者情報一覧取得
 */
export async function getApprovedAccountList(
  recruitmentId: string
): Promise<RecruitmentAccountList[]> {
  return await client.get<{ recruitmentId: string }, RecruitmentAccountList[]>(
    "/boardEducation/recruitment/getApprovedAccountListByRecruitmentId",
    { recruitmentId }
  );
}

/**
 * 応募情報の学校、教育委員会の名前のフィルター検索用オプション取得
 */
export async function getBESchoolOptions(): Promise<
  {
    value: string;
  }[]
> {
  return await client.get<
    {},
    {
      value: string;
    }[]
  >("/boardEducation/recruitment/schoolOptions", {});
}
