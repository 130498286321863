import React, { FC, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Outlet,
} from "react-router-dom";
import {
  initializeLogger,
  logger,
  SentryRoutes,
  SetSentryScope,
} from "./utils/logger";
import {
  publicRoutes,
  authenticatedRoutes,
  authorizedRoutes,
  allRoutes,
  RouteType,
} from "./routers";
import { RecoilRoot } from "recoil";
import { useCurrentUser, useFetchCurrentUser } from "./hooks/recoil/user";
import { QueryParamProvider } from "use-query-params";
import { ModalRoot } from "./components/Modal";
import { ThemeStyles } from "./theme";
import { BaseCSS } from "./components/Responsive";
import { ToastBox } from "./components/Toast";
import { NotFoundPage } from "./pages/error/NotFoundPage";
import { PermissionDeniedPage } from "./pages/error/PermissionDeniedPage";
import {
  usePolyfitHistory,
  usePolyfitLocationQuery,
  useCurrentCommunityId,
} from "./hooks/router";
import { unreachable } from "./utils/unreachable";
import { inCommunitySchool, inPTA } from "./apiClients/users";
import "./utils/reactDatePicker";
import { WebSocketProvider } from "./hooks/recoil/socket";
import {
  isBeAdminRole,
  isCsAdminRole,
  isCsGuestRole,
  isPtaAdminRole,
} from "./utils/types/role";
import {
  FirebaseUserProvider,
  useFirebaseUserContext,
} from "./hooks/context/firebaseUser";
import { validateInvitationToken } from "./apiClients/invitation";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { APIError } from "./utils/types/ApiError";

initializeLogger();

const AuthenticationRouteGuard = () => {
  const history = usePolyfitHistory();
  const fetch = useFetchCurrentUser();
  const location = useLocation();
  const { firebaseUser, isLoading } = useFirebaseUserContext();
  // TODO 汎用的に使えるように_route指定をしないでQueryを取得できるにする
  // 暫定的にLoadingを指定している
  const { token } = usePolyfitLocationQuery("LOADING", {
    token: undefined,
  });

  useEffect(() => {
    const handleRedirection = async () => {
      if (isLoading) return;
      if (!firebaseUser) {
        if (token) {
          // token.flowがORGANIZATION_CHATの場合は連絡用QRコードの登録フローにリダイレクト
          try {
            const res = await validateInvitationToken({ token });
            if (res.valid && res.flow === "ORGANIZATION_CHAT") {
              history.push({
                to: "RESIDENT_CHAT_REGISTRATION_DESCRIPTION",
                query: { token },
              });
              window.location.reload();
              return;
            }
          } catch (err) {
            logger.error(err);
          }
        }
        // ログインしていない場合はログイン画面にリダイレクト
        history.push({ to: "LOGIN", query: {} });
        window.location.reload();
      } else if (!firebaseUser.emailVerified) {
        // メール認証が済んでいない場合は認証画面にリダイレクト（現在のルートが認証画面ではない場合）
        if (location.pathname !== "/signup_email_verification") {
          history.push({
            to: "SIGNUP_EMAIL_VERIFICATION",
            query: {
              ...(token && { token }),
            },
          });
        }
      }
    };

    handleRedirection();
  }, [firebaseUser, isLoading]);

  useEffect(() => {
    if (firebaseUser) {
      fetch();
    }
  }, [firebaseUser]);

  return firebaseUser ? <Outlet /> : <></>;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AuthorizationRouteGuard = (props: {
  component: React.ReactNode;
  route: RouteType;
}) => {
  const user = useCurrentUser();
  const { fetchCommunityId } = useCurrentCommunityId();
  const { firebaseUser } = useFirebaseUserContext();

  // PTA/CS/BEチェック
  const isPTA = inPTA(user);
  const isPTAAdmin = isPtaAdminRole(user.role);
  const isCommunitySchool = inCommunitySchool(user);
  const isCommunitySchoolAdmin = isCsAdminRole(user.role);
  const isBoardEducationAdmin = isBeAdminRole(user.role);
  const isInternalAdmin = user?.internalRole === "INTERNAL_ADMIN";

  // 登録ステータス
  const isEmailVerified = firebaseUser?.emailVerified; // メール認証済み
  const isRegistering = isEmailVerified && !user?.signUpState; // 登録完了前
  const isSignupComplete = isEmailVerified && user?.signUpState && user?.able; // 登録済み && 承認済み

  useEffect(() => {
    if (!isBoardEducationAdmin) {
      fetchCommunityId();
    }
  }, []);

  switch (props.route) {
    case RouteType.PUBLIC:
      break;
    case RouteType.AUTHENTICATED:
      break;
    case RouteType.REGISTER_PTA: // 登録関連のルート(PTA)
      if (!isPTA || !isRegistering) {
        return <PermissionDeniedPage />;
      }

      break;
    case RouteType.REGISTER_CS: // 登録関連のルート(地域住民)
      // ユーザー種別チェック
      if (!isCommunitySchool || !isRegistering) {
        return <PermissionDeniedPage />;
      }

      break;
    case RouteType.PTA: // PTAユーザーのみアクセスできるルート
      if (!isPTA || !isSignupComplete) {
        return <PermissionDeniedPage />;
      }

      break;

    case RouteType.PTA_ADMIN: // PTAユーザーの管理者のみアクセスできるルート
      if (!isPTAAdmin || !isSignupComplete) {
        return <PermissionDeniedPage />;
      }

      break;
    case RouteType.CS_MEMBER: // 地域住民のみアクセスできるルート (本登録)
      if (!isCommunitySchool || !isSignupComplete) {
        return <PermissionDeniedPage />;
      }

      break;
    case RouteType.CS_MEMBER_OR_CS_GUEST: // 地域住民のみアクセスできるルート (仮登録 or 本登録)
      if (isCsGuestRole(user.role)) {
        if (!isCommunitySchool || !isEmailVerified) {
          return <PermissionDeniedPage />;
        }
      } else {
        if (!isCommunitySchool || !isSignupComplete) {
          return <PermissionDeniedPage />;
        }
      }
      break;
    case RouteType.CS_GUEST: // 地域住民のみアクセスできるルート (仮登録)
      if (!isCommunitySchool || !isEmailVerified) {
        return <PermissionDeniedPage />;
      }

      break;
    case RouteType.CS_ADMIN: // 地域住民の管理者のみアクセスできるルート
      if (!isCommunitySchoolAdmin || !isSignupComplete) {
        return <PermissionDeniedPage />;
      }

      break;
    case RouteType.PTA_OR_CS_MEMBER:
      // FIXME: 暫定で両方ともOKなルートを作る
      if (!isSignupComplete) {
        return <PermissionDeniedPage />;
      }

      break;
    case RouteType.PTA_OR_CS_MEMBER_OR_CS_GUEST:
      // FIXME: 暫定で両方ともOKなルートを作る
      if (isCsGuestRole(user.role)) {
        if (!isCommunitySchool || !isEmailVerified) {
          return <PermissionDeniedPage />;
        }
      } else {
        if (!isSignupComplete) {
          return <PermissionDeniedPage />;
        }
      }

      break;
    case RouteType.BE_ADMIN: // 教育委員会管理者のみアクセスできるルート
      if (!isBoardEducationAdmin || !isSignupComplete) {
        return <PermissionDeniedPage />;
      }

      break;
    case RouteType.INTERNAL_ADMIN: // 社内ユーザーのみアクセスできるルート
      if (!isInternalAdmin || !isSignupComplete) {
        return <PermissionDeniedPage />;
      }

      break;

    default:
      unreachable(props.route);
  }

  return (
    <>
      <WebSocketProvider isSkipCurrentCommunityId={isBoardEducationAdmin}>
        {props.component}
      </WebSocketProvider>
    </>
  );
};

/**
 * 404チェック
 */
const RouteExistsOrFail: FC<{ children: React.ReactNode }> = ({ children }) => {
  const router = allRoutes.find((item) => {
    return item.path == location.pathname;
  });
  if (!router) return <NotFoundPage />;

  return <>{children}</>;
};

type RouterType = {
  path: string;
  title: string;
  component: React.FC;
  layout: React.FC<{ children?: React.ReactNode }>;
};

/**
 * routerを受け取り、ページコンポーネントを返す関数
 */
function getPageComponent(router: RouterType) {
  return (
    <router.layout>
      <router.component />
    </router.layout>
  );
}

const ReactQueryClientProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: 1000 * 60 * 10,
        staleTime: 1000 * 60 * 5,

        // 400系エラーの場合リトライをスキップする
        retry: (failureCount, error) => {
          if (error instanceof APIError) {
            if (400 <= error.status && error.status < 500) {
              return false;
            }
          }
          // それ以外の場合は3回までリトライ
          return failureCount < 3;
        },
        retryDelay: (attempt: number) => {
          return Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000);
        },
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

function App() {
  return (
    <QueryParamProvider>
      <RecoilRoot>
        <ReactQueryClientProvider>
          <FirebaseUserProvider>
            <Router>
              <SetSentryScope />
              <ScrollToTop />
              <RouteExistsOrFail>
                <SentryRoutes>
                  {/* ログインしなくてもアクセスできるページ */}
                  {publicRoutes.map((router) => {
                    return (
                      <Route
                        key={router.path}
                        path={router.path}
                        // @ts-ignore
                        element={getPageComponent(router)}
                      />
                    );
                  })}
                  <Route element={<AuthenticationRouteGuard />}>
                    {/* ログインは必要だがorganizationの認可は必要ないページ */}
                    {authenticatedRoutes.map((router) => {
                      return (
                        <Route
                          key={router.path}
                          path={router.path}
                          // @ts-ignore
                          element={getPageComponent(router)}
                        />
                      );
                    })}

                    {/* organizationの認可が必要なページ */}
                    {authorizedRoutes.map((router) => {
                      return (
                        <Route
                          key={router.path}
                          path={router.path}
                          element={
                            <AuthorizationRouteGuard
                              // @ts-ignore
                              component={getPageComponent(router)}
                              route={router.route}
                            />
                          }
                        />
                      );
                    })}
                  </Route>
                </SentryRoutes>
              </RouteExistsOrFail>
            </Router>
            <ModalRoot />
            <ToastBox />
            <ThemeStyles />
            <BaseCSS />
          </FirebaseUserProvider>
        </ReactQueryClientProvider>
      </RecoilRoot>
    </QueryParamProvider>
  );
}

export default App;
