import { TargetClassesType } from "@shared/types/post/api";

/*
 * Child.class から表示するクラス名に変換する関数
 */
export const getClassName = (
  classValue: TargetClassesType | null | undefined,
  isSuffix: boolean = false,
  isForPostModal: boolean = false
): string => {
  if (!classValue || classValue === -1) {
    return isForPostModal ? "クラス未設定" : "未設定";
  }

  if (classValue === 100) {
    return "その他";
  }

  return classValue.toString() + (isSuffix ? "組" : "");
};

export const classOption = [
  { value: "1", text: "1" },
  { value: "2", text: "2" },
  { value: "3", text: "3" },
  { value: "4", text: "4" },
  { value: "5", text: "5" },
  { value: "6", text: "6" },
  { value: "7", text: "7" },
  { value: "8", text: "8" },
  { value: "9", text: "9" },
  { value: "10", text: "10" },
  { value: "11", text: "11" },
  { value: "12", text: "12" },
  { value: "100", text: "その他" },
];
