import { atom, useRecoilState } from "recoil";
import { ChildrenFilterType } from "../api/child";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const childrenFilterState = atom<ChildrenFilterType>({
  key: "ChildrenFilter",
  default: {
    grades: [1, 2, 3, 4, 5, 6],
    classes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 100, -1],
    parentsAreUnique: undefined,
  },
  effects_UNSTABLE: [persistAtom],
});

export const useChildrenFilter = () => {
  const [childrenFilter, setChildrenFilter] =
    useRecoilState(childrenFilterState);

  return [childrenFilter, setChildrenFilter] as const;
};
