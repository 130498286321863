import React, { useCallback } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSidebarMenuModal } from "../../../hooks/recoil/sidebar";
import { useCurrentCommunityId } from "src/hooks/router";

type Menu = {
  text: string;
  to: string;
};

type SidebarDropDownMenuProps = {
  menus?: Menu[];
};

export const SidebarDropDownMenu = ({
  menus = [],
}: SidebarDropDownMenuProps) => {
  if (menus.length === 0) return null;
  const [, { close }] = useSidebarMenuModal();
  const { communityId } = useCurrentCommunityId();
  const searchParams = new URLSearchParams();
  const getTo = useCallback(
    (to: string) => {
      if (!searchParams.get("communityId") && communityId) {
        searchParams.append("communityId", communityId);
      }
      return to + "?" + searchParams.toString();
    },
    [communityId, searchParams.toString()]
  );

  return (
    <Container>
      {menus.map((menu) => (
        <Menu key={menu.text}>
          <Text to={getTo(menu.to)} onClick={close}>
            {menu.text}
          </Text>
        </Menu>
      ))}
    </Container>
  );
};

const Container = styled.div`
  /* default hidden */
  display: none;
  /* position */
  position: absolute;
  left: 100%;
  top: 0;
  /* size */
  height: fit-content;
  min-width: 214px;
  /* color */
  background-color: #fff;
  /* border */
  border-radius: 6px;
  box-shadow: 0px 15px 15px -1px rgba(0, 0, 0, 0.04),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 0.9px 4px -1px rgba(0, 0, 0, 0.08);
  /* padding */
  padding: 4px 0;
`;

const Menu = styled.div`
  /* display */
  display: flex;
  flex-direction: column;
`;

const Text = styled(Link)`
  color: #343741;
  font-size: 14px;
  padding: 16px 12px;
  &:hover {
    background: rgba(0, 109, 228, 0.1);
  }
`;
