import React, { useCallback, useState } from "react";
import styled from "styled-components";
import {
  PCBaseInfoWrapper,
  StyledTr,
  StyledTh,
  StyledTd,
  EditLinkContainer,
  EditBaseInfoIcon,
  StyledFlexContainer,
  ChildFormLabel,
  ChildFormContainer,
} from "../../../pages/pta/admin/ProfilePage";
import { PencilIcon } from "../../../components/icons/PencilIcon";
import { useGetChildren } from "../../../hooks/api/child";
import ChildInfoEditModal from "./ChildInfoEditModal";
import { getClassName } from "../../../utils/getChildClass";

type Props = {
  currentUserId: string;
};

const ChildInfoContent = ({ currentUserId }: Props) => {
  const { children, getChildren, isLoading } = useGetChildren(currentUserId);
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
    getChildren();
  }, [setIsOpen]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <>
      <PCBaseInfoWrapper>
        <StyledFlexContainer>
          {isLoading ? (
            <div>読み込み中...</div>
          ) : (
            <>
              <ChildFormContainer>
                <StyledContainerPc>
                  {children.map((child, index) => (
                    <ChildrenWrapper key={index} isFirstChild={index === 0}>
                      <ChildFormLabel>
                        お子さま ({index + 1}人目)
                      </ChildFormLabel>

                      <ChildInfoTable>
                        <StyledTr>
                          <StyledTh>お名前</StyledTh>
                          <StyledTd>
                            {child?.childLastName} {child?.childFirstName}
                          </StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>学年</StyledTh>
                          <StyledTd>{child?.grade}</StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>クラス</StyledTh>
                          <StyledTd>{getClassName(child.class)}</StyledTd>
                        </StyledTr>
                      </ChildInfoTable>
                    </ChildrenWrapper>
                  ))}
                </StyledContainerPc>
              </ChildFormContainer>

              <ChildFormContainer>
                {children.map((child, index) => (
                  <StyledContainerSp key={index}>
                    <ChildFormLabel>お子さま ({index + 1}人目)</ChildFormLabel>

                    <StyledTableSp>
                      <div>
                        <StyledTHSp>お名前</StyledTHSp>
                        <StyledTdSp>
                          {child?.childLastName} {child?.childFirstName}
                        </StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>学年</StyledTHSp>
                        <StyledTdSp>{child?.grade}</StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>クラス</StyledTHSp>
                        <StyledTdSp>{getClassName(child.class)}</StyledTdSp>
                      </div>
                    </StyledTableSp>
                  </StyledContainerSp>
                ))}
              </ChildFormContainer>

              <EditLinkContainer onClick={onOpen}>
                <PencilIcon />
                <EditBaseInfoIcon>編集</EditBaseInfoIcon>
              </EditLinkContainer>
            </>
          )}
        </StyledFlexContainer>
      </PCBaseInfoWrapper>

      <ChildInfoEditModal
        isOpen={isOpen}
        userChildren={children}
        close={onClose}
      />
    </>
  );
};

const ChildrenWrapper = styled.div<{ isFirstChild: boolean }>`
  margin-top: ${(props) => (props.isFirstChild ? 0 : "10px")};
`;

const ChildInfoTable = styled.table`
  margin-top: 0;
`;

const StyledContainerPc = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;

const StyledContainerSp = styled.div`
  @media (min-width: 500px) {
    display: none;
  }

  &:not(:last-child) {
    margin-bottom: 14px;
  }
`;

const StyledTHSp = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const StyledTdSp = styled.p`
  font-size: 12px;
`;

const StyledTableSp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
`;

export default ChildInfoContent;
