import React, { memo } from "react";
import { ArrowLeft } from "src/components/icons/ArrowLeft";
import styled from "styled-components";
import { usePolyfitHistory } from "src/hooks/router";
import { useSearchParams } from "react-router-dom";

type Props = {
  targetRoute: "BE_CHATROOM_LIST" | "RESIDENT_CHAT";
};

export const ChatRoomBackButton = memo(({ targetRoute }: Props) => {
  const history = usePolyfitHistory();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const onClick = () => {
    history.push({
      to: targetRoute,
      query: {
        tab: tab ?? undefined,
      },
    });
  };
  return (
    <EmptyButton onClick={onClick}>
      <ArrowLeft size={18} />
    </EmptyButton>
  );
});

const EmptyButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
`;
