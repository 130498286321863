import React from "react";

type Props = {
  size?: number;
};

export default function ExternalLinkIcon({ size }: Props) {
  return (
    <svg
      width={size ? `${size}px` : "24px"}
      height={size ? `${size}px` : "24px"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#231f20">
        <path d="m20 11a1 1 0 0 0 -1 1v6a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1-1v-12a1 1 0 0 1 1-1h6a1 1 0 0 0 0-2h-6a3 3 0 0 0 -3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-6a1 1 0 0 0 -1-1z" />
        <path d="m16 5h1.58l-6.29 6.28a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l6.29-6.28v1.58a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-4a1 1 0 0 0 -1-1h-4a1 1 0 0 0 0 2z" />
      </g>
    </svg>
  );
}
