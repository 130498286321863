import React, { useState } from "react";
import styled from "styled-components";
import { RemandPostModal } from "../../pages/pta/post/PostUnApprovedPage";
import { PostType } from "../../hooks/api/post";
import { Button } from "../../components/Button3";
import { isApprovedByMe } from "./ApproveButton";

type Props = {
  post: PostType;
  refetch: () => void;
};

export const RemandButton = ({ post, refetch }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <>
      <StyledButton
        size="large"
        color="dark"
        fill={true}
        onClick={() => setIsOpenModal(true)}
        disabled={isApprovedByMe(post)}
      >
        差し戻し
      </StyledButton>
      {isOpenModal && (
        <RemandPostModal
          post={post}
          onClose={() => {
            setIsOpenModal(false);
          }}
          refetch={refetch}
        />
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  width: 112px;
`;
