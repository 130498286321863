export const withinWalkMinuteLabel = (value: number | undefined): string => {
  if (value === undefined) {
    return "-";
  } else if (value <= 1) {
    return "1分以内";
  } else if (value <= 5) {
    return "5分以内";
  } else if (value <= 10) {
    return "10分以内";
  } else if (value <= 15) {
    return "15分以内";
  } else if (value <= 20) {
    return "20分以内";
  } else if (value <= 30) {
    return "30分以内";
  } else {
    return "30分以上";
  }
};
