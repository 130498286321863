import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryOptions } from "src/hooks/query/QueryOptions";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import { TeacherFilterType } from "src/hooks/api/users";
import * as usersApi from "src/apiClients/users";

export function useTeacherUsers(
  condition: {
    q?: string;
    filter?: TeacherFilterType;
  },
  queryOptions: QueryOptions = {}
) {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "users", "teacherUsers", apiContext, condition],
    queryFn: async () => {
      return await usersApi.getTeacherUsers(condition.q, condition.filter);
    },
    ...queryOptions,
  });

  return {
    teachers: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
}

export function useInvalidateTeacherUsers() {
  const queryClient = useQueryClient();

  function invalidateTeacherUsers() {
    queryClient.invalidateQueries({
      queryKey: ["api", "users", "teacherUsers"],
    });
  }

  return {
    invalidateTeacherUsers,
  };
}
