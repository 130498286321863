// アンケート確認モーダルで使用する共通のstyled components

import styled from "styled-components";

// 各質問を囲む
export const QuestionContainer = styled.div`
  /* box-style */
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(245, 247, 250, 1);
  margin: 8px;
`;

// 質問文や補足説明のテキスト
export const QuestionTextField = styled.div`
  width: 100%;
  padding: 3px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
`;
