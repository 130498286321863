import React, { useState } from "react";
import styled from "styled-components";
import { PostType, deletePost } from "../../hooks/api/post";
import {
  ModalPortalProps,
  ModalPortal,
  ModalSubmitButtons,
} from "../../components/Modal";
import { ModalHeader } from "./CreatePostModal";
import { useToast } from "src/components/Toast";
import { logger } from "src/utils/logger";

export const DeletePostConfirmModal = ({
  post,
  onClose,
  refetch,
}: {
  post: PostType;
  onClose: () => void;
  refetch: () => void;
} & ModalPortalProps) => {
  // 削除時処理
  const toast = useToast();
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async () => {
    setDoubleClickBlocked(true);
    try {
      await deletePost(post.id);
      await refetch();
      onClose();
      toast.success("削除しました");
    } catch (error) {
      logger.error(error);
      toast.success("削除に失敗しました");
    } finally {
      setDoubleClickBlocked(false);
    }
  };
  return (
    <ModalPortal onClose={onClose}>
      <ModalTop>
        <ModalHeader>投稿削除</ModalHeader>
      </ModalTop>
      投稿を削除しますか？
      <br />
      削除すると元に戻せません
      <ModalSubmitButtons
        submitText="削除"
        onSubmit={onSubmit}
        onCancel={onClose}
        disabled={doubleClickBlocked}
      />
    </ModalPortal>
  );
};

const ModalTop = styled.div`
  height: auto;
  position: relative;
`;
