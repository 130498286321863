/* eslint-disable no-irregular-whitespace */
import React, { useState } from "react";
import styled from "styled-components";
import { PCBaseInfoWrapper } from "../../../pages/pta/admin/ProfilePage";
import { useFirebaseUserContext } from "src/hooks/context/firebaseUser";
import { ChangePasswordModal } from "./ChangePasswordModal";
import { ChangeEmailModal } from "./ChangeEmailModal";
import { Button } from "../../../components/Button";
import { SetPasswordModal } from "./SetPasswordModal";
import { RemovePasswordModal } from "./RemovePasswordModal";
import { UnlinkGoogleModal } from "./UnlinkGoogleModal";
import { LinkGoogleModal } from "./LinkGoogleModal";
import { Heading2 } from "src/components/Typo";

const LoginSetting = () => {
  const { firebaseUser } = useFirebaseUserContext();

  if (!firebaseUser) return null;

  const hasPasswordProvider = firebaseUser.providerData.some(
    (p) => p.providerId === "password"
  );

  const googleProvider = firebaseUser.providerData.find(
    (p) => p.providerId === "google.com"
  );

  // providerが1つ以下の場合はunlinkできないようにする
  const unlinkEnabled = firebaseUser.providerData.length > 1;

  // モーダル関連
  const [changeEmailModalOpened, setChangeEmailModalOpened] =
    useState<boolean>(false);

  const [setPasswordModalOpened, setSetPasswordModalOpened] =
    useState<boolean>(false);

  const [changePasswordModalOpened, setChangePasswordModalOpened] =
    useState<boolean>(false);

  const [removePasswordModalOpened, setRemovePasswordModalOpened] =
    useState<boolean>(false);

  const [linkGoogleModalOpened, setLinkGoogleModalOpened] =
    useState<boolean>(false);

  const [unlinkGoogleModalOpened, setUnlinkGoogleModalOpened] =
    useState<boolean>(false);

  return (
    <PCBaseInfoWrapper>
      <LoginSettingContainer>
        <Title>アカウント設定</Title>
        <SettingSection>
          <SettingMainItem>
            <SettingItemLabel>
              <SettingItemName>メールアドレス</SettingItemName>
              <SettingItemDescription>
                {firebaseUser.email}
              </SettingItemDescription>
            </SettingItemLabel>
            <SettingAction>
              <Button
                size="small"
                color="subPrimary"
                onClick={() => setChangeEmailModalOpened(true)}
              >
                変更
              </Button>
            </SettingAction>
          </SettingMainItem>
          <SettingItemDetailDescription>
            polyfitからのお知らせが届きます
          </SettingItemDetailDescription>
        </SettingSection>
        {hasPasswordProvider ? (
          <SettingMainItem>
            <SettingItemLabel>
              <SettingItemName>パスワード</SettingItemName>
              <SettingItemDescription>
                {hasPasswordProvider ? "********" : "未設定"}
              </SettingItemDescription>
            </SettingItemLabel>
            <SettingAction>
              <Button
                size="small"
                color="subPrimary"
                onClick={() => setChangePasswordModalOpened(true)}
              >
                変更
              </Button>
              <Button
                size="small"
                color="danger"
                disabled={!unlinkEnabled}
                onClick={() => setRemovePasswordModalOpened(true)}
              >
                無効化
              </Button>
            </SettingAction>
          </SettingMainItem>
        ) : (
          <SettingSection>
            <SettingMainItem>
              <SettingItemLabel>
                <SettingItemName>パスワードログイン</SettingItemName>
              </SettingItemLabel>
              <SettingAction>
                <Button
                  size="small"
                  color="subPrimary"
                  onClick={() =>
                    setSetPasswordModalOpened(!setPasswordModalOpened)
                  }
                >
                  有効化
                </Button>
              </SettingAction>
            </SettingMainItem>
            <SettingItemDetailDescription>
              メールアドレスとパスワードでもログインができるようになります。
              <br />
              Googleアカウントでのログインも引き続きご利用いただけます。
            </SettingItemDetailDescription>
          </SettingSection>
        )}
        <SettingSection>
          <SettingMainItem>
            <SettingItemLabel>
              <SettingItemName>Googleアカウント連携</SettingItemName>
            </SettingItemLabel>
            <SettingAction>
              <div>
                {googleProvider ? (
                  <>
                    <Button
                      size="small"
                      color="subPrimary"
                      disabled={!unlinkEnabled}
                      onClick={() => setUnlinkGoogleModalOpened(true)}
                    >
                      連携中
                    </Button>
                  </>
                ) : (
                  <Button
                    size="small"
                    color="subPrimary"
                    onClick={() => setLinkGoogleModalOpened(true)}
                  >
                    連携
                  </Button>
                )}
              </div>
            </SettingAction>
          </SettingMainItem>
          <SettingItemDetailDescription>
            {googleProvider
              ? `Googleアカウント（${googleProvider?.email}）と連携されています`
              : "お持ちのGoogleアカウントでログインができるようになります"}
          </SettingItemDetailDescription>
        </SettingSection>
      </LoginSettingContainer>

      {changeEmailModalOpened && (
        <ChangeEmailModal
          firebaseUser={firebaseUser}
          isOpen={changeEmailModalOpened}
          close={() => {
            setChangeEmailModalOpened(false);
          }}
          hasPasswordProvider={hasPasswordProvider}
          openSetPasswordModal={() => setSetPasswordModalOpened(true)}
        />
      )}
      {setPasswordModalOpened && (
        <SetPasswordModal
          firebaseUser={firebaseUser}
          isOpen={setPasswordModalOpened}
          close={() => {
            setSetPasswordModalOpened(false);
          }}
        />
      )}
      {changePasswordModalOpened && (
        <ChangePasswordModal
          isOpen={changePasswordModalOpened}
          close={() => {
            setChangePasswordModalOpened(false);
          }}
        />
      )}
      {removePasswordModalOpened && (
        <RemovePasswordModal
          firebaseUser={firebaseUser}
          isOpen={removePasswordModalOpened}
          close={() => {
            setRemovePasswordModalOpened(false);
          }}
        />
      )}
      {linkGoogleModalOpened && (
        <LinkGoogleModal
          firebaseUser={firebaseUser}
          isOpen={linkGoogleModalOpened}
          close={() => {
            setLinkGoogleModalOpened(false);
          }}
        />
      )}
      {unlinkGoogleModalOpened && (
        <UnlinkGoogleModal
          firebaseUser={firebaseUser}
          isOpen={unlinkGoogleModalOpened}
          close={() => {
            setUnlinkGoogleModalOpened(false);
          }}
        />
      )}
    </PCBaseInfoWrapper>
  );
};

const LoginSettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled(Heading2)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  color: #343741;
  margin-bottom: 12px;
`;

const SettingMainItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SettingItemLabel = styled.div`
  font-weight: 700;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
`;

const SettingItemName = styled.span`
  font-weight: 700;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
`;

const SettingItemDescription = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: #808080;
`;
const SettingSection = styled.div``;
const SettingItemDetailDescription = styled.p`
  font-weight: 400;
  font-family: "Inter";
  font-style: normal;
  font-size: 12px;
  line-height: 19px;
  color: #808080;
  margin-top: 4px;
`;

const SettingAction = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 10px;
`;

export default LoginSetting;
