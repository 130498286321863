import { createGlobalStyle } from "styled-components";

/**
 * Theme color config.
 */
export const colorsPallet = {
  primary: "#0077CC",
  accent: "#F04E98",
  success: "#00BFB3",
  warning: "#FEC514",
  danger: "#BD271E",
  emptyShade: "#fff",
  lightestShade: "#F5F7FA",
  lightShade: "#D3DAE6",
  mediumShade: "#98A2B3",
  darkShade: "#69707D",
  darkestShade: "#343741",
  fullShade: "#000000",
} as const;

export const colorTokens = {
  text: "#343741",
  title: "#1a1c21",
  textSubdued: colorsPallet.darkShade,
  disabledText: "#ABB4C4",
  link: "#0071C2",
  successText: "#007E77",
  accentText: "#C4407C",
  warningText: "#8A6A0A",
  dangerText: "#BD271E",
} as const;

export const states = {
  pageBackgroundColor: "#F5F7FA",
  focusBackgroundColor: `rgba(${colorsPallet.primary}, .1)`,
  colorHighlight: `rgba(${colorsPallet.warning}, .1)`,
} as const;

export const typo = {
  heading1: "34px",
  heading2: "27px",
  heading3: "22px",
  heading4: "16px",
  heading5: "14px",
  heading6: "12px",
  paragraph: "16px",
  small: "14px",
  code: "14.4px",
} as const;
export const lineHeight = {
  heading1: "48px",
  heading2: "40px",
  heading3: "32px",
  heading4: "24px",
  heading5: "14px",
  heading6: "12px",
  paragraph: "24px",
  small: "24px",
  code: "24px",
};

export const shadow = {
  bottomDefault:
    "0px 1px 5px rgba(0, 0, 0, 0.1), 0px 3.6px 13px rgba(0, 0, 0, 0.07), 0px 8.4px 23px rgba(0, 0, 0, 0.06), 0px 23px 35px rgba(0, 0, 0, 0.05)",
  bottomSmall:
    "0px 0.7px 1.4px rgba(0, 0, 0, 0.07), 0px 1.9px 4px rgba(0, 0, 0, 0.05), 0px 4.5px 10px rgba(0, 0, 0, 0.05)",
  bottomMedium:
    "0px 0.9px 4px -1px rgba(0, 0, 0, 0.08), 0px 2.6px 8px -1px rgba(0, 0, 0, 0.06), 0px 5.7px 12px -1px rgba(0, 0, 0, 0.05), 0px 15px 15px -1px rgba(0, 0, 0, 0.04)",
  bottomLarge:
    "0px 2.7px 9px rgba(0, 0, 0, 0.13), 0px 9.4px 24px rgba(0, 0, 0, 0.09), 0px 21.8px 43px rgba(0, 0, 0, 0.08)",
  topLarge:
    "0px -2.7px 9px rgba(0, 0, 0, 0.13), 0px -9.4px 24px rgba(0, 0, 0, 0.09), 0px -21.8px 43px rgba(0, 0, 0, 0.08)",
  flat: "0px 0px 0.8px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 17px rgba(0, 0, 0, 0.03)",
  slight:
    "0px 0.8px 0.8px rgba(0, 0, 0, 0.04), 0px 2.3px 2px rgba(0, 0, 0, 0.03)",
  borderTop: "inset 0px 1px 0px #D3DAE6",
  borderRight: "inset -1px 0px 0px #D3DAE6",
  borderBottom: "inset 0px -1px 0px #D3DAE6",
  borderLeft: "inset 1px 0px 0px #D3DAE6",
} as const;

export const form = {
  background: {
    focus: "#fff",
    normal: "#FBFCFD",
    disabled: "#EEF2F7",
    readonly: "rgba(211, 218, 230, 0.05)",
  },
  borderColor: "1px solid rgba(19, 34, 149, 0.1)",
  borderRadius: "6px",
} as const;

export const buttons = {
  primary: {
    bg: "rgba(171, 180, 196, 0.1)",
    color: "#ABB4C4",
    fillBg: "#0071C2",
    fill: colorsPallet.emptyShade,
  },
  subPrimary: {
    bg: "#C4DCF6",
    color: "#0071C2",
    fillBg: "#C4DCF6",
    fill: "#0071C2",
  },
  secondary: {
    bg: "rgba(0, 191, 179, 0.2)",
    color: "#00726B",
    fillBg: "#00BFB3",
    fill: colorsPallet.fullShade,
  },
  warning: {
    bg: "rgba(245, 167, 0, 0.2)",
    color: "#8C5F00",
    fillBg: "#FEC514",
    fill: colorsPallet.fullShade,
  },
  danger: {
    bg: "rgba(189, 39, 30, 0.2)",
    color: "#B4251D",
    fillBg: "#BD271E",
    fill: colorsPallet.emptyShade,
  },
  accent: {
    bg: "rgba(196, 64, 124, 0.2)",
    color: "#a8376a",
    fillBg: "#C4407C",
    fill: colorsPallet.emptyShade,
  },
  text: {
    bg: "rgba(105, 112, 125, 0.2)",
    color: "#5A606B",
    fillBg: "#69707D",
    fill: colorsPallet.emptyShade,
  },
  disabled: {
    bg: "rgba(105, 112, 125, 0.2)",
    color: "#5A606B",
    fillBg: "rgba(171, 180, 196, 0.1)",
    fill: colorTokens.disabledText,
  },
  ghost: {
    bg: "rgba(255, 255, 255, 0.2)",
    color: "#8F8F92",
    fillBg: "rgba(171, 180, 196, 0.1)",
    fill: colorTokens.disabledText,
  },
  ghostAndDisabled: {
    bg: "rgba(105, 112, 125, 0.3)",
    color: "#A1A5AE",
    fillBg: "rgba(171, 180, 196, 0.1)",
    fill: colorTokens.disabledText,
  },
  dark: {
    bg: "rgba(171, 180, 196, 0.1)",
    color: "#ABB4C4",
    fillBg: "#69707D",
    fill: colorsPallet.emptyShade,
  },
};

export const panels = {
  plain: "#FFFFFF",
  subdued: "#F5F7FA",
  accent: "#FFEBF4",
  primary: "#E6F0FC",
  success: "#E6F9F7",
  warning: "#FEF6E6",
  danger: "#F8E9E9",
};

export const ThemeStyles = createGlobalStyle`
  html, body {
    background: ${colorsPallet.lightestShade};
    color: ${colorTokens.title}
  }

  a {
    color: ${colorTokens.text};

  }
`;
