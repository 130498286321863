import { useMutation, useQuery } from "@tanstack/react-query";
import { getDownloadUrl } from "src/apiClients/storage";
import { QueryOptions } from "./QueryOptions";
import { useApiContext } from "src/apiClients/client";
import { useMemo } from "react";
import {
  createOrUpdateSignatureTemplate,
  getSignatureTemplate,
  uploadPdf,
} from "src/apiClients/signature";
import { SignatureTemplateRequest } from "@shared/types/signatureTemplate";
import { useToast } from "src/components/Toast";

export type GetSignatureTemplatePdfArgs = {
  pdfFilePath: string;
  fileName: string;
};

export function useSignatureTemplatePdf(
  args: GetSignatureTemplatePdfArgs,
  queryOptions: QueryOptions = {}
) {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "signatureTemplate", "pdf", apiContext, args],
    queryFn: async () => {
      return await getDownloadUrl(args.pdfFilePath, args.fileName);
    },
    ...queryOptions,
  });

  return {
    pdfUrl: useMemo(() => query.data || undefined, [query.data]),
    ...query,
  };
}

export function useSignatureTemplate(
  { type }: SignatureTemplateRequest,
  queryOptions: QueryOptions = {}
) {
  const { apiContext } = useApiContext();
  const toast = useToast();

  const query = useQuery({
    queryKey: ["api", "signature", "getSignatureTemplate", apiContext, type],
    queryFn: async () => {
      return await getSignatureTemplate({
        type,
      });
    },
    ...queryOptions,
  });

  const mutation = useMutation({
    mutationFn: async (file: File) => {
      if (file) {
        const filePath = await uploadPdf({ pdfFile: file });
        if (!filePath) throw new Error("couldnt upload pdf file");
        return await createOrUpdateSignatureTemplate({
          type,
          pdfFilePath: filePath,
        });
      }
    },
    onSuccess: async () => {
      await query.refetch();
      toast.success("入会規約の保存をしました");
    },
    onError: async () => {
      toast.error("入会規約の保存に失敗しました");
    },
  });

  return {
    query,
    mutation,
  };
}
