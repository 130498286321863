import { useState, useCallback } from "react";
import { User } from "../../apiClients/users";

const useUserSelection = (users: User[]) => {
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const isUserSelected = useCallback(
    (userId: string) => selectedUserIds.includes(userId),
    [selectedUserIds]
  );

  const toggleUserSelection = useCallback((userId: string) => {
    setSelectedUserIds((currentSelected) =>
      currentSelected.includes(userId)
        ? currentSelected.filter((id) => id !== userId)
        : [...currentSelected, userId]
    );
  }, []);

  const handleSelectAllClick = useCallback(() => {
    if (users.length === selectedUserIds.length) {
      unselectAllUsers();
    } else {
      selectAllUsers();
    }
  }, [selectedUserIds]);

  const selectAllUsers = useCallback(() => {
    setSelectedUserIds(users.map((user) => user.id));
  }, [users]);

  const unselectAllUsers = useCallback(() => {
    setSelectedUserIds([]);
  }, []);

  const selectedUsers = users.filter((user) => {
    return selectedUserIds.includes(user.id);
  });

  return {
    selectedUserIds,
    selectedUsers,
    isUserSelected,
    toggleUserSelection,
    selectAllUsers,
    unselectAllUsers,
    handleSelectAllClick,
  };
};

export default useUserSelection;
