import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "../../QueryOptions";
import { useMemo } from "react";
import { getAccount } from "src/apiClients/boardEducation/auth";
import { BEAccount } from "@shared/types/boardEducation/auth";

export const useGetAccount = (queryOptions: QueryOptions = {}) => {
  const query = useQuery<BEAccount, Error>({
    queryKey: ["api", "getAccount"],
    queryFn: () => getAccount(),
    ...queryOptions,
  });

  return {
    account: useMemo(() => query.data, [query.data]),
    ...query,
  };
};
