import { PUBLIC_ROUTE_DEFS, publicRouters } from "./public";
import {
  AUTHENTICATED_ROUTE_DEFS,
  authenticatedRouters,
} from "./authenticated";
import { REGISTER_ROUTE_DEFS, registerRouters } from "./registerPta";
import {
  REGISTER_RESIDENT_ROUTE_DEFS,
  registerResidentRouters,
} from "./registerResident";
import { PTA_ROUTE_DEFS, ptaRouters } from "./pta";
import { INTERNAL_ADMIN_ROUTE_DEFS, internalAdminRouters } from "./internal";
import { RESIDENT_ROUTE_DEFS, residentRouters } from "./resident";
import { COMMON_ROUTE_DEFS, commonRouters } from "./common";
import { BE_ADMIN_ROUTE_DEFS, beAdminRouters } from "./boardEducation";

/**
 * 型関連その他
 */
export const ROUTE_DEFS = {
  ...PUBLIC_ROUTE_DEFS,
  ...AUTHENTICATED_ROUTE_DEFS,
  ...REGISTER_ROUTE_DEFS,
  ...REGISTER_RESIDENT_ROUTE_DEFS,
  ...PTA_ROUTE_DEFS,
  ...BE_ADMIN_ROUTE_DEFS,
  ...INTERNAL_ADMIN_ROUTE_DEFS,
  ...RESIDENT_ROUTE_DEFS,
  ...COMMON_ROUTE_DEFS,
};

export type Routes = keyof typeof ROUTE_DEFS;
export type Route<T extends Routes> = (typeof ROUTE_DEFS)[T];
export type RouteQuery<T extends Routes> = Route<T>["_q"];
export type Router = {
  path: string;
  title: string;
  component: React.ComponentType<unknown>;
  layout: React.FC<{ children?: React.ReactNode }>;
  route: RouteType;
};

export const RouteType = {
  PUBLIC: "PUBLIC",
  AUTHENTICATED: "AUTHENTICATED",
  REGISTER_PTA: "REGISTER_PTA",
  REGISTER_CS: "REGISTER_CS",
  PTA: "PTA",
  PTA_ADMIN: "PTA_ADMIN",
  CS_MEMBER: "CS_MEMBER",
  CS_GUEST: "CS_GUEST",
  CS_MEMBER_OR_CS_GUEST: "CS_MEMBER_OR_CS_GUEST",
  CS_ADMIN: "CS_ADMIN",
  PTA_OR_CS_MEMBER: "PTA_OR_CS_MEMBER",
  PTA_OR_CS_MEMBER_OR_CS_GUEST: "PTA_OR_CS_MEMBER_OR_CS_GUEST",
  BE_ADMIN: "BE_ADMIN",
  INTERNAL_ADMIN: "INTERNAL_ADMIN",
} as const;
export type RouteType = (typeof RouteType)[keyof typeof RouteType];

export function createRoute<
  Query extends { [key: string]: string } | undefined = undefined
>(props: Router) {
  return props as Router & { _q: Query };
}

// ログインが必要ないルート
export const publicRoutes = [...publicRouters];

// ログインは必要だがorganizationの認可は必要ないルート
export const authenticatedRoutes = [...authenticatedRouters];

// organizationの認可が必要なルート
export const authorizedRoutes = [
  ...registerRouters,
  ...registerResidentRouters,
  ...ptaRouters,
  ...internalAdminRouters,
  ...residentRouters,
  ...beAdminRouters,
  ...commonRouters,
];

export const allRoutes = [
  ...publicRoutes,
  ...authenticatedRoutes,
  ...authorizedRoutes,
];
