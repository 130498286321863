import React, { useCallback, useMemo, useState } from "react";
import { usePolyfitHistory } from "../../../hooks/router";
import styled from "styled-components";
import * as Typo from "../../../components/Typo";
import { Margin } from "../../../components/Margin";
import { ResidentInviteMemberPageFormData } from "./ResidentInviteMemberPage";
import { Button } from "../../../components/Button3";
import { useToast } from "../../../components/Toast";
import {
  InviteResident,
  inviteResidentMember,
} from "../../../apiClients/invitation";
import { errorCodeToMessage } from "../../../utils/errorCodeToMessage";
import { getAgeGroupText } from "../../../utils/types/ageGroup";
import { UpdateInviteCommunityRolesRequest } from "@shared/types/auth";
import { useOrganizationCommunity } from "src/hooks/recoil/organization";
import { UserType } from "../../../../../server/src/@shared/types/userType";
import { getCommunityUserTypeText } from "../../../features/Member/Community/utils/utils";
import { logger } from "src/utils/logger";

type Props = {
  formData: ResidentInviteMemberPageFormData;
};

export default function ResidentInviteMemberConfirmPage({ formData }: Props) {
  const history = usePolyfitHistory();
  const toast = useToast();
  const [isInvited, setIsInvited] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const {
    elementarySchoolCommunities,
    juniorHighSchoolCommunities,
    isLoading,
  } = useOrganizationCommunity();
  const mainElementarySchool = elementarySchoolCommunities.find(
    (community) => community.id === formData.stepTwo.mainElementarySchool
  );
  const mainJuniorHighSchool = juniorHighSchoolCommunities.find(
    (community) => community.id === formData.stepTwo.mainJuniorHighSchool
  );
  const mainElementarySchoolUserType =
    formData.stepTwo.mainElementarySchoolType;
  const mainJuniorHighUserType = formData.stepTwo.mainJuniorHighSchoolType;
  const elementarySchools = elementarySchoolCommunities.filter((community) =>
    formData.stepTwo.elementarySchools?.includes(community.id)
  );
  const juniorHighSchools = juniorHighSchoolCommunities.filter((community) =>
    formData.stepTwo.juniorHighSchools?.includes(community.id)
  );

  const allData = useMemo(() => {
    const update: UpdateInviteCommunityRolesRequest = [];
    update.push(
      ...(formData.stepTwo.mainElementarySchool &&
      formData.stepTwo.mainElementarySchoolType
        ? [
            {
              roleName: "CSMember",
              communityId: formData.stepTwo.mainElementarySchool,
              isMain: true,
              mainElementarySchoolType:
                formData.stepTwo.mainElementarySchoolType,
            },
          ]
        : []),
      ...(formData.stepTwo.mainJuniorHighSchool &&
      formData.stepTwo.mainJuniorHighSchoolType
        ? [
            {
              roleName: "CSMember",
              communityId: formData.stepTwo.mainJuniorHighSchool,
              isMain: true,
              mainJuniorHighSchoolType:
                formData.stepTwo.mainJuniorHighSchoolType,
            },
          ]
        : []),
      ...(formData.stepTwo.elementarySchools?.map((school) => {
        return {
          roleName: "CSMember",
          communityId: school,
          isMain: false,
          type: UserType.RESIDENT,
        };
      }) ?? []),
      ...(formData.stepTwo.juniorHighSchools?.map((school) => {
        return {
          roleName: "CSMember",
          communityId: school,
          isMain: false,
          type: UserType.RESIDENT,
        };
      }) ?? [])
    );
    return {
      ...formData.stepOne,
      communityRoles: update,
    };
  }, [formData]);

  const sendInvitation = useCallback(async () => {
    try {
      setIsInviting(true);
      await inviteResidentMember(allData as InviteResident);
      toast.success("招待メールを送信しました");
      setIsInvited(true);
      setIsInviting(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(errorCodeToMessage(error.message));
        logger.error(error);
      }

      setIsInvited(false);
      setIsInviting(false);
    }
  }, [formData]);

  const onSubmit = () => {
    sendInvitation();
  };

  const onEdit = () => {
    history.push({ to: "RESIDENT_INVITATION", query: { step: "1" } });
  };

  if (!allData || isLoading) return <></>;

  return (
    <>
      <TitleWrapper>
        <Typo.Heading3>こちらの内容でメンバーを招待しますか？</Typo.Heading3>
      </TitleWrapper>

      <StyledContainer>
        <PreviewCard>
          <ProfileLabel>メールアドレス</ProfileLabel>
          <ProfileContent>{allData.email}</ProfileContent>
          <Margin marginBottom={32} />
          <ProfileLabel>名前</ProfileLabel>
          <ProfileContent>
            {allData.lastName} {allData.firstName}（{allData.lastNameKana}{" "}
            {allData.firstNameKana}）
          </ProfileContent>
          <Margin marginBottom={32} />
          <ProfileLabel>年代</ProfileLabel>
          <ProfileContent>{getAgeGroupText(allData.ageGroup)}</ProfileContent>
          <Margin marginBottom={32} />
          <ProfileLabel>性別</ProfileLabel>
          <ProfileContent>
            {allData.gender === "MALE" ? "男性" : "女性"}
          </ProfileContent>
          <Margin marginBottom={32} />
          <ProfileLabel>住所</ProfileLabel>
          <ProfileContent>〒{allData.postalCode}</ProfileContent>
          <ProfileContent>
            {allData.prefecture}
            {allData.city}
            {allData.address1}
            {allData.address2}
          </ProfileContent>
          <Margin marginBottom={32} />
          <ProfileLabel>最寄りの小学校区</ProfileLabel>
          <ProfileContent>{mainElementarySchool?.name ?? ""}</ProfileContent>
          <Margin marginBottom={20} />
          <ProfileLabel>最寄りの小学校区での役割</ProfileLabel>
          <ProfileContent>
            {getCommunityUserTypeText(mainElementarySchoolUserType)}
          </ProfileContent>
          <Margin marginBottom={20} />
          <ProfileLabel>最寄りの中学校区</ProfileLabel>
          <ProfileContent>{mainJuniorHighSchool?.name ?? ""}</ProfileContent>
          <Margin marginBottom={20} />
          <ProfileLabel>最寄りの中学校区での役割</ProfileLabel>
          <ProfileContent>
            {getCommunityUserTypeText(mainJuniorHighUserType)}
          </ProfileContent>
          <Margin marginBottom={20} />
          <ProfileLabel>追加小学校区</ProfileLabel>
          {elementarySchools.length > 0 &&
            elementarySchools.map((community) => (
              <ProfileContent key={community.id}>
                {community.name}
              </ProfileContent>
            ))}
          <Margin marginBottom={20} />
          <ProfileLabel>追加中学校区</ProfileLabel>
          {elementarySchools.length > 0 &&
            juniorHighSchools.map((community) => (
              <ProfileContent key={community.id}>
                {community.name}
              </ProfileContent>
            ))}
        </PreviewCard>
        <NextButton
          color="primary"
          fill
          disabled={isInvited || isInviting}
          onClick={onSubmit}
        >
          {isInviting ? "招待中..." : isInvited ? "招待済み" : "この内容で招待"}
        </NextButton>
        <Margin marginTop={8} />
        {!isInvited && (
          <Button
            color="subPrimary"
            style={{ width: "100%", padding: "9.5px 0" }}
            onClick={onEdit}
          >
            編集する
          </Button>
        )}
      </StyledContainer>
    </>
  );
}

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const StyledContainer = styled.div`
  max-width: 720px;
  margin: 0 auto;
  padding-bottom: 88px;

  @media (max-width: 1024px) {
    max-width: auto;
  }
`;

const PreviewCard = styled.div`
  background: white;
  padding: 24px;
  margin-top: 16px;
`;

const ProfileLabel = styled.p`
  color: #343741;
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: bold;
`;

const ProfileContent = styled.p`
  color: #343741;
  font-size: 18px;
`;

const NextButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
  padding: 9.5px 0;
`;
