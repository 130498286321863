import { Container, Row, Col, BaseCSS } from "styled-bootstrap-grid";
import styled from "styled-components";
export { Container, Row, Col, BaseCSS };

export const BREAKPOINTS = {
  SP: "480px",
  TABLET: "768px",
  PC: "1024px",
  CHAT_MAX: "800px", //従来のBreakpointsではchatのUIが崩れるため追加
};

// FIXME: do not use "export const" with styled-components
export const PcViewOnly = styled.div`
  @media (max-width: ${BREAKPOINTS.PC}) {
    display: none;
  }
`;

// FIXME: do not use "export const" with styled-components
export const MobileViewOnly = styled.div`
  @media (min-width: ${BREAKPOINTS.PC}) {
    display: none;
    /* 1024pxのときはSPを表示する */
    @media (max-width: ${BREAKPOINTS.PC}) {
      display: block;
    }
  }
`;
