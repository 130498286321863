import React, { memo } from "react";
import { SidebarMenu } from "./SidebarMenu";
import { useLocation } from "react-router-dom";
import { HomeIcon } from "../../icons/Home";
import { MailIcon } from "../../icons/MailIcon";
import { MemberListIcon } from "../../icons/MemberListIcon";
import { InvitationPanel } from "../../../features/Invitation/InvitationPanel";
import { SignatureIcon } from "../../icons/SignatureIcon";
import { LinkIcon } from "../../icons/LinkIcon";

export const PTAAdminSidebar = memo(() => {
  const { pathname } = useLocation();
  return (
    <>
      <SidebarMenu
        to="/pta"
        isActive={pathname == "/pta" || pathname == "/pta/disabled_members"}
        icon={<HomeIcon />}
        text="ホーム"
      />
      <SidebarMenu
        to="/pta/post"
        isActive={pathname.startsWith("/pta/post")}
        icon={<MailIcon />}
        text="連絡"
      />
      <SidebarMenu
        to="/pta/list"
        isActive={pathname.startsWith("/pta/list")}
        icon={<MemberListIcon />}
        text="名簿"
      />
      <InvitationPanel />
      <SidebarMenu
        isActive={
          pathname.startsWith("/pta/community_setting") ||
          pathname.startsWith("/pta/admin/signature_templates")
        }
        icon={<SignatureIcon />}
        text="設定"
        menus={[
          {
            text: "所属役職設定",
            to: "/pta/community_setting",
          },
          {
            text: "入会規約設定",
            to: "/pta/admin/signature_templates",
          },
        ]}
      />
      <SidebarMenu
        to="/pta/link/list"
        isActive={pathname === "/pta/link/list"}
        icon={<LinkIcon />}
        text="リンク"
      />
    </>
  );
});
