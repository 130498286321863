import { createRoute } from "..";
import AppDefaultLayout from "../../layouts/AppDefaultLayout";
import CommunityEditPage from "../../pages/internal/community/CommunityEditPage";
import CommunityListPage from "../../pages/internal/community/CommunityListPage";
import CommunityOrganizationListPage from "../../pages/internal/community/CommunityOrganizationListPage";
import CommunityUserListPage from "../../pages/internal/community/CommunityUserListPage";
import InvitationListPage from "../../pages/internal/organization/InvitationListPage";
import InvitationPage from "../../pages/internal/organization/InvitationPage";
import OrganizationCommunityListPage from "../../pages/internal/organization/OrganizationCommunityListPage";
import OrganizationEditPage from "../../pages/internal/organization/OrganizationEditPage";
import OrganizationListPage from "../../pages/internal/organization/OrganizationListPage";
import OrganizationUserListPage from "../../pages/internal/organization/OrganizationUserListPage";
import { ServiceType } from "../../utils/types/serviceType";
import { BoardEducationUserListPage } from "../../pages/internal/organization/boardEducation/BoardEducationUserListPage";
import { BoardEducationEditPage } from "../../pages/internal/organization/boardEducation/BoardEducationEditPage";

/**
 * INTERNAL_ADMINのみが通れるルート
 */
export const INTERNAL_ADMIN_ROUTE_DEFS = {
  INTERNAL_ORGANIZATION_LIST: createRoute({
    path: "/internal/organizations",
    title: "組織一覧",
    component: OrganizationListPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
  INTERNAL_ORGANIZATION_EDIT: createRoute<{ organizationId: string }>({
    path: "/internal/organizations/edit",
    title: "組織編集",
    component: OrganizationEditPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
  INTERNAL_ORGANIZATION_USER_LIST: createRoute<{ organizationId: string }>({
    path: "/internal/organizations/users",
    title: "組織のユーザー一覧",
    component: OrganizationUserListPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
  INTERNAL_ORGANIZATION_INVITATION: createRoute<{ organizationId: string }>({
    path: "/internal/organizations/invitation",
    title: "招待ページ",
    component: InvitationPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
  INTERNAL_ORGANIZATION_COMMUNITY_LIST: createRoute<{
    organizationId?: string;
  }>({
    path: "/internal/organizations/communities",
    title: "組織に所属するコミュニティ一覧",
    component: OrganizationCommunityListPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
  INTERNAL_COMMUNITY_LIST: createRoute({
    path: "/internal/communities",
    title: "コミュニティ一覧",
    component: CommunityListPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
  INTERNAL_COMMUNITY_ORGANIZATION_LIST: createRoute({
    path: "/internal/communities/organizations",
    title: "コミュニティに紐づく組織一覧",
    component: CommunityOrganizationListPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
  INTERNAL_COMMUNITY_EDIT: createRoute({
    path: "/internal/communities/edit",
    title: "コミュニティ編集",
    component: CommunityEditPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
  INTERNAL_COMMUNITY_USER_LIST: createRoute<{
    serviceType: ServiceType;
  }>({
    path: "/internal/communities/users",
    title: "コミュニティのユーザー一覧",
    component: CommunityUserListPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
  INTERNAL_INVITATION_LIST: createRoute({
    path: "/internal/invitations",
    title: "",
    component: InvitationListPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
  INTERNAL_ORGANIZATION_BOARD_EDUCATION_USERS: createRoute<{
    organizationId: string;
  }>({
    path: "/internal/organizations/board-education/users",
    title: "教育委員会ユーザー一覧ページ",
    component: BoardEducationUserListPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
  INTERNAL_BOARD_EDUCATION_EDIT: createRoute<{
    organizationId: string;
  }>({
    path: "/internal/organizations/board-education/edit",
    title: "教育委員会編集ページ",
    component: BoardEducationEditPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
};

export const internalAdminRouters = Object.values(INTERNAL_ADMIN_ROUTE_DEFS);
