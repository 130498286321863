import React from "react";

export const useFileDownload = () => {
  const download = React.useCallback((blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);
  }, []);

  const openAsSameTab = React.useCallback((blob: Blob) => {
    const fileUrl = URL.createObjectURL(blob);
    window.location.href = fileUrl;
  }, []);

  return { download, openAsSameTab };
};
