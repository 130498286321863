import React, { JSX } from "react";
import { OpenEndedSurveyResult } from "../../../apiClients/surveyResults";
import { Label } from "../../../components/form/Label";
import { QuestionTitle, SurveyResultContainer } from "./SurveyResultField";
import styled from "styled-components";

type Props = {
  surveyResult: OpenEndedSurveyResult;
};

export function OpenEndedSurveyResultField({
  surveyResult,
}: Props): JSX.Element {
  return (
    <SurveyResultContainer>
      <Label size="s" marginBottom={4}>
        質問
      </Label>
      <QuestionTitle>{surveyResult.question.text}</QuestionTitle>
      <Label size="s" marginBottom={4}>
        結果
      </Label>
      <Ul>
        {surveyResult.answers.map(
          (answer: { userId: string; text: string }, index: number) => (
            <Li key={index}>{answer.text}</Li>
          )
        )}
      </Ul>
    </SurveyResultContainer>
  );
}

const Ul = styled.ul`
  padding-left: 16px;
  word-wrap: break-word;
`;

const Li = styled.li`
  font-size: 14px;
  color: rgba(26, 28, 33, 1);
`;
