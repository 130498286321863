import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Header } from "../../../components/Header";
import ResidentInviteMemberBaseInfoPage, {
  ResidentInviteMemberBaseInfoFormData,
} from "./ResidentInviteMemberBaseInfoPage";
import ResidentInviteMemberConfirmPage from "./ResidentInviteMemberConfirmPage";
import RegidentInviteMemberRegisterCommunityPage from "./RegidentInviteMemberRegisterCommunityPage";
import { usePolyfitLocationQuery } from "../../../hooks/router";
import { FormValue as ResidentInviteMemberRegisterCommunityFormData } from "src/features/Member/Community/utils/form";

export interface ResidentInviteMemberPageFormData {
  stepOne: Partial<ResidentInviteMemberBaseInfoFormData>;
  stepTwo: Partial<ResidentInviteMemberRegisterCommunityFormData>;
}

export default function ResidentInviteMemberPage(): JSX.Element {
  const { step } = usePolyfitLocationQuery("RESIDENT_INVITATION", {
    step: "1",
  });
  const [formData, setFormData] = useState<ResidentInviteMemberPageFormData>(
    () => {
      // ローカルストレージからフォームの状態を読み込む
      const savedFormData = sessionStorage.getItem(
        "ResidentInviteMemberPageFormData"
      );
      return savedFormData
        ? JSON.parse(savedFormData)
        : {
            stepOne: {},
            stepTwo: {},
          };
    }
  );

  useEffect(() => {
    // フォームデータが変更されるたびにローカルストレージに保存
    sessionStorage.setItem(
      "ResidentInviteMemberPageFormData",
      JSON.stringify(formData)
    );
  }, [formData]);

  useEffect(() => {
    // 別ページに遷移する際にストレージから削除
    return () => {
      sessionStorage.removeItem("ResidentInviteMemberPageFormData");
    };
  }, []);

  // ステップごとのフォームデータ更新関数
  const updateFormData = (
    stepData: Partial<ResidentInviteMemberPageFormData>
  ) => {
    setFormData((prev) => ({ ...prev, ...stepData }));
  };

  return (
    <>
      <Header title="メンバー招待"></Header>
      <Body>
        {step === "1" && (
          <ResidentInviteMemberBaseInfoPage
            formData={formData}
            updateFormData={updateFormData}
          />
        )}
        {step === "2" && (
          <RegidentInviteMemberRegisterCommunityPage
            formData={formData}
            updateFormData={updateFormData}
          />
        )}
      </Body>
      {step === "3" && <ResidentInviteMemberConfirmPage formData={formData} />}
    </>
  );
}

const Body = styled.div`
  background-color: #fff;
`;
