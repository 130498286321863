import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Label } from "src/components/form/Label";
import styled from "styled-components";
import { Margin } from "src/components/Margin";
import { zodResolver } from "@hookform/resolvers/zod";
import { prefectureOptions } from "src/utils/types/prefecture";
import { useGeoCording } from "src/hooks/useGeocoding";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import { Button } from "src/components/Button";
import {
  useBoardEducationByOrganizationId,
  useInvalidateBoardEducation,
} from "src/hooks/query/internal/boardEducation/boardEducation";
import { useCurrentUser } from "src/hooks/recoil/user";
import { updateBoardEducation } from "src/apiClients/boardEducation";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { useOrganizationById } from "src/hooks/api/internal/organizations";

const FormValueSchema = z.object({
  postalCode: z
    .string()
    .min(1, "入力必須項目です")
    .regex(
      new RegExp("^[0-9]{7}"),
      "ハイフンは入力せず、半角文字のみで入力してください"
    ),
  prefecture: z.string().min(1, "入力必須項目です"),
  city: z.string().min(1, "入力必須項目です"),
  address1: z
    .string()
    .min(1, "入力必須項目です")
    .refine(
      (value) => {
        return /[0-9０-９〇一二三四五六七八九十]+/.test(value);
      },
      {
        message: "住所を最後まで入力してください",
      }
    ),
  address2: z.string().nullable(),
  latitude: z.number(),
  longitude: z.number(),
});

export type FormData = z.infer<typeof FormValueSchema>;

export const BoardEducationEditPage = () => {
  const history = usePolyfitHistory();
  const loginUser = useCurrentUser();
  const toast = useToast();
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const { organizationId } = usePolyfitLocationQuery(
    "INTERNAL_BOARD_EDUCATION_EDIT",
    {
      organizationId: "",
    }
  );
  const { invalidateBoardEducation } = useInvalidateBoardEducation();

  const { organization } = useOrganizationById(organizationId);
  const { boardEducation, isLoading } =
    useBoardEducationByOrganizationId(organizationId);
  const { getLocationAddressFromPostcode } = useGeoCording();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      postalCode: "",
      prefecture: "",
      city: "",
      address1: "",
      address2: "",
      latitude: 0,
      longitude: 0,
    },
    mode: "onChange",
    resolver: zodResolver(FormValueSchema),
  });

  useEffect(() => {
    if (boardEducation) {
      setValue("postalCode", boardEducation.postalCode || "");
      setValue("prefecture", boardEducation.prefecture || "");
      setValue("city", boardEducation.city || "");
      setValue("address1", boardEducation.address1 || "");
      setValue("address2", boardEducation.address2 || "");
      setValue("latitude", boardEducation.latitude || 0);
      setValue("longitude", boardEducation.longitude || 0);
    }
  }, [boardEducation, setValue]);

  const watchAllValue = watch();

  const getAddress = useCallback(async (value: string) => {
    try {
      const res = await getLocationAddressFromPostcode(value);
      setValue("prefecture", res.prefecture);
      setValue("city", res.city);
      setValue("address1", res.address1);
      setValue("address2", "");
      setValue("latitude", res.latitude);
      setValue("longitude", res.longitude);
      clearErrors("postalCode");
    } catch (err) {
      setError("postalCode", {
        message: "ハイフンは入力せず、正しい郵便番号を入力してください",
      });
    }
  }, []);

  const onSubmit = async (data: FormData) => {
    if (!boardEducation) return;

    try {
      setDoubleClickBlocked(true);
      await updateBoardEducation({ ...data, id: boardEducation.id });
      invalidateBoardEducation(organizationId);

      toast.success("更新しました");
    } catch (err) {
      if (err instanceof APIError) {
        toast.error(err.message);
      }
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  if (loginUser?.internalRole !== "INTERNAL_ADMIN") {
    return <>アクセス権限がありません</>;
  }

  if (isLoading) {
    return (
      <>
        <div>読み込み中…</div>
        <div>画面が切り替わらない場合はお問い合わせください</div>
      </>
    );
  }

  return (
    <>
      <h2>{organization?.name}</h2>
      <MenuContainer>
        <Link
          onClick={() => {
            history.push({
              to: "INTERNAL_ORGANIZATION_BOARD_EDUCATION_USERS",
              query: { organizationId: organizationId },
            });
          }}
        >
          教育委員会詳細へ戻る
        </Link>
      </MenuContainer>
      <Margin marginTop={20} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputContainer>
          <Label size="s" required>
            郵便番号
          </Label>
          <InputText
            placeholder="例）1030013"
            {...register("postalCode")}
            onChange={(e) => getAddress(e.target.value)}
          />
          {errors.postalCode && (
            <ErrorMessage>{errors.postalCode.message}</ErrorMessage>
          )}
        </InputContainer>
        <InputContainer>
          <Label size="s" required>
            県名
          </Label>
          <InputSelect {...register("prefecture")}>
            <option value="" disabled>
              県名を選択
            </option>
            {prefectureOptions.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.text}
              </option>
            ))}
          </InputSelect>
          {errors.prefecture && (
            <ErrorMessage>{errors.prefecture.message}</ErrorMessage>
          )}
        </InputContainer>
        <InputContainer>
          <Label size="s" required>
            市区町村
          </Label>
          <InputText placeholder="例）川崎市川崎区" {...register("city")} />
          {errors.city && <ErrorMessage>{errors.city.message}</ErrorMessage>}
        </InputContainer>
        <InputContainer>
          <Label size="s" required>
            住所1
          </Label>
          <InputText placeholder="例）旭町3-1" {...register("address1")} />
          {errors.address1 && (
            <ErrorMessage>{errors.address1.message}</ErrorMessage>
          )}
        </InputContainer>
        <InputContainer>
          <Label size="s">住所2</Label>
          <InputText
            placeholder="例）〇〇マンション101号室"
            {...register("address2")}
          />
          {errors.address2 && (
            <ErrorMessage>{errors.address2.message}</ErrorMessage>
          )}
        </InputContainer>
        <FormArea>
          緯度: {watchAllValue.latitude || "-"}
          <br />
          軽度: {watchAllValue.longitude || "-"}
          <br />
          募集デフォルト住所: 〒
          {watchAllValue.postalCode
            ? `${watchAllValue.postalCode} ${watchAllValue.prefecture} ${watchAllValue.city}${watchAllValue.address1}${watchAllValue.address2}`
            : "-"}
        </FormArea>
        <Button
          size="large"
          color="secondary"
          type="submit"
          disabled={doubleClickBlocked}
        >
          更新する
        </Button>
      </form>
    </>
  );
};

const MenuContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
`;

const Link = styled.a`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

const FormArea = styled.div`
  margin-top: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 16px;

  h4 {
    margin-bottom: 8px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const InputText = styled.input`
  width: 100%;
  color: ${(props) => (props.disabled ? "#1322951A" : "#000000")};
  background-color: #fbfcfd;
  border: 1px solid
    ${(props) => (props.disabled ? "#ddd" : "rgba(19, 34, 149, 0.1)")};
  border-radius: 6px;
  padding: 10px 8px;
  font-size: 14px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "text")};

  ::placeholder {
    color: ${(props) => (props.disabled ? "#1322951A" : "#343741")};
  }
`;

const InputSelect = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background-color: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  cursor: "pointer";
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;
