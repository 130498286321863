import React, { Dispatch, SetStateAction } from "react";
import { UseFormSetValue } from "react-hook-form";
import { SelectBelongPartType, FormValues } from "./CareerInfoCreateModal";
import { ModalBackGround } from "../../../components/ListPageFilterSetting";
import {
  DropdownMenuWrapper,
  DropdownMenu,
  DropdownMenuUl,
  DropdownMenuLi,
  DropdownMenuLabel,
} from "./SelectChildDropdown";
import { Part } from "../../../apiClients/part";

type PartsDropdownListPropsType = {
  parts: Part[];
  isOpen: boolean;
  onClose: () => void;
  selectedPart: SelectBelongPartType;
  setSelectedPart: Dispatch<SetStateAction<SelectBelongPartType>>;
  setValue: UseFormSetValue<FormValues>;
};

const PartsDropdownList = ({
  parts,
  isOpen,
  onClose,
  selectedPart,
  setSelectedPart,
  setValue,
}: PartsDropdownListPropsType) => {
  if (!isOpen) return null;

  return (
    <>
      <DropdownMenuWrapper>
        <DropdownMenu height={parts.length * 38}>
          <DropdownMenuUl>
            {parts.map((part, index) => (
              <>
                <DropdownMenuLi isLast={parts.length - 1 === index}>
                  <input
                    type="radio"
                    name="belong-select"
                    id={part.id}
                    checked={part.id === selectedPart.id}
                    onChange={() => {
                      setSelectedPart({
                        id: part.id,
                        name: part.name,
                        isNone: false,
                      });
                      setValue("partId", part.id);
                      onClose();
                    }}
                  />
                  <DropdownMenuLabel htmlFor={part.id}>
                    {part.name}
                  </DropdownMenuLabel>
                </DropdownMenuLi>
              </>
            ))}
          </DropdownMenuUl>
        </DropdownMenu>
      </DropdownMenuWrapper>
      <ModalBackGround onClick={onClose} />
    </>
  );
};

export default PartsDropdownList;
