import { useEffect, useState, RefObject } from "react";
import { DisplayItem } from "../types";

interface UseFloatingDateLabelProps {
  messageListRef: RefObject<HTMLDivElement>;
  displayItemList: DisplayItem[];
  isUnapprovedOrPending?: boolean;
}

export const useFloatingDateLabel = ({
  messageListRef,
  displayItemList,
  isUnapprovedOrPending,
}: UseFloatingDateLabelProps) => {
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const options = {
      root: messageListRef.current,
      threshold: 1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.boundingClientRect.top > 350) return;

        if (entry.isIntersecting) {
          const prevDate = entry.target.getAttribute("data-prev-date");
          if (!prevDate) return;
          setCurrentDate(prevDate);
        } else {
          const currentDate = entry.target.getAttribute("data-current-date");
          if (!currentDate) return;
          setCurrentDate(currentDate);
        }
      });
    }, options);

    document.querySelectorAll(".date-label").forEach((element) => {
      observer.observe(element);
    });

    return () => {
      setCurrentDate("");
      observer.disconnect();
    };
  }, [displayItemList, messageListRef, isUnapprovedOrPending]);

  return currentDate;
};
