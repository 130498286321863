import React, { forwardRef, useMemo } from "react";
import styled from "styled-components";
import { splitTextByKeywords, type Options } from "../utils/highlight";

export interface TextWithHighlightProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  text: string;
  keywords: string[];
  options?: Options;
}

export const TextWithHighlight = forwardRef<
  HTMLSpanElement,
  TextWithHighlightProps
>((props, ref) => {
  const { text, keywords, options, ...others } = props;

  const terms = useMemo(() => {
    return splitTextByKeywords(text, keywords, options);
  }, [text, keywords, options]);

  return (
    <span ref={ref} {...others}>
      {terms.map((term) => (
        <>
          {term.hit ? (
            <HighlightText>{term.text}</HighlightText>
          ) : (
            <span>{term.text}</span>
          )}
        </>
      ))}
    </span>
  );
});

const HighlightText = styled.strong`
  background-color: #ff0;
`;
