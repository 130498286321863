export const throttle = <F extends (...args: any[]) => void>(
  func: F,
  limit: number
): ((...args: Parameters<F>) => void) => {
  let lastFunc: any;
  let lastRan: number | null = null;

  return function (...args: Parameters<F>) {
    if (!lastRan) {
      func(...args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - (lastRan as number) >= limit) {
          func(...args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - (lastRan as number)));
    }
  };
};
