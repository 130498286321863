import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import { ChatRoomFilterType } from "@shared/types/boardEducation/chatRoom";

const { persistAtom } = recoilPersist();

const defaultFilterState = {
  showCSOrganizationChat: false,
  type: "",
  recruitmentName: null,
  recruitmentSchedule: null,
  applicationStatus: "",
  approvalStatus: "",
  participantChatRoomApprovalStatus: "",
  chatRoomName: "",
  communityIds: [],
  roles: [],
} as ChatRoomFilterType;

const chatRoomFilterState = atom<ChatRoomFilterType>({
  key: "BEChatRoomFilter",
  default: defaultFilterState,
  effects_UNSTABLE: [persistAtom],
});

export const useChatRoomFilter = () => {
  const [chatRoomFilter, setChatRoomFilter] =
    useRecoilState(chatRoomFilterState);
  const [isFilterActive, setIsFilterActive] = useState(false);

  useEffect(() => {
    const isDefaultFilter = Object.keys(defaultFilterState).every(
      (key) =>
        chatRoomFilter[key as keyof ChatRoomFilterType] ===
        defaultFilterState[key as keyof ChatRoomFilterType]
    );

    setIsFilterActive(!isDefaultFilter);
  }, [chatRoomFilter]);

  const resetFilter = () => {
    setChatRoomFilter(defaultFilterState);
  };

  return {
    chatRoomFilter,
    setChatRoomFilter,
    isFilterActive,
    resetFilter,
  } as const;
};
