import React from "react";
import {
  ModalBody,
  ModalPortal,
  ModalSubmitButtons,
} from "../../../../components/Modal";
import { Margin } from "../../../../components/Margin";
import { ModalHeader } from "../../../Post/CreatePostModal";
import styled from "styled-components";

interface Props {
  comment: string;
  onSubmit: () => void;
  onClose: () => void;
  disabled: boolean;
}

export default function DeleteApplicationCommentModal({
  comment,
  onSubmit,
  onClose,
  disabled,
}: Props) {
  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>コメントを削除</ModalHeader>
        <ModalBody>
          <CommentArea>{comment}</CommentArea>
          <Margin marginTop={15} />
          <div>こちらのコメントを削除しますか？</div>
          <Margin marginTop={15} />
          <ModalSubmitButtons
            submitColor="danger"
            submitText="削除する"
            onSubmit={onSubmit}
            onCancel={onClose}
            // disabled={disabled}
          />
        </ModalBody>
      </ModalPortal>
    </>
  );
}

const CommentArea = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
`;
