import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

const SidebarMenuModalState = atom<boolean>({
  key: "SidebarMenuModalState",
  default: false,
});

export function useSidebarMenuModal() {
  const [isShown, setIsShown] = useRecoilState(SidebarMenuModalState);

  const show = useCallback(() => {
    setIsShown(true);
  }, [setIsShown]);

  const close = useCallback(() => {
    setIsShown(false);
  }, [setIsShown]);

  const toggle = useCallback(() => {
    setIsShown((prevState) => !prevState);
  }, [setIsShown]);

  return [isShown, { show, close, toggle }] as const;
}
