import { useState } from "react";
import * as apiClient from "../../apiClients/link";
import { Link } from "../../apiClients/link";

export function useLink() {
  const createLink = async (arg: Link) => await apiClient.createLink(arg);
  const updateLink = async (arg: Link) => await apiClient.updateLink(arg);
  const deleteLink = async (linkId: string) =>
    await apiClient.deleteLink(linkId);

  return {
    createLink,
    updateLink,
    deleteLink,
  };
}

export function useGetOrganizationLinks() {
  const [links, setLinks] = useState<Link[] | null>(null);

  const getOrganizationLinks = async () => {
    await apiClient.getOrganizationLinks().then((links) => {
      setLinks(links);
    });
  };

  return {
    links,
    getOrganizationLinks,
  };
}
