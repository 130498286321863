import React from "react";
import { ErrorPageLayout } from "./ErrorPageLayout";

export const PermissionDeniedPage = () => {
  return (
    <ErrorPageLayout
      image={<Illustration />}
      title="アクセス権限がありません"
      message="このページにアクセスする権限がありません。管理者にお問い合わせください。"
    />
  );
};

const Illustration = () => {
  return (
    <svg
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M208 183.5H19C18.7239 183.5 18.5 183.276 18.5 183V47C18.5 46.7239 18.7239 46.5 19 46.5H154.549H208C208.276 46.5 208.5 46.7239 208.5 47V77.5V183C208.5 183.276 208.276 183.5 208 183.5Z"
        fill="#DEEFFF"
        stroke="#37434F"
        strokeWidth="5"
      />
      <path d="M22 181L202 165V181H22Z" fill="#8AC6FF" />
      <path
        d="M104.005 127H124.005"
        stroke="#37434F"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M98.0049 88C98.0049 88 93.4981 92.7014 89.6576 93.8923C85.6521 95.1344 79 94.23 79 94.23"
        stroke="#37434F"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M129.005 88C129.005 88 133.512 92.7014 137.352 93.8923C141.358 95.1344 148.01 94.23 148.01 94.23"
        stroke="#37434F"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <circle cx="93.0049" cy="105" r="6" fill="#37434F" />
      <circle cx="134.005" cy="105" r="6" fill="#37434F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206 64H21V60H206V64Z"
        fill="#37434F"
      />
      <rect x="21" y="49" width="185" height="11" fill="white" />
      <circle cx="28.5" cy="54.5" r="3.5" fill="#37434F" />
      <circle cx="37.5" cy="54.5" r="3.5" fill="#37434F" />
      <circle cx="46.5" cy="54.5" r="3.5" fill="#37434F" />
      <circle cx="197" cy="163" r="39" fill="#37434F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.02 179.263C210.192 180.435 212.091 180.435 213.263 179.263C214.435 178.091 214.435 176.192 213.263 175.02L201.242 163L213.263 150.979C214.435 149.807 214.435 147.908 213.263 146.736C212.091 145.565 210.192 145.565 209.02 146.736L197 158.757L184.979 146.736C183.807 145.565 181.908 145.565 180.736 146.736C179.565 147.908 179.565 149.807 180.736 150.979L192.757 163L180.736 175.02C179.565 176.192 179.565 178.091 180.736 179.263C181.908 180.435 183.807 180.435 184.979 179.263L197 167.242L209.02 179.263Z"
        fill="white"
      />
      <path
        d="M201 12.1319L204.49 18.7032C204.779 19.2475 205.303 19.6281 205.91 19.7349L213.238 21.0235L208.067 26.3735C207.639 26.8166 207.439 27.4325 207.525 28.0428L208.564 35.4105L201.878 32.1457C201.324 31.8752 200.676 31.8752 200.122 32.1457L193.436 35.4105L194.475 28.0428C194.561 27.4325 194.361 26.8166 193.933 26.3735L188.762 21.0235L196.09 19.7349C196.697 19.6281 197.221 19.2475 197.51 18.7032L201 12.1319Z"
        fill="white"
        stroke="#37434F"
        strokeWidth="2"
      />
      <path
        d="M228 46.1319L230.57 50.9701C230.859 51.5144 231.383 51.895 231.99 52.0018L237.385 52.9506L233.578 56.8896C233.149 57.3327 232.949 57.9486 233.035 58.5588L233.8 63.9835L228.878 61.5797C228.324 61.3092 227.676 61.3092 227.122 61.5797L222.2 63.9835L222.965 58.5588C223.051 57.9486 222.851 57.3327 222.422 56.8896L218.615 52.9506L224.01 52.0018C224.617 51.895 225.141 51.5144 225.43 50.9701L228 46.1319Z"
        fill="white"
        stroke="#37434F"
        strokeWidth="2"
      />
    </svg>
  );
};
