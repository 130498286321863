import React from "react";

export const CopyIcon = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4 0C10.6349 0 10.8603 0.098875 11.0223 0.273L13.7651 3.2725C13.916 3.43525 14 3.65138 14 3.87538V13.125C14 13.608 13.616 14 13.1429 14H2.85714C2.384 14 2 13.608 2 13.125V0.875C2 0.392 2.384 0 2.85714 0H10.4ZM13 4H10.4C10.1792 4 10 3.8208 10 3.6V1H3V13H13V4Z"
        fill="#343741"
      />
      <path
        d="M2 1H1C0.447715 1 0 1.44772 0 2V15C0 15.5523 0.447715 16 1 16H11C11.5523 16 12 15.5523 12 15V14H11V15H1V2H2V1Z"
        fill="#343741"
      />
    </svg>
  );
};
