import styled from "styled-components";
import React from "react";
import { BREAKPOINTS } from "../../components/Responsive";

export const NoResults = () => {
  return (
    <NoResultsWrapper>
      <NoResultsSVG />
      <Message>検索結果と一致する結果が見つかりません</Message>
    </NoResultsWrapper>
  );
};

const NoResultsWrapper = styled.div`
  text-align: center;
  padding: 24px;
  width: 100%;
  background-color: #fff;
`;

const Message = styled.p`
  font-weight: 700;
  font-size: 22px;
  color: #343741;
  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 16px;
  }
`;

const NoResultsSVG = () => {
  return (
    <svg
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M157.242 65.316L157.242 65.3257V65.3354V171C157.242 171.276 157.018 171.5 156.742 171.5H49C48.7239 171.5 48.5 171.276 48.5 171V35C48.5 34.7239 48.7239 34.5 49 34.5H126.998H156.977C157.254 34.5 157.479 34.7262 157.477 35.0039L157.242 65.316Z"
        fill="#DEEFFF"
        stroke="#37434F"
        strokeWidth="5"
      />
      <path d="M51 37H52.0588L57 169H51V37Z" fill="white" />
      <path d="M110 163.5L130 155V169H89L110 163.5Z" fill="#8AC6FF" />
      <rect x="66" y="64" width="74" height="5" rx="2.5" fill="#37434F" />
      <rect x="66" y="85" width="48" height="5" rx="2.5" fill="#37434F" />
      <rect x="66" y="106" width="30" height="5" rx="2.5" fill="#37434F" />
      <rect
        x="163.377"
        y="156.521"
        width="10.1012"
        height="57"
        transform="rotate(-45 163.377 156.521)"
        fill="#37434F"
      />
      <path
        d="M202.492 198.863L182.67 179.041L193.042 168.67L212.864 188.492C215.728 191.356 215.728 195.999 212.864 198.863C210 201.727 205.356 201.727 202.492 198.863Z"
        fill="white"
        stroke="#37434F"
        strokeWidth="5"
      />
      <path d="M189 182.839L197.839 174" stroke="#37434F" strokeWidth="4" />
      <circle
        cx="146.5"
        cy="133.5"
        r="45"
        fill="white"
        stroke="#37434F"
        strokeWidth="5"
      />
      <circle
        cx="146.5"
        cy="133.5"
        r="34"
        fill="#DEEFFF"
        stroke="#37434F"
        strokeWidth="5"
      />
      <path
        d="M142.269 142.918C141.869 142.918 141.545 142.593 141.545 142.193V142.193C141.559 139.707 141.779 137.726 142.205 136.249C142.646 134.771 143.285 133.578 144.123 132.669C144.961 131.76 145.97 130.936 147.149 130.197C148.029 129.629 148.818 129.04 149.514 128.429C150.21 127.818 150.764 127.143 151.176 126.405C151.588 125.652 151.794 124.814 151.794 123.891C151.794 122.91 151.559 122.051 151.09 121.312C150.622 120.574 149.99 120.006 149.194 119.608C148.413 119.21 147.546 119.011 146.595 119.011C145.671 119.011 144.798 119.217 143.974 119.629C143.15 120.027 142.475 120.624 141.95 121.419V121.419C140.99 122.846 139.736 124.338 138.017 124.338H136.885C134.429 124.338 132.34 122.259 133.131 119.934C133.46 118.968 133.9 118.093 134.45 117.307C135.742 115.446 137.454 114.061 139.585 113.152C141.715 112.229 144.066 111.767 146.637 111.767C149.464 111.767 151.964 112.236 154.137 113.173C156.311 114.097 158.015 115.439 159.251 117.2C160.487 118.962 161.105 121.085 161.105 123.571C161.105 125.233 160.828 126.71 160.274 128.003C159.734 129.281 158.974 130.418 157.994 131.412C157.014 132.392 155.856 133.28 154.521 134.075C153.399 134.743 152.475 135.439 151.751 136.163C151.041 136.888 150.508 137.726 150.153 138.678C149.812 139.629 149.634 140.801 149.62 142.193V142.193C149.62 142.593 149.296 142.918 148.896 142.918H142.269ZM145.764 156.554C144.343 156.554 143.129 156.057 142.12 155.062C141.126 154.054 140.636 152.847 140.65 151.44C140.636 150.048 141.126 148.855 142.12 147.861C143.129 146.866 144.343 146.369 145.764 146.369C147.113 146.369 148.299 146.866 149.322 147.861C150.345 148.855 150.863 150.048 150.877 151.44C150.863 152.378 150.615 153.237 150.132 154.018C149.663 154.785 149.045 155.403 148.278 155.872C147.511 156.327 146.673 156.554 145.764 156.554Z"
        fill="#37434F"
      />
      <path
        d="M181 46.1319L184.49 52.7032C184.779 53.2475 185.303 53.6281 185.91 53.7349L193.238 55.0235L188.067 60.3735C187.639 60.8166 187.439 61.4325 187.525 62.0428L188.564 69.4105L181.878 66.1457C181.324 65.8752 180.676 65.8752 180.122 66.1457L173.436 69.4105L174.475 62.0428C174.561 61.4325 174.361 60.8166 173.933 60.3735L168.762 55.0235L176.09 53.7349C176.697 53.6281 177.221 53.2475 177.51 52.7032L181 46.1319Z"
        fill="white"
        stroke="#37434F"
        strokeWidth="2"
      />
      <path
        d="M199 77.1319L201.57 81.9701C201.859 82.5144 202.383 82.895 202.99 83.0018L208.385 83.9506L204.578 87.8896C204.149 88.3327 203.949 88.9486 204.035 89.5588L204.8 94.9835L199.878 92.5797C199.324 92.3092 198.676 92.3092 198.122 92.5797L193.2 94.9835L193.965 89.5588C194.051 88.9486 193.851 88.3327 193.422 87.8896L189.615 83.9506L195.01 83.0018C195.617 82.895 196.141 82.5144 196.43 81.9701L199 77.1319Z"
        fill="white"
        stroke="#37434F"
        strokeWidth="2"
      />
    </svg>
  );
};
