import * as client from "./client";
import {
  Community,
  CommunityMemberRoleCountsResponse,
  GetCommunityListQuery,
} from "@shared/types/community";

export type CommunitySettings = {
  id: string;
  communityId: string;
  department: string;
  role: string;
  auth: string;
};

export async function getCommunityById(communityId: string) {
  const res = await client.get<{ communityId: string }, Community>(
    "/community/getCommunity",
    {
      communityId: communityId,
    }
  );
  return res;
}

export async function getCommunityMemberRoleCounts() {
  const res = await client.get<{}, CommunityMemberRoleCountsResponse>(
    "/community/getCommunityMemberRoleCounts",
    {}
  );
  return res;
}

export type UpdateCommunityArgs = {
  id: string;
  name: string;
  schoolCode: string;
  address: string | null;
  postalCode: string | null;
  nameListPublished?: boolean;
  requireAdminToUpdateCareer?: boolean;
  latitude?: number;
  longitude?: number;
  postSchoolYearsFilter?: string[];
  prefecture?: string;
  city?: string;
  address1?: string;
  address2?: string;
};

export async function updateCommunity(
  args: UpdateCommunityArgs
): Promise<Community> {
  const res = await client.post<UpdateCommunityArgs, Community>(
    "/community/updateCommunity",
    args
  );
  return res;
}

export async function updateGeography(args: { id: string }): Promise<void> {
  const res = await client.post<{ id: string }, void>(
    "/community/updateGeography",
    args
  );
  return res;
}

export async function getCommunities(
  searchText: string,
  take?: number
): Promise<Community[]> {
  const res = await client.get<GetCommunityListQuery, Community[]>(
    "/community/list",
    { searchText, take }
  );
  return res;
}

export type CreateCommunityArg = {
  name: string;
  schoolCode: string;
  address: string | null;
  postalCode: string | null;
  latitude?: number;
  longitude?: number;
  prefecture?: string;
  city?: string;
  address1?: string;
  address2?: string;
};
export async function createCommunity(
  args: CreateCommunityArg
): Promise<Community> {
  const res = await client.post<CreateCommunityArg, Community>(
    "/community/create",
    args
  );
  return res;
}

export type CommunitySettingNode = {
  id: string;
  communityId: string;
  name: string;
  community: Community;
};
export type CommunitySettingArgs = {
  communityId: string;
  name: string;
};
export async function createDepartment(
  args: CommunitySettingArgs
): Promise<CommunitySettingNode> {
  const res = await client.post<CommunitySettingArgs, CommunitySettingNode>(
    "/community/createDepartment",
    args
  );
  return res;
}

export async function createRole(
  args: CommunitySettingArgs
): Promise<CommunitySettingNode> {
  const res = await client.post<CommunitySettingArgs, CommunitySettingNode>(
    "/community/createRole",
    args
  );
  return res;
}

export async function createAuth(
  args: CommunitySettingArgs
): Promise<CommunitySettingNode> {
  const res = await client.post<CommunitySettingArgs, CommunitySettingNode>(
    "/community/createAuth",
    args
  );
  return res;
}

export async function deleteDepartment(args: { id: string }) {
  const res = await client.post("/community/deleteDepartment", args);
  return res;
}
