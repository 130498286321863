import * as client from "../client";
import {
  RecruitmentCommentCreateArgsType,
  RecruitmentCommentUpdateArgsType,
} from "@shared/types/boardEducation/recruitmentComment";

/**
 * 応募に対してのコメント作成
 */
export async function createRecruitmentApplicationComment(
  args: RecruitmentCommentCreateArgsType
): Promise<void> {
  await client.post<{
    args: RecruitmentCommentCreateArgsType;
  }>("/boardEducation/recruitment-comment/create", { args });
}

/**
 * 応募に対してのコメント編集
 */
export async function editRecruitmentApplicationComment(
  args: RecruitmentCommentUpdateArgsType
): Promise<void> {
  await client.post<{ args: RecruitmentCommentUpdateArgsType }>(
    "/boardEducation/recruitment-comment/edit",
    { args }
  );
}

/**
 * 応募に対してのコメント削除
 */
export async function deleteRecruitmentApplicationComment(
  recruitmentApplicationCommentId: string
): Promise<void> {
  await client.post<{ recruitmentApplicationCommentId: string }>(
    "/boardEducation/recruitment-comment/delete",
    { recruitmentApplicationCommentId }
  );
}
