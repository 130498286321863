import React, { useEffect, useState } from "react";
import { Margin } from "../../../components/Margin";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../../components/Modal";
import { useToast } from "../../../components/Toast";
import { ModalHeader } from "../../pta/admin/ProfilePage";
import { SearchCommunity } from "../../../components/form/SearchCommunity";
import { Organization } from "../../../apiClients/organization";
import {
  getCommunityById,
  updateCommunity,
} from "../../../apiClients/community";
import useGoogleGeocoding from "../../../hooks/useGoogleGeocoding";
import { APIError } from "src/utils/types/ApiError";

type Props = {
  isOpen: boolean;
  organization: Organization | undefined;
  onClose: () => void;
  addCommunity: (communityIds: string[]) => Promise<void>;
};

export const OrganizationAddCommunityModal = ({
  isOpen,
  organization,
  onClose,
  addCommunity,
}: Props) => {
  if (!isOpen) return null;
  const [selectedCommunityIds, setSelectedCommunityIds] = useState<string[]>(
    []
  );
  const [isError, setIsError] = useState<boolean>(false);
  const toast = useToast();
  const { getLocationAddressByAddress } = useGoogleGeocoding();

  useEffect(() => {
    if (isError && selectedCommunityIds.length > 0) return setIsError(false);
  }, [selectedCommunityIds]);

  const onSubmit = async () => {
    if (selectedCommunityIds.length === 0) return setIsError(true);

    try {
      await Promise.all(
        selectedCommunityIds.map(async (communityId) => {
          const community = await getCommunityById(communityId);
          if (community.latitude) return;

          const res = await getLocationAddressByAddress(community.address);
          if (!res) {
            throw new Error("住所が取得できませんでした");
          }
          await updateCommunity({
            id: communityId,
            name: community.name,
            schoolCode: community.schoolCode,
            address: community.address,
            postalCode: community.postalCode,
            prefecture: res.prefecture,
            city: res.city,
            address1: res.address1,
            address2: res.address2,
            latitude: res.latitude,
            longitude: res.longitude,
          });
        })
      );
      await addCommunity(selectedCommunityIds);
      toast.success("コミュニティを紐付けました");
      onClose();
    } catch (error) {
      if (error instanceof APIError) {
        toast.error(error.message);
        return;
      }
      toast.error("コミュニティの紐付けに失敗しました");
    }
  };

  if (!organization) return null;

  return (
    <ModalPortal
      onClose={() => {
        onClose();
      }}
    >
      <ModalHeader>コミュニティを追加</ModalHeader>
      <ModalBody>
        <p>追加したいコミュニティを指定してください</p>
        <Margin marginTop={20} />
        <SearchCommunity
          serviceType={organization.serviceType}
          selectedCommunityIds={selectedCommunityIds}
          setSelectedCommunityIds={setSelectedCommunityIds}
        />
        <Margin marginTop={20} />
        <ModalSubmitButtons
          submitText="追加"
          disabled={selectedCommunityIds.length === 0}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </ModalBody>
    </ModalPortal>
  );
};
