import React, { FC } from "react";
import styled from "styled-components";
import { TextField2 } from "./Form";

//TableComponents
export const TableHeader: FC<{ children: React.ReactNode }> = (props) => {
  return (
    <thead>
      <tr>{props.children}</tr>
    </thead>
  );
};
export const TableHeaderItem: FC<{ children: React.ReactNode }> = (props) => {
  return <th>{props.children}</th>;
};
export const TableBody: FC<{ children: React.ReactNode }> = (props) => {
  return <tbody>{props.children}</tbody>;
};

export const TableRow: FC<{
  onClick?: () => void;
  children: React.ReactNode;
}> = (props) => {
  return <tr onClick={props.onClick}>{props.children}</tr>;
};

//CustomTableComponents
type TableDataProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};
export const TableData: FC<TableDataProps> = ({
  children,
  style,
  className,
}) => {
  return (
    <td className={className} style={style}>
      {children}
    </td>
  );
};

type InputDataProps = {
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
export const InputData: FC<InputDataProps> = ({ placeholder, onChange }) => {
  return (
    <StyledInput>
      <TextField2 placeholder={placeholder} onChange={onChange} />
    </StyledInput>
  );
};

type SelectDataProps = {
  placeholder?: string;
  onClick?: () => void;
  value: string;
  defaultValue?: string;
};

export const SelectData = ({
  placeholder,
  onClick,
  value,
}: SelectDataProps) => {
  return (
    <StyledSelect>
      <TextField2
        placeholder={placeholder}
        onClick={onClick}
        value={value}
        onChange={() => {}}
      />
    </StyledSelect>
  );
};

export const Table = styled.table`
  font-size: 0.875rem;
  line-height: 1.5;
  font-feature-settings: "calt" 1, "kern" 1, "liga" 1, "tnum" 1;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-radius: 6px;
  table-layout: auto;
  padding: 20px;

  th,
  td {
    width: 33%;
    padding: 12px 20px;
    vertical-align: middle;
    text-align: inherit;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
  }

  th {
    font-size: 24px;
    font-weight: bold;
  }

  tbody {
    color: #1a1c21;
  }
  tfoot tr td {
    text-align: right;
  }
`;

export const StyledDiv = styled.div`
  padding: 40px;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #ebebeb;
`;

export const StyledInput = styled.div`
  label {
    margin: 0;
  }
  input {
    border: none;
    font-size: 1rem;
    padding: 16px 16px;

    ::placeholder {
      color: #d3d3d3;
      font-weight: bold;
    }
    &:focus {
      outline: 2px solid #1c87d3;
    }
  }
`;

export const StyledSelect = styled.div`
  label {
    margin: 0;
  }
  input {
    border: none;
    font-size: 1rem;
    padding: 16px 16px;
    caret-color: transparent;
    ::placeholder {
      color: #d3d3d3;
      font-weight: bold;
    }
    &:focus {
      outline: 2px solid #1c87d3;
    }
  }
`;

export const StyledRadioField = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  label {
    margin-bottom: 8px;
  }
  box-shadow: 0 0 5px #fff, 0 0 5px #ccc, 0 0 1px #aaa;
  input {
    margin-right: 10px;
  }
`;
