import React from "react";
import { ModalSubmitButtons } from "../../../components/Modal";
import { CareerButtonUIArea } from "../../Profile/Career/CareerInfoCreateModal";
import { FormType } from "../LinkModal";

type Props = {
  onClose: () => void;
  disabled: boolean;
  type: FormType;
};

export const SubmitButtons = ({ onClose, disabled, type }: Props) => {
  return (
    <CareerButtonUIArea>
      <div style={{ marginLeft: "auto" }}>
        {type === "delete" ? (
          <ModalSubmitButtons
            submitText="削除"
            submitColor="danger"
            onCancel={onClose}
            disabled={disabled}
          />
        ) : (
          <ModalSubmitButtons
            submitText={type === "create" ? "作成" : "更新"}
            onCancel={onClose}
            disabled={disabled}
          />
        )}
      </div>
    </CareerButtonUIArea>
  );
};
