/**
 * ポストくん
 * 郵便番号 REST API
 * https://postcode.teraren.com/
 */

interface PostalCodeResponse {
  postcode_type: string;
  jis: string;
  old: string;
  new: string;
  prefecture_kana: string;
  city_kana: string;
  suburb_kana: string;
  prefecture: string;
  city: string;
  suburb: string;
  street_address: string | null;
  office: string | null;
  office_kana: string | null;
  office_roman: string | null;
  post_type: string | null;
  is_separated_suburb: number;
  is_koaza: number;
  is_chome: number;
  is_include_area: number;
  status: number;
  reason: number;
  prefecture_roman: string;
  city_roman: string;
  suburb_roman: string;
  created_at: string;
  updated_at: string;
}

export async function getAddressFromPostalCode(
  postalCode: string
): Promise<PostalCodeResponse> {
  const url = `https://postcode.teraren.com/postcodes/${postalCode}.json`;
  const res = await fetch(url.toString(), { method: "GET" });
  if (!res.ok) {
    throw new Error("郵便番号が見つかりませんでした");
  }
  return await res.json();
}
