import React from "react";

export const MemberListIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 20H20V4H6L6 20ZM4 21C4 21.5523 4.44772 22 5 22H21C21.5523 22 22 21.5523 22 21V3C22 2.44772 21.5523 2 21 2H5C4.44772 2 4 2.44772 4 3L4 21Z"
        fill="#37434F"
      />
      <rect x="2" y="3" width="3" height="2" rx="1" fill="#37434F" />
      <rect x="2" y="7" width="3" height="2" rx="1" fill="#37434F" />
      <rect x="2" y="11" width="3" height="2" rx="1" fill="#37434F" />
      <rect x="2" y="15" width="3" height="2" rx="1" fill="#37434F" />
      <rect x="2" y="19" width="3" height="2" rx="1" fill="#37434F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7.75C10.6528 7.75 8.75 9.65279 8.75 12C8.75 14.3472 10.6528 16.25 13 16.25C15.3472 16.25 17.25 14.3472 17.25 12C17.25 9.65279 15.3472 7.75 13 7.75ZM7.25 12C7.25 8.82436 9.82436 6.25 13 6.25C16.1756 6.25 18.75 8.82436 18.75 12C18.75 15.1756 16.1756 17.75 13 17.75C9.82436 17.75 7.25 15.1756 7.25 12Z"
        fill="#37434F"
      />
      <path
        d="M11 10.9274C10.219 11.0907 9.78105 10.7641 9 10.9274L10 8.8364L13 8L16 8.8364L17 10.9274C17 10.9274 15.781 11.0907 15 10.9274C14.219 10.7641 13 9.67281 13 9.67281C13 9.67281 11.781 10.7641 11 10.9274Z"
        fill="#37434F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7.48047L16.3531 8.41531L17.7476 11.3313L17.0664 11.4225L17 10.927C17.0664 11.4225 17.0665 11.4225 17.0664 11.4225L17.0647 11.4228L17.0612 11.4232L17.0491 11.4248L17.0052 11.4301C16.9677 11.4346 16.9141 11.4407 16.8476 11.4474C16.7148 11.4608 16.5296 11.4768 16.3183 11.4876C15.9073 11.5084 15.354 11.5118 14.8977 11.4164C14.6297 11.3603 14.3556 11.2335 14.1141 11.0985C13.8666 10.9601 13.6235 10.7961 13.4127 10.6425C13.2574 10.5293 13.1163 10.4193 13 10.3256C12.8838 10.4193 12.7427 10.5293 12.5874 10.6425C12.3766 10.7961 12.1335 10.9601 11.886 11.0985C11.6445 11.2335 11.3704 11.3603 11.1024 11.4164C10.6278 11.5156 10.2518 11.4652 9.94771 11.4244C9.94301 11.4238 9.93832 11.4231 9.93365 11.4225C9.63073 11.3819 9.40146 11.3538 9.10238 11.4164L8.11279 11.6233L9.64697 8.41531L13 7.48047ZM13.3337 9.29997L13.3356 9.30166L13.3444 9.30946L13.3811 9.34144C13.4136 9.36958 13.4616 9.41055 13.5218 9.46049C13.6427 9.56067 13.811 9.69541 14.0016 9.83433C14.1932 9.97394 14.4013 10.1134 14.602 10.2256C14.8087 10.3411 14.9798 10.4119 15.1024 10.4375C15.4187 10.5037 15.8509 10.5086 16.237 10.4903L15.647 9.25657L13 8.51861L10.3531 9.25657L9.80667 10.3992C9.89349 10.4082 9.97524 10.4191 10.0524 10.4295C10.0571 10.4301 10.0618 10.4308 10.0664 10.4314C10.3694 10.472 10.5986 10.5001 10.8977 10.4375C11.0203 10.4119 11.1914 10.3411 11.3981 10.2256C11.5988 10.1134 11.8069 9.97394 11.9985 9.83433C12.1891 9.69541 12.3574 9.56067 12.4783 9.46049C12.5385 9.41055 12.5865 9.36958 12.619 9.34144L12.6557 9.30946L12.6645 9.30166L12.6664 9.29997L13 9.00125L13.3337 9.29997Z"
        fill="#37434F"
      />
      <circle cx="11.25" cy="12.75" r="0.75" fill="#37434F" />
      <circle cx="14.75" cy="12.75" r="0.75" fill="#37434F" />
    </svg>
  );
};
