import React, { useEffect } from "react";
import { PromoteModalPortal, useModal } from "../../../components/Modal";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { isCsMemberRole } from "../../../utils/types/role";
import { usePromotionDisplay } from "../../../hooks/recoil/promotionDisplay";
import { useResidentInfo } from "../../../hooks/api/residentInfo";
import { isResidentInfoEmpty } from "../../../utils/isResidentInfoEmpty";
import { usePolyfitHistory } from "../../../hooks/router";
import { useRecoilState } from "recoil";
import { profileTabState } from "../../../hooks/recoil/profileTab";
import { parseISO, differenceInDays, isValid } from "date-fns";

// プロモーションモーダルを再表示するまでの間隔（日数）
const EXPIRATION_DAYS = 3;
const isSmartPhone = window.innerWidth <= 480;

export const PromoteResidentInfoModal = () => {
  const [promotionDisplay, updatePromotionDisplay] = usePromotionDisplay();
  const currentUser = useCurrentUser();
  const isCSMember = isCsMemberRole(currentUser.role);
  const [
    isShowPromoteResidentInfoModal,
    {
      show: showPromoteResidentInfoModal,
      close: closePromoteResidentInfoModal,
    },
  ] = useModal();
  const { isLoading: isLoadingResidentInfo, currentResidentInfo } =
    useResidentInfo();
  const [, setOpenProfileTab] = useRecoilState(profileTabState);
  const history = usePolyfitHistory();

  const promoteResidentInfoImgUrl = isSmartPhone
    ? getStaticImageUrl("/promoteResidentInfoImgSP.png")
    : getStaticImageUrl("/promoteResidentInfoImgPC.png");

  const updateShowPromoteResidentInfoModalAt = () => {
    updatePromotionDisplay(
      "showPromoteResidentInfoModalAt",
      new Date().toISOString()
    );
  };

  const handleClose = () => {
    closePromoteResidentInfoModal();
    updateShowPromoteResidentInfoModalAt();
  };

  const handleClickedImg = () => {
    updateShowPromoteResidentInfoModalAt();
    // プロフィール画面に遷移後のタブを「配信・応募条件」タブにする処理
    setOpenProfileTab("residentInfo");
    history.push(
      {
        to: "PTA_PROFILE",
        query: { userId: currentUser.id, editModal: "show" },
      },
      { replace: true }
    );
  };

  const shouldShowPromotionModal = () => {
    const { showPromoteResidentInfoModalAt } = promotionDisplay;

    // ローカルストレージに日付が保存されていない場合はモーダルを表示
    if (!showPromoteResidentInfoModalAt) return true;

    const lastShownTime = parseISO(showPromoteResidentInfoModalAt);

    // 日付が無効な場合はモーダルを表示
    if (!isValid(lastShownTime)) return true;

    const currentTime = new Date();
    const daysDifference = differenceInDays(currentTime, lastShownTime);

    // 前回表示から3日以上経過している場合はモーダルを表示
    return daysDifference >= EXPIRATION_DAYS;
  };

  useEffect(() => {
    if (isLoadingResidentInfo || !currentResidentInfo) {
      return;
    }

    /*
     * プロモーションモーダルの表示条件：
     * - CSメンバーであること
     * - ResidentInfo情報が未登録（ResidentInfoレコードが初期値のnullまたは{}のみで構成されていること）
     * - 非表示ボタンを押したことがないこと、または3日以上経過していること
     *
     * 非表示の状態管理：
     * - 非表示ボタンの状態はWebストレージで管理する
     * - 異なるデバイスやブラウザでは再度表示される
     * - ブラウザのストレージをクリアした場合も再度表示される
     */
    if (
      isCSMember &&
      shouldShowPromotionModal() &&
      isResidentInfoEmpty(currentResidentInfo)
    ) {
      showPromoteResidentInfoModal();
    }
  }, [
    isShowPromoteResidentInfoModal,
    isCSMember,
    isLoadingResidentInfo,
    currentResidentInfo,
  ]);

  if (!isShowPromoteResidentInfoModal) {
    return null;
  }

  return (
    <PromoteModalPortal
      onClose={handleClose}
      onClickImg={handleClickedImg}
      imageUrl={promoteResidentInfoImgUrl}
    />
  );
};
