import React, { forwardRef, useImperativeHandle } from "react";
import "quill/dist/quill.snow.css";
import styled from "styled-components";
import { useQuillEditor } from "./useQuillEditor";
import Loader from "../Common/Loader";

// Editorをカスタムしても、吐き出されるHTMLが想定通り行かない場合はsanitizeが悪さをしてる可能性があるので注意
// frontではDOMPurify
// serverではsanitize-htmlを使ってる

export interface QuillEditorHandle {
  updateContent: (value: string) => void;
  deleteImages: () => void;
}

type Props = {
  onChange: (value: string) => void;
  initialValue?: string;
  placeholder?: string;
  imageUploadDirectory?: string;
};

export const QuillEditor = forwardRef((props: Props, ref) => {
  const { quillElementRef, updateContent, deleteImages, isUploading } =
    useQuillEditor(props);

  useImperativeHandle(ref, () => ({
    updateContent,
    deleteImages,
  }));

  return (
    <>
      {isUploading && (
        <ProgressBarBackGround>
          <ProgressBarContainer>
            <Loader />
            <SubmittingDescription>
              <br />
              画像をアップロード中です
            </SubmittingDescription>
          </ProgressBarContainer>
        </ProgressBarBackGround>
      )}
      <Container
        ref={quillElementRef}
        onClick={() => quillElementRef.current?.focus()}
      />
    </>
  );
});

QuillEditor.displayName = "QuillEditor";

const Container = styled.div`
  min-height: 200px;
`;

const ProgressBarBackGround = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ProgressBarContainer = styled.div`
  padding-top: 24px;
  width: 40%;
  height: 40%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 32px 64px -16px rgb(0 16 14 / 31%);
`;

const SubmittingDescription = styled.div`
  text-align: center;
  padding-bottom: 10%;
`;
