import React, { useCallback, useState } from "react";
import { NonPostElement } from "../PTAPersonalPage";
import { PostHeader } from "../../../features/Post/PostHeader";
import { useMinePostList } from "../../../hooks/api/post";
import { Posts } from "./PostPage";
import { PostNavigation } from "../../../features/Post/Post";
import { PostCard } from "../../../features/Post/PostCard";
import styled from "styled-components";
import { Spinner } from "../../../components/icons/Spinner";
import { useOrganization } from "../../../hooks/recoil/organization";
import { usePostUserGroupList } from "../../../hooks/recoil/postUserGroup";
import { TargetGradesType } from "@shared/types/post/api";

/**
 * 連絡ページ(自分)
 *
 * TODO: 要検討 コンポーネント設計
 * 全て、自分、未承認は共通の方がメンテしやすそうなので共通化する
 * getAllPosts, getCurrentUserPosts, getUnApprovedPosts(これはAdminのみ)
 *
 * TODO: 再投稿申請用モーダル追加
 */
export default function PostMePage() {
  const { organization, writeOrganization } = useOrganization({});
  const { getPostUserGroupLabel } = usePostUserGroupList();
  const { posts, isLoading, getPosts } = useMinePostList();

  const getPostBelongLabel = useCallback(
    (target: TargetGradesType | string) => {
      return (
        organization?.belongs?.find((belong) => belong.id === target)?.name ??
        "削除済み"
      );
    },
    [organization]
  );

  const [openPostId, setOpenPostId] = useState("");

  return (
    <>
      <PostHeader
        organization={organization}
        updateOrganization={writeOrganization}
        refetch={getPosts}
      />
      <PostNavigation />
      <Posts>
        {posts.length === 0 && isLoading && (
          <Card>
            <Spinner />
          </Card>
        )}
        {posts.length !== 0
          ? posts.map((post, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    if (openPostId !== post.id) {
                      setOpenPostId(post.id);
                    } else {
                      setOpenPostId("");
                    }
                  }}
                >
                  <PostCard
                    post={post}
                    isOpen={openPostId == post.id}
                    type="me"
                    getPostBelongLabel={getPostBelongLabel}
                    getPostUserGroupLabel={getPostUserGroupLabel}
                    refetch={getPosts}
                    borderBottom={i !== posts.length - 1}
                  />
                </div>
              );
            })
          : !isLoading && (
              <NonPostElement
                title="連絡がありません"
                message="新規作成から作成ができます"
              />
            )}
      </Posts>
    </>
  );
}

const Card = styled.div`
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
