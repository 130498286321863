import React from "react";

type Props = {
  size: number;
};
export const PersonIcon = ({ size }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00065 5.00016C4.35898 5.00016 3.80968 4.77169 3.35273 4.31475C2.89579 3.8578 2.66732 3.3085 2.66732 2.66683C2.66732 2.02516 2.89579 1.47586 3.35273 1.01891C3.80968 0.561968 4.35898 0.333496 5.00065 0.333496C5.64232 0.333496 6.19162 0.561968 6.64857 1.01891C7.10551 1.47586 7.33398 2.02516 7.33398 2.66683C7.33398 3.3085 7.10551 3.8578 6.64857 4.31475C6.19162 4.77169 5.64232 5.00016 5.00065 5.00016ZM0.333984 9.66683V8.0335C0.333984 7.70294 0.419054 7.39912 0.589193 7.12204C0.759332 6.84495 0.985373 6.6335 1.26732 6.48766C1.8701 6.18627 2.4826 5.96023 3.10482 5.80954C3.72704 5.65884 4.35898 5.5835 5.00065 5.5835C5.64232 5.5835 6.27426 5.65884 6.89648 5.80954C7.51871 5.96023 8.13121 6.18627 8.73398 6.48766C9.01593 6.6335 9.24197 6.84495 9.41211 7.12204C9.58225 7.39912 9.66732 7.70294 9.66732 8.0335V9.66683H0.333984ZM1.50065 8.50016H8.50065V8.0335C8.50065 7.92655 8.47392 7.82933 8.42044 7.74183C8.36697 7.65433 8.29648 7.58627 8.20898 7.53766C7.68398 7.27516 7.15412 7.07829 6.6194 6.94704C6.08468 6.81579 5.5451 6.75016 5.00065 6.75016C4.45621 6.75016 3.91662 6.81579 3.3819 6.94704C2.84718 7.07829 2.31732 7.27516 1.79232 7.53766C1.70482 7.58627 1.63433 7.65433 1.58086 7.74183C1.52739 7.82933 1.50065 7.92655 1.50065 8.0335V8.50016ZM5.00065 3.8335C5.32148 3.8335 5.59614 3.71926 5.82461 3.49079C6.05308 3.26232 6.16732 2.98766 6.16732 2.66683C6.16732 2.346 6.05308 2.07134 5.82461 1.84287C5.59614 1.6144 5.32148 1.50016 5.00065 1.50016C4.67982 1.50016 4.40517 1.6144 4.17669 1.84287C3.94822 2.07134 3.83398 2.346 3.83398 2.66683C3.83398 2.98766 3.94822 3.26232 4.17669 3.49079C4.40517 3.71926 4.67982 3.8335 5.00065 3.8335Z"
        fill="#69707D"
      />
    </svg>
  );
};
