import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Career } from "../../../apiClients/career";
import { Margin } from "../../../components/Margin";
import {
  ModalPortal,
  ModalPortalProps,
  ModalSubmitButtons,
} from "../../../components/Modal";
import { useCareer } from "../../../hooks/api/career";
import {
  SelectWrapper,
  TextChildField,
} from "../../../pages/registration/pta/RegisterChildInfoPage";
import { useToast } from "../../../components/Toast";
import { ModalHeader } from "../../../pages/pta/admin/ProfilePage";
import { PulldownIcon } from "../../../components/icons/PulldownIcon";
import BelongsDropDownList from "./BelongsDropdownList";
import PartsDropdownList from "./PartsDropdownList";
import * as theme from "../../../theme";
import CareerDeleteModal from "./CareerDeleteModal";
import { FormLabel, YearSample, FormValues } from "./CareerInfoCreateModal";
import { CalenderIcon } from "../../../components/icons/CalenderIcon";
import { CalenderUI } from "../../../components/CalenderUI";
import { SelectChildDropdown } from "./SelectChildDropdown";
import { Child } from "../../../hooks/api/child";
import { SelectHighestPartDropdown } from "./SelectHighestPartDropdown";
import { getSchoolYear } from "../../../utils/getSchoolYear";
import { useOrganization } from "../../../hooks/recoil/organization";
import { APIError } from "src/utils/types/ApiError";

// 所属・役職のセレクトボックスの値
export type SelectBelongPartType = {
  id?: string;
  name?: string;
  isNone: boolean;
};

const CareerEditModal = ({
  inputCareer,
  userId,
  userChildren,
  isOpen,
  onClose,
  refetch,
}: {
  inputCareer: Career | null;
  userId: string;
  userChildren: Child[];
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
} & ModalPortalProps) => {
  const toast = useToast();
  const [selectedBelong, setSelectedBelong] = useState<SelectBelongPartType>(
    {} as SelectBelongPartType
  );
  const isSelectedBelongEmpty = Object.keys(selectedBelong).length === 0;

  const [selectedPart, setSelectedPart] = useState<SelectBelongPartType>(
    {} as SelectBelongPartType
  );
  const isSelectedPartEmpty = Object.keys(selectedPart).length === 0;

  const [isShowBelongDropdown, setIsShowBelongDropdown] =
    useState<boolean>(false);
  const [isShowPartDropdown, setIsShowPartDropdown] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  const {
    1: { updateCareer, deleteCareer },
  } = useCareer(userId);

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async (data: FormValues) => {
    setDoubleClickBlocked(true);
    try {
      await updateCareer(
        data.id,
        data.userId,
        data.childId,
        data.isGraduatedChild,
        data.isHighestPart,
        data.organizationId,
        data.belongId,
        data.partId,
        data.startDate,
        data.endDate
      );
      onClose();
      toast.success("保存しました");
    } catch (err) {
      if (err instanceof APIError) {
        toast.error(err.message);
      } else {
        toast.error("保存に失敗しました");
      }
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  const { organization } = useOrganization({});

  useEffect(() => {
    // 所属役職のデフォルト表示を設定
    if (!inputCareer?.belong) {
      setSelectedBelong({ isNone: true, name: "なし" });
    } else {
      setSelectedBelong({
        isNone: false,
        name: inputCareer.belong.name,
        id: inputCareer.belong.id,
      });
    }

    if (!inputCareer?.part) {
      setSelectedPart({ isNone: true, name: "なし" });
    } else {
      setSelectedPart({
        isNone: false,
        name: inputCareer.part.name,
        id: inputCareer.part.id,
      });
    }
  }, []);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      id: inputCareer?.id,
      userId: inputCareer?.userId,
      childId: inputCareer?.childId,
      partId: inputCareer?.part && inputCareer?.part.id,
      belongId: inputCareer?.belong && inputCareer?.belong.id,
      organizationId: inputCareer?.organizationId,
      startDate: inputCareer?.startDate?.split("-")[0],
      endDate: inputCareer?.endDate?.split("-")[0],
      isGraduatedChild: inputCareer?.isGradutatedChild,
      isHighestPart: inputCareer?.isHighestPart,
    },
    mode: "onBlur",
    shouldUnregister: false,
  });

  const onDeleteCareer = async () => {
    await deleteCareer(inputCareer?.id!);
    onClose();
    refetch();
  };
  const [showYearList, setShowYearList] = useState(false);
  const [showEndYearList, setShowEndYearList] = useState(false);
  const [thisYear, setThisYear] = useState(new Date().getFullYear());

  const changeYearValue = (value: string) => {
    setValue("startDate", value);
    setShowYearList(false);
  };

  const changeEndYearValue = (value: string) => {
    setValue("endDate", value);
    setShowEndYearList(false);
  };

  useEffect(() => {
    const selectedStartDate = watch("startDate");
    const selectedEndDate = watch("endDate");

    if (
      selectedEndDate &&
      (new Date(selectedStartDate) > new Date(selectedEndDate) ||
        new Date(selectedStartDate).getFullYear() ===
          new Date(selectedEndDate).getFullYear())
    ) {
      setError("endDate", {
        type: "custom",
        message: "終了日が不正です。",
      });
    } else clearErrors("endDate");
  }, [watch("startDate"), watch("endDate")]);

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { childId, isGraduatedChild, isHighestPart } = getValues();

    const childIdIsInvalid = !childId && !isGraduatedChild;
    const isHighestPartIsInvalid =
      isHighestPart === undefined || isHighestPart === null;

    const invalid = childIdIsInvalid || isHighestPartIsInvalid;

    if (childIdIsInvalid) {
      setError("childId", {
        type: "custom",
        message: "子供名もしくは卒業済みを選択してください",
      });
    }

    if (isHighestPartIsInvalid) {
      setError("isHighestPart", {
        type: "custom",
        message: "子供の最高役職かどうかを選択してください",
      });
    }

    if (invalid) return;

    handleSubmit(onSubmit)();
  };

  // NOTE: DB叩くよりフロント側で持ってるchildrenからひっぱってきた方が早い
  const defaultSelectChild = () => {
    if (inputCareer?.childId === null) return { id: null, name: "卒業済み" };

    const child = userChildren.filter(
      (chi) => chi.id == inputCareer?.childId
    )[0];
    if (!child) return null;

    return {
      id: child.id ?? "",
      name: `${child.childLastName} ${child.childFirstName}` ?? "",
    };
  };

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={onClose} modalBoxIsHeightFull={true}>
          <form onSubmit={submit}>
            <CareerStyledContainer>
              <ModalHeader>経歴を編集</ModalHeader>
              <SelectWrapper>
                <FormLabel>組織</FormLabel>
                <InputPTANameWrapper>
                  <InputPTAName
                    placeholder="組織名"
                    value={organization?.name}
                    disabled
                  />
                  <PTAText isDisabled>PTA</PTAText>
                </InputPTANameWrapper>
              </SelectWrapper>
              <SelectWrapper>
                <FormLabel>所属</FormLabel>
                <Margin marginBottom={8} />
                <BelongPartSelect
                  isOpen={isShowBelongDropdown}
                  onClick={() => {
                    if (isShowPartDropdown) setIsShowPartDropdown(false);
                    setIsShowBelongDropdown(!isShowBelongDropdown);
                  }}
                >
                  {isSelectedBelongEmpty
                    ? "選択してください"
                    : selectedBelong.name}
                  <span>
                    <PulldownIcon size={40} />
                  </span>
                </BelongPartSelect>
                <BelongsDropDownList
                  belongs={organization?.belongs!}
                  isOpen={isShowBelongDropdown}
                  onClose={() => setIsShowBelongDropdown(false)}
                  selectedBelong={selectedBelong}
                  setSelectedBelong={setSelectedBelong}
                  setValue={setValue}
                />

                {errors != null && errors.belongId != null ? (
                  <span className="error-message">
                    {errors?.belongId.message}
                  </span>
                ) : (
                  <Margin marginTop={16} />
                )}
              </SelectWrapper>
              <SelectWrapper>
                <FormLabel>役職</FormLabel>
                <Margin marginBottom={8} />
                <BelongPartSelect
                  isOpen={isShowPartDropdown}
                  onClick={() => {
                    if (isShowBelongDropdown) setIsShowBelongDropdown(false);
                    setIsShowPartDropdown(!isShowPartDropdown);
                  }}
                >
                  {isSelectedPartEmpty ? "選択してください" : selectedPart.name}
                  <span>
                    <PulldownIcon size={40} />
                  </span>
                </BelongPartSelect>
                <PartsDropdownList
                  parts={organization?.parts!}
                  isOpen={isShowPartDropdown}
                  onClose={() => setIsShowPartDropdown(false)}
                  selectedPart={selectedPart}
                  setSelectedPart={setSelectedPart}
                  setValue={setValue}
                />

                {errors != null && errors?.partId != null ? (
                  <span className="error-message">
                    {errors?.partId.message}
                  </span>
                ) : (
                  <Margin marginTop={16} />
                )}
              </SelectWrapper>

              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  gap: "16px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <FormLabel>開始</FormLabel>
                  <InputYearFieldWrapper>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                    </CalenderIconWrapper>
                    <InputYearField
                      placeholder="選択してください"
                      value={watch("startDate")?.split("-")[0]}
                      onClick={() => {
                        setShowYearList(!showYearList);
                        if (showEndYearList) return setShowEndYearList(false);
                      }}
                    />
                  </InputYearFieldWrapper>
                  <YearSample>
                    注：今年度は
                    {getSchoolYear(new Date())}
                    とえらぶ
                  </YearSample>
                  {showYearList && (
                    <CalenderUI
                      thisYear={thisYear}
                      setThisYear={setThisYear}
                      onChangeYear={changeYearValue}
                    />
                  )}
                  {errors != null && errors?.startDate != null ? (
                    <span className="error-message">
                      {errors?.startDate?.message}
                    </span>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </div>

                <div style={{ width: "100%" }}>
                  <FormLabel>終了</FormLabel>
                  <InputYearFieldWrapper>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                    </CalenderIconWrapper>

                    <InputYearField
                      placeholder="選択してください"
                      value={watch("endDate")?.split("-")[0]}
                      onClick={() => {
                        setShowEndYearList(!showEndYearList);
                        if (showYearList) return setShowYearList(false);
                      }}
                    />
                  </InputYearFieldWrapper>
                  <YearSample>
                    注：今年度は
                    {getSchoolYear(new Date()) + 1}
                    とえらぶ
                  </YearSample>
                  {showEndYearList && (
                    <CalenderUI
                      thisYear={thisYear}
                      setThisYear={setThisYear}
                      onChangeYear={changeEndYearValue}
                    />
                  )}
                  {errors != null && errors?.endDate != null ? (
                    <span className="error-message">
                      {errors?.endDate?.message}
                    </span>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </div>
              </div>

              <SelectWrapper>
                <FormLabel>この経歴に紐つくお子様</FormLabel>
                <Margin marginBottom={8} />
                <SelectChildDropdown
                  childItems={[
                    ...userChildren.map((child) => {
                      return {
                        id: child.id ?? null,
                        name: `${child.childLastName} ${child.childFirstName}`,
                      };
                    }),
                    { id: null, name: "卒業済み" },
                  ]}
                  formValues={watch()}
                  setFormValue={setValue}
                  defaultValue={defaultSelectChild()}
                  clearErrors={clearErrors}
                />

                {errors?.childId != null ? (
                  <span className="error-message">
                    {errors?.childId.message}
                  </span>
                ) : (
                  <Margin marginTop={16} />
                )}
              </SelectWrapper>

              <SelectWrapper>
                <FormLabel>
                  上記の役職はこのお子様に紐つく最高役職ですか？
                </FormLabel>
                <Margin marginBottom={8} />
                <SelectHighestPartDropdown
                  formValues={watch()}
                  setFormValues={setValue}
                  defaultValue={{
                    isHighestPart: inputCareer?.isHighestPart ?? false,
                    label: inputCareer?.isHighestPart ? "はい" : "いいえ",
                  }}
                  clearErrors={clearErrors}
                />

                {errors?.isHighestPart != null && (
                  <span className="error-message">
                    {errors?.isHighestPart.message}
                  </span>
                )}
              </SelectWrapper>

              <CareerButtonUIArea>
                {inputCareer != null && (
                  <DeletePostButton
                    type="button"
                    onClick={() => {
                      setIsShowDeleteModal(true);
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 3H16V4H0V3H5V1C5 0.448 5.448 0 6 0H10C10.552 0 11 0.448 11 1V3ZM3.944 11H7V12H4.1L4.492 14.519C4.534 14.788 4.746 14.977 4.985 14.977H11.015C11.254 14.977 11.466 14.788 11.508 14.519L13.006 4.943H14L12.496 14.673C12.38 15.42 11.756 15.977 11.015 15.977H4.985C4.244 15.977 3.62 15.42 3.504 14.673L1.993 4.943H9V5.95H3.157L3.476 8H8V9H3.632L3.944 11ZM6 3H10V1H6V3Z"
                        fill="#5A606B"
                      />
                    </svg>
                  </DeletePostButton>
                )}
                <div style={{ marginLeft: "auto" }}>
                  <ModalSubmitButtons
                    // disabled={errors != null}
                    submitText="更新"
                    onCancel={onClose}
                    disabled={doubleClickBlocked}
                  />
                </div>
              </CareerButtonUIArea>
            </CareerStyledContainer>
          </form>
        </ModalPortal>
      )}

      <CareerDeleteModal
        isOpen={isShowDeleteModal}
        onSubmit={onDeleteCareer}
        onClose={() => {
          setIsShowDeleteModal(false);
        }}
      />
    </>
  );
};

export const DefaultOptionText = styled.span`
  color: #aab4c4;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

const DeletePostButton = styled.button`
  display: block;
  width: 40px;
  height: 40px;
  border: none;
  background: rgba(105, 112, 125, 0.2);
  border-radius: 6px;
  cursor: pointer;
`;

const CareerButtonUIArea = styled.div`
  display: flex;
  margin-top: 20px;
`;

const CareerStyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  .error-message {
    font-size: 12px;
    color: #bd271e;
  }
`;

export const InputYearFieldWrapper = styled.div`
  position: relative;
`;

export const InputYearField = styled.input`
  width: 100%;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-radius: 6px;
  background: #fbfcfd;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  box-sizing: border-box;
  appearance: none;
  padding: 9.5px 12px;
  padding-left: 38px;
  cursor: pointer;
`;

export const CalenderIconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 13px;
`;

export const InputPTANameWrapper = styled.div`
  position: relative;
`;

const InputPTAName = styled(TextChildField)`
  &:disabled {
    opacity: 0.6;
  }
`;

export const PTAText = styled.p<{ isDisabled: boolean }>`
  padding: 10px 12px;
  line-height: 21px;
  font-weight: 900;
  z-index: 2;
  position: absolute;
  top: 1px;
  right: 0;
  opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};
`;

export const PastCareerWrap = styled.div`
  margin-top: 18px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #e4e6f3;
  padding: 20px;
`;

export const CurrentBadge = styled.div`
  background-color: #79aad9;
  width: 40px;
  height: 20px;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  border-radius: 5px;
  margin: 2px 0 0 10px;
`;

export const CareerTerm = styled.div`
  font-weight: normal;
  color: #69707d;
  text-align: left;
  padding-right: 16px;
`;

export const PastCareerCell = styled.th`
  display: flex;
`;

export const PastCareerSmallCell = styled.th`
  display: flex;
  font-size: 12px;
`;

export const PastCareerParentCell = styled.th`
  display: flex;
  padding-bottom: 70px;
`;

export const PastCareerHeading = styled.div`
  padding-bottom: 14px;
`;

const BelongPartSelect = styled.div<{ isOpen: boolean }>`
  width: 100%;
  padding: 10px 40px 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
  position: relative;

  ${(props) =>
    props.isOpen && `border-bottom: 2px solid ${theme.colorsPallet.primary};`}

  span {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export default CareerEditModal;
