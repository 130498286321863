import React from "react";
import styled from "styled-components";
import { Title } from "./Title";

type Props = {
  title: string;
};
export const Header = ({ title }: Props) => {
  return (
    <HeadingBar>
      <Title>{title}</Title>
    </HeadingBar>
  );
};

const HeadingBar = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
`;
