import React, { useEffect } from "react";
import { useCurrentUser } from "../../../hooks/recoil/user";
import * as Typo from "../../../components/Typo";
import * as Responsive from "../../../components/Responsive";
import styled from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import { Button, ButtonGroup } from "../../../components/Button";
import { Margin } from "../../../components/Margin";
import { CheckIcon } from "../../../components/icons/CheckIcon";
import { RegistrationFooter } from "../../../components/Footer2";
import { StepMember } from "../../../components/icons/StepMember";
import { StepAdmin } from "../../../components/icons/StepAdmin";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import { useBaseInfoById } from "../../../hooks/api/baseInfo";
import { Role, isPtaAdminRole } from "../../../utils/types/role";
import { useOrganization } from "../../../hooks/recoil/organization";
import { sortByOrder } from "src/utils/sortByOrder";

const FormValueSchema = z.object({
  belongId: z.string().optional(),
  partId: z.string().optional(),
});

type FormValues = z.infer<typeof FormValueSchema>;

export default function PositionSettingPage() {
  const currentUser = useCurrentUser();
  const { organization } = useOrganization({});
  const { baseInfoById, updateBelongAndPart } = useBaseInfoById(currentUser.id);

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(FormValueSchema),
    defaultValues: {
      belongId: "",
      partId: "",
    },
    mode: "onBlur",
  });

  const history = usePolyfitHistory();
  const user = useCurrentUser();

  const onSubmit = async (data: FormValues) => {
    try {
      await updateBelongAndPart(data.belongId, data.partId);

      switch (currentUser.role) {
        case Role.PTAAdmin:
          history.push({ to: "SIGNATURE_SETTING" });
          break;
        case Role.PTAMember:
          history.push({ to: "SIGNATURE_CONFIRM" });
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const selectBelong = async (id: string) => {
    await setValue("belongId", id);
  };
  const selectPart = async (id: string) => {
    await setValue("partId", id);
  };

  const watchedBelongId = useWatch({ name: "belongId", control });
  const watchedPartId = useWatch({ name: "partId", control });

  // APIからのデータ取得の完了した時点で、ユーザーが未選択の場合に初期値を設定
  useEffect(() => {
    if (baseInfoById?.belongId && !watchedBelongId && !watchedPartId) {
      setValue("belongId", baseInfoById.belongId ?? "");
    }
    if (baseInfoById?.partId && !watchedPartId) {
      setValue("partId", baseInfoById.partId);
    }
  }, [baseInfoById, setValue]);

  const sortedBelongs = sortByOrder(organization.belongs);
  const sortedParts = sortByOrder(organization.parts);
  return (
    <>
      <Margin marginTop={20} />
      {isPtaAdminRole(user.role) ? (
        <StepAdmin stepNum={4} />
      ) : (
        <StepMember stepNum={3} />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledContainer>
          <Margin marginBottom={16} />
          <Typo.Heading3 className="title">所属・役職の設定</Typo.Heading3>
          <Text2>
            本部や運営委員会、会長や書記など所属・役職がある方は該当する役職を設定してください
          </Text2>
          <Responsive.Row>
            <Responsive.Col>
              <Text>所属</Text>
              <ButtonGroupExtend>
                {organization &&
                  sortedBelongs.map((belong) => (
                    <Button
                      key={belong.id}
                      size="large"
                      fill
                      color={
                        watchedBelongId !== belong.id ? "ghost" : "primary"
                      }
                      className="button"
                      onClick={() => selectBelong(belong.id)}
                      type="button"
                    >
                      <span>{belong.name}</span>
                      {watchedBelongId === belong.id && <CheckIcon size={8} />}
                    </Button>
                  ))}
              </ButtonGroupExtend>
              {errors.belongId && (
                <ErrorMessage>{errors.belongId.message}</ErrorMessage>
              )}

              <Text>役職</Text>
              <ButtonGroupExtend>
                {organization &&
                  sortedParts.map((part) => (
                    <Button
                      key={part.id}
                      size="large"
                      fill
                      color={watchedPartId !== part.id ? "ghost" : "primary"}
                      className="button"
                      onClick={() => selectPart(part.id)}
                      type="button"
                    >
                      <span>{part.name}</span>
                      {watchedPartId === part.id && <CheckIcon size={8} />}
                    </Button>
                  ))}
              </ButtonGroupExtend>
              {errors.partId && (
                <ErrorMessage>{errors.partId.message}</ErrorMessage>
              )}
            </Responsive.Col>
          </Responsive.Row>
        </StyledContainer>
        <RegistrationFooter />
      </form>
    </>
  );
}

const StyledContainer = styled.div`
  max-width: 702px;
  margin: 0 auto;
  padding-bottom: 88px;
  table {
    border-collapse: collapse;
  }
  .table-belongs {
    width: 330px;
  }
  th,
  td {
    font-size: 16px;
    font-weight: normal;
    background-color: white;
  }
  tr {
    border: 1px solid #ccd1d9;
  }
  thead tr {
    border: 1px solid #ccd1d9;
  }
  .td-input {
    padding: 0;
  }
  .submit-button {
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  .title {
    font-weight: bold;
    padding-bottom: 8px;
  }
  .sub {
    padding: 8px 0;
  }
  .error-wrapper {
    display: flex;
    flex-direction: column;

    .error-message {
      font-size: 12px;
      color: #bd271e;
    }
  }
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

const Text = styled.p`
  font-weight: bold;
  margin: 10px 0;
`;
const Text2 = styled(Typo.Paragraph)`
  color: gray;
`;

const ButtonGroupExtend = styled(ButtonGroup)`
  display: flex;
  flex-wrap: wrap;
  .button {
    justify-content: flex-start;
    width: auto;
    max-width: 702px;
    margin: 0 8px 16px 0;
    > span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
    @media (max-width: 1279px) {
      margin: 0 14px 16px 0;
    }
  }
  svg {
    flex-shrink: 0;
    margin-left: 12px;
    @media (max-width: 1279px) {
      display: none;
    }
  }
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;
