import React from "react";

export const UnderConstructionIcon = () => {
  return (
    <svg
      width="251"
      height="244"
      viewBox="0 0 251 244"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M208 187.5H19C18.7239 187.5 18.5 187.276 18.5 187V51C18.5 50.7239 18.7239 50.5 19 50.5H154.549H208C208.276 50.5 208.5 50.7239 208.5 51V81.5V187C208.5 187.276 208.276 187.5 208 187.5Z"
        fill="#DEEFFF"
        stroke="#37434F"
        strokeWidth="5"
      />
      <path d="M22 185L202 169V185H22Z" fill="#8AC6FF" />
      <path
        d="M104.005 131H124.005"
        stroke="#37434F"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <circle cx="93.0049" cy="109" r="6" fill="#37434F" />
      <circle cx="134.005" cy="109" r="6" fill="#37434F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206 68H21V64H206V68Z"
        fill="#37434F"
      />
      <rect x="21" y="53" width="185" height="11" fill="white" />
      <circle cx="28.5" cy="58.5" r="3.5" fill="#37434F" />
      <circle cx="37.5" cy="58.5" r="3.5" fill="#37434F" />
      <circle cx="46.5" cy="58.5" r="3.5" fill="#37434F" />
      <circle
        cx="221.591"
        cy="34.3308"
        r="3.33333"
        transform="rotate(-10 221.591 34.3308)"
        stroke="#33363F"
        strokeWidth="2"
      />
      <circle
        cx="210.102"
        cy="36.3562"
        r="3.33333"
        transform="rotate(-10 210.102 36.3562)"
        stroke="#33363F"
        strokeWidth="2"
      />
      <path
        d="M202.089 29.3074L211.937 27.5709L209.912 16.0815M209.912 16.0815L210.49 19.3642L199.001 21.3901L201.548 35.834C201.74 36.9217 202.777 37.6481 203.865 37.4563L206.819 36.9353M209.912 16.0815L216.477 14.9239L225.42 19.8117C225.686 19.9574 225.872 20.2163 225.925 20.5155L226.42 23.3249M220.338 17.6277L218.697 17.9172L219.855 24.4825L226.42 23.3249M226.42 23.3249L227.809 31.2033C228.001 32.2911 227.275 33.3284 226.187 33.5203L224.874 33.7518M218.309 34.9094L213.385 35.7777"
        stroke="#33363F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="239.443"
        cy="58.7486"
        r="2.5"
        transform="rotate(-10 239.443 58.7486)"
        stroke="#33363F"
        strokeWidth="2"
      />
      <circle
        cx="230.826"
        cy="60.2681"
        r="2.5"
        transform="rotate(-10 230.826 60.2681)"
        stroke="#33363F"
        strokeWidth="2"
      />
      <path
        d="M224.817 54.9801L232.203 53.6777L230.684 45.0607M230.684 45.0607L231.118 47.5227L222.501 49.0421L224.324 59.3826C224.516 60.4704 225.553 61.1967 226.641 61.0049L228.364 60.701M230.684 45.0607L235.608 44.1924L242.209 47.8006C242.476 47.9463 242.662 48.2053 242.714 48.5045L243.065 50.4932M238.504 46.2203L237.273 46.4374L238.141 51.3614L243.065 50.4932M243.065 50.4932L244.02 55.9096C244.212 56.9974 243.486 58.0347 242.398 58.2265L241.905 58.3134M236.981 59.1816L233.288 59.8328"
        stroke="#33363F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
