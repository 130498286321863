import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryOptions } from "./QueryOptions";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import { fetchChatRoomById } from "src/apiClients/chatRoom";

export const useGetChatRoom = (
  chatRoomId: string | undefined,
  queryOptions: QueryOptions = {}
) => {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "chat-room", "fetchChatRoomById", apiContext, chatRoomId],
    queryFn: async () => {
      if (chatRoomId) return await fetchChatRoomById(chatRoomId);
    },
    ...queryOptions,
  });

  return {
    chatRoom: useMemo(() => query.data, [query.data]),
    ...query,
  };
};

export function useInvalidateGetChatRoom() {
  const queryClient = useQueryClient();

  function invalidateGetChatRoom() {
    queryClient.invalidateQueries({
      queryKey: ["api", "chat-room", "fetchChatRoomById"],
    });
  }

  return {
    invalidateGetChatRoom,
  };
}
