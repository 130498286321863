import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { CancelScheduledPostModal } from "../../pages/pta/post/PostUndeliveredPage";
import { PostType } from "../../hooks/api/post";
import { Button } from "../../components/Button3";
import { useCurrentUser } from "../../hooks/recoil/user";
import { useToast } from "../../components/Toast";

type Props = {
  post: PostType;
  refetch: () => void;
};

export const CancelScheduledButton = ({ post, refetch }: Props) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = useCallback(() => {
    if (!post.scheduledSendAt) {
      return;
    }

    if (
      new Date(post.scheduledSendAt).getTime() - new Date().getTime() <=
      10 * 60 * 1000
    ) {
      toast.warn("10分以内の予約投稿はキャンセルできません。");
    } else {
      setIsOpenModal(true);
    }
  }, []);

  return (
    <>
      <StyledButton
        size="large"
        color="dark"
        fill={true}
        onClick={handleOpenModal}
        disabled={!currentUser?.isApprover}
      >
        予約取り消し
      </StyledButton>
      {isOpenModal && (
        <CancelScheduledPostModal
          post={post}
          onClose={() => {
            setIsOpenModal(false);
          }}
          refetch={refetch}
        />
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  width: 140px;
`;
