import React, { useState } from "react";
import { ModalBackGround } from "../../components/Common/Menu";
import { ModalBody, ModalHeader, ModalPortal } from "../../components/Modal";
import { LinkFormElements } from "./LinkForm";
import { SubmitButtons } from "./LinkForm/SubmitButtons";
import { useForm } from "react-hook-form";
import { useLink } from "../../hooks/api/link";
import { useToast } from "../../components/Toast";
import { APIError } from "src/utils/types/ApiError";

// NOTE: LinkModalをcreate update deleteで共通化させるためにFormTypeを用意
export type FormType = "create" | "update" | "delete";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  type: FormType;
  closeMenu?: () => void;
  link?: LinkFormValue | null;
};

export type LinkFormValue = {
  id: string | null;
  title: string;
  url: string;
  description?: string | null;
};

export const LinkModal = ({
  isOpen,
  onClose,
  closeMenu,
  type,
  link,
}: Props) => {
  if (!isOpen) return null;

  const toast = useToast();
  const { createLink, updateLink, deleteLink } = useLink();

  const validationForLink = (data: LinkFormValue) => {
    const urlPattern =
      // FIXME: Regular Expressionを見直す
      // eslint-disable-next-line no-useless-escape
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
    let isValid = false;

    try {
      new URL(data.url);
      isValid = true;
    } catch (err) {
      isValid = false;
    }

    return isValid && urlPattern.test(data.url);
  };

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LinkFormValue>({
    defaultValues: {
      id: link?.id ?? null,
      title: link?.title ?? "",
      url: link?.url ?? "",
      description: link?.description ?? null,
    },
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async (data: LinkFormValue) => {
    if (!(type === "delete") && !validationForLink(data))
      return toast.error("URLをリンク形式で正しく入力してください");
    setDoubleClickBlocked(true);
    try {
      switch (type) {
        case "create":
          await createLink({
            title: data.title,
            url: data.url,
            description: data.description ?? null,
          });
          toast.success("保存しました");
          break;
        case "update":
          await updateLink({
            id: data.id,
            title: data.title,
            url: data.url,
            description: data.description ?? null,
          });
          toast.success("更新しました");
          break;
        case "delete":
          await deleteLink(link?.id ?? "");
          toast.success("削除しました");
          break;
        default:
          throw new Error("Invalid type");
      }
      onClose();
      if (closeMenu) {
        closeMenu();
      }
    } catch (err) {
      if (err instanceof APIError) {
        toast.error(err.message);
      } else {
        const typeToMessage = {
          create: "保存",
          update: "更新",
          delete: "削除",
        };
        toast.error(typeToMessage[type] + "に失敗しました");
      }
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>リンクを作成</ModalHeader>
        <ModalBody>
          <form onSubmit={submit}>
            {type === "delete" ? (
              <div>
                「{link?.url}」を削除します。
                削除するとリンク一覧から表示されなくなります。 よろしいですか？
              </div>
            ) : (
              <LinkFormElements
                register={register}
                watch={watch}
                errors={errors}
              />
            )}
            <SubmitButtons
              onClose={onClose}
              disabled={!isValid || doubleClickBlocked}
              type={type}
            />
          </form>
        </ModalBody>
      </ModalPortal>
      <ModalBackGround isOpen={isOpen} />
    </>
  );
};
