import React from "react";
import { PostType } from "../../hooks/api/post";
import { PostList } from "./PostList";
import styled from "styled-components";
import { TargetGradesType } from "@shared/types/post/api";

type props = {
  post: PostType;
  isOpen: boolean;
  type: "default" | "me" | "unapproved" | "undelivered";
  onPostApproved?: (post: PostType) => void;
  getPostBelongLabel: (target: TargetGradesType | string) => string;
  getPostUserGroupLabel: (target: string) => string;
  refetch: () => void;
  borderBottom?: boolean;
};

export const PostCard = ({
  post,
  isOpen,
  type,
  onPostApproved,
  getPostBelongLabel,
  getPostUserGroupLabel,
  refetch,
  borderBottom = true,
}: props) => {
  return (
    <PostCardWrapper borderBottom={borderBottom}>
      <PostList
        post={post}
        isOpen={isOpen}
        type={type}
        onPostApproved={onPostApproved}
        getPostBelongLabel={getPostBelongLabel}
        getPostUserGroupLabel={getPostUserGroupLabel}
        refetch={refetch}
      />
    </PostCardWrapper>
  );
};

export const PostCardWrapper = styled.div<{ borderBottom?: boolean }>`
  ${({ borderBottom }) => borderBottom && "border-bottom: 1px solid #d3dae6;"}
`;
