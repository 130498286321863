import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import { useLoginUser } from "../../../hooks/recoil/user";
import { Spinner } from "../../../components/icons/Spinner";
import { useGetCommunities } from "src/hooks/query/communityList";

export default function CommunityListPage() {
  const history = usePolyfitHistory();
  const { loginUser } = useLoginUser();
  const [searchText, setSearchText] = useState<string>("");
  const { communities, isLoading } = useGetCommunities({
    searchText,
    take: 200,
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearchText(searchText);
  };

  const handleEditCommunity = useCallback((communityId: string) => {
    history.push({
      to: "INTERNAL_COMMUNITY_EDIT",
      query: { communityId },
    });
  }, []);

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <>
      {loginUser?.internalRole !== "INTERNAL_ADMIN" ? (
        <>
          <div>読み込み中…</div>
          <div>画面が切り替わらない場合はお問い合わせください</div>
        </>
      ) : (
        <>
          <h2>コミュニティ一覧</h2>{" "}
          <a
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push({ to: "INTERNAL_ORGANIZATION_LIST" });
            }}
          >
            組織一覧
          </a>{" "}
          <TextField
            id="search-text-field"
            onChange={onChange}
            type="text"
            placeholder={"コミュニティを検索"}
          />
          <table>
            <tr>
              <td>コミュニティID</td>
              <td>コミュニティ名</td>
              <td>学校コード</td>
              <td>郵便番号</td>
              <td>住所</td>
              <td>作成日時</td>
              <td>更新日時</td>
              <td>操作</td>
            </tr>
            {communities.map((community, i) => {
              return (
                <tr
                  key={i}
                  style={i % 2 === 0 ? { backgroundColor: "white" } : undefined}
                >
                  <td>
                    <a
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push({
                          to: "INTERNAL_COMMUNITY_ORGANIZATION_LIST",
                          query: {
                            communityId: community.id,
                          },
                        });
                      }}
                    >
                      {community.id}
                    </a>
                  </td>
                  <td>
                    <a
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push({
                          to: "INTERNAL_COMMUNITY_ORGANIZATION_LIST",
                          query: {
                            communityId: community.id,
                          },
                        });
                      }}
                    >
                      {community.name}
                    </a>
                  </td>
                  <td>{community.schoolCode}</td>
                  <td>{community.postalCode}</td>
                  <td>
                    {community.address}
                    {community.latitude && community.longitude && (
                      <>
                        {" "}
                        <a
                          href={`https://www.google.com/maps?q=${community.latitude},${community.longitude}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          地図
                        </a>
                      </>
                    )}
                  </td>
                  <td>{community.createdAt}</td>
                  <td>{community.updatedAt}</td>
                  <td>
                    <TransitionLink
                      onClick={() => {
                        handleEditCommunity(community.id);
                      }}
                    >
                      edit
                    </TransitionLink>
                  </td>
                </tr>
              );
            })}
          </table>
        </>
      )}
    </> // end
  );
}

const TransitionLink = styled.a`
  text-decoration-line: underline;
  &:hover {
    cursor: pointer;
  }
`;

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;
