import React, { JSX } from "react";
import { useModal } from "../../../components/Modal";
import { SurveyResultModal } from "./SurveyResultModal";
import styled from "styled-components";
import { SurveyQuestions } from "@shared/types/post/survey/survey";

export function AggregateSurvey({
  surveyQuestions,
  postId,
  isAlreadyAnswered,
}: {
  surveyQuestions: SurveyQuestions | undefined;
  postId: string;
  isAlreadyAnswered: boolean;
}): JSX.Element {
  // ref: https://vitejs.dev/guide/env-and-mode.html#env-variables
  if (!import.meta.env.VITE_FEATURE_SURVEY) {
    return <></>;
  }
  // サーバー側で弾いているはずだが安全側に倒しておく
  if (surveyQuestions === undefined) {
    return <></>;
  }
  // モーダル状態
  const [, { show, close }] = useModal();
  const [isNewModal, setIsNewModal] = React.useState(false);

  // アンケートの回答期限切れ判定
  const isExpired = new Date(surveyQuestions.deadLine) < new Date();

  return (
    <>
      <TextButton
        onClick={(event) => {
          // このボタンのクリックに際してはカード開閉のイベントを無視する
          event.stopPropagation();
          // モーダルを開く
          setIsNewModal(!isNewModal);
          show();
        }}
      >
        アンケート結果を見る
      </TextButton>

      <SurveyResultModal
        surveyQuestionsId={surveyQuestions.id}
        onClose={() => {
          setIsNewModal(!isNewModal);
          close();
        }}
        isOpen={isNewModal}
        postId={postId}
        isExpired={isExpired}
        isAlreadyAnswered={isAlreadyAnswered}
      />
    </>
  );
}

const TextButton = styled.button`
  color: #005ec4;
  font-size: 14px;
  font-weight: normal;
  border-width: 0;
  background-color: inherit;
  cursor: pointer;
  width: fit-content;
`;
