import React, { FC, useCallback } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  UseFormRegisterReturn,
} from "react-hook-form";
import styled from "styled-components";

type props = {
  label?: string | React.ReactNode;
  register?: UseFormRegisterReturn;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
};

export const CheckBox = ({
  label,
  register,
  onChange,
  defaultChecked,
  checked,
  disabled,
}: props) => {
  return (
    <CheckBoxWrapper disabled={disabled}>
      <HiddenCheckbox
        onChange={onChange}
        defaultChecked={defaultChecked}
        checked={checked}
        disabled={disabled}
        {...register}
      />
      <StyledCheckbox checked={checked} disabled={disabled}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      <LabelText>{typeof label === "function" ? <label /> : label}</LabelText>
    </CheckBoxWrapper>
  );
};

type propsRHF<T extends FieldValues, U> = {
  label: string | FC;
  control: Control<T, object>;
  name: Path<T>;
  value: U;
};

export const CheckBoxRHF = <T extends object, U>({
  label,
  control,
  name,
  value,
}: propsRHF<T, U>) => {
  const handleCheckboxChange = useCallback((values: U[], checkedValue: U) => {
    if (values.includes(checkedValue)) {
      return values.filter((value) => value !== checkedValue);
    } else {
      return [...values, checkedValue];
    }
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={(props) => {
        return (
          <CheckBoxWrapper>
            <HiddenCheckbox
              // eslint-disable-next-line react/prop-types
              checked={(props.field.value as U[]).includes(value)}
              onChange={() =>
                // eslint-disable-next-line react/prop-types
                props.field.onChange(
                  // eslint-disable-next-line react/prop-types
                  handleCheckboxChange(props.field.value as U[], value)
                )
              }
            />
            <StyledCheckbox>
              <Icon viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
              </Icon>
            </StyledCheckbox>
            <LabelText>
              {typeof label === "function" ? <label /> : label}
            </LabelText>
          </CheckBoxWrapper>
        );
      }}
    />
  );
};

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ checked?: boolean; disabled?: boolean }>`
  font-family: "Avenir-Roman";
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 1px solid #c9cbcd;
  box-sizing: content-box;
  border-radius: 4px;
  transition: all 100ms;

  ${Icon} {
    visibility: hidden;
  }

  ${HiddenCheckbox}:checked + & {
    background: #0077cc;
    border: 1px solid #0077cc;
    ${Icon} {
      visibility: visible;
    }
  }

  ${(props) =>
    props.disabled &&
    `
    background: #f5f5f5;
    border-color: #cccccc;
  `}
`;

const LabelText = styled.span`
  margin-left: 8px;
  color: #343741;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CheckBoxWrapper = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;
