import * as client from "./client";
import {
  PostUserGroupType,
  PostUserGroupUserType,
} from "../hooks/api/postUserGroup";
import type {
  CreateGroupRequest,
  CreateGroupResponse,
  ExistGroupResponse,
  MyPostUserGroupsRequest,
  UpdatePostUserGroupRequest,
} from "@shared/types/postUserGroup";

const endpoint = "/postUserGroup";
/**
 * 連絡ユーザーグループ一覧取得
 */
export async function getPostUserGroups() {
  const res = await client.get<{}, PostUserGroupType[]>(endpoint, {});
  return res;
}

/**
 * 連絡ユーザーグループのユーザー一覧取得
 */
export async function getGroupUsers(id: string) {
  const res = await client.get<{ id: string }, PostUserGroupUserType[]>(
    `${endpoint}/users`,
    { id }
  );
  return res;
}

/**
 * アカウントが所属する連絡ユーザーグループ一覧取得
 * @param args GetMyPostUserGroupsArgs
 */
export async function fetchMyPostUserGroups(args: MyPostUserGroupsRequest) {
  const res = await client.get<MyPostUserGroupsRequest, PostUserGroupType[]>(
    `${endpoint}/myPostUserGroups`,
    args
  );
  return res;
}

/**
 * 連絡ユーザーグループ作成
 */
export async function createGroup({ args }: { args: CreateGroupRequest }) {
  const res = await client.post<CreateGroupRequest, CreateGroupResponse>(
    `${endpoint}/create`,
    { ...args }
  );
  return res;
}

/**
 * 連絡ユーザーグループ更新
 */
export async function updateGroup({
  args,
}: {
  args: UpdatePostUserGroupRequest;
}) {
  const res = await client.post<UpdatePostUserGroupRequest, PostUserGroupType>(
    `${endpoint}/update`,
    { ...args }
  );
  return res;
}

/**
 * 連絡ユーザーグループ削除
 */
export async function deleteGroup(id: string) {
  const res = await client.post<{ id: string }, PostUserGroupType>(
    `${endpoint}/delete`,
    { id }
  );
  return res;
}

/**
 * すでにグループが存在するかどうかの判定
 */
export async function existGroup() {
  const res = await client.get<{}, ExistGroupResponse>(`${endpoint}/exist`, {});
  return res;
}
