import React from "react";
import styled from "styled-components";

const StyledLeadUsingChromeText = styled.p`
  margin: 0;
  padding: 0;
  text-align: left;
  margin-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #69707d;

  a {
    color: #0077cc;
  }
`;

export const LeadUsingChromeText = () => {
  return (
    <StyledLeadUsingChromeText>
      ※polyfit(ポリフィット)はGoogle Chromeでご利用ください。
      <br />
      他のブラウザの場合は動作保証しません。
      <br />
      インストールは
      <a
        href="https://www.google.co.jp/chrome/"
        target="_blank"
        rel="noopener noreferrer"
      >
        こちら
      </a>
      からお願いします。
    </StyledLeadUsingChromeText>
  );
};
