import React, { useCallback, useEffect, useState } from "react";
import {
  approveApplication,
  declineApplicationByRecruitmentApplicationId,
  rejectApplication,
} from "../../../apiClients/recruitmentApplication";
import ApproveApplicationModal from "../../../features/Recruitment/application/ApproveApplicationModal";
import RejectApplicationModal from "../../../features/Recruitment/application/RejectApplicationModal";
import DeclineApplicationModal from "../../../features/Recruitment/application/DeclineApplicationModal";
import { usePolyfitHistory } from "../../../hooks/router";
import styled from "styled-components";
import { NoResults } from "../../../features/ListPage/NoResults";
import { ApplicantTable } from "../../../features/Recruitment/application/ApplicantTable";
import { ApplicantCardList } from "../../../features/Recruitment/application/ApplicantCardList";
import { MobileViewOnly, PcViewOnly } from "../../../components/Responsive";
import CountRecruitmentApplicationBar from "../../../features/Recruitment/application/CountRecruitmentApplicationBar";
import { Header } from "../../../components/Header";
import { Spinner } from "../../../components/icons/Spinner";
import { logger } from "src/utils/logger";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import { ResidentRecruitmentApplicationFilter } from "./ResidentRecruitmentApplicationFilter";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { getApplicationListByFilter } from "../../../apiClients/recruitmentApplication";

export default function ResidentRecruitmentApplicationListPage() {
  const history = usePolyfitHistory();

  // filter
  const methods = useForm<ResidentRecruitmentApplicationFilter>({
    defaultValues: {
      recruitmentId: undefined,
      name: undefined,
      applicationStatus: undefined,
    },
  });

  const [applicantList, setApplicantList] = useState<
    RecruitmentApplication[] | null
  >(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchRecruitment = useCallback(async () => {
    setLoading(true);
    const applicationStatus = methods.getValues("applicationStatus") || "ALL";
    const name = methods.getValues("name") || "";
    const recruitmentId = methods.getValues("recruitmentId") || undefined;
    const recruitments = await getApplicationListByFilter(
      applicationStatus,
      name,
      recruitmentId
    );
    setApplicantList(recruitments);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchRecruitment();
  }, []);

  const [editingApplicantId, setEditingApplicantId] = useState<
    string | undefined
  >(undefined);
  const [editingApplicantName, setEditingApplicantName] = useState<
    string | undefined
  >(undefined);
  const [confirmChangeStatus, setConfirmChangeStatus] = useState<
    "APPLIED" | "APPROVED" | "REJECTED" | "DECLINED" | undefined
  >(undefined);

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false); //同時にモーダルが開くことは無いので一つのdoubleClickBlockedで十分

  const handleModalOpen = useCallback(
    (
      modalStatus: "APPROVED" | "REJECTED" | "DECLINED",
      editingApplicantId: string,
      editingApplicantName: string
    ) => {
      setConfirmChangeStatus(modalStatus);
      setEditingApplicantId(editingApplicantId);
      setEditingApplicantName(editingApplicantName);
    },
    []
  );

  const handleApproveSubmit = useCallback(async () => {
    setDoubleClickBlocked(true);
    try {
      await approveApplication(editingApplicantId ?? "");
      await fetchRecruitment();
      setConfirmChangeStatus(undefined);
    } catch (error) {
      logger.error(new Error(`Error during approval process:` + error));
    } finally {
      setDoubleClickBlocked(false);
    }
  }, [editingApplicantId]);

  const handleRejectSubmit = useCallback(
    async (comment: string) => {
      setDoubleClickBlocked(true);
      try {
        await rejectApplication(editingApplicantId ?? "", comment);
        await fetchRecruitment();
        setConfirmChangeStatus(undefined);
      } catch (error) {
        logger.error(new Error(`Error during approval process:` + error));
      } finally {
        setDoubleClickBlocked(false);
      }
    },
    [editingApplicantId]
  );

  const handleDeclineSubmit = useCallback(
    async (comment: string) => {
      setDoubleClickBlocked(true);
      try {
        await declineApplicationByRecruitmentApplicationId(
          editingApplicantId ?? "",
          comment
        );
        await fetchRecruitment();
        setConfirmChangeStatus(undefined);
      } catch (error) {
        logger.error(new Error(`Error during approval process:` + error));
      } finally {
        setDoubleClickBlocked(false);
      }
    },
    [editingApplicantId]
  );

  const handleClose = useCallback(() => {
    setConfirmChangeStatus(undefined);
    setEditingApplicantId(undefined);
  }, []);

  const onApplicantDetailClick = useCallback(
    (userId: string) => {
      history.push({
        to: "PTA_LIST_MEMBER",
        query: { userId },
      });
    },
    [history]
  );

  const handleChangeStatus = useCallback(
    (applicantId?: string, userName?: string, userId?: string) =>
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        switch (e.target.value) {
          case "APPROVED":
            handleModalOpen(e.target.value, applicantId ?? "", userName ?? "");
            break;
          case "REJECTED":
            handleModalOpen(e.target.value, applicantId ?? "", userName ?? "");
            break;
          case "DECLINED":
            handleModalOpen(e.target.value, applicantId ?? "", userName ?? "");
            break;
          default:
            break;
        }
      },
    []
  );

  const applicantListIsEmpty = applicantList && !applicantList?.length;

  const onSubmit: SubmitHandler<ResidentRecruitmentApplicationFilter> = () => {
    fetchRecruitment();
  };

  return (
    <ApplicantListPage>
      <CountRecruitmentApplicationBar />
      <Header title="応募一覧" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ResidentRecruitmentApplicationFilter />
        </form>
      </FormProvider>

      {isLoading ? (
        <SpinnerCard>
          <Spinner />
        </SpinnerCard>
      ) : applicantListIsEmpty ? (
        <NoResults />
      ) : (
        <>
          <PcViewOnly>
            <ApplicantTable
              applicantList={applicantList}
              handleChangeStatus={handleChangeStatus}
              onApplicantDetailClick={onApplicantDetailClick}
            />
          </PcViewOnly>
          <MobileViewOnly>
            <ApplicantCardList
              applicantList={applicantList}
              handleChangeStatus={handleChangeStatus}
              onApplicantDetailClick={onApplicantDetailClick}
            />
          </MobileViewOnly>
        </>
      )}
      {confirmChangeStatus === "APPROVED" && (
        <ApproveApplicationModal
          name={editingApplicantName ?? ""}
          onSubmit={handleApproveSubmit}
          onClose={handleClose}
          disabled={doubleClickBlocked}
        />
      )}
      {confirmChangeStatus === "REJECTED" && (
        <RejectApplicationModal
          name={editingApplicantName ?? ""}
          onSubmit={handleRejectSubmit}
          onClose={handleClose}
          disabled={doubleClickBlocked}
        />
      )}
      {confirmChangeStatus === "DECLINED" && (
        <DeclineApplicationModal
          name={editingApplicantName ?? ""}
          onSubmit={handleDeclineSubmit}
          onClose={handleClose}
          disabled={doubleClickBlocked}
        />
      )}
    </ApplicantListPage>
  );
}

const ApplicantListPage = styled.div`
  margin: 0 auto;
`;

const SpinnerCard = styled.div`
  display: flex;
  justify-content: center;
  background: #ffffff;
  padding-bottom: 40px;
  padding-top: 40px;
`;
