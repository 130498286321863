import { User } from "src/apiClients/users";
import * as client from "./client";
import { Organization } from "./organization";

export type Belong = {
  id: string;
  organizationId: string;
  name: string;
  order: number | null;
  createdAt: Date;
  organization?: Organization;
  users: User[];
};

export async function createBelong(
  organizationId: string,
  name: string,
  order: number | null
) {
  const res = await client.post("/belong/create", {
    organizationId,
    name,
    order,
  });
  return res;
}

export async function updateBelong(
  belongId: string,
  name: string,
  order: number | null
) {
  const res = await client.post("/belong/update", {
    belongId,
    name,
    order,
  });
  return res;
}

export async function deleteBelong(belongId: string) {
  const res = await client.post("/belong/delete", { belongId });
  return res;
}
