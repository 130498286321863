/**
 * z-indexの値を一元管理する
 *
 * ノーマルの要素(グローバルに影響しないz-index)は1 ~ 99でよしなに変更してください
 * fixedElement -> グローバルヘッダーのような固定要素のz-index
 * toast -> toastのz-index
 * notModalMenuBackground -> modal上にないドロップダウンボックスや絞り込みのメニュー系の背景のz-index
 * notModalMenu -> modal上にないドロップダウンボックスや絞り込みのメニュー系のz-index
 * modalBackground -> modalの背景のz-index
 * modal -> modalのz-index
 * modalMenu -> modal上にあるドロップダウンボックスや絞り込みのメニュー系のz-index
 * modalOnModalBackground -> modalの上にあるmodalの背景のz-index
 * modalOnModal -> modalの上にあるmodalのz-index
 */

export const zIndexes = {
  sidebar: 100,
  notModalMenuBackground: 800,
  notModalMenu: 850,
  fixedElement: 900,
  toast: 950,
  modalBackground: 990,
  modal: 1000,
  modalMenuBackground: 1100,
  modalMenu: 1200,
  modalOnModalBackground: 1400,
  modalOnModal: 1500,
};
