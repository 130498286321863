import React from "react";
export const FilePlusIcon = () => {
  return (
    <svg
      width="241"
      height="240"
      viewBox="0 0 241 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46 42C46 41.7239 46.2239 41.5 46.5 41.5H116.5H146.5C146.776 41.5 147 41.7239 147 42V170C147 170.276 146.776 170.5 146.5 170.5H46.5C46.2239 170.5 46 170.276 46 170V42Z"
        fill="#DEEFFF"
        stroke="#37434F"
        strokeWidth="5"
      />
      <path d="M48.5 44H49.0294L51.5 168H48.5V44Z" fill="white" />
      <path d="M55.5 55L52.5 168H55.5V55Z" fill="#8AC6FF" />
      <path
        d="M166.742 84.316L166.742 84.3257V84.3354V190C166.742 190.276 166.518 190.5 166.242 190.5H58.5C58.2239 190.5 58 190.276 58 190V54C58 53.7239 58.2239 53.5 58.5 53.5H136.498H166.477C166.754 53.5 166.979 53.7262 166.977 54.0039L166.742 84.316Z"
        fill="#DEEFFF"
        stroke="#37434F"
        strokeWidth="5"
      />
      <path d="M59.5 56H60.5588L65.5 188H59.5V56Z" fill="white" />
      <circle cx="162.5" cy="170" r="39" fill="#37434F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.5 190C159.5 191.657 160.843 193 162.5 193C164.157 193 165.5 191.657 165.5 190V173H182.5C184.157 173 185.5 171.657 185.5 170C185.5 168.343 184.157 167 182.5 167H165.5V150C165.5 148.343 164.157 147 162.5 147C160.843 147 159.5 148.343 159.5 150V167H142.5C140.843 167 139.5 168.343 139.5 170C139.5 171.657 140.843 173 142.5 173H159.5V190Z"
        fill="white"
      />
      <path
        d="M175.5 16.1319L178.99 22.7032C179.279 23.2475 179.803 23.6281 180.41 23.7349L187.738 25.0235L182.567 30.3735C182.139 30.8166 181.939 31.4325 182.025 32.0428L183.064 39.4105L176.378 36.1457C175.824 35.8752 175.176 35.8752 174.622 36.1457L167.936 39.4105L168.975 32.0428C169.061 31.4325 168.861 30.8166 168.433 30.3735L163.262 25.0235L170.59 23.7349C171.197 23.6281 171.721 23.2475 172.01 22.7032L175.5 16.1319Z"
        fill="white"
        stroke="#37434F"
        strokeWidth="2"
      />
      <path
        d="M191.5 55.1319L194.07 59.9701C194.359 60.5144 194.883 60.895 195.49 61.0018L200.885 61.9506L197.078 65.8896C196.649 66.3327 196.449 66.9486 196.535 67.5588L197.3 72.9835L192.378 70.5797C191.824 70.3092 191.176 70.3092 190.622 70.5797L185.7 72.9835L186.465 67.5588C186.551 66.9486 186.351 66.3327 185.922 65.8896L182.115 61.9506L187.51 61.0018C188.117 60.895 188.641 60.5144 188.93 59.9701L191.5 55.1319Z"
        fill="white"
        stroke="#37434F"
        strokeWidth="2"
      />
    </svg>
  );
};
