import { RecruitmentTemplate } from "@shared/types/recruitmentTemplate";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { getRecruitmentTemplateById } from "src/apiClients/recruitmentTemplate";

export const useRecuseRecruitmentTemplate = (recruitmentTemplateId: string) => {
  const query = useQuery<RecruitmentTemplate, Error>({
    queryKey: [
      "api",
      "recruitmentTemplate",
      "getRecruitmentTemplate",
      recruitmentTemplateId,
    ],
    queryFn: () => getRecruitmentTemplateById(recruitmentTemplateId),
  });

  return {
    recruitmentTemplate: useMemo(() => query.data || null, [query.data]),
    ...query,
  };
};

export function useInvalidateRecruitmentTemplate() {
  const queryClient = useQueryClient();

  function invalidateRecruitmentTemplate(recruitmentTemplateId?: string) {
    if (recruitmentTemplateId) {
      queryClient.invalidateQueries({
        queryKey: [
          "api",
          "recruitmentTemplate",
          "getRecruitmentTemplate",
          recruitmentTemplateId,
        ],
      });
    } else {
      queryClient.invalidateQueries({
        queryKey: ["api", "recruitmentTemplate", "getRecruitmentTemplate"],
      });
    }
  }

  return {
    invalidateRecruitmentTemplate,
  };
}
