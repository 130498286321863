export type Result = {
  text: string;
  hit: boolean;
};

export type Options = {
  caseInsensitive?: boolean;
};

export function splitTextByKeywords(
  text: string,
  keywords: string[],
  options: Options = {}
) {
  const flags = options.caseInsensitive ? "gi" : "g";
  const searchWord = keywords.map((keyword) => keyword.trim()).join("|");
  if (!searchWord) {
    return [{ text, hit: false }];
  }

  const regex = new RegExp(`(${searchWord})`, flags);
  let match;
  let lastIndex = 0;
  const results: Result[] = [];

  while ((match = regex.exec(text)) !== null) {
    if (match.index > lastIndex) {
      results.push({ text: text.slice(lastIndex, match.index), hit: false });
    }
    results.push({ text: match[0], hit: true });
    lastIndex = regex.lastIndex;
  }

  if (lastIndex < text.length) {
    results.push({ text: text.slice(lastIndex), hit: false });
  }

  return results;
}
