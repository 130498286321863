import React, { memo } from "react";
import styled from "styled-components";

type Props = {
  size?: keyof typeof sizes;
};

const sizes = {
  md: 48,
};

export const Spinner = memo(({ size = "md" }: Props) => {
  return <StyledDiv size={sizes[size]} />;
});

const StyledDiv = styled.div<{
  size: number;
}>`
  width: ${({ size }) => size}px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: ${({ size }) => size / 8}px solid rgb(211, 218, 230);
  border-right-color: rgb(0, 119, 204);
  animation: spinner 1s infinite linear;

  @keyframes spinner {
    to {
      transform: rotate(1turn);
    }
  }
`;
