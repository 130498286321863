import React, { FC } from "react";
import styled from "styled-components";

type props = {
  color: "primary" | "success" | "danger" | "survey";
  children?: React.ReactNode;
};
export const Badge: FC<props> = ({ color, children }) => {
  return (
    <StyledBadge color={color}>
      <BadgeText>{children}</BadgeText>
    </StyledBadge>
  );
};

const StyledBadge = styled.div<{
  color: "primary" | "success" | "danger" | "survey";
}>`
  padding: 5px 24px;
  border-radius: 3px;
  display: grid;
  place-items: center;
  background: ${(props) => {
    if (props.color === "primary") return "#79AAD9";
    if (props.color === "success") return "#6DCCB1";
    if (props.color === "danger") return "#ff7e62";
    if (props.color === "survey") return "#5cc4ff";
  }};
`;

const BadgeText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  white-space: nowrap;
  display: flex;
  gap: 8px;
  align-items: center;
`;
