import { UpdateInviteCommunityRolesRequest } from "@shared/types/auth";
import * as client from "./client";
import {
  FlowType,
  InvitationValidateRequest,
  InvitationValidateResponse,
  InviteBEUserArgs,
} from "@shared/types/invitation";

export type InviteResident = {
  email: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  phoneNumber: string;
  gender: "MALE" | "FEMALE" | "OTHER";
  ageGroup: number;
  postalCode: string;
  prefecture: string;
  city: string;
  address1: string;
  address2: string | null;
  communityRoles: UpdateInviteCommunityRolesRequest;
};

export async function validateInvitationToken({
  token,
}: InvitationValidateRequest): Promise<InvitationValidateResponse> {
  const res = await client.post<
    { token: string },
    { valid: boolean; type?: "PTA" | "RESIDENT" }
  >("/invitation/validate", {
    token,
  });
  return res;
}

export async function getOrGenerateInvitationToken({
  organizationId,
}: {
  organizationId?: string;
}): Promise<string> {
  const res = await client.post<{}, { token: string }>(
    "/invitation/getOrGenerate/pta",
    { organizationId }
  );
  return res.token;
}

export async function getOrGenerateInvitationResidentToken({
  organizationId,
  flow = "DEFAULT",
}: {
  organizationId?: string;
  flow?: FlowType;
}): Promise<string> {
  const res = await client.post<{}, { token: string }>(
    "/invitation/getOrGenerate/resident",
    { organizationId, flow }
  );
  return res.token;
}

export const inviteResidentMember = async (
  args: InviteResident
): Promise<void> => {
  await client.post<InviteResident>("/invitation/resident", args);
};

export const inviteBEMember = async (args: InviteBEUserArgs): Promise<void> => {
  await client.post<InviteBEUserArgs>("/invitation/boardEducation", args);
};

export const verifiedInvitationResidentMember = async (
  token: string
): Promise<{
  isNewUser: boolean;
}> => {
  return await client.post<
    { token: string },
    {
      isNewUser: boolean;
    }
  >("/invitation/resident/verify", {
    token,
  });
};

export const verifiedInvitationBoardEducationMember = async (
  token: string
): Promise<{
  isNewUser: boolean;
}> => {
  return await client.post<
    { token: string },
    {
      isNewUser: boolean;
    }
  >("/invitation/boardEducation/verify", {
    token,
  });
};
