import { useCallback, useState, useEffect } from "react";
import * as usersApi from "../../apiClients/users";
import * as authApi from "../../apiClients/auth";
import { TeacherUser, User } from "../../apiClients/users";
import { ptaUsersState } from "../recoil/ptaUsres";
import { useRecoilState } from "recoil";
import { UserType } from "../../utils/types/userType";
import { Role } from "../../utils/types/role";
import { ServiceType } from "../../utils/types/serviceType";

const dummyUsers: User[] = [];
// for (let i = 0; i < 10; i++) {
//   dummyUsers.push({
//     id: Math.random().toString(16),
//     email: 'hello',
//     name: 'Mr. Unknown',
//   })
// }

export function useUserById(userId: string) {
  const initialState: User = {
    id: "",
    email: "",
    name: "",
    internalRole: "Member",
    role: "PTAMember",
    isApprover: false,
    picture: "",
    firebaseUid: "",
    able: false,
    signUpState: false,
    type: "PARENT",
  };
  const [user, setUser] = useState<User>(initialState);
  const [isWritingUser, setIsWriting] = useState(false);
  const getUser = async () => {
    const user = await authApi.getUserById(userId);
    setUser(user);
  };
  const writeUser = useCallback(
    async (
      {
        id,
        email,
        name,
        internalRole,
        type,
        role,
        isApprover,
        picture,
        able,
        signUpState,
      }: {
        id: string;
        email: string;
        name: string;
        internalRole: "INTERNAL_ADMIN" | "Member";
        type: UserType;
        role: Role;
        isApprover: boolean;
        picture: string;
        able: boolean;
        signUpState: boolean;
      },
      organizationId: string
    ) => {
      setIsWriting(true);
      // TODO: Accountに置き換える
      const user = await usersApi.updateUserById({
        userId: id,
        email: email,
        name: name,
        internalRole: internalRole,
        picture: picture,
      });
      await authApi.updateAccountByInternalAdmin(
        {
          userId: id,
          able,
          signUpState,
          isApprover,
          userType: type,
          role,
        },
        organizationId
      );
      setUser(user);
      setIsWriting(false);
    },
    []
  );
  useEffect(() => {
    if (userId) getUser();
  }, [userId]);
  return { user, isWritingUser, writeUser };
}

/**
 * for Internal Admin
 */
export function useUserListByCommunityId(communityId: string) {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUsers("PTA");
  }, []);

  const getUsers = async (serviceType: ServiceType) => {
    setLoading(true);
    const users = await usersApi.getUsersByCommunityId({
      communityId,
      serviceType,
    });
    setUsers(users);
    setLoading(false);
  };

  return { users, getUsers, loading };
}

/**
 * for Internal Admin
 */
export function useUserListByOrganizationId(organizationId: string) {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoading(true);
    const users = await usersApi.getUsersByOrganizationId({
      organizationId,
    });
    setUsers(users);
    setLoading(false);
  };

  return { users, getUsers, loading };
}

/**
 * for polyfit Users
 */
export function usePTAUsers({ skip = false }: { skip?: boolean }) {
  const [users, setUsers] = useRecoilState(ptaUsersState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!skip) getUsers();
  }, []);

  const getUsers = async () => {
    const users = await usersApi.getPTAUsers();
    setUsers(users);
  };

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    const users = await usersApi.getPTAUsers();
    setUsers([...users, ...dummyUsers]);
    setIsLoading(false);
  }, []);

  return [{ users, isLoading }, fetchUsers, getUsers] as const;
}

export function useDisabledUsersByOrganizationId() {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    const users = await usersApi.getDisabledUsersByOrganizationId();
    setUsers([...users, ...dummyUsers]);
    setIsLoading(false);
  }, []);

  return [{ users, isLoading }, fetchUsers] as const;
}

/**
 * 未承認の教員を取得する
 */
export type TeacherFilterType = {};
export const useDisabledTeacherUser = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [teachers, setTeacher] = useState<TeacherUser[]>([]);

  const getDisabledTeacherUsers = async (
    q?: string,
    filter?: TeacherFilterType
  ) => {
    setIsLoading(true);
    // レンダリングごとにsetChildrenが呼び出されると、重複が発生するのでレンダリングごとにChildrenをリセットする
    setTeacher([]);
    const teachers = await usersApi.getDisabledTeacherUser(q, filter);

    setTeacher([...new Set(teachers)]);
    setIsLoading(false);
  };

  useEffect(() => {
    getDisabledTeacherUsers();
  }, []);

  return { isLoading, teachers, getDisabledTeacherUsers };
};

export function useAdminUsersByOrganizationId({
  organizationId,
}: {
  organizationId: string;
}) {
  const [adminUsers, setAdminUsers] = useState<User[]>([]);

  const fetchAdminUsers = useCallback(async () => {
    setAdminUsers([]);
    await usersApi
      .getAdminUserByOrganizationId(organizationId)
      .then((res) => setAdminUsers(res.users));
  }, [organizationId]);

  useEffect(() => {
    fetchAdminUsers();
  }, []);

  return { adminUsers, fetchAdminUsers };
}
