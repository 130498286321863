import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import { useToast } from "../../../components/Toast";
import styled from "styled-components";
import { CopyIcon } from "../../../components/icons/CopyIcon";
import { DownloadIcon } from "../../../components/icons/DownloadIcon";

export const QrcodeView = ({ link }: { link: string }) => {
  const toast = useToast();
  return (
    <Container>
      <QRCodeCanvas
        value={link}
        size={170}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
        includeMargin={false}
      />
      <QRCodeButtons>
        <QRCodeCopy
          onClick={() => {
            const c = document.getElementsByTagName("canvas");
            c[0].toBlob(function (blob) {
              if (blob == null) {
                return;
              }
              const item = new ClipboardItem({
                "image/png": blob,
              });
              navigator.clipboard.write([item]);
              toast.success("コピーしました");
            });
          }}
        >
          <CopyIcon />
          画像をコピー
        </QRCodeCopy>
        <QRCodeDownload
          onClick={() => {
            const c = document.getElementsByTagName("canvas");
            const link = document.createElement("a");
            link.href = c[0].toDataURL("image/png");
            link.download = "招待リンク.png";
            link.click();
            toast.success("ダウンロードしました");
          }}
        >
          <DownloadIcon />
          ダウンロード
        </QRCodeDownload>
      </QRCodeButtons>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f7fa;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
`;

const QRCodeButtons = styled.div`
  width: 160px;
  margin-top: 16px;
`;

const QRCodeCopy = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  /* Font Style */
  color: #5a606b;
  font-size: 14px;
  padding: 9.5px 12px;
`;

const QRCodeDownload = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  /* Font Style */
  color: #5a606b;
  font-size: 14px;
  padding: 9.5px 12px;
`;
