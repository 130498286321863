import React, { useState } from "react";
import { PostUserGroupType } from "../../hooks/api/postUserGroup";
import { Member } from "../../components/SearchMembersField";
import {
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "../../components/Modal";
import { Margin } from "../../components/Margin";
import styled from "styled-components";
import { PulldownIcon } from "../../components/icons/PulldownIcon";
import { GroupsDropdownList } from "./GroupsDropdownList";
import { EditGroupModal } from "./EditGroupModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  members: Member[];
  selectedGroupId: string;
  setSelectedGroupId: (value: string) => void;
  setIsOpenEditGroupSelectedModal: (value: boolean) => void;
  postGroups: PostUserGroupType[];
  getPostGroups: () => Promise<void>;
};

export const EditGroupSelectedModal = ({
  isOpen,
  onClose,
  members,
  selectedGroupId,
  setSelectedGroupId,
  setIsOpenEditGroupSelectedModal,
  postGroups,
  getPostGroups,
}: Props) => {
  const [isOpenedList, setIsOpenedList] = useState(false);
  const [isOpenEditGroupModal, setIsOpenEditGroupModal] = useState(false);
  const onSubmit = () => {
    setIsOpenEditGroupModal(true);
    onClose();
  };

  const selectedGroupName =
    postGroups.find((group) => group.id === selectedGroupId)?.name || "";
  return (
    <>
      {isOpen && (
        <ModalPortal
          onClose={() => {
            onClose();
          }}
        >
          <ModalHeader>連絡グループ編集</ModalHeader>
          <p>作成済みの連絡グループを編集できます。</p>
          <Margin marginTop={20} />
          <GroupSelect
            onClick={() => {
              setIsOpenedList(!isOpenedList);
            }}
          >
            {selectedGroupId !== "" ? selectedGroupName : "グループを選択"}
            <span>
              <PulldownIcon size={40} />
            </span>
          </GroupSelect>
          {isOpenedList && (
            <GroupsDropdownList
              groups={postGroups}
              selectedGroupId={selectedGroupId}
              setSelectedGroupId={setSelectedGroupId}
            />
          )}

          <ModalSubmitButtons
            submitText="次へ"
            disabled={selectedGroupId === ""}
            onSubmit={onSubmit}
            onCancel={() => {
              onClose();
            }}
          />
        </ModalPortal>
      )}

      <EditGroupModal
        isOpen={isOpenEditGroupModal}
        onClose={() => setIsOpenEditGroupModal(false)}
        onCancel={() => setIsOpenEditGroupSelectedModal(true)}
        members={members}
        selectedGroupName={selectedGroupName}
        selectedGroupId={selectedGroupId}
        getPostGroups={getPostGroups}
      />
    </>
  );
};

const GroupSelect = styled.div`
  width: 100%;
  padding: 10px 40px 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
  position: relative;

  span {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
