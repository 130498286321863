import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import styled from "styled-components";
import encoding from "encoding-japanese";
import { ModalBody, ModalHeader } from "../../components/Modal";
import { FileCharacterIcon } from "../../components/icons/FileCharacterIcon";

import * as csvApi from "../../apiClients/csv";
import { useCurrentUser } from "../../hooks/recoil/user";
import { useToast } from "../../components/Toast";
import { DangerIcon } from "../../components/icons/DangerIcon";
import { zIndexes } from "../../zIndex";

// TODO: 下記のような単純なロジックをどこに置くか
const convertCharCodeToUnicode = (
  encodedArray: number[],
  encodeType?: encoding.Encoding
) => {
  if (encodeType === "UNICODE") return;

  return encoding.convert(encodedArray, { from: encodeType, to: "UNICODE" });
};

type Props = {
  isOpenListBulkImportModal: boolean;
  setIsOpenListBulkImportModal: Dispatch<SetStateAction<boolean>>;
  downloadCsvFile: () => void;
};

export const ListBulkImportModal = ({
  isOpenListBulkImportModal,
  setIsOpenListBulkImportModal,
  downloadCsvFile,
}: Props) => {
  const fileImportButtonRef = useRef<HTMLInputElement>(null);
  const currentUser = useCurrentUser();
  const [isBulkUpdateFailed, setIsBulkUpdateFailed] = useState(false);
  const toast = useToast();

  const importListCsvFile = () => {
    if (fileImportButtonRef.current) fileImportButtonRef.current.click();
  };

  function mapCSVToArray(csv: string): string[][] {
    return csv.split("\n").map((row) => row.split(","));
  }

  function str2Array(str: string) {
    const array = [];
    const il = str.length;
    for (let i = 0; i < il; i++) array.push(str.charCodeAt(i));
    return array;
  }

  const onFileImportChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const file = event.target.files![0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = async (event) => {
      const inputFile = event.target?.result as string;
      const sjisArray = str2Array(inputFile);
      const fileTextCode = encoding.detect(sjisArray) as encoding.Encoding;
      const uniArray = convertCharCodeToUnicode(sjisArray, fileTextCode);
      const result = encoding.codeToString(uniArray!);

      const res = await csvApi.bulkUpdateByCsvFile({
        csvData: mapCSVToArray(result),
        organizationId: currentUser.account?.organizationId ?? "",
      });

      if (res.status === "success") {
        toast.success("名簿の更新しました");
        setIsOpenListBulkImportModal(false);
        // TODO: BulkInmportは使われていなので修正を入れていません
        setTimeout(() => location.reload(), 1000);
      }
      if (res.status === "failed") setIsBulkUpdateFailed(true);
    };
  };

  return (
    <>
      {isOpenListBulkImportModal && (
        <ModalWrapper>
          <ModalBox>
            <CloseButton
              onClick={() => {
                setIsOpenListBulkImportModal(false);
                setIsBulkUpdateFailed(false);
              }}
            />
            <ModalHeader>名簿一括更新（インポート）</ModalHeader>
            <ModalBody>
              フォーマットに従ったファイルを入れていただけると自動で入力することができます。
              <br />
              インポートをする際の元ファイルは
              <DownloadCsvFileLink onClick={() => downloadCsvFile()}>
                こちら
              </DownloadCsvFileLink>
              からダウンロードすることができます。
              <br />
              （注意）名簿一括更新で変更できるものは、PTA所属、PTA役職、登校班名、子供の学年、クラスのみです。
              <br />
              <ModalIconContainer>
                <FileCharacterIcon />
                <p>
                  正しいデータで読み込まれたようです。
                  <br />
                  更新する場合は以下のボタンを押してください。
                </p>
              </ModalIconContainer>
            </ModalBody>
            <ButtonsContainer isBulkUpdateError={isBulkUpdateFailed}>
              <CancelButton
                type="button"
                onClick={() => {
                  setIsOpenListBulkImportModal(false);
                  setIsBulkUpdateFailed(false);
                }}
              >
                キャンセル
              </CancelButton>
              <input
                hidden
                type="file"
                accept=".csv"
                ref={fileImportButtonRef}
                onChange={onFileImportChange}
              />
              <BulkImportButton type="button" onClick={importListCsvFile}>
                名簿を一括で更新する
              </BulkImportButton>
            </ButtonsContainer>

            {isBulkUpdateFailed && (
              <BulkUpdateErrorMessage>
                <BulkUpdateFailedTitle>
                  <div>
                    <DangerIcon size={32} />
                  </div>
                  名簿の一括更新ができませんでした
                </BulkUpdateFailedTitle>
                <BulkUpdateFailedDescription>
                  新しいユーザーを名簿の一括更新では追加することはできません、追加でユーザーを追加したい場合は新規登録をお願いしてください。
                </BulkUpdateFailedDescription>
              </BulkUpdateErrorMessage>
            )}
          </ModalBox>
        </ModalWrapper>
      )}
    </>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  box-sizing: border-box;
  overflow: scroll;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: ${zIndexes.modal};
  opacity: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalBox = styled.div`
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  background-color: white;
  text-align: left;
  padding: 24px;
  width: 80%;
  margin: 16px;
  margin-top: 50px;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 32px 64px -16px rgb(0 16 14 / 31%);
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #343741;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%343741" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>')
    no-repeat center center;
  width: 24px;
  height: 24px;
  margin-top: 10px;
  margin-right: 10px;
`;

const ModalIconContainer = styled.div`
  text-align: center;
`;

const DownloadCsvFileLink = styled.a`
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`;

const CancelButton = styled.button`
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 9.5px;
  color: #005ec4;
  cursor: pointer;
`;

const BulkImportButton = styled.button`
  border: none;
  font-size: 14px;
  font-weight: 500;
  background: #0071c2;
  border-radius: 6px;
  padding: 12px 9.5px;
  color: white;
  cursor: pointer;
`;

const ButtonsContainer = styled.div<{ isBulkUpdateError: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 11px;
  margin-bottom: ${(props) => props.isBulkUpdateError && "120px"};
`;

const BulkUpdateErrorMessage = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f8e9e9;
  margin-top: 30px;
  padding: 16px;
`;

const BulkUpdateFailedTitle = styled.p`
  color: #bd271e;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-left: -12px;
`;

const BulkUpdateFailedDescription = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #343741;
`;
