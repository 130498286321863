import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { ShapeIcon } from "../../components/icons/ShapeIcon";
import { TrashIcon } from "../../components/icons/TrashIcon";
import { Menu } from "../../components/Common/Menu";
import { LinkModal } from "./LinkModal";

type Props = {
  title: string;
  url: string;
  description: string | null;
  isAdmin?: boolean;
  id?: string | null;
  refetch?: () => void;
};

export const LinkBar = ({
  title,
  url,
  description,
  isAdmin = false,
  id = null,
  refetch = () => {},
}: Props) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenUpdateLinkModal, setIsOpenUpdateLinkModal] = useState(false);
  const [isOpenDeleteLinkModal, setIsOpenDeleteLinkModal] = useState(false);
  const menuList = [
    {
      label: "リンクを編集",
      onClick: () => {
        setIsOpenUpdateLinkModal(true);
      },
      icon: <ShapeIcon />,
    },
    {
      label: "リンクを削除",
      onClick: () => {
        setIsOpenDeleteLinkModal(true);
      },
      color: "#BD271E",
      icon: <TrashIcon />,
    },
  ];

  const handleEditClose = useCallback(() => {
    setIsOpenUpdateLinkModal(false);
    refetch();
  }, [refetch]);

  const handleDeleteClose = useCallback(() => {
    setIsOpenDeleteLinkModal(false);
    refetch();
  }, [refetch]);

  return (
    <>
      <StyledLinkBarWrapper>
        <StyledAnchor isAdmin={isAdmin} href={url} target="_blank">
          <StyledLinkTitle>{title}</StyledLinkTitle>
          <StyledLinkDescription>{description || ""}</StyledLinkDescription>
        </StyledAnchor>

        <Menu
          isDisplay={isAdmin}
          menuList={menuList}
          isOpenMenu={isOpenMenu}
          toggleMenu={setIsOpenMenu}
        />
      </StyledLinkBarWrapper>

      <LinkModal
        isOpen={isOpenUpdateLinkModal}
        onClose={handleEditClose}
        closeMenu={() => setIsOpenMenu(false)}
        type="update"
        link={{ id, title, url, description }}
      />

      <LinkModal
        isOpen={isOpenDeleteLinkModal}
        onClose={handleDeleteClose}
        closeMenu={() => setIsOpenMenu(false)}
        type="delete"
        link={{ id, title, url, description }}
      />
    </>
  );
};

const StyledLinkBarWrapper = styled.div`
  padding: 12px 24px;
  border-radius: 6px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;

  &:hover {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1),
      0px 3.6px 13px rgba(0, 0, 0, 0.07), 0px 8.4px 23px rgba(0, 0, 0, 0.06),
      0px 23px 35px rgba(0, 0, 0, 0.05);
  }
`;

const StyledAnchor = styled.a<{ isAdmin: boolean }>`
  width: ${(props) => (props.isAdmin ? "90%" : "100%")};

  @media (max-width: 640px) {
    width: 85%;
  }

  @media (max-width: 400px) {
    width: 80%;
  }
`;

const StyledLinkText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledLinkTitle = styled(StyledLinkText)`
  white-space: nowrap;
  color: #343741;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
`;

const StyledLinkDescription = styled(StyledLinkText)`
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* 表示する行数を制限 */
  -webkit-box-orient: vertical;
  color: #69707d;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  @media (max-width: 600px) {
    -webkit-line-clamp: 7; /* 表示する行数を制限 */
  }
`;
