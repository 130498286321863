import React, { useMemo, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  ModalWrapper,
  ModalHeader,
  EditModalFormLabel,
  CheckTabs,
  TextField,
  SelectField,
  ErrorMessage,
  StyledFlexSpaceBetween,
} from "../../../pages/pta/admin/ProfilePage";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../../components/Modal";
import {
  TrashButton,
  TrashIcon,
  AddChildForm,
} from "../../../pages/registration/pta/RegisterChildInfoPage";
import { Margin } from "../../../components/Margin";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { useToast } from "../../../components/Toast";
import { useGetChildren } from "../../../hooks/api/child";
import { Child, upsertAndDeleteChildren } from "../../../apiClients/child";
import type { BaseInfo } from "../../../hooks/recoil/user";
import { classOption } from "../../../utils/getChildClass";
import { useBaseInfo } from "../../../hooks/api/baseInfo";
import { useInvalidateGetChildrenByCondition } from "src/hooks/query/childrenList";

type FormValues = {
  baseInfo: BaseInfo;
  children: Child[];
};

const ChildInfoEditModal = ({
  isOpen,
  userChildren,
  close,
}: {
  isOpen: boolean;
  userChildren: Child[];
  close: () => void;
}) => {
  const user = useCurrentUser();
  const { currentBaseInfo } = useBaseInfo();
  const { getChildren } = useGetChildren(user.id);
  const toast = useToast();
  const { invalidateGetChildrenByCondition } =
    useInvalidateGetChildrenByCondition();

  const defaultValues = useMemo(() => {
    return {
      children: userChildren,
    };
  }, [userChildren]);

  const {
    register,
    control,
    reset,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues,
    mode: "onBlur",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const { fields, append, remove } = useFieldArray({
    name: "children",
    control,
  });

  const newChildInfo = useMemo(() => {
    return {
      childLastName: currentBaseInfo.lastName,
      childFirstName: "",
      childLastNameKana: currentBaseInfo.lastNameKana,
      childFirstNameKana: "",
      grade: undefined,
      class: undefined,
    };
  }, [currentBaseInfo]);

  const onSubmitChildInfo = async (): Promise<void> => {
    try {
      const children = getValues("children");
      const childrenWithUserId = children.map((child) => {
        return {
          childFirstName: child.childFirstName,
          childFirstNameKana: child.childFirstNameKana,
          childLastName: child.childLastName,
          childLastNameKana: child.childLastNameKana,
          class: child.class,
          grade: child.grade,
          userId: user.id,
          id: child.id,
        };
      });

      await upsertAndDeleteChildren(user.id, childrenWithUserId);
      await getChildren();
      invalidateGetChildrenByCondition();

      close();
      toast.success("保存しました");
    } catch (err) {
      console.error(err);
    }
  };

  const gradeOption = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
    { value: "6", text: "6" },
  ];

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={close} modalBoxIsHeightFull={true}>
          <ModalWrapper>
            <ModalHeader>お子様情報</ModalHeader>
            <ModalBody>
              {fields.map((_field, index) => (
                <>
                  <StyledFlexSpaceBetween>
                    <EditModalFormLabel>
                      お子さまのお名前({index + 1}人目)
                    </EditModalFormLabel>

                    {fields.length > 1 && (
                      <TrashButton
                        style={{ marginTop: "12px", marginRight: "5px" }}
                        type="button"
                        onClick={() => remove(index)}
                      >
                        <TrashIcon />
                      </TrashButton>
                    )}
                  </StyledFlexSpaceBetween>

                  <CheckTabs>
                    <div>
                      <TextField
                        placeholder="例) 山田"
                        {...register(
                          `children.${index}.childLastName` as const,
                          {
                            required: "入力必須項目です",
                          }
                        )}
                      />
                      {errors?.children &&
                        errors.children[index]?.childLastName && (
                          <ErrorMessage>
                            {/* @ts-ignore */}
                            {errors.children[index].childLastName?.message}
                          </ErrorMessage>
                        )}
                    </div>
                    <div>
                      <TextField
                        placeholder="例) 太郎"
                        {...register(
                          `children.${index}.childFirstName` as const,
                          {
                            required: "入力必須項目です",
                          }
                        )}
                      />
                      {errors?.children &&
                        errors.children[index]?.childFirstName && (
                          <ErrorMessage>
                            {/* @ts-ignore */}
                            {errors.children[index].childFirstName?.message}
                          </ErrorMessage>
                        )}
                    </div>
                  </CheckTabs>
                  <EditModalFormLabel>ふりがな</EditModalFormLabel>
                  <CheckTabs>
                    <div>
                      <TextField
                        placeholder="例) やまだ"
                        {...register(
                          `children.${index}.childLastNameKana` as const,
                          {
                            required: "入力必須項目です",
                            pattern: {
                              value: /^[ぁ-んーー]+$/u,
                              message: "ひらがなのみで入力してください",
                            },
                          }
                        )}
                      />
                      {errors?.children &&
                        errors.children[index]?.childLastNameKana && (
                          <ErrorMessage>
                            {/* @ts-ignore */}
                            {errors.children[index].childLastNameKana?.message}
                          </ErrorMessage>
                        )}
                    </div>
                    <div>
                      <TextField
                        placeholder="例) たろう"
                        {...register(
                          `children.${index}.childFirstNameKana` as const,
                          {
                            required: "入力必須項目です",
                            pattern: {
                              value: /^[ぁ-んーー]+$/u,
                              message: "ひらがなのみで入力してください",
                            },
                          }
                        )}
                      />
                      {errors?.children &&
                        errors.children[index]?.childFirstNameKana && (
                          <ErrorMessage>
                            {/* @ts-ignore */}
                            {errors.children[index].childFirstNameKana?.message}
                          </ErrorMessage>
                        )}
                    </div>
                  </CheckTabs>
                  <CheckTabs>
                    <div>
                      <EditModalFormLabel>学年</EditModalFormLabel>
                      <SelectField
                        {...register(`children.${index}.grade` as const, {
                          required: "入力必須項目です",
                          setValueAs: (v) =>
                            v === "" ? undefined : parseInt(v, 10),
                        })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          選択してください
                        </option>
                        {gradeOption.map((opt) => {
                          return (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          );
                        })}
                      </SelectField>
                      {errors?.children && errors.children[index]?.grade && (
                        <ErrorMessage>
                          {/* @ts-ignore */}
                          {errors.children[index].grade?.message}
                        </ErrorMessage>
                      )}
                    </div>
                    <div>
                      <EditModalFormLabel>クラス</EditModalFormLabel>
                      <SelectField
                        {...register(`children.${index}.class` as const, {
                          required: "入力必須項目です",
                          setValueAs: (v) =>
                            v === "" ? undefined : parseInt(v, 10),
                        })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          選択してください
                        </option>
                        {classOption.map((opt) => {
                          return (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          );
                        })}
                      </SelectField>
                      {errors?.children && errors.children[index]?.class && (
                        <ErrorMessage>
                          {/* @ts-ignore */}
                          {errors.children[index].class?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </CheckTabs>
                </>
              ))}

              <Margin marginTop={20} />
              <AddChildForm
                type="button"
                onClick={() => {
                  append(newChildInfo);
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "5px" }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 7H11.5C11.7761 7 12 7.22386 12 7.5C12 7.77614 11.7761 8 11.5 8H8V11.5C8 11.7761 7.77614 12 7.5 12C7.22386 12 7 11.7761 7 11.5V8H3.5C3.22386 8 3 7.77614 3 7.5C3 7.22386 3.22386 7 3.5 7H7V3.5C7 3.22386 7.22386 3 7.5 3C7.77614 3 8 3.22386 8 3.5V7ZM7.5 0C11.6356 0 15 3.36441 15 7.5C15 11.6356 11.6356 15 7.5 15C3.36441 15 0 11.6356 0 7.5C0 3.36441 3.36441 0 7.5 0ZM7.5 0.882353C3.84529 0.882353 0.882353 3.84529 0.882353 7.5C0.882353 11.1547 3.84529 14.1176 7.5 14.1176C11.1547 14.1176 14.1176 11.1547 14.1176 7.5C14.1176 3.84529 11.1547 0.882353 7.5 0.882353Z"
                    fill="#5A606B"
                  />
                </svg>
                お子さまを追加
              </AddChildForm>
              <Margin marginTop={25} />
              <ModalSubmitButtons
                submitText="保存"
                onCancel={close}
                onSubmit={() => handleSubmit(onSubmitChildInfo)()}
              />
            </ModalBody>
          </ModalWrapper>
        </ModalPortal>
      )}
    </>
  );
};

export default ChildInfoEditModal;
