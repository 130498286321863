import React from "react";

export const SignatureIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 20H19V4H5L5 20ZM3 21C3 21.5523 3.44772 22 4 22H20C20.5523 22 21 21.5523 21 21V3C21 2.44772 20.5523 2 20 2H4C3.44772 2 3 2.44772 3 3L3 21Z"
        fill="#37434F"
      />
      <rect x="7" y="7" width="10" height="2" rx="1" fill="#37434F" />
      <rect x="7" y="11" width="10" height="2" rx="1" fill="#37434F" />
      <rect x="7" y="15" width="10" height="2" rx="1" fill="#37434F" />
    </svg>
  );
};
