import React, { useEffect, useState } from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "../../components/Modal";
import { Label } from "../../components/form/Label";
import { Margin } from "../../components/Margin";
import styled from "styled-components";
import {
  Member,
  SearchMembersField,
} from "../../components/SearchMembersField";
import { createPostUserGroup } from "../../hooks/api/postUserGroup";
import { useToast } from "../../components/Toast";
import { usePostUserGroupList } from "src/hooks/recoil/postUserGroup";
import { logger } from "src/utils/logger";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  members: Member[];
  getPostGroups: () => Promise<void>;
};

export const AddGroupModal = ({
  isOpen,
  onClose,
  members,
  getPostGroups,
}: Props) => {
  if (!isOpen) return null;
  const [groupName, setGroupName] = useState("");
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const toast = useToast();
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);

  const onSubmit = async () => {
    setDoubleClickBlocked(true);
    try {
      await createPostUserGroup({
        name: groupName,
        accountIds: selectedMemberIds,
      });
      await getPostGroups();
      toast.success("連絡グループを作成しました");
    } catch (error) {
      logger.error(error);
      toast.error("連絡グループの作成に失敗しました");
    } finally {
      setDoubleClickBlocked(false);
      onClose();
    }
  };
  const { postGroups } = usePostUserGroupList();
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (postGroups.some((group) => group.name === groupName)) {
      setError("この連絡グループ名は既に登録されています");
    } else {
      setError("");
    }
  }, [groupName]);

  return (
    <ModalPortal
      onClose={() => {
        onClose();
      }}
    >
      <ModalHeader>連絡グループ作成</ModalHeader>
      <ModalBody>
        <p>選択されたメンバーで連絡グループが作成できます。</p>
        <Margin marginTop={20} />
        <Label>グループ名を入力</Label>
        <TextField
          placeholder="例) 2年3組バレー"
          type="text"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
        <ErrorMsg>{error}</ErrorMsg>
        <Margin marginTop={20} />
        <Label>メンバーを追加</Label>
        <SearchMembersField
          members={members}
          selectedMemberIds={selectedMemberIds}
          setSelectedMemberIds={setSelectedMemberIds}
        />
        <Margin marginTop={20} />
        <ModalSubmitButtons
          submitText="作成"
          disabled={
            groupName === "" ||
            selectedMemberIds.length === 0 ||
            error !== "" ||
            doubleClickBlocked
          }
          onSubmit={() => {
            onSubmit();
          }}
          onCancel={() => {
            onClose();
          }}
        />
      </ModalBody>
    </ModalPortal>
  );
};

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const ErrorMsg = styled.div`
  font-size: 14px;
  color: #bd271e;
`;
