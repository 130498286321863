import { RecruitmentFilter } from "@shared/types/recruitment";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const recruitmentFilterState = atom<RecruitmentFilter>({
  key: "RecruitmentFilter",
  default: {
    volunteerType: "",
    isPaidVolunteer: undefined,
    date: "",
    isOpen: false,
  },
  effects_UNSTABLE: [persistAtom],
});

export const useRecruitmentFilter = () => {
  const [recruitmentFilter, setRescruitmentFilter] = useRecoilState(
    recruitmentFilterState
  );

  return [recruitmentFilter, setRescruitmentFilter] as const;
};
