import React from "react";
import styled from "styled-components";
import type {
  ChatRoom,
  IndividualChatWithOrganization,
} from "@shared/types/chatRoom";
import * as chatRoomApi from "../../../../apiClients/chatRoom";
import {
  isCsAdminRole,
  isCsMemberRole as isCsMember,
} from "../../../../utils/types/role";
import { useCurrentUser } from "../../../../hooks/recoil/user";
import { Button } from "../../../../components/Button3";
import { DeleteChatRoomModal } from "./DeleteChatRoomModal";
import { useModal } from "../../../../components/Modal";
import { usePolyfitHistory } from "../../../../hooks/router";
import { ChatInputForm } from "src/components/form/ChatInputForm";

type IndividualContentChatMessageInputAreaProps = {
  input: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  canSend: boolean;
  onSend: () => void;
  chatRoom: ChatRoom;
  chatRoomParticipantId: string;
  individualChatWithOrganization: IndividualChatWithOrganization;
};

export const IndividualContentChatMessageInputArea = (
  props: IndividualContentChatMessageInputAreaProps
) => {
  const user = useCurrentUser();
  const isCsMemberRole = isCsMember(user.role);
  const isCsAdmin = isCsAdminRole(user.role);
  const [isOpen, { show, close }] = useModal();
  const history = usePolyfitHistory();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleApprove = async () => {
    setIsSubmitting(true);
    await chatRoomApi.approveChatRoom(props.chatRoom.id);
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    await chatRoomApi.rejectChatRoom(props.chatRoom.id);
    setIsSubmitting(false);
    close();
    history.push({ to: "RESIDENT_CHAT", query: {} });
  };

  const handleDeleteModal = async () => {
    show();
  };

  return (
    <>
      {isCsAdmin && props.chatRoom.approvalStatus === "PENDING" ? (
        <RequestActionContainer>
          <Description>
            <Link
              href={`/pta/list/member?userId=${props.individualChatWithOrganization.account.userId}`}
              target="_blank"
              rel="noreferrer"
            >
              {props.individualChatWithOrganization.account.name}
            </Link>
            さんからのメッセージリクエストを許可しますか?
          </Description>
          <ActionButtonContainer>
            <ActionDeleteButton
              empty={true}
              height="40px"
              onClick={handleDeleteModal}
              disabled={isSubmitting}
            >
              削除
            </ActionDeleteButton>
            <Button
              fill={true}
              height="40px"
              width="112px"
              onClick={handleApprove}
              disabled={isSubmitting}
            >
              許可
            </Button>
          </ActionButtonContainer>
        </RequestActionContainer>
      ) : (
        <ChatMessageInputContainer>
          <ChatMessageInputArea>
            <ChatInputForm
              placeholder="メッセージを入力"
              setValue={props.setInput}
              setFiles={props.setFiles}
              canSend={props.canSend}
              onSend={props.onSend}
            />
          </ChatMessageInputArea>
          {isCsMemberRole &&
            (props.chatRoom.approvalStatus === "UNAPPROVED" ||
              props.chatRoom.approvalStatus === "PENDING") && (
              <PendingMessageText>
                ※ チャット申請し、承認されると返信が届きます。
              </PendingMessageText>
            )}
        </ChatMessageInputContainer>
      )}
      <DeleteChatRoomModal
        isOpen={isOpen}
        onClose={close}
        onSubmit={handleDelete}
        targetUserName={props.individualChatWithOrganization.account.name}
      />
    </>
  );
};

const ChatMessageInputContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChatMessageInputArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const RequestActionContainer = styled(ChatMessageInputContainer)`
  flex-direction: column;
`;

const Description = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.05em;
  text-align: left;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding: 24px;
`;

const ActionDeleteButton = styled(Button)`
  margin-right: 16px;
  color: #b4251d;
`;

const Link = styled.a`
  color: #0077cc;
`;

const PendingMessageText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 17.76px;
  text-align: left;
  width: 100%;
  color: #69707d;
`;
