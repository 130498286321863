import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import { formatDateTimeToTime } from "src/features/common/ChatMessage/utils/formatRecruitmentSchedule";
import type { UserMessageItem } from "src/features/common/ChatMessage/types";
import { ChatMessageAvatar } from "./ChatMessageAvator";
import { ChatMessageFileContainer } from "./ChatMessageFileContainer";
import { useCurrentCommunityId } from "src/hooks/router";

type Props = {
  message: UserMessageItem;
  participantId: string;
  participantAdminId?: string;
  lastReadAt?: Date;
  lastReadAts?: Date[];
  isGroupChat?: boolean;
};

export const ChatMessage = ({ isGroupChat = false, ...props }: Props) => {
  const message = props.message.value;
  const isMyMessage = message.participantId === props.participantId;
  const { communityId } = useCurrentCommunityId();
  const adminId = props.participantAdminId ?? communityId;
  const isAdminMessage = message.participantId === adminId;

  const renderChatMessageContent = useCallback(() => {
    switch (message.type) {
      case "PLAIN":
        return (
          <MessageContent isMyMessage={isMyMessage}>
            {message.content}
            <ChatMessageFileContainer
              files={message.files}
            ></ChatMessageFileContainer>
          </MessageContent>
        );
      case "WITH_HEADER":
        return (
          <MessageWithHeader>
            <MessageHeader
              isMyMessage={isMyMessage}
              backgroundColor={message.headerBackgroundColor}
            >
              {message.headerText}
            </MessageHeader>
            <MessageContent isMyMessage={isMyMessage} withHeader={true}>
              {message.content}
            </MessageContent>
          </MessageWithHeader>
        );
    }
  }, [message, props.lastReadAt, props.lastReadAts]);

  const renderChatMessage = useCallback(() => {
    const alreadyReadStatement: string | null = (() => {
      // 個別チャット
      if (props.lastReadAt) {
        return new Date(message.createdAt) < new Date(props.lastReadAt)
          ? "既読"
          : null;
        // グループメッセージ
      } else if (props.lastReadAts) {
        const alreadyReadCount = props.lastReadAts.filter(
          (lastReadAt) => new Date(message.createdAt) < new Date(lastReadAt)
        ).length;
        return alreadyReadCount > 0 ? `既読: ${alreadyReadCount}` : null;
      } else {
        return null;
      }
    })();
    return (
      <>
        <ChatMessageContainer key={message.id} isMyMessage={isMyMessage}>
          <ChatMessageAvatar message={message} isAdmin={isAdminMessage} />
          <ChatMessageNameAndMessages>
            {isGroupChat && (
              <ChatMessageUserName isMyMessage={isMyMessage}>
                {isAdminMessage
                  ? message.user.name + "(管理者)"
                  : message.user.name}
              </ChatMessageUserName>
            )}
            <ChatMessageContentAndInfo isMyMessage={isMyMessage}>
              {renderChatMessageContent()}
              <MessageInfo isMyMessage={isMyMessage}>
                {isMyMessage && alreadyReadStatement && (
                  <div>{alreadyReadStatement}</div>
                )}
                {formatDateTimeToTime(message.createdAt)}
              </MessageInfo>
            </ChatMessageContentAndInfo>
          </ChatMessageNameAndMessages>
        </ChatMessageContainer>
      </>
    );
  }, [message, props.lastReadAt, props.lastReadAts]);

  return <>{renderChatMessage()}</>;
};

const ChatMessageContainer = styled.div<{ isMyMessage: boolean }>`
  width: 100%;
  display: flex;
  align-items: flex-top;
  ${(props) =>
    props.isMyMessage
      ? css`
          flex-flow: row-reverse;
        `
      : css``}
`;

const MessageInfo = styled.div<{ isMyMessage: boolean }>`
  color: #868686;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 10px;
  font-weight: 500;
  ${(props) =>
    props.isMyMessage
      ? css`
          text-align: end;
          padding-right: 8px;
        `
      : css`
          text-align: start;
          padding-left: 8px;
        `}
`;

const MessageContent = styled.div<{
  isMyMessage: boolean;
  withHeader?: boolean;
}>`
  max-width: ${(props) =>
    props.withHeader ? undefined : "calc(100% - 100px)"};
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 4px 12px;
  display: flex;
  flex-direction: column;
  flex-gap: 4px;
  ${(props) =>
    props.isMyMessage
      ? css`
          color: #ffffff;
          background: #0077cc;
          border-radius: ${
            props.withHeader ? "0px 0px 0px 12px" : "12px 12px 12px 12px"
          };
          margin-right: 10px;
          position: relative;
          &::after {
            content: "";
            border: 12px solid transparent;
            border-top-color: #0077cc;
            position: absolute;
            right: -10px;
            z-index: 1;
            top: 10px;
             border-top-color: #0077cc;
             display: ${props.withHeader ? "none" : "block"};
          }
          }
        `
      : css`
          color: #313131;
          background: #f5f7fa;
          border-radius: ${props.withHeader
            ? "0px 0px 12px 0px"
            : "12px 12px 12px 12px"};
          margin-left: 10px;
          position: relative;
          &::after {
            content: "";
            border: 12px solid transparent;
            position: absolute;
            left: -10px;
            z-index: 1;
            top: 8px;
            border-top-color: #f5f7fa;
            display: ${props.withHeader ? "none" : "block"};
          }
        `}
`;

const MessageHeader = styled.div<{
  isMyMessage: boolean;
  backgroundColor: string;
}>`
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0px;
  padding: 0px 16px;
  text-align: center;
  color: #ffffff;
  background: ${(props) => props.backgroundColor};
  border-radius: 24px 24px 0px 0px;
  white-space: pre-wrap;
  word-wrap: break-word;
  height: 40px;
  margin-right: ${(props) => (props.isMyMessage ? "8px" : "0")};
  margin-left: ${(props) => (props.isMyMessage ? "0" : "8px")};
`;

const MessageWithHeader = styled.div`
  display: grid;
`;
const ChatMessageUserName = styled.div<{ isMyMessage: boolean }>`
  display: flex;
  ${(props) =>
    props.isMyMessage
      ? css`
          flex-flow: row-reverse;
          margin-right: 4px;
        `
      : css`
          margin-left: 4px;
        `}
  font-size: 8px;
  color: #868686;
  font-weight: bold;
`;

//レイアウト調整用のdiv tag
const ChatMessageNameAndMessages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const ChatMessageContentAndInfo = styled.div<{ isMyMessage: boolean }>`
  display: flex;
  ${(props) =>
    props.isMyMessage
      ? css`
          flex-flow: row-reverse;
        `
      : css``}
`;
