import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useCurrentUser } from "../../hooks/recoil/user";
import { PageContainer } from "../../components/Page";
import { useModal } from "../../components/Modal";
import { Heading3 } from "../../components/Typo";
import * as Responsive from "../../components/Responsive";
import { DisabledOrganizationPanel } from "./panels/DisabledOrganizationPanel";
import {
  useLatestPostByOrganizationId,
  useMinePostList,
  useUnApprovedPostList,
} from "../../hooks/api/post";
import { usePolyfitHistory } from "../../hooks/router";
import { Margin } from "../../components/Margin";
import { DangerIcon } from "../../components/icons/DangerIcon";
import { CreatePostModal } from "../../features/Post/CreatePostModal";
import { PostCard } from "../../features/Post/PostCard";
import { Panel } from "../../components/Panel";
import { Spinner } from "../../components/icons/Spinner";
import { useOrganization } from "../../hooks/recoil/organization";
import { usePostUserGroupList } from "../../hooks/recoil/postUserGroup";
import { TargetGradesType } from "@shared/types/post/api";

const IndexPageContainer = styled(PageContainer)``;

export default function PTAPersonalPage() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isShown, { show, close }] = useModal();
  const user = useCurrentUser();
  const { organization } = useOrganization({});
  const { getPostUserGroupLabel } = usePostUserGroupList();
  const { post, isLoading: isLatestPostLoading } =
    useLatestPostByOrganizationId();
  const [isNewModal, setIsNewModal] = useState(false);
  const { posts: unApprovedPost, getPosts } = useUnApprovedPostList(user);
  const myUnApprovedPost = unApprovedPost.filter((post) =>
    post.postApprovals.every((approval) => approval.userId !== user.id)
  );
  const { posts: myPosts } = useMinePostList();
  const myRemandedPosts = myPosts.filter((item) => {
    return item.status === "REMANDED"; // 差し戻しだけに絞る
  });
  const history = usePolyfitHistory();
  const [openPostId, setOpenPostId] = useState("");

  const getPostBelongLabel = useCallback(
    (target: TargetGradesType | string) => {
      return (
        organization?.belongs?.find((belong) => belong.id === target)?.name ??
        "削除済み"
      );
    },
    [organization]
  );

  return (
    <IndexPageContainer>
      <Responsive.Row>
        {myUnApprovedPost.length > 0 && (
          <Responsive.Col>
            <UnApprovalContent>
              <UnApprovalPopUp>
                <CausionsBlock>
                  <UnApprovalConfirm>
                    <DangerIcon size={35} />
                    未承認が{myUnApprovedPost.length}件あります
                  </UnApprovalConfirm>
                  <RequireConfirmText>
                    確認して承認してください
                  </RequireConfirmText>
                </CausionsBlock>

                <ConfirmButton
                  onClick={() => history.push({ to: "POST_UNAPPROVED_PAGE" })}
                >
                  確認する
                </ConfirmButton>
              </UnApprovalPopUp>
            </UnApprovalContent>
            <Margin marginBottom={12} />
          </Responsive.Col>
        )}
        {myRemandedPosts.length > 0 && (
          <Responsive.Col>
            <UnApprovalContent>
              <UnApprovalPopUp>
                <CausionsBlock>
                  <UnApprovalConfirm>
                    <DangerIcon size={35} />
                    あなたの連絡に差戻しが来ています
                  </UnApprovalConfirm>
                  <RequireConfirmText>確認してください</RequireConfirmText>
                </CausionsBlock>
                <ConfirmButton
                  onClick={() => history.push({ to: "POST_ME_PAGE" })}
                >
                  確認する
                </ConfirmButton>
              </UnApprovalPopUp>
            </UnApprovalContent>
            <Margin marginBottom={12} />
          </Responsive.Col>
        )}
        <Responsive.Col>
          <Heading3>
            <UnApprovalPopUp>
              連絡
              <NewPostAndPostList>
                <TextButton
                  onClick={() => {
                    setIsNewModal(!isNewModal);
                    show();
                  }}
                >
                  新規作成
                </TextButton>
                {isNewModal && (
                  <CreatePostModal
                    onClose={() => {
                      setIsNewModal(!isNewModal);
                      close();
                    }}
                    refetch={getPosts}
                  />
                )}
                <VerticalLine />
                <TextButton onClick={() => history.push({ to: "POST_PAGE" })}>
                  連絡一覧
                </TextButton>
              </NewPostAndPostList>
            </UnApprovalPopUp>
          </Heading3>
        </Responsive.Col>
      </Responsive.Row>
      <Margin marginTop={8} />
      <Responsive.Row>
        <Responsive.Col>
          <Margin marginTop={10} />
          {post && (
            <Panel
              onClick={() => {
                if (openPostId !== post.id) {
                  setOpenPostId(post.id);
                } else {
                  setOpenPostId("");
                }
              }}
            >
              <PostCard
                post={post}
                isOpen={openPostId == post.id}
                type="default"
                getPostBelongLabel={getPostBelongLabel}
                getPostUserGroupLabel={getPostUserGroupLabel}
                refetch={getPosts}
                borderBottom={false}
              />
            </Panel>
          )}
          {/* 読み込み中 */}
          {isLatestPostLoading && (
            <Card>
              <Spinner />
            </Card>
          )}
          {!isLatestPostLoading && post == undefined && (
            <NonPostElement
              title="連絡がありません"
              message="新規作成から作成ができます"
            />
          )}
          <Margin marginBottom={30} />
        </Responsive.Col>
        <Responsive.Col>
          <DisabledOrganizationPanel />
        </Responsive.Col>
      </Responsive.Row>
    </IndexPageContainer>
  );
}

const NewPostAndPostList = styled.span`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
const TextButton = styled.button`
  color: #005ec4;
  font-size: 16px;
  font-weight: normal;
  border-width: 0;
  background-color: inherit;
  cursor: pointer;
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 18px;
  background-color: #d3dae6;
  margin: 0 16px;
`;

// FIXME: do not use "export const" with styled-components
export const Label = styled.div`
  width: 72px;
  background: #6dccb1;
  border-radius: 6px;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: white;
`;

// FIXME: do not use "export const" with styled-components
export const RemandLabel = styled(Label)`
  background: #ff7e62;
`;

const CausionsBlock = styled.div`
  @media (max-width: 460px) {
    width: 100%;
    text-align: center;
  }
`;

const UnApprovalPopUp = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 460px) {
    flex-direction: column;
  }
`;

const UnApprovalConfirm = styled.span`
  color: #8a6a0a;
  display: flex;
  align-items: center;
  font-size: 16px;

  @media (max-width: 500px) {
    font-size: 13px;
  }

  @media (max-width: 460px) {
    justify-content: center;
  }
`;

const RequireConfirmText = styled.p`
  font-size: 16px;
  padding-left: 10px;

  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

const ConfirmButton = styled.button`
  margin-left: auto;
  min-height: 32px;
  min-width: 108px;
  border: none;
  border-radius: 5px;
  background-color: #fec514;
  cursor: pointer;
  margin-top: 10px;

  @media (max-width: 500px) {
    font-size: 12px;
  }

  @media (max-width: 460px) {
    width: 80%;
    margin: 10px auto;
  }
`;
const UnApprovalContent = styled.div`
  width: 100%;
  background-color: #fff9e8;
  padding: 16px;
`;

export const NonPostElement = ({
  title,
  message,
}: {
  title: string;
  message: string;
}) => {
  return (
    <Card>
      <svg
        width="240"
        height="240"
        viewBox="0 0 240 240"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="240" height="240" fill="white" />
        <path
          d="M182 200.5H58C57.1716 200.5 56.5 199.828 56.5 199V43C56.5 42.1716 57.1716 41.5 58 41.5H146.328C146.729 41.5 147.114 41.6607 147.395 41.9463L149.167 40.1976L147.395 41.9463L183.068 78.0879L184.847 76.3317L183.068 78.0879C183.345 78.3686 183.5 78.7471 183.5 79.1416V199C183.5 199.828 182.828 200.5 182 200.5Z"
          fill="#DEEFFF"
          stroke="#37434F"
          strokeWidth="5"
        />
        <rect x="84" y="81" width="49" height="8" rx="4" fill="#37434F" />
        <rect x="84" y="105" width="72" height="8" rx="4" fill="#37434F" />
        <rect x="84" y="129" width="72" height="8" rx="4" fill="#37434F" />
        <rect x="84" y="153" width="72" height="8" rx="4" fill="#37434F" />
        <path
          d="M40 194.132L42.5697 198.97C42.8588 199.514 43.3826 199.895 43.9896 200.002L49.3851 200.951L45.5778 204.89C45.1494 205.333 44.9493 205.949 45.0354 206.559L45.8003 211.983L40.8776 209.58C40.3238 209.309 39.6762 209.309 39.1224 209.58L34.1997 211.983L34.9646 206.559C35.0507 205.949 34.8506 205.333 34.4222 204.89L30.6149 200.951L36.0104 200.002C36.6174 199.895 37.1412 199.514 37.4303 198.97L40 194.132Z"
          fill="white"
          stroke="#37434F"
          strokeWidth="2"
        />
        <path
          d="M199 42.1319L202.49 48.7032C202.779 49.2475 203.303 49.6281 203.91 49.7349L211.238 51.0235L206.067 56.3735C205.639 56.8166 205.439 57.4325 205.525 58.0428L206.564 65.4105L199.878 62.1457C199.324 61.8752 198.676 61.8752 198.122 62.1457L191.436 65.4105L192.475 58.0428C192.561 57.4325 192.361 56.8166 191.933 56.3735L186.762 51.0235L194.09 49.7349C194.697 49.6281 195.221 49.2475 195.51 48.7032L199 42.1319Z"
          fill="white"
          stroke="#37434F"
          strokeWidth="2"
        />
        <path
          d="M178 76.5L148.5 46.5V74.5C148.5 75.6046 149.395 76.5 150.5 76.5H178Z"
          fill="white"
        />
        <path d="M154 81.5V86.5L181 82V81.5H154Z" fill="#8AC6FF" />
        <path d="M59 44H60.5L67.5 198H59V44Z" fill="white" />
        <path
          d="M146 44V74C146 76.7614 148.239 79 151 79H181"
          stroke="#37434F"
          strokeWidth="5"
        />
      </svg>
      <NonPostTitle>{title}</NonPostTitle>
      <NonPostMessage>{message}</NonPostMessage>
    </Card>
  );
};

const Card = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 0px 0.8px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 17px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NonPostTitle = styled.p`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const NonPostMessage = styled.p`
  font-size: 16px;
`;
