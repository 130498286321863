import React from "react";
import { FieldErrors, UseFormRegister, UseFormWatch } from "react-hook-form";
import styled from "styled-components";
import { LinkFormValue } from "../LinkModal";

type Props = {
  register: UseFormRegister<LinkFormValue>;
  watch: UseFormWatch<LinkFormValue>;
  errors: FieldErrors<LinkFormValue>;
};

export const LinkFormElements = ({ register, watch, errors }: Props) => {
  return (
    <LinkFormElementsWrapper>
      <div>
        <StyledLabel>
          リンクタイトル
          <RequiredLabel>*必須</RequiredLabel>
        </StyledLabel>
        <StyledInput
          type="text"
          value={watch("title")}
          placeholder="例）2023年度〇〇小学校PTAスケジュール"
          {...register("title", {
            required: "入力してください",
          })}
        />
        <ErrorMessage className="error-message">
          {errors?.title?.message ?? ""}
        </ErrorMessage>
      </div>

      <div>
        <StyledLabel>
          リンクURL
          <RequiredLabel>*必須</RequiredLabel>
        </StyledLabel>
        <StyledTextfield
          value={watch("url")}
          placeholder="例）https://example.com"
          {...register("url", {
            required: "入力してください",
          })}
        />
        <ErrorMessage className="error-message">
          {errors?.url?.message ?? ""}
        </ErrorMessage>
      </div>

      <div>
        <StyledLabel>リンク説明文（100文字以下推奨）</StyledLabel>
        <StyledTextfield
          value={watch("description") ?? ""}
          placeholder="例）2023年度１学期の八幡小学校PTAスケジュール一覧になります。"
          {...register("description", {
            required: false,
          })}
        />
        <ErrorMessage className="error-message">
          {errors?.description?.message ?? ""}
        </ErrorMessage>
      </div>
    </LinkFormElementsWrapper>
  );
};

const LinkFormElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const RequiredLabel = styled.span`
  display: inline-block;
  margin-left: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #bd271e;
`;

const StyledLabel = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #1a1c21;
`;

const StyledInput = styled.input`
  background: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-radius: 6px;
  display: block;
  width: 100%;
  padding: 12px;
`;

const StyledTextfield = styled.textarea`
  display: block;
  width: 100%;
  padding: 12px;
  background: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-radius: 6px;
`;

const ErrorMessage = styled.span`
  display: inline-block;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #bd271e;
`;
