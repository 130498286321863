import React from "react";
import { Spinner } from "src/components/icons/Spinner";
import styled from "styled-components";

export const LoadingCard = () => {
  return (
    <Container>
      <Spinner />
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
