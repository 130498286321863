import React from "react";

export const CarIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6133 1.34008C10.48 0.946748 10.1067 0.666748 9.66667 0.666748H2.33333C1.89333 0.666748 1.52667 0.946748 1.38667 1.34008L0 5.33341V10.6667C0 11.0334 0.3 11.3334 0.666667 11.3334H1.33333C1.7 11.3334 2 11.0334 2 10.6667V10.0001H10V10.6667C10 11.0334 10.3 11.3334 10.6667 11.3334H11.3333C11.7 11.3334 12 11.0334 12 10.6667V5.33341L10.6133 1.34008ZM2.56667 2.00008H9.42667L10.12 4.00008H1.87333L2.56667 2.00008ZM10.6667 8.66675H1.33333V5.56008L1.41333 5.33341H10.5933L10.6667 5.56008V8.66675Z"
        fill="white"
      />
      <path
        d="M3 8.00008C3.55228 8.00008 4 7.55236 4 7.00008C4 6.4478 3.55228 6.00008 3 6.00008C2.44772 6.00008 2 6.4478 2 7.00008C2 7.55236 2.44772 8.00008 3 8.00008Z"
        fill="white"
      />
      <path
        d="M9 8.00008C9.55229 8.00008 10 7.55236 10 7.00008C10 6.4478 9.55229 6.00008 9 6.00008C8.44772 6.00008 8 6.4478 8 7.00008C8 7.55236 8.44772 8.00008 9 8.00008Z"
        fill="white"
      />
    </svg>
  );
};
