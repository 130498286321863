import React from "react";

export const CalenderIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4V3.006C13 2.45 12.55 2 11.994 2H10V3H9V2H5V3H4V2H2.006C1.45 2 1 2.45 1 3.006V12.994C1 13.55 1.45 14 2.006 14H11.994C12.55 14 13 13.55 13 12.994V5H1V4H13ZM10 1H11.994C13.102 1 14 1.897 14 3.006V12.994C14.0001 13.2575 13.9483 13.5184 13.8476 13.7618C13.7468 14.0053 13.599 14.2264 13.4127 14.4127C13.2264 14.599 13.0053 14.7468 12.7618 14.8476C12.5184 14.9483 12.2575 15.0001 11.994 15H2.006C1.74253 15.0001 1.48162 14.9483 1.23818 14.8476C0.994743 14.7468 0.773551 14.599 0.587251 14.4127C0.40095 14.2264 0.253195 14.0053 0.15243 13.7618C0.0516658 13.5184 -0.000131189 13.2575 2.49524e-07 12.994V3.006C2.49524e-07 1.898 0.897 1 2.006 1H4V0H5V1H9V0H10V1ZM3 7H5V8H3V7ZM6 7H8V8H6V7ZM9 7H11V8H9V7ZM3 9H5V10H3V9ZM6 9H8V10H6V9ZM9 9H11V10H9V9ZM3 11H5V12H3V11ZM6 11H8V12H6V11ZM9 11H11V12H9V11Z"
        fill="#343741"
      />
    </svg>
  );
};
