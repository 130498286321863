import { useCallback, useEffect, useState } from "react";
import {
  RecruitmentApplication,
  getApplicationListByStatus,
  getMyApplicationByRecruitmentId,
  getApplicationListByUserId,
  countApplication,
  CountApplicationResponse,
} from "../../apiClients/recruitmentApplication";
import { AppliedRecruitment } from "@shared/types/appliedRecruitment";
import {
  ApplicationStatus,
  RecruitmentApplicationListOrder,
} from "@shared/types/recruitmentApplication";

export const useApplicantByRecruitmentId = (recruitmentId: string) => {
  const [applicant, setApplicant] = useState<RecruitmentApplication | null>(
    null
  );
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchRecruitment = async () => {
    setLoading(true);
    const recruitment = await getMyApplicationByRecruitmentId(recruitmentId);
    setApplicant(recruitment);
    setLoading(false);
  };

  useEffect(() => {
    fetchRecruitment();
  }, [recruitmentId]);

  return { applicant, isLoading, refetch: fetchRecruitment };
};

export const useApplicantList = (
  status: ApplicationStatus | "ALL",
  name: string,
  order?: RecruitmentApplicationListOrder,
  recruitmentId?: string
) => {
  const [applicantList, setApplicantList] = useState<
    RecruitmentApplication[] | null
  >(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchRecruitment = useCallback(async () => {
    setLoading(true);
    const recruitments = await getApplicationListByStatus(status, name, order);
    setApplicantList(recruitments);
    setLoading(false);
  }, [status, name, order]);

  useEffect(() => {
    fetchRecruitment();
  }, [status, name, order]);

  return { applicantList, isLoading, refetch: fetchRecruitment };
};

export const useRecruitmentApplicantListByUserId = (userId: string) => {
  const [recruitmentList, setRecruitmentList] = useState<
    AppliedRecruitment[] | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchRecruitmentListByUserId = useCallback(async () => {
    setRecruitmentList(null);
    setIsLoading(true);
    const appliedRecruitmentList = await getApplicationListByUserId(userId);
    setRecruitmentList(appliedRecruitmentList);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchRecruitmentListByUserId();
  }, [userId]);

  return { recruitmentList, isLoading, refetch: fetchRecruitmentListByUserId };
};

export const useCountRecruitmentApplicant = () => {
  const [countApplicant, setApplicant] =
    useState<CountApplicationResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchCountRecruitmentApplication = useCallback(async () => {
    setApplicant(null);
    setIsLoading(true);
    const countApplicantList = await countApplication();
    setApplicant(countApplicantList);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchCountRecruitmentApplication();
  }, []);

  return {
    countRecruitmentApplicant: countApplicant,
    isLoading,
    refetch: countApplication,
  };
};
