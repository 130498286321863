import React, { useState, CSSProperties } from "react";

interface Props {
  src: string;
  alt: string;
  width: string;
  height: string;
}

export const ImageView = ({
  src,
  alt,
  width = "auto",
  height = "auto",
}: Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const containerStyle: CSSProperties = {
    position: "relative" as const,
    width,
    height,
    backgroundColor: "#f0f0f0", // プレースホルダーの背景色
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    borderRadius: "8px",
    padding: "4px",
  };

  const imageStyle: CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    opacity: imageLoaded ? 1 : 0,
    transition: "opacity 0.3s ease-in-out",
  };

  const placeholderStyle: CSSProperties = {
    position: "absolute",
    fontSize: "16px",
    color: "#888",
    textAlign: "center",
    opacity: imageLoaded ? 0 : 1,
    transition: "opacity 0.3s ease-in-out",
  };

  return (
    <div style={containerStyle}>
      <img src={src} alt={alt} onLoad={handleImageLoad} style={imageStyle} />
      {!imageLoaded && <div style={placeholderStyle}>Loading...</div>}
    </div>
  );
};
