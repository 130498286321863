import React, { FC } from "react";
import { Navigation } from "../components/Navigation2";
import styled from "styled-components";
import { Footer } from "../components/Footer";
import { SideBar } from "../components/Common/Sidebar/SideBar";
import { useCurrentUser } from "../hooks/recoil/user";
import { inPTA } from "../apiClients/users";
import { BREAKPOINTS } from "../components/Responsive";

const PageFooter = styled(Footer)`
  @media (max-width: 1279px) {
    display: none;
  }
`;

const SideBarBlock = styled.div`
  min-width: 160px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const PageBody = styled.div`
  flex: 1;
  padding: 24px;
  min-width: 0;
  height: 100%;
  @media (max-width: ${BREAKPOINTS.SP}) {
    padding: 24px 16px;
  }
`;

const Container = styled.div`
  height: 100%;
  padding-top: 48px;
`;

type AppLayoutProps = {
  children?: React.ReactNode;
};

const AppDefaultLayout: FC<AppLayoutProps> = (props) => {
  const currentUser = useCurrentUser();

  return (
    <>
      <Navigation />
      <Container>
        <SideBar />
        <div style={{ display: "flex", height: "100%" }}>
          <SideBarBlock />
          <PageBody>
            {props.children}
            {inPTA(currentUser) && (
              <PageFooter>
                <a
                  href="https://www.notion.so/polyfit-12871017dc6544bb86694b709998927c"
                  target="_blank"
                  rel="noreferrer"
                >
                  ご利用ガイド
                </a>
              </PageFooter>
            )}
          </PageBody>
        </div>
      </Container>
    </>
  );
};

export default AppDefaultLayout;
