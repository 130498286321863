import React, { useState } from "react";
import styled from "styled-components";
import { PostType } from "../../hooks/api/post";
import { DeletePostConfirmModal } from "./DeletePostConfirmModal";
import { TrashIcon } from "../../components/icons/TrashIcon2";
import { colorsPallet } from "../../theme";

export const TrashPostButton = ({
  post,
  refetch,
}: {
  post: PostType;
  refetch: () => void;
}) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  return (
    <>
      <DeletePostButton
        onClick={() => setIsOpenDeleteModal(!isOpenDeleteModal)}
      >
        <TrashIcon color={colorsPallet.danger} />
        <p>削除</p>
      </DeletePostButton>
      {isOpenDeleteModal && (
        <DeletePostConfirmModal
          post={post}
          onClose={() => {
            setIsOpenDeleteModal(false);
          }}
          refetch={refetch}
        />
      )}
    </>
  );
};

const DeletePostButton = styled.button`
  color: ${colorsPallet.danger};
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  font-size: 14px;
  border: none;
  background-color: inherit;
  cursor: pointer;
`;
