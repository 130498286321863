import { Dispatch, SetStateAction, useState } from "react";
import {
  mailEvents,
  useGetMailEventsByRecordId,
} from "../../hooks/api/mailEvent";
import { PostType, resendPost, usePostWithTargets } from "../../hooks/api/post";
import { useToast } from "../../components/Toast";
import { User } from "../../apiClients/users";

interface DeliverySummaryHook {
  isLoading: boolean;
  targetUsers: User[];
  unreadUsers: User[];
  undeliveredUsers: User[];
  isOpenUnreadUsersModal: boolean;
  isOpenUndeliveredUsersModal: boolean;
  setIsOpenUnreadUsersModal: Dispatch<SetStateAction<boolean>>;
  setIsOpenUndeliveredUsersModal: Dispatch<SetStateAction<boolean>>;
  onResend: (users: User[]) => Promise<void>;
  onDownloadCSV: (users: User[]) => Promise<void>;
  post: PostType | undefined;
}

export const useDeliverySummary = (postId: string): DeliverySummaryHook => {
  const toast = useToast();
  const [isOpenUnreadUsersModal, setIsOpenUnreadUsersModal] =
    useState<boolean>(false);
  const [isOpenUndeliveredUsersModal, setIsOpenUndeliveredUsersModal] =
    useState<boolean>(false);
  const { mailEvents, isLoading: isMailEventLoading } =
    useGetMailEventsByRecordId(postId);
  const { post, isLoading: isPostLoading } = usePostWithTargets(postId);

  const isLoading: boolean = isMailEventLoading || isPostLoading;

  // 1. 連絡の対象者
  // idで重複を削除
  const targetUsers =
    post?.targetUsers.reduce(
      (uniqueUsers: User[], { user }: { user: User }) => {
        if (!uniqueUsers.some((u) => u.id === user.id)) {
          uniqueUsers.push(user);
        }
        return uniqueUsers;
      },
      []
    ) ?? [];

  // 2. メール開封イベントが記録されたユーザー
  const readAddresses =
    mailEvents
      ?.filter((mailEvent: mailEvents) => mailEvent.eventType === "open")
      .map((mailEvent: mailEvents) => mailEvent.email) ?? [];

  // 3. 送信失敗イベントが記録されたユーザー
  const undeliveredMailAddresses =
    mailEvents
      ?.filter(
        (mailEvent) =>
          mailEvent.eventType === "dropped" || mailEvent.eventType === "bounce"
      )
      .map((mailEvent: mailEvents) => mailEvent.email) ?? [];

  // 未読ユーザー: 1 && not 2 && not 3
  const unreadUsers = targetUsers.filter(
    (user: User) =>
      !readAddresses.includes(user.email) &&
      !undeliveredMailAddresses.includes(user.email)
  );

  // 不通ユーザー: 1 && 3
  const undeliveredUsers = targetUsers.filter((user: User) =>
    undeliveredMailAddresses.includes(user.email)
  );

  const onResend = async (users: User[]) => {
    try {
      await resendPost(
        postId,
        users.map((user) => user.id)
      );
    } catch (err) {
      toast.error("再送に失敗しました");
      return;
    }

    toast.success("再送しました");
    setIsOpenUnreadUsersModal(false);
  };

  const convertToCSV = (users: User[]): string => {
    const csvRows = [
      ["id", "name", "email"].join(","),
      ...users.map((user) => [user.id, user.name, user.email].join(",")),
    ];

    return csvRows.join("\n");
  };

  const onDownloadCSV = async (users: User[]) => {
    const csvData = convertToCSV(users);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    // BlobからオブジェクトURLを作成
    const url = window.URL.createObjectURL(blob);

    // ダウンロードリンクを生成
    const a = document.createElement("a");
    a.href = url;
    a.download = "undeliveredUsers.csv";
    document.body.appendChild(a); // リンクをDOMに追加
    a.click(); // リンクをクリックしてダウンロードを実行

    // リソースのクリーンアップ
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    toast.success("CSVをダウンロードしました");
    setIsOpenUndeliveredUsersModal(false);
  };

  return {
    isLoading,
    targetUsers,
    unreadUsers,
    undeliveredUsers,
    isOpenUnreadUsersModal,
    isOpenUndeliveredUsersModal,
    onResend,
    onDownloadCSV,
    setIsOpenUnreadUsersModal,
    setIsOpenUndeliveredUsersModal,
    post,
  };
};
