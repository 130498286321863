import React, { useEffect, useState } from "react";
import { usePolyfitHistory } from "../../../hooks/router";
import styled from "styled-components";
import * as Responsive from "../../../components/Responsive";
import * as Typo from "../../../components/Typo";
import { Margin } from "../../../components/Margin";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Label } from "../../../components/form/Label";
import { useOrganizationCommunity } from "src/hooks/recoil/organization";
import { Spinner } from "src/components/icons/Spinner";
import { Community } from "@shared/types/community";
import { ResidentInviteMemberPageFormData } from "./ResidentInviteMemberPage";
import { Button } from "src/components/Button3";
import { RegisterStepper } from "src/components/RegisterStepper";
import { useToast } from "src/components/Toast";
import { CommunityListItem } from "src/features/Member/Community/CommunityListItem";
import { SignatureTemplateButton } from "src/features/Member/Community/SignatureTemplateButton";
import {
  useFilteredSchoolCommunitiesExceptMain,
  useSelectedCommunity,
} from "src/features/Member/Community/utils/hooks";
import { hasValidSignatureTemplate } from "src/features/Member/Community/utils/utils";
import {
  TabType,
  FormValueSchema,
  FormValue as ResidentInviteMemberRegisterCommunityFormData,
} from "src/features/Member/Community/utils/form";
import { CommunityUserTypeSelect } from "src/features/Member/Community/CommunityUserTypeSelect";

const CURERNT_STEP = 2;

type Props = {
  formData: ResidentInviteMemberPageFormData;
  updateFormData: (stepData: Partial<ResidentInviteMemberPageFormData>) => void;
};

/**
 * CSメンバー招待登録時に校区を選択するページ
 */
export default function RegidentInviteMemberRegisterCommunityPage({
  formData,
  updateFormData,
}: Props) {
  const history = usePolyfitHistory();
  const toast = useToast();
  const [tab, setTab] = useState<TabType>("elementarySchool");
  const {
    elementarySchoolCommunities,
    juniorHighSchoolCommunities,
    isLoading,
  } = useOrganizationCommunity();
  const [selectedCommunities, setSelectedCommunities] = useState<Community[]>(
    []
  );

  const {
    control,
    getValues,
    setValue,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ResidentInviteMemberRegisterCommunityFormData>({
    resolver: zodResolver(FormValueSchema),
    defaultValues: {
      mainElementarySchool: formData.stepTwo.mainElementarySchool ?? "",
      mainJuniorHighSchool: formData.stepTwo.mainJuniorHighSchool ?? "",
      mainElementarySchoolType: formData.stepTwo.mainElementarySchoolType,
      mainJuniorHighSchoolType: formData.stepTwo.mainJuniorHighSchoolType,
      elementarySchools: formData.stepTwo.elementarySchools ?? [],
      juniorHighSchools: formData.stepTwo.juniorHighSchools ?? [],
    },
  });
  // 最寄りの小学校区
  const mainElementarySchool = watch("mainElementarySchool");
  // 最寄りの中学校区
  const mainJuniorHighSchool = watch("mainJuniorHighSchool");
  // 最寄りの小学校区以外の小学校区
  const filteredElementarySchoolCommunities =
    useFilteredSchoolCommunitiesExceptMain(
      elementarySchoolCommunities,
      mainElementarySchool
    );
  // 最寄りの中学校区以外の中学校区
  const filteredJuniorHighSchoolCommunities =
    useFilteredSchoolCommunitiesExceptMain(
      juniorHighSchoolCommunities,
      mainJuniorHighSchool
    );

  useEffect(() => {
    filteredElementarySchoolCommunities.map((community) => {
      if (
        formData.stepTwo.elementarySchools &&
        formData.stepTwo.elementarySchools.includes(community.id)
      ) {
        setSelectedCommunities((prev) => {
          if (prev.findIndex((p) => p.id === community.id) === -1) {
            return [...prev, community];
          }
          return prev.filter((p) => p.id !== community.id);
        });
      }
    });
    filteredJuniorHighSchoolCommunities.map((community) => {
      if (
        formData.stepTwo.juniorHighSchools &&
        formData.stepTwo.juniorHighSchools.includes(community.id)
      ) {
        setSelectedCommunities((prev) => {
          if (prev.findIndex((p) => p.id === community.id) === -1) {
            return [...prev, community];
          }
          return prev.filter((p) => p.id !== community.id);
        });
      }
    });
  }, [isLoading]);

  // 最寄りの小学校区が更新された場合は、追加で選択された小学校区からは削除する
  useEffect(() => {
    const elementarySchools = getValues("elementarySchools");
    const values = elementarySchools.filter((s) => s !== mainElementarySchool);
    setValue("elementarySchools", values);
    setSelectedCommunities((prev) => {
      return prev.filter((p) => p.id !== mainElementarySchool);
    });

    // 最寄りの小学校区の役割をクリア
    setValue("mainElementarySchoolType", undefined);
  }, [mainElementarySchool]);
  // 最寄りの中学校区が更新された場合は、追加で選択された中学校区からは削除する
  useEffect(() => {
    const juniorHighSchools = getValues("juniorHighSchools");
    const values = juniorHighSchools.filter((s) => s !== mainJuniorHighSchool);
    setValue("juniorHighSchools", values);
    setSelectedCommunities((prev) => {
      return prev.filter((p) => p.id !== mainJuniorHighSchool);
    });

    // 最寄りの中学校区の役割をクリア
    setValue("mainJuniorHighSchoolType", undefined);
  }, [mainJuniorHighSchool]);

  const handleElementarySchoolsCheckBox = (community: Community) => {
    const elementarySchools = getValues("elementarySchools");
    const values = elementarySchools.includes(community.id)
      ? elementarySchools.filter((s) => s !== community.id)
      : [...elementarySchools, community.id];
    setValue("elementarySchools", values);
    setSelectedCommunities((prev) => {
      if (prev.findIndex((p) => p.id === community.id) === -1) {
        return [...prev, community];
      }
      return prev.filter((p) => p.id !== community.id);
    });
  };

  const handleJuniorHighSchoolsCheckBox = (community: Community) => {
    const juniorHighSchools = getValues("juniorHighSchools");
    const values = juniorHighSchools.includes(community.id)
      ? juniorHighSchools.filter((s) => s !== community.id)
      : [...juniorHighSchools, community.id];
    setValue("juniorHighSchools", values);
    setSelectedCommunities((prev) => {
      if (prev.findIndex((p) => p.id === community.id) === -1) {
        return [...prev, community];
      }
      return prev.filter((p) => p.id !== community.id);
    });
  };

  const onSubmit = async (
    data: ResidentInviteMemberRegisterCommunityFormData
  ) => {
    if (!data.mainElementarySchool && !data.mainJuniorHighSchool) {
      toast.error("一つ以上の校区を選択してください");
      return;
    }
    try {
      updateFormData({ stepTwo: data });
      history.push({
        to: "RESIDENT_INVITATION",
        query: { step: `${CURERNT_STEP + 1}` },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const selectedElementarySchool = useSelectedCommunity(
    elementarySchoolCommunities,
    mainElementarySchool
  );

  const selectedJuniorHighSchool = useSelectedCommunity(
    juniorHighSchoolCommunities,
    mainJuniorHighSchool
  );

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledContainer>
          <RegisterStepper count={2} current={2} title="" />
          <Margin marginBottom={16} />
          <SubTitleWrapper>
            <Typo.Heading3>校区選択</Typo.Heading3>
            <Required>*必須</Required>
          </SubTitleWrapper>
          <Margin marginBottom={16} />
          <div>
            <InputWrapper>
              <div>
                <Label>最寄りの小学校区</Label>
                <SelectWrapper>
                  <SelectBox {...register("mainElementarySchool")}>
                    <option value="" disabled style={{ color: "#aab4c4" }}>
                      〇〇小学校
                    </option>
                    {!isLoading &&
                      elementarySchoolCommunities
                        .filter(hasValidSignatureTemplate)
                        .map((community) => {
                          return (
                            <option
                              key={community.id}
                              value={community.id}
                              selected={
                                community.id ===
                                  formData.stepTwo.mainElementarySchool ?? ""
                              }
                            >
                              {community.name}
                            </option>
                          );
                        })}
                  </SelectBox>
                  {errors.mainElementarySchool ? (
                    <ErrorMessage>
                      {errors?.mainElementarySchool?.message}
                    </ErrorMessage>
                  ) : (
                    <Margin marginTop={8} />
                  )}
                  {selectedElementarySchool && (
                    <>
                      <SignatureTemplateButton
                        community={selectedElementarySchool}
                      />
                      <CommunityUserTypeSelect
                        name="mainElementarySchoolType"
                        control={control}
                        fieldError={errors.mainElementarySchoolType}
                      />
                    </>
                  )}
                </SelectWrapper>
              </div>
              <div>
                <Label>最寄りの中学校区</Label>
                <SelectWrapper>
                  <SelectBox {...register("mainJuniorHighSchool")}>
                    <option value="" disabled style={{ color: "#aab4c4" }}>
                      〇〇中学校
                    </option>
                    {!isLoading &&
                      juniorHighSchoolCommunities
                        .filter(hasValidSignatureTemplate)
                        .map((community) => {
                          return (
                            <option
                              key={community.id}
                              value={community.id}
                              selected={
                                community.id ===
                                  formData.stepTwo.mainJuniorHighSchool ?? ""
                              }
                            >
                              {community.name}
                            </option>
                          );
                        })}
                  </SelectBox>
                  {errors.mainJuniorHighSchool ? (
                    <ErrorMessage>
                      {errors?.mainJuniorHighSchool?.message}
                    </ErrorMessage>
                  ) : (
                    <Margin marginTop={8} />
                  )}
                  {selectedJuniorHighSchool && (
                    <>
                      <SignatureTemplateButton
                        community={selectedJuniorHighSchool}
                      />
                      <CommunityUserTypeSelect
                        name="mainJuniorHighSchoolType"
                        control={control}
                        fieldError={errors.mainJuniorHighSchoolType}
                      />
                    </>
                  )}
                </SelectWrapper>
              </div>
            </InputWrapper>
            <Responsive.Col>
              <Margin marginTop={16} />
              <Label>校区を追加</Label>
              <TabWrapper>
                <Tab
                  isActive={tab === "elementarySchool"}
                  onClick={() => setTab("elementarySchool")}
                >
                  小学校
                </Tab>
                <Tab
                  isActive={tab === "juniorHighSchool"}
                  onClick={() => setTab("juniorHighSchool")}
                >
                  中学校
                </Tab>
              </TabWrapper>
              <CommunityListWrapper>
                <CommunityList>
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : tab === "elementarySchool" ? (
                    filteredElementarySchoolCommunities.map(
                      (community, index) => {
                        return (
                          <CommunityListItem
                            key={index}
                            community={community}
                            onCheckboxChange={handleElementarySchoolsCheckBox}
                            isChecked={watch("elementarySchools").includes(
                              community.id || ""
                            )}
                          />
                        );
                      }
                    )
                  ) : (
                    tab === "juniorHighSchool" &&
                    filteredJuniorHighSchoolCommunities.map(
                      (community, index) => {
                        return (
                          <CommunityListItem
                            key={index}
                            community={community}
                            onCheckboxChange={handleJuniorHighSchoolsCheckBox}
                            isChecked={watch("juniorHighSchools").includes(
                              community.id || ""
                            )}
                          />
                        );
                      }
                    )
                  )}
                </CommunityList>
              </CommunityListWrapper>
              {selectedCommunities.length > 0 && (
                <SelectedValuesList>
                  <Divider />
                  <SubTitle>選択された追加の校区</SubTitle>
                  <Margin marginBottom={10} />
                  <BadgeWrapper>
                    {selectedCommunities.map((community, index) => {
                      return <Badge key={index}>{community.name}</Badge>;
                    })}
                  </BadgeWrapper>
                </SelectedValuesList>
              )}
            </Responsive.Col>
          </div>
          <NextButton color="primary" fill>
            次へ
          </NextButton>
        </StyledContainer>
      </form>
    </div>
  );
}

const StyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

const Required = styled.span`
  color: #bd271e;
  font-size: 12px;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;

const SelectWrapper = styled.div`
  min-width: 240px;
  @media (max-width: 1279px) {
    min-width: 163px;
  }
`;

const SelectBox = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 240px;
  gap: 20px;
  @media (max-width: 1279px) {
    flex-flow: column;
  }
`;

const TabWrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #1322951a;
`;

const Tab = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: start;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 20px;
  ${(props) =>
    props.isActive && `color: #0077CC; border-bottom: 2px solid #0077CC`};
  cursor: pointer;
`;

const CommunityListWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 8px;
`;

const CommunityList = styled.ul`
  overflow: auto;
  width: 100%;
  max-height: 300px;
  background: white;
  list-style: none;
  border-radius: 6px;
  border: 1px solid #1322951a;
`;

// 選択した値のリスト
const SelectedValuesList = styled.ul`
  list-style: none;
`;

const SubTitle = styled.p`
  font-size: 12px;
`;

const Divider = styled.div`
  width: 100%;
  border: 0.75px solid #f0f2f5;
  margin: 10px 0px;
`;

const BadgeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const Badge = styled.div`
  width: fit-content;
  border: 1px solid #0071c2;
  border-radius: 36px;
  background: #e6f1fa;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  gap: 4px;
  font-size: 12px;
  margin-right: 8px;
`;

const NextButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
  padding: 9.5px 0;
`;
