import React from "react";
import styled from "styled-components";

type Props = {
  variant?: "contained" | "text";
  toggle: (arg: boolean) => void;
  isOpenMenu: boolean;
};

export const MenuButton = ({
  variant = "contained",
  toggle,
  isOpenMenu,
}: Props) => {
  return (
    <AdminMenuButton variant={variant} onClick={() => toggle(!isOpenMenu)}>
      ...
    </AdminMenuButton>
  );
};

const AdminMenuButton = styled.button<{
  variant?: "contained" | "text";
}>`
  width: 40px;
  height: 40px;
  background-color: ${(props) =>
    props.variant === "contained" ? "#e1e2e5" : "transparent"};
  color: #343741;
  border-radius: 6px;
  border: none;
  margin-left: 12px;
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
  z-index: 1;
`;
