import React, { FC } from "react";
import styled from "styled-components";
import {
  useForm,
  Path,
  RegisterOptions,
  SubmitHandler,
  FieldValues,
  DefaultValues,
} from "react-hook-form";
import { DangerIcon } from "../icons/DangerIcon";
import { PulldownIcon } from "../icons/PulldownIcon";

/**
 * @param {object} defaultValue - formの初期値
 */
export const useCustomReactHookForm = <T extends FieldValues>(
  defaultValue?: DefaultValues<T>
) => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: defaultValue,
  });

  type TextFieldProps = {
    name: Path<T>;
    type?: string;
    options?: RegisterOptions;
    isDanger?: boolean;
  };
  const TextField: FC<
    TextFieldProps &
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >
  > = ({ name, options, isDanger, ...others }) => {
    return (
      <TextFieldWrapper className={isDanger ? "danger-true" : "danger-false"}>
        <input {...register(name, { ...options })} {...others} />
        {isDanger && <DangerIcon size={40} />}
      </TextFieldWrapper>
    );
  };

  type SelectFieldProps = {
    name: Path<T>;
    options?: RegisterOptions;
    isDanger?: boolean;
    selectList: {
      value: any;
      text: string;
    }[];
  };
  const SelectField: FC<
    SelectFieldProps &
      React.DetailedHTMLProps<
        React.SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
      >
  > = ({ name, options, isDanger, selectList, ...others }) => {
    return (
      <SelectFieldWrapper className={isDanger ? "danger-true" : "danger-false"}>
        <select {...register(name, { ...options })} {...others}>
          {selectList.map((opt) => {
            return (
              <option key={opt.value} value={opt.value}>
                {opt.text}
              </option>
            );
          })}
        </select>
        {isDanger ? <DangerIcon size={40} /> : <PulldownIcon size={40} />}
      </SelectFieldWrapper>
    );
  };

  type RadioInputFieldProps = {
    name: Path<T>;
    options?: RegisterOptions;
    isDanger?: boolean;
  };
  const RadioInputField: FC<
    RadioInputFieldProps &
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  > = ({ name, options, isDanger, ...others }) => {
    return (
      <input {...register(name, { ...options })} {...others} type="radio" />
    );
  };

  type FormProps = {
    onSubmit?: SubmitHandler<T>;
    children: React.ReactNode;
  };
  const Form: FC<FormProps> = ({ onSubmit = () => {}, children }) => {
    return <form onSubmit={handleSubmit(onSubmit)}>{children}</form>;
  };

  return {
    TextField,
    RadioInputField,
    SelectField,
    Form,
    errors,
    reset,
    getValues,
    setValue,
    trigger,
    clearErrors,
  };
};

const TextFieldWrapper = styled.div`
  position: relative;
  input {
    width: 100%;
    padding: 10px 12px;
    background: #fbfcfd;
    border: 1px rgba(19, 34, 149, 0.1) solid;
    border-radius: 6px;
    font-size: 14px;
    color: #343741;
    line-height: 21px;
    ::placeholder {
      color: #aab4c4;
    }
  }
  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  &.danger-false {
    input:focus {
      border-bottom: solid 2px #0071c2;
    }
  }
  &.danger-true input {
    border-bottom: solid 2px #bd271e;
  }
`;

const SelectFieldWrapper = styled.div`
  position: relative;
  select {
    appearance: none;
    width: 100%;
    padding: 10px 40px 10px 12px;
    background: #fbfcfd;
    border: 1px rgba(19, 34, 149, 0.1) solid;
    border-radius: 6px;
    font-size: 14px;
    color: #343741;
    line-height: 21px;
  }
  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  &.danger-false {
    input:focus {
      border-bottom: solid 2px #0071c2;
    }
  }
  &.danger-true input {
    border-bottom: solid 2px #bd271e;
  }
`;
