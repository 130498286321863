import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { User } from "../../apiClients/users";

const { persistAtom } = recoilPersist();

export const ptaUsersState = atom<User[]>({
  key: "ptaUsers",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
