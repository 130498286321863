import React from "react";

export const TrashIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.43363 0.917969C7.95716 0.917969 8.38376 1.34378 8.38376 1.86809V2.64145H11.3888C11.6329 2.64145 11.8307 2.83931 11.8307 3.08337V3.52529C11.8307 3.76936 11.6329 3.96721 11.3888 3.96721H11.2562V13.6452C11.2562 14.3283 10.7019 14.8826 10.0189 14.8826H1.97593C1.29288 14.8826 0.738557 14.3283 0.738557 13.6452V3.96721H0.605982C0.361916 3.96721 0.164062 3.76936 0.164062 3.52529V3.08337C0.164062 2.83931 0.361916 2.64145 0.605982 2.64145H3.61103V1.86809C3.61103 1.34417 4.03724 0.917969 4.56116 0.917969H7.43363ZM9.93048 3.96721H2.06432V13.358C2.06432 13.4494 2.1262 13.5266 2.21034 13.5497L2.26318 13.5569H9.73161C9.82307 13.5569 9.9002 13.495 9.92337 13.4108L9.93048 13.358V3.96721ZM4.93074 6.08689C4.88682 5.76344 4.60935 5.51393 4.27391 5.51393C3.90798 5.51393 3.61103 5.81087 3.61103 6.17681V11.3473L3.61709 11.4372C3.661 11.7606 3.93847 12.0101 4.27391 12.0101C4.63985 12.0101 4.93679 11.7132 4.93679 11.3473V6.17681L4.93074 6.08689ZM7.72088 5.51393C8.05632 5.51393 8.33379 5.76344 8.3777 6.08689L8.38376 6.17681V11.3473C8.38376 11.7132 8.08681 12.0101 7.72088 12.0101C7.38544 12.0101 7.10797 11.7606 7.06406 11.4372L7.058 11.3473V6.17681C7.058 5.81087 7.35495 5.51393 7.72088 5.51393ZM4.93679 2.24373H7.058V2.64145H4.93679V2.24373Z"
        fill="#BD271E"
      />
    </svg>
  );
};
