import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { getBoardEducationByOrganizationId } from "src/apiClients/boardEducation";

export const useBoardEducationByOrganizationId = (organizationId: string) => {
  const query = useQuery({
    queryKey: ["api", "boardEducation", "getByOrganizationId", organizationId],
    queryFn: () => getBoardEducationByOrganizationId(organizationId),
  });

  return {
    boardEducation: useMemo(() => query.data || null, [query.data]),
    ...query,
  };
};

export function useInvalidateBoardEducation() {
  const queryClient = useQueryClient();

  function invalidateBoardEducation(organizationId?: string) {
    if (organizationId) {
      queryClient.invalidateQueries({
        queryKey: [
          "api",
          "boardEducation",
          "getByOrganizationId",
          organizationId,
        ],
      });
    } else {
      queryClient.invalidateQueries({
        queryKey: ["api", "boardEducation", "getByOrganizationId"],
      });
    }
  }

  return {
    invalidateBoardEducation,
  };
}
