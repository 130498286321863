import { ChatRoomFilterType } from "@shared/types/chatRoom";
import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const chatRoomFilterState = atom<ChatRoomFilterType>({
  key: "ChatRoomFilter_v2",
  default: {
    type: "",
    recruitmentName: null,
    recruitmentSchedule: null,
    applicationStatus: "",
    approvalStatus: "",
    participantChatRoomApprovalStatus: "",
    chatRoomName: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export const useChatRoomFilter = () => {
  const [chatRoomFilter, setChatRoomFilter] =
    useRecoilState(chatRoomFilterState);
  const [isFilterActive, setIsFilterActive] = useState(false);

  useEffect(() => {
    setIsFilterActive(
      !!chatRoomFilter.type ||
        !!chatRoomFilter.recruitmentName ||
        !!chatRoomFilter.recruitmentSchedule ||
        !!chatRoomFilter.applicationStatus ||
        !!chatRoomFilter.approvalStatus ||
        !!chatRoomFilter.chatRoomName
    );
  }, [chatRoomFilter]);

  const resetFilter = () => {
    setChatRoomFilter({
      type: "",
      recruitmentName: null,
      recruitmentSchedule: null,
      applicationStatus: "",
      approvalStatus: "",
      participantChatRoomApprovalStatus: "",
      chatRoomName: "",
    });
  };

  return {
    chatRoomFilter,
    setChatRoomFilter,
    isFilterActive,
    resetFilter,
  } as const;
};
