import { useEffect, useLayoutEffect, useState } from "react";
import * as childApi from "../../apiClients/child";

export type Child = childApi.Child;

export function useCurrentUserChildren() {
  const [children, setChildren] = useState<Child[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCurrentUserChildren = async () => {
    setIsLoading(true);

    await childApi.currentUserChildren().then((res) => {
      setChildren(res);
    });

    setIsLoading(false);
  };

  useEffect(() => {
    getCurrentUserChildren();
  }, []);

  return {
    children,
    refetch: getCurrentUserChildren,
    isLoading,
  };
}

export type ChildrenFilterType = {
  grades?: (childApi.ChildGrade | undefined)[];
  classes?: (childApi.ChildClass | undefined)[];
  parentsAreUnique?: "0" | "1";
};

export const useGetChildren = (userId: string, skip: boolean = false) => {
  const [children, setChildren] = useState<Child[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const getChildren = async () => {
    setIsLoading(true);
    const children = await childApi.getChildren(userId);
    setChildren(children);
    setIsLoading(false);
  };
  useEffect(() => {
    if (!skip) getChildren();
  }, [skip]);
  return { children, getChildren, isLoading };
};

/**
 * 複数ユーザーの子供を一括で取得する
 */
export const useGetDisabledUsersChildrenByOrganizationId = () => {
  const [children, setChildren] = useState<Child[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const getDisabledUsersChildrenByOrganizationId = async (
    q?: string,
    filter?: ChildrenFilterType
  ) => {
    // レンダリングごとにsetChildrenが呼び出されると、重複が発生するのでレンダリングごとにChildrenをリセットする
    setChildren([]);
    setIsLoading(true);
    const children = await childApi.getDisabledUsersChildrenByOrganizationId(
      q,
      filter
    );

    setChildren([...new Set(children)]);
    setIsLoading(false);
  };

  useLayoutEffect(() => {
    setChildren([]);
  }, []);

  useEffect(() => {
    getDisabledUsersChildrenByOrganizationId();
  }, []);

  return { children, getDisabledUsersChildrenByOrganizationId, isLoading };
};
