import React from "react";
import { Margin } from "src/components/Margin";
import styled from "styled-components";
import {
  ModalPortal,
  ModalHeader,
  ModalSubmitButtons,
} from "src/components/Modal";

interface Props {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
  chatRoomName: string;
}

export const RejectChatRoomModal = (props: Props) => {
  if (!props.isOpen) return null;

  return (
    <ModalPortal onClose={props.onClose}>
      <ModalHeader>
        <p>{props.chatRoomName}への招待</p>
      </ModalHeader>
      <ModalBody>
        <p>{`${props.chatRoomName}への招待を拒否してもよろしいですか？`}</p>
        <Margin marginBottom={10} />
      </ModalBody>
      <ModalSubmitButtons
        submitText="拒否"
        submitColor="danger"
        onSubmit={props.onSubmit}
        onCancel={props.onClose}
        cancelColor="subPrimary"
      />
    </ModalPortal>
  );
};

const ModalBody = styled.div`
  min-height: 50px;
  height: fit-content;
`;
