import { RecruitmentStatus } from "./recruitment";

export const ApplicationStatus = {
  APPLIED: "APPLIED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  DECLINED: "DECLINED",
} as const;

export type ApplicationStatus =
  (typeof ApplicationStatus)[keyof typeof ApplicationStatus];

/**
 * 応募系APIクライアント 取得系
 */
export type RecruitmentApplication = {
  id: string;
  accountId: string;
  user: {
    id: string;
    email: string;
    name: string;
    picture: string | null;
  };
  coverLetterChatMessage?: {
    content: string;
  };
  status: ApplicationStatus;
  rejectedAt: Date;
  recruitment: {
    id: string;
    picture: string | null;
    title: string | null;
    status: RecruitmentStatus;
    createdBy?: string;
    schedule: {
      start: Date;
      end: Date;
    }[];
  };
};
export const RecruitmentApplicationListOrder = {
  USER_NAME: "USER_NAME",
  RECRUITMENT_TITLE: "RECRUITMENT_TITLE",
  RECRUITMENT_START_DATE: "RECRUITMENT_START_DATE",
} as const;

export type RecruitmentApplicationListOrder =
  (typeof RecruitmentApplicationListOrder)[keyof typeof RecruitmentApplicationListOrder];
