import { useMemo } from "react";
import { CommunityWithSignatureTemplate } from "src/@shared/types/organization";
import { Community } from "src/@shared/types/community";
import { AccountCommunityRole } from "src/apiClients/auth";
import {
  isElementarySchoolByType,
  isJuniorHighSchoolByType,
} from "@shared/types/school";

export function useSelectedCommunity(
  communities: CommunityWithSignatureTemplate[],
  selectedId: string | undefined
): CommunityWithSignatureTemplate | undefined {
  return useMemo(
    () => communities.find((c) => c.id === selectedId),
    [communities, selectedId]
  );
}

export function useFilteredSchoolCommunitiesExceptMain(
  communities: CommunityWithSignatureTemplate[],
  mainCommunityId: string
): CommunityWithSignatureTemplate[] {
  return useMemo(
    () =>
      communities
        .filter((community) => community.id !== mainCommunityId)
        .sort((a, b) => a.name.localeCompare(b.name, "ja")),
    [communities, mainCommunityId]
  );
}

export const useFindMainCommunity = (
  communities: Community[],
  communityRoles: AccountCommunityRole[]
) => {
  return useMemo(() => {
    const mainCommunity = communities.find(
      (community) =>
        communityRoles.findIndex(
          (role) => role.communityId === community.id && role.isMain
        ) !== -1
    );

    if (!mainCommunity) {
      return undefined;
    }

    const mainCommunityRole = communityRoles.find(
      (role) => role.communityId === mainCommunity.id && role.isMain
    );

    return {
      community: mainCommunity,
      communityRole: mainCommunityRole!,
    };
  }, [communities, communityRoles]);
};

export const useFindAdditionalCommunities = (
  communities: Community[],
  communityRoles: AccountCommunityRole[]
) => {
  return useMemo(() => {
    return communities.filter(
      (community) =>
        communityRoles.findIndex(
          (role) => role.communityId === community.id && !role.isMain
        ) !== -1
    );
  }, [communities, communityRoles]);
};

type ExtractedSchools<T extends Community> = {
  elementarySchools: T[];
  juniorHighSchools: T[];
};

export const extractElementaryAndJuniorHighSchools = <T extends Community>(
  communities: T[]
): ExtractedSchools<T> => {
  const elementarySchools: T[] = [];
  const juniorHighSchools: T[] = [];

  communities.forEach((community) => {
    if (isElementarySchoolByType(community.school.type)) {
      elementarySchools.push(community);
    } else if (isJuniorHighSchoolByType(community.school.type)) {
      juniorHighSchools.push(community);
    }
  });

  return { elementarySchools, juniorHighSchools };
};

export const useExtractSchools = <T extends Community>(
  communities: T[]
): ExtractedSchools<T> => {
  return useMemo(
    () => extractElementaryAndJuniorHighSchools(communities),
    [communities]
  );
};
