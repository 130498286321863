import * as client from "./client";
import type {
  Recruitment,
  RecruitmentCreateArgsType,
  RecruitmentFilter,
  RecruitmentUpdateArgsType,
} from "@shared/types/recruitment";

/**
 * 募集系APIクライアント
 */

/**
 * 募集の状態を返す
 * @param {Recruitment} recruitment
 * @returns {boolean} 募集中かどうか
 */
export const recruitmentIsOpened = (
  recruitment: Pick<Recruitment, "schedule" | "status">
): boolean => {
  const { schedule, status } = recruitment;
  if (status === "CLOSED") {
    return false;
  }
  // 募集時間(複数)の中で一番遅い終了時刻
  const end = schedule
    .map((s) => s.end)
    .sort()
    .reverse()[0];
  return new Date() < new Date(end);
};

export async function getDraftRecruitmentList(): Promise<Recruitment[]> {
  return await client.get<{}, Recruitment[]>("/recruitment/draftList", {});
}

export async function getRecruitmentList(
  filter: RecruitmentFilter
): Promise<Recruitment[]> {
  return await client.get<{}, Recruitment[]>("/recruitment/list", {
    volunteerType: filter.volunteerType,
    isPaidVolunteer: filter.isPaidVolunteer,
    date: filter.date,
    isOpen: filter.isOpen,
  });
}

export async function getApplyRecruitmentList(
  status?: string
): Promise<Recruitment[]> {
  return await client.get<{}, Recruitment[]>("/recruitment/applyList", {
    status,
  });
}

export async function getApproveRecruitmentList(): Promise<Recruitment[]> {
  return await client.get<{}, Recruitment[]>("/recruitment/approveList", {});
}

export async function getRecruitmentById(
  recruitmentId: string
): Promise<Recruitment> {
  return await client.get<{ recruitmentId: string }, Recruitment>(
    "/recruitment/getByRecruitmentId",
    { recruitmentId }
  );
}

export async function createRecruitment(
  arg: RecruitmentCreateArgsType,
  files: File[]
): Promise<void> {
  const recruitmentCreateArg = JSON.stringify(arg);
  await client.postWithFormData<
    { recruitmentCreateArg: string; files: File[] },
    { result: string }
  >("/recruitment/create", { recruitmentCreateArg, files });
}

export async function updateRecruitment(
  arg: RecruitmentUpdateArgsType,
  files: File[]
) {
  const recruitmentUpdateArg = JSON.stringify(arg);
  await client.postWithFormData<
    { recruitmentUpdateArg: string; files: File[] },
    { result: string }
  >("/recruitment/update", { recruitmentUpdateArg, files });
}

export async function closeRecruitment(recruitmentId: string): Promise<void> {
  await client.post<{ recruitmentId: string }, { result: string }>(
    "/recruitment/close",
    { recruitmentId }
  );
}

export async function deleteRecruitment(recruitmentId: string): Promise<void> {
  await client.post<{ recruitmentId: string }, { result: string }>(
    "/recruitment/delete",
    { recruitmentId }
  );
}

// 公開済みの募集は論理削除する
export async function deleteOpenRecruitment(
  recruitmentId: string
): Promise<void> {
  await client.post<{ recruitmentId: string }, { result: string }>(
    "/recruitment/deleteOpen",
    { recruitmentId }
  );
}

export async function applyRecruitment(
  recruitmentId: string,
  comment?: string
): Promise<void> {
  await client.post<
    { recruitmentId: string; comment?: string },
    { result: string }
  >("/recruitment/apply", { recruitmentId, comment });
}
