import React from "react";
import { OpenEndedSurveyResultField } from "./OpenEndedSurveyResultField";
import { MultiChoiceSurveyResultField } from "./MultiChoiceSurveyResultField";
import {
  SurveyResult,
  isMultiChoiceSurveyResult,
  isOpenEndedSurveyResult,
} from "../../../apiClients/surveyResults";
import styled from "styled-components";

// 各質問に対するcomponent
export function SurveyResultField({
  surveyResult,
}: {
  surveyResult: SurveyResult;
}): JSX.Element {
  // 種別によって表示が変わる
  if (isMultiChoiceSurveyResult(surveyResult)) {
    return <MultiChoiceSurveyResultField surveyResult={surveyResult} />;
  }
  if (isOpenEndedSurveyResult(surveyResult)) {
    return <OpenEndedSurveyResultField surveyResult={surveyResult} />;
  }
  return <></>;
}

export const SurveyResultContainer = styled.div`
  /* box-style */
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(245, 247, 250, 1);
`;

export const QuestionTitle = styled.p`
  font-size: 14px;
  color: rgba(26, 28, 33, 1);
  margin-bottom: 14px;
`;
