import { useEffect, useState } from "react";
import * as mailEventsApi from "../../apiClients/mailEvent";

export type mailEvents = {
  id: string;
  email: string;
  mailType: "post"; // 必要に応じて他のメールタイプも追加する
  recordId: string;
  timestamp: number;
  smtpId: string;
  eventType: "open" | "delivered" | "bounce" | "dropped";
  categories: string[];
  sgEventId: string;
  sgMessageId: string;
  useragent?: string | null;
  ip?: string | null;
  url?: string | null;
  response?: string | null;
  attempt?: string | null;
  reason?: string | null;
  status?: string | null;
  asmGroupId?: number | null;
};

export const useGetMailEventsByRecordId = (recordId: string) => {
  const [mailEvents, setMailEvents] = useState<mailEvents[]>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getMailEventsByRecordId();
  }, [recordId]);

  const getMailEventsByRecordId = async () => {
    setIsLoading(true);
    const mailEvents = await mailEventsApi.getMailEventsByRecordId(recordId);
    // @ts-ignore
    setMailEvents(mailEvents);
    setIsLoading(false);
  };

  return { mailEvents, isLoading };
};
