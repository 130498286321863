import React, { JSX } from "react";
import { MultiChoiceSurveyResult } from "../../../apiClients/surveyResults";
import { QuestionTitle, SurveyResultContainer } from "./SurveyResultField";
import { Label } from "../../../components/form/Label";
import styled from "styled-components";

const colors = [
  "rgba(222, 239, 255, 1)",
  "rgba(121, 170, 217, 1)",
  "rgba(109, 204, 177, 1)",
  "rgba(255, 183, 98, 1)",
  "rgba(238, 120, 157, 1)",
  "rgba(255, 163, 143, 1)",
  "rgba(255, 186, 244, 1)",
  "rgba(192, 170, 255, 1)",
  "rgba(160, 244, 255, 1)",
  "rgba(255, 234, 122, 1)",
];

// 各選択肢に何票入ったかを表現するデータ型
interface AggregatedResult {
  choiceText: string;
  numberOfVotes: number;
}

// 各ユーザーの選択がリストでサーバーから渡ってくる
// チャート表示のため、各選択肢をカウントして集計、ソートして返す
function aggregate(result: MultiChoiceSurveyResult): AggregatedResult[] {
  // 「選択肢ID：文章」の関係を作成
  const mapChoiceIdAndChoiceText = new Map<string, string>();
  for (const choice of result.question.choiceList) {
    mapChoiceIdAndChoiceText.set(choice.id, choice.text);
  }
  // 選択肢IDでアクセスしたいのでMapを使用して集計
  // 「選択肢ID: 得票数」の関係を考える
  const aggregatedValues = new Map<string, number>();
  for (const answer of result.answers) {
    // 重みがけに子供の数を取得
    const weight: number = result.question.weightsNumberOfChildren
      ? answer.numberOfChildren
      : 1;
    answer.choiceIds.forEach((choiceId: string) => {
      // この選択肢に対する現在の統計を取得
      // 空の場合もあり得る
      const aggregatedValue: number | undefined =
        aggregatedValues.get(choiceId);
      // choiceId毎に加算＆ユーザー追加していく
      // 初出(undefined)なら現在0票ということ
      const currentValue: number = aggregatedValue ?? 0;
      // 重みがけした値を加算＆ユーザーリストへ追加する
      aggregatedValues.set(choiceId, currentValue + weight);
    });
  }
  // 「選択肢の文章: 得票数」の関係にする
  // NOTE: Mapはmapメソッドが使えないので配列を初期化して追加する形式にしている
  const reshapedAnswers = new Array<AggregatedResult>();
  aggregatedValues.forEach((numberOfVotes: number, choiceId: string) => {
    // 空文字にはなり得ないが文法上必要なので
    const choiceText: string = mapChoiceIdAndChoiceText.get(choiceId) ?? "";
    reshapedAnswers.push({
      choiceText: choiceText,
      numberOfVotes,
    });
  });
  // 得票数を降順にソートして返す
  return reshapedAnswers.sort((a, b) => b.numberOfVotes - a.numberOfVotes);
}

export function MultiChoiceSurveyResultField({
  surveyResult,
}: {
  surveyResult: MultiChoiceSurveyResult;
}): JSX.Element {
  // チャート表示用に各選択肢に何票入ったかを集計する
  const aggregatedResults: AggregatedResult[] = aggregate(surveyResult);
  // 割合で出したいので総得票数を計算
  const totalOfVotes = aggregatedResults.reduce(
    (acc, aggregatedResult) => acc + aggregatedResult.numberOfVotes,
    0
  );
  // ０票の時は割れないのでチャートを出さない
  const showChart: boolean = totalOfVotes > 0;

  return (
    <SurveyResultContainer>
      <Label size="s" marginBottom={4}>
        質問
      </Label>
      <QuestionTitle>{surveyResult.question.text}</QuestionTitle>
      <Label size="s" marginBottom={4}>
        結果
      </Label>
      {/* グラフの前に各選択肢内容を先に示す */}
      <OptionContainer>
        {aggregatedResults.map(
          (aggregatedResult: AggregatedResult, index: number) => {
            return (
              <Option key={index} color={colors[index]}>
                {aggregatedResult.choiceText}
              </Option>
            );
          }
        )}
      </OptionContainer>
      <Chart>
        {showChart &&
          aggregatedResults.map(
            (aggregatedResult: AggregatedResult, index: number) => {
              const numberOfVotes: number = aggregatedResult.numberOfVotes;
              return (
                <ChartItem
                  key={index}
                  color={colors[index]}
                  flex={numberOfVotes}
                  isFirst={index === 0}
                  isLast={index === aggregatedResults.length - 1}
                >
                  <span>
                    {Math.round((numberOfVotes / totalOfVotes) * 100)}%
                  </span>
                </ChartItem>
              );
            }
          )}
        {!showChart && <div>まだ投票されていません</div>}
      </Chart>
    </SurveyResultContainer>
  );
}

const OptionContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  row-gap: 4px;
  column-gap: 16px;
  margin-bottom: 4px;
`;

const Option = styled.li<{
  color?: string;
}>`
  /* text-style */
  color: rgba(52, 55, 65, 1);
  font-size: 14px;
  /* list-style */
  list-style-type: none;
  /* other-style */
  display: inline-block;
  position: relative;
  vertical-align: middle;
  /* dot-style */
  ::before {
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 8px;
    height: 8px;
    background: ${({ color }) => color || colors[0]};
    border-radius: 50%;
    margin-right: 8px;
  }
`;

const Chart = styled.div`
  display: flex;
`;

const ChartItem = styled.div<{
  color?: string;
  flex: number;
  isFirst?: boolean;
  isLast?: boolean;
}>`
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: bold;
  background-color: ${({ color }) => color || colors[0]};
  flex: ${({ flex }) => flex};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isFirst }) => isFirst && "border-radius: 8px 0 0 8px;"}
  ${({ isLast }) => isLast && "border-radius: 0 8px 8px 0;"}
  ${({ isFirst, isLast }) =>
    isFirst && isLast && "border-radius: 8px; !important;"}
`;
