import React from "react";
import { usePolyfitHistory } from "src/hooks/router";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { Avatar } from "./Avatar";
import { RecruitmentAccountList } from "@shared/types/boardEducation/recruitment";
import styled from "styled-components";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

type Props = {
  accountList: RecruitmentAccountList[] | null;
};

export const AccountListTable = ({ accountList }: Props) => {
  const history = usePolyfitHistory();
  return (
    <>
      <NameListTable>
        <NameListColumnNameRow head={true}>
          <NameListColumn>一覧</NameListColumn>
        </NameListColumnNameRow>
        <ScrollWrapper>
          {(accountList?.length === 0 || accountList === null) && (
            <NonUserNameListColumnNameRow>
              <Name>該当するユーザーがいません</Name>
            </NonUserNameListColumnNameRow>
          )}
          {accountList?.map((list) => (
            <NameListColumnNameRow
              key={list.accountId}
              onClick={() => {
                history.push({
                  to: "BE_ADMIN_MEMBER_DETAIL",
                  query: {
                    accountId: list.accountId,
                    communityId: list.communityId,
                  },
                });
              }}
            >
              <Avatar
                src={list.account?.user.picture || defaultUserImg}
                alt={list.account?.user.name || ""}
                size={40}
              />
              <Name>{list.account?.user.name}</Name>
            </NameListColumnNameRow>
          ))}
        </ScrollWrapper>
      </NameListTable>
    </>
  );
};

const ScrollWrapper = styled.div`
  max-height: 180px;
  overflow-y: auto;
`;

const NameListTable = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #e3e6eb;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
`;

const NameListColumn = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
  background-color: #f5f7fa;
`;

const NonUserNameListColumnNameRow = styled.div`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  border-top: 1px solid #e3e6eb;
  border-left: none;
  border-right: none;
  border-bottom: none;
  justify-content: flex-start;
  background-color: #fff;
  border-collapse: collapse;
`;

const Name = styled.p`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: center;
`;

const NameListColumnNameRow = styled.div<{
  head?: boolean;
}>`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  border-top: 1px solid #e3e6eb;
  border-left: none;
  border-right: none;
  border-bottom: none;

  justify-content: flex-start;
  background-color: #fff;
  border-collapse: collapse;
  cursor: pointer;
  &:hover {
    filter: brightness(95%);
  }
  ${(props) =>
    props.head &&
    `
    padding: 0px;
    pointer-events: none;
    border: none;
    background-color: #f5f7fa;
    font-weight: bold;
    color: #343741;
    &:hover {
      filter: brightness(100%);
    }
    `};
`;
