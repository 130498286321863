import React from "react";
import styled from "styled-components";
import { PostType } from "../../hooks/api/post";
import { Margin } from "../../components/Margin";
import { MobileViewOnly, PcViewOnly } from "../../components/Responsive";
import { PostHistoryButtons } from "./PostHistoryButtons";
import { PostHistoryContent } from "./PostHistoryContent";

type props = {
  post: PostType;
  type: "default" | "me" | "unapproved" | "undelivered";
  onPostApproved?: (post: PostType) => void;
  refetch: () => void;
};
export const PostHistory = ({ post, type, onPostApproved, refetch }: props) => {
  if (type === "default") return <></>;
  return (
    <>
      <PcViewOnly>
        <Wrapper>
          <Header>
            <Title>履歴</Title>
            <PostHistoryButtons
              post={post}
              type={type}
              onPostApproved={onPostApproved}
              refetch={refetch}
            />
          </Header>
          <Margin marginTop={16} />
          <PostHistoryContent post={post} />
        </Wrapper>
      </PcViewOnly>
      <MobileViewOnly>
        <Wrapper>
          <Header>
            <Title>履歴</Title>
          </Header>
          <Margin marginTop={16} />
          <PostHistoryContent post={post} />
          <Margin marginTop={16} />
          <PostHistoryButtons
            post={post}
            type={type}
            onPostApproved={onPostApproved}
            refetch={refetch}
          />
        </Wrapper>
      </MobileViewOnly>
    </>
  );
};

const Wrapper = styled.div`
  background: #f5f7fa;
  border-radius: 4px;
  padding: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #343741;
`;
