import React, { useState } from "react";
import styled from "styled-components";
import { zIndexes } from "../../zIndex";
import { AddGroupModal } from "./AddGroupModal";
import { Member } from "../../components/SearchMembersField";
import { EditGroupSelectedModal } from "./EditGroupSelectedModal";
import { usePostUserGroupList } from "../../hooks/recoil/postUserGroup";
import { colorsPallet } from "../../theme";
import { DeleteGroupModal } from "./DeleteGroupModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  members: Member[];
  isGroupExist: boolean;
};

export const GroupMenuModal = ({
  isOpen,
  onClose,
  members,
  isGroupExist,
}: Props) => {
  const [isOpenAddGroupModal, setIsOpenAddGroupModal] = useState(false);
  const [isOpenEditGroupSelectedModal, setIsOpenEditGroupSelectedModal] =
    useState(false);
  const [isOpenDeleteGroupModal, setIsOpenDeleteGroupModal] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<string>("");
  const { postGroups, getPostGroups } = usePostUserGroupList();

  return (
    <>
      {isOpen && (
        <Container>
          <ModalWrapper>
            <MenuList>
              <MenuItem onClick={() => setIsOpenAddGroupModal(true)}>
                新規作成
              </MenuItem>
              {isGroupExist && (
                <>
                  <MenuItem
                    onClick={() => {
                      setIsOpenEditGroupSelectedModal(true);
                    }}
                  >
                    グループ編集
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setIsOpenDeleteGroupModal(true);
                    }}
                    color={colorsPallet.danger}
                  >
                    グループ削除
                  </MenuItem>
                </>
              )}
            </MenuList>
          </ModalWrapper>
          <ModalBackground onClick={() => onClose()} />
          <AddGroupModal
            isOpen={isOpenAddGroupModal}
            onClose={() => setIsOpenAddGroupModal(false)}
            members={members}
            getPostGroups={getPostGroups}
          />
          <EditGroupSelectedModal
            isOpen={isOpenEditGroupSelectedModal}
            onClose={() => setIsOpenEditGroupSelectedModal(false)}
            members={members}
            selectedGroupId={selectedGroupId}
            setSelectedGroupId={setSelectedGroupId}
            setIsOpenEditGroupSelectedModal={setIsOpenEditGroupSelectedModal}
            postGroups={postGroups}
            getPostGroups={getPostGroups}
          />
          <DeleteGroupModal
            isOpen={isOpenDeleteGroupModal}
            onClose={() => setIsOpenDeleteGroupModal(false)}
            postGroups={postGroups}
            getPostGroups={getPostGroups}
          />
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  position: relative;
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: ${zIndexes.modal};
  background: #ffffff;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const MenuList = styled.ul`
  list-style: none;
`;

const MenuItem = styled.li<{
  color?: string;
}>`
  padding: 16px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: #e4e6f3;
  }
  color: ${(props) => props.color || "#343741"};
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${zIndexes.modalBackground};
`;
