import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { LinkBar } from "../../../features/LinkList/LinkBar";
import { LinkListHeader } from "../../../features/LinkList/LinkListHeader";
import { LinkModal } from "../../../features/LinkList/LinkModal";
import { NotFoundLink } from "../../../features/LinkList/NotFoundLink";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { useGetOrganizationLinks } from "../../../hooks/api/link";
import { isPtaAdminRole } from "../../../utils/types/role";

export default function LinkListPage() {
  const user = useCurrentUser();
  const [isOpenLinkModal, setIsOpenLinkModal] = useState(false);
  const { links, getOrganizationLinks } = useGetOrganizationLinks();

  useEffect(() => {
    const fetcOrganizationLinks = async () => {
      await getOrganizationLinks();
    };
    fetcOrganizationLinks();
  }, []);

  const handleClose = useCallback(async () => {
    setIsOpenLinkModal(false);
    await getOrganizationLinks();
  }, []);

  return (
    <StyledPageWrapper>
      <LinkListHeader
        isAdmin={isPtaAdminRole(user.role)}
        openModal={() => setIsOpenLinkModal(true)}
      />
      <LinkBarsWrapper>
        {links && links.length < 1 ? (
          <NotFoundLink />
        ) : (
          links &&
          links.map((link, index) => (
            <LinkBar
              {...link}
              key={index}
              isAdmin={isPtaAdminRole(user.role)}
              refetch={getOrganizationLinks}
            />
          ))
        )}
      </LinkBarsWrapper>

      <LinkModal
        isOpen={isOpenLinkModal}
        onClose={handleClose}
        type="create"
        link={null}
      />
    </StyledPageWrapper>
  );
}

const LinkBarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  @media (max-width: 1279px) {
    margin-bottom: 100px;
  }
`;
