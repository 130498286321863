import { useEffect, useState } from "react";
import * as chatRoomApi from "src/apiClients/boardEducation/chatRoom";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import { ChatRoomEntity } from "@shared/types/boardEducation/chatRoom";
import { useWebSocket } from "src/hooks/recoil/socket";
import { EVENT_NAMES } from "@shared/types/webSocket";

export const useSelectedChatRoom = () => {
  const socket = useWebSocket();
  const [chatRoom, setChatRoom] = useState<ChatRoomEntity | null>();
  const [isLoading, setIsLoading] = useState(true);
  const { chatRoomId } = usePolyfitLocationQuery("BE_CHATROOM_LIST", {});
  const history = usePolyfitHistory();
  const { tab } = usePolyfitLocationQuery("BE_CHATROOM_LIST", {});

  useEffect(() => {
    fetchChatRoomById();
  }, [chatRoomId]);

  useEffect(() => {
    if (!socket.instance) return;

    const handleUpdatedChatRoom = async (data: { chatRoomId: string }) => {
      if (data.chatRoomId === chatRoomId) {
        await fetchChatRoomById();
      }
    };

    const handleDeletedChatRoom = (data: { chatRoomId: string }) => {
      if (data.chatRoomId === chatRoomId) {
        clearSelectedChatRoom();

        history.replace({
          to: "BE_CHATROOM_LIST",
          query: { tab },
        });
      }
    };

    socket.instance.on(EVENT_NAMES.UPDATED_CHAT_ROOM, handleUpdatedChatRoom);
    socket.instance.on(EVENT_NAMES.DELETED_CHAT_ROOM, handleDeletedChatRoom);

    return () => {
      socket.instance?.off(
        EVENT_NAMES.UPDATED_CHAT_ROOM,
        handleUpdatedChatRoom
      );
      socket.instance?.off(
        EVENT_NAMES.DELETED_CHAT_ROOM,
        handleDeletedChatRoom
      );
    };
  }, [socket.isInitialized, chatRoomId]);

  const fetchChatRoomById = async () => {
    if (chatRoomId == undefined) return;
    const chatRoom = await chatRoomApi.fetchChatRoomById(chatRoomId);
    setChatRoom(chatRoom);
    setIsLoading(false);
  };

  const clearSelectedChatRoom = () => {
    setChatRoom(null);
  };

  return {
    chatRoom,
    isLoading,
    refetch: fetchChatRoomById,
    clear: clearSelectedChatRoom,
  };
};
