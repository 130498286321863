import { createRoute } from "..";
import EmptyLayout from "../../layouts/EmptyLayout";
import SignUpCompleteMemberPage from "../../pages/registration/pta/SignUpCompleteMemberPage";
import EmailVerification from "../../pages/registration/EmailVerification";
import IndexPage from "../../pages/IndexPage";
import { LoadingPage } from "../../pages/LoadingPage";
import SignupCompleteResidentPage from "../../pages/registration/resident/SignupCompleteResidentPage";
import { SignupCompleteResidentStartOrganizationChatPage } from "../../pages/registration/resident/SignupCompleteResidentStartOrganizationChatPage";
import AppRegistrationLayout from "../../layouts/AppRegistrationLayout";

/**
 * FirebaseAuthのログインセッションが必要なページ
 */
export const AUTHENTICATED_ROUTE_DEFS = {
  SIGNUP_EMAIL_VERIFICATION: createRoute<{ token?: string }>({
    path: "/signup_email_verification",
    title: "",
    component: EmailVerification,
    layout: EmptyLayout,
    route: "AUTHENTICATED",
  }),
  SIGNUP_COMPLETE_MEMBER: createRoute({
    path: "/signup_complete_member",
    title: "",
    component: SignUpCompleteMemberPage,
    layout: EmptyLayout,
    route: "AUTHENTICATED",
  }),
  ROOT: createRoute({
    path: "/",
    title: "",
    component: IndexPage,
    layout: EmptyLayout,
    route: "AUTHENTICATED",
  }),
  LOADING: createRoute<{ token?: string; communityId?: string }>({
    path: "/loading",
    title: "",
    component: LoadingPage,
    layout: EmptyLayout,
    route: "AUTHENTICATED",
  }),
  SIGNUP_COMPLETE_RESIDENT: createRoute({
    path: "/signup_complete_resident",
    title: "",
    component: SignupCompleteResidentPage,
    layout: EmptyLayout,
    route: "AUTHENTICATED",
  }),
  SIGNUP_COMPLETE_RESIDENT_START_ORGANIZATION_CHAT: createRoute<{
    token: string;
  }>({
    path: "/signup_complete_resident_start_organization_chat",
    title: "",
    component: SignupCompleteResidentStartOrganizationChatPage,
    layout: AppRegistrationLayout,
    route: "AUTHENTICATED",
  }),
};

export const authenticatedRouters = Object.values(AUTHENTICATED_ROUTE_DEFS);
