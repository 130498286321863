import React from "react";
import { Margin } from "src/components/Margin";
import styled from "styled-components";
import {
  ModalPortal,
  ModalHeader,
  ModalSubmitButtons,
} from "src/components/Modal";
import { deleteChatRoom } from "src/apiClients/boardEducation/chatRoom";
import { ChatRoom } from "@shared/types/chatRoom";

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  chatRoom: ChatRoom;
}

export const DeleteChatRoomModal = (props: Props) => {
  const onSubmit = async () => {
    await deleteChatRoom(props.chatRoom.id);
    props.onSubmit();
  };

  return (
    <ModalPortal onClose={props.onClose}>
      <ModalHeader>
        <p>グループ削除</p>
      </ModalHeader>
      <ModalBody>
        <div>{`${props.chatRoom.name} を削除してもよろしいですか？\n削除した後は全てのメッセージが見れなくなります。`}</div>
        <Margin marginBottom={10} />
      </ModalBody>
      <ModalSubmitButtons
        submitText="削除"
        submitColor="danger"
        onSubmit={onSubmit}
        onCancel={props.onClose}
        cancelColor="subPrimary"
      />
    </ModalPortal>
  );
};

const ModalBody = styled.div`
  min-height: 50px;
  height: fit-content;
  white-space: pre-wrap;
`;
