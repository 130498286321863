import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { updateTeacherInfo } from "../../../apiClients/auth";
import {
  EditModalFormLabel,
  ModalWrapper,
  ErrorMessage,
  SelectField,
} from "../../../pages/pta/admin/ProfilePage";
import { Button } from "../../../components/Button";
import { Margin } from "../../../components/Margin";
import { ModalBody, ModalHeader, ModalPortal } from "../../../components/Modal";
import { useToast } from "../../../components/Toast";
import styled from "styled-components";
import { classOption } from "../../../utils/getChildClass";
import { ChildClass } from "../../../apiClients/child";

type Props = {
  onClose: () => void;
  teacherInfo: { grade: number; class: ChildClass } | null | undefined;
  fetch: () => void;
};

type FormValues = {
  grade: number;
  class: ChildClass;
};

export const TeacherInfoEditModal = ({
  onClose,
  teacherInfo,
  fetch,
}: Props) => {
  const toast = useToast();
  const [hasHomeroom, setHasHomeroom] = useState<boolean | undefined>();

  const homeroomOption = [
    { value: "true", text: "担任あり" },
    { value: "false", text: "担任なし" },
  ];

  const gradeOption = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
    { value: "6", text: "6" },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      grade:
        teacherInfo !== null && teacherInfo !== undefined
          ? teacherInfo.grade
          : undefined,
      class:
        teacherInfo !== null && teacherInfo !== undefined
          ? teacherInfo.class
          : undefined,
    },
    mode: "onBlur",
  });

  useEffect(() => {
    setHasHomeroom(teacherInfo !== null);
  }, [teacherInfo]);

  const onSubmit = async (data: any) => {
    await updateTeacherInfo(hasHomeroom ? data : null)
      .then(() => {
        toast.success("担任情報を更新しました");
        fetch();
      })
      .catch(() => {
        toast.error("担任情報の更新に失敗しました");
      });

    onClose();
  };
  return (
    <ModalPortal onClose={onClose} modalBoxIsHeightFull={true}>
      <ModalWrapper>
        <ModalHeader>担任情報</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <EditModalFormLabel>担任の有無</EditModalFormLabel>
              <SelectField
                value={(hasHomeroom ?? false)?.toString()}
                onChange={(e) => {
                  setHasHomeroom(e.target.value === "true");
                }}
              >
                {homeroomOption.map((opt) => {
                  return (
                    <option key={opt.value} value={opt.value}>
                      {opt.text}
                    </option>
                  );
                })}
              </SelectField>
            </div>

            {hasHomeroom && (
              <CheckTabs>
                <div>
                  <EditModalFormLabel>学年</EditModalFormLabel>
                  <SelectField
                    {...register(`grade` as const, {
                      required: "入力必須項目です",
                      setValueAs: (v) =>
                        v === "" ? undefined : parseInt(v, 10),
                    })}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      選択してください
                    </option>
                    {gradeOption.map((opt) => {
                      return (
                        <option key={opt.value} value={opt.value}>
                          {opt.text}
                        </option>
                      );
                    })}
                  </SelectField>
                  {errors?.grade && (
                    <ErrorMessage>{errors?.grade?.message}</ErrorMessage>
                  )}
                </div>
                <div>
                  <EditModalFormLabel>クラス</EditModalFormLabel>
                  <SelectField
                    {...register(`class` as const, {
                      required: "入力必須項目です",
                      setValueAs: (v) =>
                        v === "" ? undefined : parseInt(v, 10),
                    })}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      選択してください
                    </option>
                    {classOption.map((opt) => {
                      return (
                        <option key={opt.value} value={opt.value}>
                          {opt.text}
                        </option>
                      );
                    })}
                  </SelectField>
                  {errors?.class && (
                    <ErrorMessage>{errors?.class?.message}</ErrorMessage>
                  )}
                </div>
              </CheckTabs>
            )}
            <Margin marginTop={25} />
            <Flex>
              <Button
                type="button"
                color="primary"
                empty
                size="large"
                onClick={onClose}
              >
                キャンセル
              </Button>
              <Button type="submit" color="primary" fill size="large">
                保存
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalWrapper>
    </ModalPortal>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
`;

const CheckTabs = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  > * {
    width: 100%;
  }
`;
