import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "./QueryOptions";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import {
  currentOrganization,
  getOrganizationByRecruitmentId,
} from "src/apiClients/organization";
import { Organization } from "@shared/types/organization";

export const useGetCurrentOrganization = (queryOptions: QueryOptions = {}) => {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "organization", "currentOrganization", apiContext],
    queryFn: async () => {
      return await currentOrganization();
    },
    ...queryOptions,
  });

  return {
    organization: useMemo(() => query.data, [query.data]),
    ...query,
  };
};

export const useGetOrganizationByRecruitmentId = (
  recruitmentId: string,
  queryOptions: QueryOptions = {}
) => {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: [
      "api",
      "organization",
      "getByRecruitmentId",
      recruitmentId,
      apiContext,
    ],
    queryFn: async () => {
      return await getOrganizationByRecruitmentId(recruitmentId);
    },
    ...queryOptions,
  });

  return {
    organization: useMemo(() => query.data, [query.data]) as Organization,
    ...query,
  };
};
