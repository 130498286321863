import styled from "styled-components";
import { PostType } from "../../hooks/api/post";
import React from "react";
import { Avatar } from "../../components/Common/Avatar";
import { toDateFormat } from "../../utils/time";

export const PostUserInfo = ({ post }: { post: PostType }) => {
  /**
   * 投稿日時を表示する
   * - 予約投稿の場合は予約日時を表示
   */
  const displayPostDate = post.scheduledSendAt ?? post.updatedAt;
  return (
    <>
      <PCWrapper>
        <Avatar size={25} src={post.user.picture ?? ""} alt={post.user.name} />
        <CareerText>
          {post.user.baseInfo?.belong?.name || "所属なし"}{" "}
          {post.user.baseInfo?.part?.name || "役職なし"}
        </CareerText>
        <StyledText>
          {post.user.baseInfo?.lastName + " " + post.user.baseInfo?.firstName}
        </StyledText>
        <VerticalLine />
        <StyledText>{toDateFormat(displayPostDate)}</StyledText>
      </PCWrapper>

      <SPWrapper>
        <Avatar size={25} src={post.user.picture ?? ""} alt={post.user.name} />
        <div>
          <CareerText>
            {post.user.baseInfo?.belong?.name || "所属なし"}{" "}
            {post.user.baseInfo?.part?.name || "役職なし"}
          </CareerText>
          <StyledText>
            {post.user.baseInfo?.lastName + " " + post.user.baseInfo?.firstName}
          </StyledText>
          <StyledText>{toDateFormat(displayPostDate)}</StyledText>
        </div>
      </SPWrapper>
    </>
  );
};

const SPWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (min-width: 461px) {
    display: none;
  }
`;

const PCWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 460px) {
    width: 100%;
    display: none;
  }
`;

const StyledText = styled.span`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  color: #69707d;

  @media (max-width: 460px) {
    font-size: 12px;
    display: block;
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 14px;
  background-color: #d3dae6;

  @media (max-width: 460px) {
    display: none;
  }
`;

const CareerText = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  color: #69707d;

  @media (max-width: 460px) {
    font-size: 12px;
  }
`;
