import React, { useEffect } from "react";
import { useCurrentUserHook } from "../hooks/recoil/user";
import { usePolyfitHistory, usePolyfitLocationQuery } from "../hooks/router";
import {
  inBoardEducation,
  inCommunitySchool,
  inPTA,
} from "../apiClients/users";
import { setCurrentAccount } from "../apiClients/auth";
import { useGetOrGenerateInvitation } from "../hooks/recoil/invitation";
import {
  isCsAdminRole,
  isCsGuestRole,
  isPtaAdminRole,
} from "../utils/types/role";
import { useOrganization } from "../hooks/recoil/organization";
import { logout } from "../utils/auth";
import { useFirebaseUserContext } from "../hooks/context/firebaseUser";
import { validateInvitationToken } from "../apiClients/invitation";
import { getFirstCommunityId } from "src/features/Member/Community/utils/utils";
import { logger } from "src/utils/logger";

/**
 * ログイン直後にこの画面に遷移し、適切な画面にリダイレクトする
 * Loading画面になっているが実際Loadingはしてなくてユーザーに違和感のない名前にしているだけ
 */
export function LoadingPage() {
  const { firebaseUser } = useFirebaseUserContext();
  const { user, loading } = useCurrentUserHook();
  const history = usePolyfitHistory();
  const { fetchOrganization } = useOrganization({ skip: true });
  const { fetchInvitation } = useGetOrGenerateInvitation({ skip: true });
  const { token } = usePolyfitLocationQuery("LOADING", {
    token: undefined,
  });
  const currentCommunityId = (() => {
    const searchParams = new URLSearchParams(location.search);
    const communityId = searchParams.get("communityId");
    const roleId = user.account?.roleId;
    const firstCommunityId = getFirstCommunityId(
      user.account?.communityRoles ?? [],
      roleId
    );
    return communityId ?? firstCommunityId;
  })();

  useEffect(() => {
    const handleRedirection = async () => {
      if (!firebaseUser || loading || !user) {
        console.log("waiting for loading...");
        return;
      }

      // PTA/CS/BEチェック
      const isPTA = inPTA(user);
      const isCommunitySchool = inCommunitySchool(user);
      const isBoardEducation = inBoardEducation(user);

      // 登録
      const isEmailVerified = firebaseUser?.emailVerified; // メール認証済み
      const isRegistering = isEmailVerified && !user?.signUpState; // 登録完了前
      const isRegistered = isEmailVerified && user?.signUpState && !user?.able; // 登録済み && 承認待ち
      const isSignupComplete =
        isEmailVerified && user?.signUpState && user?.able; // 登録済み && 承認済み

      if (!user.account) {
        await logout();
        return;
      }
      if (!isEmailVerified) {
        history.push({ to: "SIGNUP_EMAIL_VERIFICATION", query: {} });
      } else if (isPTA && isRegistering) {
        if (isPtaAdminRole(user.role)) {
          history.push({ to: "REGISTER_ORGANIZATION" });
        } else {
          history.push({ to: "REGISTER_USER_TYPE" });
        }
      } else if (isPTA && isRegistered) {
        history.push({ to: "SIGNUP_COMPLETE_MEMBER" });
      } else if (isPTA && isSignupComplete) {
        await setCurrentAccount(user.id, user.account?.organizationId ?? "");
        await fetchOrganization();
        await fetchInvitation();
        history.push({
          to: "PTA_INDEX",
          query: { communityId: currentCommunityId },
        });
      } else if (isCommunitySchool && isCsGuestRole(user.role)) {
        // 連絡用QRコードのルート
        if (token) {
          try {
            const res = await validateInvitationToken({ token });
            if (res.valid && res.flow === "ORGANIZATION_CHAT") {
              history.push({
                to: "RESIDENT_ORGANIZATION_CHAT_REGISTRATION_HANDLER",
                query: { token },
              });
              return;
            }
          } catch (err) {
            logger.error(err);
          }
        }
        history.push({ to: "RESIDENT_CHAT", query: {} });
      } else if (isCommunitySchool && isRegistering) {
        if (isCsAdminRole(user.role)) {
          history.push({ to: "RESIDENT_REGISTER_ORGANIZATION" });
        } else {
          // 連絡用QRコードのルート
          if (token) {
            try {
              const res = await validateInvitationToken({ token });
              if (res.valid && res.flow === "ORGANIZATION_CHAT") {
                history.push({
                  to: "RESIDENT_REGISTER_INFO",
                  query: { token },
                });
                return;
              }
            } catch (err) {
              logger.error(err);
            }
          }
          history.push({ to: "RESIDENT_REGISTER_DESCRIPTION" });
        }
      } else if (isCommunitySchool && isRegistered) {
        history.push({ to: "SIGNUP_COMPLETE_RESIDENT" });
      } else if (isCommunitySchool && isSignupComplete) {
        await setCurrentAccount(user.id, user.account?.organizationId ?? "");
        await fetchOrganization();
        await fetchInvitation();
        if (isCsAdminRole(user.role)) {
          history.push({
            to: "PTA_LIST",
            query: { communityId: currentCommunityId },
          });
        } else {
          // 連絡用QRコードのルート
          if (token) {
            try {
              const res = await validateInvitationToken({ token });
              if (res.valid && res.flow === "ORGANIZATION_CHAT") {
                history.push({
                  to: "RESIDENT_ORGANIZATION_CHAT_REGISTRATION_HANDLER",
                  query: { token },
                });
                return;
              }
            } catch (err) {
              logger.error(err);
            }
          }
          history.push({
            to: "RESIDENT_RECRUITMENT_LIST",
            query: { communityId: currentCommunityId },
          });
        }
      } else if (isBoardEducation && (isRegistering || isRegistered)) {
        // TODO 未実装
      } else if (isBoardEducation && isSignupComplete) {
        history.push({ to: "BE_ADMIN_MEMBER_LIST" });
      } else {
        console.log("maybe loading error, please reload page.");
      }
    };

    handleRedirection();
  }, [firebaseUser, loading, user]);

  return <></>;
}
