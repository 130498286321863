import * as client from "./client";
import {
  InvitationBEUserList,
  InvitationBEUserListRequest,
} from "../../../server/src/@shared/types/invitationBEUserList";

/**
 * 招待中の教育委員会ユーザー取得する（Internal用）
 */
export async function getInvitationBEUsersByOrganizationId({
  organizationId,
}: {
  organizationId: string;
}): Promise<InvitationBEUserList[]> {
  const res = await client.get<
    InvitationBEUserListRequest,
    { invitationBEUsers: InvitationBEUserList[] }
  >("/invitationBeUser/list", {
    organizationId,
  });
  return res.invitationBEUsers;
}
