import React from "react";

export const GlyphIcon = () => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50528 9.49124L13.9585 3.05216L12.4585 1.55216L6.00529 7.99123L7.50528 9.49124ZM11.9683 0.686593C12.226 0.44866 12.6252 0.427176 12.8772 0.686593L14.8058 2.61516C15.0571 2.86588 15.0571 3.27345 14.8058 3.52416L8.02587 10.3082C7.90566 10.429 7.74174 10.4965 7.57138 10.4965H5.64285C5.28799 10.4965 5 10.2085 5 9.85365V7.92508C5 7.75472 5.0675 7.59079 5.18835 7.47058L11.9683 0.686593ZM3.5 13.4965C3.22386 13.4965 3 13.2726 3 12.9965C3 12.7204 3.22386 12.4965 3.5 12.4965H10.5C10.7761 12.4965 11 12.7204 11 12.9965C11 13.2726 10.7761 13.4965 10.5 13.4965H3.5ZM7.5 1.49651C7.77614 1.49651 8 1.72036 8 1.99651C8 2.27265 7.77614 2.49651 7.5 2.49651H1V15.4965H13V7.99651C13 7.72036 13.2239 7.49651 13.5 7.49651C13.7761 7.49651 14 7.72036 14 7.99651V15.4965C14 16.0488 13.5523 16.4965 13 16.4965H1C0.447715 16.4965 0 16.0488 0 15.4965V2.49651C0 1.94422 0.447715 1.49651 1 1.49651H7.5Z"
        fill="#69707D"
      />
    </svg>
  );
};
