import { useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import * as invitationUsersApi from "../../../../apiClients/invitationBEUser";
import { QueryOptions } from "src/hooks/query/QueryOptions";

export function useInvitationBEUserListByOrganizationId(
  organizationId: string,
  queryOptions: QueryOptions = {}
) {
  const query = useQuery({
    queryKey: [
      "api",
      "users",
      "getInvitationBEUsersByOrganizationId",
      organizationId,
    ],
    queryFn: () =>
      invitationUsersApi.getInvitationBEUsersByOrganizationId({
        organizationId,
      }),
    ...queryOptions,
  });

  return {
    invitationBEUsers: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
}

export function useInvalidateInvitationBEUserListByOrganizationId() {
  const queryClient = useQueryClient();

  function invalidateInvitationBEUserListByOrganizationId(
    organizationId: string
  ) {
    queryClient.invalidateQueries({
      queryKey: [
        "api",
        "users",
        "getInvitationBEUsersByOrganizationId",
        organizationId,
      ],
    });
  }

  return {
    invalidateInvitationBEUserListByOrganizationId,
  };
}
