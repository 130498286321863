import React from "react";
import { v4 as uuidv4 } from "uuid";
import { User } from "../../../apiClients/users";
import {
  isMultiChoiceQuestion,
  isOpenEndedQuestion,
  SURVEY_DEFAULT_DEADLINE,
  MAXIMUM_NUMBER_OF_CHOICES,
} from "../../../apiClients/survey";
import {
  Choice,
  MultiChoiceQuestion,
  OpenEndedQuestion,
  Question,
  QuestionType,
  SurveyQuestions,
  SurveyState,
} from "@shared/types/post/survey/survey";

// stateを扱うハンドラ一覧
export interface EventHandlers {
  // 質問
  handleAddQuestion: () => void;
  handleEditQuestionText: (question: Question) => void; // テキストの編集
  handleEditQuestionType: (question: Question, type: QuestionType) => void; // 種別の編集
  handleEditDescription: (question: Question) => void; // 補足説明テキストの編集
  handleDeleteQuestion: (question: Question) => void;
  // 選択肢
  handleAddChoice: (question: MultiChoiceQuestion) => void;
  handleEditChoice: (question: MultiChoiceQuestion, choice: Choice) => void;
  handleDeleteChoice: (question: MultiChoiceQuestion, choice: Choice) => void;
  // 「子供複数世帯を複数票として扱う」の変更
  handleEditWeightsNumberOfChildren: (
    question: MultiChoiceQuestion,
    flag: boolean
  ) => void;
  // 唯一複数回答フラグの変更
  handleEditAllowMultipleAnswers: (
    question_: MultiChoiceQuestion,
    flag: boolean
  ) => void;
  // アンケート編集状態変更
  handleUpdateSurveyState: (state: SurveyState) => void;
  // アンケート初期化（作成キャンセル時など）
  handleResetSurvey: () => void;
  // 回答期日変更
  handleUpdateDeadLine: (date: Date | null) => void;
}

// 選択式の質問を作成する
// NOTE: 仕様上デフォは選択式を返す
function createNewMultiChoiceQuestion(
  text: string = "",
  description: string = ""
): MultiChoiceQuestion {
  // 選択肢列、初めから二つ与える
  const choiceList: Choice[] = [createNewChoice(), createNewChoice()];
  const newQuestion: MultiChoiceQuestion = {
    id: uuidv4(),
    text,
    description,
    questionType: "MULTI_CHOICE",
    choiceList: choiceList,
    weightsNumberOfChildren: false,
    // TOOD: デフォルトでは唯一回答とする、要確認
    allowMultipleAnswers: false,
  };
  return newQuestion;
}

// 記述式の質問を作成する
function createNewOpenEndedQuestion(
  text: string = "",
  description: string = ""
): OpenEndedQuestion {
  const newQuestion: OpenEndedQuestion = {
    id: uuidv4(),
    text,
    description,
    questionType: "OPEN_ENDED",
  };
  return newQuestion;
}

// 新規選択肢作成のカーネル
function createNewChoice(): Choice {
  const id: string = uuidv4();
  const newChoice: Choice = {
    id: id,
    text: "",
  };
  return newChoice;
}

function getDefaultDeadline(): Date {
  const now: Date = new Date();
  // ミリ秒に直す
  return new Date(now.getTime() + SURVEY_DEFAULT_DEADLINE);
}

// stateが必要な質問リストと付随するイベントハンドラを定義して返す関数
// CreateSurveyで用いられる全てのuseStateをラップする
// NOTE: 既存のアンケートがある場合（ポスト再編集時）は
//       引数（givenSurveyQuestions）で取る
export function useEventHandlers(
  user: User,
  givenSurveyQuestions?: SurveyQuestions
): [SurveyQuestions, EventHandlers] {
  // このアンケートのUUID
  // 1. 引数のアンケートがない場合（新規投稿）
  // 2. 引数のアンケートが未定義の場合（アンケートなしのポスト編集）
  // は新規生成
  const surveyQuestionsId: string =
    givenSurveyQuestions === undefined ||
    givenSurveyQuestions.state === "UNDEFINED"
      ? uuidv4()
      : givenSurveyQuestions.id;
  // post上でのアンケートの状態
  const [surveyState, setSurveyState] = React.useState<SurveyState>(
    // 引数のアンケートがない場合（新規投稿）は未定義とする
    givenSurveyQuestions === undefined
      ? "UNDEFINED"
      : givenSurveyQuestions.state
  );
  // 全ての質問保持と変更を担うobject
  const [questionList, setQuestionList] = React.useState<Question[]>(
    // 1. 引数のアンケートがない場合（新規投稿）
    // 2. 引数のアンケートが未定義の場合（アンケートなしのポスト編集）
    // はデフォで質問をひとつ与える
    givenSurveyQuestions === undefined ||
      givenSurveyQuestions.state === "UNDEFINED"
      ? [createNewMultiChoiceQuestion()]
      : givenSurveyQuestions.questionList
  );
  // 回答期日の保持と変更を担うobject
  const [deadLine, setDeadLine] = React.useState<Date>(
    // 1. 引数のアンケートがない場合（新規投稿）
    // 2. 引数のアンケートが未定義の場合（アンケートなしのポスト編集）
    // は本日を与える
    givenSurveyQuestions === undefined ||
      givenSurveyQuestions.deadLine === undefined ||
      givenSurveyQuestions.state === "UNDEFINED"
      ? getDefaultDeadline()
      : new Date(givenSurveyQuestions.deadLine)
  );
  // 新規質問作成
  const handleAddQuestion = (): void => {
    // 新しく作成し、末尾に追加してセット
    setQuestionList([...questionList, createNewMultiChoiceQuestion()]);
  };
  // 質問テキスト修正
  const handleEditQuestionText = (question_: Question): void => {
    setQuestionList(
      questionList.map((question: Question) => {
        // 質問が指定IDでなければそのまま返す
        if (question_.id !== question.id) {
          return question;
        }
        // 質問が指定IDなら新しい要素（テキスト更新済）を返す
        return question_;
      })
    );
  };
  // 質問種類修正
  const handleEditQuestionType = (
    question_: Question,
    newQuestionType: QuestionType
  ): void => {
    setQuestionList(
      questionList.map((question: Question) => {
        // 質問が指定IDなら質問種別更新
        // NOTE: ここだけ等値比較なのはこうしないとtscがUnion型を見分けてくれないから
        if (question_.id === question.id) {
          // 違う型なので新しいオブジェクトを作成し、質問文と補足説明はコピーする
          if (newQuestionType === "MULTI_CHOICE") {
            return createNewMultiChoiceQuestion(
              question.text,
              question.description
            );
          } else if (newQuestionType === "OPEN_ENDED") {
            return createNewOpenEndedQuestion(
              question.text,
              question.description
            );
          } else {
            // 選択式、記述式以外の種別が追加された
            // 実装が必要
            const _unreachable: never = newQuestionType;
          }
        }
        return question;
      })
    );
  };
  // 補足説明テキスト修正
  // NOTE: 現状はonEditQuestionTextと偶然同じだが責務は異なる
  //       仕様が異なるケースも出てくると思われるので分けておく
  const handleEditDescription = (question_: Question): void => {
    setQuestionList(
      questionList.map((question: Question) => {
        // 質問が指定IDでなければそのまま返す
        if (question_.id !== question.id) {
          return question;
        }
        // 質問が指定IDなら新しい要素（補足説明テキスト更新済）を返す
        return question_;
      })
    );
  };
  // 質問削除
  const handleDeleteQuestion = (question_: Question): void => {
    // この質問が空でなければ警告を出す
    const isEmpty = (question: Question): boolean => {
      if (isMultiChoiceQuestion(question)) {
        if (question.text !== "") {
          return false;
        }
        for (const choice of question.choiceList) {
          if (choice.text !== "") {
            return false;
          }
        }
      } else if (isOpenEndedQuestion(question)) {
        return question.text === "";
      } else {
        // 未実装の質問種別
        const _unreachable: never = question;
      }
      return true;
    };
    if (
      !isEmpty(question_) &&
      !confirm("入力内容が破棄されます。\nこの質問を削除してもよろしいですか？")
    ) {
      return;
    }
    // 削除処理
    setQuestionList(
      questionList.filter((question: Question) => question_.id !== question.id)
    );
  };
  // 選択肢追加
  const handleAddChoice = (question_: MultiChoiceQuestion): void => {
    setQuestionList(
      questionList.map((question: Question) => {
        // 質問が指定IDでなければそのまま返す
        if (question_.id !== question.id) {
          return question;
        }
        // 最大選択肢数に到達していれば追加せずエラーを出す
        // TODO: 頻度は高くないイベントでUXへの影響は少ないと考えられる
        //       とりあえずalert
        if (question_.choiceList.length >= MAXIMUM_NUMBER_OF_CHOICES) {
          alert(`選択肢の最大数は${MAXIMUM_NUMBER_OF_CHOICES}個です`);
          return question_;
        }
        // 指定ID、新しい選択肢を追加
        question_.choiceList = [...question_.choiceList, createNewChoice()];
        return question_;
      })
    );
  };
  // 選択肢テキスト修正
  const handleEditChoice = (
    question_: MultiChoiceQuestion,
    choice_: Choice
  ): void => {
    setQuestionList(
      questionList.map((question: Question) => {
        // 質問が指定IDでなければそのまま返す
        if (question_.id !== question.id) {
          return question;
        }
        // 指定ID、指定された選択肢を編集
        question_.choiceList = question_.choiceList.map((choice: Choice) => {
          // 選択肢が指定IDでなければそのまま返す
          if (choice_.id !== choice.id) {
            return choice;
          }
          // 更新後のテキストは引数に含まれているのでそれを返す
          return choice_;
        });
        return question_;
      })
    );
  };
  // 選択肢削除
  const handleDeleteChoice = (
    question_: MultiChoiceQuestion,
    choice_: Choice
  ): void => {
    setQuestionList(
      questionList.map((question: Question) => {
        // 質問が指定IDでなければそのまま返す
        if (question_.id !== question.id) {
          return question;
        }
        question_.choiceList = question_.choiceList.filter(
          (choice: Choice) => choice_.id !== choice.id
        );
        return question_;
      })
    );
  };
  // 「子供複数世帯を複数票として扱う」の変更
  const handleEditWeightsNumberOfChildren = (
    question_: MultiChoiceQuestion,
    flag: boolean
  ): void => {
    setQuestionList(
      questionList.map((question: Question) => {
        // 質問が指定IDでなければそのまま返す
        if (question_.id !== question.id) {
          return question;
        }
        // チェック状態を変更して返す
        question_.weightsNumberOfChildren = flag;
        return question_;
      })
    );
  };
  // 唯一複数回答フラグの変更
  const handleEditAllowMultipleAnswers = (
    question_: MultiChoiceQuestion,
    flag: boolean
  ): void => {
    setQuestionList(
      questionList.map((question: Question) => {
        // 質問が指定IDでなければそのまま返す
        if (question_.id !== question.id) {
          return question;
        }
        // チェック状態を変更して返す
        question_.allowMultipleAnswers = flag;
        return question_;
      })
    );
  };
  // アンケートの状態変化、キャンセル／下書き保存／作成ボタンを押された時など
  const handleUpdateSurveyState = (newSurveyState: SurveyState): void => {
    setSurveyState(newSurveyState);
  };
  // アンケート情報を初期化する
  const handleResetSurvey: () => void = () => {
    // UNDEFINED（アンケート無し）とする
    setSurveyState("UNDEFINED");
    // デフォの空質問を一つ持つ状態に戻す
    setQuestionList([createNewMultiChoiceQuestion()]);
    // 期日を今日に
    setDeadLine(new Date());
  };
  // 回答期日変更
  const handleUpdateDeadLine = (date: Date | null): void => {
    if (date === null) {
      return;
    }
    setDeadLine(date);
  };
  // 必要なものを全部詰めて返す
  const surveyQuestions: SurveyQuestions = {
    id: surveyQuestionsId,
    userId: user.id,
    state: surveyState,
    questionList: questionList,
    deadLine: deadLine,
  };
  const eventHandlers: EventHandlers = {
    handleAddQuestion,
    handleEditQuestionText,
    handleEditQuestionType,
    handleEditDescription,
    handleDeleteQuestion,
    handleAddChoice,
    handleEditChoice,
    handleDeleteChoice,
    handleEditWeightsNumberOfChildren,
    handleEditAllowMultipleAnswers,
    handleUpdateSurveyState,
    handleResetSurvey,
    handleUpdateDeadLine,
  };
  return [surveyQuestions, eventHandlers];
}
