import React from "react";
import styled from "styled-components";

type Props = {
  onClick?: () => void;
};
export const SpMenuButtonIcon = ({ onClick }: Props) => {
  return (
    <Button type="button" onClick={onClick}>
      <svg
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="20px"
        height="20px"
      >
        <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z" />
      </svg>
    </Button>
  );
};

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media not screen and (max-width: 1024px) {
    display: none;
  }
`;
