import styled from "styled-components";
import * as theme from "../theme";

export const Footer = styled.div`
  margin-top: 16px;
  border-top: 1px solid ${theme.colorsPallet.lightShade};
  color: ${theme.colorsPallet.darkShade};
  padding: 32px 8px;
  display: flex;
  justify-content: center;

  > a {
    color: ${theme.colorsPallet.darkShade};
    &:hover {
      color: ${theme.colorsPallet.darkestShade};
      text-decoration-line: underline;
    }
  }
`;
