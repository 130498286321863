import * as client from "./client";

/**
 * 応募に対してのコメントAPIクライアント
 */

export type RecruitmentAppliedCommentCreateArgsType = {
  recruitmentApplicationId: string;
  comment: string;
  isAlert: boolean;
};

export async function createRecruitmentApplicationComment(
  arg: RecruitmentAppliedCommentCreateArgsType
): Promise<void> {
  await client.post<{ arg: RecruitmentAppliedCommentCreateArgsType }>(
    "/recruitment-comment/create",
    { arg }
  );
}

export async function deleteRecruitmentApplicationComment(
  recruitmentApplicationCommentId: string
): Promise<void> {
  await client.post<{ recruitmentApplicationCommentId: string }>(
    "/recruitment-comment/delete",
    { recruitmentApplicationCommentId }
  );
}

export type RecruitmentApplicationComment = {
  id: string;
  organizationId: string;
  recruitmentApplicationId: string;
  userId: string;
  comment: string;
  isAlert: boolean;
  user: {
    select: {
      id: string;
      name: string;
      picture: string;
    };
  }[];
};
export async function getRecruitmentApplicationComment(
  recruitmentApplicationId: string
): Promise<RecruitmentApplicationComment[]> {
  return await client.get<{}, RecruitmentApplicationComment[]>(
    "/recruitment-application/list",
    {
      recruitmentApplicationId,
    }
  );
}

export type EditRecruitmentApplicationCommentArgs = {
  recruitmentApplicationCommentId: string;
  comment: string;
  isAlert: boolean;
};

export async function editRecruitmentApplicationComment(
  args: EditRecruitmentApplicationCommentArgs
): Promise<void> {
  await client.post<EditRecruitmentApplicationCommentArgs>(
    "/recruitment-comment/edit",
    args
  );
}
