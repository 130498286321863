import { atom } from "recoil";

export type FormEmailPassword = {
  email: string;
  password: string;
  passwordConfirm: string;
};

export const formEmailPasswordState = atom<FormEmailPassword>({
  key: "FormEmailPassword",
  default: {
    email: "",
    password: "",
    passwordConfirm: "",
  },
});
