import React from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import styled from "styled-components";
import { Label } from "src/components/form/Label";
import { ButtonGroupExtend, ButtonSelect } from "src/components/ButtonSelect";
import { Margin } from "src/components/Margin";

type Props = {
  name:
    | "mainElementarySchoolType"
    | "mainJuniorHighSchoolType"
    | "community.mainElementarySchoolType"
    | "community.mainJuniorHighSchoolType";
  fieldError?: FieldError;
  control: Control<any>;
};

export const CommunityUserTypeOptions = [
  { value: "PARENT", text: "保護者" },
  { value: "TEACHER", text: "教職員" },
  { value: "RESIDENT", text: "地域住民" },
] as const;

export const CommunityUserTypeSelect = (props: Props) => {
  const label = /mainElementarySchoolType$/.test(props.name)
    ? "最寄り小学校区での役割"
    : "最寄り中学校区での役割";

  return (
    <RadioInputContainer>
      <Label>{label}</Label>
      <ButtonGroupContainer>
        {CommunityUserTypeOptions.map((option, i) => (
          <Controller
            key={i}
            name={props.name}
            control={props.control}
            render={({ field: { value, onChange } }) => (
              <ButtonSelect
                selected={value === option.value}
                onClick={() => onChange(option.value)}
              >
                <span>{option.text}</span>
              </ButtonSelect>
            )}
          />
        ))}
      </ButtonGroupContainer>
      {props.fieldError ? (
        <ErrorMessage>{props.fieldError.message}</ErrorMessage>
      ) : (
        <Margin marginTop={8} />
      )}
    </RadioInputContainer>
  );
};

const ButtonGroupContainer = styled(ButtonGroupExtend)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const RadioInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #1a1c21;
  font-weight: bold;
  margin-top: 16px;
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;
