import React, { JSX } from "react";
import { Badge } from "../../components/Common/Badge";
import { isSurveyDefined } from "../../apiClients/surveyQuestions";
import { SurveyIcon } from "../../components/icons/SurveyIcon";
import { SurveyQuestions } from "@shared/types/post/survey/survey";

export function showSurveyBadge(
  surveyQuestions: SurveyQuestions | undefined
): boolean {
  // アンケートが定義されているときのみ表示
  if (isSurveyDefined(surveyQuestions)) {
    return true;
  }
  return false;
}

export function SurveyBadge(): JSX.Element {
  return (
    <Badge color="survey">
      <SurveyIcon />
      アンケート
    </Badge>
  );
}
