import React from "react";

interface Props {
  color?: string;
}

export const CloseIcon = ({ color }: Props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.30579 7.91856C5.69725 7.52958 6.32981 7.53125 6.71921 7.92228L9.79773 11.0136C10.1335 11.3508 10.6791 11.3515 11.0158 11.0152C11.3517 10.6796 11.3524 10.1354 11.0174 9.79899L7.93687 6.70565C7.54758 6.31474 7.54841 5.6824 7.93872 5.29252L11.015 2.21956C11.3519 1.88307 11.3547 1.33798 11.0213 0.998036C10.683 0.652992 10.1279 0.65086 9.7869 0.993293L6.72122 4.07174C6.33108 4.4635 5.69708 4.46433 5.30592 4.0736L2.21988 0.990931C1.87818 0.649612 1.32371 0.65247 0.985555 0.997294C0.651775 1.33765 0.654588 1.8834 0.991859 2.2203L4.06545 5.29052C4.45649 5.68113 4.45649 6.3149 4.06545 6.70551L1.01567 9.75194C0.67872 10.0885 0.67872 10.6346 1.01567 10.9712C1.35148 11.3066 1.89531 11.3074 2.232 10.9728L5.30579 7.91856Z"
        fill={color ? color : "#AAB4C4"}
      />
    </svg>
  );
};
