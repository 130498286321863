import React, { JSX } from "react";
import { isMultiChoiceQuestion } from "../../../apiClients/survey";
import { MultiChoiceQuestionForm } from "./MultiChoiceQuestionForm";
import { OpenEndedQuestionForm } from "./OpenEndedQuestionForm";
import { EventHandlers } from "./eventHandlers";
import styled from "styled-components";
import { type Question } from "@shared/types/post/survey/survey";

// 各質問用component
//   この質問削除ボタン
//   質問種類決めるボタン（選択式、記述式）
//   質問記述欄
//   （選択式のみ）選択肢記述欄
//
// question: この質問のプロパティ一覧
// isDeletable: この質問の削除を許すかのフラグ
export function Question({
  question,
  eventHandlers,
  isDeletable,
}: {
  question: Question;
  eventHandlers: EventHandlers;
  isDeletable: boolean;
}): JSX.Element {
  // 表示内容、質問の種類によって変わる
  if (isMultiChoiceQuestion(question)) {
    return (
      <MultiChoiceQuestionForm
        question={question}
        eventHandlers={eventHandlers}
        isDeletable={isDeletable}
      />
    );
  }
  return (
    <OpenEndedQuestionForm
      question={question}
      eventHandlers={eventHandlers}
      isDeletable={isDeletable}
    />
  );
}

export const QuestionContainer = styled.div`
  /* box-style */
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(245, 247, 250, 1);
  /* flex-style */
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* other-style */
  position: relative;
`;

export const QuestionField = styled.div``;
