import React from "react";

export const LocationSpotIcon = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.38125 17.5575C6.63461 18.0642 7.36156 18.0642 7.61492 17.5575L13.1149 6.55746C13.165 6.45723 13.1899 6.34626 13.1873 6.23423C13.1826 6.02761 13.1513 5.69075 13.0723 5.26505C12.9412 4.5594 12.7178 3.85275 12.3773 3.18694C11.3854 1.24765 9.62124 0.0625 7 0.0625C4.37876 0.0625 2.61463 1.24765 1.62274 3.18694C1.28219 3.85275 1.05885 4.5594 0.92775 5.26505C0.84866 5.69075 0.817422 6.02761 0.812681 6.23423C0.81011 6.34626 0.834965 6.45723 0.885081 6.55746L6.38125 17.5575ZM7 4.875C6.24061 4.875 5.625 5.49061 5.625 6.25C5.625 7.00939 6.24061 7.625 7 7.625C7.75939 7.625 8.375 7.00939 8.375 6.25C8.375 5.49061 7.75939 4.875 7 4.875ZM2.19669 6.10608C2.20915 5.95956 2.2347 5.75795 2.27962 5.5162C2.38801 4.93279 2.57208 4.35038 2.84691 3.81306C3.6132 2.31485 4.91916 1.4375 7 1.4375C9.08084 1.4375 10.3868 2.31485 11.1531 3.81306C11.4279 4.35038 11.612 4.93279 11.7204 5.5162C11.7653 5.75795 11.7908 5.95956 11.8033 6.10608L7 15.7127L2.19669 6.10608Z"
        fill="#343741"
      />
    </svg>
  );
};
