import React, { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { getToken } from "../../apiClients/client";
import { useCurrentCommunityId } from "../router";

const WebSocketContext = createContext<{
  instance: Socket | null;
  isInitialized: boolean;
}>({ instance: null, isInitialized: false });

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({
  children,
  isSkipCurrentCommunityId,
}: {
  children: React.ReactNode;
  isSkipCurrentCommunityId: boolean;
}) => {
  const [instance, setSocket] = useState<Socket | null>(null);
  const [isInitialized, setIsSocketInitialized] = useState(false);
  const { communityId } = useCurrentCommunityId();

  useEffect(() => {
    const establishConnection = async () => {
      const token = await getToken();
      if (!token) return;
      if (!isSkipCurrentCommunityId && communityId === "") return;

      // websocketの接続portは3001
      const newSocket = io(location.origin.replace("3000", "3001"), {
        withCredentials: true,
        query: {
          token: `Bearer ${token}`,
          currentCommunityId: communityId,
          isSkipCurrentCommunityId,
        },
        reconnectionDelay: 5000,
        reconnectionAttempts: 5,
        transports: ["websocket"],
      });
      return newSocket;
    };

    const registerEventListeners = (socket: Socket) => {
      socket.on("connect", () => {
        console.log("websocket connected");
        setIsSocketInitialized(true);
      });

      socket.on("tokenRefreshRequired", async () => {
        console.log("token refresh required");
        socket.disconnect();
        initialize();
      });

      socket.on("connect_error", (err) => {
        console.log("websocket connection error:", err);
      });

      socket.on("disconnect", async (reason) => {
        setIsSocketInitialized(false);
        console.log("websocket disconnected:", reason);
        if (reason === "io server disconnect") {
          // サーバーによって切断された場合、再接続を試みる
          initialize();
          setIsSocketInitialized(true);
        }
      });
    };

    const initialize = async () => {
      const socket = await establishConnection();
      if (socket === undefined) return;
      registerEventListeners(socket);
      setSocket(socket);
    };

    initialize();

    // コンポーネントがアンマウントされた際に WebSocket 接続を破棄
    return () => {
      if (instance) {
        instance.disconnect();
        setIsSocketInitialized(false);
      }
    };
  }, [communityId]);

  return (
    <WebSocketContext.Provider value={{ instance, isInitialized }}>
      {children}
    </WebSocketContext.Provider>
  );
};
