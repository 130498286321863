import { useEffect, useState } from "react";
import * as invitationApi from "../../apiClients/invitation";

export const useInvitation = (token: string) => {
  const [invitation, setInvitation] =
    useState<
      Awaited<ReturnType<typeof invitationApi.validateInvitationToken>>
    >();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    setIsLoading(true);
    invitationApi
      .validateInvitationToken({ token })
      .then((res) => {
        setInvitation(res);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [token]);
  return {
    isValid: invitation?.valid ?? false,
    type: invitation?.type,
    organizationId: invitation?.organizationId,
    flow: invitation?.flow,
    invitation,
    isLoading,
  } as const;
};

export const useGetOrGenerateInvitation = ({
  skip = false,
  pta = false,
  resident = false,
}: {
  skip?: boolean;
  pta?: boolean;
  resident?: boolean;
}) => {
  const [invitationLink, setInvitationLink] = useState<string>("");
  const [invitationResidentLink, setInvitationResidentLink] =
    useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const fetchInvitation = async () => {
    setLoading(true);
    if (!pta && !resident) {
      setLoading(false);
      return;
    }

    // openExternalBrowserプロパティをつけることでLINEで開いた際にデフォルトのブラウザで開かれる
    // https://sougawa-pc.net/line-open-default-browser/
    if (pta) {
      const ptaToken =
        (await invitationApi.getOrGenerateInvitationToken({})) ?? "";
      setInvitationLink(
        `${location.origin}/signup?token=${ptaToken}&openExternalBrowser=1`
      );
    }

    if (resident) {
      const residentToken =
        (await invitationApi.getOrGenerateInvitationResidentToken({})) ?? "";
      setInvitationResidentLink(
        `${location.origin}/signup?token=${residentToken}&openExternalBrowser=1`
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!skip) {
      fetchInvitation();
    }
  }, [pta, resident]);

  return { loading, invitationLink, invitationResidentLink, fetchInvitation };
};
