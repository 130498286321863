import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const recruitmentApplyListFilterState = atom<string>({
  key: "RecruitmentApplyListFilter",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const useRecruitmentApplyListFilter = () => {
  const [recruitmentApplyListFilter, setRescruitmentApplyListFilter] =
    useRecoilState(recruitmentApplyListFilterState);

  return [recruitmentApplyListFilter, setRescruitmentApplyListFilter] as const;
};
