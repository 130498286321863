import React, { useCallback } from "react";
import styled from "styled-components";
import * as Responsive from "../../../components/Responsive";
import * as Typo from "../../../components/Typo";
import { Margin } from "../../../components/Margin";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ButtonGroupExtend,
  ButtonSelect,
} from "../../../components/ButtonSelect";
import { genderOption } from "../../../utils/types/gender";
import { Label } from "../../../components/form/Label";
import { prefectureOptions } from "../../../utils/types/prefecture";
import { getAddressFromPostalCode } from "../../../apiClients/postcode";
import { ResidentInviteMemberPageFormData } from "./ResidentInviteMemberPage";
import { usePolyfitHistory } from "../../../hooks/router";
import { Button } from "../../../components/Button3";
import { ageGroup, zTransformAgeGroup } from "../../../utils/types/ageGroup";
import { RegisterStepper } from "src/components/RegisterStepper";

const CURERNT_STEP = 1;

const FormValueSchema = z.object({
  email: z.string().min(1, "入力必須項目です"),
  lastName: z.string().min(1, "入力必須項目です"),
  firstName: z.string().min(1, "入力必須項目です"),
  lastNameKana: z
    .string()
    .min(1, "入力必須項目です")
    .refine((value) => /^[ぁ-んーー]+$/u.test(value), {
      message: "ひらがなで入力してください",
    }),
  firstNameKana: z
    .string()
    .min(1, "入力必須項目です")
    .refine((value) => /^[ぁ-んーー]+$/u.test(value), {
      message: "ひらがなで入力してください",
    }),
  gender: z.enum(["MALE", "FEMALE", "OTHER"], {
    errorMap: () => ({ message: "入力必須項目です" }),
  }),
  ageGroup: zTransformAgeGroup,
  postalCode: z
    .string()
    .min(1, "入力必須項目です")
    .refine(
      async (value) => {
        try {
          if (value.length !== 7) return false;
          await getAddressFromPostalCode(value);
          return true;
        } catch (err) {
          return false;
        }
      },
      {
        message: "ハイフンは入力せず、正しい郵便番号を入力してください",
      }
    ),
  prefecture: z.string().min(1, "入力必須項目です"),
  city: z.string().min(1, "入力必須項目です"),
  address1: z.string().min(1, "入力必須項目です"),
  address2: z.string().nullable(),
  phoneNumber: z
    .string()
    .regex(/^[0-9]+$/, {
      message: "ハイフンは入力せず、半角数字のみで入力してください",
    })
    .regex(new RegExp("^0{1}[0-9]{9,10}$"), "電話番号の形式で入力してください"),
});
export type ResidentInviteMemberBaseInfoFormData = z.infer<
  typeof FormValueSchema
>;

type Props = {
  formData: ResidentInviteMemberPageFormData;
  updateFormData: (stepData: Partial<ResidentInviteMemberPageFormData>) => void;
};

// 地域住民招待
// 基本情報の入力ページ
export default function ResidentInviteMemberBaseInfoPage({
  formData,
  updateFormData,
}: Props) {
  const {
    setValue,
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<ResidentInviteMemberBaseInfoFormData>({
    resolver: zodResolver(FormValueSchema),
    defaultValues: { prefecture: "", ageGroup: -1, ...formData.stepOne },
  });

  const history = usePolyfitHistory();
  const onSubmit = (data: ResidentInviteMemberBaseInfoFormData) => {
    try {
      updateFormData({ stepOne: data });
      history.push({
        to: "RESIDENT_INVITATION",
        query: { step: `${CURERNT_STEP + 1}` },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAddress = useCallback(
    async (value: string) => {
      try {
        if (value.length < 7) return true;
        const res = await getAddressFromPostalCode(value);
        setValue("prefecture", res.prefecture);
        setValue("city", res.city);
        setValue("address1", res.suburb);
        setValue("address2", "");
        clearErrors("postalCode");
        clearErrors("prefecture");
        clearErrors("city");
      } catch (err) {
        setError("postalCode", {
          message: "ハイフンは入力せず、正しい郵便番号を入力してください",
        });
      }
    },
    [errors]
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledContainer>
          <RegisterStepper count={2} current={1} title="" />
          <Margin marginBottom={16} />
          <SubTitleWrapper>
            <Typo.Heading3>基本情報の入力</Typo.Heading3>
            <Required>*必須</Required>
          </SubTitleWrapper>
          <Margin marginBottom={16} />
          <Responsive.Row>
            <Responsive.Col>
              <Label>メールアドレス</Label>
              <TextField
                type="email"
                placeholder="email@example.com"
                {...register("email")}
              />
              {errors.email ? (
                <ErrorMessage>{errors.email.message}</ErrorMessage>
              ) : (
                <Margin marginTop={16} />
              )}
              <Label>お名前</Label>
              <HorizontalInputWrapper>
                <SelectWrapper>
                  <TextField placeholder="例）山田" {...register("lastName")} />
                  {errors.lastName ? (
                    <ErrorMessage>{errors.lastName.message}</ErrorMessage>
                  ) : (
                    <Margin marginTop={8} />
                  )}
                </SelectWrapper>
                <SelectWrapper>
                  <TextField
                    placeholder="例）太郎"
                    {...register("firstName")}
                  />
                  {errors.firstName ? (
                    <ErrorMessage>{errors.firstName.message}</ErrorMessage>
                  ) : (
                    <Margin marginTop={8} />
                  )}
                </SelectWrapper>
              </HorizontalInputWrapper>
              <HorizontalInputWrapper>
                <SelectWrapper>
                  <TextField
                    placeholder="例）やまだ"
                    {...register("lastNameKana")}
                  />
                  {errors.lastNameKana ? (
                    <ErrorMessage>{errors.lastNameKana.message}</ErrorMessage>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </SelectWrapper>
                <SelectWrapper>
                  <TextField
                    placeholder="例）たろう"
                    {...register("firstNameKana")}
                  />
                  {errors.firstNameKana ? (
                    <ErrorMessage>{errors.firstNameKana.message}</ErrorMessage>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </SelectWrapper>
              </HorizontalInputWrapper>

              <Label>年代</Label>
              <SelectWrapper>
                <SelectBox {...register("ageGroup")}>
                  <option value="-1" disabled>
                    年代を選択
                  </option>
                  {ageGroup.map((opt) => {
                    return (
                      <option key={opt.value} value={opt.value}>
                        {opt.text}
                      </option>
                    );
                  })}
                </SelectBox>
                {errors?.ageGroup != null ? (
                  <ErrorMessage>{errors?.ageGroup?.message}</ErrorMessage>
                ) : (
                  <Margin marginTop={16} />
                )}
              </SelectWrapper>

              <Label>性別</Label>
              <ButtonGroupExtend>
                {genderOption.map((option, i) => (
                  <Controller
                    key={i}
                    name="gender"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ButtonSelect
                        selected={value === option.value}
                        onClick={() => onChange(option.value)}
                      >
                        <span>{option.text}</span>
                      </ButtonSelect>
                    )}
                  />
                ))}
              </ButtonGroupExtend>

              {errors?.gender != null ? (
                <ErrorMessage>{errors?.gender?.message}</ErrorMessage>
              ) : (
                <Margin marginTop={16} />
              )}

              <Label>住所</Label>
              <SelectWrapper>
                <TextField
                  {...register("postalCode")}
                  placeholder="例）1030013"
                  onChange={(e) => getAddress(e.target.value)}
                />
                {errors.postalCode ? (
                  <ErrorMessage>{errors.postalCode.message}</ErrorMessage>
                ) : (
                  <Margin marginTop={8} />
                )}
              </SelectWrapper>

              <HorizontalInputWrapper>
                <SelectWrapper>
                  <SelectBox {...register(`prefecture`)}>
                    <option value="" disabled>
                      県名を選択
                    </option>
                    {prefectureOptions.map((opt) => {
                      return (
                        <option key={opt.value} value={opt.value}>
                          {opt.text}
                        </option>
                      );
                    })}
                  </SelectBox>
                  {errors?.prefecture != null ? (
                    <ErrorMessage>{errors?.prefecture?.message}</ErrorMessage>
                  ) : (
                    <Margin marginTop={8} />
                  )}
                </SelectWrapper>
                <SelectWrapper>
                  <TextField
                    {...register("city")}
                    placeholder="例）川崎市川崎区"
                  />
                  {errors.city ? (
                    <ErrorMessage>{errors.city.message}</ErrorMessage>
                  ) : (
                    <Margin marginTop={8} />
                  )}
                </SelectWrapper>
              </HorizontalInputWrapper>

              <SelectWrapper>
                <TextField
                  {...register("address1")}
                  placeholder="例）旭町3-1"
                />
                {errors.address1 ? (
                  <ErrorMessage>{errors.address1.message}</ErrorMessage>
                ) : (
                  <Margin marginTop={8} />
                )}
              </SelectWrapper>

              <SelectWrapper>
                <TextField
                  {...register("address2")}
                  placeholder="例）〇〇マンション101号室"
                />
                {errors.address2 ? (
                  <ErrorMessage>{errors.address2.message}</ErrorMessage>
                ) : (
                  <Margin marginTop={8} />
                )}
              </SelectWrapper>

              <Margin marginTop={16} />
              <Label>電話番号（日中に連絡が取れる番号）</Label>
              <TextField
                placeholder="例)  09012345678"
                {...register("phoneNumber")}
              />
              {errors.phoneNumber ? (
                <ErrorMessage>{errors.phoneNumber.message}</ErrorMessage>
              ) : (
                <Margin marginTop={8} />
              )}
            </Responsive.Col>
          </Responsive.Row>
          <NextButton color="primary" fill>
            次へ
          </NextButton>
        </StyledContainer>
      </form>
    </>
  );
}

const NextButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
  padding: 9.5px 0;
`;

const StyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;

const HorizontalInputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 16px;
`;

const SelectWrapper = styled.div`
  min-width: 240px;
  @media (max-width: 1279px) {
    min-width: 163px;
  }
`;

const SelectBox = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background: "#fbfcfd";
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: "pointer";
`;

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const SubTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Required = styled.span`
  color: #bd271e;
  font-size: 12px;
`;
