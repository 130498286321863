/**
 * APIのエラーレスポンスの型定義
 */
export type ApiErrorResponse = {
  /**
   * HTTPステータスコード
   * @example 422
   */
  status: number;

  /**
   * エラーコード（プロジェクトで定義したエラーコード）
   * @example "DuplicatedEmail"
   */
  errorCode: string;

  /**
   * エラーメッセージ（フロントで表示するメッセージ）
   * @example "このメールアドレスは既に登録されています"
   */
  message: string;

  /**
   * サーバーのエラーメッセージをそのまま返す（デバッグ用）
   * @example "Duplicate entry'
   */
  errorMessage: string;
};

/**
 * APIエラーを表すクラス
 */
export class APIError extends Error {
  /**
   * HTTPステータスコード
   * @example 422
   */
  status: number;

  /**
   * エラーコード（プロジェクトで定義したエラーコード）
   * @example "DuplicatedEmail"
   */
  errorCode: string;

  /**
   * サーバーのエラーメッセージをそのまま返す（デバッグ用）
   * @example "Duplicate entry'
   */
  errorMessage: string;

  constructor(response: Partial<ApiErrorResponse>) {
    super(response.message ?? "Internal Server Error");
    this.status = response.status ?? 500;
    this.errorCode = response.errorCode ?? "InternalServerError";
    this.errorMessage = response.errorMessage ?? "何らかのエラーが発生しました";
  }
}
