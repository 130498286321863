export const Role = {
  PTAAdmin: "PTAAdmin",
  PTAMember: "PTAMember",
  CSAdmin: "CSAdmin",
  CSMember: "CSMember",
  CSGuest: "CSGuest",
  CSOperator: "CSOperator",
  BEAdmin: "BEAdmin",
} as const;

export type Role = (typeof Role)[keyof typeof Role];
