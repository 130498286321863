import { useCallback, useEffect, useMemo, useState } from "react";
import { usePolyfitLocationQuery } from "../../../hooks/router";
import {
  ChatMessageForView,
  fetchChatMessagesByRoomId,
} from "../../../apiClients/chatMessage";
import { useWebSocket } from "../../../hooks/recoil/socket";
import { useNewMessageNotification } from "src/features/common/ChatMessage/hooks/useNewMessageNotification";
import { formatDateTimeToDate } from "src/features/common/ChatMessage/utils/formatRecruitmentSchedule";
import { EVENT_NAMES } from "@shared/types/webSocket";
import { UNIQUE_INTERNAL_PARTICIPANT_IDS } from "@shared/types/chatMessage";
import { useGetChatRoom } from "src/hooks/query/chatRoom";
import { ApprovalStatus } from "@shared/types/chatRoom";
import { logger } from "src/utils/logger";
import { DisplayItem } from "src/features/common/ChatMessage/types";

export const useMessageList = ({
  initLastReadAt,
  messageListRef,
  chatRoomParticipantId,
  initLastReadAts,
}: {
  initLastReadAt: Date;
  messageListRef: React.RefObject<HTMLDivElement>;
  chatRoomParticipantId: string;
  initLastReadAts?: Date[];
}) => {
  const { chatRoomId } = usePolyfitLocationQuery("RESIDENT_CHAT", {});
  const { chatRoom } = useGetChatRoom(chatRoomId);
  const [messageList, setMessageList] = useState<ChatMessageForView[]>([]);
  const [lastReadAt, setLastReadAt] = useState<Date>(initLastReadAt);
  const [lastReadAts, setLastReadAts] = useState<Date[] | undefined>(
    initLastReadAts
  );
  const { newPartnerMessage, setNewPartnerMessage, NewMessageNotification } =
    useNewMessageNotification({ messageListRef });
  const socket = useWebSocket();

  const scrollToBottom = () => {
    if (!messageListRef.current) return;
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  };

  // 前後のメッセージを比較し、日付が変わっていたら、間にdateLabel用のデータを差し込む
  const displayItemList: DisplayItem[] = useMemo(
    () =>
      messageList.reduce((acc: DisplayItem[], message: ChatMessageForView) => {
        const prevItem = acc[acc.length - 1];
        const selectAndPushToMessageList = (
          acc: DisplayItem[],
          message: ChatMessageForView
        ) => {
          if (
            message.participantId === UNIQUE_INTERNAL_PARTICIPANT_IDS.SYSTEM
          ) {
            acc.push({ itemType: "systemMessage", value: message });
          } else {
            acc.push({ itemType: "userMessage", value: message });
          }
        };
        if (!prevItem) {
          acc.push({
            prevDate: null,
            itemType: "dateLabel",
            value: formatDateTimeToDate(message.createdAt),
          });
          selectAndPushToMessageList(acc, message);
        } else if (
          (prevItem.itemType === "userMessage" ||
            prevItem.itemType === "systemMessage") &&
          formatDateTimeToDate(prevItem.value.createdAt) !==
            formatDateTimeToDate(message.createdAt)
        ) {
          acc.push({
            prevDate: formatDateTimeToDate(prevItem.value.createdAt),
            itemType: "dateLabel",
            value: formatDateTimeToDate(message.createdAt),
          });
          selectAndPushToMessageList(acc, message);
        } else {
          selectAndPushToMessageList(acc, message);
        }

        return acc;
      }, [] as DisplayItem[]),
    [messageList]
  );

  // メッセージを読み込み完了したら最下部にスクロール
  useEffect(() => {
    if (newPartnerMessage !== "") return;
    setTimeout(() => {
      scrollToBottom();
    }, 50);
  }, [messageList]);

  const fetchChatMessages = useCallback(async () => {
    if (chatRoomId == null) return;
    try {
      const chatMessages = await fetchChatMessagesByRoomId(chatRoomId);
      setMessageList(chatMessages);
    } catch (error) {
      logger.error(new Error(`Error fetching chat messages:` + error));
    }
  }, [chatRoomId]);

  // 初回fetch
  useEffect(() => {
    fetchChatMessages();
  }, [chatRoomId]);

  // 重複削除用のset
  const receivedMessageIds = new Set();
  useEffect(() => {
    if (!socket.instance) return;

    const newMessageHandler = (data: { chatMessage: ChatMessageForView }) => {
      // ParticipantChatRoomのapprovalStatusがAPPROVEDではない場合、処理をスキップ
      if (
        chatRoom?.chatRoom.ParticipantChatRooms.find(
          (participantChatRoom) =>
            participantChatRoom.participantId === chatRoomParticipantId
        )?.approvalStatus !== ApprovalStatus.APPROVED
      )
        return;
      const message: ChatMessageForView = data.chatMessage;

      // 現在のチャットルームIDと異なる場合、処理をスキップ
      if (message.chatRoomId !== chatRoomId) return;
      // まだ有効化されていないメッセージの場合、処理をスキップ
      if (new Date(message.enabledAt) > new Date()) return;
      // 既に受信済みのメッセージIDの場合、処理をスキップ
      if (receivedMessageIds.has(data.chatMessage.id)) return;

      // メッセージの送信者が相手の場合
      if (
        data.chatMessage.participantId !== chatRoomParticipantId &&
        messageListRef.current
      ) {
        const isAtBottom =
          messageListRef.current.scrollHeight -
            messageListRef.current.scrollTop <=
          messageListRef.current.clientHeight + 10; // 10ピクセルの遊びを設ける

        if (!isAtBottom) {
          // 画面下部に新着メッセージを知らせる通知を表示
          setNewPartnerMessage(data.chatMessage.content);
        }
      } else {
        // 自分のメッセージの場合、スクロールを一番下に移動
        scrollToBottom();
      }

      // 新しいメッセージをメッセージリストに追加
      setMessageList((prevMessageList) => [...prevMessageList, message]);

      // 受信済みメッセージIDを記録
      receivedMessageIds.add(data.chatMessage.id);
    };

    const lastReadAtHandler = (data: {
      lastReadAt: Date;
      chatRoomId: string;
      participantId: string;
      lastReadAts?: Date[];
    }) => {
      if (data.chatRoomId !== chatRoomId) return;
      if (data.participantId === chatRoomParticipantId) return;
      setLastReadAt(data.lastReadAt);
      setLastReadAts(data.lastReadAts);
    };

    socket.instance.on(EVENT_NAMES.NEW_MESSAGE, newMessageHandler);
    socket.instance.on(EVENT_NAMES.LAST_READ_AT, lastReadAtHandler);

    // コンポーネントのアンマウント時にリスナーを削除
    return () => {
      if (socket.instance) {
        socket.instance.off(EVENT_NAMES.NEW_MESSAGE, newMessageHandler);
        socket.instance.off(EVENT_NAMES.LAST_READ_AT, lastReadAtHandler);
      }
    };
  }, [socket.isInitialized, chatRoom, chatRoomParticipantId]);
  return {
    displayItemList,
    lastReadAt,
    lastReadAts,
    NewMessageNotification,
    fetchChatMessages,
  };
};
