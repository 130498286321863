import React, { memo } from "react";

type Props = {
  size: number;
};

export const ArrowLeft = memo(({ size = 16 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0179 14.0425C11.3175 13.7565 11.3285 13.2818 11.0425 12.9821L6.45161 8.17262C6.35939 8.07601 6.35939 7.92399 6.45161 7.82738L11.0425 3.01786C11.3285 2.71823 11.3175 2.24349 11.0179 1.95748C10.7182 1.67148 10.2435 1.68252 9.95748 1.98214L5.36657 6.79167C4.72107 7.46791 4.72107 8.53209 5.36657 9.20833L9.95748 14.0179C10.2435 14.3175 10.7182 14.3285 11.0179 14.0425Z"
        fill="black"
      />
    </svg>
  );
});
