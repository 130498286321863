export const wageTypeOptions = [
  { value: "HOURLY", text: "時給" },
  { value: "DAILY", text: "日給" },
  { value: "MONTHLY", text: "月給" },
] as const;

export type WageType = (typeof wageTypeOptions)[number]["value"];

export function getWageTypeText(gender: WageType | null): string {
  return wageTypeOptions.find((option) => option.value === gender)?.text ?? "";
}
