import React from "react";
import styled from "styled-components";

export const Google = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4 12.1097C20.4 11.4059 20.3428 10.8924 20.2191 10.3597H12.1714V13.5362H16.8952C16.8 14.3256 16.2857 15.5145 15.1429 16.3133L15.1268 16.4197L17.6713 18.3881L17.8476 18.4057C19.4666 16.9126 20.4 14.7156 20.4 12.1097Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1714 20.4789C14.4857 20.4789 16.4285 19.7181 17.8476 18.4056L15.1428 16.3132C14.419 16.8174 13.4476 17.1692 12.1714 17.1692C9.9048 17.1692 7.98101 15.6761 7.29522 13.6124L7.1947 13.6209L4.54889 15.6657L4.51428 15.7616C5.9238 18.5578 8.81905 20.4789 12.1714 20.4789Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29517 13.6124C7.11423 13.0797 7.0095 12.5091 7.0095 11.9194C7.0095 11.3297 7.11423 10.7592 7.28566 10.2265L7.28087 10.1131L4.60189 8.03546L4.51423 8.07707C3.9333 9.23734 3.59998 10.5404 3.59998 11.9194C3.59998 13.2985 3.9333 14.6014 4.51423 15.7616L7.29517 13.6124Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1714 6.66953C13.7809 6.66953 14.8666 7.36391 15.4857 7.94405L17.9047 5.58541C16.4191 4.20637 14.4857 3.35999 12.1714 3.35999C8.81905 3.35999 5.9238 5.28109 4.51428 8.07709L7.28571 10.2265C7.98101 8.16282 9.9048 6.66953 12.1714 6.66953Z"
        fill="#EB4335"
      />
    </svg>
  );
};

export const ImportAction = () => {
  return <ImportButton>ファイルを選択</ImportButton>;
};

const ImportButton = styled.div`
  background: #0071c2;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 166px;
  height: 40px;
`;

export const BackIcon = ({
  size = "12px",
  color = "#343741",
}: {
  size?: string;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.4142 61.8405C36.6332 61.0594 36.6332 59.7931 37.4142 59.0121L77.0122 19.4141C77.7932 18.633 79.0596 18.633 79.8406 19.4141L82.669 22.2425C83.4501 23.0236 83.4501 24.2899 82.669 25.0709L41.6569 66.0831L37.4142 61.8405Z"
        fill={color}
      />
      <path
        d="M79.8405 101.669C79.0594 102.45 77.7931 102.45 77.0121 101.669L37.4141 62.0713C36.633 61.2903 36.633 60.0239 37.4141 59.2429L41.6567 55.0002L82.6689 96.0124C83.45 96.7935 83.45 98.0598 82.6689 98.8409L79.8405 101.669Z"
        fill={color}
      />
    </svg>
  );
};

export const ThumbsUp = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  color = "#343741",
}: {
  size?: string;
  color?: string;
}) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M86.8278 54.995C88.2552 52.578 89.0625 49.9999 89.0625 46.8831C89.0625 39.7192 83.0049 32.954 75.0944 32.954H69.1569C69.9577 30.8691 70.5973 28.3756 70.5973 25.3792C70.5973 13.5312 64.4629 8.33325 55.0911 8.33325C45.064 8.33325 45.6359 23.7846 43.4115 26.009C39.7091 29.7113 35.3361 36.824 32.2201 39.5833H16.1458C13.2694 39.5833 10.9375 41.9151 10.9375 44.7916V83.8541C10.9375 86.7306 13.2694 89.0624 16.1458 89.0624H26.5625C28.9865 89.0624 31.0234 87.4065 31.6045 85.1643C38.8488 85.3272 43.8213 91.665 60.5436 91.665C61.7188 91.665 63.0208 91.6666 64.1602 91.6666C76.7118 91.6666 82.387 85.2501 82.5423 76.1506C84.7101 73.1518 85.8462 69.1321 85.3646 65.2473C86.9684 62.2441 87.5885 58.6811 86.8278 54.995ZM76.7773 63.7564C78.8216 67.1956 76.9824 71.7984 74.5085 73.1265C75.7617 81.066 71.6426 83.8525 65.8626 83.8525H59.707C48.047 83.8525 40.4966 77.6969 31.7708 77.6969V47.3958H33.5482C38.1641 47.3958 44.6126 35.8577 48.9356 31.5331C53.5514 26.9172 52.0133 19.2236 55.0911 16.1458C62.7848 16.1458 62.7848 21.5136 62.7848 25.3792C62.7848 31.7545 58.1689 34.6109 58.1689 40.7665H75.0944C78.5303 40.7665 81.2354 43.8443 81.25 46.9221C81.2646 49.9983 79.1634 53.0761 77.6253 53.0761C79.8208 55.4451 80.2899 60.4387 76.7773 63.7564ZM25.2604 78.6458C25.2604 80.8031 23.5116 82.552 21.3542 82.552C19.1968 82.552 17.4479 80.8031 17.4479 78.6458C17.4479 76.4884 19.1968 74.7395 21.3542 74.7395C23.5116 74.7395 25.2604 76.4884 25.2604 78.6458Z"
        fill="#0A2540"
      />
    </svg>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AirPlaneIcon = ({ size }: { size?: string }) => {
  return (
    <svg
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="240" height="240" fill="#F5F7FA" />
      <path
        d="M108.339 145.516L196.386 57.6474L171.057 183.626L108.339 145.516Z"
        fill="#DEEFFF"
      />
      <path
        d="M186.332 60.9994L54.3329 114.5L84.3252 135.591L186.332 60.9994Z"
        fill="#DEEFFF"
      />
      <path
        d="M97.3316 140.499L99.3327 168.999L104.333 144.999L158.833 90.5007L97.3316 140.499Z"
        fill="#8AC6FF"
      />
      <path
        d="M111.943 170.719L115.786 151.602L127.546 159.066L111.943 170.719Z"
        fill="#8AC6FF"
      />
      <path
        d="M171.94 184.312L197.958 54.9017L42.9704 110.442L85.3058 135.784L100.167 178.552L106.672 146.199L171.94 184.312Z"
        stroke="#37434F"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.261 145.81L196.584 56.6671L85.8944 135.394"
        stroke="#37434F"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.168 178.553L127.937 159.655"
        stroke="#37434F"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9994 204.206L25.1145 194.359M66.9282 157.317L34.6417 185.919"
        stroke="#37434F"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.7132 205.287L56.9999 204M80.5494 181.128L65.3436 195.919"
        stroke="#37434F"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.8313 205L114.952 184.119"
        stroke="#37434F"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.832 145.132L36.4017 149.97C36.6908 150.514 37.2147 150.895 37.8216 151.002L43.2172 151.951L39.4098 155.89C38.9815 156.333 38.7814 156.949 38.8674 157.559L39.6324 162.983L34.7096 160.58C34.1558 160.309 33.5083 160.309 32.9545 160.58L28.0317 162.983L28.7966 157.559C28.8827 156.949 28.6826 156.333 28.2543 155.89L24.4469 151.951L29.8424 151.002C30.4494 150.895 30.9733 150.514 31.2624 149.97L33.832 145.132Z"
        fill="white"
        stroke="#37434F"
        strokeWidth="2"
      />
      <path
        d="M136.243 181.995L136.416 182.98C137.023 182.873 137.547 182.492 137.836 181.948L139.332 179.132L140.828 181.948C141.117 182.492 141.641 182.873 142.248 182.98L145.388 183.532L143.172 185.825C142.744 186.268 142.544 186.884 142.63 187.494L143.075 190.652L140.21 189.253C139.656 188.982 139.008 188.982 138.454 189.253L135.589 190.652L136.034 187.494C136.12 186.884 135.92 186.268 135.492 185.825L133.276 183.532L136.416 182.98L136.243 181.995Z"
        fill="white"
        stroke="#37434F"
        strokeWidth="2"
      />
      <path
        d="M214.832 75.1319L217.402 79.9701C217.691 80.5144 218.215 80.895 218.822 81.0018L224.217 81.9506L220.41 85.8896C219.981 86.3327 219.781 86.9486 219.867 87.5588L220.632 92.9835L215.71 90.5797C215.156 90.3092 214.508 90.3092 213.954 90.5797L209.032 92.9835L209.797 87.5588C209.883 86.9486 209.683 86.3327 209.254 85.8896L205.447 81.9506L210.842 81.0018C211.449 80.895 211.973 80.5144 212.262 79.9701L214.832 75.1319Z"
        fill="white"
        stroke="#37434F"
        strokeWidth="2"
      />
    </svg>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ChevronIcon = ({
  size,
  color,
}: {
  size?: string;
  color?: string;
}) => {
  // size プロパティが存在する場合はそれを使用し、ない場合はデフォルトで24を設定
  const iconSize = size || "24";

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 10L12 15L17 10L7 10Z" fill={color} />
    </svg>
  );
};

export const ReverseChevronIcon = ({
  size,
  color,
}: {
  size?: string;
  color?: string;
}) => {
  // size プロパティが存在する場合はそれを使用し、ない場合はデフォルトで24を設定
  const iconSize = size || "24";

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 14L12 9L17 14L7 14Z" fill={color} />
    </svg>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ThunderIcon = ({ size }: { size?: string }) => {
  // size プロパティが存在する場合はそれを使用し、ない場合はデフォルトで24を設定
  const iconSize = size || "24";

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4807 14.1032C7.34777 14.3647 7.45205 14.6845 7.71361 14.8175C7.97517 14.9504 8.29497 14.8461 8.4279 14.5846L11.6305 8.28283C11.8102 7.92941 11.5534 7.51089 11.1569 7.51089H5.64786L8.53268 1.83455C8.66561 1.57299 8.56133 1.2532 8.29977 1.12027C8.03821 0.987336 7.71841 1.09161 7.58548 1.35317L4.30835 7.80145C4.12874 8.15487 4.3855 8.57339 4.78195 8.57339H10.291L7.4807 14.1032Z"
        fill="#69707D"
      />
    </svg>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PaperPlaneIcon = ({
  size,
  color,
}: {
  size?: number;
  color?: string;
}) => {
  // size プロパティが存在する場合はそれを使用し、ない場合はデフォルトで24を設定
  const iconSize = size || 24;

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 ${iconSize} ${iconSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4 24.4L24.85 16.92C25.66 16.57 25.66 15.43 24.85 15.08L7.4 7.60002C6.74 7.31002 6.01 7.80002 6.01 8.51002L6 13.12C6 13.62 6.37 14.05 6.87 14.11L21 16L6.87 17.88C6.37 17.95 6 18.38 6 18.88L6.01 23.49C6.01 24.2 6.74 24.69 7.4 24.4Z"
        fill={color}
      />
    </svg>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PersonIcon = ({
  size,
  color,
}: {
  size?: string;
  color?: string;
}) => {
  // size プロパティが存在する場合はそれを使用し、ない場合はデフォルトで24を設定
  const iconSize = size || "24px";

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z"
        fill={color}
      />
    </svg>
  );
};

export const MessageLinesIcon = ({
  size,
  color,
}: {
  size?: string;
  color?: string;
}) => {
  // size プロパティが存在する場合はそれを使用し、ない場合はデフォルトで24を設定
  const iconSize = size || "24px";

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4H36V28H6.34L4 30.34V4ZM4 0C1.8 0 0.02 1.8 0.02 4L0 40L8 32H36C38.2 32 40 30.2 40 28V4C40 1.8 38.2 0 36 0H4ZM8 20H24V24H8V20ZM8 14H32V18H8V14ZM8 8H32V12H8V8Z"
        fill={color}
      />
    </svg>
  );
};

export const UserGroupIcon = ({
  size,
  color,
}: {
  size?: string;
  color?: string;
}) => {
  // size プロパティが存在する場合はそれを使用し、ない場合はデフォルトで24を設定
  const iconSize = size || "24px";

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 40 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 17.5C9.32 17.5 0 19.84 0 24.5V28H28V24.5C28 19.84 18.68 17.5 14 17.5ZM4.68 24C6.36 22.84 10.42 21.5 14 21.5C17.58 21.5 21.64 22.84 23.32 24H4.68ZM14 14C17.86 14 21 10.86 21 7C21 3.14 17.86 0 14 0C10.14 0 7 3.14 7 7C7 10.86 10.14 14 14 14ZM14 4C15.66 4 17 5.34 17 7C17 8.66 15.66 10 14 10C12.34 10 11 8.66 11 7C11 5.34 12.34 4 14 4ZM28.08 17.62C30.4 19.3 32 21.54 32 24.5V28H40V24.5C40 20.46 33 18.16 28.08 17.62ZM26 14C29.86 14 33 10.86 33 7C33 3.14 29.86 0 26 0C24.92 0 23.92 0.26 23 0.7C24.26 2.48 25 4.66 25 7C25 9.34 24.26 11.52 23 13.3C23.92 13.74 24.92 14 26 14Z"
        fill={color}
      />
    </svg>
  );
};
