import { z } from "zod";

// ageGroupの選択肢のvalue
export const ageGroupValues = ["10", "20", "30", "40", "50", "60", "70", "80"];

/**
 * ageGroupValuesからageGroupを生成
 *   { value: "10", text: "10代" },
 *   { value: "20", text: "20代" },
 *   { value: "30", text: "30代" },
 *   { value: "40", text: "40代" },
 *   { value: "50", text: "50代" },
 *   { value: "60", text: "60代" },
 *   { value: "70", text: "70代" },
 *   { value: "80", text: "80代以上" },
 */
export const ageGroup = ageGroupValues.map((value) => ({
  value,
  text: `${value}代`,
}));

const isValidAgeGroupValue = (value: number | string) => {
  const valueStr = value.toString();
  return ageGroupValues.includes(valueStr);
};

/**
 * z.unionでstring|numberの型も受け入れるようにしている
 *   文字列型：フォームからの入力値
 *   数値型：Web Storage、サーバーサイドから取得したageGroup
 */
export const zTransformAgeGroup = z.union([
  z
    .string()
    // ageGroupのvalueに含まれるかどうかをチェック
    .refine(isValidAgeGroupValue, {
      message: "入力必須項目です",
    })
    .transform((value) => Number(value)),
  z
    .number()
    // ageGroupのvalueに含まれるかどうかをチェック
    .refine(isValidAgeGroupValue, {
      message: "入力必須項目です",
    }),
]);

export function getAgeGroupText(ageGroupValue: number | undefined): string {
  const ageGroupValueString = ageGroupValue?.toString();
  return (
    ageGroup.find((option) => option.value === ageGroupValueString)?.text ?? ""
  );
}
