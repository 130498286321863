import React from "react";

export const SignatureConfirmIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
    >
      <rect width="240" height="240" rx="12" fill="white" />
      <path
        d="M188.179 72.2371L188.179 72.2466V72.2561V207C188.179 207.276 187.955 207.5 187.679 207.5H52C51.7239 207.5 51.5 207.276 51.5 207V33C51.5 32.7239 51.7239 32.5 52 32.5H149.892H187.977C188.255 32.5 188.479 32.7262 188.477 33.0038L188.179 72.2371Z"
        fill="#DEEFFF"
        stroke="#343741"
        strokeWidth="5"
      />
      <rect x="69" y="80" width="28" height="28" rx="4" fill="#343741" />
      <rect x="69" y="120" width="28" height="28" rx="4" fill="#343741" />
      <rect x="69" y="160" width="28" height="28" rx="4" fill="#343741" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.8013 86.2663C93.7588 87.2611 93.7285 88.8437 92.7337 89.8012L81.3052 100.801C80.8047 101.283 80.1263 101.535 79.4328 101.496C78.7392 101.458 78.0929 101.132 77.6489 100.598L73.0775 95.098C72.1949 94.0362 72.3402 92.46 73.4021 91.5774C74.4639 90.6949 76.0401 90.8402 76.9227 91.902L79.7752 95.334L89.2664 86.1988C90.2612 85.2413 91.8438 85.2715 92.8013 86.2663Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.8013 126.266C93.7588 127.261 93.7285 128.844 92.7337 129.801L81.3052 140.801C80.8047 141.283 80.1263 141.535 79.4328 141.496C78.7392 141.458 78.0929 141.132 77.6489 140.598L73.0775 135.098C72.1949 134.036 72.3402 132.46 73.4021 131.577C74.4639 130.695 76.0401 130.84 76.9227 131.902L79.7752 135.334L89.2664 126.199C90.2612 125.241 91.8438 125.272 92.8013 126.266Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.8013 166.266C93.7588 167.261 93.7285 168.844 92.7337 169.801L81.3052 180.801C80.8047 181.283 80.1263 181.535 79.4328 181.496C78.7392 181.458 78.0929 181.132 77.6489 180.598L73.0775 175.098C72.1949 174.036 72.3402 172.46 73.4021 171.577C74.4639 170.695 76.0401 170.84 76.9227 171.902L79.7752 175.334L89.2664 166.199C90.2612 165.241 91.8438 165.272 92.8013 166.266Z"
        fill="white"
      />
      <path d="M54 35H55.2353L61 205H54V35Z" fill="white" />
      <rect x="105" y="85" width="66" height="5" rx="2.5" fill="#343741" />
      <rect x="105" y="125" width="66" height="5" rx="2.5" fill="#343741" />
      <rect x="105" y="165" width="66" height="5" rx="2.5" fill="#343741" />
      <rect x="105" y="98" width="66" height="5" rx="2.5" fill="#343741" />
      <rect x="105" y="138" width="66" height="5" rx="2.5" fill="#343741" />
      <rect x="105" y="178" width="66" height="5" rx="2.5" fill="#343741" />
      <rect x="91" y="56" width="58" height="5" rx="2.5" fill="#343741" />
    </svg>
  );
};
