import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { getRecruitmentById } from "src/apiClients/recruitment";

export const useRecruitmentDetail = (recruitmentId: string) => {
  const query = useQuery({
    queryKey: ["api", "recruitment", "getByRecruitmentId", recruitmentId],
    queryFn: () => getRecruitmentById(recruitmentId),
  });

  return {
    recruitment: useMemo(() => query.data || null, [query.data]),
    ...query,
  };
};

export function useInvalidateRecruitmentDetail() {
  const queryClient = useQueryClient();

  function invalidateRecruitmentDetail(recruitmentId?: string) {
    if (recruitmentId) {
      queryClient.invalidateQueries({
        queryKey: ["api", "recruitment", "getByRecruitmentId", recruitmentId],
      });
    } else {
      queryClient.invalidateQueries({
        queryKey: ["api", "recruitment", "getByRecruitmentId"],
      });
    }
  }

  return {
    invalidateRecruitmentDetail,
  };
}
