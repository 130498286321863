import React from "react";
import { Tooltip } from "react-tooltip";
import { Avatar } from "src/components/Common/Avatar";
import { ChatMessageForView } from "src/apiClients/chatMessage";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";

type Props = {
  message: ChatMessageForView;
  isAdmin?: boolean;
};

const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

export const ChatMessageAvatar = ({ message, isAdmin }: Props) => {
  const userName = isAdmin ? message.user.name + "(管理者)" : message.user.name;
  return (
    <>
      <Tooltip
        id={`avatar-tooltip-${message.id}`}
        place="bottom-start"
        content={userName}
        style={{ background: "#616161", fontSize: "13px" }}
        arrowColor="white"
      />
      <div data-tooltip-id={`avatar-tooltip-${message.id}`}>
        <Avatar
          src={message.user.picture ?? defaultUserImg}
          alt={message.user.name}
          size={32}
        />
      </div>
    </>
  );
};
