import React, { useCallback, useEffect, useMemo } from "react";
import { atom, useRecoilCallback, useRecoilValue } from "recoil";
import { Button } from "./Button";
import * as styles from "./Toast.css";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

type Toast = {
  key: string;
  type: "info" | "success" | "warn" | "error";
  expiredAt: number;
  content: string | React.ReactChild;
};
const toastsState = atom<Toast[]>({
  key: "global_toasts",
  default: [],
});

type ToastOption = {
  duration?: number;
};
/**
 * @example
 * import { useToast } from 'uikit';
 *
 * const toast = useToast();
 * toast.info('hello');
 */
export function useToast() {
  const addToast = useRecoilCallback(
    ({ snapshot, set }) =>
      async (toast: Omit<Toast, "key">) => {
        const toasts = await snapshot.getPromise(toastsState);
        const key = Math.random().toString(16);
        set(toastsState, [
          ...toasts,
          {
            key: key,
            ...toast,
          },
        ]);
      },
    []
  );

  const api = useMemo(() => {
    const sendToast =
      (type: Toast["type"]) =>
      (content: Toast["content"], option?: ToastOption) => {
        return addToast({
          type,
          content,
          expiredAt: Date.now() + (option?.duration || 3000),
        });
      };
    return {
      info: sendToast("info"),
      success: sendToast("success"),
      warn: sendToast("warn"),
      error: sendToast("error"),
    };
  }, [addToast]);

  return api;
}

export const Toast = ({
  toast,
  onClose,
}: {
  toast: Toast;
  onClose?: (toastId: string) => void;
}) => {
  const onClick = useCallback(() => {
    onClose?.(toast.key);
  }, [toast, onClose]);
  return (
    <div className={classNames(styles.Toast, styles.ToastColor, toast.type)}>
      <div className={styles.ToastContent}>{toast.content}</div>
      <Button
        // @ts-expect-error todo
        theme="support"
        size="small"
        className={classNames(styles.ToastColor, toast.type)}
        onClick={onClick}
      >
        {/* @ts-ignore */}
        <FontAwesomeIcon icon={faTimes} className="sw-Btn_SingleIcon" />
      </Button>
    </div>
  );
};

export const ToastBox = () => {
  const messages = useRecoilValue(toastsState);

  const expireToasts = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const toasts = await snapshot.getPromise(toastsState);
        set(
          toastsState,
          toasts.filter((toast) => toast.expiredAt > Date.now())
        );
      },
    []
  );

  const closeToast = useRecoilCallback(
    ({ snapshot, set }) =>
      async (toastKey: string) => {
        const toasts = await snapshot.getPromise(toastsState);
        set(
          toastsState,
          toasts.filter((toast) => toast.key !== toastKey)
        );
      },
    []
  );

  useEffect(() => {
    if (messages.length === 0) return;
    const interval = setInterval(() => {
      expireToasts();
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [expireToasts, messages]);

  return (
    <div className={styles.ToastBox}>
      {messages.map((toast) => {
        return <Toast key={toast.key} toast={toast} onClose={closeToast} />;
      })}
    </div>
  );
};
