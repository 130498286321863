import React, { useEffect, useState } from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "../../components/Modal";
import {
  Member,
  SearchMembersField,
} from "../../components/SearchMembersField";
import { Margin } from "../../components/Margin";
import { Label } from "../../components/form/Label";
import { TextField } from "../../components/Form";
import {
  updatePostUserGroup,
  usePostUserGroupUsers,
} from "../../hooks/api/postUserGroup";
import { useToast } from "../../components/Toast";
import { usePostUserGroupList } from "src/hooks/recoil/postUserGroup";
import styled from "styled-components";
import { logger } from "src/utils/logger";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  members: Member[];
  selectedGroupName: string;
  selectedGroupId: string;
  getPostGroups: () => Promise<void>;
};

export const EditGroupModal = ({
  isOpen,
  onClose,
  onCancel,
  members,
  selectedGroupName,
  selectedGroupId,
  getPostGroups,
}: Props) => {
  if (!isOpen) return null;
  const [groupName, setGroupName] = useState(selectedGroupName);
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const { postGroupUsers } = usePostUserGroupUsers(selectedGroupId);
  const toast = useToast();

  useEffect(() => {
    const defaultSelectedMemberIds = postGroupUsers.map((user) => user.id);
    setSelectedMemberIds(defaultSelectedMemberIds);
  }, [postGroupUsers]);

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async () => {
    setDoubleClickBlocked(true);
    try {
      await updatePostUserGroup({
        id: selectedGroupId,
        name: groupName,
        accountIds: selectedMemberIds,
      });
      await getPostGroups();
      toast.success("編集しました");
      onClose();
    } catch (error) {
      logger.error(error);
      toast.error("編集に失敗しました");
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  const { postGroups } = usePostUserGroupList();
  const [error, setError] = useState<string>("");

  useEffect(() => {
    //編集を選択したグループ名と一致していてもOKにする
    if (
      postGroups.some(
        (group) => group.name === groupName && group.id !== selectedGroupId
      )
    ) {
      setError("この連絡グループ名は既に登録されています");
    } else {
      setError("");
    }
  }, [groupName]);

  return (
    <ModalPortal
      onClose={() => {
        onClose();
      }}
    >
      <ModalHeader>連絡グループ編集</ModalHeader>
      <ModalBody>
        <p>
          「<strong>{selectedGroupName}</strong>
          」のグループメンバーを編集できます。
        </p>
        <Margin marginTop={20} />
        <Label>グループ名を編集</Label>
        <TextField
          placeholder="例) 2年3組バレー"
          type="text"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
        <ErrorMsg>{error}</ErrorMsg>
        <Margin marginTop={20} />
        <Label>メンバーを編集</Label>
        <SearchMembersField
          members={members}
          selectedMemberIds={selectedMemberIds}
          setSelectedMemberIds={setSelectedMemberIds}
        />
        <ModalSubmitButtons
          submitText="編集完了"
          disabled={
            groupName === "" ||
            selectedMemberIds.length === 0 ||
            error !== "" ||
            doubleClickBlocked
          }
          onSubmit={onSubmit}
          cancelText="戻る"
          onCancel={() => {
            onClose();
            onCancel();
          }}
        />
      </ModalBody>
    </ModalPortal>
  );
};

const ErrorMsg = styled.div`
  font-size: 14px;
  color: #bd271e;
`;
