import React from "react";

export const UnionIcon = () => {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.11111 10H8.88889V1.11111H1.11111V10ZM0 10.5556C0 10.8624 0.248731 11.1111 0.555556 11.1111H9.44444C9.75127 11.1111 10 10.8624 10 10.5556V0.555556C10 0.248731 9.75127 0 9.44444 0H0.555556C0.248731 0 0 0.248731 0 0.555556V10.5556ZM2.22198 3.33333C2.22198 3.02651 2.47071 2.77778 2.77754 2.77778H3.33309C3.63992 2.77778 3.88865 3.02651 3.88865 3.33333C3.88865 3.64016 3.63992 3.88889 3.33309 3.88889H2.77754C2.47071 3.88889 2.22198 3.64016 2.22198 3.33333ZM5.00012 2.77778C4.6933 2.77778 4.44456 3.02651 4.44456 3.33333C4.44456 3.64016 4.6933 3.88889 5.00012 3.88889H7.22234C7.52917 3.88889 7.7779 3.64016 7.7779 3.33333C7.7779 3.02651 7.52917 2.77778 7.22234 2.77778H5.00012ZM2.22198 5.55652C2.22198 5.24969 2.47071 5.00096 2.77754 5.00096H3.33309C3.63992 5.00096 3.88865 5.24969 3.88865 5.55652C3.88865 5.86334 3.63992 6.11208 3.33309 6.11208H2.77754C2.47071 6.11208 2.22198 5.86334 2.22198 5.55652ZM5.00012 5.00096C4.6933 5.00096 4.44456 5.24969 4.44456 5.55652C4.44456 5.86334 4.6933 6.11208 5.00012 6.11208H7.22234C7.52917 6.11208 7.7779 5.86334 7.7779 5.55652C7.7779 5.24969 7.52917 5.00096 7.22234 5.00096H5.00012ZM2.22198 7.7773C2.22198 7.47047 2.47071 7.22174 2.77754 7.22174H3.33309C3.63992 7.22174 3.88865 7.47047 3.88865 7.7773C3.88865 8.08412 3.63992 8.33285 3.33309 8.33285H2.77754C2.47071 8.33285 2.22198 8.08412 2.22198 7.7773ZM5.00012 7.22174C4.6933 7.22174 4.44456 7.47047 4.44456 7.7773C4.44456 8.08412 4.6933 8.33285 5.00012 8.33285H7.22234C7.52917 8.33285 7.7779 8.08412 7.7779 7.7773C7.7779 7.47047 7.52917 7.22174 7.22234 7.22174H5.00012Z"
        fill="#0077CC"
      />
    </svg>
  );
};
