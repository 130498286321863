import * as client from "./client";
import {
  ChatRoom,
  ChatRoomEntity,
  ChatRoomFilterType,
  CreateChatRoomRequest,
  CreateGroupChatRoomRequest,
  GetChatRoomRequest,
  UpdateChatRoomRequest,
  UpdateGroupChatRoomRequest,
} from "@shared/types/chatRoom";

export async function fetchChatRooms(filter: ChatRoomFilterType) {
  const filterQuery = JSON.stringify(filter);
  const chatRooms = await client.get<{ filter: string }, ChatRoomEntity[]>(
    "/chat-room/fetchChatRooms",
    { filter: filterQuery }
  );
  return chatRooms;
}

export async function fetchChatRoomById(chatRoomId: string) {
  const chatRoom = await client.get<
    Omit<GetChatRoomRequest, "currentCommunityId">,
    ChatRoomEntity
  >("/chat-room/fetchChatRoomById", {
    chatRoomId,
  });
  return chatRoom;
}

export async function approveChatRoom(chatRoomId: string) {
  await client.post<UpdateChatRoomRequest, {}>("/chat-room/approveChatRoom", {
    chatRoomId,
  });
}

export async function rejectChatRoom(chatRoomId: string) {
  await client.post<UpdateChatRoomRequest, {}>("/chat-room/rejectChatRoom", {
    chatRoomId,
  });
}

export async function deleteChatRoom(chatRoomId: string) {
  await client.post<UpdateChatRoomRequest, {}>("/chat-room/deleteChatRoom", {
    chatRoomId,
  });
}

/**
 * メンバー権限者がINDIVIDUAL_CHAT_WITH_ORGANIZATION用のチャットルームを作成または取得する
 */
export async function getOrCreateChatRoomByMember(): Promise<ChatRoom> {
  const chatRoom = await client.post<{}, ChatRoom>(
    "/chat-room/getOrCreateIndividualChatRoomByMember"
  );

  return chatRoom;
}

/**
 * CsAdmin権限者がINDIVIDUAL_CHAT_WITH_ORGANIZATION用のチャットルームを作成または取得する
 */
export async function getOrCreateChatRoomByCsAdmin(
  accountId: string
): Promise<ChatRoom> {
  const chatRoom = await client.post<CreateChatRoomRequest, ChatRoom>(
    "/chat-room/getOrCreateIndividualChatRoomByCsAdmin",
    {
      accountId,
    }
  );
  return chatRoom;
}

/**
 * CsAdmin権限者がGROUP_CHAT_WITH_ORGANIZATION用のチャットルームを作成する
 */
export async function createGroupChatRoomByCsAdmin(
  name: string,
  accountIds: string[],
  file?: File | undefined
): Promise<ChatRoom> {
  const chatRoom = await client.postWithFormData<
    CreateGroupChatRoomRequest & { file?: File },
    ChatRoom
  >("/chat-room/createGroupChatRoomByCsAdmin", {
    accountIds,
    name,
    file,
  });

  return chatRoom;
}

export async function updateGroupChatRoomByCsAdmin(
  chatRoomId: string,
  name: string,
  accountIds: string[],
  file?: File | undefined
): Promise<void> {
  await client.postWithFormData<
    UpdateGroupChatRoomRequest & { file?: File },
    ChatRoom
  >("/chat-room/updateGroupChatRoomByCsAdmin", {
    chatRoomId,
    accountIds,
    name,
    file,
  });
}
