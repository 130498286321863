import React from "react";
import { PageContainer, PageHeader, PageBody } from "../../../components/Page";
import * as Typo from "../../../components/Typo";
import * as Responsive from "../../../components/Responsive";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import {
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import { CommunityChatMessageIcon } from "../../../components/icons/CommunityChatMessageIcon";

export const SignupCompleteResidentStartOrganizationChatPage = () => {
  const history = usePolyfitHistory();
  const { token } = usePolyfitLocationQuery(
    "SIGNUP_COMPLETE_RESIDENT_START_ORGANIZATION_CHAT",
    {
      token: "",
    }
  );

  return (
    <>
      <Container>
        <IconContainer>
          <CommunityChatMessageIcon />
        </IconContainer>
        <PageHeader>問い合わせをできる準備ができました！</PageHeader>
        <PageBody>
          <Responsive.Row>
            <Responsive.Col>
              <Typo.Paragraph className="message">
                ログインをすると、チャット画面が立ち上がります。
                ログイン画面からログインをして、
                問い合わせのチャットをしましょう。
              </Typo.Paragraph>
            </Responsive.Col>
          </Responsive.Row>
          <Responsive.Row>
            <Responsive.Col>
              <Button
                size="large"
                fill
                className="message"
                onClick={() => {
                  history.push({
                    to: "RESIDENT_ORGANIZATION_CHAT_REGISTRATION_HANDLER",
                    query: { token },
                  });
                }}
              >
                チャットを開始する
              </Button>
            </Responsive.Col>
          </Responsive.Row>
        </PageBody>
      </Container>
    </>
  );
};

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 702px;
  margin: 0 auto;
  padding-top: 124px;
  .message {
    text-align: center;
  }
  button {
    margin: 12px auto;
  }
  @media (max-width: 1279px) {
    padding-top: 120px;
  }
`;

const IconContainer = styled.div`
  margin-bottom: 12px;
`;
