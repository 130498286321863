import { createRoute } from "..";
import EmptyLayout from "../../layouts/EmptyLayout";
import ActionPage from "../../pages/ActionPage";
import InputEmailPage from "../../pages/InputEmailPage";
import LoginPage from "../../pages/LoginPage";
import SignupPage from "../../pages/SignupPage";
import LoginWithResetEmailCompleteMemberPage from "../../pages/registration/pta/LoginWithResetEmailCompleteMemberPage";
import SendResetPasswordMailCompletePage from "../../pages/registration/pta/SendResetPasswordMailCompletePage";
import ResidentInviteMemberActivationCompletePage from "../../pages/resident/inviteMember/ResidentInviteMemberActivationCompletePage";
import { ResidentChatRegistrationDescription } from "../../pages/registration/resident/ResidentChatRegistrationDescription";
import AppRegistrationLayout from "../../layouts/AppRegistrationLayout";
import BoardEducationInviteMemberActivationCompletePage from "../../pages/boardEducation/invite/BoardEducationInviteMemberActivationCompletePage";

/**
 * 認証しなくてもアクセスできるページ
 */
export const PUBLIC_ROUTE_DEFS = {
  LOGIN: createRoute<{ token?: string }>({
    path: "/login",
    title: "",
    component: LoginPage,
    layout: EmptyLayout,
    route: "PUBLIC",
  }),
  SIGNUP: createRoute<{ token: string }>({
    path: "/signup",
    title: "",
    component: SignupPage,
    layout: EmptyLayout,
    route: "PUBLIC",
  }),
  LOGIN_WITH_RESET_EMAIL_COMPLETE_MEMBER: createRoute({
    path: "/login_with_reset_email_complete_member",
    title: "",
    component: LoginWithResetEmailCompleteMemberPage,
    layout: EmptyLayout,
    route: "PUBLIC",
  }),
  SEND_RESET_PASSWORD_MAIL_COMPLETE: createRoute({
    path: "/send_reset_password_mail_complete",
    title: "",
    component: SendResetPasswordMailCompletePage,
    layout: EmptyLayout,
    route: "PUBLIC",
  }),
  INPUT_EMAIL: createRoute({
    path: "/input_email",
    title: "",
    component: InputEmailPage,
    layout: EmptyLayout,
    route: "PUBLIC",
  }),
  ACTION: createRoute({
    path: "/action",
    title: "",
    component: ActionPage,
    layout: EmptyLayout,
    route: "PUBLIC",
  }),
  RESIDENT_ACTIVATION: createRoute<{ token: string }>({
    path: "/resident_activation",
    title: "",
    component: ResidentInviteMemberActivationCompletePage,
    layout: EmptyLayout,
    route: "PUBLIC",
  }),
  BOARD_EDUCATION_ACTIVATION: createRoute<{ token: string }>({
    path: "/board_education_activation",
    title: "",
    component: BoardEducationInviteMemberActivationCompletePage,
    layout: EmptyLayout,
    route: "PUBLIC",
  }),
  RESIDENT_CHAT_REGISTRATION_DESCRIPTION: createRoute<{ token: string }>({
    path: "/resident_chat_register_description",
    title: "",
    component: ResidentChatRegistrationDescription,
    layout: AppRegistrationLayout,
    route: "PUBLIC",
  }),
};

export const publicRouters = Object.values(PUBLIC_ROUTE_DEFS);
