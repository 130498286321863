import React, { ReactElement } from "react";
import styled from "styled-components";
import { zIndexes } from "../../../zIndex";

type Props = {
  isOpen: boolean;
  items: {
    label: string;
    onClick: () => void;
    color?: string;
    icon?: ReactElement;
  }[];
};

export const MenuModal = ({ isOpen, items }: Props) => {
  if (!isOpen) return null;

  return (
    <ModalWrapper>
      <MenuList>
        {items.map((item, index) => {
          const Icon: ReactElement = item?.icon ?? <></>;
          return (
            <MenuItem key={index} onClick={item.onClick} color={item?.color}>
              {Icon}
              {item.label}
            </MenuItem>
          );
        })}
      </MenuList>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  position: absolute;
  top: 45px;
  right: 0;
  z-index: ${zIndexes.notModalMenu};
  background: #ffffff;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const MenuList = styled.ul`
  list-style: none;
  width: 174px;
`;

const MenuItem = styled.li<{ color?: string }>`
  padding: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8.16px;
  cursor: pointer;
  color: ${(props) => props.color ?? "#343741"};

  &:hover {
    background: #deefff;
  }
`;
