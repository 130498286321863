import React from "react";
import { verifiedInvitationResidentMember } from "../../../apiClients/invitation";
import { usePolyfitLocationQuery } from "../../../hooks/router";
import { InviteMemberActivationContent } from "../../../features/InviteMember/InviteMemberActivationContent";

export default function ResidentInviteMemberActivationCompletePage() {
  const { token } = usePolyfitLocationQuery("RESIDENT_ACTIVATION", {
    token: "",
  });

  return (
    <InviteMemberActivationContent
      verify={verifiedInvitationResidentMember}
      token={token}
    />
  );
}
