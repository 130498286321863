import { BEResidentFilter } from "../../../../../server/src/@shared/types/boardEducation/residentUsers";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const residentFilterState = atom<BEResidentFilter>({
  key: "BEResidentFilter",
  default: {
    isMain: true,
    communityIds: [],
    communityUserType: [],
    preferredPaid: [],
    preferredDays: [],
    licenses: [],
    preferredVolunteerTypes: [],
    name: "",
    city: "",
    withinWalkMinute: undefined,
  },
  effects_UNSTABLE: [persistAtom],
});

export const useBEResidentFilter = () => {
  const [residentFilter, setResidentFilter] =
    useRecoilState(residentFilterState);

  return [residentFilter, setResidentFilter] as const;
};
