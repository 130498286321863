import React from "react";

export const CommunityRegisterIcon = () => {
  return (
    <svg
      width="246"
      height="246"
      viewBox="0 0 246 246"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3" y="3" width="240" height="240" rx="12" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.8013 89.2663C96.7588 90.2611 96.7285 91.8437 95.7337 92.8012L84.3052 103.801C83.8047 104.283 83.1263 104.535 82.4328 104.496C81.7392 104.458 81.0929 104.132 80.6489 103.598L76.0775 98.098C75.1949 97.0362 75.3402 95.46 76.4021 94.5774C77.4639 93.6949 79.0401 93.8402 79.9227 94.902L82.7752 98.334L92.2664 89.1988C93.2612 88.2413 94.8438 88.2715 95.8013 89.2663Z"
        fill="white"
      />
      <path
        d="M205.775 97.3999H166.74L131.701 61.8877C129.402 59.5595 126.268 58.248 122.999 58.248C119.729 58.248 116.595 59.5595 114.299 61.8877L79.2592 97.3999H40.2242C33.4744 97.3999 28 102.875 28 109.624V187.751H113.102V148.118C113.102 142.652 117.533 138.221 122.999 138.221C128.464 138.221 132.895 142.652 132.895 148.118V187.751H218V109.625C218 102.875 212.528 97.3999 205.775 97.3999ZM57.8634 160.387H47.57V141.389H57.8634V160.387ZM89.9704 160.387H79.677V141.389H89.9704V160.387ZM122.999 112.831C114.91 112.831 108.353 106.271 108.353 98.1852C108.353 90.0935 114.91 83.5392 122.999 83.5392C131.087 83.5392 137.644 90.0935 137.644 98.1852C137.644 106.271 131.087 112.831 122.999 112.831ZM166.319 160.387H156.026V141.389H166.319V160.387ZM198.427 160.387H188.136V141.389H198.427V160.387Z"
        fill="#343741"
      />
      <path d="M122.999 89.127V98.1842H130.551" fill="#4B4B4B" />
      <path
        d="M122.999 89.127V98.1842H130.551"
        stroke="#231815"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
