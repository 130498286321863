import { useMemo } from "react";
import { RouteQuery, Routes, ROUTE_DEFS, authorizedRoutes } from "../routers";
import { useNavigate, useLocation } from "react-router";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import { useCurrentUser, useFetchCurrentUser } from "./recoil/user";
import { getFirstCommunityId } from "../features/Member/Community/utils/utils";

export type BlocksLocation<T extends Routes> = RouteQuery<T> extends undefined
  ? {
      to: T;
      query?: { communityId?: string };
    }
  : {
      to: T;
      query: RouteQuery<T> & { communityId?: string };
    };

/**
 * @example
 * const to = createBlocksUrl({ to: 'BLOCKS_LIBRARY', query: { dirId: 'hoge'} });
 * <Link to={to}>to library</Link>
 */
export function createPolyfitUrl<T extends Routes>(
  arg: BlocksLocation<T>
): string {
  const route = ROUTE_DEFS[arg.to];
  const url = new URL(route.path, location.origin);
  const query: NonNullable<RouteQuery<T>> = {
    ...(arg.query || ({} as any)),
  };
  Object.entries(query).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });
  const searchParams = new URLSearchParams(location.search);
  const communityId = searchParams.get("communityId");
  if (
    !url.searchParams.get("communityId") &&
    communityId &&
    authorizedRoutes.findIndex((r) => r.path === route.path) !== -1
  ) {
    url.searchParams.append("communityId", communityId);
  }
  return `${url.pathname}${url.search}`;
}

export function usePolyfitUrl<T extends Routes>(
  arg: BlocksLocation<T>
): string {
  const url = useMemo(() => {
    return createPolyfitUrl(arg);
  }, [arg]);
  return url;
}

/**
 * @example
 * const history = usePolyfitHistory();
 * history.push({
 *   to: 'BLOCKS_LIBRARY',
 *   query: {
 *     dirId: 'hoge'
 *   }
 * })
 */
export function usePolyfitHistory() {
  const internalNavigate = useNavigate();

  const history = useMemo(() => {
    return {
      push: <T extends Routes>(
        loc: BlocksLocation<T>,
        options?: NavigateOptions | undefined
      ) => {
        const url = createPolyfitUrl(loc);
        internalNavigate(url, options);
      },
      pushUrl: (url: string, options?: NavigateOptions | undefined) => {
        internalNavigate(url, options);
      },
      replace: <T extends Routes>(loc: BlocksLocation<T>) => {
        const url = createPolyfitUrl(loc);
        internalNavigate(url, { replace: true });
      },
      go: (n: number) => {
        internalNavigate(n);
      },
      goBack: () => {
        internalNavigate(-1);
      },
      goForward: () => {
        internalNavigate(1);
      },
    };
  }, [internalNavigate]);

  return history;
}

/**
 * @example
 * const { dirId } = usePolyfitLocationQuery('BLOCKS_LIBRARY', { dirId: '' });
 */
export function usePolyfitLocationQuery<T extends Routes>(
  _route: T,
  defaultQuery: RouteQuery<T>
) {
  const { search } = useLocation();

  const query = useMemo(() => {
    const urlObj = new URL(search, location.origin);
    const searchParams = urlObj.searchParams.entries();
    const params = Object.fromEntries(searchParams);
    return {
      ...defaultQuery,
      ...(params as RouteQuery<T>),
    } as RouteQuery<T>;
  }, [search, defaultQuery]);

  return query;
}

export function useCurrentCommunityId() {
  const user = useCurrentUser();
  const refetchUser = useFetchCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();

  const communityId = useMemo(() => {
    return searchParams.get("communityId") || "";
  }, [searchParams]);

  const fetchCommunityId = async () => {
    if (!communityId) {
      await refetchUser();
      const roleId = user.account?.roleId;
      const firstCommunityId = getFirstCommunityId(
        user.account?.communityRoles ?? [],
        roleId
      );
      if (!firstCommunityId) return;
      const url = new URL(window.location.href);
      url.searchParams.set("communityId", firstCommunityId);
      window.location.href = url.toString();
    } else {
      if (
        !user.account?.communityRoles.some(
          (role) => role.communityId === communityId
        )
      ) {
        const roleId = user.account?.roleId;
        const firstCommunityId = getFirstCommunityId(
          user.account?.communityRoles ?? [],
          roleId
        );
        if (!firstCommunityId) return;
        const url = new URL(window.location.href);
        url.searchParams.set("communityId", firstCommunityId);
        window.location.href = url.toString();
      }
    }
  };

  const setCommunityId = (_communityId: string) => {
    if (_communityId) {
      setSearchParams((prev) => {
        prev.set("communityId", _communityId);
        return prev;
      });
    }
  };

  return { communityId, setCommunityId, fetchCommunityId };
}
