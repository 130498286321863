import React, { useCallback, useState } from "react";
import { User } from "../../apiClients/users";
import { Margin } from "../Margin";
import {
  SearchCandidate,
  SearchTextField,
  SearchTextFieldLabel,
} from "./SearchSchool";

type Props = {
  users: User[];
  onClick: (user: User) => void;
};
export const SearchUserField = ({ users, onClick }: Props) => {
  const [text, setText] = useState("");
  const filteredUsers = useCallback(() => {
    return users.filter((user) => {
      const name =
        (user.baseInfo?.lastName ?? "") + (user.baseInfo?.firstName ?? "");
      const nameKana =
        (user.baseInfo?.lastNameKana ?? "") +
        (user.baseInfo?.firstNameKana ?? "");
      return name.includes(text) || nameKana.includes(text);
    });
  }, [text, users]);
  return (
    <>
      <SearchTextFieldLabel>
        <SearchTextField
          placeholder="例)  山田花子"
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </SearchTextFieldLabel>
      <Margin marginBottom={10} />
      <SearchCandidate height={120}>
        {filteredUsers().map((user) => (
          <li
            key={user.id}
            onClick={() => {
              onClick(user);
            }}
          >
            {user.baseInfo?.lastName ?? ""} {user.baseInfo?.firstName ?? ""}
          </li>
        ))}
      </SearchCandidate>
    </>
  );
};
