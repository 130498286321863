import { toZonedTime, format } from "date-fns-tz";
import { ja } from "date-fns/locale/ja";

/**
 * 連絡機能で使用する
 * @param date
 * @returns
 * @example 2021年01月01日 12:00
 */
export const toDateFormat = (date: Date) => {
  const timeZone = "Asia/Tokyo";
  const zonedDate = toZonedTime(date, timeZone);
  const pattern = "yyyy年MM月dd日 HH:mm";
  const formattedDate = format(zonedDate, pattern, { timeZone });
  return formattedDate;
};

/**
 * 募集機能で使用する
 */
export const toDateIntervalFormat = (date1: Date, date2: Date) => {
  const timeZone = "Asia/Tokyo";
  const zonedDate1 = toZonedTime(date1, timeZone);
  const zonedDate2 = toZonedTime(date2, timeZone);
  const patternDate = "yyyy年MM月dd日";
  const patternTime = "HH:mm";

  // 同じ日にちか確認
  if (
    date1.getFullYear() !== date2.getFullYear() ||
    date1.getMonth() !== date2.getMonth() ||
    date1.getDate() !== date2.getDate()
  ) {
    return "invalid date";
  }

  const dayOfWeek = date1.getDay();
  const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek];

  const formattedDate = format(zonedDate1, patternDate, { timeZone });
  const formattedTime1 = format(zonedDate1, patternTime, { timeZone });
  const formattedTime2 = format(zonedDate2, patternTime, { timeZone });

  return `${formattedDate}(${dayOfWeekStr}) ${formattedTime1} ~ ${formattedTime2}`;
};

export const toRecruitmentDateFormat = (date: Date) => {
  const timeZone = "Asia/Tokyo";
  const zonedDate = toZonedTime(date, timeZone);
  const pattern = "yyyy-MM-dd";
  const formattedDate = format(zonedDate, pattern, { timeZone });
  return formattedDate;
};

export const toRecruitmentTimeFormat = (date: Date) => {
  const timeZone = "Asia/Tokyo";
  const zonedDate = toZonedTime(date, timeZone);
  const pattern = "HH:mm";
  const formattedDate = format(zonedDate, pattern, { timeZone });
  return formattedDate;
};

/**
 * yyyy年MM月
 *
 * @param date
 * @returns
 */
export const toDateFormatYearMonth = (date: Date) => {
  const timeZone = "Asia/Tokyo";
  const zonedDate = toZonedTime(date, timeZone);
  const pattern = "yyyy年MM月";
  const formattedDate = format(zonedDate, pattern, { locale: ja });
  return formattedDate;
};

/**
 * YYYY-MM-DD
 *
 * @param date
 * @returns
 */
export const toInputDateFormatYearMonth = (date: string | Date | undefined) => {
  if (date == null) {
    return "";
  }
  const timeZone = "Asia/Tokyo";
  const zonedDate = toZonedTime(date, timeZone);
  const pattern = "yyyy-MM-dd";
  const formattedDate = format(zonedDate, pattern, { locale: ja });
  return formattedDate;
};

/**
 * YYYY年MM月DD日
 *
 * @param date
 * @returns
 */
export const toDisplayDateFormatYearMonth = (
  date: string | Date | undefined
) => {
  if (date == null) {
    return "";
  }
  const timeZone = "Asia/Tokyo";
  const zonedDate = toZonedTime(date, timeZone);
  const pattern = "yyyy年MM月dd日";
  const formattedDate = format(zonedDate, pattern, { locale: ja });
  return formattedDate;
};

/**
 * YYYY/MM/DD
 */
export const toDisplaySlashDateFormat = (date: string | Date | undefined) => {
  if (date == null) {
    return "";
  }
  const timeZone = "Asia/Tokyo";
  const zonedDate = toZonedTime(date, timeZone);
  const pattern = "yyyy/MM/dd";
  const formattedDate = format(zonedDate, pattern, { locale: ja });
  return formattedDate;
};

/**
 * YYYY-MM-DD
 *
 * @param date
 * @returns
 */
export const toInputDateFormatHourMinute = (
  date: string | Date | undefined
) => {
  if (date == null) {
    return "";
  }
  const timeZone = "Asia/Tokyo";
  const zonedDate = toZonedTime(date, timeZone);
  const pattern = "HH:mm";
  const formattedDate = format(zonedDate, pattern, { locale: ja });
  return formattedDate;
};

/**
 * 分を時間と分の表記に変換する
 * @param minutes
 * @returns string
 * @example 120 => 2時間
 * @example 90 => 1時間30分
 */
export const toHourMinuteFormat = (minutes: number) => {
  const hour = Math.floor(minutes / 60);
  const minute = minutes % 60;
  if (hour === 0) {
    return `${minute}分`;
  }
  if (minute === 0) {
    return `${hour}時間`;
  }
  return `${hour}時間${minute}分`;
};
