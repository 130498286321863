import React, { useCallback, useEffect, useState } from "react";
import {
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "../../components/Modal";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { SearchUserField } from "../../components/form/SearchUserField";
import { Margin } from "../../components/Margin";
import { usePTAUsers } from "../../hooks/api/users";
import { useFetchCurrentUser } from "../../hooks/recoil/user";
import { updateApprovers, User } from "../../apiClients/users";
import { Button } from "../../components/Button";
import { CloseIcon } from "../../components/icons/CloseIcon";

type Props = {
  onClose: () => void;
  refetch: () => void;
};

export const PostApproverModal = ({ onClose, refetch }: Props) => {
  const { handleSubmit } = useForm();
  const fetchUser = useFetchCurrentUser();
  const [{ users }] = usePTAUsers({});
  const [targetUsers, setTargetUsers] = useState<User[]>([]); // 検索対象のユーザー
  const [approvers, setApprovers] = useState<User[]>([]);
  const onPick = useCallback(
    (user: User) => {
      if (approvers.find((approver) => approver.id === user.id)) return;
      setApprovers([...approvers, user]);
    },
    [approvers]
  );
  const onRemove = useCallback(
    (user: User) => {
      setApprovers(approvers.filter((approver) => approver.id !== user.id));
    },
    [approvers]
  );
  const onSubmit = async () => {
    const approverIds = approvers.map((approver) => approver.id);
    await updateApprovers(approverIds);
    await refetch();
    await fetchUser();
    onClose();
  };
  useEffect(() => {
    // approversの初期値をセット
    setApprovers(users.filter((user) => user.isApprover));
  }, [users]);
  useEffect(() => {
    // approversに含まれないのユーザーをtargetUsersにセット
    setTargetUsers(users.filter((user) => !approvers.includes(user)));
  }, [approvers]);
  return (
    <ModalPortal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <p>承認者の設定</p>
        </ModalHeader>
        <ModalBody>
          <p>追加したい承認者を入力しましょう。</p>
          <p>承認者を変更すると承認待ちの連絡は全て差し戻しされます。</p>
          <Margin marginBottom={10} />
          <SearchUserField users={targetUsers} onClick={onPick} />
          <Divider />
          <SubTitle>設定中の承認者</SubTitle>
          <Margin marginBottom={10} />
          <ApproverBadgeWrapper>
            {approvers.map((user) => (
              <ApproverBadge
                key={user.id}
                user={user}
                onClick={() => onRemove(user)}
              />
            ))}
          </ApproverBadgeWrapper>
        </ModalBody>
        <ModalSubmitButtons submitText="設定" onCancel={onClose} />
      </form>
    </ModalPortal>
  );
};

const ModalBody = styled.div`
  min-height: 200px;
  height: fit-content;
`;

const Divider = styled.div`
  width: 100%;
  border: 0.75px solid #f0f2f5;
  margin: 10px 0px;
`;

const SubTitle = styled.p`
  font-size: 12px;
`;

const ApproverBadgeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

type ApproverBadgeProps = {
  user: User;
  onClick?: () => void;
};

const ApproverBadge = ({ user, onClick }: ApproverBadgeProps) => {
  return (
    <Badge>
      {user.name}
      <RemoveButton onClick={onClick}>
        <CloseIcon />
      </RemoveButton>
    </Badge>
  );
};

const Badge = styled.div`
  width: fit-content;
  border: 1px solid #0071c2;
  border-radius: 36px;
  background: #e6f1fa;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  gap: 4px;
  font-size: 12px;
`;

const RemoveButton = styled(Button)`
  width: 16px;
  height: 16px;
  color: #aab4c4;
  background-color: transparent;
  padding: 0;
`;
