import * as client from "../client";
import { Organization } from "@shared/types/boardEducation/organization";

/**
 * 教育委員会が管理する組織を取得する
 */
export async function getManagedOrganization(): Promise<Organization> {
  const res = await client.get<{}, Organization>(
    "/boardEducation/organization/getManagedOrganization",
    {}
  );
  return res;
}

/**
 * 教育委員会の自身の組織を取得する
 */
export async function getCurrentOrganization(): Promise<Organization> {
  const res = await client.get<{}, Organization>(
    "/boardEducation/organization/getCurrentOrganization",
    {}
  );
  return res;
}
