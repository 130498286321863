import React, { useState } from "react";
import { PlusIcon } from "../../../components/icons/PlusIcon";
import * as Typo from "../../../components/Typo";
import styled from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableBody,
  TableRow,
  TableData,
} from "../../../components/Table2";
import { ButtonGroup } from "../../../components/Button";
import { useCustomReactHookForm } from "../../../components/form/ReactHookForm";
import { RegistrationFooter } from "../../../components/Footer2";
import { StepAdmin } from "../../../components/icons/StepAdmin";
import { Margin } from "../../../components/Margin";
import { useOrganization } from "../../../hooks/recoil/organization";
import { usePartById } from "../../../hooks/api/part";
import { useBelongById } from "../../../hooks/api/belong";

type OrganizationSettingArgs = {
  belong: string;
  part: string;
};

export default function OrganizationSettingPage() {
  const { TextField, Form, errors, getValues, setValue, trigger, clearErrors } =
    useCustomReactHookForm<OrganizationSettingArgs>();
  const { organization, fetchOrganization } = useOrganization({});
  const { createBelong, deleteBelong } = useBelongById(organization.id);
  const { createPart, deletePart } = usePartById(organization.id);
  const history = usePolyfitHistory();

  const onSubmitBelong = async () => {
    clearErrors();
    const result = await trigger("belong");
    if (result) {
      await createBelong(
        getValues("belong") ?? "",
        organization.belongs.length + 1
      );
      fetchOrganization();
      setValue("belong", "");
    }
  };
  const onSubmitPart = async () => {
    clearErrors();
    const result = await trigger("part");
    if (result) {
      await createPart(getValues("part") ?? "", organization.parts.length + 1);
      fetchOrganization();
      setValue("part", "");
    }
  };
  const onClickNext = () => {
    history.push({ to: "POSITION_SETTING" });
  };

  const [menuId, setMenuId] = useState("");
  const onClickMenu = (id: string) => {
    if (menuId === id) {
      setMenuId("");
    } else {
      setMenuId(id);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Margin marginTop={20} />
      <StepAdmin stepNum={3} />
      <StyledContainer>
        <Typo.Heading3 className="title">所属役職の設定</Typo.Heading3>
        <Text className="sub">
          本部や委員会、会長や書記など所属・役職を設定できます
        </Text>
        <FormWrapper>
          <Form>
            <Table className="table-belongs">
              <TableHeader>
                <TableHeaderItem>所属</TableHeaderItem>
              </TableHeader>
              <TableBody>
                {organization &&
                  organization.belongs.map((belong, index) => (
                    <TableRow key={index}>
                      {menuId === belong.id ? (
                        <TableDataExtend className="edit">
                          <span>{belong.name}</span>
                          <div onClick={() => onClickMenu(belong.id)}>･･･</div>
                          <ButtonGroupExtend>
                            <Button2
                              //TODO:type="button"としないと勝手にvalidationのチェックが入りエラーメッセージが生成される
                              type="button"
                              onClick={async () => {
                                await deleteBelong(belong.id).then(() => {
                                  fetchOrganization();
                                });
                              }}
                            >
                              削除
                            </Button2>
                          </ButtonGroupExtend>
                        </TableDataExtend>
                      ) : (
                        <TableDataExtend>
                          <span>{belong.name}</span>
                          <div onClick={() => onClickMenu(belong.id)}>･･･</div>
                        </TableDataExtend>
                      )}
                    </TableRow>
                  ))}
                <TableRow>
                  <TableData className="td-input">
                    <TextFieldWrapper>
                      <TextField
                        placeholder="例) 本部、委員会"
                        name="belong"
                        options={{
                          required: "入力してから追加してください",
                          deps: ["belong"],
                        }}
                        isDanger={Boolean(errors.belong)}
                        className="text-field"
                        onKeyDown={handleKeyDown}
                      />
                    </TextFieldWrapper>
                  </TableData>
                </TableRow>
              </TableBody>
            </Table>
            <div className="error-wrapper">
              {errors.belong && (
                <span className="error-message">{errors.belong.message}</span>
              )}
              <button
                className="submit-button"
                type="button"
                onClick={() => onSubmitBelong()}
              >
                <IconWrapper>
                  <PlusIcon size={18} />
                  <span>追加</span>
                </IconWrapper>
              </button>
            </div>
          </Form>
          <form>
            <Table className="table-belongs">
              <TableHeader>
                <TableHeaderItem>役職</TableHeaderItem>
              </TableHeader>
              <TableBody>
                {organization &&
                  organization.parts.map((part, index) => (
                    <TableRow key={index}>
                      {menuId === part.id ? (
                        <TableDataExtend className="edit">
                          <span>{part.name}</span>
                          <div onClick={() => onClickMenu(part.id)}>･･･</div>
                          <ButtonGroupExtend>
                            <Button2
                              //TODO:type="button"としないと勝手にvalidationのチェックが入りエラーメッセージが生成される
                              type="button"
                              onClick={async () => {
                                await deletePart(part.id).then(() => {
                                  fetchOrganization();
                                });
                              }}
                            >
                              削除
                            </Button2>
                          </ButtonGroupExtend>
                        </TableDataExtend>
                      ) : (
                        <TableDataExtend>
                          <span>{part.name}</span>
                          <div onClick={() => onClickMenu(part.id)}>･･･</div>
                        </TableDataExtend>
                      )}
                    </TableRow>
                  ))}
                <TableRow>
                  <TableData className="td-input">
                    <TextFieldWrapper>
                      <TextField
                        placeholder="例) 会長、副会長、書記"
                        name="part"
                        options={{
                          required: "入力してから追加してください",
                          deps: ["part"],
                        }}
                        isDanger={Boolean(errors.part)}
                        className="text-field"
                        onKeyDown={handleKeyDown}
                      />
                    </TextFieldWrapper>
                  </TableData>
                </TableRow>
              </TableBody>
            </Table>
            <div className="error-wrapper">
              {errors.part && (
                <span className="error-message">{errors.part.message}</span>
              )}
              <button
                className="submit-button"
                type="button"
                onClick={() => onSubmitPart()}
              >
                <IconWrapper>
                  <PlusIcon size={18} />
                  <span>追加</span>
                </IconWrapper>
              </button>
            </div>
          </form>
        </FormWrapper>
      </StyledContainer>
      <RegistrationFooter onClickNext={onClickNext} />
    </>
  );
}

const StyledContainer = styled.div`
  max-width: 702px;
  margin: 0 auto;
  padding-bottom: 88px;
  table {
    border-collapse: collapse;
    background-color: #fff;
  }
  th,
  td {
    font-size: 16px;
    font-weight: normal;
  }
  tr {
    border: 1px solid #ccd1d9;
  }
  thead tr {
    border: 1px solid #ccd1d9;
  }
  .td-input {
    padding: 0;
  }
  .submit-button {
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  .title {
    font-weight: bold;
    padding-bottom: 8px;
  }
  .sub {
    padding: 8px 0;
  }
  .error-wrapper {
    display: flex;
    flex-direction: column;

    .error-message {
      font-size: 12px;
      color: #bd271e;
    }
  }
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

const TextFieldWrapper = styled.div`
  .text-field {
    width: 100%;
    padding-left: 20px;
    line-height: 3rem;
    outline: none;
    border: none;
    font-size: 16px;
  }
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 343px 343px;
  column-gap: 16px;
  .submit-button {
    border: none;
    background: transparent;
  }
  @media (max-width: 1279px) {
    grid-template-columns: 343px;
    column-gap: 0;
    row-gap: 24px;
  }
`;

const IconWrapper = styled.div`
  width: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    padding-top: 2px;
  }
`;

const Text = styled.p`
  color: gray;
`;

const TableDataExtend = styled(TableData)`
  display: flex;
  width: 343px !important;
  justify-content: space-between;
  span {
    // overflow-y: scroll;
    // スクロールバーが毎回表示されてデザイン的に違和感がある&無くても問題ないので削除
  }
  div {
    cursor: pointer;
    margin-left: 16px;
  }
  position: relative;
  &.edit {
    width: 270px !important;
  }
`;

const ButtonGroupExtend = styled(ButtonGroup)`
  position: absolute;
  right: -60px;
  top: 8px;
  border: 1px solid gray;
  padding: 5px 10px;
  border-radius: 10px;
  background: white;
  z-index: 3;
`;

const Button2 = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;
