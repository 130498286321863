import React from "react";
import styled from "styled-components";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { useGetDownloadUrl } from "src/hooks/query/firebaseStorage";
import { colorsPallet } from "src/theme";
import { BREAKPOINTS } from "src/components/Responsive";

type Props = {
  onClick: () => void;
  chatRoomName: string;
  iconFilePath?: string;
  createdAt: string;
  isActive: boolean;
  isUnAnswered: boolean;
  isPending: boolean;
};

const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

export const ChatRoomGroupBoardEducationCard = (props: Props) => {
  const { url } = props.iconFilePath
    ? useGetDownloadUrl(props.iconFilePath, "")
    : { url: "" };
  return (
    <ChatRoom isActive={props.isActive} onClick={props.onClick}>
      <Picture src={url || defaultUserImg} alt={defaultUserImg} />
      <Info>
        <Badges>
          <StatusBadge color="#413BA5" isPending={props.isPending}>
            教育委員会グループメッセージ
          </StatusBadge>
          {props.isPending ? (
            <StatusBadge
              isPending={props.isPending}
              color={colorsPallet.mediumShade}
            >
              招待中
            </StatusBadge>
          ) : (
            props.isUnAnswered && <UnreadBadge />
          )}
        </Badges>
        <RTitle>{props.chatRoomName}</RTitle>
        <UserNameAndDate>
          <ChatRoomSubLabel>{props.createdAt}</ChatRoomSubLabel>
        </UserNameAndDate>
      </Info>
    </ChatRoom>
  );
};

const ChatRoom = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 16px;
  border-radius: 16px;
  background: ${(props) => (props.isActive ? "#F5F7FA" : "#fff")};
  &:hover {
    background: #f5f7fa;
  }
`;

const UserNameAndDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 70%;
  margin-left: 16px;
`;

const ChatRoomSubLabel = styled.p`
  color: #69707d;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
`;

const Badges = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  @media (max-width: ${BREAKPOINTS.SP}) {
    gap: 4px;
  }
`;

const StatusBadge = styled.span<{ color: string; isPending: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.color};
  color: white;
  font-size: 0.7em;
  height: 20px;
  padding: 2px 8px;
  border-radius: 4px;
  padding: 2px 8px;
  height: 20px;

  ${(props) =>
    props.isPending
      ? `
    font-size: 10px;
  `
      : `
    font-size: 0.7em;
  gap: 4px;
  `}
`;

const UnreadBadge = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #0077cc;
`;

const RTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  margin-top: 12px;
`;

const Picture = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
`;
