import React, { memo } from "react";

type Props = {
  size: number;
};

export const Tag = memo(({ size = 16 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 448 512"
      //   fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#343741"
        d="M0 80L0 229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7L48 32C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
      />
    </svg>
  );
});
