import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useToast } from "../../../components/Toast";
import {
  auth,
  reauthenticateWithPassword,
  updatePassword,
} from "../../../utils/firebase";
import {
  ModalPortal,
  ModalBody,
  ModalRemoveButtons,
} from "../../../components/Modal";
import { EyeOpenIcon } from "../../../components/icons/EyeOpenIcon";
import { EyeClosedIcon } from "../../../components/icons/EyeClosedIcon";
import * as theme from "../../../theme";
import {
  StyledInputLabel,
  StyledPasswordInputWrapper,
  StyledPasswordInput,
  StyledEyeIconWrapper,
  StyledInputLabelAdditional,
} from "../../../components/Form";
import {
  ModalWrapper,
  ModalHeader,
  ErrorMessage,
} from "../../../pages/pta/admin/ProfilePage";
import { ResetPasswordLinkWrapper } from "../../../pages/LoginPage";
import * as Margin from "../../../components/Margin";
import { errorCodeToMessage } from "../../../utils/errorCodeToMessage";

type PasswordResetFormValue = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

type Props = {
  isOpen: boolean;
  close: () => void;
};

export const ChangePasswordModal = ({ isOpen, close }: Props) => {
  const [isDisplayOldPassword, setIsDisplayOldPassword] =
    useState<boolean>(false);
  const [isDisplayNewPassword, setIsDisplayNewPassword] =
    useState<boolean>(false);
  const [isDisplayPasswordConfirm, setIsDisplayPasswordConfirm] =
    useState<boolean>(false);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm<PasswordResetFormValue>({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
  });
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const handleOnResetPassword = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
    setDoubleClickBlocked(true);

    try {
      const oldPassword = getValues("oldPassword");
      const newPassword = getValues("newPassword");
      const newPasswordConfirm = getValues("newPasswordConfirm");
      if (newPassword !== newPasswordConfirm) {
        return setError("newPasswordConfirm", {
          type: "custom",
          message: "新しいパスワードが一致しません。",
        });
      }

      await reauthenticateWithPassword(currentUser, oldPassword);
      await updatePassword(currentUser, newPasswordConfirm);
      toast.success("保存しました");
      close();
    } catch (err: any) {
      console.log(err);
      if (err.code === "auth/wrong-password") {
        setError("oldPassword", {
          type: "custom",
          message: errorCodeToMessage(err.code),
        });
      } else {
        setError("newPasswordConfirm", {
          type: "custom",
          message: errorCodeToMessage(err.code),
        });
      }
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={() => close()}>
          <ModalWrapper>
            <ModalHeader>パスワードの変更</ModalHeader>
            <ModalBody>
              <StyledInputLabel htmlFor="oldPassword">
                現在のパスワード
              </StyledInputLabel>
              <StyledPasswordInputWrapper>
                <StyledPasswordInput
                  type={isDisplayOldPassword ? "text" : "password"}
                  {...register("oldPassword", {
                    required: "入力必須項目です",
                  })}
                />
                <StyledEyeIconWrapper
                  onClick={() => setIsDisplayOldPassword(!isDisplayOldPassword)}
                >
                  {isDisplayOldPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
                </StyledEyeIconWrapper>
              </StyledPasswordInputWrapper>
              {errors.oldPassword && (
                <ErrorMessage>{errors.oldPassword.message}</ErrorMessage>
              )}

              <ResetPasswordLinkWrapper>
                <Link
                  to={"/input_email"}
                  style={{
                    color: theme.colorsPallet.darkShade,
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  パスワードを忘れた方
                </Link>
              </ResetPasswordLinkWrapper>

              <Margin.Margin marginTop={25} />
              <StyledInputLabel htmlFor="newPassword">
                新しいパスワード
                <StyledInputLabelAdditional>
                  8文字以上の半角英数字
                </StyledInputLabelAdditional>
              </StyledInputLabel>
              <StyledPasswordInputWrapper>
                <StyledPasswordInput
                  type={isDisplayNewPassword ? "text" : "password"}
                  {...register("newPassword", {
                    required: "入力必須項目です",
                  })}
                />
                <StyledEyeIconWrapper
                  onClick={() => setIsDisplayNewPassword(!isDisplayNewPassword)}
                >
                  {isDisplayNewPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
                </StyledEyeIconWrapper>
              </StyledPasswordInputWrapper>
              {errors.newPassword && (
                <ErrorMessage>{errors.newPassword.message}</ErrorMessage>
              )}

              <Margin.Margin marginTop={10} />
              <StyledInputLabel htmlFor="password">
                新しいパスワードの再入力
              </StyledInputLabel>
              <StyledPasswordInputWrapper>
                <StyledPasswordInput
                  type={isDisplayPasswordConfirm ? "text" : "password"}
                  {...register("newPasswordConfirm", {
                    required: "入力必須項目です",
                  })}
                />
                <StyledEyeIconWrapper
                  onClick={() =>
                    setIsDisplayPasswordConfirm(!isDisplayPasswordConfirm)
                  }
                >
                  {isDisplayPasswordConfirm ? (
                    <EyeOpenIcon />
                  ) : (
                    <EyeClosedIcon />
                  )}
                </StyledEyeIconWrapper>
              </StyledPasswordInputWrapper>
              {errors.newPasswordConfirm && (
                <ErrorMessage>{errors.newPasswordConfirm.message}</ErrorMessage>
              )}

              <Margin.Margin marginTop={20} />
              <ModalRemoveButtons
                disabled={doubleClickBlocked}
                submitText="保存"
                onSubmit={handleSubmit(handleOnResetPassword)}
                onCancel={close}
              />
            </ModalBody>
          </ModalWrapper>
        </ModalPortal>
      )}
    </>
  );
};
