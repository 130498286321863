import React from "react";
import { PageContainer, PageHeader, PageBody } from "../../components/Page";
import * as Typo from "../../components/Typo";
import * as Responsive from "../../components/Responsive";
import styled from "styled-components";
import { UnderConstructionIcon } from "../../components/icons/UnderConstructionIcon";

const SignupResidentCompletePageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 702px;
  margin: 0 auto;
  padding-top: 124px;
  .message {
    text-align: center;
  }
  button {
    margin: 12px auto;
  }
  @media (max-width: 1279px) {
    padding-top: 120px;
  }
`;

export default function ResidentHomePage() {
  // const history = usePolyfitHistory();

  return (
    <>
      <SignupResidentCompletePageContainer>
        <UnderConstructionIcon />
        <PageHeader>工事中です</PageHeader>
        <PageBody>
          <Responsive.Row>
            <Responsive.Col>
              <Typo.Paragraph className="message">
                <p>12月中旬に募集一覧の機能をアップデートする予定です</p>
                <p>登録内容を変更をしたい場合は右上のプロフィール画面から</p>
                <p>ご変更いただくことができます。</p>
              </Typo.Paragraph>
            </Responsive.Col>
          </Responsive.Row>
          <Responsive.Row>
            {/* <Responsive.Col>
              <Button
                size="large"
                fill
                className="message"
                onClick={() => {
                  history.push({ to: "LOGIN" });
                }}
              >
                ログイン画面
              </Button>
            </Responsive.Col> */}
          </Responsive.Row>
        </PageBody>
      </SignupResidentCompletePageContainer>
    </>
  );
}
